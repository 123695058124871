import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import { PermissionsPermissionsEnum } from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import { Column } from '../../interfaces/components';
import fedexLabels from '../../utils/fedexLabels';
import { Typography } from '../primitives/index';
import MuiButton from '@material-ui/core/Button';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';
import configurationUtils from '../../utils/configurationUtils';
import { Box, Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import clx from 'classnames';
import colors from '../../utils/colors';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';

// Services
import PackagesService from '../../services/Packages.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import { Helmet } from 'react-helmet';
import PackagePrediction from './utils/PackagePrediction';
import EphemeralStateService from '../../services/EphemeralState.service';

export const GET_COLUMNS_PACKAGES = (): Column[] => {
  return [
    {
      id: 'prediction_icon',
      label: 'Prediction',
      width: 'auto',
      useCustomComponent: true,
      hide: !EphemeralStateService.getMyStationConfiguratation()
        .GENERAL.BEHAVIORS.ML_INTEGRATION.arrival_prediction,
    },
    {
      id: 'tracking_number',
      label: 'Tracking ID',
      width: 150,
    },
    {
      id: 'stop_name',
      label: 'Destination',
      width: 'auto',
    },
    {
      id: 'zipcode',
      label: 'ZIP Code',
      width: 100,
    },
    {
      id: 'id',
      label: 'ID',
      width: 'auto',
    },

    {
      id: 'process_step_label',
      label: 'Process Step',

      width: 'auto',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      width: 'auto',
    },
    {
      id: 'sort_param',
      label: 'Sortation Parameter',
      width: 'auto',
    },
    {
      id: 'last_event',
      label: 'Last Event',
      width: 'auto',
    },
    {
      id: 'origin_station_name',
      label: 'Origin Station',
      width: 'auto',
    },
  ];
};
export const fetch = async (
  pageIndex: number,
  rowsPerPage: number,
  filters: SelectedFilters,
  sortedBy?: string,
  options?: any,
) => {
  let sortByVal = sortedBy;
  if (sortByVal?.includes('fmc_full_name')) {
    sortByVal = sortByVal.replace('fmc_full_name', 'fmc__first_name');
  }
  if (sortByVal?.includes('last_event')) {
    sortByVal = sortByVal.replace('last_event', 'process_step_time');
  }

  const isManifested =
    filters.manifested && filters.manifested.values.length
      ? filters.manifested.values[0].id === 'yes'
        ? true
        : filters.manifested.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const isScanned =
    filters.scanned && filters.scanned.values.length
      ? filters.scanned.values[0].id === 'yes'
        ? true
        : filters.scanned.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const isArrived =
    filters.scanned && filters.arrived.values.length
      ? filters.arrived.values[0].id === 'yes'
        ? true
        : filters.arrived.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const inSystem =
    filters.in_system && filters.in_system.values.length
      ? filters.in_system.values[0].id === 'yes'
        ? true
        : filters.in_system.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const hidePending =
    filters.hidePending && filters.hidePending.values.length
      ? filters.hidePending.values[0].id === 'yes'
        ? true
        : filters.hidePending.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const firstScannedInWindow =
    filters.packageScannedToday &&
    filters.packageScannedToday.values.length
      ? filters.packageScannedToday.values[0].id === 'yes'
        ? true
        : filters.packageScannedToday.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const res = await PackagesService.getAll({
    page: pageIndex,
    pageSize: rowsPerPage,
    sortBy:
      (sortByVal as
        | 'process_step_time'
        | 'outbound_load'
        | 'fmc__first_name'
        | 'container'
        | '-process_step_time'
        | '-outbound_load'
        | '-fmc__first_name'
        | '-container'
        | 'process_step_time'
        | '-process_step_time') || undefined,
    owner:
      filters.owner && filters.owner.values.length
        ? filters.owner.values.map((item: any) => item.id)
        : undefined,
    carrier:
      filters.carrier && filters.carrier.values.length
        ? filters.carrier.values.map((item: any) => item.id)
        : undefined,
    route:
      filters.route && filters.route.values.length
        ? filters.route.values.map((item: any) => item.id)
        : undefined,
    destination:
      filters.stop && filters.stop.values.length
        ? filters.stop.values.map((item: any) => item.id)
        : undefined,
    area:
      filters.area && filters.area.values.length
        ? filters.area.values[0].id
        : undefined,
    processStep:
      filters.processStep && filters.processStep.values.length
        ? filters.processStep.values.map((item: any) => item.id)
        : undefined,
    isManifested,
    isScanned,
    isArrived,
    inSystem,
    hidePending,
    firstScannedInWindow,
    defects:
      filters.packageDefect && filters.packageDefect.values.length
        ? filters.packageDefect.values.map((item: any) => item.id)
        : undefined,
    ...options,
  });

  res.data.results.forEach((pck: any) => {
    pck.prediction_icon = (
      <PackagePrediction prediction={pck.arrival} />
    );

    pck.tracking_number =
      pck.tracking_number_alias ||
      pck.tracking_number ||
      common.emptyValue;

    pck.process_step_label = pck.process_step
      ? fedexLabels.getLabel(pck.process_step)
      : common.emptyValue;

    pck.sort_param = pck.sort_param
      ? sanitizeHtml(pck.sort_param, {
          allowedTags: [],
        })
      : common.emptyValue;

    pck.dwell_time = pck.last_process_step_time
      ? utils.getLongestDwellTimeLabel(pck.last_process_step_time)
      : common.emptyValue;

    if (pck.last_event_data?.event_type) {
      const eventLabel = fedexLabels.getLabel(
        (pck.last_event = pck.last_event_data?.event_type),
      );
      if (pck.last_event_data?.user_full_name) {
        pck.last_event =
          eventLabel + ' by ' + pck.last_event_data?.user_full_name;
      } else {
        pck.last_event = eventLabel;
      }
    } else {
      pck.last_event = common.emptyValue;
    }
  });

  return res;
};

interface Props {
  classes: { [key: string]: string };
  location: any;
}
export interface IProcessStep {
  id: string;
  label: string;
  active: boolean;
}
const Packages: React.FC<Props> = ({ classes, location }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const fetchPackages = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await fetch(
      pageIndex,
      rowsPerPage,
      selectedFilters || {},
      sortedBy,
      { trackingNumber: filterByString },
    );
    if (res.data.count > 0) {
      setBatchExportEnabled(true);
    } else {
      setBatchExportEnabled(false);
    }
    return res;
  };

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const keyHash = _.isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.PACKAGEREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      ${configurationUtils.getPageTitle(false, 'PACKAGE')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='PACKAGE'>
        <ExportCSVDialog
          exportFilters={{}} // Packages export doesn't require filters.
          key={'dialog' + filterLastUpdate}
          type='PACKAGES'
          isOpen={showDialog}
          onAfterClose={() => {
            setShowDialog(false);
          }}
          packageFilters={selectedFilters}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.PACKAGEREPORTREAD,
            ) && (
              <MuiButton
                variant='outlined'
                className={classes.outlinedButton}
                style={{ marginLeft: 15 }}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Batch Export CSVs
              </MuiButton>
            )}
            <MuiButton
              variant='outlined'
              className={clx(
                classes.outlinedButton,
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </MuiButton>
          </Grid>
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map((item: any) =>
                  item.values.length ? (
                    <Box
                      key={item.key}
                      className={classes.filterBadge}
                    >
                      <b>{item.label}</b>
                      {item.values.map((value: any) => (
                        <span
                          style={{ marginLeft: '5px' }}
                          key={`${value.name || value.full_name}-${
                            value.id
                          }`}
                        >
                          {value.name || value.full_name}
                          <MuiButton
                            className={classes.filterBadgeButton}
                            onClick={() => {
                              setFilterToRemove({
                                filterKey: item.key,
                                filterValue: value.id,
                              });
                            }}
                          >
                            <ClearIcon />
                          </MuiButton>
                        </span>
                      ))}
                    </Box>
                  ) : undefined,
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={'table' + filterLastUpdate}
            tableKey={'table' + filterLastUpdate}
            title={''}
            columns={GET_COLUMNS_PACKAGES()}
            dataTestIdPrefix={'packages'}
            filterByString={true}
            filterByStringPlaceholder={'Search by Tracking ID'}
            fetch={fetchPackages}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.PACKAGE}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || '-last_event'
            }
            sortableBy={['current_load', 'last_event']}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      color: colors.black,
      backgroundColor: colors.veryLightGray,
      borderColor: colors.veryLightGray,
      minWidth: '40px',
      marginLeft: '20px',
    },
  })),
)(Packages);
