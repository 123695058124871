import React, { useState, useEffect } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes } from '../../interfaces/routes';
import Button from '@material-ui/core/Button';
import PaginatedTable, {
  filterObj,
  getSelectedFilterOption,
} from '../paginatedTable/PaginatedTable';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import queryString from 'query-string';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import AddStaffDialog from './AddStaffDialog';
import { renderDuration } from '../DetailsPagesFunctions';
import configurationUtils from '../../utils/configurationUtils';
import { Grid, Typography } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { Column } from '../../interfaces/components';
import { Staff, PermissionsPermissionsEnum } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

// Services

import StaffService from '../../services/Staff.service';
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';

export enum ROLE_LABELS {
  BLANK = 'Blank',
  OPERATORI = 'Operator I',
  OPERATORII = 'Operator II',
  OPERATORIII = 'Operator III',
  STATIONADMIN = 'Station Admin',
  ORGADMIN = 'Organization Admin',
  SUPERUSER = 'Super User',
}

export enum OT_PERMISSIONS_LABELS {
  OPERATORTOOLSORTATION = 'Sortation',
  OPERATORTOOLMISSORT = 'Missorting',
  OPERATORTOOLLOADING = 'Loading',
  OPERATORTOOLMISLOAD = 'Misloading',
  OPERATORTOOLMISMOVE = 'Mismoving',
  OPERATORTOOLMEASURING = 'Measuring',
  OPERATORTOOLCONTAINERS = 'Container Operations',
  OPERATORTOOLLOADS = 'Load Operations',
  OPERATORTOOLTROUBLESHOOTING = 'Troubleshooting Mode',
  OPERATORTOOLRELABEL = 'Relabel Mode',
  OPERATORTOOLRETURNING = 'Return Mode',
  OPERATORTOOLINBOUNDLOADS = 'Inbound Loads',
}

export enum OTHER_PERMISSIONS_LABELS {
  TECHNICALASSETSREAD = 'Read technical assets',
  WEBHOOKREAD = 'Read data from webhooks',
  WEBHOOKWRITE = 'Write data to webhooks',
}

interface Props {
  classes: { [key: string]: string };
}

const StationStaffComponent: React.FC<RouteComponentProps & Props> =
  ({ classes, location }) => {
    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [showAddDialog, setShowAddDialog] =
      useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [disabledAdd, setDisabledAdd] = useState<boolean>(true);

    const [lastUpdated, setLastUpdated] = useState<string>(
      new Date().toISOString(),
    );

    // Get labels on each render cycle
    const COLUMNS: Column[] = [
      {
        id: 'full_name',
        label: 'Name',
        width: 'auto',
        align: 'left',
        showOnMobile: true,
      },
      {
        id: 'role',
        label: 'Role',
        width: 'auto',
        align: 'left',
        showOnMobile: true,
      },
      {
        id: 'id',
        label: 'ID',
        width: 100,
        align: 'center',
      },

      {
        id: 'device_id',
        label: configurationUtils.getPageTitle(true, 'DEVICE'),
        width: 'auto',
        align: 'left',
      },
      {
        id: 'active_since',
        label: 'Uptime',
        width: 'auto',
        align: 'left',
      },
      {
        id: 'status',
        label: 'Status',
        width: 'auto',
        align: 'left',
      },
      {
        id: 'seconds_per_package',
        label: 'SPP',
        width: 'auto',
        align: 'left',
      },
      {
        id: 'daily_totals',
        label: 'Daily Totals',
        width: 'auto',
        align: 'left',
        showOnMobile: true,
      },
    ];

    const handleError = async (e: AxiosError) => {
      setError(await ErrorHandler.getLabel(e));
    };

    const onAddStaff = async (
      staffParams: Staff,
      onAfterDialogClose: () => void,
      updateUser: boolean,
    ) => {
      setError(undefined);
      setShowProgress(true);
      try {
        if (updateUser) {
          staffParams.first_name = undefined;
          staffParams.last_name = undefined;
          staffParams.email = undefined;
          await StaffService.update(staffParams.id!, staffParams);
        } else {
          if (staffParams.email === '') staffParams.email = undefined;
          await StaffService.create(staffParams);
        }

        setLastUpdated(new Date().toISOString());
        onAfterDialogClose();
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setShowProgress(false);
      }
    };

    const fetchStaffData = async (
      pageIndex: number,
      rowsPerPage: number,
      filterOptions?: filterObj[],
      filterByString?: string,
      sortedBy?: string,
    ) => {
      const sortedByValue = sortedBy?.replace('status', 'active');

      let fActive = undefined;

      if (filterOptions)
        fActive = getSelectedFilterOption(filterOptions, 'Status');

      if (fActive === 'ALL') {
        fActive = undefined;
      } else if (fActive === 'active') {
        fActive = true;
      } else {
        fActive = false;
      }

      const res = await StaffService.getAll({
        page: pageIndex,
        pageSize: rowsPerPage,
        sortBy: sortedByValue as
          | 'active'
          | 'seconds_per_package'
          | '-active'
          | '-seconds_per_package'
          | undefined,
        active: fActive,
      });

      res.data.results.forEach((staff: any) => {
        staff.device_id = staff.device_id || common.emptyValue;
        staff.active_since =
          staff?.active_since && staff?.active
            ? renderDuration(
                (new Date().getTime() -
                  new Date(staff?.active_since!).getTime()) /
                  1000,
              )
            : common.emptyValue;
        staff.status = staff.active
          ? `<span style="color:${colors.darkGold}">Active</span>`
          : 'Inactive';
        staff.daily_totals = staff.daily_totals || common.emptyValue;
        staff.seconds_per_package = staff.seconds_per_package
          ? renderDuration(staff.seconds_per_package)
          : common.emptyValue;
        staff.role = staff.permission_data
          ? ROLE_LABELS[
              staff.permission_data.role_type.replace(
                '_',
                '',
              ) as keyof typeof ROLE_LABELS
            ] ?? common.emptyValue
          : common.emptyValue;
      });
      setDisabledAdd(false);
      return res;
    };

    const filters = () => {
      return [
        {
          name: 'Status',
          options: [
            { label: 'All', value: 'ALL', selected: true },
            { label: 'Active', value: 'active', selected: false },
            { label: 'Inactive', value: 'inactive', selected: false },
          ],
          labelWidth: 50,
        },
      ];
    };

    useEffect(() => {
      PermissionsService.redirectIfNoPermission(
        PermissionsPermissionsEnum.USERREAD,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Helmet>
          <title>
            {`CloudSort -
  ${configurationUtils.getPageTitle(false, 'STAFF')} ${
              queryString.parse(location.search)['page']
                ? '- Page ' +
                  queryString.parse(location.search)['page']
                : ''
            }`}
          </title>
        </Helmet>
        <Layout navCurrent='STAFF'>
          {showProgress && <ProgressIndicator />}
          <AddStaffDialog
            isOpen={showAddDialog}
            onAfterDialogClose={() => {
              setShowAddDialog(false);
            }}
            onAddStaff={onAddStaff}
            error={error}
          />
          {PermissionsService.hasPermission(
            PermissionsPermissionsEnum.USERWRITE,
          ) && (
            <Grid container className={classes.header}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title} variant={'h3'}>
                  {configurationUtils.getPageTitle()}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                className={classes.nonMobileAlignRight}
              >
                <Button
                  data-testid={'station-staff-add-button'}
                  variant='contained'
                  className={classes.containedButton}
                  fullWidth={false}
                  disabled={!!error || disabledAdd}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAddDialog(true);
                  }}
                >
                  <AddToPhotosOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Add {configurationUtils.getPageTitle(true, 'STAFF')}
                </Button>
              </Grid>
            </Grid>
          )}

          <PaginatedTable
            key={lastUpdated}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'station-staff'}
            fetch={fetchStaffData}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.STAFF}
            filterIsEnabled={true}
            fetchfilters={filters}
            sortableBy={['seconds_per_package', 'status']}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || undefined
            }
          />
        </Layout>
      </>
    );
  };

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
  })),
)(StationStaffComponent);
