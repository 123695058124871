import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../stationPlanner.styles';
import Layout from '../../layout/Layout';
import { Typography } from '../../primitives/index';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import { Button, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import colors from '../../../utils/colors';
import ShiftPlannerPresetsService from '../../../services/ShiftPlannerPresets.service';
import { ShiftPlanPreset } from 'cloudsort-client';
import PaginatedTable from '../../paginatedTable/PaginatedTable';
import { Column } from '../../../interfaces/components';
import { AuthRoutes } from '../../../interfaces/routes';
import PermissionsService from '../../../services/Permissions.service';
import AddEditPresetDialog from '../addEditPresetDialog/AddEditPresetDialog';
import ActionsDialog from '../../actionsDialog/ActionsDialog';
import { DialogInteraction } from '../StationPlanner';
import queryString from 'query-string';
//Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const COLUMNS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: 50,
    align: 'left',
    hide: true,
  },
  {
    id: 'name',
    label: 'Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'recurrence_formatted',
    label: 'Recurrrence',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'created_by_name',
    label: 'Created by',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'modified_on_formatted',
    label: 'Last updated',
    width: 'auto',
    align: 'center',
  },
];

const defaultShiftPlanDialogFields = {
  name: '',
  recurrence: 'none',
  recurrenceDays: '',
};

const ShiftPresets: React.FC<Props> = ({ classes, location }) => {
  const [showAddEditDialog, setShowAddEditDialog] =
    useState<boolean>(false);

  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [
    showDeleteConfirmationDialog,
    setShowDeleteConfirmationDialog,
  ] = useState<boolean>(false);

  const [dialogInteraction, setDialogInteraction] =
    useState<DialogInteraction>(DialogInteraction.ADD);
  const [editOrDeleteId, setEditOrDeleteId] = useState<number>();
  const [deleteName, setDeleteName] = useState<string>('');

  const [shiftPlanDialogFields, setShiftPlanDialogFields] = useState<{
    [name: string]: string;
  }>(defaultShiftPlanDialogFields);

  const [loadedPresets, setLoadedPresets] = useState<
    ShiftPlanPreset[]
  >([]);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [error, setError] = useState<string>();
  const [errorContext, setErrorContext] = useState<any>(); //interface not defined on the BE

  const [forceAction, setForceAction] = useState<boolean>(false);

  const getCapitalizedLabel = (str: string) => {
    return str.charAt(0) + str.slice(1).toLowerCase();
  };

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    setShowProgress(true);
    try {
      const response = await ShiftPlannerPresetsService.getAll({
        page: pageIndex,
        pageSize: rowsPerPage,
      });

      setLoadedPresets(
        response?.data.results.map((item: any) => {
          item.recurrence_formatted = item.recurrence
            ?.map(getCapitalizedLabel)
            ?.join(', ');

          item.modified_on_formatted = new Date(
            item.modified_on,
          ).toLocaleString();

          return item;
        }),
      );
      return response;
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    } finally {
      setShowProgress(false);
    }
  };

  const processAddEditDialog = async (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => {
    setError(undefined);
    setErrorContext(undefined);
    setShowProgress(true);
    if (params.interaction === DialogInteraction.ADD) {
      try {
        await ShiftPlannerPresetsService.createShiftPlanPreset(
          params.fields.name,
          params.fields.recurrenceDays
            ? (params.fields.recurrenceDays.split(',') as any)
            : undefined,
          forceAction,
        );

        setShowAddEditDialog(false);
        setLastUpdated(new Date().toISOString());
        setForceAction(false);
      } catch (error) {
        if (error.response.data.error_context.conflicts) {
          setErrorContext(error.response.data.error_context);
          //Next time 'save' is clicked force save
          setForceAction(true);
        } else {
          setError(error.response.data.descriptions.join(', '));
        }
      } finally {
        setShowProgress(false);
      }
    } else if (
      params.interaction === DialogInteraction.EDIT &&
      editOrDeleteId
    ) {
      const presetEdited = loadedPresets.find(
        (preset) => preset.id === editOrDeleteId,
      );

      if (presetEdited) {
        setShowProgress(true);

        try {
          await ShiftPlannerPresetsService.updateShiftPlanPreset(
            editOrDeleteId,
            {
              ...presetEdited,
              name: params.fields.name,
              recurrence: params.fields.recurrenceDays
                ? (params.fields.recurrenceDays.split(',') as any)
                : undefined,
            },

            forceAction,
          );
          setShowAddEditDialog(false);
          setLastUpdated(new Date().toISOString());
        } catch (error) {
          if (error.response.data.error_context.conflicts) {
            setErrorContext(error.response.data.error_context);
            //Next time 'save' is clicked force save
            setForceAction(true);
          } else {
            setError(error.response.data.descriptions.join(', '));
          }
        } finally {
          setShowProgress(false);
        }
      }
    }
  };

  const processDelete = async (id: number) => {
    setShowProgress(true);

    try {
      await ShiftPlannerPresetsService.deleteShiftPlanPreset(id);
      setLastUpdated(new Date().toISOString());
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    } finally {
      setShowProgress(false);
    }
  };

  const showEditDialog = (id: number) => {
    setEditOrDeleteId(id);
    setDialogInteraction(DialogInteraction.EDIT);
    setShowAddEditDialog(true);

    const presetEdited = loadedPresets.find(
      (preset) => preset.id === id,
    );

    if (presetEdited) {
      setShiftPlanDialogFields({
        name: presetEdited.name || '',
        recurrence: presetEdited.recurrence?.length
          ? 'weekly'
          : 'none',
        recurrenceDays: presetEdited.recurrence?.join(',') || '',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Cloudsort - Shift Presets List  ${
          queryString.parse(location.search)['page']
            ? '- Page ' + queryString.parse(location.search)['page']
            : ''
        }`}</title>
      </Helmet>
      <Layout navCurrent='SHIFT_PRESETS'>
        {showProgress && <ProgressIndicator />}
        <Grid
          container
          style={{
            borderBottom: `1px solid ${colors.veryLightGray}`,
            paddingBottom: 15,
            marginBottom: 30,
          }}
        >
          <Grid item sm={8}>
            <Typography
              className={classes.title}
              style={{
                fontSize: 24,
                borderBottom: 0,
                margin: 0,
                fontWeight: 'normal',
              }}
            >
              Shift Planner Preset List
            </Typography>
          </Grid>

          <Grid item sm={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              className={classes.buttonOutlined}
              onClick={() => {
                setDialogInteraction(DialogInteraction.ADD);
                setErrorContext(undefined);
                setShowAddEditDialog(true);
                setShiftPlanDialogFields({
                  ...defaultShiftPlanDialogFields,
                });
              }}
            >
              <AddToPhotosOutlinedIcon /> Add New Preset
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PaginatedTable
              title=''
              key={lastUpdated}
              columns={COLUMNS}
              dataTestIdPrefix={'shift-presets-lists'}
              fetch={fetch}
              rowsLoadDetailPages={true}
              actions={
                PermissionsService.hasPermission(
                  'STATION_PLANNER_WRITE',
                )
                  ? [
                      {
                        cellWidth: 50,
                        tableLabel: ' ',
                        columnLabel: (
                          <EditIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        qualifier: 'id',
                        callback: (id) => {
                          showEditDialog(id);
                        },
                      },
                      {
                        cellWidth: 50,
                        tableLabel: ' ',
                        columnLabel: (
                          <DeleteIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        qualifier: 'id',
                        callback: (id) => {
                          setEditOrDeleteId(id);
                          setDeleteName(
                            loadedPresets.find(
                              (preset) => preset.id === id,
                            )?.name || '',
                          );
                          setShowDeleteConfirmationDialog(true);
                        },
                      },
                    ]
                  : []
              }
              detailsPageBasePath={AuthRoutes.SHIFT_PRESETS.replace(
                '/',
                '',
              )}
            />
          </Grid>
        </Grid>
        <AddEditPresetDialog
          isOpen={showAddEditDialog}
          interaction={dialogInteraction}
          fields={shiftPlanDialogFields}
          onClose={() => {
            setForceAction(false);
            setShowAddEditDialog(false);
            setShiftPlanDialogFields(defaultShiftPlanDialogFields);
            setErrorContext(undefined);
            setError(undefined);
            setErrorContext(undefined);
          }}
          onSubmit={processAddEditDialog}
          conflicts={errorContext}
          error={error}
        />
        <ActionsDialog
          isOpen={showDeleteConfirmationDialog}
          title='Delete confirmation'
          description={`Are you sure you want to delete preset "${deleteName}"?`}
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            processDelete(editOrDeleteId!);
            setShowDeleteConfirmationDialog(false);
          }}
          secondaryActionLabel='Cancel'
          onSecondaryAction={() => {
            setShowDeleteConfirmationDialog(false);
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(ShiftPresets);
