import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import FormControl from '@material-ui/core/FormControl';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  useTheme,
  useMediaQuery,
  InputLabel,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import EditIcon from '@material-ui/icons/Edit';
import {
  Customer,
  DockDoorDetails,
  InboundLoad,
  InboundLoadLoadStatusEnum,
} from 'cloudsort-client';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { debounce } from 'lodash';
import CustomersService from '../../services/Customers.service';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import InboundLoadsService from '../../services/InboundLoads.service';
import DockDoorService from '../../services/DockDoors.service';
import clx from 'classnames';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  type: 'add' | 'edit';
  data?: InboundLoad;
}

const emptyInboundLoadData: InboundLoad = {
  name: '',
  expected_inbound_load_time: new Date().toISOString(),
  arrival_time: undefined,
  expected_containers_at_arrival: 0,
  expected_packages_at_arrival: 0,
  load_status: InboundLoadLoadStatusEnum.PLANNED,
  origin_station: '',
  owner: null,
  owner_full_name: undefined,
  trailer_id: null,
};

//Add or edit inbound load.
const InboundLoadDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  type,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState();
  const [inboundLoadParams, setInboundLoadParams] = useState(
    emptyInboundLoadData,
  );

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (type === 'edit' && data) {
      setInboundLoadParams({
        ...data,
      });
    }
    if (type === 'add') {
      setInboundLoadParams({
        ...emptyInboundLoadData,
      });
    }
  }, [data, type]);

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
    onAfterClose();
    setInboundLoadParams({ ...emptyInboundLoadData });
    updateParent();
  };

  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(undefined, inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const handleCreate = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.create(inboundLoadParams);
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.update(
        inboundLoadParams.id!,
        inboundLoadParams,
      );
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const asyncStyles = {
    ...asyncSelectStyles,
    menuPortal: (styles: any) => {
      return {
        ...styles,
        zIndex: 9999,
        marginLeft: 5,
      };
    },
    placeholder: (styles: any) => {
      return {
        ...styles,
        color: colors.lightGray,
        top: '65%',
      };
    },
    control: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: 'transparent !important',
        background: '#F1F1F1',
        padding: '12px 0 0 0',
        marginLeft: '-5px',
        boxShadow: 'none !important',
        outline: `none !important`,
        border: 0,
      };
    },
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {type === 'add' && (
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <AddToPhotosOutlinedIcon
                style={{
                  margin: '10px auto',
                  height: 'auto',
                  width: 40,
                  color: colors.darkGold,
                }}
              />

              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 18,
                  marginBottom: 20,
                }}
              >
                Add New {inboundLoadLabels.singular}
              </span>
            </div>
          </DialogTitle>
        )}
        {type === 'edit' && (
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <EditIcon
                style={{
                  margin: '10px auto',
                  height: 'auto',
                  width: 40,
                  color: colors.darkGold,
                }}
              />

              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 18,
                  marginBottom: 20,
                }}
              >
                {`Edit ${inboundLoadLabels.singular} ${
                  inboundLoadParams.name || inboundLoadParams.id
                }`}
              </span>
            </div>
          </DialogTitle>
        )}
        <DialogContent
          className={classes.dialogContent}
          style={{ padding: '0 40px' }}
        >
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <Grid container>
            <Grid
              item
              container
              xs={12}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    className={classes.datePicker}
                    style={{
                      width: '100%',
                      margin: 0,
                      marginLeft: 5,
                      marginTop: 5,
                    }}
                    disablePast={type === 'add'} //Disable past dates when adding new loads
                    variant='dialog'
                    format='MM/dd/yyyy hh:mmaaa'
                    margin='normal'
                    label='Scheduled Arrival'
                    value={
                      new Date(
                        inboundLoadParams.expected_inbound_load_time ||
                          '',
                      )
                    }
                    onChange={(e) => {
                      setInboundLoadParams({
                        ...inboundLoadParams,
                        expected_inbound_load_time:
                          e as unknown as string,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'edit-inbound-load-origin'}
              >
                <TextField
                  value={inboundLoadParams.origin_station}
                  autoFocus
                  label='Origin'
                  onChange={(e) => {
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      origin_station: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                  height: 50,
                }}
                data-testid='select-owner'
              >
                <InputLabel
                  className={classes.selectLabel}
                  style={{ fontSize: 12, top: '-18px' }}
                >
                  Owner
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  isClearable
                  cacheOptions
                  styles={asyncStyles}
                  loadOptions={(
                    inputValue: string,
                    callback: any,
                  ) => {
                    loadCustomerOptions(inputValue, callback);
                  }}
                  onChange={(option) => {
                    const selectedOwner = option as TypeAheadItem;
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      owner: selectedOwner
                        ? Number(selectedOwner.value)
                        : undefined,
                      owner_full_name: selectedOwner
                        ? selectedOwner.label
                        : undefined,
                    });
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  value={{
                    value: inboundLoadParams.owner
                      ? String(inboundLoadParams.owner)
                      : '',
                    label:
                      inboundLoadParams.owner_full_name ||
                      'Start typing...',
                  }}
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'edit-inbound-load-id'}
              >
                <TextField
                  value={inboundLoadParams.name}
                  autoFocus
                  label='Load ID'
                  onChange={(e) => {
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      name: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                  height: 50,
                }}
                data-testid='select-dock-door'
              >
                <InputLabel
                  className={classes.selectLabel}
                  style={{ fontSize: 12, top: '-18px' }}
                >
                  Unload Door
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  isClearable
                  cacheOptions
                  styles={asyncStyles}
                  loadOptions={(
                    inputValue: string,
                    callback: any,
                  ) => {
                    loadDDOptions(inputValue, callback);
                  }}
                  onChange={(option) => {
                    const selectedDockDoor = option as TypeAheadItem;
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      dockdoor: selectedDockDoor
                        ? Number(selectedDockDoor.value)
                        : undefined,
                      dockdoor_name: selectedDockDoor
                        ? selectedDockDoor.label
                        : '',
                    });
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  value={
                    inboundLoadParams.dockdoor
                      ? {
                          value: String(inboundLoadParams.dockdoor),
                          label:
                            inboundLoadParams.dockdoor_name || '',
                        }
                      : {
                          value: '',
                          label: 'Start Typing...',
                        }
                  }
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'edit-inbound-load-containers'}
              >
                <TextField
                  type='number'
                  value={
                    inboundLoadParams.expected_containers_at_arrival
                  }
                  autoFocus
                  label={configurationUtils.getPageTitle(
                    false,
                    'CONTAINER',
                  )}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      Number(e.target.value) >= 0
                    ) {
                      setInboundLoadParams({
                        ...inboundLoadParams,
                        expected_containers_at_arrival: e.target.value
                          ? Number(e.target.value)
                          : undefined,
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                  inputProps={{
                    min: '0',
                    step: '1',
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'edit-inbound-load-packages'}
              >
                <TextField
                  type='number'
                  value={
                    inboundLoadParams.expected_packages_at_arrival
                  }
                  autoFocus
                  label={configurationUtils.getPageTitle(
                    false,
                    'PACKAGE',
                  )}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      Number(e.target.value) >= 0
                    ) {
                      setInboundLoadParams({
                        ...inboundLoadParams,
                        expected_packages_at_arrival: Number(
                          e.target.value,
                        ),
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                  inputProps={{
                    min: '0',
                    step: '1',
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'edit-inbound-load-trailer_id'}
              >
                <TextField
                  value={inboundLoadParams.trailer_id || ''}
                  autoFocus
                  label='Trailer ID'
                  onChange={(e) => {
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      trailer_id: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: '14px 54px' }}>
          <MuiButton
            variant='outlined'
            className={clx(
              classes.outlinedButton,
              classes.cancelButton,
            )}
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            variant='outlined'
            className={clx(
              classes.outlinedButton,
              classes.saveButton,
            )}
            data-testid={'edit-inbound-load-save-button'}
            disabled={false}
            onClick={(e) => {
              if (type === 'edit') {
                handleUpdate();
              } else {
                handleCreate();
              }
            }}
          >
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
    },
    form: {
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    saveButton: {
      background: colors.darkGold,
      '&:hover': {
        background: colors.darkGold,
      },
    },
    cancelButton: {
      background: colors.white,
      color: colors.darkGold,
      borderColor: colors.darkGold,
      borderWidth: 2,
      '&:hover': {
        background: colors.white,
      },
    },
  })),
)(InboundLoadDialog);
