import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
} from 'cloudsort-client';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import { Column } from '../../interfaces/components';
import { Typography } from '../primitives/index';
import MuiButton from '@material-ui/core/Button';
import { Box, Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import clx from 'classnames';
import colors from '../../utils/colors';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import _ from 'lodash';
import PermissionsService from '../../services/Permissions.service';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import { Helmet } from 'react-helmet';
import WebhooksService from '../../services/Webhooks.service';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Webhooks: React.FC<Props> = ({ classes, location }) => {
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const COLUMNS_WEBHOOKS: Column[] = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'method',
      label: 'Method',
    },
    {
      id: 'url',
      label: 'Listener URL',
    },
    {
      id: 'organization_name',
      label: 'Organization',
    },
    {
      id: 'owner_name',
      label: 'Owner',
    },
    {
      id: 'events_count',
      label: 'Events',
    },
  ];

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    const owner = selectedFilters?.owner?.values[0]?.id as number;
    const organization = selectedFilters?.organization?.values[0]
      ?.id as number;
    const res = await WebhooksService.getAll({
      page: pageIndex,
      page_size: rowsPerPage,
      owner: owner,
      organization: organization,
    });

    for (const webhook of res.data.results as any[]) {
      webhook.events_count = webhook.filter_events.length;
      webhook.owner_name =
        webhook.owner_name === '' ? null : webhook.owner_name;
    }
    return res;
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.WEBHOOKREAD,
    );
    PermissionsService.redirectIfNoRoles([
      PermissionsRoleTypeEnum.ORGADMIN,
      PermissionsRoleTypeEnum.SUPERUSER,
    ]);
  }, []);

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const keyHash = _.isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      Webhooks ${
        queryString.parse(location.search)['page']
          ? '- Page ' + queryString.parse(location.search)['page']
          : ''
      }`}
        </title>
      </Helmet>
      <Layout>
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              Webhooks
            </Typography>
          </Grid>
          {PermissionsService.belongsToAnyOfRoles([
            PermissionsRoleTypeEnum.SUPERUSER,
          ]) && (
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <MuiButton
                variant='outlined'
                className={clx(
                  classes.outlinedButton,
                  classes.headerButtons,
                  classes.headerFilterButton,
                )}
                onClick={() => {
                  setShowFiltersDrawer(true);
                }}
              >
                <FilterListIcon />
              </MuiButton>
            </Grid>
          )}
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map(
                  (item: any) =>
                    !!item.values.length && (
                      <Box
                        key={item.key}
                        className={classes.filterBadge}
                      >
                        <b>{item.label}</b>
                        {item.values.map((value: any) => (
                          <span
                            style={{ marginLeft: '5px' }}
                            key={`${value.name || value.full_name}-${
                              value.id
                            }`}
                          >
                            {value.name || value.full_name}
                            <MuiButton
                              className={classes.filterBadgeButton}
                              onClick={() => {
                                setFilterToRemove({
                                  filterKey: item.key,
                                  filterValue: value.id,
                                });
                              }}
                            >
                              <ClearIcon />
                            </MuiButton>
                          </span>
                        ))}
                      </Box>
                    ),
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={'webhooks-table' + filterLastUpdate}
            tableKey={'table-webhooks' + filterLastUpdate}
            title={''}
            columns={COLUMNS_WEBHOOKS}
            dataTestIdPrefix={'webhooks'}
            fetch={fetch}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.WEBHOOKS}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      color: colors.black,
      backgroundColor: colors.veryLightGray,
      borderColor: colors.veryLightGray,
      minWidth: '40px',
      marginLeft: '20px',
    },
  })),
)(Webhooks);
