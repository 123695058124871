import React, { useEffect, useState } from 'react';

import { AuthRoutes } from '../../../interfaces/routes';
import { withStyles } from '@material-ui/core/styles';
import {
  CardContainerVariants,
  CardItemVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import { LoaderRing } from '../../primitives';
import StackedStatsOneRow from '../../stackedStatsOneRow/StackedStatsOneRow';
import useMountedStatus from '../../../hooks/useMountedStatus';

//Services
import StaffService from '../../../services/Staff.service';
import { CARD_LOAD_DELAY } from '../Dashboard';

interface Props {
  title: string;
  index: number;
  classes: { [key: string]: string };
}

const StaffStats: React.FC<Props> = ({ title, index }) => {
  const [data, setData] = useState<StatData>({});
  const [percentageActive, setPercentageActive] = useState<number>();

  const componentMounted = useMountedStatus();

  const fetchStats = async () => {
    const staffLabels: {
      key: any;
      label: any;
    }[] = [
      {
        key: 'TOTAL',
        label: 'Total',
      },
      {
        key: 'ACTIVE',
        label: 'Active',
      },
      {
        key: 'INACTIVE',
        label: 'Inactive',
      },
    ];

    let results: StatData = staffLabels.reduce((acc, step) => {
      return {
        ...acc,
        [step.key]: {
          label: step.label,
          value: 'pending',
        },
      };
    }, {});

    setData(results);

    setTimeout(async () => {
      // Fetch data
      let resTotal: number | 'error';
      let resActive: number | 'error';
      try {
        resTotal = (
          await StaffService.getAll({
            page: 1,
            pageSize: 1,
          })
        ).data.count;
      } catch (e) {
        resTotal = 'error';
      }

      try {
        resActive = (
          await StaffService.getAll({
            page: 1,
            pageSize: 1,
            active: true,
          })
        ).data.count;
      } catch (e) {
        resActive = 'error';
      }

      results = {
        TOTAL: {
          ...results['TOTAL'],
          value: resTotal.toString(),
          filter: {
            name: 'Status',
            value: 'All',
          },
        },
        ACTIVE: {
          ...results['ACTIVE'],
          value: resActive.toString(),
          filter: {
            name: 'Status',
            value: 'Active',
          },
        },
        INACTIVE: {
          ...results['INACTIVE'],
          value:
            resTotal === 'error' || resActive === 'error'
              ? 'error'
              : (resTotal - resActive).toString(),
          filter: {
            name: 'Status',
            value: 'Inactive',
          },
        },
      };

      if (componentMounted) {
        setData(results);

        if (resTotal !== 'error' && resActive !== 'error') {
          setPercentageActive(
            Math.round(
              Number(results['TOTAL'].value) !== 0
                ? Number(results['ACTIVE'].value) /
                    Number(results['TOTAL'].value)
                : 100,
            ),
          );
        }
      }
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <>
      <StackedStatsOneRow
        title={title}
        containerStyleVariant={CardContainerVariants.WHITE}
        itemsStyleVariant={CardItemVariants.WHITE_PAPER}
        dataTestId='dashboard-staff-stats'
        stats={data}
        linkBase={AuthRoutes.STAFF}
        percentage={percentageActive}
      />
    </>
  ) : (
    <LoaderRing />
  );
};

export default withStyles({})(StaffStats);
