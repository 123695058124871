import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import selectStyles from '../../select/select.styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';

import { LoadPointDetails } from 'cloudsort-client';
import {
  Grid,
  Paper,
  Slider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import colors from '../../../utils/colors';
import configurationUtils from '../../../utils/configurationUtils';

// Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface Props {
  classes: { [key: string]: string };
  open: boolean;
  onAfterDialogClose: () => void;
  onEdit: (
    updatedLoadpoints?: LoadPointDetailsWithDisplayName[],
  ) => void;
  loadpoints: LoadPointDetailsWithDisplayName[];
}

export interface LoadPointDetailsWithDisplayName
  extends LoadPointDetails {
  display_name: string;
}

export const roundToTwoDeciamals = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const percentageLabelFormat = (value: number): string => {
  return `${Math.round(value * 100)}%`;
};

export const normalizeLBValues = (
  loadpoints: LoadPointDetailsWithDisplayName[],
): LoadPointDetailsWithDisplayName[] => {
  //normalize values so their sum is 100
  const lpsCopy = [...loadpoints];

  //if one loadpoint has no LB value, set all vb values to same numbe
  if (lpsCopy.some((lp) => lp.load_balancing === null)) {
    lpsCopy.forEach((lp) => {
      lp.load_balancing = 1;
    });
  }

  const loadBalancingSum = lpsCopy.reduce(
    (acc, lp) => acc + (lp.load_balancing || 0),
    0,
  );

  lpsCopy.forEach((lp) => {
    lp.load_balancing = roundToTwoDeciamals(
      (lp.load_balancing || 0) / loadBalancingSum,
    );
  });

  return lpsCopy;
};

const EditLoadBalancingDialog: React.FC<Props> = ({
  classes,
  open,
  onAfterDialogClose,
  onEdit,
  loadpoints,
}) => {
  const afterDialogClose = () => {
    onAfterDialogClose();
  };

  const [editedLoadpoints, setEditedLoadpoints] =
    useState<LoadPointDetailsWithDisplayName[]>(loadpoints);
  const [totalVolume, setTotalVolume] = useState(1);

  const handleChange = (lpID: number, newValue: number) => {
    const lpsCopy = [...editedLoadpoints].map((lp) => {
      if (lp.id === lpID) {
        lp.load_balancing = newValue;
      }
      return lp;
    });

    let numberOfOtherLPs = lpsCopy.length - 1;

    let currentVolume = lpsCopy.reduce(
      (acc, lp) => acc + (lp.load_balancing || 0),
      0,
    );

    if (currentVolume > 1) {
      lpsCopy
        .filter((lp) => lp.id !== lpID)
        .sort(
          (a, b) => (a.load_balancing || 0) - (b.load_balancing || 0),
        )
        .forEach((lp) => {
          const overflow = roundToTwoDeciamals(
            (currentVolume - 1) / numberOfOtherLPs,
          );

          if ((lp.load_balancing || 0) < overflow) {
            currentVolume += overflow - (lp.load_balancing || 0);
            lp.load_balancing = 0;
            numberOfOtherLPs--;
          } else {
            lp.load_balancing = roundToTwoDeciamals(
              (lp.load_balancing || 0) - overflow,
            );
          }
        });
    }

    const newVolume = lpsCopy.reduce(
      (acc, lp) => acc + (lp.load_balancing || 0),
      0,
    );

    setTotalVolume(roundToTwoDeciamals(newVolume));
    setEditedLoadpoints(lpsCopy);
  };

  const changeComitted = () => {
    adjustFirstLP();
  };

  const adjustFirstLP = () => {
    if (totalVolume > 0.98 && totalVolume < 1.02) {
      const totalVolumeExcludingFirstLP = editedLoadpoints.reduce(
        (acc, lp, currentIndex) => {
          if (currentIndex > 0) return acc + (lp.load_balancing || 0);
          return acc;
        },
        0,
      );

      const lpsCopy = [...editedLoadpoints];
      lpsCopy[0].load_balancing = roundToTwoDeciamals(
        1 - totalVolumeExcludingFirstLP,
      );

      setEditedLoadpoints(lpsCopy);
      setTotalVolume(1);
    }
  };

  useEffect(() => {
    setEditedLoadpoints(normalizeLBValues(cloneDeep(loadpoints)));
  }, [loadpoints]);

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.dialogRoot }}
      open={open}
      TransitionComponent={Transition}
      onClose={() => {
        afterDialogClose();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DialogTitle
            style={{
              fontSize: 18,
              lineHeight: '21px',
            }}
          >
            Balancing
          </DialogTitle>
        </Grid>
        <Grid item xs={6}>
          <DialogActions style={{ padding: '16px 8px 16px 0' }}>
            <MuiButton
              data-testid={'edit-loadbalancing-cancel-button'}
              className={classes.goldButton}
              onClick={() => {
                afterDialogClose();
              }}
            >
              Cancel
            </MuiButton>

            {totalVolume === 1 ? (
              <MuiButton
                className={classes.goldButton}
                data-testid={'edit-loadbalancing-edit-button'}
                onClick={() => {
                  onEdit(editedLoadpoints);
                }}
                disabled={totalVolume !== 1}
              >
                Save
              </MuiButton>
            ) : (
              <Tooltip title='The total must be 100%'>
                <MuiButton
                  className={classes.goldButton}
                  data-testid={'edit-loadbalancing-edit-button'}
                  onClick={() => {}}
                  style={{
                    color: colors.lightGray,
                    cursor: 'not-allowed',
                  }}
                >
                  Save
                </MuiButton>
              </Tooltip>
            )}
          </DialogActions>
        </Grid>
      </Grid>

      <DialogContent className={classes.dialogRoot}>
        <Typography
          style={{
            fontSize: 13,
            lineHeight: '15px',
            marginBottom: 16,
            color: colors.gray,
          }}
        >
          {configurationUtils.getPageTitle(false, 'LOADPOINT')}
        </Typography>
        {editedLoadpoints.map((loadpoint) => (
          <Paper
            className={classes.paper}
            key={`loadbalancing-slider-${loadpoint.id}`}
          >
            <Grid
              container
              spacing={2}
              alignContent='center'
              justify='center'
              alignItems='center'
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: 14,
                    marginLeft: 5,
                  }}
                >
                  <FiberManualRecordIcon className={classes.icon} />
                  {loadpoint.display_name}
                </Typography>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Slider
                  className={classes.slider}
                  classes={{
                    root: classes.sliderRoot,
                    colorPrimary: classes.sliderPrimary,
                    track: classes.sliderTrack,
                    rail: classes.sliderTrackRail,
                    thumb: classes.sliderThumb,
                  }}
                  min={0}
                  max={1}
                  step={0.01}
                  value={loadpoint.load_balancing || 0}
                  onChange={(_, newValue) =>
                    handleChange(loadpoint.id!, newValue as number)
                  }
                  onChangeCommitted={() => changeComitted()}
                />
              </Grid>
              <Grid item>
                <Typography style={{ minWidth: 48 }}>
                  {percentageLabelFormat(
                    loadpoint.load_balancing || 0,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    ...selectStyles,
    detailInfoButton: {
      margin: '-50px 0 0 auto',
      '& svg': {
        marginLeft: 5,
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      overflowY: 'auto',
      width: 360,
      maxWidth: '100%',
    },
    paper: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      backgroundColor: colors.white,
      boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
      marginBottom: 16,
      padding: '10px 0',
    },
    icon: {
      color: colors.darkGold,
      paddingLeft: 10,
      marginBottom: -6,
      marginRight: 5,
    },
    slider: {
      marginTop: 10,
    },
    sliderRoot: {
      height: 9,
      borderRadius: 4.5,
    },
    sliderPrimary: {
      backgroundColor: colors.white,
    },
    sliderTrack: {
      backgroundColor: colors.gold,
      height: 4,
      borderRadius: 2,
    },
    sliderTrackRail: {
      backgroundColor: colors.veryLightGray,
      height: 4,
      borderRadius: 2,
    },
    sliderThumb: {
      backgroundColor: colors.gold,
      marginTop: -4,
    },
    goldButton: {
      textTransform: 'none',
      color: colors.darkGold,
      fontSize: 14,
    },
  })),
)(EditLoadBalancingDialog);
