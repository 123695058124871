import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterOption,
  filterObj,
  selectFilterByQueryString,
  getSelectedFilterOption,
} from '../paginatedTable/PaginatedTable';
import {
  AuthRoutes,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import queryString from 'query-string';
import Button from '@material-ui/core//Button';
import { Typography } from '../primitives';
import colors from '../../utils/colors';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import ErrorHandler from '../../utils/ErrorHandler';
import { common } from '../../utils/strings';
import { AlertBanner } from '../primitives/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Link,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import AsyncSelect from 'react-select/async';
import { components, ValueType } from 'react-select';
import { TypeAheadItem, Column } from '../../interfaces/components';
import { debounce } from 'lodash';
import AddStopsDialog from './AddStopDialog';

// Services
import StationsService from '../../services/Stations.service';
import LoadPointsService from '../../services/LoadPoints.service';
import LoadPlansService from '../../services/LoadPlans.service';
import RoutesService from '../../services/Routes.service';
import SchemesService from '../../services/Schemes.service';
import FmcService from '../../services/Fmc.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

// Types
import { AxiosError } from 'axios';
import {
  Route2StationDetails,
  Scheme,
  LoadPoint,
  Location,
  PermissionsPermissionsEnum,
  APIExceptionErrorCodeEnum,
} from 'cloudsort-client';
import browserHistory from '../../utils/browserHistory';
import { Helmet } from 'react-helmet';
import { noOptionsMessage } from '../asyncSelect/utils';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';

const DEFAULT_ALIGN = 'left';

interface Props extends RouteComponentProps {
  match: any;
  classes: { [key: string]: string };
}

interface StopsData {
  lpData?: LoadPoint[];
  routesData?: Route2StationDetails[];
  schemesData: Scheme[];
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon fontSize={'small'} />
      </components.DropdownIndicator>
    )
  );
};

const Stops: React.FC<Props> = ({ match, classes, location }) => {
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    filterObj[]
  >([]);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [addDialogActionType, setAddDialogActionType] = useState<
    'add' | 'edit'
  >('add');
  const [showSchemeConflictDialog, setShowSchemeConflictDialog] =
    useState<boolean>(false);
  const [stopToEdit, setStopToEdit] = useState<any>();
  const [selectedSchemesToAdd, setSelectedSchemesToAdd] =
    useState<ValueType<TypeAheadItem>>();
  const [selectedSchemesToDelete, setSelectedSchemesToDelete] =
    useState<Scheme[]>([]);

  const [locations, setLocation] = useState<Location[]>([]);
  const [stopsData, setStopsData] = useState<Map<number, StopsData>>(
    new Map<number, StopsData>(),
  );
  const [activeLoadPlan, setActiveLoadPlan] = useState<number>();

  const [stationModules, setStationModules] = useState<string[]>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [deleteName, setDeleteName] = useState<string>();
  const [editingStopData, setEditingStopData] = useState<any>();

  const urlParams: any = useParams();

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('lg'));

  // Get labels on each render cycle
  let COLUMNS_STOPS: Column[] = [
    {
      id: 'name',
      label: configurationUtils.getPageTitle(true, 'STOP'),
      width: isSmScreen ? 200 : 300,
      align: DEFAULT_ALIGN,
    },
  ];

  if (configurationUtils.isModuleActive('LOADPOINT'))
    COLUMNS_STOPS.push({
      id: 'load_points_count',
      label: 'Load Points',
      width: isSmScreen ? 120 : 150,
      align: DEFAULT_ALIGN,
    });

  if (configurationUtils.isModuleActive('ROUTE'))
    COLUMNS_STOPS.push({
      id: 'routes_count',
      label: configurationUtils.getPageTitle(false, 'ROUTE'),
      width: isSmScreen ? 120 : 150,
      align: DEFAULT_ALIGN,
    });

  COLUMNS_STOPS.push(
    {
      id: 'fmcs_label',
      label: 'Carrier',
      align: DEFAULT_ALIGN,
      width: isSmScreen ? 120 : 150,
    },
    {
      id: 'schemes_count',
      label: 'Schemes',
      align: DEFAULT_ALIGN,
      width: isSmScreen ? 120 : 150,
    },
    {
      id: 'geo_location',
      label: 'GPS Coordinates',
      width: isSmScreen ? 200 : 300,
      align: DEFAULT_ALIGN,
    },
  );

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const filters = async () => {
    try {
      let routeOptions = [
        { label: 'All', value: 'ALL', selected: true },
      ];
      if (stationModules?.includes('ROUTE')) {
        // Route
        const routesData = (
          await RoutesService.getAll({
            // Keep MAX_PAGE_SIZE because will be replaced with filters drawer later
            pageSize: MAX_PAGE_SIZE,
          })
        ).data.results;

        routeOptions = [
          { label: 'All', value: 'ALL', selected: true },
          ...routesData.map((route) => {
            return {
              label: route.name || '',
              value: route.id?.toString(),
              selected: false,
            } as filterOption;
          }),
        ];

        const routeQS = queryString.parse(location.search)['Route'];
        if (routeQS) {
          selectFilterByQueryString(routeQS, routeOptions);
        }
      }

      // FMC
      const fmcOptions = [
        { label: 'All', value: 'ALL', selected: true },
        ...(await FmcService.getAll()).data.results.map((fmc) => {
          return {
            label: fmc.full_name,
            value: fmc.full_name,
            selected: false,
          } as filterOption;
        }),
      ];

      const fmcQS = queryString.parse(location.search)['Carrier'];
      if (fmcQS) {
        selectFilterByQueryString(fmcQS, fmcOptions);
      }

      const res: filterObj[] = [
        {
          name: 'Carrier',
          options: fmcOptions,
          labelWidth: 50,
        },
      ];
      if (stationModules?.includes('ROUTE')) {
        res.push({
          name: configurationUtils.getPageTitle(true, 'ROUTE'),
          options: routeOptions,
          labelWidth: 45,
        });
      }
      return res;
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const exportStops = async () => {
    let fmc, route;
    fmc =
      getSelectedFilterOption(selectedFilterOptions, 'Carrier') ||
      undefined;
    if (fmc === 'ALL') {
      fmc = undefined;
    }
    route = getSelectedFilterOption(
      selectedFilterOptions,
      configurationUtils.getPageTitle(true, 'ROUTE'),
    );
    if (route === 'ALL') {
      route = undefined;
    }
    try {
      setShowProgress(true);
      const res = await StationsService.locationsExport({
        route: route ? parseInt(route) : undefined,
        fmc: fmc,
      });
      const blob = new Blob([res!.data], {
        type: 'text/csv;charset=utf-8',
      });
      const fileNamStr = `${stopsLabels.plural}-export.csv`;
      saveAs(blob, fileNamStr);
    } finally {
      setShowProgress(false);
    }
  };

  const fetchLocations = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    let fmc, route;
    if (filterOptions) {
      fmc = getSelectedFilterOption(filterOptions, 'Carrier');
      if (fmc === 'ALL') {
        fmc = undefined;
      }

      if (stationModules?.includes('ROUTE')) {
        route = getSelectedFilterOption(
          filterOptions,
          configurationUtils.getPageTitle(true, 'ROUTE'),
        );
        if (route === 'ALL') {
          route = undefined;
        }
      } else route = undefined;
    }

    const res = await StationsService.getLocations({
      page: pageIndex,
      pageSize: rowsPerPage,
      search: filterByString,
      sortBy: sortedBy as any,
      route: route ? parseInt(route) : undefined,
      fmc,
      stationOwner: EphemeralStateService.getMyStationId(),
    });

    setLocation(res.data.results);

    res.data.results.forEach((stop: any) => {
      stop.rules = common.emptyValue;
      stop.fmcs_label = stop.fmcs.length
        ? stop.fmcs.join(', ')
        : common.emptyValue;
      stop.geo_location =
        stop.geo_location[0] && stop.geo_location[1]
          ? `${stop.geo_location[0]}, ${stop.geo_location[1]}`
          : common.emptyValue;
    });
    //Save filters
    setSelectedFilterOptions(filterOptions || []);
    return res;
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const getActiveLoadPlan = async () => {
    try {
      const {
        data: { results },
      } = await LoadPlansService.getAll({ active: true });
      if (results.length) {
        setActiveLoadPlan(results[0].id);
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const loadExpandedContentData = async (
    params: any,
    onAfterLoadExpandedContentData: () => void,
  ) => {
    try {
      if (!stopsData.get(params.id)) {
        let lpData, routesData;

        if (configurationUtils.isModuleActive('LOADPOINT'))
          lpData = await LoadPointsService.getAll({
            targetStation: params.id,
            pageSize: MAX_PAGE_SIZE,
            isActive: true,
          });
        if (configurationUtils.isModuleActive('ROUTE'))
          routesData = await RoutesService.getRouteToStationsById(
            params.id,
            MAX_PAGE_SIZE,
          );
        const schemesData = await SchemesService.getAll({
          station: params.id,
          stationOwner: EphemeralStateService.getMyStationId(),
          isActive: true,
        });
        const mapState = new Map(stopsData);
        mapState.set(params.id, {
          lpData: configurationUtils.isModuleActive('LOADPOINT')
            ? lpData?.data.results
            : undefined,
          routesData: configurationUtils.isModuleActive('ROUTE')
            ? routesData?.data.results
            : undefined,
          schemesData: schemesData.data.results,
        });
        setStopsData(mapState);
        return mapState;
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      onAfterLoadExpandedContentData();
    }
  };

  const onUpdateParent = () => {
    setLastUpdated(new Date().toISOString());
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
  };

  const onCloseEditDestinationSchemesDialog = () => {
    setStopToEdit(undefined);
    setSelectedSchemesToAdd(undefined);
    setSelectedSchemesToDelete([]);
  };

  const renderEditDestinationSchemes = () => {
    return (
      <Dialog disableBackdropClick open={!!stopToEdit}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <SettingsIcon
            style={{
              color: colors.darkGold,
              width: '48px',
              height: 'auto',
            }}
          />
          <br />
          Edit Destination Schemes
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Box pb={3}>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                isMulti
                styles={{
                  control: (styles: any, state: any) => {
                    return {
                      ...styles,
                      backgroundColor: '#F2F2F2',
                      padding: '7px 12px',
                      border: 'none',
                      borderRadius: 4,
                      boxShadow: 'none !important',
                      fontSize: '1rem',
                      minWidth: 410,
                    };
                  },
                  menu: (styles: any) => {
                    return {
                      ...styles,
                      top: 0,
                      transform: 'translateY(-8px)',
                    };
                  },
                  menuPortal: (styles, state) => {
                    return {
                      ...styles,
                      zIndex: 1301,
                    };
                  },
                  indicatorSeparator: (styles: any) => {
                    return {
                      ...styles,
                      display: 'none',
                    };
                  },
                  indicatorsContainer: (styles: any) => {
                    return {
                      ...styles,
                    };
                  },
                  option: (styles: any) => {
                    return {
                      ...styles,
                      backgroundColor: colors.white,
                      padding: '14px 0 14px 20px',
                      color: '#D39C6E',
                      '&:hover': {
                        backgroundColor: 'rgba(225, 187, 155, 0.2)',
                        cursor: 'pointer',
                      },
                    };
                  },
                  placeholder: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.gray,
                      fontSize: 16,
                      letterSpacing: 0,
                      lineHeight: '16px',
                    };
                  },
                  dropdownIndicator: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.dark,
                      padding: 0,
                      opacity: 0.7,
                    };
                  },
                }}
                menuPortalTarget={document.body}
                loadOptions={loadOptions}
                onChange={(option: ValueType<TypeAheadItem>) => {
                  setSelectedSchemesToAdd(option);
                }}
                noOptionsMessage={noOptionsMessage}
                placeholder={'Search Schemes'}
                components={{
                  DropdownIndicator,
                  MenuList: (menuListprops: any) => {
                    return (
                      <Fragment>
                        {menuListprops.children.length && (
                          <Box
                            p={2}
                            pt={1}
                            pb={0}
                            style={{ color: '#A3A3A3', fontSize: 12 }}
                          >
                            RESULTS
                          </Box>
                        )}
                        <components.MenuList
                          {...menuListprops}
                          className={classes.menuList}
                        >
                          <div>{menuListprops.children}</div>
                        </components.MenuList>
                      </Fragment>
                    );
                  },
                  Option: (optionProps: any) => {
                    return (
                      <components.Option {...optionProps}>
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            style={{ paddingRight: '10px' }}
                          >
                            {optionProps.children}
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            style={{ paddingRight: '10px' }}
                          >
                            <span
                              style={{
                                color: colors.lightGray,
                              }}
                            >
                              {optionProps.value.used_on
                                ? 'Used on ' +
                                  optionProps.value.used_on
                                : 'Unused'}{' '}
                            </span>
                          </Grid>
                        </Grid>
                      </components.Option>
                    );
                  },
                }}
              />
            </Box>
          </Grid>
          <Box pl={1} pr={1}>
            <Grid container>
              <Grid item xs={12}>
                {!!stopToEdit?.data?.schemesData?.length && (
                  <Box pb={2}>
                    <Typography className={classes.darkGold}>
                      Schemes
                    </Typography>
                  </Box>
                )}
              </Grid>
              {stopToEdit &&
                stopToEdit?.data?.schemesData?.map(
                  (scheme: Scheme) => {
                    return (
                      <Grid
                        container
                        direction={'row'}
                        wrap={'nowrap'}
                        key={scheme.id}
                      >
                        <Grid item xs={5}>
                          {scheme.name}
                        </Grid>
                        <Grid item>
                          <Box
                            pl={2}
                            pb={2}
                            className={classes.link}
                            onClick={() => {
                              selectedSchemesToDelete.push(scheme);
                              setSelectedSchemesToDelete([
                                ...selectedSchemesToDelete,
                              ]);
                              const filteredSchemesData =
                                stopToEdit.data.schemesData.filter(
                                  (sc: Scheme) => sc.id !== scheme.id,
                                );
                              stopToEdit.data.schemesData =
                                filteredSchemesData;
                              setStopToEdit({ ...stopToEdit });
                            }}
                          >
                            Remove
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  },
                )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box pl={1} pr={1}>
            <Button
              className={classes.darkGold}
              onClick={() => {
                onCloseEditDestinationSchemesDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.darkGold}
              onClick={() => {
                editSchemes();
              }}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const stopSchemesIds = stopToEdit.data.schemesData.map(
        (scheme: Scheme) => scheme.id,
      );
      SchemesService.getByName(inputValue, activeLoadPlan)
        .then((data) => {
          callback(
            data.data.results
              .filter((result) => {
                return !stopSchemesIds.includes(result.id);
              })
              .map((result) => {
                return {
                  value: result,
                  label: result.name,
                };
              }),
          );
        })
        .catch((e) => {
          handleError(e);
          setStopToEdit(undefined);
        });
    }, 500),
    [stopToEdit],
  );

  const editSchemes = async (conflictResolve?: boolean) => {
    setShowProgress(true);
    try {
      const schemesToAdd = (
        selectedSchemesToAdd as unknown as any[]
      )?.map((el: any) => el.value);
      const calls: Promise<any>[] = [];
      if (selectedSchemesToDelete?.length) {
        selectedSchemesToDelete.forEach(async (scheme) => {
          const station2Scheme =
            await SchemesService.getStation2Scheme({
              station: stopToEdit.id,
              scheme: scheme.id,
            });
          if (station2Scheme) {
            calls.push(
              SchemesService.deleteStation2Scheme(
                station2Scheme?.data?.results[0]?.id!,
              ),
            );
          }
        });
      }
      const conflictsAll: any = [];
      if (schemesToAdd?.length) {
        for (const scheme of schemesToAdd) {
          try {
            await SchemesService.addStation2Scheme(
              {
                station: stopToEdit.id,
                scheme: scheme.id,
              },
              conflictResolve,
            );
          } catch (e) {
            if (
              e.response?.data?.error_code ===
              APIExceptionErrorCodeEnum.ResourceConflict
            ) {
              e.response?.data?.error_context.conflicts.forEach(
                (conflict: any) => {
                  conflictsAll.push({
                    label: conflict.scheme_name,
                    value: {
                      id: conflict.scheme_id,
                      name: conflict.scheme_name,
                      station_id: conflict.station_id,
                      station_name: conflict.station_name,
                    },
                  });
                },
              );
            }
          } finally {
            if (conflictsAll.length) {
              setTimeout(() => {
                setSelectedSchemesToAdd(conflictsAll);
                setShowSchemeConflictDialog(true);
                setShowProgress(false);
              }, 250);
            }
          }
        }
      }

      await Promise.all(calls);
      if (!conflictsAll.length) {
        onCloseEditDestinationSchemesDialog();
        browserHistory.replace({
          pathname: `${AuthRoutes.STOP}/default-open/${Array.from(
            stopsData.keys(),
          ).join(
            '-',
          )}?${stationDashboardUrlId}=${EphemeralStateService.getMyStationId()}`,
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
      onCloseEditDestinationSchemesDialog();
    }
  };

  const preLoadStops = async () => {
    setShowProgress(true);
    setTimeout(async () => {
      const preloadIds = urlParams.defaultOpenIds
        ?.split('-')
        .map((id: string) => parseInt(id));
      const res: Map<number, StopsData>[] = await Promise.all(
        preloadIds?.map(async (id: number) => {
          return await loadExpandedContentData({ id }, () => {});
        }),
      );
      let mapState: Map<number, StopsData> = new Map();
      res.forEach((r) => {
        if (r) {
          Array.from(r.keys()).forEach((key) => {
            mapState.set(key, r.get(key)!);
          });
        }
      });
      if (mapState.size) {
        setStopsData(mapState);
      }
      setShowProgress(false);
    }, 250);
  };

  const renderTableValues = (keyPrefix: string, data?: any[]) => {
    return (
      <TableCell align={DEFAULT_ALIGN}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data?.length
            ? data.map((dataEl) => {
                if (keyPrefix === 'lp') {
                  return (
                    <span key={`${keyPrefix}-${dataEl.id}`}>
                      {[
                        dataEl.area_name,
                        dataEl.zone_name,
                        dataEl.name,
                      ].join(' ')}
                    </span>
                  );
                }

                if (keyPrefix === 'scheme') {
                  return (
                    <span key={`${keyPrefix}-${dataEl.id}`}>
                      <Link
                        style={{ color: colors.dark }}
                        to={`${AuthRoutes.SCHEME}/${dataEl.id}`}
                      >
                        {dataEl.name}
                      </Link>
                    </span>
                  );
                }

                return (
                  <span key={`${keyPrefix}-${dataEl.id}`}>
                    {dataEl.route_name || dataEl.name}
                  </span>
                );
              })
            : common.emptyValue}
        </div>
      </TableCell>
    );
  };

  const expandedContentRenderer = (data: any) => {
    const stopData = stopsData.get(data.id);
    const tableHeaders = [
      '',
      '',
      stopData?.lpData ? 'Load Points' : '',
      stopData?.routesData
        ? configurationUtils.getPageTitle(false, 'ROUTE')
        : '',
      '',
      'Schemes',
      '',
    ];
    const getWidth = (index: number) => {
      if (index === 0) {
        return 48;
      } else if (index === 1) {
        return isSmScreen ? 200 : 300;
      } else if (index === 6) {
        return 'auto';
      }
      return isSmScreen ? 120 : 150;
    };
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((tH, index) => {
                return (
                  <TableCell
                    key={`ep-table-head-${index}`}
                    align={DEFAULT_ALIGN}
                    style={{
                      ...{
                        width: getWidth(index),
                      },
                    }}
                  >
                    {tH}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography style={{ fontSize: 12 }}>
                  {`${data.address || ''}, ${data.state || ''}`}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {data.zipcode}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {data.city}
                </Typography>
                <br />
                <Typography style={{ fontSize: 12 }}>
                  {data.external_id}
                </Typography>
              </TableCell>
              <TableCell />

              {stopData?.lpData ? (
                renderTableValues('lp', stopData?.lpData)
              ) : (
                <TableCell> </TableCell>
              )}
              {stopData?.routesData ? (
                renderTableValues('routes', stopData?.routesData)
              ) : (
                <TableCell> </TableCell>
              )}
              <TableCell />
              {renderTableValues('scheme', stopData?.schemesData)}
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell style={{ padding: '0 16px 5px 16px' }}>
                {PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                ) && (
                  <Typography
                    className={classes.link}
                    style={{ fontWeight: 'bold' }}
                    onClick={() => {
                      setStopToEdit({
                        data: { ...stopData },
                        id: data.id,
                      });
                    }}
                  >
                    Edit Schemes
                  </Typography>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const processDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      await StationsService.deleteStation(deleteId!);
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.STATIONREAD,
    );

    setStationModules(
      configurationUtils
        .getConfigArray({
          config:
            EphemeralStateService.getMyStationConfiguratation()
              .GENERAL.MODULES,
          filter: { key: 'active', value: true },
        })
        .map((item: any) => item.id),
    );

    getActiveLoadPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlParams?.defaultOpenIds) {
      preLoadStops();
      setTimeout(() => {
        window.history.replaceState(
          null,
          '',
          AuthRoutes.STOP +
            '?stationId=' +
            EphemeralStateService.getMyStationId(),
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'STOP')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STOP'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          title={'Please confirm the changes'}
          msg={
            <>
              {(selectedSchemesToAdd as unknown as any[])?.map(
                (scheme, index) => {
                  const stopName = locations.find(
                    (loc) => loc?.id === stopToEdit?.id,
                  )?.name;
                  const { label, value } = scheme;
                  return (
                    <p
                      style={{ textAlign: 'center' }}
                      key={`conflict-scheme-label-${index}`}
                    >
                      You are about to move{' '}
                      <span className={classes.darkGold}>
                        {label}
                      </span>{' '}
                      from{' '}
                      <span className={classes.darkGold}>
                        {value.station_name}
                      </span>{' '}
                      to{' '}
                      <span className={classes.darkGold}>
                        {stopName}
                      </span>
                    </p>
                  );
                },
              )}
            </>
          }
          primaryActionLabel={'Confirm'}
          onPrimaryAction={() => {
            editSchemes(true);
          }}
          onCancel={() => {
            setError(undefined);
            setShowSchemeConflictDialog(false);
          }}
          isOpen={showSchemeConflictDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-confirmation-dialog'}
          title={
            'Delete ' + configurationUtils.getPageTitle(true, 'STOP')
          }
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'STOP',
          )} ${deleteName}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />
        <AddStopsDialog
          isOpen={showAddDialog}
          onAfterClose={onAfterDialogClose}
          updateParent={onUpdateParent}
          type={addDialogActionType}
          data={editingStopData}
        />
        {renderEditDestinationSchemes()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            <Button
              disabled={!!error}
              style={{ marginBottom: '10px' }}
              variant='outlined'
              className={classes.buttonOutlined}
              data-testid={'stops-loadplans-button'}
              onClick={() => {
                browserHistory.push(`${AuthRoutes.LOADPLAN}_list`);
              }}
            >
              <AppsIcon />
              {configurationUtils.getPageTitle(false, 'LOADPLAN')}
            </Button>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.STATIONWRITE,
            ) && (
              <Button
                disabled={!!error}
                variant='outlined'
                className={classes.buttonFilled}
                data-testid={'stops-add-button'}
                onClick={(e) => {
                  e.preventDefault();
                  setAddDialogActionType('add');
                  setShowAddDialog(true);
                }}
                style={{ marginLeft: '10px', marginBottom: '10px' }}
              >
                <AddToPhotosOutlinedIcon />
                Create {stopsLabels.plural}
              </Button>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.STATIONREAD,
            ) && (
              <Button
                variant='outlined'
                className={classes.outlinedButton}
                style={{ marginLeft: '10px', marginBottom: '10px' }}
                onClick={() => {
                  exportStops();
                }}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Batch Export CSVs
              </Button>
            )}
          </Grid>
        </Grid>

        {stationModules && ( //make sure modules config is loaded
          <PaginatedTable
            key={lastUpdated}
            title=''
            columns={COLUMNS_STOPS}
            dataTestIdPrefix={'stops'}
            fetch={fetchLocations}
            rowsLoadDetailPages={false}
            isExpandedPanel
            expandedContentRenderer={expandedContentRenderer}
            loadExpandedContentData={loadExpandedContentData}
            detailsPageBasePath={AuthRoutes.STOP}
            filterIsEnabled={true}
            fetchfilters={filters}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || undefined
            }
            sortableBy={['routes_count']}
            filterByString={true}
            filterByStringPlaceholder={`Search ${stopsLabels.plural}`}
            actions={[
              {
                cellWidth: 70,
                tableLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.STATIONWRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.STATIONWRITE,
                ) ? (
                  <>
                    <EditIcon />
                  </>
                ) : undefined,
                qualifier: 'id',
                callback: (id: number) => {
                  const stop = locations.find(
                    (loc) => loc?.id === id,
                  );

                  setError(undefined);
                  setEditingStopData(stop);
                  setAddDialogActionType('edit');
                  setShowAddDialog(true);
                },
              },
              {
                cellWidth: 70,
                tableLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.STATIONWRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.STATIONWRITE,
                ) ? (
                  <>
                    <CancelIcon />
                  </>
                ) : undefined,
                qualifier: 'id',
                callback: (id: number) => {
                  const stopName = locations.find(
                    (loc) => loc?.id === id,
                  )?.name as string;
                  setDeleteName(stopName);
                  setDeleteId(id);
                  setError(undefined);
                  setShowDeleteConfirmation(true);
                },
              },
            ]}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...sectionPageBaseStyle,
    ...detailsPageStyles,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    buttonOutlined: {
      color: colors.darkGold,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    inputPadding: {
      paddingLeft: 5,
      paddingRigth: 5,
    },
    darkGold: {
      color: colors.darkGold,
    },
    dialogContent: {
      maxWidth: 460,
    },
  })),
)(Stops);
