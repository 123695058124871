import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AlertBanner, Box, Typography } from '../primitives/index';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { Grid, Theme } from '@material-ui/core';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import MuiButton from '@material-ui/core/Button';
import clx from 'classnames';
import Button from '@material-ui/core//Button';
import {
  InboundLoad,
  InboundLoadLoadStatusEnum,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import PermissionsService from '../../services/Permissions.service';
import InboundLoadsService from '../../services/InboundLoads.service';
import { AuthRoutes } from '../../interfaces/routes';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import _ from 'lodash';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import InboundLoadDialog from './InboundLoadDialog';
import { renderTimeString } from '../DetailsPagesFunctions';
import { format } from 'date-fns';
import { formatStatusEnum } from './utils/misc';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const InboundLoads: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [inboundLoadsData, setInboundLoadsData] = useState<
    InboundLoad[]
  >([]);
  const [showAddInboundLoadDialog, setShowAddInboundLoadDialog] =
    useState(false);
  const [selectedInboundLoadData, setSelectedInboundLoadData] =
    useState<InboundLoad>();
  const [inboundLoadDialogType, setInboundLoadDialogType] = useState<
    'add' | 'edit'
  >('add');
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const COLUMNS: Column[] = [
    {
      id: 'expected_inbound_load_time_formatted',
      label: 'Scheduled Arrival',
      useCustomComponent: true,
    },
    {
      id: 'name',
      label: 'Load ID',
    },
    {
      id: 'origin_station',
      label: 'Origin',
    },
    {
      id: 'trailer_id',
      label: 'Trailer ID',
    },
    {
      id: 'owner_full_name',
      label: 'Owner',
    },
    {
      id: 'expected_containers_at_arrival',
      label: configurationUtils.getPageTitle(false, 'CONTAINER'),
    },
    {
      id: 'expected_packages_at_arrival',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
    },
    {
      id: 'dockdoor_name',
      label: 'Unload Door',
    },
    {
      id: 'load_status_formatted',
      label: 'Status',
      useCustomComponent: true,
    },
  ];

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.INBOUNDLOADREAD,
    );
  }, []);

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const keyHash = _.isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  const formatStatus = (status: InboundLoadLoadStatusEnum) => {
    const formattedStatus = formatStatusEnum(status);

    let statusColor = colors.black;
    if (status === InboundLoadLoadStatusEnum.FINISHED) {
      statusColor = colors.darkGold;
    }
    if (status === InboundLoadLoadStatusEnum.ARRIVED) {
      statusColor = colors.green;
    }

    return (
      <Typography style={{ color: statusColor }}>
        {formattedStatus}
      </Typography>
    );
  };

  const formatScheduledArrival = (scheduledArrival: string) => {
    const isToday =
      new Date().toDateString() ===
      new Date(scheduledArrival).toDateString();
    if (isToday) {
      return (
        <Typography>{renderTimeString(scheduledArrival)}</Typography>
      );
    } else {
      return (
        <Typography>
          {`${renderTimeString(scheduledArrival)} on ${format(
            new Date(scheduledArrival),
            'MM/dd/yyyy',
          )}`}
        </Typography>
      );
    }
  };

  const fetch = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    try {
      setShowProgress(true);
      let hidePending = undefined;
      let loadStatus = undefined;
      const owner: number[] = [];
      if (selectedFilters!.hidePending?.values?.length > 0) {
        hidePending =
          selectedFilters!.hidePending.values[0].id === 'yes';
      }
      if (selectedFilters!.inboundLoadStatus?.values?.length > 0) {
        loadStatus = selectedFilters!.inboundLoadStatus.values[0]
          .id as InboundLoadLoadStatusEnum;
      }
      if (selectedFilters!.owner?.values?.length > 0) {
        for (let selectedOwner of selectedFilters!.owner.values) {
          owner.push(selectedOwner.id as number);
        }
      }
      const res = await InboundLoadsService.getAll({
        page: pageIndex,
        pageSize: rowsPerPage,
        loadName: filterByString,
        hidePending: hidePending,
        inboundLoadStatus: loadStatus,
        owner: owner.length > 0 ? owner : undefined,
      });
      res.data.results.forEach((load: any) => {
        load.expected_inbound_load_time_formatted =
          formatScheduledArrival(load.expected_inbound_load_time);
        load.load_status_formatted = formatStatus(load.load_status);
      });
      setInboundLoadsData(res.data.results);
      return res;
    } catch (e) {
      setError(await ErrorHandler.getLabel(e));
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'INBOUND_LOAD')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='INBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <InboundLoadDialog
          isOpen={showAddInboundLoadDialog}
          onAfterClose={() => setShowAddInboundLoadDialog(false)}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
          type={inboundLoadDialogType}
          data={selectedInboundLoadData}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {inboundLoadLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.INBOUNDLOADWRITE,
            ) && (
              <Button
                variant='outlined'
                className={classes.buttonFilled}
                onClick={() => {
                  setInboundLoadDialogType('add');
                  setShowAddInboundLoadDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon />
                Add {inboundLoadLabels.singular}
              </Button>
            )}
            <MuiButton
              variant='outlined'
              className={clx(
                classes.outlinedButton,
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </MuiButton>
          </Grid>
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map(
                  (item: any) =>
                    !!item.values.length && (
                      <Box
                        key={item.key}
                        className={classes.filterBadge}
                      >
                        <b>{item.label}</b>
                        {item.values.map((value: any) => (
                          <span
                            style={{ marginLeft: '5px' }}
                            key={`${value.name || value.full_name}-${
                              value.id
                            }`}
                          >
                            {value.name || value.full_name}
                            <MuiButton
                              className={classes.filterBadgeButton}
                              onClick={() => {
                                setFilterToRemove({
                                  filterKey: item.key,
                                  filterValue: value.id,
                                });
                              }}
                            >
                              <ClearIcon />
                            </MuiButton>
                          </span>
                        ))}
                      </Box>
                    ),
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={lastUpdated + filterLastUpdate}
            tableKey={'inboundLoads' + filterLastUpdate}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'inbound-loads-list'}
            fetch={fetch}
            filterByString={true}
            filterByStringPlaceholder={'Search by Load ID'}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.INBOUND_LOAD.replace(
              '/',
              '',
            )}
            actions={[
              {
                cellWidth: 70,
                tableLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.INBOUNDLOADWRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.INBOUNDLOADWRITE,
                ) ? (
                  <EditIcon />
                ) : undefined,
                qualifier: 'id',
                callback: (id: number) => {
                  const inboundLoad = inboundLoadsData.find(
                    (load) => load.id === id,
                  );
                  setInboundLoadDialogType('edit');
                  setSelectedInboundLoadData(inboundLoad);
                  setShowAddInboundLoadDialog(true);
                },
              },
            ]}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      color: colors.black,
      backgroundColor: colors.veryLightGray,
      borderColor: colors.veryLightGray,
      minWidth: '40px',
      marginLeft: '20px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      marginBottom: 10,
      height: 42,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
  })),
)(InboundLoads);
