import React, { Fragment, useEffect, useState } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import Box from '@material-ui/core/Box';
import { AlertBanner, Typography } from '../primitives';
import { createStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import configurationUtils from '../../utils/configurationUtils';
import Button from '@material-ui/core/Button';
import UploadCSVDialog from '../uploadCSVDialog/UploadCSVDialog';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ErrorHandler from '../../utils/ErrorHandler';

// Types
import { Column } from '../../interfaces/components';
import { PermissionsPermissionsEnum } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import LoadPlansService from '../../services/LoadPlans.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CancelIcon from '@material-ui/icons/Cancel';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
}

const LoaplansList: React.FC<Props> = ({ classes }) => {
  const [showUploadDialog, setShowUploadDialog] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [error, setError] = useState<string>();

  // Get labels on each render cycle
  const COLUMNS_LOADPLANS_LIST: Column[] = [
    {
      id: 'id',
      label: 'Load Plan ID',
      width: 150,
      align: 'left',
    },
    {
      id: 'total_zipcodes',
      label: 'Total ZIP Codes',
      width: 150,
      align: 'left',
    },
    {
      id: 'total_stops',
      label: `Total ${configurationUtils.getPageTitle(
        false,
        'STOP',
      )}`,
      width: 150,
      align: 'left',
    },
    {
      id: 'active',
      label: 'Status',
      width: 100,
      align: 'left',
    },
    {
      id: 'imported_by',
      label: 'Imported by',
      width: 'auto',
      align: 'left',
    },
    { id: 'is_inactive_loadplan', label: '', hide: true },
  ];

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const fetchTableData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await LoadPlansService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
    });

    res.data.results.map((lp: any) => {
      lp.is_inactive_loadplan = lp.active ? false : true;

      lp.active = lp.active
        ? `<span style="color: #D39C6E">Active</span>`
        : 'Inactive';

      lp.imported_by =
        lp.created_by_name +
        ' at ' +
        new Date(lp.created_on).toLocaleString();

      return lp;
    });
    return res;
  };

  const processDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      await LoadPlansService.delete(deleteId!);
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e);
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.LOADPLANREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'LOADPLAN')}`}
        </title>
      </Helmet>
      <Layout navCurrent='LOAD_PLAN'>
        <UploadCSVDialog
          isOpen={showUploadDialog}
          onAfterClose={() => {
            setShowUploadDialog(false);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        <ConfirmationDialog
          data-testid={'delete-confirmation-dialog'}
          title={
            'Delete ' +
            configurationUtils.getPageTitle(true, 'LOADPLAN')
          }
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'LOADPLAN',
          )} ${deleteId}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />
        <Fragment>
          <Grid container className={classes.header}>
            <Grid item xs={6}>
              <Box
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box>
                  <Link to={AuthRoutes.STOP} className={classes.back}>
                    <ArrowLeftIcon />
                    <Typography>Back</Typography>
                  </Link>
                </Box>

                <Typography className={classes.title} variant={'h3'}>
                  {configurationUtils.getPageTitle(false, 'LOADPLAN')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.nonMobileAlignRight}>
              {PermissionsService.hasPermission(
                PermissionsPermissionsEnum.LOADPLANWRITE,
              ) && (
                <Button
                  data-testid={'add-new-load-plan-dialog-btn'}
                  variant='contained'
                  className={classes.containedButton}
                  style={{
                    marginLeft: 15,
                    height: inXsScreen ? 'fit-content' : 42,
                  }}
                  onClick={() => {
                    setShowUploadDialog(true);
                  }}
                >
                  <AddToPhotosOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Add New Load Plan
                </Button>
              )}
            </Grid>
          </Grid>

          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <PaginatedTable
            disableUpdateQueryStringUrl
            key={lastUpdated}
            title={''}
            columns={COLUMNS_LOADPLANS_LIST}
            dataTestIdPrefix={'load-plans'}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.LOADPLAN}
            fetch={fetchTableData}
            actions={[
              {
                tableLabel: PermissionsService.hasPermission(
                  'LOADPLAN_WRITE',
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  'LOADPLAN_WRITE',
                ) ? (
                  <>
                    <CancelIcon /> Remove
                  </>
                ) : undefined,
                qualifier: 'is_inactive_loadplan',
                callback: (id: number) => {
                  setDeleteId(id);
                  setError(undefined);
                  setShowDeleteConfirmation(true);
                },
              },
            ]}
          />
        </Fragment>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(LoaplansList);
