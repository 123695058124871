import {
  createStyles,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { Position } from 'react-flow-renderer';
import flowColors from '../flowColors';
import flowStyles from '../flowStyles';
import { NodeData } from '../interfaces';
import Handles from './parts/Handles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getFormattedTimeLeft } from '../flowUtils';
import { ReturnDataStation } from 'cloudsort-client';
import {
  renderVolumeString,
  renderWeightString,
} from '../../../DetailsPagesFunctions';

interface Props {
  classes: { [key: string]: string };
  data: NodeData;
  sourcePosition: Position;
  targetPosition: Position;
}

const SecondaryAreaNode = ({
  classes,
  data,
  sourcePosition,
  targetPosition,
}: Props) => {
  const { isThroughputMode } = data;

  const renderLoadPoint = (
    loadpoint: any,
    index: number,
    loadpointMeta: ReturnDataStation,
  ) => {
    const dispatchTime = loadpointMeta?.dispatch_time
      ? getFormattedTimeLeft(loadpointMeta.dispatch_time)
      : '';

    return (
      <div
        className={
          classes.loadPoint + ' ' + loadpoint.additionalCssClass
        }
        key={`loadpoint-${index}`}
      >
        <h4>{loadpoint.name}</h4>
        <div className={classes.flexContainerCentered}>
          <p>
            {isThroughputMode
              ? loadpoint.exitPackages
              : loadpoint.packages}{' '}
            P
          </p>
        </div>
        {dispatchTime && !isThroughputMode && (
          <Tooltip
            arrow
            classes={{
              tooltipArrow: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            placement='bottom'
            title={dispatchTime}
          >
            <div className={classes.dispatchTimeContainer}>
              {dispatchTime}
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.nodeContainer}
        style={{ width: data.width, height: data.height }}
      >
        <div className={classes.title}>
          {data.name}
          <span>{data.packages} P</span>
          {isThroughputMode && (
            <span>
              {data.exitPackages} P
              <ExitToAppIcon
                style={{ width: 16, height: 16, marginBottom: -3 }}
              />
            </span>
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            {Object.values(data.zones!).map((zone: any, index) => (
              <div className={classes.zone} key={`zone-${index}`}>
                <div className={classes.title}>{zone.name}</div>
                <div
                  className={classes.loadPoints}
                  style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
                >
                  {Object.values(zone.loadpoints!).map(
                    (loadpoint: any, index) => {
                      const {
                        loadpointMeta,
                      }: { loadpointMeta: ReturnDataStation } =
                        loadpoint?.meta;
                      return loadpointMeta && !isThroughputMode ? (
                        <Tooltip
                          arrow
                          classes={{
                            tooltipArrow: classes.tooltip,
                            arrow: classes.tooltipArrow,
                          }}
                          PopperProps={{
                            popperOptions: {
                              modifiers: {
                                offset: {
                                  enabled: true,
                                  offset: '0, -5px',
                                },
                              },
                            },
                          }}
                          title={
                            <>
                              <Typography
                                variant='h4'
                                className={classes.tooltipTitle}
                              >
                                Current Volume
                              </Typography>
                              <Typography
                                variant='body1'
                                className={classes.tooltipValue}
                              >
                                {renderVolumeString(
                                  loadpointMeta.volume,
                                )}
                              </Typography>
                              <Typography
                                variant='h4'
                                className={classes.tooltipTitle}
                              >
                                Current Weight
                              </Typography>
                              <Typography
                                variant='body1'
                                className={classes.tooltipValue}
                              >
                                {renderWeightString(
                                  loadpointMeta.weight,
                                )}
                              </Typography>
                            </>
                          }
                          placement='left'
                          key={`loadpoint-tooltip-${index}`}
                        >
                          {renderLoadPoint(
                            loadpoint,
                            index,
                            loadpointMeta,
                          )}
                        </Tooltip>
                      ) : (
                        renderLoadPoint(
                          loadpoint,
                          index,
                          loadpointMeta,
                        )
                      );
                    },
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Handles
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...flowStyles,
    nodeContainer: {
      padding: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'strech',
      flexDirection: 'column',
      color: 'white',
      width: 'auto',
      height: 'auto',
      background: flowColors.secondaryBackground,
      border: `1px solid ${flowColors.secondaryBorder}`,
      borderRadius: 5,
    },
    title: {
      color: flowColors.black,
      fontSize: '15px',
      lineHeight: '15px',
      marginBottom: 5,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& span': {
        fontSize: '11px',
        float: 'right',
      },
    },
    content: {
      flexGrow: 1,
    },
    contentInner: {
      height: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridRowGap: 5,
      gridColumnGap: 5,
      gridAutoRows: '1fr',
    },

    zone: {
      padding: 5,
      background: flowColors.white,
      border: `1px solid ${flowColors.primaryBorder}`,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
    },

    loadPoints: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridRowGap: 5,
      gridColumnGap: 5,
      gridAutoRows: '1fr',
    },

    flexContainerCentered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',

      '& p': {
        padding: 0,
        margin: 0,
        color: flowColors.white,
        fontSize: 10,
      },
    },

    dispatchTimeContainer: {
      position: 'absolute',
      bottom: 3,
      left: 3,
      right: 3,
      fontSize: 10,
      padding: 3,
      textAlign: 'center',
      borderRadius: 3,
      background: flowColors.secondaryBackground,
      color: flowColors.black,
      overflow: 'hidden',
    },
  })),
)(SecondaryAreaNode);
