import React, { useEffect, useState, useMemo } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AuthRoutes, NonAuthRoutes } from '../../interfaces/routes';
import { Typography, AlertBanner } from '../primitives';
import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';
import Box from '@material-ui/core/Box';
import {
  humanReadableNull,
  renderVolumeString,
  renderWeightString,
  renderContainerLocationString,
} from '../DetailsPagesFunctions';

import {
  ContainerDetails,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import ProcessSteps from '../eventsSnapshot/processSteps/ProcessSteps';
import browserHistory from '../../utils/browserHistory';

import detailsPageStyles from '../commonStyles/detailsPage.style';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import colors from '../../utils/colors';
import { COLUMNS_PACKAGES } from '../TableColumns';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

// Services
import ContainersService from '../../services/Containers.service';
import PackagesService from '../../services/Packages.service';
import EventsService from '../../services/Events.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import utils from '../../utils/stats';
import qrCodeUtils from '../../utils/qrCode';
import { Helmet } from 'react-helmet';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { Grid } from '@material-ui/core';
import { formatLabel, formatContainer } from './Containers';
import { labelConfigOpt } from '../../services/utils/labelDefinitions';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const ContainerDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const thisContainersId = match.params.id;
  const [container, setContainer] = useState<ContainerDetails>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'package_count',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'volume',
      label: 'Volume',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
  ];

  const isBOLActive = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.LOAD_OPS.bol_active;
  }, []);

  const isBOLInherit = useMemo(() => {
    return (
      EphemeralStateService.getMyStationConfiguratation().GENERAL
        .BEHAVIORS.LOAD_OPS.bol_mode === 'INHERIT_ONLY'
    );
  }, []);

  const fetchContainerDetails = async () => {
    setShowProgress(true);
    try {
      const { data } = await ContainersService.getById(
        thisContainersId,
      );
      setContainer(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const fetchPackages = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await PackagesService.getAll({
      page: pageIndex,
      container: [thisContainersId],
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });
    res.data.results.forEach((pck: any) => {
      pck.process_step_time = utils.getLongestDwellTimeLabel(
        pck.process_step_time,
      );
    });
    return res;
  };

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      parentContainer: container!.id,
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });

    return res;
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      container: thisContainersId,
      pageSize: rowsPerPage,
    });
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (container?.id) {
        const labelOTConfig = await labelConfigOpt();
        qrCodeUtils.download(
          await ContainersService.getLabel(
            container.id,
            labelOTConfig.size,
            labelOTConfig.dpi,
          ),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const updateContainerDetailsData = async (
    newData: ContainerDetails,
  ) => {
    setShowProgress(true);
    try {
      const { data } = await ContainersService.update(
        container?.id!,
        newData,
      );
      setContainer(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.CONTAINERREAD,
    );

    if (!container) {
      fetchContainerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      ${configurationUtils.getPageTitle(true, 'CONTAINER')} ${
            container?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='CONTAINER'>
        {showProgress && <ProgressIndicator />}

        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        {!container?.is_parent_container && (
          <Box mb={3}>
            <Box
              onClick={() => {
                browserHistory.replace(NonAuthRoutes.REFRESH_ROUTE);
                setTimeout(() => {
                  browserHistory.goBack();
                }, 0);
              }}
              className={classes.back}
            >
              <ArrowLeftIcon />
              <Typography>Back</Typography>
            </Box>
          </Box>
        )}

        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
          data-testid='container-detail-view'
        >
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              style={{ marginBottom: 16 }}
            >
              {`${
                container?.is_parent_container
                  ? configurationUtils.getModuleLabel(
                      true,
                      'GROUP_CONTAINER',
                    )
                  : configurationUtils.getPageTitle(true, 'CONTAINER')
              } ${container?.id || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.CONTAINERLABELREAD,
            ) && (
              <Button
                variant='outlined'
                className={classes.outlinedButton}
                disabled={!container}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onGetQrCick();
                }}
              >
                <FilterCenterFocusIcon style={{ marginRight: 10 }} />
                label
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={8}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid='container-detail-details'
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Status'
                value={
                  container?.last_process_step
                    ? formatLabel(container.last_process_step || '')
                    : common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={configurationUtils.getPageTitle(true, 'ROUTE')}
                value={
                  container?.route ? (
                    <Link
                      to={`${AuthRoutes.ROUTE}/${container?.route}`}
                    >
                      {humanReadableNull(container?.route_name)}
                    </Link>
                  ) : (
                    'N/A'
                  )
                }
              />
              {container?.is_parent_container && (
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={`${configurationUtils.getModuleLabel(
                    false,
                    'GROUP_CONTAINER',
                  )} ${configurationUtils.getPageTitle(
                    false,
                    'CONTAINER',
                  )}`}
                  value={
                    container.children_count || common.emptyValue
                  }
                />
              )}
              {isBOLActive && (
                <SingleDetail
                  inline
                  valueStyle={{ color: colors.darkGold }}
                  onEdit={
                    !isBOLInherit
                      ? (value) => {
                          updateContainerDetailsData({
                            bol: value,
                          });
                        }
                      : undefined
                  }
                  label={'BOL'}
                  value={container?.bol || common.emptyValue}
                />
              )}
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={'Tracking Number'}
                value={
                  container?.tracking_number || common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={configurationUtils.getPageTitle(
                  true,
                  'INBOUND_LOAD',
                )}
                value={
                  container?.inbound ? (
                    <Link
                      to={`${AuthRoutes.INBOUND_LOAD}/${container.inbound_load}`}
                      style={{
                        color: colors.darkGold,
                      }}
                    >
                      {humanReadableNull(container.inbound_load_name)}
                    </Link>
                  ) : (
                    common.emptyValue
                  )
                }
              />
              {container?.area &&
                container.area.area_type !== 'PRIMARY' && (
                  <>
                    <SingleDetail
                      inline={true}
                      valueStyle={{ color: colors.darkGold }}
                      label='Location'
                      value={renderContainerLocationString(
                        container?.station_name,
                        container?.area?.name,
                        container?.zone?.name,
                        container?.container_status,
                        container?.load_point?.name,
                      )}
                    />

                    <SingleDetail
                      inline={true}
                      valueStyle={{ color: colors.darkGold }}
                      label='Target Load'
                      value={
                        container?.next_steps?.outbound_load?.id ? (
                          <Link
                            to={`${AuthRoutes.OUTBOUND_LOAD}/${container.next_steps.outbound_load.id}`}
                          >
                            {humanReadableNull(
                              container?.next_steps?.outbound_load
                                ?.name,
                            )}
                          </Link>
                        ) : (
                          'N/A'
                        )
                      }
                    />
                    <SingleDetail
                      inline={true}
                      valueStyle={{ color: colors.darkGold }}
                      label={
                        'Target ' +
                        configurationUtils.getPageTitle(true, 'STOP')
                      }
                      value={
                        container?.route ? (
                          <Link
                            to={`${AuthRoutes.STOP}/?search=${container?.stop_name}`}
                          >
                            {humanReadableNull(container?.stop_name)}
                          </Link>
                        ) : (
                          'N/A'
                        )
                      }
                    />
                  </>
                )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.paper}
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
              }}
            >
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                <Typography
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Target Load Time
                </Typography>
                <Typography
                  variant='h6'
                  className={classes.statsValue}
                >
                  {container?.next_steps?.outbound_load?.load_time
                    ? new Date(
                        container?.next_steps?.outbound_load?.load_time,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue}
                </Typography>
              </div>
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                <Typography
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Time Left
                </Typography>
                <Typography
                  variant='h6'
                  className={classes.statsValue}
                >
                  {container?.next_steps?.outbound_load?.load_time &&
                  new Date() <
                    new Date(
                      container?.next_steps?.outbound_load?.load_time,
                    )
                    ? utils.getLongestDwellTimeLabel(
                        container?.next_steps?.outbound_load
                          ?.load_time,
                      )
                    : common.emptyValue}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>

        {!container?.is_parent_container && (
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ marginRight: 18, padding: 16 }}
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  {configurationUtils.getPageTitle(true, 'CONTAINER')}{' '}
                  Details
                </Typography>
                <SingleDetail
                  label={`Containerized ${configurationUtils.getPageTitle(
                    false,
                    'PACKAGE',
                  )}`}
                  value={humanReadableNull(container?.package_count)}
                  valueStyle={{ color: colors.darkGold }}
                />
                <SingleDetail
                  label='Volume'
                  value={renderVolumeString(container?.volume)}
                />
                <SingleDetail
                  label='Weight'
                  value={renderWeightString(container?.weight)}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ padding: 16 }}
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  {configurationUtils.getPageTitle(true, 'CONTAINER')}{' '}
                  Details
                </Typography>
                <SingleDetail
                  label='Target Staging Area'
                  value={humanReadableNull(
                    container?.next_steps?.staging_area?.name,
                  )}
                  valueStyle={{ color: colors.darkGold }}
                />
                <SingleDetail
                  label='Type'
                  value={container?.outbound ? 'Outbound' : 'Inbound'}
                />
                <SingleDetail
                  label='Format'
                  value={formatLabel(
                    humanReadableNull(container?.container_type),
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        )}

        <Typography variant='h3'>Event Checkpoints</Typography>
        {container?.process_steps_history ? (
          <ProcessSteps
            stepsData={container?.process_steps_history}
            dataTestPrefix='container'
          />
        ) : (
          <AlertBanner
            className={classes.banner}
            severity='info'
            alertTitle={
              'There is no information to display at the moment'
            }
          />
        )}

        <br />
        <EventsPaginatedTable
          dataTestIdPrefix={'container-details-events'}
          fetch={fetchEvents}
        />

        <br />
        <PaginatedTable
          disableUpdateQueryStringUrl
          title={configurationUtils.getPageTitle(false, 'PACKAGE')}
          columns={COLUMNS_PACKAGES}
          dataTestIdPrefix={'container-details-packages'}
          fetch={fetchPackages}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.PACKAGE}
        />

        {container?.is_parent_container && (
          <>
            <br />
            <PaginatedTable
              disableUpdateQueryStringUrl
              title={`${configurationUtils.getPageTitle(
                false,
                'CONTAINER',
              )}`}
              columns={COLUMNS_CONTAINERS}
              dataTestIdPrefix={'nested-container-details-containers'}
              fetch={fetchContainers}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.CONTAINER}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    padding2em: {
      padding: '2em',
    },
    flexRowEvenSpread: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    half: {
      width: '50%',
      borderRight: '1px solid #fafafa',
    },
    containerDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableHeader: {
      background: '#fafafa',
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      cursor: 'pointer',
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(ContainerDetailsComponent);
