import React, { useState, Fragment, useEffect } from 'react';
import {
  AlertBanner,
  Button,
  Typography,
  Container,
  CustomTextField,
} from '../primitives';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PasswordRequirementsIndicator, {
  validatePassword,
} from '../passwordRequirementsIndicator/PasswordRequirementsIndicator';
import { NonAuthRoutes } from '../../interfaces/routes';
import generatePassword from 'generate-password';
import styles from '../logIn/logIn.styles';
import browserHistory from '../../utils/browserHistory';
import { Paper } from '@material-ui/core';

//Services
import AuthService from '../../services/Auth.service';

//Icons
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: any;
}
const SetPassword: React.FC<Props> = ({ classes, match }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [usingGeneratedPassword, setUsingGeneratedPassword] =
    useState<boolean>(true);
  const [firstPasswordUpdate, setfirstPasswordUpdate] =
    useState<boolean>(true);
  const [passwordFieldType, setPasswordFieldType] =
    useState<string>('text');
  const [passwordFieldType2, setPasswordFieldType2] =
    useState<string>('password');

  const canSubmitForm =
    password1 &&
    validatePassword(password1) &&
    password1 === password2 &&
    !showProgress;

  const onPasswordChange = (e: React.BaseSyntheticEvent) => {
    if (firstPasswordUpdate) {
      setUsingGeneratedPassword(false);
      setPassword2('');
      setPasswordFieldType('password');
      setfirstPasswordUpdate(false);
    }
    e.persist();
    if (error) {
      setError('');
    }

    setPassword1(e.target.value);
  };

  const onButtonClick = async () => {
    setShowProgress(true);
    try {
      if (password1) {
        //Update password
        await AuthService.changePassword({
          token: match.params.code,
          password: password1,
        });
      }
      //redirect to login
      browserHistory.push(NonAuthRoutes.LOGIN + '/password-set');
    } catch (e) {
      setError(
        `The link you are using is invalid or has expired. Click the button below and we'll send you a new one.`,
      );
    } finally {
      setShowProgress(false);
    }
  };

  const onResendLinkClick = async () => {
    await AuthService.resetPassword(match.params.email);

    //redirect to login
    browserHistory.push(NonAuthRoutes.LOGIN + '/link-resent');
  };

  useEffect(() => {
    if (AuthService.isLoggedIn()) AuthService.logOut({});

    const password = generatePassword.generate({
      length: 16,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true,
    });

    setPassword1(password);
    setPassword2(password);
    if (!validatePassword(password)) {
      //Setting this to false will show the password requirements list.
      setUsingGeneratedPassword(false);
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`CloudSort - Set Password`}</title>
      </Helmet>
      <div
        className={classes.halfWidth + ' ' + classes.loginBackground}
      >
        <a href='/'>
          <img
            className={classes.loginLogo}
            alt='CloudSort'
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/loginAssets/logo.svg`}
          />
        </a>
      </div>
      <div
        className={classes.halfWidth}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showProgress && (
          <ProgressIndicator data-testid='progress-indicator-component' />
        )}
        <Container
          component='main'
          maxWidth='xs'
          data-testid='set-password--component'
          style={{
            marginLeft: '20px',
          }}
        >
          <div className={classes.paper}>
            <Typography variant='h2' className={classes.loginHeading}>
              Set a new password
            </Typography>
            {usingGeneratedPassword && (
              <Paper className={classes.alertBox}>
                <CheckCircleOutlineOutlinedIcon />
                <p>
                  Enter a new password or use the strong randomly
                  generated password we created for you
                </p>
              </Paper>
            )}
            <form className={classes.form} noValidate>
              <CustomTextField
                inputProps={{
                  'data-testid': 'set-password-password1',
                  spellCheck: false,
                }}
                type={passwordFieldType}
                required
                label='New Password'
                name='password'
                autoComplete='password'
                autoFocus
                value={password1}
                onClick={(e: React.BaseSyntheticEvent) => {
                  usingGeneratedPassword && e.target.select();
                }}
                onChange={onPasswordChange}
                labelButton={
                  passwordFieldType === 'text' ? 'Hide' : 'Show'
                }
                labelButtonAction={() => {
                  passwordFieldType === 'text'
                    ? setPasswordFieldType('password')
                    : setPasswordFieldType('text');
                }}
              />
              {usingGeneratedPassword &&
                validatePassword(password1) && (
                  <p className={classes.strongPassword}>Strong</p>
                )}
              {!usingGeneratedPassword && (
                <Fragment>
                  <CustomTextField
                    inputProps={{
                      'data-testid': 'set-password-password2',
                    }}
                    buttonProps={{
                      tabIndex: 1,
                    }}
                    type={passwordFieldType2}
                    required
                    label='Confirm Password'
                    name='confirmPassword'
                    autoComplete='password'
                    value={password2}
                    onChange={(e: React.BaseSyntheticEvent) => {
                      e.persist();
                      if (error) {
                        setError('');
                      }
                      setPassword2(e.target.value);
                    }}
                    labelButton={
                      passwordFieldType2 === 'text' ? 'Hide' : 'Show'
                    }
                    labelButtonAction={() => {
                      passwordFieldType2 === 'text'
                        ? setPasswordFieldType2('password')
                        : setPasswordFieldType2('text');
                    }}
                  />
                  <PasswordRequirementsIndicator
                    password1={password1}
                    password2={password2}
                    checkForMatchingPasswords={true}
                  ></PasswordRequirementsIndicator>
                </Fragment>
              )}
              {error && (
                <AlertBanner
                  severity='error'
                  data-testid='set-password-error'
                  alertMsg={error}
                  alertTitle={'Error'}
                  buttonLabel={'Resend link'}
                  buttonAction={onResendLinkClick}
                />
              )}
              <Button
                data-testid='set-password-button'
                disabled={!canSubmitForm}
                className={classes.submitButton}
                onClick={(e) => {
                  e.preventDefault();
                  onButtonClick();
                }}
              >
                Set Password
              </Button>
              {canSubmitForm && (
                <div className={classes.pressEnter}>
                  or press enter <KeyboardReturnIcon />
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(SetPassword);
