import { StatData } from '../../../stackedStats/StackedStats';
import { NetworkStatsErrors } from '../../../../hooks/useNetworkStatsErrorRegistry';
import { isEmpty } from 'lodash';

const setErrorByPS = (
  results: StatData,
  key: string,
  label: string,
) => {
  const data = {
    ...results,
    [key]: {
      label: label,
      value: 'error',
    },
  };

  return data;
};

const setErrorsOnData = (
  dataObj: StatData,
  networkStatsErrors: NetworkStatsErrors,
) => {
  if (!isEmpty(dataObj)) {
    for (let errorKey in networkStatsErrors) {
      if (networkStatsErrors[errorKey]) {
        dataObj[errorKey].value = 'error';
      }
    }
    return dataObj;
  }
  return {};
};

export { setErrorByPS, setErrorsOnData };
