import React, { Dispatch } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Switch, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import classNames from 'classnames';
import { INTERACTION } from '../../Configuration';
import Highlighter from 'react-highlight-words';
import configPageStyles from '../../../commonStyles/configPage.style';

interface Props {
  classes: { [key: string]: string };
  data: any;
  title: string;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  highlight?: string[];
}

const SingleToggleBehavior: React.FC<Props> = ({
  classes,
  data,
  title,
  interaction,
  setPartialData,
  highlight = [],
}) => {
  return (
    <>
      <Grid item sm={12}>
        <Box>
          <Box
            className={classNames(classes.moduleTitle, classes.pLeft)}
            mb={1}
            mt={2}
          >
            <Highlighter
              highlightClassName={classes.searchResultsMark}
              searchWords={highlight}
              autoEscape={true}
              textToHighlight={title}
            />
          </Box>

          <Box mt={2}>
            <Grid container item sm={12} className={classes.row}>
              <Grid
                item
                sm={11}
                xs={9}
                className={classNames(classes.text, classes.pLeft)}
              >
                <Highlighter
                  highlightClassName={classes.searchResultsMark}
                  searchWords={highlight}
                  autoEscape={true}
                  textToHighlight='Active'
                />
              </Grid>
              <Grid
                item
                sm={1}
                xs={3}
                className={classes.muiSwitchHoler}
              >
                <Switch
                  data-testid={`${title}-switch`}
                  color='default'
                  size='small'
                  disabled={interaction === INTERACTION.READ}
                  className={
                    data.active
                      ? classes.muiSwitch
                      : classes.muiSwitchOff
                  }
                  checked={data.active}
                  onChange={() => {
                    data.active = !data.active;
                    setPartialData({
                      ...data,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(SingleToggleBehavior);
