import React, { Fragment, useEffect, useState } from 'react';
import { MenuList, MenuItem, Box } from '@material-ui/core';
import styles from './navigation.styles';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import browserHistory from '../../utils/browserHistory';
import { useParams } from 'react-router-dom';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';

// Redux
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  selectConfig,
  updateNavEnd,
} from '../../redux/slices/configSlice';

// Mui components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';

// Services
import OrganizationsService from '../../services/Organizations.service';
import StationsService from '../../services/Stations.service';
import PermissionsService from '../../services/Permissions.service';

// Types
import {
  Organization,
  Station,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import LocalStorageService from '../../services/LocalStorage.service';

interface Props {
  classes: { [key: string]: string };
  currentPage?: string;
  basePath: string;
  redirectToActiveStation?: boolean;
}

enum Mode {
  CLOUDSORT,
  ORGANIZATION,
  STATION,
}

const Navigation: React.FC<Props> = ({
  classes,
  basePath,
  redirectToActiveStation = false,
}) => {
  const urlParams: any = useParams();
  const [mode, setMode] = useState<Mode>();
  const [orgData, setOrgData] = useState<Organization[]>([]);
  const [stations, setStations] = useState<Station[]>();
  const [csEpExpanded, setCsEpExpanded] = useState<boolean>(true);
  const [stationsByOrg, setStationsByOrg] = useState<
    Map<number, Station[]>
  >(new Map<number, Station[]>());
  const [selectedStationId, setSelectedStationId] = useState<
    number | undefined
  >(urlParams.stationId ? parseInt(urlParams.stationId) : undefined);
  const [selectedOrgId, setSelectedOrgId] = useState<
    number | undefined
  >(urlParams.orgId ? parseInt(urlParams.orgId) : undefined);
  const [orgEpExpanded, setOrgEpExpanded] = useState<
    Map<number, boolean>
  >(new Map<number, boolean>());

  const dispatch = useAppDispatch();
  const configReduxStore = useAppSelector(selectConfig);

  const expansionPanelClasses = {
    root: classes.expansionPanelRoot,
    disabled: classes.expansionPanelDisabled,
    expanded: classes.expansionPanelExpanded,
  };

  const expansionPanelSummaryClasses = {
    root: classes.expansionPanelSummaryConfig,
    content: classes.expansionPanelSummaryContent,
    expanded: classes.expansionPanelSummaryExpanded,
    expandIcon: classes.expansionPanelSummaryExpandIcon,
    disabled: classes.expansionPanelSummaryDisabled,
  };

  const onClickOrg = (org: Organization) => {
    browserHistory.push(`${basePath}/organization/${org.id}`);
  };

  const onClickStation = (station: Station, orgId: number) => {
    browserHistory.push(
      `${basePath}/organization/${orgId}/station/${station.id}`,
    );
  };

  if (
    !selectedOrgId &&
    !selectedStationId &&
    redirectToActiveStation
  ) {
    const stationData: Station =
      LocalStorageService.getMyStationData() as Station;
    onClickStation(stationData, stationData.organization!);
  }

  const fetchOrgData = async () => {
    try {
      const {
        data: { results },
      } = await OrganizationsService.getAll(1, MAX_PAGE_SIZE);
      const epExpandedObj = new Map<number, boolean>();
      results.forEach((org) => {
        epExpandedObj.set(org.id!, true);
      });
      setOrgEpExpanded(epExpandedObj);
      setOrgData(results);

      if (results.length) {
        const stationsObj = new Map(stationsByOrg);
        await Promise.all(
          results.map(async (org) => {
            const { data } = await StationsService.getStationByOrg(
              org.id!,
              undefined,
              MAX_PAGE_SIZE,
            );
            stationsObj.set(org.id!, data.results);
          }),
        );
        setStationsByOrg(new Map(stationsObj));
      }
    } catch (e) {
      console.error('Nav config', e);
    }
  };

  const fetchStationsData = async () => {
    try {
      const {
        data: { results },
      } = await StationsService.getAllStations(true);
      setStations(results);
    } catch (e) {
      console.error('Nav config', e);
    }
  };

  const renderOrgItems = () => {
    return orgData.map((org: Organization, index) => {
      return (
        <ExpansionPanel
          expanded={orgEpExpanded.get(org.id!)}
          key={org.id}
          classes={expansionPanelClasses}
        >
          <ExpansionPanelSummary
            classes={expansionPanelSummaryClasses}
            className={classNames({
              [classes.current]:
                org.id === selectedOrgId && !selectedStationId,
            })}
            style={{ padding: '4px 32px 3px 26px' }}
            expandIcon={<ExpandMoreIcon />}
            id={'configuration-nav-org-' + index}
            onClick={(e: any) => {
              e.stopPropagation();
              if (
                ['svg', 'path', 'BUTTON'].includes(e.target.nodeName)
              ) {
                const epExpandedObj = new Map<number, boolean>(
                  orgEpExpanded,
                );
                epExpandedObj.set(
                  org.id!,
                  !orgEpExpanded.get(org.id!),
                );
                setOrgEpExpanded(epExpandedObj);
              } else {
                onClickOrg(org);
              }
            }}
          >
            <Box className={classes.folderIcon}>
              <FolderIcon />
            </Box>
            <Typography variant='h6'>{org.name}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetailsRoot }}
          >
            <MenuList className={classes.menuList}>
              {stationsByOrg
                ?.get(org.id!)
                ?.map((station: Station) => {
                  return (
                    <MenuItem
                      key={station.id}
                      data-testid={
                        'navigation-menu-link-config' + station.name
                      }
                      className={classNames(
                        {
                          [classes.current]:
                            org.id! === selectedOrgId &&
                            station.id === selectedStationId,
                        },
                        classes.menuItem,
                      )}
                      style={{
                        padding: '9px 10px 9px 70px',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        onClickStation(station, org.id!);
                      }}
                    >
                      {station.name}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  const renderStationItems = () => {
    return stations?.map((station: Station) => {
      return (
        <MenuItem
          key={station.id}
          data-testid={'navigation-menu-link-config' + station.name}
          className={classNames(
            {
              [classes.current]: station.id === selectedStationId,
            },
            classes.menuItem,
          )}
          style={{
            minWidth: 220,
            paddingTop: 16,
            paddingBottom: 17.5,
          }}
          onClick={(e) => {
            e.preventDefault();
            browserHistory.push(
              `${basePath}/organization/-1/station/${station.id}`,
            );
          }}
        >
          {station.name}
        </MenuItem>
      );
    });
  };

  const initFetch = () => {
    const hasMasterRead = PermissionsService.hasPermission(
      PermissionsPermissionsEnum.MASTERREAD,
    );
    const hasOrgRead = PermissionsService.hasPermission(
      PermissionsPermissionsEnum.ORGANIZATIONREAD,
    );
    if (hasMasterRead || hasOrgRead) {
      if (hasMasterRead) {
        setMode(Mode.CLOUDSORT);
      } else if (hasOrgRead) {
        setMode(Mode.ORGANIZATION);
      }
      fetchOrgData();
    } else {
      setMode(Mode.STATION);
      fetchStationsData();
    }
  };

  useEffect(() => {
    initFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mode === Mode.ORGANIZATION && orgData.length) {
      onClickOrg(orgData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData]);

  useEffect(() => {
    if (mode === Mode.STATION && stations?.length) {
      onClickStation(stations[0], -1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations]);

  useEffect(() => {
    if (parseInt(urlParams.orgId)) {
      setSelectedOrgId(parseInt(urlParams.orgId));
    }
    if (parseInt(urlParams.stationId)) {
      setSelectedStationId(parseInt(urlParams.stationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    if (configReduxStore.stale) {
      initFetch();
      dispatch(updateNavEnd());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configReduxStore]);

  return (
    <Fragment>
      {mode === Mode.CLOUDSORT && (
        <ExpansionPanel
          expanded={csEpExpanded}
          classes={expansionPanelClasses}
          style={{ margin: 0 }}
        >
          <ExpansionPanelSummary
            disabled={redirectToActiveStation}
            classes={expansionPanelSummaryClasses}
            className={classNames({
              [classes.current]: !selectedOrgId && !selectedStationId,
            })}
            style={{ padding: '4px 32px 3px 16px' }}
            expandIcon={<ExpandMoreIcon />}
            onClick={(e: any) => {
              e.stopPropagation();
              if (
                ['svg', 'path', 'BUTTON'].includes(e.target.nodeName)
              ) {
                setCsEpExpanded(!csEpExpanded);
              } else {
                setSelectedStationId(undefined);
                setSelectedOrgId(undefined);
                browserHistory.push(basePath);
              }
            }}
          >
            <Box className={classes.folderIcon}>
              <FolderIcon />
            </Box>
            <Typography variant='h6'>CloudSort</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetailsRoot }}
          >
            <MenuList className={classes.menuList}>
              {renderOrgItems()}
            </MenuList>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {mode === Mode.ORGANIZATION && renderOrgItems()}
      {mode === Mode.STATION && renderStationItems()}
    </Fragment>
  );
};

export default withStyles(styles)(Navigation);
