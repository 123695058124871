import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core//Button';
import { Typography } from '../primitives';
import colors from '../../utils/colors';
import ErrorHandler from '../../utils/ErrorHandler';
import { common } from '../../utils/strings';
import { AlertBanner } from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { CSVLink } from 'react-csv';
import MuiButton from '@material-ui/core/Button';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import { Grid, Box, Paper, InputLabel } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import { AxiosError } from 'axios';
import { Customer, InboundManifestResponse } from 'cloudsort-client';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import { debounce } from 'lodash';
import CustomersService from '../../services/Customers.service';
import ManifestsService from '../../services/Manifests.service';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
}

enum CreateInboundManifestSteps {
  OWNER_SELECTION = 'Owner selection',
  FILE_SELECTION = 'File selection',
  RESULTS = 'Results screen',
}

const CreateInboundManifestDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [currentStep, setCurrentStep] =
    useState<CreateInboundManifestSteps>(
      CreateInboundManifestSteps.OWNER_SELECTION,
    );
  const [selectedOwner, setSelectedOwner] = useState<TypeAheadItem>({
    value: '0',
    label: '',
  });
  //Manifest upload
  const [formClass, setFormClass] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [uploadResults, setUploadResults] =
    useState<InboundManifestResponse>();
  const [selectedFile, setSelectedFile] = useState<any>();

  const manifestLabels = {
    singular: configurationUtils.getPageTitle(true, 'MANIFEST'),
    plural: configurationUtils.getPageTitle(false, 'MANIFEST'),
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(undefined, inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const handleClose = () => {
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    setSelectedOwner({ value: '0', label: '' });
    onAfterClose();
    setCurrentStep(CreateInboundManifestSteps.OWNER_SELECTION);
  };

  const onFileChangeHandler = (event: any) => {
    setSelectedFileLabel(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const onFileDroppedHandler = (event: any) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
        setFormClass(undefined);
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      });
    }
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const uploadManifestFile = async () => {
    setError(undefined);
    try {
      setIsUploading(true);
      const response = await ManifestsService.importInboundManifest({
        owner: selectedOwner.value as unknown as number,
        file: selectedFile,
        options: {
          onUploadProgress,
        },
      });
      setUploadResults(response.data);
      setIsUploading(false);
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e as AxiosError);
    }
  };

  const renderFileSelectionForm = () => {
    return (
      <>
        <form
          data-testid={'import-manifest:drop-zone'}
          className={
            classes.form + ' ' + (formClass && classes[formClass])
          }
          onDragOver={(e) => {
            e.preventDefault();
            setFormClass('draggingActive');
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setFormClass(undefined);
          }}
          onDrop={(e) => {
            e.preventDefault();
            onFileDroppedHandler(e);
          }}
        >
          <input
            accept='.csv'
            className={classes.input}
            style={{ display: 'none' }}
            id='button-file'
            type='file'
            onChange={onFileChangeHandler}
          />
          <p>{selectedFileLabel}</p>
          <label htmlFor='button-file'>
            <Button
              variant='outlined'
              component='span'
              className={classes.goldButton}
            >
              Browse File
            </Button>
          </label>
        </form>
      </>
    );
  };

  const renderUploadingProgress = () => {
    return (
      <>
        {isUploading && (
          <p className={classes.grayTitle}>
            Uploading new {manifestLabels.plural} file
          </p>
        )}
        <Paper
          className={classes.fileContainer}
          data-testid={'imported-manifest-info'}
        >
          <Grid container>
            <Grid item xs={2} style={{ padding: '10px' }}>
              <img
                className={classes.img}
                src={`${
                  process.env.REACT_APP_BASENAME || ''
                }/icons/csv-file.png`}
                alt={'CSV'}
              />
            </Grid>
            <Grid item xs={10} style={{ padding: '10px' }}>
              <p className={classes.uploadFileInfo}>
                <span className={classes.uploadFilename}>
                  {selectedFileLabel}
                </span>

                <span className={classes.uploadPercentage}>
                  {uploadProgress}%
                </span>
              </p>
              <div className={classes.uploadProgressBar}>
                <div
                  className={classes.uploadProgressBarInner}
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  const capitalize = (word: string = common.emptyValue) => {
    word = word.trim();
    if (word) {
      return word[0].toUpperCase() + word.toLowerCase().slice(1);
    }
    return common.emptyValue;
  };

  const renderImportResults = () => {
    let errorsCount = 0;
    let warningsCount = 0;
    for (let issue of uploadResults?.issues || []) {
      if (issue.level === 'error') {
        errorsCount += 1;
      }
      if (issue.level === 'warning') {
        warningsCount += 1;
      }
    }
    return (
      <>
        <Typography
          className={classes.boldNameParam}
          style={{ marginBottom: 10 }}
        >
          New {manifestLabels.singular} Imported
        </Typography>

        <Grid container data-testid='imported-file-results'>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Manifest ID
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-status'}
              >
                {uploadResults?.id || common.emptyValue}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                External ID
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-status'}
              >
                {uploadResults?.external_id || common.emptyValue}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Owner
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-status'}
              >
                {capitalize(uploadResults?.owner_full_name)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-status'}
              >
                {capitalize(uploadResults?.status)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Total containers
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-total-containers'}
              >
                {uploadResults?.total_containers}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Total containerized packages
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-total-containerized-packages'}
              >
                {uploadResults?.total_containerized_packages}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Total packages
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-total-packages'}
              >
                {uploadResults?.total_packages}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={8} direction={'column'}>
              <Typography className={classes.paramValueLight}>
                Issues
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.boldNameParam}
                data-testid={'results-issues'}
              >
                {errorsCount > 0 || warningsCount > 0
                  ? `${errorsCount} errors, ${warningsCount} warnings`
                  : 'None'}
              </Typography>
            </Grid>
          </Grid>
          {(errorsCount > 0 || warningsCount > 0) && (
            <Grid
              item
              container
              xs={12}
              style={{ marginBottom: '10px' }}
            >
              <Grid item container xs={7} direction={'column'}></Grid>
              <Grid item xs={5}>
                <Typography style={{ textAlign: 'right' }}>
                  <CSVLink
                    className={classes.link}
                    style={{ fontSize: '15px' }}
                    data={uploadResults?.issues || ''}
                    filename={`${selectedFileLabel}-import-issues-log.csv`}
                  >
                    Download issues log
                  </CSVLink>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box m={0.8} />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <AddToPhotosOutlinedIcon
              style={{
                margin: '10px auto',
                height: 'auto',
                width: 40,
                color: colors.darkGold,
              }}
            />

            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 16,
                marginBottom: 20,
              }}
            >
              Import New {manifestLabels.singular}
            </span>
          </div>
        </DialogTitle>
        {currentStep ===
          CreateInboundManifestSteps.OWNER_SELECTION && (
          <>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              <p>Please select an owner from the list to proceed.</p>
              <>
                <InputLabel
                  className={classes.selectLabel}
                  style={{
                    fontSize: 14,
                    top: '-25px',
                    marginBottom: 16,
                    marginTop: 16,
                  }}
                >
                  Owner
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  styles={{
                    ...asyncSelectStyles,
                    menuPortal: (styles: any) => {
                      return {
                        ...styles,
                        zIndex: 9999,
                      };
                    },
                    control: (styles: any, state: any) => {
                      return {
                        ...styles,
                        backgroundColor: colors.ultraLightGray,
                        border: '1px solid white',
                        color: colors.gray,
                        padding: '10px 0',
                        boxShadow: 'none !important',
                        outline: `none !important`,
                        '&:hover': {
                          border: `1px solid ${colors.ultraLightGray}`,
                        },
                        '&:focus': {
                          border: `1px solid ${colors.ultraLightGray}`,
                        },
                      };
                    },
                    placeholder: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    loadingIndicator: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    input: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    singleValue: (styles: any) => {
                      return {
                        ...styles,
                        color:
                          selectedOwner.label.length > 0
                            ? colors.black
                            : colors.lightGray,
                      };
                    },
                    indicatorsContainer: (styles: any) => {
                      return {
                        ...styles,
                        svg: {
                          color: `${colors.dark} !important`,
                        },
                        color: colors.black,
                      };
                    },
                  }}
                  loadOptions={(
                    inputValue: string,
                    callback: any,
                  ) => {
                    loadCustomerOptions(inputValue, callback);
                  }}
                  onChange={(option) => {
                    setSelectedOwner(option as TypeAheadItem);
                  }}
                  placeholder={'Start Typing...'}
                  value={
                    selectedOwner.label.length > 0
                      ? selectedOwner
                      : {
                          value: '0',
                          label: 'Start Typing...',
                        }
                  }
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </>
            </DialogContent>
            <DialogActions>
              <MuiButton
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CANCEL
              </MuiButton>
              <MuiButton
                disabled={selectedOwner.label.length === 0}
                data-testid='go-to-file-selection'
                onClick={(e) => {
                  setCurrentStep(
                    CreateInboundManifestSteps.FILE_SELECTION,
                  );
                }}
                className={classes.button}
              >
                NEXT
              </MuiButton>
            </DialogActions>
          </>
        )}
        {currentStep ===
          CreateInboundManifestSteps.FILE_SELECTION && (
          <>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                />
              )}
              {renderFileSelectionForm()}
            </DialogContent>
            <DialogActions>
              <MuiButton
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CANCEL
              </MuiButton>
              <MuiButton
                disabled={!selectedFileLabel.endsWith('.csv')}
                data-testid='start-import'
                onClick={(e) => {
                  uploadManifestFile();
                  setCurrentStep(CreateInboundManifestSteps.RESULTS);
                }}
                className={classes.button}
              >
                UPLOAD
              </MuiButton>
            </DialogActions>
          </>
        )}
        {currentStep === CreateInboundManifestSteps.RESULTS &&
          isUploading && (
            <>
              <DialogContent
                className={classes.dialogContent}
                style={{ padding: '0 40px' }}
              >
                {error && (
                  <AlertBanner
                    className={classes.banner}
                    severity='error'
                    alertTitle={'Error'}
                    alertMsg={error}
                  />
                )}
                {renderUploadingProgress()}
              </DialogContent>
              <DialogActions>
                <MuiButton
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </MuiButton>
              </DialogActions>
            </>
          )}
        {currentStep === CreateInboundManifestSteps.RESULTS &&
          !isUploading && (
            <>
              <DialogContent
                className={classes.dialogContent}
                style={{ padding: '0 40px' }}
              >
                {!isUploading && (
                  <>
                    {renderUploadingProgress()}
                    <Box mb={2} />
                  </>
                )}
                {renderImportResults()}
              </DialogContent>
              <DialogActions>
                <MuiButton
                  onClick={(e) => {
                    updateParent();
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CLOSE
                </MuiButton>
              </DialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
      minHeight: '150px',
    },
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.gold,
      },
    },
    form: {
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    draggingActive: {
      borderColor: colors.lightGray,
    },
    dropRejected: {
      borderColor: 'red',
    },
    grayTitle: {
      color: colors.gray,
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: colors.gray,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: colors.lightGold,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: colors.darkGold,
      height: '5px',
    },
  })),
)(CreateInboundManifestDialog);
