import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    padding: 16,
  },
  cardHeader: {
    position: 'relative',
    width: '100%',
    margin: '10px 0',
    minHeight: '40px',
    '&  .MuiCircularProgress-colorPrimary': {
      color: `${colors.darkGold} !important`,
      zIndex: 2,
    },
    '&  .MuiCircularProgress-colorSecondary': {
      color: `${colors.secondaryWhite} !important`,
    },
  },
  cardTitle: {
    color: colors.dark,
    margin: '0!important',
    fontSize: '18px',
    paddingRight: '64px',
  },
  percentageIndicator: {
    position: 'absolute',
    top: '-10px',
    right: 0,
  },
  //Container styles
  containerDark: {
    backgroundColor: colors.dark,
    color: colors.white,

    '& $cardTitle': {
      color: colors.white,
    },

    '& $percentageIndicator': {
      color: colors.white,
    },
  },
  containerWhite: {
    backgroundColor: colors.white,
  },
  containerGold: {
    backgroundColor: colors.lightGold,
  },

  // Title and count
  itemTitleSmall: {
    fontSize: '12px',
    marginBottom: 0,
  },
  itemCountLarge: {
    fontSize: '24px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));
