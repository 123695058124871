import React from 'react';
import {
  AreaChart as Stack,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineType,
  Label,
} from 'recharts';
import { formatBigNumber } from '../ChartHelperFunctions';

interface configEl {
  dataKey: string;
  color: string;
}

interface Props {
  dataTestId?: string;
  height?: number;
  chartData?: ReadonlyArray<object>;
  config: configEl[];
  chartType?: LineType;
  xAxisLabel?: string;
  stackId?: number;
  yLabel?: string;
  hideYAxis?: boolean;
}

const StackedAreaChart: React.FC<Props> = ({
  dataTestId,
  height = 250,
  chartData = [],
  chartType = 'monotone',
  xAxisLabel = 'name',
  stackId = '1',
  config,
  yLabel,
  hideYAxis,
}) => {
  return (
    <div data-testid={dataTestId}>
      <ResponsiveContainer width='100%' height={height}>
        <Stack
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={xAxisLabel} interval={7} />
          <YAxis
            hide={hideYAxis}
            orientation='left'
            tickFormatter={(yValue) => formatBigNumber(yValue)}
          >
            <Label
              x={-20}
              y={70}
              dx={50}
              dy={125}
              value={yLabel}
              position='insideLeft'
              angle={-90}
            />
          </YAxis>
          <Tooltip />
          <Legend iconType={'circle'} />
          {config?.map((configEl) => (
            <Area
              key={configEl.dataKey}
              type={chartType}
              dataKey={configEl.dataKey}
              stackId={stackId}
              stroke={configEl.color}
              fill={configEl.color}
            />
          ))}
        </Stack>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedAreaChart;
