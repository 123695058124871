import React from 'react';

export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='19'
    viewBox='0 0 19 19'
  >
    <g>
      <path
        d='M17.094 4.411V0H0v4.411h1.104v12.131H0v.552h17.094v-.552H15.99V4.412h1.104zM.552 3.86V.552h15.99V3.86H.552h0zm3.86 12.682V9.375h8.27v7.167h-8.27zm11.026 0h-2.204v-7.72H3.86v7.72H1.656V4.412h13.786v12.13h-.004z'
        transform='translate(1 1)'
      />
      <path
        d='M5.239 10.203H11.855V11.203H5.239zM5.239 11.855H11.855V12.855H5.239zM5.239 13.51H11.855V14.51H5.239zM5.239 15.163H11.855V16.163H5.239zM8.271 1.931H9.271V2.931H8.271zM7.167 1.931H8.167V2.931H7.167zM9.375 1.931H10.375V2.931H9.375z'
        transform='translate(1 1)'
      />
    </g>
  </svg>
);
