import React, { Fragment, Dispatch } from 'react';
import { Typography } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Button,
  Divider,
  TextField,
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../utils/colors';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import { INTERACTION } from '../Configuration';
import ColorPicker from 'material-ui-color-picker';
import { Mode } from '../Configuration';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import { useParams } from 'react-router-dom';
import { AlertBanner } from '../../primitives';
import globalStationOptionsUtils from '../../../utils/globalStationOptionsUtils';
import browserHistory from '../../../utils/browserHistory';
import { AuthRoutes } from '../../../interfaces/routes';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

// Services
import OrganizationsService from '../../../services/Organizations.service';
import StationsService from '../../../services/Stations.service';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  mode: Mode;
  setData: Dispatch<any>;
  createOrg: () => Promise<void>;
  createStation: () => Promise<void>;
}

const Settings: React.FC<Props> = ({
  classes,
  data,
  interaction,
  mode,
  setData,
  createOrg,
  createStation,
}) => {
  const urlParams: any = useParams();

  const getItemTypeLabel = (capitalize?: boolean) => {
    if (mode === Mode.ORGANIZATION) {
      return capitalize ? 'Organization' : 'organization';
    } else if (mode === Mode.STATION) {
      return capitalize ? 'Station' : 'station';
    }
    return '';
  };

  const fetchOrgs = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await OrganizationsService.getAll(
      pageIndex,
      rowsPerPage,
    );
    return res;
  };

  const fetchStations = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await StationsService.getStationByOrg(
      urlParams.orgId,
      pageIndex,
      rowsPerPage,
    );
    return res;
  };

  const renderCSorOrgMainInfo = () => {
    return (
      <Fragment>
        <Box fontWeight={500} style={{ fontSize: 11 }} mb={1} mt={3}>
          Info
        </Box>
        <Divider />
        <Typography className={classes.inputLabel}>Name</Typography>
        <Box>
          <TextField
            data-testid={'org-name-input'}
            value={data?.name || ''}
            disabled={interaction === INTERACTION.READ}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />
        </Box>
        <Grid direction='row' xs={12} container item>
          <Grid item xs={6}>
            <Typography className={classes.inputLabel}>
              Primary Color
            </Typography>
            <Box pr={2}>
              <ColorPicker
                value={''}
                disabled={interaction === INTERACTION.READ}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputName,
                }}
                classes={{
                  root: classes.inputNameHolder,
                }}
                onChange={(color) => console.log(color)}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.inputLabel}>
              Secondary Color
            </Typography>
            <Box>
              <ColorPicker
                value={''}
                disabled={interaction === INTERACTION.READ}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputName,
                }}
                classes={{
                  root: classes.inputNameHolder,
                }}
                onChange={(color) => console.log(color)}
              />
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Typography className={classes.inputLabel}>Logo</Typography>
        </Box>
        <Box>
          <div className={classes.accountNameInitials}>
            {data.name &&
              data.name.split(' ')[0].charAt(0) +
                data.name
                  .split(' ')
                  [data.name.split(' ').length - 1].charAt(0)}
          </div>
        </Box>
      </Fragment>
    );
  };

  const renderCSorOrgAdminInfo = () => {
    return (
      <Fragment>
        <Box
          fontWeight={500}
          style={{ fontSize: 11, textTransform: 'capitalize' }}
          mb={1}
          mt={3}
        >
          Admin Info
        </Box>
        <Divider />
        <Typography className={classes.inputLabel}>Admin</Typography>
        <Box>
          <TextField
            disabled={interaction === INTERACTION.READ}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
          />
        </Box>
        <Typography className={classes.inputLabel}>
          Admin email
        </Typography>
        <Box>
          <TextField
            value={''}
            disabled={interaction === INTERACTION.READ}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
          />
        </Box>
      </Fragment>
    );
  };

  const renderStationSettings = () => {
    return (
      <Fragment>
        <Grid item sm={6} xs={12}>
          <Box
            fontWeight={500}
            style={{ fontSize: 11 }}
            mb={1}
            mt={3}
          >
            Info
          </Box>
          <Divider />
          <Typography className={classes.inputLabel}>Name</Typography>
          <Box>
            <TextField
              data-testid={'station-name-input'}
              value={data?.name || ''}
              onFocus={() => {
                if (data?.name === 'New Station') {
                  setData({
                    ...data,
                    name: '',
                  });
                }
              }}
              onBlur={() => {
                if (data?.name === '') {
                  setData({
                    ...data,
                    name: 'New Station',
                  });
                }
              }}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  name: e.target.value,
                });
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            Admin
          </Typography>
          <Box>
            <TextField
              value={''}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            Admin email
          </Typography>
          <Box>
            <TextField
              value={''}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
            />
          </Box>
          {/* <Typography className={classes.inputLabel}>
            Timezone
          </Typography>
          <Box>
            <Select variant='outlined' className={classes.muiSelect}>
              <MenuItem value='none'>None</MenuItem>
            </Select>
          </Box>
          <Typography className={classes.inputLabel}>
            CloudSort uses your time zone to record events, send
            notification and to sync station dashboards.
            </Typography> */}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            fontWeight={500}
            style={{ fontSize: 11, textTransform: 'capitalize' }}
            mb={1}
            mt={3}
          >
            Location
          </Box>
          <Divider />
          <Typography className={classes.inputLabel}>City</Typography>
          <Box>
            <TextField
              data-testid={'station-city-input'}
              value={data?.city || ''}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  city: e.target.value,
                });
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            State
          </Typography>
          <Box>
            <TextField
              data-testid={'station-state-input'}
              value={(data?.state || '').toUpperCase()}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              inputProps={{
                maxLength: 2,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  state: e.target.value
                    .replace(/[^a-zA-Z]/, '')
                    .toUpperCase(),
                });
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            Address
          </Typography>
          <Box>
            <TextField
              data-testid={'station-address-input'}
              value={data?.address || ''}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  address: e.target.value,
                });
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            ZIP Code
          </Typography>
          <Box>
            <TextField
              data-testid={'station-zipcode-input'}
              value={data?.zipcode || ''}
              disabled={interaction === INTERACTION.READ}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  zipcode: e.target.value,
                });
              }}
            />
          </Box>
          <Typography className={classes.inputLabel}>
            GPS coordinates
          </Typography>
          <Box>
            <TextField
              data-testid={'station-geolocation-input'}
              value={
                data.geo_location
                  ? `${data.geo_location[0]}, ${data.geo_location[1]}`
                  : ''
              }
              disabled={true}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
            />
          </Box>
        </Grid>
      </Fragment>
    );
  };

  const handleGoToDashboard = async (id: number) => {
    if (mode === Mode.ORGANIZATION) {
      await globalStationOptionsUtils.setStationData(id);
      setTimeout(() => {
        browserHistory.push(AuthRoutes.DASHBOARD);
      }, 0);
    } else if (mode === Mode.CLOUDSORT) {
      browserHistory.push(
        `${AuthRoutes.CONFIGURATION}/organization/${id}`,
      );
    }
  };

  return (
    <Fragment>
      <Grid direction='row' xs={12} container item>
        <Grid item xs={6}>
          {!!urlParams.created && (
            <AlertBanner
              className={classes.banner}
              severity='info'
              alertTitle={`New ${getItemTypeLabel(true)} Created`}
              alertMsg={'Please fill the information to start.'}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        direction='row'
        xs={12}
        spacing={4}
        container
        item
        data-testid={'settings-csorg-org-info'}
      >
        {(mode === Mode.ORGANIZATION || mode === Mode.CLOUDSORT) && (
          <Fragment>
            <Grid
              item
              sm={6}
              xs={12}
              data-testid={'settings-csorg-main-info'}
            >
              {mode !== Mode.CLOUDSORT && renderCSorOrgMainInfo()}
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              data-testid={'settings-csorg-admin-info'}
            >
              {mode !== Mode.CLOUDSORT && renderCSorOrgAdminInfo()}
            </Grid>
          </Fragment>
        )}
        {mode === Mode.STATION && renderStationSettings()}
      </Grid>
      {(mode === Mode.ORGANIZATION || mode === Mode.CLOUDSORT) && (
        <Grid
          direction='column'
          xs={12}
          container
          item
          data-testid={'settings-module'}
        >
          <Grid direction='row' xs={12} container item>
            <Box
              width='100%'
              justifyContent='space-between'
              style={{ display: 'flex' }}
            >
              <Box mt={4} mb={2}></Box>
              <Box mt={4} mb={2}>
                <Button
                  data-testid={'add-new-btn'}
                  id='add-new-button'
                  fullWidth={false}
                  variant='contained'
                  className={classes.containedButton}
                  onClick={() => {
                    if (mode === Mode.CLOUDSORT) {
                      createOrg();
                    } else if (mode === Mode.ORGANIZATION) {
                      createStation();
                    }
                  }}
                >
                  <AddToPhotosOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Add New
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <PaginatedTable
                key={mode + '_' + data.id}
                title={
                  mode === Mode.CLOUDSORT
                    ? 'Organizations'
                    : 'Stations'
                }
                columns={
                  mode === Mode.CLOUDSORT
                    ? [
                        {
                          id: 'name',
                          label: 'Name',
                          width: 'auto',
                          align: 'left',
                        },
                        {
                          id: 'total_stations',
                          label: 'Stations',
                          width: 'auto',
                          align: 'left',
                        },
                        {
                          id: 'admin',
                          label: 'Admin',
                          width: 'auto',
                          align: 'left',
                        },
                      ]
                    : [
                        {
                          id: 'name',
                          label: 'Name',
                          width: 'auto',
                          align: 'left',
                        },
                        {
                          id: 'total_areas',
                          label: 'Areas',
                          width: 'auto',
                          align: 'left',
                        },
                        {
                          id: 'admin',
                          label: 'Admin',
                          width: 'auto',
                          align: 'left',
                        },
                      ]
                }
                dataTestIdPrefix={'config-details-table'}
                fetch={
                  mode === Mode.CLOUDSORT ? fetchOrgs : fetchStations
                }
                actions={[
                  {
                    tableLabel: ' ',
                    columnLabel:
                      mode === Mode.CLOUDSORT ? (
                        <>Go to Organization's settings</>
                      ) : (
                        <>Go to Station's dashboard</>
                      ),
                    qualifier: 'name',
                    callback: handleGoToDashboard,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    stationBadge: {
      border: `2px solid ${colors.black}`,
      borderRadius: '5px',
      color: colors.black,
      padding: theme.spacing(1, 2),
      marginRight: theme.spacing(1),
      display: 'inline-block',
    },
    accountNameInitials: {
      width: 66,
      height: 66,
      lineHeight: '66px',
      marginRight: 6,
      overflow: 'none',
      borderRadius: '50%',
      textAlign: 'center',
      color: colors.gold,
      backgroundColor: colors.dark,
      fontWeight: 'bold',
      fontSize: 24,
    },
    muiSelect: {
      border: '1px solid #363437',
      width: '100%',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#363437',
        borderWidth: 1,
        backgroundColor: 'transparent',
      },
      '& div': {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
    inputNameHolder: {
      marginRight: 4,
      border: `1px solid ${colors.dark}`,
      borderRadius: '4px',
      width: '100%',
      height: 42,
    },
    inputName: {
      padding: '5px 7px',
    },
    inputLabel: {
      opacity: 0.8,
      color: colors.dark,
      fontSize: 14,
      padding: '12px 0',
    },
    banner: {
      width: '100%',
      borderRadius: 4,
      backgroundColor: '#F1E0D1',
      boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
      color: colors.dark,
    },
  })),
)(Settings);
