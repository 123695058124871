import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import ReAuthDialog from '../reAuthDialog/ReAuthDialog';
import colors from '../../utils/colors';
import {
  Box,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Typography, AlertBanner } from '../primitives';
import Button from '@material-ui/core/Button';
import ErrorHandler from '../../utils/ErrorHandler';
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { useParams } from 'react-router-dom';
import AddUserDialog from '../usersGodView/addUser/AddUserDialog';
import detailsPageStyle from '../commonStyles/detailsPage.style';

//Sub-components
import PersonalInfo from './PersonalInfo';
import RolesPermissions from './RolesPermissions';

//Types
import {
  Staff,
  PermissionsPermissionsEnum,
  AssignedStation,
  AssignedOrganization,
} from 'cloudsort-client';

//Services
import StaffService from '../../services/Staff.service';
import AuthService from '../../services/Auth.service';
import PermissionsService from '../../services/Permissions.service';
import LocalStorageService from '../../services/LocalStorage.service';
import EphemeralStateService from '../../services/EphemeralState.service';

//Icons
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
}

export interface permissionEntity {
  id?: number;
  name?: string;
  type: 'STATION' | 'ORGANIZATION';
}

enum TabsEnum {
  PERSONAL_INFO,
  PERMISSIONS,
  NOTIFICATIONS,
  LANGUAGE,
}

const Profile: React.FC<Props> = ({ classes }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAssignDialog, setShowAssignDialog] =
    useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [staffEditData, setStaffEditData] = useState<Staff>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState<string>('');
  const [prompt, setPrompt] = useState<string>();
  const [enableSave, setEnableSave] = useState<boolean>(false);

  const [showReAuthDialog, setShowReAuthDialog] =
    useState<boolean>(false);
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.PERSONAL_INFO);
  const [assignedEntities, setAssignedEntities] = useState<
    permissionEntity[]
  >([]);
  const [selectedEntity, setSelectedEntity] =
    useState<permissionEntity>();

  const urlParams: any = useParams();
  const isOwnProfile = urlParams.userId === undefined;

  const getProfileData = async (
    stationId?: number,
    orgId?: number,
  ) => {
    setShowProgress(true);
    if (!stationId && urlParams.stationId) {
      stationId = urlParams.stationId;
      setSelectedEntity({ type: 'STATION' });
    }
    if (!orgId && !stationId && urlParams.orgId) {
      orgId = urlParams.orgId;
      setSelectedEntity({ type: 'ORGANIZATION' });
    }
    try {
      const data = (
        await StaffService.getById(
          isOwnProfile ? AuthService.getMyId() : urlParams.userId,
          isOwnProfile && !stationId
            ? EphemeralStateService.getMyStationId()
            : stationId,
          orgId,
        )
      ).data;
      if (data.permission_data === null)
        data.permission_data = {
          role_type: undefined,
          permissions: [],
        };
      setStaffData(data);
      let assignedEnt: permissionEntity[] = [];

      if (data.assigned_organizations)
        data.assigned_organizations!.forEach(
          (e: AssignedOrganization) => {
            if (e.id === orgId) {
              setSelectedEntity({
                id: e.id!,
                name: `Organization ${e.name}`,
                type: 'ORGANIZATION',
              });
            }
            assignedEnt.push({
              id: e.id!,
              name: `Organization ${e.name}`,
              type: 'ORGANIZATION',
            });
          },
        );
      const selectedStationId =
        isOwnProfile && !stationId
          ? EphemeralStateService.getMyStationId()
          : stationId;
      if (data.assigned_stations)
        data.assigned_stations!.forEach((e: AssignedStation) => {
          if (e.id === selectedStationId) {
            setSelectedEntity({
              id: e.id!,
              name: `Station ${e.name}`,
              type: 'STATION',
            });
          }
          assignedEnt.push({
            id: e.id!,
            name: `Station ${e.name}`,
            type: 'STATION',
          });
        });

      setAssignedEntities(assignedEnt);
      //edit params
      setStaffEditData({ ...data, password: '' });
    } catch (e) {
      setError(await ErrorHandler.getLabel(e));
    }
    setShowProgress(false);
  };

  const processUpdateForm = async () => {
    setShowProgress(true);
    try {
      //Update profile
      const data = await StaffService.update(staffData?.id!, {
        ...staffData,
        first_name: staffEditData.first_name,
        last_name: staffEditData.last_name,
        email:
          staffEditData.email !== staffData!.email
            ? staffEditData.email
            : undefined,
        password:
          staffEditData.password !== ''
            ? staffEditData.password
            : undefined,
        permission_data: {
          ...staffData?.permission_data,
          station:
            selectedEntity?.type === 'STATION'
              ? staffData?.permission_data?.station
              : undefined,
          organization:
            selectedEntity?.type === 'ORGANIZATION'
              ? staffData?.permission_data?.organization
              : undefined,
        },
      });
      setStaffData(data.data);
      if (isOwnProfile)
        LocalStorageService.setMyFullName(
          data.data.first_name + ' ' + data.data.last_name,
        );

      //edit params
      setStaffEditData({ ...data.data, password: '' });

      //set prompt
      setPrompt(
        `${
          isOwnProfile ? 'Your' : 'The'
        } profile has been updated. If you updated an email address, it'll need to be verified before it becomes active.`,
      );
    } catch (e) {
      setError(await ErrorHandler.getLabel(e));
    } finally {
      setShowProgress(false);
    }
  };

  const onAssignStationStaff = async (
    staffParams: Staff,
    onAfterDialogClose: () => void,
  ) => {
    setError('');
    setShowProgress(true);
    try {
      staffParams.email = undefined;
      await StaffService.update(staffParams.id!, staffParams);

      onAfterDialogClose();
      getProfileData();
    } catch (e) {
      setError(await ErrorHandler.getLabel(e));
    } finally {
      setShowProgress(false);
    }
  };

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    getProfileData();

    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.STATIONUSERSELFWRITE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort
${staffData ? '- ' + staffData.full_name + `'s profile` : ''}`}
        </title>
      </Helmet>
      <Layout
        className={isOwnProfile ? classes.flyIn : undefined}
        layoutType={isOwnProfile ? 'profile' : 'default'}
        fullName={staffData?.first_name + ' ' + staffData?.last_name}
        showNavigation={false}
      >
        {showProgress && <ProgressIndicator />}
        <Container>
          {error && (
            <AlertBanner
              severity='error'
              data-testid='update-profile-error'
              alertMsg={error}
              alertTitle={'Error'}
              style={{ marginBottom: '20px' }}
            />
          )}
          {prompt && (
            <AlertBanner
              severity='success'
              data-testid='update-profile-prompt'
              alertMsg={prompt}
              alertTitle={'Success'}
              style={{ marginBottom: '20px' }}
            />
          )}
        </Container>
        <AddUserDialog
          isOpen={showAssignDialog}
          onAfterDialogClose={() => {
            setShowAssignDialog(false);
          }}
          onAddStaff={onAssignStationStaff}
          error={error}
          initialStaff={staffData}
        />
        {staffData && (
          <Container component='main' data-testid='profile-component'>
            <Grid container>
              <Grid item xs={12} sm={6} md={8}>
                <Typography
                  variant='h3'
                  className={classes.title}
                  style={{ marginBottom: 20 }}
                >
                  {` ${staffData?.full_name}'s profile`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{ textAlign: 'right' }}
              >
                <Button
                  className={classes.containedButton}
                  data-testid={'profile-cancel-button'}
                  onClick={(e) => {
                    isOwnProfile
                      ? browserHistory.push(AuthRoutes.DASHBOARD)
                      : browserHistory.goBack();
                  }}
                  style={{ marginRight: '10px' }}
                >
                  <HighlightOffIcon />
                  Cancel
                </Button>
                {PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.STATIONUSERSELFWRITE,
                ) && (
                  <Button
                    data-testid='profile-save-button'
                    className={classes.containedButton}
                    disabled={!showProgress && !enableSave}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        staffData?.email !== staffEditData.email ||
                        staffEditData.password !== ''
                      )
                        setShowReAuthDialog(true);
                      else processUpdateForm();
                    }}
                  >
                    <CheckCircleOutlineIcon />
                    Save
                  </Button>
                )}
              </Grid>
              <hr className={classes.divider} />
            </Grid>
            <Box mt={2} mb={3}>
              <Paper
                className={classes.paper}
                style={{
                  maxWidth: 'fit-content',
                  overflow: 'hidden',
                }}
              >
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  variant='scrollable'
                  classes={{ indicator: classes.tabsIndicator }}
                >
                  <Tab
                    label='Personal Info'
                    classes={{ wrapper: classes.tabWrapper }}
                  />
                  <Tab
                    label='Roles & Permissions'
                    classes={{ wrapper: classes.tabWrapper }}
                  />
                </Tabs>
              </Paper>
            </Box>
            {tab === TabsEnum.PERSONAL_INFO && staffData && (
              <PersonalInfo
                isOwnProfile={isOwnProfile}
                staffData={staffData}
                staffEditData={staffEditData}
                setStaffEditData={setStaffEditData}
                setError={setError}
                setPrompt={setPrompt}
                setEnableSave={setEnableSave}
                setShowAssignDialog={setShowAssignDialog}
              />
            )}
            {tab === TabsEnum.PERMISSIONS && (
              <RolesPermissions
                isOwnProfile={isOwnProfile}
                staffData={staffData}
                setStaffData={setStaffData}
                setEnableSave={setEnableSave}
                getProfileData={getProfileData}
                assignedEntities={assignedEntities}
                selectedEntity={selectedEntity}
                setSelectedEntity={setSelectedEntity}
              />
            )}
          </Container>
        )}{' '}
        <ReAuthDialog
          isOpen={showReAuthDialog}
          onAfterClose={() => {
            setShowReAuthDialog(false);
          }}
          callbackFn={() => {
            setShowReAuthDialog(false);
            processUpdateForm();
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    divider: {
      width: '100%',
      border: 'none',
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    title: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
      margin: `${theme.spacing(1)}px 0`,
    },
    subtitle: {
      fontWeight: 'normal',
      fontSize: '0.875rem',
      color: colors.lightGray,
      '& svg': {
        width: '0.875rem',
        height: '0.875rem',
        fontSize: '0.875rem',
        margin: '0 3px -3px 0',
      },
    },
    flyIn: {
      animation: '$fly-in 700ms',
    },
    '@keyframes fly-in': {
      '0%': {
        transform: 'translateY(-100%)',
      },
      '100%': {
        transform: 'translateY(0%)',
      },
    },
    tabWrapper: {
      textTransform: 'capitalize',
      padding: '12px 40px',
    },
    tabsIndicator: {
      height: 5,
      backgroundColor: colors.darkGold,
    },
    tabsBorders: {
      borderRight: '1px solid rgba(151, 151, 151, 0.2)',
      borderLeft: '1px solid rgba(151, 151, 151, 0.2)',
    },

    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(Profile);
