import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes } from '../../interfaces/routes';
import { renderTimestampString } from '../DetailsPagesFunctions';
import { saveAs } from 'file-saver';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, Typography } from '../primitives';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';

import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import {
  Box,
  Button,
  createStyles,
  Grid,
  Theme,
} from '@material-ui/core';
import configurationUtils from '../../utils/configurationUtils';
import { PermissionsPermissionsEnum } from 'cloudsort-client';
import detailsPageStyle from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import queryString from 'query-string';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import _ from 'lodash';
import clx from 'classnames';
import colors from '../../utils/colors';
import { Helmet } from 'react-helmet';

//Services
import ManifestsService from '../../services/Manifests.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import CreateInboundManifestDialog from './CreateInboundManifestDialog';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Manifests: React.FC<Props> = ({ classes, location }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showBatchExportDialog, setShowBatchExportDialog] =
    useState<boolean>(false);
  const [
    showCreateInboundManifestDialog,
    setShowCreateInboundManifestDialog,
  ] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const manifestLabels = {
    singular: configurationUtils.getPageTitle(true, 'MANIFEST'),
    plural: configurationUtils.getPageTitle(false, 'MANIFEST'),
  };

  // Filter drawer
  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const keyHash = _.isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  // Get labels on each render cycle
  const COLUMNS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 120,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'total_packages',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'owner_full_name',
      label: 'Owner',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'fmc_full_name',
      label: 'Carrier',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'created_on',
      label: 'Created',
      align: 'center',
      width: 'left',
    },
  ];

  const fetch = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ManifestsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      manifestType:
        (selectedFilters?.type?.values[0]?.id as
          | 'inbound'
          | 'outbound') || undefined,
      sortBy: sortedBy as any,
    });

    res.data.results.forEach((manifest: any) => {
      manifest.created_on = renderTimestampString(
        manifest.created_on,
      );
      manifest.type = manifest.inbound
        ? 'Inbound'
        : manifest.outbound
        ? 'Outbound'
        : 'N/A';
    });

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    return res;
  };

  const downloadCSV = async (id: number) => {
    setShowProgress(true);
    try {
      const res = await ManifestsService.getCSVExport({
        id: id.toString(),
      });

      const blob = new Blob([res.data], {
        type: 'text/csv;charset=utf-8',
      });

      saveAs(blob, `manifest_export_for_manifest_id_${id}.csv`);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.MANIFESTREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'MANIFEST')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='MANIFEST'>
        <ExportCSVDialog
          exportFilters={{
            manifestType:
              (selectedFilters?.type?.values[0]?.id as
                | 'inbound'
                | 'outbound') || undefined,
          }}
          downloadName={'manifest_export'}
          type='MANIFESTS'
          isOpen={showBatchExportDialog}
          onAfterClose={() => {
            setShowBatchExportDialog(false);
          }}
        />
        <CreateInboundManifestDialog
          isOpen={showCreateInboundManifestDialog}
          onAfterClose={() => {
            setShowCreateInboundManifestDialog(false);
          }}
          updateParent={() => {
            setFilterLastUpdate(Date.now());
          }}
        />
        {showProgress && <ProgressIndicator />}

        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />

        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
            style={{ marginBottom: '20px' }}
          />
        )}

        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.MANIFESTWRITE,
            ) && (
              <Button
                variant='outlined'
                className={classes.buttonFilled}
                onClick={() => {
                  setShowCreateInboundManifestDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon />
                Import {manifestLabels.singular}
              </Button>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.MANIFESTREPORTREAD,
            ) && (
              <Button
                variant='outlined'
                className={classes.containedButton}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowBatchExportDialog(true);
                }}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Batch Export CSVs
              </Button>
            )}
            <Button
              variant='outlined'
              className={clx(
                classes.outlinedButton,
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map((item: any) =>
                  item.values.length ? (
                    <Box
                      key={item.key}
                      className={classes.filterBadge}
                    >
                      <b>{item.label}</b>
                      {item.values.map((value: any) => (
                        <span
                          style={{ marginLeft: '5px' }}
                          key={`${value.name || value.full_name}-${
                            value.id
                          }`}
                        >
                          {value.name || value.full_name}
                          <Button
                            className={classes.filterBadgeButton}
                            onClick={() => {
                              setFilterToRemove({
                                filterKey: item.key,
                                filterValue: value.id,
                              });
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </span>
                      ))}
                    </Box>
                  ) : undefined,
                )}
              </>
            )}
          </Grid>
        </Grid>

        {!!filterLastUpdate && (
          <PaginatedTable
            key={'table' + filterLastUpdate}
            tableKey={'table' + filterLastUpdate}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'manifests'}
            fetch={fetch}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.MANIFEST.replace('/', '')}
            actions={[
              {
                tableLabel: ' ',
                columnLabel: <>Export CSV</>,
                qualifier: 'total_packages',
                callback: downloadCSV,
              },
            ]}
            sortableBy={['created_on']}
            defaultSort='-created_on'
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...detailsPageStyle,
    ...filterBadgeStyle,
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 15px 15px 0',
      },
    },
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    headerFilterButton: {
      color: colors.black,
      backgroundColor: colors.veryLightGray,
      borderColor: colors.veryLightGray,
      minWidth: '40px',
      marginLeft: '20px',
      '&:hover': {
        color: colors.black,
      },
    },
    headerButtons: {
      margin: '0 0 10px 15px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 15px 15px 0',
      },
    },
  })),
)(Manifests);
