import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Theme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { CurrentView, ModuleName } from '../Modules';
import selectStyles from '../../../select/select.styles';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';
interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (wifi: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  ssid: '',
  password: '',
  security: '',
  key: null,
};

const WifiConfig: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'WiFi Configuration';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        data.ssid,
      ]);

      setSearchResult(results);

      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='wifi-config-dialog-SSID'
            autoFocus
            label={'SSID'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.ssid}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                ssid: e.target.value,
              });
            }}
          />
          <TextField
            data-testid='wifi-config-dialog-password'
            label={'Password'}
            type={'password'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.password}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                password: e.target.value,
              });
            }}
          />
          <FormControl
            className={classes.formControl}
            style={{
              width: '100%',
              margin: '10px 0px 10px 0',
              height: 50,
              borderColor: colors.black,
            }}
          >
            <InputLabel className={classes.selectLabel} shrink>
              Security
            </InputLabel>
            <Select
              data-testid='wifi-config-dialog-select-security-type'
              disableUnderline={true}
              displayEmpty
              classes={{
                selectMenu: classes.selectMenu,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.selectPaper,
                  list: classes.selectList,
                },
              }}
              value={dialogData.security}
              onChange={(e) => {
                const value = e.target.value;
                setDialogData({
                  ...dialogData,
                  security: value,
                });
              }}
            >
              <MenuItem value={''} disabled>
                Please select...
              </MenuItem>
              <MenuItem
                value='WEP-PSK'
                data-testid='wifi-config-dialog-security-web-PSK'
              >
                WEP-PSK
              </MenuItem>
              <MenuItem
                value='WPA-PSK'
                data-testid='wifi-config-dialog-security-WPA-PSK'
              >
                WPA-PSK
              </MenuItem>
              <MenuItem
                value='WPA-EAP'
                data-testid='wifi-config-dialog-security-WPA-EAP'
              >
                WPA-EAP
              </MenuItem>
              <MenuItem
                value='WEP-8021X'
                data-testid='wifi-config-dialog-security-WEP-8021X'
              >
                WEP-8021X
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='wifi-config-dialog-save-btn'
            onClick={() => {
              data.ssid = dialogData.ssid;
              data.password = dialogData.password;
              data.security = dialogData.security;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid
                    item
                    sm={11}
                    xs={10}
                    className={classes.pLeft}
                  >
                    Network
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>

              <Grid
                container
                item
                sm={12}
                key={data.ssid}
                className={classes.row}
              >
                <Grid
                  item
                  sm={11}
                  xs={10}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight={data.ssid}
                  />
                </Grid>
                <Grid item sm={1} xs={2}>
                  <IconButton
                    data-testid='wifi-config-edit-btn'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={classes.editIcon}
                    onClick={() => {
                      setDialogData({
                        ssid: data.ssid,
                        password: data.password,
                        security: data.security,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...configPageStyles(theme),
  })),
)(WifiConfig);
