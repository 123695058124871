import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import selectStyles from '../../select/select.styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import configurationUtils, {
  GlobalModules,
} from '../../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  open: boolean;
  onAfterDialogClose: () => void;
  onEdit: (name?: string) => void;
  name?: string;
  title: string;
  validationRegex?: RegExp;
  validationHelperText?: string;
  validationNameCheck?: keyof typeof GlobalModules;
}

const EditNameDialog: React.FC<Props> = ({
  classes,
  open,
  onAfterDialogClose,
  onEdit,
  name,
  title,
  validationRegex,
  validationHelperText,
  validationNameCheck,
}) => {
  const [selectedName, setSelectedName] = useState<string>();

  const afterDialogClose = () => {
    setSelectedName('');
    onAfterDialogClose();
  };

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.dialogRoot }}
      open={open}
      TransitionComponent={Transition}
      onClose={() => {
        afterDialogClose();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        <FormControl
          className={classes.formControl}
          style={{ width: '100%', margin: '5px 0px' }}
        >
          <TextField
            autoFocus
            label='Name'
            type={'string'}
            defaultValue={name}
            onChange={(e) => {
              setSelectedName(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputPadding,
            }}
            error={
              validationNameCheck &&
              selectedName &&
              configurationUtils.isModuleNameValidated(
                validationNameCheck,
              ) &&
              validationRegex
                ? !validationRegex.test(selectedName)
                : false
            }
            helperText={
              validationNameCheck &&
              configurationUtils.isModuleNameValidated(
                validationNameCheck,
              ) &&
              validationHelperText
            }
            FormHelperTextProps={{ style: { paddingLeft: '5px' } }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <MuiButton
          data-testid={'edit-name-cancel-button'}
          onClick={() => {
            afterDialogClose();
          }}
        >
          Cancel
        </MuiButton>
        <MuiButton
          data-testid={'edit-name-edit-button'}
          onClick={() => {
            onEdit(selectedName?.toUpperCase());
          }}
          disabled={
            !selectedName ||
            (validationNameCheck &&
              configurationUtils.isModuleNameValidated(
                validationNameCheck,
              ) &&
              validationRegex &&
              !validationRegex.test(selectedName || ''))
          }
        >
          Edit
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    ...selectStyles,
    detailInfoButton: {
      margin: '-50px 0 0 auto',
      '& svg': {
        marginLeft: 5,
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      minWidth: 300,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    muiSelect: {
      paddingLeft: 5,
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })),
)(EditNameDialog);
