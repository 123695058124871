import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { CurrentView, ModuleName } from '../Modules';
import selectStyles from '../../../select/select.styles';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (sessionData: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  timeout_minutes: 0,
};

const Session: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Session';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        `${data.timeout_minutes} minutes`,
      ]);

      setSearchResult(results);

      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const inputLabelProps = {
    shrink: true,
    className: classes.inputNameLabel,
  };
  const inputProps = {
    disableUnderline: true,
    className: classes.inputName,
  };
  const textFieldClasses = {
    root: classes.inputNameHolder,
  };

  const onlyNumbers = (value: number) =>
    parseInt(value.toString().replace('/[^]/g', ''));

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit Session Inactivity Timeout</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='edit-dialog-timeout-minutes'
            autoFocus
            label={'Minutes'}
            type={'number'}
            InputLabelProps={inputLabelProps}
            InputProps={inputProps}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={textFieldClasses}
            value={dialogData.timeout_minutes}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                ...dialogData,
                timeout_minutes: onlyNumbers(parseInt(value)),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='edit-dialog-session-save-btn'
            onClick={() => {
              data.timeout_minutes = dialogData.timeout_minutes;

              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid
                    item
                    sm={11}
                    xs={10}
                    className={classes.pLeft}
                  >
                    Inactivity Timeout
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>

              <Grid
                container
                item
                sm={12}
                key={data.timeout_minutes}
                className={classes.row}
              >
                <Grid
                  item
                  sm={11}
                  xs={10}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    data-testid='timeout-minutes-value'
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight={`${data.timeout_minutes} minutes`}
                  />
                </Grid>
                <Grid item sm={1} xs={2}>
                  <IconButton
                    data-testid='timeout-minutes-edit-btn'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={classes.editIcon}
                    onClick={() => {
                      setDialogData({
                        timeout_minutes: data.timeout_minutes,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...configPageStyles(theme),
  })),
)(Session);
