import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Switch, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import { INTERACTION } from '../../Configuration';
import enumToLabel from '../../../../utils/enumToLabel';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import configPageStyles from '../../../commonStyles/configPage.style';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import DragHandleIcon from '@material-ui/icons/DragHandle';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  modulesData: any;
  interaction?: INTERACTION;
  setPartialData: (tsm: any) => void;
  currentView: CurrentView;
}

const Tsm: React.FC<Props> = ({
  classes,
  data,
  modulesData,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [tsmData, setTsmData] = useState<any>({});
  const [shouldShowWholeSubsection, setShouldShowWholeSubsection] =
    useState<{ [section: string]: boolean }>({});
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'TSM';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(
    function setTheTsmData() {
      if (data && currentView !== CurrentView.SEARCH) {
        setTsmData(data);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, currentView],
  );

  useEffect(
    function filterTsmDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: {
          [sectionKey: string]: { [sectionItemKey: string]: any };
        } = {};
        const tmpShowSubsection: { [section: string]: boolean } = {};

        for (let sectionData of Object.entries(data)) {
          const sectionName = enumToLabel(sectionData[0]);
          const sectionEnum = sectionData[0];
          const showWholeSubsection = searchResult.some((result) =>
            sectionName.toLowerCase().includes(result.toLowerCase()),
          );
          tmpShowSubsection[sectionEnum] = showWholeSubsection;
          const sectionItems = sectionData[1] as {
            [s: string]: unknown;
          };

          for (let sectionItem of Object.entries(sectionItems)) {
            const subSectionName = enumToLabel(sectionItem[0]);
            const subSectionEnum = sectionItem[0];
            if (
              showWholeSection ||
              showWholeSubsection ||
              searchResult.some((result) =>
                subSectionName
                  .toLowerCase()
                  .includes(result.toLowerCase()),
              )
            ) {
              if (!tmpData[sectionEnum]) tmpData[sectionEnum] = {};
              tmpData[sectionEnum][subSectionEnum] = sectionItem[1];
            }
          }
        }
        setShouldShowWholeSubsection(tmpShowSubsection);
        setTsmData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let configSectionData of Object.entries(data)) {
          dynamicLabels.push(enumToLabel(configSectionData[0]));
          const sectionItems = configSectionData[1] as {
            [s: string]: unknown;
          };
          for (let sectionItem of Object.entries(sectionItems)) {
            dynamicLabels.push(enumToLabel(sectionItem[0]));
          }
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);

        setSearchResult(results);
        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const getDisabledObj = (tsmKey: string) => {
    switch (tsmKey) {
      case 'container':
      case 'containers':
      case 'default_container_type':
        return {
          disabled: !modulesData['CONTAINER']?.active,
          msg: 'Containers module is currently disabled',
        };

      case 'packages':
        return {
          disabled: !modulesData['PACKAGE']?.active,
          msg: 'Packages module is currently disabled',
        };

      case 'dock_door':
        return {
          disabled: !modulesData['DOCK_DOOR']?.active,
          msg: 'Dock Doors module is currently disabled',
        };

      case 'zone':
        return {
          disabled: !modulesData['ZONE']?.active,
          msg: 'Zone module is currently disabled',
        };

      case 'area':
      case 'area_type':
        return {
          disabled: !modulesData['AREA']?.active,
          msg: 'Area module is currently disabled',
        };

      case 'outbound_load':
      case 'target_load_time':
      case 'dispatch_time':
      case 'outbound_load_status':
      case 'trailer_id':
        return {
          disabled: !modulesData['OUTBOUND_LOAD']?.active,
          msg: 'Outbound loads module is currently disabled',
        };

      default:
        return { disabled: false, msg: '' };
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const tsmDataById = { ...data[result.destination.droppableId] };
    const tsmDataArr = Object.entries(tsmDataById).sort(
      (a: any, b: any) => a[1].position - b[1].position,
    );

    const [removed] = tsmDataArr.splice(sourceIndex, 1);
    tsmDataArr.splice(destinationIndex, 0, removed);

    tsmDataArr.forEach((el: any, index) => {
      el[1].position = index++;
    });

    setPartialData({
      ...data,
      [result.destination.droppableId]:
        Object.fromEntries(tsmDataArr),
    });
  };

  const renderSwitch = (
    dataKey: string,
    objStructureKey: string,
    dataObj: any,
    param: string,
  ) => {
    return (
      <Switch
        data-testid={`tsm-switch-${objStructureKey}-${param}-${dataKey}`}
        color='default'
        size='small'
        disabled={
          interaction === INTERACTION.READ ||
          getDisabledObj(dataKey).disabled
        }
        className={
          dataObj[param] ? classes.muiSwitch : classes.muiSwitchOff
        }
        checked={dataObj[param]}
        onChange={() => {
          const dataCopy = { ...data };
          dataCopy[objStructureKey][dataKey][param] =
            !dataCopy[objStructureKey][dataKey][param];
          setPartialData(dataCopy);
        }}
      />
    );
  };

  const renderComponent = () => {
    return (
      <>
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight='TSM'
              />
            </Box>
            {Object.entries(tsmData || {})
              ?.map(([key, _]: any) => key)
              ?.map((objStructureKey) => {
                const showWholeSubsection =
                  shouldShowWholeSubsection[objStructureKey];
                return (
                  <Box mt={2} key={objStructureKey}>
                    <Box mb={2}>
                      <Box
                        className={classNames(
                          classes.moduleTitle,
                          classes.pLeft,
                        )}
                        mb={1}
                        mt={3}
                      >
                        <Highlighter
                          highlightClassName={
                            classes.searchResultsMark
                          }
                          searchWords={searchResult}
                          autoEscape={true}
                          textToHighlight={enumToLabel(
                            objStructureKey,
                          )}
                        />
                      </Box>
                      <Grid
                        container
                        item
                        sm={12}
                        className={classes.textHeader}
                      >
                        <Grid
                          item
                          md={10}
                          sm={8}
                          xs={6}
                          className={classes.pLeft}
                        >
                          Name
                        </Grid>
                        <Grid item md={1} sm={2} xs={3}>
                          Active
                        </Grid>
                        <Grid item md={1} sm={2} xs={3}>
                          Clickable
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    </Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId={objStructureKey}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {Object.entries(tsmData || {})
                              ?.filter(
                                ([sectionKey, _]: any) =>
                                  sectionKey === objStructureKey,
                              )
                              ?.map(([key, dataByKey]: any) => {
                                return Object.entries(dataByKey)
                                  .sort(
                                    (a: any, b: any) =>
                                      a[1].position - b[1].position,
                                  )
                                  .map(
                                    (
                                      [dataKey, dataObj]: any,
                                      index,
                                      array: any[],
                                    ) => {
                                      return (
                                        <Draggable
                                          key={`${dataKey}-${index}`}
                                          draggableId={`${dataKey}-${index}`}
                                          isDragDisabled={
                                            interaction ===
                                              INTERACTION.READ ||
                                            (!showWholeSection &&
                                              !showWholeSubsection)
                                          }
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              data-testid={`tsm-drag-drop-${enumToLabel(
                                                objStructureKey,
                                              )}-${enumToLabel(
                                                dataKey,
                                              )}`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Grid
                                                container
                                                item
                                                sm={12}
                                                xs={12}
                                                key={dataKey}
                                                className={
                                                  classes.row
                                                }
                                              >
                                                <Grid
                                                  item
                                                  sm={1}
                                                  xs={1}
                                                  className={
                                                    classes.dndIcon
                                                  }
                                                >
                                                  <DragHandleIcon />
                                                </Grid>
                                                <Grid
                                                  item
                                                  md={9}
                                                  sm={7}
                                                  xs={5}
                                                  className={classNames(
                                                    classes.text,
                                                    classes.pLeft,
                                                  )}
                                                >
                                                  <Highlighter
                                                    highlightClassName={
                                                      classes.searchResultsMark
                                                    }
                                                    searchWords={
                                                      searchResult
                                                    }
                                                    autoEscape={true}
                                                    textToHighlight={enumToLabel(
                                                      dataKey,
                                                    )}
                                                  />
                                                </Grid>

                                                <Grid
                                                  item
                                                  md={1}
                                                  sm={2}
                                                  xs={3}
                                                  className={
                                                    classes.muiSwitchHoler
                                                  }
                                                >
                                                  {getDisabledObj(
                                                    dataKey,
                                                  ).disabled ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          getDisabledObj(
                                                            dataKey,
                                                          ).msg
                                                        }
                                                        placement='top'
                                                        PopperProps={{
                                                          popperOptions:
                                                            {
                                                              modifiers:
                                                                {
                                                                  offset:
                                                                    {
                                                                      enabled:
                                                                        true,
                                                                      offset:
                                                                        '0, -65px',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                      >
                                                        <div>
                                                          {renderSwitch(
                                                            dataKey,
                                                            objStructureKey,
                                                            dataObj,
                                                            'active',
                                                          )}
                                                        </div>
                                                      </Tooltip>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {renderSwitch(
                                                        dataKey,
                                                        objStructureKey,
                                                        dataObj,
                                                        'active',
                                                      )}
                                                    </>
                                                  )}
                                                </Grid>
                                                <Grid
                                                  item
                                                  md={1}
                                                  sm={2}
                                                  xs={3}
                                                  className={
                                                    classes.muiSwitchHoler
                                                  }
                                                >
                                                  {getDisabledObj(
                                                    dataKey,
                                                  ).disabled ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          getDisabledObj(
                                                            dataKey,
                                                          ).msg
                                                        }
                                                        placement='top'
                                                        PopperProps={{
                                                          popperOptions:
                                                            {
                                                              modifiers:
                                                                {
                                                                  offset:
                                                                    {
                                                                      enabled:
                                                                        true,
                                                                      offset:
                                                                        '0, -65px',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                      >
                                                        <div>
                                                          {renderSwitch(
                                                            dataKey,
                                                            objStructureKey,
                                                            dataObj,
                                                            'clickable',
                                                          )}
                                                        </div>
                                                      </Tooltip>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {renderSwitch(
                                                        dataKey,
                                                        objStructureKey,
                                                        dataObj,
                                                        'clickable',
                                                      )}
                                                    </>
                                                  )}
                                                </Grid>
                                                {index !==
                                                array.length - 1 ? (
                                                  <Grid item xs={12}>
                                                    <Divider
                                                      className={
                                                        classes.divider
                                                      }
                                                    />
                                                  </Grid>
                                                ) : (
                                                  <></>
                                                )}
                                              </Grid>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    },
                                  );
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                );
              })}
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(Tsm);
