import React, { useEffect, useState } from 'react';

import {
  DatasetRow,
  ReportDatasetEventIntervalsOriginEventEnum,
  ReportDatasetEventIntervalsTargetEventEnum,
} from 'cloudsort-client';
import { withStyles } from '@material-ui/core/styles';
import {
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import { BarChart } from '../../primitives';
import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import colors from '../../../utils/colors';
import { CARD_LOAD_DELAY } from '../Dashboard';

//Services
import ReportsService, {
  ReportFilters,
} from '../../../services/Reports.service';

//Icons
import FilterListIcon from '@material-ui/icons/FilterList';

interface Props {
  title_p2s: string;
  title_s2c: string;
  index: number;
  filters?: SelectedFilters;
  classes: { [key: string]: string };
}
interface ChartData {
  arg: string;
  value: number;
}

const DwellTimeCharts: React.FC<Props> = ({
  title_p2s,
  title_s2c,
  index,
  filters,
  classes,
}) => {
  const [primaryToSecondary, setPrimaryToSecondary] = useState<
    ChartData[] | undefined
  >();
  const [secondaryToContainerize, setSecondaryToContainerize] =
    useState<ChartData[] | undefined>();

  const parsedFilters: ReportFilters = {
    owner:
      filters && filters.owner && filters.owner.values.length
        ? filters.owner.values.map((item: any) => item.id)
        : undefined,
    carrier:
      filters && filters.carrier && filters.carrier.values.length
        ? filters.carrier.values.map((item: any) => item.id)
        : undefined,
  };

  const fetchStats = async () => {
    const res = await ReportsService.getDwellTimes(
      ReportDatasetEventIntervalsOriginEventEnum.PRIMARY,
      ReportDatasetEventIntervalsTargetEventEnum.SECONDARY,
      undefined,
      parsedFilters,
    );

    const res2 = await ReportsService.getDwellTimes(
      ReportDatasetEventIntervalsOriginEventEnum.CONTAINERIZED,
      ReportDatasetEventIntervalsTargetEventEnum.STAGED,
      undefined,
      parsedFilters,
    );

    const p2sDwell: ChartData[] = [],
      s2cDwell: ChartData[] = [];

    res.data.data?.map((item: DatasetRow) => {
      p2sDwell.push({
        arg: new Date(item.timestamp ?? '').toLocaleTimeString(
          navigator.language,
          {
            hour: 'numeric',
          },
        ) as string,
        value: Math.ceil(item.value!),
      });
    });

    res2.data.data?.map((item: DatasetRow) => {
      s2cDwell.push({
        arg: new Date(item.timestamp ?? '').toLocaleTimeString(
          navigator.language,
          {
            hour: 'numeric',
          },
        ) as string,
        value: Math.ceil(item.value!),
      });
    });

    //Update state
    setPrimaryToSecondary(p2sDwell);
    setSecondaryToContainerize(s2cDwell);
  };

  useEffect(() => {
    let componentMounted = true;

    setTimeout(() => {
      if (componentMounted) {
        fetchStats();
      }
    }, index * CARD_LOAD_DELAY);

    return function cleanup() {
      componentMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltip = () => {
    if (
      filters &&
      getAppliedFilters(filters, ['route', 'stop', 'hidePending'])
        .length
    )
      return (
        <Tooltip
          title={
            <Box className={classes.filterTooltip}>
              <p>The following filters are applied to this card:</p>
              <ul>
                {getAppliedFilters(filters, [
                  'route',
                  'stop',
                  'hidePending',
                ]).map((filter) => (
                  <li key={filter.key}>
                    <b>{filter.label}:</b>{' '}
                    {filter.values
                      .map((value) => value.name || value.full_name)
                      .join(', ')}
                  </li>
                ))}
              </ul>
            </Box>
          }
        >
          <FilterListIcon className={classes.filterIcon} />
        </Tooltip>
      );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Typography variant='h3' className={classes.cardTitle}>
            {renderTooltip()} {title_p2s}
          </Typography>
          <Typography variant='h5' className={classes.cardSubtitle}>
            Dwell Times
          </Typography>
          <BarChart
            height={250}
            chartData={primaryToSecondary}
            dataType='minutesSeconds'
            dataTestId='chart-p2sdwell'
          />
          {!primaryToSecondary && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: 130,
                left: '50%',
              }}
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Typography variant='h3' className={classes.cardTitle}>
            {renderTooltip()} {title_s2c}
          </Typography>
          <Typography variant='h5' className={classes.cardSubtitle}>
            Dwell Times
          </Typography>
          <BarChart
            height={250}
            chartData={secondaryToContainerize}
            fill={colors.gold}
            dataType='minutesSeconds'
            dataTestId='chart-s2sdwell'
          />
          {!secondaryToContainerize && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: 130,
                left: '50%',
              }}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    cardTitle: {
      color: colors.dark,
      margin: '0 0 10px 0!important',
      fontWeight: 'bold',
      fontSize: '18px',
      paddingRight: '64px',
    },
    cardSubtitle: {
      fontSize: '10px',
      color: colors.lightGray,
    },
    paper: {
      padding: 16,
      position: 'relative',
    },
    //Filter tooltip
    filterTooltip: {
      '& p': {
        fontSize: '12px',
      },
      '& ul': {
        fontSize: '12px',
        padding: '0 0 0 20px',
      },
    },
    filterIcon: {
      fontSize: '18px',
      background: colors.gold,
      color: colors.white,
      padding: '2px',
      borderRadius: '50%',
      margin: '0 5px -2px 0',
    },
  })),
)(DwellTimeCharts);
