import React, { Dispatch, useEffect, useState } from 'react';
import enumToLabel from '../../../../utils/enumToLabel';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Switch, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import classNames from 'classnames';
import { INTERACTION } from '../../Configuration';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';
import { processSearchQuery } from '../searchQuery';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  title: string;
  data: any;
  dataKeys: string[];
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  currentView: CurrentView;
}

const MultipleToggleBehaviors: React.FC<Props> = ({
  classes,
  title,
  data,
  dataKeys,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [togglesData, setTogglesData] = useState<any[]>([]);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = title;

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(
    function setToggleData() {
      if (data && currentView !== CurrentView.SEARCH) {
        const tmpData: any[] = [];
        for (let key of dataKeys) {
          tmpData.push(key);
        }
        setTogglesData(tmpData);
      }
    },
    [data, dataKeys, currentView],
  );

  useEffect(
    function filterModuleDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let key of dataKeys) {
          if (
            showWholeSection ||
            searchResult.some((result) =>
              enumToLabel(key)
                .toLowerCase()
                .includes(result.toLowerCase()),
            )
          )
            tmpData.push(key);
        }
        setTogglesData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let key of dataKeys) {
          dynamicLabels.push(enumToLabel(key));
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);

        setSearchResult(results);
        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const renderComponent = () => {
    return (
      <>
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>

            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={11} xs={9} className={classes.pLeft}>
                    Behavior
                  </Grid>
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              {togglesData.map((dataKey, index, array: any[]) => {
                return (
                  <Grid
                    container
                    item
                    sm={12}
                    className={classes.row}
                    key={dataKey}
                  >
                    <Grid
                      item
                      sm={11}
                      xs={9}
                      className={classNames(
                        classes.text,
                        classes.pLeft,
                      )}
                    >
                      <Highlighter
                        highlightClassName={classes.searchResultsMark}
                        searchWords={searchResult}
                        autoEscape={true}
                        textToHighlight={enumToLabel(dataKey)}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      xs={3}
                      className={classes.muiSwitchHoler}
                    >
                      <Switch
                        data-testid={`${SECTION_TITLE}-switch-${enumToLabel(
                          dataKey,
                        )}`}
                        color='default'
                        size='small'
                        disabled={interaction === INTERACTION.READ}
                        className={
                          data[index].active
                            ? classes.muiSwitch
                            : classes.muiSwitchOff
                        }
                        checked={data[index].active}
                        onChange={() => {
                          data[index].active = !data[index].active;
                          setPartialData({
                            ...data,
                          });
                        }}
                      />
                    </Grid>
                    {index !== array.length - 1 && (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(MultipleToggleBehaviors);
