import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { common } from '../../utils/strings';
import Layout from '../layout/Layout';
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
  WebHookSubscription,
  WebHookSubscriptionFilter,
  WebHookSubscriptionFilterFilterTypeEnum,
  WebHookSubscriptionFilterResourceTypeEnum,
} from 'cloudsort-client';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { AlertBanner, Typography } from '../primitives/index';
import {
  Box,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';
import WebhooksService from '../../services/Webhooks.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Link } from 'react-router-dom';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import enumToLabel from '../../utils/enumToLabel';
import { AuthRoutes } from '../../interfaces/routes';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

type EnchancedWebHookSubscriptionFilter =
  WebHookSubscriptionFilter & {
    event_types_count: string | number | undefined;
    resource_type_label: string;
    filter_type_label: string;
  };

const WebhookDetails: React.FC<Props> = ({ classes, match }) => {
  const [webhookData, setWebhookData] =
    useState<WebHookSubscription>();
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState();
  const [isReady, setIsReady] = useState(false);
  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const thisWebhookId = match.params.webhookId;

  const fetchAndSetWebhookData = async () => {
    try {
      setShowProgress(true);
      const res = await WebhooksService.getById(thisWebhookId);
      for (const event of res.data
        .filter_events as EnchancedWebHookSubscriptionFilter[]) {
        event.event_types_count = event.event_types?.length;
        if (
          (event.filter_type ===
            WebHookSubscriptionFilterFilterTypeEnum.Star ||
            event.filter_type ===
              WebHookSubscriptionFilterFilterTypeEnum.OUTBOUNDMANIFEST ||
            event.resource_type ===
              WebHookSubscriptionFilterResourceTypeEnum.Star) &&
          !event.event_types
        ) {
          event.event_types_count = '*';
        }
        event.resource_type_label = enumToLabel(
          event.resource_type as string,
        );
        event.filter_type_label = enumToLabel(event.filter_type);
      }
      setWebhookData(res.data);
      setIsReady(true);
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.WEBHOOKREAD,
    );
    PermissionsService.redirectIfNoRoles([
      PermissionsRoleTypeEnum.ORGADMIN,
      PermissionsRoleTypeEnum.SUPERUSER,
    ]);
    fetchAndSetWebhookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLUMNS_EVENTS: Column[] = [
    {
      id: 'filter_type_label',
      label: 'Filter Type',
      width: 250,
    },
    {
      id: 'resource_type_label',
      label: 'Resource Type',
      width: 250,
    },
    {
      id: 'station_id',
      label: 'Station ID',
      width: 250,
    },
    {
      id: 'event_types_count',
      label: 'Event Types',
    },
  ];

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const start = (pageIndex - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return {
      data: {
        results: webhookData?.filter_events.slice(start, end),
        count: webhookData?.filter_events.length,
      },
    };
  };
  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
Webhook ${webhookData?.id || ''}`}
        </title>
      </Helmet>
      <Layout>
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {showProgress && <ProgressIndicator />}
        {isReady && (
          <>
            <Grid container className={classes.header}>
              <Grid item xs={12} sm={6}>
                <Box
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box>
                    <Link
                      to={AuthRoutes.WEBHOOKS}
                      className={classes.back}
                    >
                      <ArrowLeftIcon />
                      <Typography>Back</Typography>
                    </Link>
                  </Box>
                  <Typography
                    className={classes.title}
                    variant={'h3'}
                  >
                    {`Webhook ID ${webhookData?.id}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12}>
                <Paper
                  className={classes.paper}
                  style={{ padding: 16 }}
                  data-testid='webhook-details'
                >
                  <SingleDetail
                    inline={true}
                    label={'Webhook ID'}
                    value={webhookData?.id || common.emptyValue}
                  />
                  <SingleDetail
                    inline={true}
                    label={'Listener URL'}
                    value={webhookData?.url || common.emptyValue}
                  />
                  <br />
                  <SingleDetail
                    inline={true}
                    label={'Owner'}
                    value={
                      webhookData?.owner_name || common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'Organization'}
                    value={
                      webhookData?.organization_name ||
                      common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'Events'}
                    value={
                      webhookData?.filter_events?.length ||
                      common.emptyValue
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid container className={classes.header}>
              <Grid item xs={12} sm={6}>
                <Box
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    className={classes.title}
                    variant={'h3'}
                  >
                    List of Events
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <PaginatedTable
              key={'webhook-events'}
              tableKey={'wh-events'}
              title=''
              columns={COLUMNS_EVENTS}
              dataTestIdPrefix={'webhook-events'}
              fetch={fetchEvents}
              rowsLoadDetailPages={false}
              isExpandedPanel
              expandedContentRenderer={(data) => {
                return (
                  <Box
                    marginLeft={inXsScreen ? '46px' : '812px'}
                    paddingRight={3}
                  >
                    {data.event_types && (
                      <>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Event Types
                        </Typography>
                        {data.event_types.map(
                          (eventType: any, index: number) => {
                            return (
                              <Typography
                                key={`${eventType}-${index}`}
                              >
                                {eventType}
                              </Typography>
                            );
                          },
                        )}
                      </>
                    )}
                  </Box>
                );
              }}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    tableCell: {
      width: 250,
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(WebhookDetails);
