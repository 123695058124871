import colors from '../../utils/colors';

export default {
  filterBadgesHolder: {
    display: 'block',
    marginTop: '10px',
  },
  filterBadge: {
    textTransform: 'none',
    border: `1px solid ${colors.darkGold}`,
    borderRadius: '4px',
    padding: '5px',
    width: 'auto',
    display: 'inline-block',
    margin: '0 5px 5px 0',
  },
  filterBadgeButton: {
    backgroundColor: colors.darkGold,
    color: colors.white,
    width: '0.75em',
    height: '0.75em',
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '1rem',
    margin: '0 0 2px 5px',
    '& svg': {
      width: '0.5em',
      height: '0.5em',
    },
  },
  filterHR: {
    width: '100%',
    border: `2px solid ${colors.veryLightGray}`,
    color: colors.lightGray,
    marginBottom: '10px',
  },
};
