import React, { Fragment, useState, useEffect } from 'react';
import SingleRowStats from '../../singleRowStats/SingleRowStats';
import { AuthRoutes } from '../../../interfaces/routes';
import {
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import colors from '../../../utils/colors';
import { common } from '../../../utils/strings';
import StaffService from '../../../services/Staff.service';
import { renderDuration } from '../../DetailsPagesFunctions';
import { StaffStats as StaffStatsInterface } from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  staffId: string;
}

const styles = createStyles((theme: Theme) => ({
  staffStats1: {
    '& div:first-child': {
      '& div:first-child h6': {
        color: colors.gold,
      },
    },
  },
  staffStats3: {
    '& div:first-child': {
      '& div:nth-child(4) h6': {
        color: colors.gold,
      },
    },
  },
  halfWidth: {
    [theme.breakpoints.up('sm')]: { width: '50%' },
  },
}));

const StaffStats: React.FC<Props> = ({ classes, staffId }) => {
  const [statsLoaded, setStatsLoaded] = useState<boolean>(false);
  const [stationStaffStats, setStationStaffStats] =
    useState<StaffStatsInterface>();

  const getStationStaffStats = async () => {
    try {
      const { data } = await StaffService.getStatsById(
        Number(staffId),
      );
      setStationStaffStats(data);
      setStatsLoaded(true);
    } catch (e) {}
  };

  useEffect(() => {
    getStationStaffStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {statsLoaded && (
        <Fragment>
          <SingleRowStats
            link_base={AuthRoutes.STAFF}
            dataTestId='staff-stats-1'
            fetch={() => [
              {
                label: 'Time per Package',
                value: renderDuration(
                  stationStaffStats?.seconds_per_package || 0,
                ),
              },
              {
                label: 'Packages per Hour',
                value:
                  stationStaffStats?.packages_per_hour?.toFixed(1),
              },
              {
                label: 'Seconds per ft3',
                value: stationStaffStats?.seconds_per_cft?.toFixed(1),
              },
              {
                label: 'Minutes per ft3',
                value: stationStaffStats?.minutes_per_cft?.toFixed(1),
              },
            ]}
            className={classes.staffStats1}
          />
          <SingleRowStats
            link_base={AuthRoutes.STAFF}
            dataTestId='staff-stats-2'
            fetch={() => [
              {
                label: 'Total Packages',
                value: stationStaffStats?.total_packages,
              },
              {
                label: 'Total Volume',
                value:
                  stationStaffStats?.total_volume?.toFixed(1) +
                  ' ft3',
              },
              {
                label: 'Scans/m',
                value:
                  stationStaffStats?.scans_per_minute?.toFixed(1),
              },
              {
                label: 'Actions Performed',
                value: stationStaffStats?.actions_performed,
              },
            ]}
            className={classes.staffStats}
          />
          <SingleRowStats
            containerClassName={classes.halfWidth}
            link_base={AuthRoutes.STAFF}
            dataTestId='staff-stats-3'
            fetch={() => [
              {
                label: 'Working Minutes',
                value: stationStaffStats?.working_time?.toFixed(1),
              },
              {
                label: 'Av. Session Duration',
                value: stationStaffStats?.avg_session_duration
                  ? renderDuration(
                      Math.round(
                        stationStaffStats.avg_session_duration * 60,
                      ),
                    )
                  : common.emptyValue,
              },
            ]}
            className={classes.staffStats3}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(StaffStats);
