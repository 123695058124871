import React, { useState, Fragment, BaseSyntheticEvent } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { Typography } from '../primitives';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { createStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AlertBanner } from '../primitives';
import TextField from '@material-ui/core/TextField';
import colors from '../../utils/colors';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import validateEmail from '../../utils/validateEmail';
import {
  Card,
  CardContent,
  Grid,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { debounce } from 'lodash';
import {
  OTHER_PERMISSIONS_LABELS,
  OT_PERMISSIONS_LABELS,
} from './StationStaff';

// Types
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
  Staff,
} from 'cloudsort-client';

//Services
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import configurationUtils from '../../utils/configurationUtils';
import StaffService from '../../services/Staff.service';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterDialogClose: () => void;
  onAddStaff: (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => void;
  error?: string;
}

const initAddParams: Staff = {
  first_name: '',
  last_name: '',
  email: '',
  permission_data: {
    role_type: PermissionsRoleTypeEnum.OPERATORI,
    permissions: [],
  },
};

const AddStaffDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterDialogClose,
  onAddStaff,
  error,
}) => {
  const [staffParams, setStaffParams] =
    useState<Staff>(initAddParams);
  const [roleSelected, setRoleSelected] = useState<Boolean>(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    useState<boolean>(true);

  const [isEmailConfirmationMsg, setIsEmailConfirmationMsg] =
    useState<boolean>(false);

  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();

  const isValidEmail = validateEmail(staffParams.email || '');
  const hasNoName =
    !staffParams?.first_name?.trim() ||
    !staffParams?.last_name?.trim();

  const hasNoEmail = !staffParams.email?.trim();

  const onEmailChange = (e: BaseSyntheticEvent) => {
    debouncedCheck(e.target.value);
  };

  const debouncedCheck = debounce(function (email: string) {
    if (validateEmail(email)) {
      checkIfEmailIsRegistered(email);
    } else
      setStaffParams({
        ...staffParams,
        id: undefined,
        email: undefined,
      });
  }, 300);

  const onClose = () => {
    onAfterDialogClose();
    setTimeout(() => {
      setRoleSelected(false);
      setStaffParams(initAddParams);
      setIsEmailConfirmationMsg(false);
    }, 300);
  };

  const selectRole = (role: PermissionsRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffParams({
      ...staffParams,
      permission_data: {
        role_type: role,
        permissions: defaultPermissions,
        station: EphemeralStateService.getMyStationId(),
      },
    });
    setRoleSelected(true);

    if (role === PermissionsRoleTypeEnum.OPERATORI)
      setEmailAlreadyRegistered(false);
    else setEmailAlreadyRegistered(true);
  };

  const checkIfEmailIsRegistered = async (email: string) => {
    try {
      const { data } = await StaffService.checkEmail(email);
      setStaffParams({
        ...staffParams,
        id: data.id,
        email: data.email,
      });
      setEmailAlreadyRegistered(true);
    } catch (e) {
      setStaffParams({
        ...staffParams,
        id: undefined,
        email,
      });
      setEmailAlreadyRegistered(false);
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const renderRoleSelection = () => {
    return (
      <Grid container item direction={'row'} xs={12} spacing={2}>
        <Grid style={{ minWidth: 135 }} item xs={inXsScreen ? 6 : 4}>
          <Card
            elevation={2}
            onClick={() => {
              selectRole(PermissionsRoleTypeEnum.OPERATORI);
            }}
          >
            <CardContent className={classes.cardContent}>
              <Box>
                <img
                  className={classes.img}
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/staff/staff.png`}
                  alt={'Operator I'}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  className={classes.roleName}
                  variant={'h6'}
                  gutterBottom
                >
                  Operator I
                </Typography>
                <Typography className={classes.roleDescription}>
                  Sort and Containerize Packages
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ minWidth: 135 }} item xs={inXsScreen ? 6 : 4}>
          <Card elevation={2}>
            <CardContent
              data-testid='station-staff-add-select-role'
              className={classes.cardContent}
              onClick={() => {
                selectRole(PermissionsRoleTypeEnum.OPERATORII);
              }}
            >
              <Box>
                <img
                  className={classes.img}
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/staff/staff.png`}
                  alt={'Operator II'}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  className={classes.roleName}
                  variant={'h6'}
                  gutterBottom
                >
                  Operator II
                </Typography>
                <Typography className={classes.roleDescription}>
                  Sort, Containerize Packages, Container Ops & Webapp
                  access
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid style={{ minWidth: 135 }} item xs={inXsScreen ? 6 : 4}>
          <Card elevation={2}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                selectRole(PermissionsRoleTypeEnum.OPERATORIII);
              }}
            >
              <Box>
                <img
                  className={classes.img}
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/staff/staff.png`}
                  alt={'Operator III'}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  className={classes.roleName}
                  variant={'h6'}
                  gutterBottom
                >
                  Operator III
                </Typography>
                <Typography className={classes.roleDescription}>
                  Full visibility of station performance and access to
                  control ops.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const renderPermissions = (
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffParams!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <>
        {Object.keys(PermissionsPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionsToRender).includes(permission),
          )
          .map((key) => {
            return PermissionsPermissionsEnum[
              key as keyof typeof PermissionsPermissionsEnum
            ];
          })
          .map((permission) => {
            return (
              <Fragment
                key={`fragment-${permissionLabels[permission]}`}
              >
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    dependencyStatus[permission].enabled
                      ? ''
                      : dependencyStatus[permission].tooltipMessage
                  }
                  placement='top-start'
                >
                  <span>
                    <FormControlLabel
                      key={permissionLabels[permission]}
                      style={{ width: '100%' }}
                      control={
                        <Checkbox
                          color={undefined}
                          key={`checkbox-${permissionLabels[permission]}`}
                          classes={{
                            root: classes.checkbox,
                            checked: classes.checked,
                          }}
                          disabled={
                            !dependencyStatus[permission].enabled
                          }
                          checked={staffParams.permission_data?.permissions!.some(
                            (selectedPermission) =>
                              selectedPermission === permission,
                          )}
                          onChange={(e) => {
                            const isAlreadySelected =
                              staffParams.permission_data?.permissions!.some(
                                (selectedPermission) =>
                                  selectedPermission === permission,
                              );
                            if (isAlreadySelected) {
                              const {
                                newPermissionsState,
                                disabledPermissions,
                              } = disablePermissionAndDependants(
                                permission,
                                staffParams.permission_data
                                  ?.permissions as string[],
                              );

                              //All dependencies are disabled, show notification to the user.
                              if (disabledPermissions.length > 0) {
                                setShowDependencyNotification(true);
                                setDependencyNotificationMessage(
                                  `Your selection also disabled the dependant permission${
                                    disabledPermissions.length === 1
                                      ? ''
                                      : 's'
                                  }: ${disabledPermissions.join(
                                    ', ',
                                  )}.`,
                                );
                              }

                              setStaffParams({
                                ...staffParams,
                                permission_data: {
                                  role_type:
                                    staffParams.permission_data
                                      ?.role_type!,
                                  station:
                                    staffParams.permission_data
                                      ?.station,
                                  permissions: [
                                    ...newPermissionsState,
                                  ],
                                },
                              });
                            } else {
                              setStaffParams({
                                ...staffParams,
                                permission_data: {
                                  role_type:
                                    staffParams.permission_data
                                      ?.role_type!,
                                  station:
                                    staffParams.permission_data
                                      ?.station,
                                  permissions: [
                                    ...staffParams.permission_data
                                      ?.permissions,
                                    permission,
                                  ],
                                },
                              });
                            }
                          }}
                        />
                      }
                      label={permissionLabels[permission]}
                    />
                  </span>
                </Tooltip>
              </Fragment>
            );
          })}
      </>
    );
  };

  const renderForm = () => {
    return (
      <Grid container item xs={12}>
        <Snackbar
          data-testid='snackbar-notification'
          key={dependencyNotificationMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showDependencyNotification}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setShowDependencyNotification(false);
          }}
          autoHideDuration={4000}
          message={dependencyNotificationMessage}
        />
        <Grid item xs={12}>
          {staffParams.permission_data?.role_type! !==
            PermissionsRoleTypeEnum.OPERATORI && (
            <Fragment>
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Email Address </Typography>
              </Box>
              <TextField
                placeholder={'Email Address'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={onEmailChange}
                helperText={
                  !!staffParams.email && !isValidEmail
                    ? 'Please enter a valid email.'
                    : null
                }
                error={!!staffParams.email && !isValidEmail}
              />
            </Fragment>
          )}
          {(staffParams.permission_data?.role_type! ===
            PermissionsRoleTypeEnum.OPERATORI ||
            !emailAlreadyRegistered) && (
            <>
              <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
                <Typography>First Name</Typography>
              </Box>
              <TextField
                autoFocus
                placeholder={'First Name'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={(e) => {
                  setStaffParams({
                    ...staffParams,
                    first_name: e.target.value,
                  });
                }}
              />
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Last Name</Typography>
              </Box>
              <TextField
                placeholder={'Last Name'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={(e) => {
                  setStaffParams({
                    ...staffParams,
                    last_name: e.target.value,
                  });
                }}
              />
            </>
          )}
        </Grid>
        <Grid item>
          <Box
            data-testid={'operator-tool-permissions-box'}
            pt={2}
            p={0.5}
            pl={0}
          >
            <Box
              fontWeight='fontWeightBold'
              borderBottom='1px solid black'
              mb={1}
            >
              <Typography>Operator Tool Permissions</Typography>
            </Box>
            {renderPermissions(OT_PERMISSIONS_LABELS)}

            <Box
              fontWeight='fontWeightBold'
              borderBottom='1px solid black'
              mb={1}
              mt={1}
            >
              <Typography>Other Permissions</Typography>
            </Box>
            {renderPermissions(OTHER_PERMISSIONS_LABELS)}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      data-testid={'station-staff-add-dialog'}
      open={isOpen}
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      onClose={() => {
        onClose();
      }}
    >
      {error && (
        <Box mt={2} ml={2} mr={2}>
          <AlertBanner
            data-testid={'station-staff-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
            style={{ marginBottom: 0 }}
          />
        </Box>
      )}
      <DialogTitle>
        {!isEmailConfirmationMsg ? (
          <Fragment>
            <Box p={4} pt={2} pb={0.5}>
              <Typography align={'center'} variant={'h6'}>
                Please add the new{' '}
                {configurationUtils.getPageTitle(true, 'STAFF')}'s
                details.
              </Typography>
              <Box p={2} pt={0.5} pb={0}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  style={{ opacity: 0.6 }}
                >
                  The {configurationUtils.getPageTitle(true, 'STAFF')}{' '}
                  will receive a link in the email to complete the
                  registration.
                </Typography>
              </Box>
            </Box>
          </Fragment>
        ) : (
          ''
        )}
      </DialogTitle>
      <DialogContent>
        {isEmailConfirmationMsg ? (
          <Fragment>
            <Box>
              <Box fontWeight='fontWeightBold'>
                <Typography align={'center'} variant={'body1'}>
                  New {configurationUtils.getPageTitle(true, 'STAFF')}{' '}
                  added sucessfully
                </Typography>
              </Box>
              <Box p={18} pt={2} pb={0.5}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  style={{ opacity: 0.6 }}
                >
                  We have sent an email to{' '}
                  <span style={{ color: colors.darkGold }}>
                    {staffParams.email}
                  </span>{' '}
                  to complete the registration process.
                </Typography>
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            {!roleSelected && renderRoleSelection()}
            {roleSelected && renderForm()}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Box pl={1} pr={1}>
          <Button
            data-testid={'station-staff-dialog-close'}
            className={classes.button}
            onClick={() => {
              onClose();
            }}
          >
            {isEmailConfirmationMsg ? 'OK' : 'Cancel'}
          </Button>
          {!isEmailConfirmationMsg && (
            <Button
              data-testid={'station-staff-dialog-add'}
              className={classes.button}
              disabled={
                !emailAlreadyRegistered
                  ? hasNoName ||
                    (hasNoEmail &&
                      staffParams.permission_data!.role_type! !==
                        PermissionsRoleTypeEnum.OPERATORI) ||
                    (!hasNoEmail && !isValidEmail)
                  : hasNoEmail
              }
              onClick={() => {
                onAddStaff(
                  staffParams!,
                  () => {
                    if (!hasNoEmail && !emailAlreadyRegistered) {
                      setIsEmailConfirmationMsg(true);
                    }
                  },
                  emailAlreadyRegistered,
                );
                if (hasNoEmail) onClose();
              }}
            >
              Add
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    cardContent: {
      padding: 0,
      cursor: 'pointer',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    roleName: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    roleDescription: {
      minHeight: '55px',
      fontSize: '0.8rem',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    button: {
      color: colors.darkGold,
    },
    paper: {
      padding: 0,
      margin: 0,
    },
    paperScrollPaper: {
      maxHeight: '100%',
    },
    tooltip: {
      background: colors.dark,
    },
    tooltipArrow: {
      color: colors.dark,
    },
  })),
)(AddStaffDialog);
