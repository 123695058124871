import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner, Typography } from '../../primitives';
import colors from '../../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  DialogTitle,
  createStyles,
  TextField,
  FormControl,
  Theme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DialogInteraction } from '../StationPlanner';

import selectStyles from '../../select/select.styles';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';

//Icons
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

interface Props {
  classes: { [key: string]: string };
  interaction: DialogInteraction;
  showProgress?: boolean;
  fields: { [name: string]: string };
  onClose: () => void;
  onSubmit: (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => void;
  isOpen: boolean;
  error?: string;
  conflicts?: any;
  title?: string;
}

const AddEditPresetDialog: React.FC<Props> = ({
  showProgress = false,
  isOpen,
  interaction,
  fields,
  classes,
  onClose,
  onSubmit,
  error,
  title,
  conflicts,
}) => {
  const [fieldValues, setFieldValues] = useState<{
    [name: string]: string;
  }>({
    name: '',
    recurrence: 'none',
    recurrenceDays: '',
  });
  const [showWeeklyOptions, setShowWeeklyOptions] =
    useState<boolean>();

  const handleSubmit = () => {
    onSubmit({ interaction, fields: fieldValues });
  };

  useEffect(() => {
    setFieldValues(fields);
    setShowWeeklyOptions(fields.recurrence === 'weekly');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={isOpen}
        data-testid='add-edit-shift-preset-row-dialog'
      >
        <DialogTitle
          style={{ textAlign: 'center', padding: '40px 0 20px 0' }}
        >
          {interaction === DialogInteraction.ADD ? (
            <AddToPhotosOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          ) : (
            <EditIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          )}
          <br />
          {title || interaction === DialogInteraction.ADD
            ? 'Add New Preset'
            : 'Update Preset'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                  style={{ marginBottom: 20 }}
                />
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label={
                        <span style={{ color: colors.title }}>
                          Name
                        </span>
                      }
                      value={fieldValues.name}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          name: e.target.value,
                        });
                      }}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{
                      width: '100%',
                      margin: '0 0px 10px 0',
                      height: 50,
                    }}
                  >
                    <InputLabel className={classes.selectLabel}>
                      Recurrence
                    </InputLabel>
                    <Select
                      data-testid='shift-preset-dialog-recurrence-select'
                      disableUnderline={true}
                      classes={{
                        selectMenu: classes.selectMenu,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: classes.selectPaper,
                          list: classes.selectList,
                        },
                      }}
                      value={fieldValues.recurrence}
                      onChange={(e) => {
                        setShowWeeklyOptions(
                          e.target.value === 'weekly',
                        );
                        setFieldValues({
                          ...fieldValues,
                          recurrence: e.target.value as string,
                          recurrenceDays: '',
                        });
                      }}
                    >
                      <MenuItem value='none'>None</MenuItem>
                      <MenuItem value='weekly'>Weekly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {showWeeklyOptions && (
                  <Grid item xs={12}>
                    <span
                      style={{
                        display: 'flex',
                        color: colors.darkGold,
                        width: '100%',
                        margin: '10px 0',
                      }}
                    >
                      Repeat on
                    </span>
                    <Box className={classes.formGroup}>
                      {[
                        'MONDAY',
                        'TUESDAY',
                        'WEDNESDAY',
                        'THURSDAY',
                        'FRIDAY',
                        'SATURDAY',
                        'SUNDAY',
                      ].map((day) => (
                        <FormGroup key={`reccurence-${day}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={(
                                  fieldValues.recurrenceDays || ''
                                )
                                  .split(',')
                                  .includes(day)}
                                onChange={(value) => {
                                  const { checked } = value.target;
                                  setFieldValues({
                                    ...fieldValues,
                                    recurrenceDays: checked
                                      ? [
                                          ...fieldValues.recurrenceDays
                                            .split(',')
                                            .filter((day) => day),
                                          day,
                                        ].join(',')
                                      : fieldValues.recurrenceDays
                                          .split(',')
                                          .filter((d) => d !== day)
                                          .join(','),
                                  });
                                }}
                                value={day}
                              />
                            }
                            label={
                              day.charAt(0) +
                              day.slice(1).toLowerCase()
                            }
                          />
                        </FormGroup>
                      ))}
                    </Box>
                  </Grid>
                )}
                {conflicts?.conflicts?.length > 0 && (
                  <Grid item xs={12}>
                    <Alert
                      style={{
                        background: colors.gold,
                        color: colors.black,
                      }}
                      icon={
                        <ErrorOutlineOutlinedIcon
                          style={{
                            color: colors.black,
                          }}
                        />
                      }
                    >
                      <Typography
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Recurrence overlapping
                      </Typography>
                      {conflicts?.conflicts.map((item: any) => {
                        return (
                          <Typography key={item.name}>
                            <span
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {item.name}
                            </span>
                            {` is also set to repeat on: `}
                            {item.day_of_week?.join(', ')}.
                          </Typography>
                        );
                      })}
                      <Typography
                        style={{
                          marginTop: 15,
                        }}
                      >
                        By saving you agree to override it.
                      </Typography>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            className={classes.containedButton}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!fieldValues.name || !fieldValues.recurrence}
            variant='outlined'
            className={classes.buttonFilled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...selectStyles,
    dialogContent: {
      width: '520px',
      maxWidth: '100%',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',

      '&:hover': {
        color: colors.darkGold,
        background: 'transparent',
      },
    },
    formGroup: {
      maxHeight: 200,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: colors.darkGold,
      },
    },
  })),
)(AddEditPresetDialog);
