import React, { Fragment, useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import colors from '../../utils/colors';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { Typography } from '../primitives';

import selectStyles from '../select/select.outlined.styles';

//Types
import {
  Staff,
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
} from 'cloudsort-client';
import {
  ROLE_LABELS,
  OT_PERMISSIONS_LABELS,
  OTHER_PERMISSIONS_LABELS,
} from '../stationStaff/StationStaff';

//Services

import PermissionsService from '../../services/Permissions.service';

//Icons

import { permissionEntity } from './Profile';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';

interface Props {
  classes: { [key: string]: string };
  isOwnProfile: boolean;
  staffData: Staff;
  setStaffData: (staff: Staff) => void;
  setEnableSave: (enabled: boolean) => void;
  getProfileData: (stationId?: number, orgId?: number) => void;
  assignedEntities: permissionEntity[];
  selectedEntity?: permissionEntity;
  setSelectedEntity: (entity: permissionEntity) => void;
}

const RolesPermissions: React.FC<Props> = ({
  classes,
  isOwnProfile,
  staffData,
  setStaffData,
  setEnableSave,
  getProfileData,
  assignedEntities,
  selectedEntity,
  setSelectedEntity,
}) => {
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();
  const [isViewReady, setIsViewReady] = useState<boolean>(false);

  const selectRole = (role: PermissionsRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffData({
      ...staffData,
      permission_data: {
        ...staffData?.permission_data,
        role_type: role,
        permissions: defaultPermissions,
      },
    });
  };

  useEffect(() => {
    if (assignedEntities.length === 1) {
      setSelectedEntity(assignedEntities[0]);
      if (assignedEntities[0].type === 'STATION') {
        getProfileData(assignedEntities[0].id);
      } else {
        getProfileData(undefined, assignedEntities[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (staffData?.permission_data?.permissions) {
      setIsViewReady(true);
    }
  }, [staffData]);

  const renderPermissionsListAsCheckBoxes = (
    title: string,
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffData!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <>
        <Typography
          variant='h3'
          style={{
            marginBottom: '6px',
            fontSize: ' 0.75rem',
          }}
        >
          {title}
        </Typography>
        <hr
          className={classes.divider}
          style={{ margin: '0 0 18px 0' }}
        />
        {Object.keys(PermissionsPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionsToRender).includes(permission),
          )
          .map((key) => {
            return PermissionsPermissionsEnum[
              key as keyof typeof PermissionsPermissionsEnum
            ];
          })
          .map((permission) => {
            return (
              <Fragment
                key={`fragment-${permissionLabels[permission]}`}
              >
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    dependencyStatus[permission].enabled
                      ? ''
                      : dependencyStatus[permission].tooltipMessage
                  }
                  placement='top-start'
                >
                  <span>
                    {staffData?.permission_data?.permissions && (
                      <FormControlLabel
                        key={permissionLabels[permission]}
                        style={{ width: '100%' }}
                        control={
                          <Checkbox
                            disabled={
                              !selectedEntity?.id ||
                              isOwnProfile ||
                              !dependencyStatus[permission].enabled
                            }
                            color={undefined}
                            key={`checkbox-${permissionLabels[permission]}`}
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            checked={staffData.permission_data?.permissions.some(
                              (selectedPermission) =>
                                selectedPermission === permission,
                            )}
                            onChange={(e) => {
                              const isAlreadySelected =
                                staffData.permission_data?.permissions!.some(
                                  (selectedPermission) =>
                                    selectedPermission === permission,
                                );
                              if (isAlreadySelected) {
                                const {
                                  newPermissionsState,
                                  disabledPermissions,
                                } = disablePermissionAndDependants(
                                  permission,
                                  staffData!.permission_data!
                                    .permissions as string[],
                                );

                                //All dependencies are disabled, show notification to the user.
                                if (disabledPermissions.length > 0) {
                                  setShowDependencyNotification(true);
                                  setDependencyNotificationMessage(
                                    `Your selection also disabled the dependant permission${
                                      disabledPermissions.length === 1
                                        ? ''
                                        : 's'
                                    }: ${disabledPermissions.join(
                                      ', ',
                                    )}.`,
                                  );
                                }
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    ...staffData.permission_data,
                                    permissions: [
                                      ...newPermissionsState,
                                    ],
                                  },
                                });
                              } else {
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    ...staffData.permission_data,
                                    permissions: [
                                      ...staffData.permission_data
                                        ?.permissions,
                                      permission,
                                    ],
                                  },
                                });
                              }
                              setEnableSave(true);
                            }}
                          />
                        }
                        label={permissionLabels[permission]}
                      />
                    )}
                  </span>
                </Tooltip>
              </Fragment>
            );
          })}
      </>
    );
  };

  return isViewReady ? (
    <Grid container>
      <Snackbar
        data-testid='snackbar-notification'
        key={dependencyNotificationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showDependencyNotification}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setShowDependencyNotification(false);
        }}
        autoHideDuration={4000}
        message={dependencyNotificationMessage}
      />
      <Grid item sm={6} xs={12}>
        <FormControl
          className={classes.formControl}
          style={{ width: '100%', margin: 0 }}
        >
          <Select
            disableUnderline={true}
            readOnly={assignedEntities.length === 1}
            onChange={(e: React.BaseSyntheticEvent) => {
              if (e.target.value !== 'none') {
                const selected = assignedEntities?.find(
                  (entity) => entity.name === e.target.value,
                );
                if (selected) {
                  setSelectedEntity(selected);
                  if (selected.type === 'STATION') {
                    getProfileData(selected.id);
                  } else {
                    getProfileData(undefined, selected.id);
                  }
                }
              }
            }}
            classes={{
              selectMenu: classes.selectMenu,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: {
                paper: classes.selectPaper,
                list: classes.selectList,
              },
              style: { width: '100%' },
            }}
            value={selectedEntity?.name ?? 'none'}
          >
            <MenuItem key={`fragment-undefined`} value='none'>
              Select Entity
            </MenuItem>
            {assignedEntities.map((entity) => {
              return (
                <MenuItem
                  key={`fragment-${entity.type}-${entity.id}`}
                  value={entity.name}
                >
                  {entity.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>{' '}
        <Typography
          variant='h3'
          style={{
            marginBottom: '6px',
            fontSize: ' 0.75rem',
          }}
        >
          Role
        </Typography>
        <hr
          className={classes.divider}
          style={{ margin: '0 0 18px 0' }}
        />
        <FormControl
          className={classes.formControl}
          style={{ width: '100%', margin: 0 }}
        >
          <Select
            disabled={!selectedEntity?.id || isOwnProfile}
            disableUnderline={true}
            onChange={(e: React.BaseSyntheticEvent) => {
              if (e.target.value !== '') {
                selectRole(e.target.value);
                setEnableSave(true);
              }
            }}
            classes={{
              selectMenu: classes.selectMenu,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: {
                paper: classes.selectPaper,
                list: classes.selectList,
              },
              style: { width: '100%' },
            }}
            value={staffData?.permission_data!.role_type ?? 'none'}
          >
            <MenuItem
              key={`fragment-undefined`}
              value='none'
              disabled
            >
              No Role
            </MenuItem>
            {Object.keys(PermissionsRoleTypeEnum)
              .filter((key) => key !== 'SUPERUSER' && key !== 'BLANK')
              .map((key) => {
                return {
                  label:
                    ROLE_LABELS[
                      key as keyof typeof PermissionsRoleTypeEnum
                    ],
                  value:
                    PermissionsRoleTypeEnum[
                      key as keyof typeof PermissionsRoleTypeEnum
                    ],
                };
              })
              .map((permission) => {
                return (
                  <MenuItem
                    key={`fragment-${permission.label}`}
                    value={permission.value}
                  >
                    {permission.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {renderPermissionsListAsCheckBoxes(
          'Operator Tool permissions',
          OT_PERMISSIONS_LABELS,
        )}
        {renderPermissionsListAsCheckBoxes(
          'Other permissions',
          OTHER_PERMISSIONS_LABELS,
        )}
      </Grid>
    </Grid>
  ) : (
    <ProgressIndicator />
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,

    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    divider: {
      width: '100%',
      border: 'none',
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    tooltip: {
      background: colors.dark,
    },
    tooltipArrow: {
      color: colors.dark,
    },
  })),
)(RolesPermissions);
