import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  innerContainer: {
    textAlign: 'center',
  },
  errorBackground: {
    display: 'block',
    backgroundImage: `url(${`${
      process.env.REACT_APP_BASENAME || ''
    }/loginAssets/bg.jpg`})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%',
  },
  errorLogo: {
    margin: '5%',
    height: 'auto',
    maxWidth: '275px',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      margin: '7%',
    },
  },
  errorMessage: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  errorNumber: {
    fontWeight: 400,
    fontSize: '9rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '12rem',
    },
    letterSpacing: 0,
    color: colors.darkGold,
  },
  notFound: {
    color: colors.darkGold,
    margin: '10px 35px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '5.5rem',
    },
  },
  errorRedirect: {
    color: colors.white,
    margin: '10px 35px',
    fontWeight: 400,
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.8rem',
    },
  },
}));
