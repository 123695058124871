import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes } from '../../interfaces/routes';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import configurationUtils from '../../utils/configurationUtils';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Typography from '../primitives/typography/Typography';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { AlertBanner } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import selectStyles from '../select/select.styles';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';

//Services
import RoutesService from '../../services/Routes.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import DockDoorService from '../../services/DockDoors.service';

//Types
import { Column, TypeAheadItem } from '../../interfaces/components';
import {
  DockDoorDetails,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import { debounce } from 'lodash';
import { noOptionsMessage } from '../asyncSelect/utils';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Routes: React.FC<Props> = ({ classes, location }) => {
  const COLUMNS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'name',
      label: 'Name',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'stations_count',
      label: `${configurationUtils.getPageTitle(
        false,
        'STOP',
      )} Count`,
      width: 200,
      align: 'center',
    },
    {
      id: 'outboundloads_count',
      label: `${configurationUtils.getPageTitle(
        false,
        'OUTBOUND_LOAD',
      )} Count`,
      width: 200,
      align: 'center',
    },
  ];

  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [selectedName, setSelectedName] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [dockDoor, setDockDoor] = useState<TypeAheadItem>();

  const fetchRoutes = (
    page: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    return RoutesService.getAll({
      page,
      pageSize: rowsPerPage,
      search: filterByString,
    });
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
    setSelectedName('');
    setDockDoor(undefined);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const addHandler = async () => {
    setShowProgress(true);
    try {
      if (selectedName) {
        await RoutesService.create({
          name: selectedName,
          station: EphemeralStateService.getMyStationId(),
          default_dockdoor: dockDoor?.value
            ? Number(dockDoor.value)
            : undefined,
        });
        setLastUpdated(new Date().toISOString());
        onAfterDialogClose();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogContent style={{ padding: inXsScreen ? 16 : 0 }}>
          <DialogTitle>
            New {configurationUtils.getPageTitle(true, 'ROUTE')}
          </DialogTitle>

          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <div
            style={{
              padding: inXsScreen ? '10px 0px' : '10px 24px',
            }}
          >
            <FormControl
              className={classes.formControl}
              style={{ width: '100%', margin: '0 0px 10px 0' }}
            >
              <TextField
                data-testid={'routes-dialog-name'}
                autoFocus
                label={
                  <span style={{ color: colors.title }}>Name</span>
                }
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
                FormHelperTextProps={{
                  style: { paddingLeft: '5px', marginTop: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
                style={{ width: '300px', maxWidth: '100%' }}
              />
            </FormControl>
            {configurationUtils.isModuleActive('DOCK_DOOR') && (
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                  margin: 0,
                  height: 50,
                }}
                data-testid='select-dock-door'
              >
                <InputLabel
                  className={classes.selectLabel}
                  style={{ fontSize: 12, top: '-18px' }}
                >
                  Default{' '}
                  {configurationUtils.getModuleLabel(
                    true,
                    'DOCK_DOOR',
                  )}
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  isClearable
                  cacheOptions
                  styles={{
                    ...asyncSelectStyles,
                    menuPortal: (styles: any) => {
                      return {
                        ...styles,
                        zIndex: 9999,
                      };
                    },
                    input: (styles: any) => {
                      return {
                        ...styles,
                        margin: '0 -3px',
                      };
                    },
                    placeholder: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.lightGray,
                        top: '65%',
                      };
                    },
                    control: (styles: any, state: any) => {
                      return {
                        ...styles,
                        backgroundColor: 'transparent !important',
                        background: '#F1F1F1',
                        padding: '10px 0 0 0',
                        boxShadow: 'none !important',
                        outline: `none !important`,
                        border: 0,
                      };
                    },
                  }}
                  loadOptions={loadDDOptions}
                  onChange={(option) => {
                    setDockDoor(option as TypeAheadItem);
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  value={dockDoor}
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </FormControl>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            data-testid={'routes-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addHandler();
            }}
            data-testid={'routes-dialog-add'}
            disabled={!selectedName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.ROUTEREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
  ${configurationUtils.getPageTitle(false, 'ROUTE')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='ROUTE'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.ROUTEWRITE,
            ) && (
              <Button
                data-testid='add-route-btn'
                variant='outlined'
                className={classes.containedButton}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add
              </Button>
            )}
          </Grid>
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          filterByString={true}
          filterByStringPlaceholder={`Search ${configurationUtils.getPageTitle(
            false,
            'ROUTE',
          )}`}
          dataTestIdPrefix={'routes'}
          fetch={fetchRoutes}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.ROUTE}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      minWidth: 600,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
  })),
)(Routes);
