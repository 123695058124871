import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '../primitives';
import Paper from '@material-ui/core/Paper';
import colors from '../../utils/colors';
import {
  createStyles,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import detailsPageStyle from '../commonStyles/detailsPage.style';
import CircularProgressWithLabel from '../primitives/circularProgressWithLabel/CircularProgressWithLabel';

interface Props {
  classes: { [key: string]: string };
  title: string;
  expected: number;
  arrived: number;
  predictedToArrive: number;
  predictedToArriveAndArrived: number;
}

const ArrivalPredictionStats: React.FC<Props> = ({
  classes,
  title,
  expected,
  arrived,
  predictedToArrive,
  predictedToArriveAndArrived,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container style={{ marginBottom: 8 }} spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.predictionsSectionTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper
          className={classes.paper}
          style={{ padding: '10px 13px' }}
        >
          <Grid container spacing={0}>
            <Grid item xs={6} sm={10}>
              <Typography className={classes.primaryTitle}>
                Manifested
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              container
              direction='row'
              alignItems='center'
              justify='flex-end'
            >
              <CircularProgressWithLabel
                value={(arrived / expected) * 100 || 0}
                tooltipText={`Percentage of manifested ${title} that arrived.`}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.secondaryTitle}>
                Total
              </Typography>
              <Typography className={classes.totalValue}>
                {expected}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className={isMobileView ? '' : classes.divider}
            >
              <Typography
                align='right'
                className={classes.secondaryTitle}
              >
                Arrived
              </Typography>
              <Typography
                align='right'
                className={classes.arrivedValue}
              >
                {arrived}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper
          className={classes.paper}
          style={{ padding: '10px 15px' }}
        >
          <Grid container spacing={0}>
            <Grid item xs={6} sm={10}>
              <Typography className={classes.primaryTitle}>
                Predicted to arrive
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              container
              direction='row'
              alignItems='center'
              justify='flex-end'
            >
              <CircularProgressWithLabel
                value={
                  (predictedToArriveAndArrived / predictedToArrive) *
                    100 || 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.secondaryTitle}>
                Total
              </Typography>
              <Typography className={classes.totalValue}>
                {predictedToArrive}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className={isMobileView ? '' : classes.divider}
            >
              <Typography
                align='right'
                className={classes.secondaryTitle}
              >
                Arrived
              </Typography>
              <Typography
                align='right'
                className={classes.arrivedValue}
              >
                {predictedToArriveAndArrived}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    arrivedValue: {
      color: colors.green,
      fontSize: '18px',
    },
    totalValue: {
      color: colors.black,
      fontSize: '18px',
    },
    primaryTitle: {
      color: colors.black,
      fontSize: '14px',
    },
    secondaryTitle: {
      color: colors.lightGray,
      fontSize: '14px',
    },
    predictionsSectionTitle: {
      fontSize: '14px',
      color: colors.dark,
      fontWeight: 700,
    },
    divider: {
      borderLeft: `1px solid ${colors.lightGray}`,
    },
  })),
)(ArrivalPredictionStats);
