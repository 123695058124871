import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AreaByQueryAreaTypeEnum,
  Container,
  ContainerContainerTypeEnum,
  ContainerLastProcessStepEnum,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { AuthRoutes } from '../../interfaces/routes';
import {
  renderContainerLocationString,
  renderTimestampString,
  renderVolumeString,
} from '../DetailsPagesFunctions';
import queryString from 'query-string';
import configurationUtils from '../../utils/configurationUtils';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import clx from 'classnames';
import colors from '../../utils/colors';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import {
  Box,
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

// Services
import ContainerService, {
  ContainersSortBy,
} from '../../services/Containers.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

//Icons
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

//Misc
import { Helmet } from 'react-helmet';

export const formatLabel = (str?: string) => {
  if (str === 'N/A') return str;
  const label = str
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

const getStatusColor = (status: ContainerLastProcessStepEnum) => {
  switch (status) {
    case ContainerLastProcessStepEnum.OPENED:
      return colors.green;
    case ContainerLastProcessStepEnum.DISPATCHED:
      return colors.darkGold;
    default:
      return colors.dark;
  }
};

export const formatContainer = (container: any) => {
  const containerObj = container as Container;
  container.load_point = containerObj.load_point || common.emptyValue;

  const { station_name } = containerObj;

  container.volume = renderVolumeString(containerObj.volume);

  container.dwell_time = container.last_process_step_time
    ? utils.getLongestDwellTimeLabel(container.last_process_step_time)
    : common.emptyValue;

  container.type = 'N/A';
  if (containerObj.outbound) {
    container.type = (
      <Grid container justify='flex-start' spacing={1}>
        <Grid item>
          <Typography
            style={{
              fontSize: 14,
            }}
          >
            Outbound
          </Typography>
        </Grid>
        <Grid item>
          <PlayCircleFilledIcon
            style={{
              color: colors.darkGold,
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    container.type = (
      <Grid container justify='flex-start' spacing={2}>
        <Grid item>
          <Typography
            style={{
              fontSize: 14,
            }}
          >
            Inbound
          </Typography>
        </Grid>
        <Grid item>
          <PlayCircleFilledIcon
            style={{
              transform: 'rotate(-180deg)',
              color: colors.dark,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  container.location = renderContainerLocationString(
    station_name,
    containerObj?.area?.name,
    containerObj?.area?.area_type === AreaByQueryAreaTypeEnum.PRIMARY
      ? undefined
      : containerObj?.zone?.name,
    containerObj.container_status,
    containerObj?.load_point?.name,
  );
  container.critical_activity_time = renderTimestampString(
    containerObj.critical_activity_time,
  );

  container.container_type = formatLabel(
    containerObj.container_type || '',
  );
  container.is_parent_container = containerObj.is_parent_container
    ? 'Yes'
    : 'No';

  container.children_count =
    containerObj.children_count || common.emptyValue;

  container.display_status = (
    <Typography
      style={{
        color: getStatusColor(container.last_process_step),
        fontSize: 14,
      }}
    >
      {formatLabel(container.last_process_step || '')}
    </Typography>
  );
};

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Containers: React.FC<Props> = ({ classes, location }) => {
  const [stationModules, setStationModules] = useState<string[]>();

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const isBOLActive = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.LOAD_OPS.bol_active;
  }, []);

  // Get labels on each render cycle
  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
    {
      id: 'is_parent_container',
      label: configurationUtils.getModuleLabel(
        true,
        'GROUP_CONTAINER',
      ),
      align: 'center',
      width: 'left',
      hide: !configurationUtils.isModuleActive('GROUP_CONTAINER'),
    },
    {
      id: 'children_count',
      label: `${configurationUtils.getModuleLabel(
        false,
        'CONTAINER',
      )} Count`,
      align: 'center',
      width: 'left',
      hide: !configurationUtils.isModuleActive('GROUP_CONTAINER'),
    },
  ];

  if (isBOLActive) {
    COLUMNS_CONTAINERS.push({
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
    });
  }

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ContainerService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      sortBy: (sortedBy as ContainersSortBy) || undefined,
      owner:
        selectedFilters &&
        selectedFilters.owner &&
        selectedFilters.owner.values.length
          ? selectedFilters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        selectedFilters &&
        selectedFilters.carrier &&
        selectedFilters.carrier.values.length
          ? selectedFilters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        selectedFilters &&
        selectedFilters.route &&
        selectedFilters.route.values.length
          ? selectedFilters.route.values.map((item: any) => item.id)
          : undefined,
      destination:
        selectedFilters &&
        selectedFilters.stop &&
        selectedFilters.stop.values.length
          ? selectedFilters.stop.values.map((item: any) => item.id)
          : undefined,
      area:
        selectedFilters &&
        selectedFilters.area &&
        selectedFilters.area.values.length
          ? Number(selectedFilters.area.values[0].id)
          : undefined,
      hidePending:
        selectedFilters &&
        selectedFilters.hidePending &&
        selectedFilters.hidePending.values.length
          ? selectedFilters.hidePending.values[0].id === 'yes'
            ? true
            : selectedFilters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
      processStep:
        selectedFilters &&
        selectedFilters.containerProcessStep &&
        selectedFilters.containerProcessStep.values.length
          ? selectedFilters.containerProcessStep.values.map(
              (item: any) => item.id,
            )
          : undefined,
      containerType:
        selectedFilters &&
        selectedFilters.format &&
        selectedFilters.format.values.length
          ? (selectedFilters.format.values[0]
              .id as ContainerContainerTypeEnum)
          : undefined,
      outbound:
        selectedFilters &&
        selectedFilters.type &&
        selectedFilters.type.values.length
          ? selectedFilters.type.values[0].id === 'outbound'
            ? true
            : selectedFilters.type.values[0].id === 'inbound'
            ? false
            : undefined
          : undefined,
      defects:
        selectedFilters &&
        selectedFilters.containerDefect &&
        selectedFilters.containerDefect.values.length
          ? selectedFilters.containerDefect.values.map(
              (item: any) => item.id,
            )
          : undefined,

      //outbound: fOutbound,containerType: fType as ContainerContainerTypeEnum,
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });

    return res;
  };

  const keyHash = _.isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.CONTAINERREAD,
    );

    setStationModules(
      configurationUtils
        .getConfigArray({
          config:
            EphemeralStateService.getMyStationConfiguratation()
              .GENERAL.MODULES,
          filter: { key: 'active', value: true },
        })
        .map((item: any) => item.id),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
        ${configurationUtils.getPageTitle(false, 'CONTAINER')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='CONTAINER'>
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              className={clx(
                classes.outlinedButton,
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map((item: any) =>
                  item.values.length ? (
                    <Box
                      key={item.key}
                      className={classes.filterBadge}
                    >
                      <b>{item.label}</b>
                      {item.values.map((value: any) => (
                        <span
                          style={{ marginLeft: '5px' }}
                          key={`${value.name || value.full_name}-${
                            value.id
                          }`}
                        >
                          {value.name || value.full_name}
                          <Button
                            className={classes.filterBadgeButton}
                            onClick={() => {
                              setFilterToRemove({
                                filterKey: item.key,
                                filterValue: value.id,
                              });
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </span>
                      ))}
                    </Box>
                  ) : undefined,
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!!stationModules &&
          !!filterLastUpdate && ( //make sure modules config is loaded
            <PaginatedTable
              key={'table' + filterLastUpdate}
              tableKey={'table' + filterLastUpdate}
              title={''}
              columns={COLUMNS_CONTAINERS}
              dataTestIdPrefix={'containers'}
              fetch={fetchContainers}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.CONTAINER}
              defaultSort={
                (queryString.parse(location.search)[
                  'sortBy'
                ] as string) || undefined
              }
              sortableBy={[
                'process_step_time',
                'outbound_load_time',
                'critical_activity_time',
              ]}
            />
          )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...filterBadgeStyle,
    ...sectionPageBaseStyle,
    headerFilterButton: {
      color: colors.black,
      backgroundColor: colors.veryLightGray,
      borderColor: colors.veryLightGray,
      minWidth: '40px',
      marginLeft: '20px',
    },
    defaultFontSize: {
      fontSize: 14,
    },
  })),
)(Containers);
