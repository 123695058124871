import React, { useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import colors from '../../utils/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AuthRoutes } from '../../interfaces/routes';
import { Column } from '../../interfaces/components';
import { AlertBanner, Typography } from '../primitives/index';
import MuiButton from '@material-ui/core/Button';
import ErrorHandler from '../../utils/ErrorHandler';
import { Grid } from '@material-ui/core';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import Layout from '../layout/Layout';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import queryString from 'query-string';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import selectStyles from '../select/select.styles';
import configurationUtils from '../../utils/configurationUtils';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';

// Types
import {
  AreaAreaTypeEnum,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

// Services
import AreasService from '../../services/Areas.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';

const COLUMNS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: 50,
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    width: 150,
    align: 'center',
  },
  {
    id: 'area_type',
    label: 'Type',
    width: 'auto',
    align: 'center',
  },
];

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Areas: React.FC<Props> = ({ classes, location }) => {
  const areaTypes = Object.entries(AreaAreaTypeEnum);

  const [selectedAreaType, setSelectedAreaType] = useState<
    AreaAreaTypeEnum | undefined
  >(AreaAreaTypeEnum.PRIMARY);
  const [selectedName, setSelectedName] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const addHandler = async () => {
    setShowProgress(true);
    try {
      if (selectedName && selectedAreaType) {
        await AreasService.create({
          name: selectedName,
          area_type: selectedAreaType,
          station: EphemeralStateService.getMyStationId(),
        });
        setLastUpdated(new Date().toISOString());
        onAfterDialogClose();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);

    setSelectedAreaType(AreaAreaTypeEnum.PRIMARY);
    setSelectedName('');
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getEnumLabel = (str: string) => {
    return str.charAt(0) + str.slice(1).toLowerCase();
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogContent>
          <DialogTitle
            style={{ padding: inXsScreen ? '16px 0px' : '16px 24px' }}
          >
            New {configurationUtils.getPageTitle(true, 'AREA')}
          </DialogTitle>

          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <div
            style={{
              padding: inXsScreen ? '10px 0px' : '10px 24px',
            }}
          >
            <FormControl
              className={classes.formControl}
              style={{ width: '100%', margin: '0 0px 10px 0' }}
            >
              <TextField
                data-testid='add-area-dialog:name'
                autoFocus
                label={
                  <span style={{ color: colors.title }}>Name</span>
                }
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
                error={
                  configurationUtils.isModuleNameValidated('AREA') &&
                  !/^[A-Z][0-9]{1,3}$/.test(selectedName || '')
                }
                helperText={
                  configurationUtils.isModuleNameValidated('AREA')
                    ? 'A capital letter followed by up to 3 digits.'
                    : undefined
                }
                FormHelperTextProps={{
                  style: { paddingLeft: '5px', marginTop: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
              />
            </FormControl>
            <FormControl
              className={classes.formControl}
              style={{
                width: '100%',
                margin: '0 0px 10px 0',
                height: 50,
              }}
            >
              <InputLabel className={classes.selectLabel}>
                Type
              </InputLabel>
              <Select
                data-testid='add-area-dialog:type'
                disableUnderline={true}
                onChange={(e: React.BaseSyntheticEvent) => {
                  setSelectedAreaType(e.target.value);
                }}
                classes={{
                  selectMenu: classes.selectMenu,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: classes.selectPaper,
                    list: classes.selectList,
                  },
                }}
                value={selectedAreaType}
              >
                {areaTypes.map((type) => (
                  <MenuItem
                    key={type[1]}
                    value={type[1]}
                    data-testid={`add-area-dialog:type:${getEnumLabel(
                      type[0],
                    )}`}
                  >
                    {getEnumLabel(type[0])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <MuiButton
            data-testid={'areas-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              addHandler();
            }}
            data-testid={'areas-dialog-add'}
            disabled={
              !selectedName ||
              (configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(selectedName || '')) ||
              !selectedAreaType
            }
          >
            Add
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    const res = await AreasService.getAll(pageIndex, rowsPerPage);
    res.data.results.forEach((area: any) => {
      area.area_type = getEnumLabel(area.area_type);
    });

    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${configurationUtils.getPageTitle(
            false,
            'AREA',
          )} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.STATIONWRITE,
            ) && (
              <MuiButton
                data-testid='areas:add-area:dialog-button'
                variant='outlined'
                className={classes.containedButton}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add
              </MuiButton>
            )}
          </Grid>
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          dataTestIdPrefix={'areas'}
          fetch={fetch}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.AREA.replace('/', '')}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      minWidth: 600,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
  })),
)(Areas);
