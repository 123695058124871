import React, { Fragment, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import generatePassword from 'generate-password';
import PasswordRequirementsIndicator, {
  validatePassword,
} from '../passwordRequirementsIndicator/PasswordRequirementsIndicator';
import validateEmail from '../../utils/validateEmail';

import colors from '../../utils/colors';
import { Grid } from '@material-ui/core';
import { Typography, CustomTextField } from '../primitives';
import Button from '@material-ui/core/Button';

import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import detailsPageStyle from '../commonStyles/detailsPage.style';

//Types
import { Staff, AssignedStation } from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  isOwnProfile: boolean;
  staffData: Staff;
  staffEditData: Staff;
  setStaffEditData: (staff: Staff) => void;
  setError: (error: string) => void;
  setPrompt: (prompt?: string) => void;
  setEnableSave: (enabled: boolean) => void;
  setShowAssignDialog: (show: boolean) => void;
}

const PersonalInfo: React.FC<Props> = ({
  classes,
  isOwnProfile,
  staffData,
  staffEditData,
  setStaffEditData,
  setError,
  setPrompt,
  setEnableSave,
  setShowAssignDialog,
}) => {
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [
    showPasswordSection,
    setShowPasswordSection,
  ] = useState<boolean>(false);
  const [
    usingGeneratedPassword,
    setUsingGeneratedPassword,
  ] = useState<boolean>(false);
  const [
    firstPasswordUpdate,
    setfirstPasswordUpdate,
  ] = useState<boolean>(true);

  const onGeneratePasswordClick = () => {
    const password = generatePassword.generate({
      length: 16,
      numbers: true,
      lowercase: true,
      uppercase: true,
    });
    setStaffEditData({
      ...staffEditData,
      password,
    });
    setConfirmPassword(password);
    setUsingGeneratedPassword(true);
    setfirstPasswordUpdate(true);
    setPrompt(undefined);
    setEnableSave(true);
  };

  const onPasswordChange = (e: React.BaseSyntheticEvent) => {
    if (firstPasswordUpdate) {
      setUsingGeneratedPassword(false);
      setConfirmPassword('');
      setfirstPasswordUpdate(false);
    }
    e.persist();
    setError('');
    setPrompt(undefined);
    setStaffEditData({
      ...staffEditData,
      password: e.target.value,
    });
    if (
      e.target.value === confirmPassword &&
      validatePassword(e.target.value)
    )
      setEnableSave(true);
    else setEnableSave(false);
  };

  const handleGoToDashboard = async (id: number) => {
    await globalStationOptionsUtils.setStationData(id);
    setTimeout(() => {
      browserHistory.push(AuthRoutes.DASHBOARD);
    }, 0);
  };

  return (
    <Grid container spacing={6}>
      <Grid item sm={6} xs={12}>
        <Typography
          variant='h3'
          style={{
            marginBottom: '6px',
            fontSize: ' 0.75rem',
          }}
        >
          Personal Info
        </Typography>
        <hr
          className={classes.divider}
          style={{ margin: '0 0 18px 0' }}
        />
        <form className={classes.form} noValidate>
          <CustomTextField
            variant='outlined'
            inputProps={{
              'data-testid': 'update-profile-first-name',
              type: 'text',
            }}
            containerProps={{ style: { maxWidth: '360px' } }}
            required
            label='First Name'
            name='first_name'
            autoComplete='first_name'
            value={staffEditData.first_name}
            onChange={(e: React.BaseSyntheticEvent) => {
              e.persist();
              setError('');
              setPrompt(undefined);
              setStaffEditData({
                ...staffEditData,
                first_name: e.target.value,
              });
              if (staffEditData.first_name !== '')
                setEnableSave(true);
              else setEnableSave(false);
            }}
          />

          <CustomTextField
            variant='outlined'
            inputProps={{
              'data-testid': 'update-profile-last-name',
              type: 'text',
            }}
            containerProps={{ style: { maxWidth: '360px' } }}
            required
            label='Last Name'
            name='last_name'
            autoComplete='last_name'
            value={staffEditData.last_name}
            onChange={(e: React.BaseSyntheticEvent) => {
              e.persist();
              setError('');
              setPrompt(undefined);
              setStaffEditData({
                ...staffEditData,
                last_name: e.target.value,
              });
              if (staffEditData.last_name !== '') setEnableSave(true);
              else setEnableSave(false);
            }}
          />

          <CustomTextField
            variant='outlined'
            inputProps={{
              'data-testid': 'update-profile-email',
              type: 'text',
            }}
            containerProps={{ style: { maxWidth: '360px' } }}
            required
            label='Email'
            name='edit_email'
            autoComplete='edit_email'
            value={staffEditData.email}
            onChange={(e: React.BaseSyntheticEvent) => {
              e.persist();
              setError('');
              setPrompt(undefined);
              setStaffEditData({
                ...staffEditData,
                email: e.target.value,
              });
              if (
                staffEditData.email !== '' &&
                validateEmail(staffEditData.email)
              )
                setEnableSave(true);
              else setEnableSave(false);
            }}
            validatorFunction={validateEmail}
            validatorError={`The email address you've entered doesn't seem right. Please check spelling.`}
          />
          <Typography
            variant='body1'
            style={{
              fontSize: '0.75rem',
              margin: '-12px 0 24px 0',
            }}
          >
            If you update your email address, we'll send an email to
            your new address to verify it.
            <br />
            <b>
              The new address will not become active until confirmed.
            </b>
          </Typography>

          {!showPasswordSection && isOwnProfile && (
            <Fragment>
              <Typography variant='h4'>Password</Typography>
              <Button
                data-testid='profile-set-password-button'
                className={classes.containedButton}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPasswordSection(true);
                }}
                style={{ marginLeft: '0' }}
              >
                Set new password
              </Button>
            </Fragment>
          )}
          {showPasswordSection && (
            <div className={classes.passwordUpdateContainer}>
              <CustomTextField
                variant='outlined'
                inputProps={{
                  'data-testid': 'update-profile-password1',
                  type: usingGeneratedPassword ? 'text' : 'password',
                }}
                containerProps={{
                  style: { maxWidth: '360px' },
                }}
                required
                label='New Password'
                name='password'
                autoComplete='password'
                value={staffEditData.password}
                onChange={onPasswordChange}
                onClick={(e: React.BaseSyntheticEvent) => {
                  usingGeneratedPassword && e.target.select();
                }}
                labelButton='Generate Random Password'
                labelButtonAction={onGeneratePasswordClick}
                buttonProps={{
                  tabIndex: 1,
                }}
              />
              {!usingGeneratedPassword && (
                <Fragment>
                  {' '}
                  <CustomTextField
                    variant='outlined'
                    inputProps={{
                      'data-testid': 'update-profile-password2',
                      type: 'password',
                    }}
                    containerProps={{
                      style: { maxWidth: '360px' },
                    }}
                    required
                    label='Confirm Password'
                    name='confirmPassword'
                    autoComplete='password'
                    value={confirmPassword}
                    onChange={(e: React.BaseSyntheticEvent) => {
                      e.persist();
                      setError('');
                      setPrompt(undefined);
                      setConfirmPassword(e.target.value);
                      if (staffEditData.password === e.target.value)
                        setEnableSave(true);
                      else setEnableSave(false);
                    }}
                  />
                  <PasswordRequirementsIndicator
                    password1={staffEditData.password}
                    password2={confirmPassword}
                    checkForMatchingPasswords={true}
                  ></PasswordRequirementsIndicator>
                </Fragment>
              )}
            </div>
          )}
        </form>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography
          variant='h3'
          style={{
            marginBottom: '6px',
            fontSize: ' 0.75rem',
          }}
        >
          Assigned Stations
        </Typography>
        <hr
          className={classes.divider}
          style={{ margin: '0 0 18px 0' }}
        />
        {!staffData.assigned_stations?.length && (
          <Typography
            variant='body1'
            style={{ marginBottom: '20px' }}
          >
            No stations has been assigned to this user.
          </Typography>
        )}
        {staffData.assigned_stations &&
          staffData.assigned_stations.map(
            (station: AssignedStation) => {
              return (
                <div
                  key={'assigned-station-' + station.id}
                  style={{ marginBottom: '10px' }}
                >
                  <span className={classes.stationBadge}>
                    {station.name}
                  </span>
                  <Typography
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      color: colors.darkGold,
                    }}
                    onClick={(e: React.SyntheticEvent) => {
                      e.preventDefault();
                      handleGoToDashboard(station.id!);
                    }}
                  >
                    Go to {station.name} Dashboard
                  </Typography>
                </div>
              );
            },
          )}
        {!isOwnProfile && (
          <Button
            data-testid='profile-set-password-button'
            className={classes.containedButton}
            onClick={(e) => {
              e.preventDefault();
              setShowAssignDialog(true);
            }}
            style={{ marginLeft: '0' }}
          >
            Assign New Station
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    divider: {
      width: '100%',
      border: 'none',
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    stationBadge: {
      border: `2px solid ${colors.black}`,
      borderRadius: '5px',
      color: colors.black,
      padding: theme.spacing(1, 2),
      marginRight: theme.spacing(1),
      display: 'inline-block',
    },
    passwordUpdateContainer: {
      maxWidth: '360px',
    },
  })),
)(PersonalInfo);
