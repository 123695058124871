import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import FormControl from '@material-ui/core/FormControl';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  useTheme,
  useMediaQuery,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';

import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DockDoorService from '../../services/DockDoors.service';
import clx from 'classnames';

import {
  DockDoorDetails,
  InboundLoad,
  InboundLoadLoadStatusEnum,
} from 'cloudsort-client';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { debounce } from 'lodash';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import InboundLoadsService from '../../services/InboundLoads.service';
import { formatStatusEnum } from './utils/misc';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  data: InboundLoad;
}

const emptyInboundLoadData: InboundLoad = {
  arrival_time: new Date().toISOString(),
  load_status: InboundLoadLoadStatusEnum.PLANNED,
  trailer_id: '',
  dockdoor: undefined,
  dockdoor_name: undefined,
};

const InboundLoadCheckinDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState();
  const [inboundLoadParams, setInboundLoadParams] = useState(
    emptyInboundLoadData,
  );
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    setOpen(isOpen);
    setInboundLoadParams({
      ...data,
      arrival_time: data?.arrival_time
        ? data.arrival_time
        : new Date().toISOString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const getHiddenLoadStatuses = () => {
    const hiddenLoadStatuses = [InboundLoadLoadStatusEnum.UNLOADING];
    if (
      data &&
      data.load_status === InboundLoadLoadStatusEnum.ARRIVED
    ) {
      hiddenLoadStatuses.push(InboundLoadLoadStatusEnum.PLANNED);
    }
    return hiddenLoadStatuses;
  };

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
    onAfterClose();
    updateParent();
  };

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const completeCheckin = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.update(inboundLoadParams.id!, {
        arrival_time: inboundLoadParams.arrival_time,
        load_status: inboundLoadParams.load_status,
        dockdoor: inboundLoadParams.dockdoor,
        trailer_id: inboundLoadParams.trailer_id,
      });
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CheckCircleOutlineIcon
              style={{
                margin: '10px auto',
                height: 'auto',
                width: 40,
                color: colors.darkGold,
              }}
            />

            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 18,
                marginBottom: 20,
              }}
            >
              {`Checkin ${inboundLoadLabels.singular} ${
                inboundLoadParams.name || inboundLoadParams.id
              }`}
            </span>
          </div>
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{ padding: '0 40px' }}
        >
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <Grid container>
            <Grid
              item
              container
              xs={12}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    className={classes.datePicker}
                    style={{
                      width: '100%',
                      margin: 0,
                      marginLeft: 5,
                      marginTop: 5,
                    }}
                    variant='dialog'
                    format='MM/dd/yyyy hh:mmaaa'
                    margin='normal'
                    label='Actual Arrival Time'
                    value={
                      new Date(inboundLoadParams.arrival_time || '')
                    }
                    onChange={(e) => {
                      setInboundLoadParams({
                        ...inboundLoadParams,
                        arrival_time: e as unknown as string,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                style={{
                  marginRight: 16,
                  marginBottom: isXsScreen ? 16 : 0,
                  height: 50,
                  width: '100%',
                }}
                className={classes.formControl}
                data-testid={'checkin-inbound-load-trailer-id'}
              >
                <TextField
                  value={inboundLoadParams.trailer_id || ''}
                  autoFocus
                  label='Trailer ID'
                  onChange={(e) => {
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      trailer_id: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                }}
              >
                <InputLabel className={classes.selectLabel}>
                  Status
                </InputLabel>
                <Select
                  data-testid='inbound-load-status-select'
                  disableUnderline={true}
                  displayEmpty
                  classes={{
                    selectMenu: classes.selectMenu,
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: {
                      paper: classes.selectPaper,
                      list: classes.selectList,
                    },
                  }}
                  value={inboundLoadParams.load_status || ''}
                  onChange={(e) => {
                    const value = e.target
                      .value as InboundLoadLoadStatusEnum;
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      load_status: value,
                    });
                  }}
                >
                  {Object.keys(InboundLoadLoadStatusEnum)
                    .filter(
                      (status) =>
                        !getHiddenLoadStatuses().includes(
                          InboundLoadLoadStatusEnum[
                            status as keyof typeof InboundLoadLoadStatusEnum
                          ],
                        ),
                    )
                    .map((status) => (
                      <MenuItem
                        value={status}
                        key={status}
                        data-testid={`inbound-load-status-${status}`}
                      >
                        {formatStatusEnum(status)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              style={{ marginBottom: '10px' }}
            >
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                  height: 50,
                }}
                data-testid='select-unload-door'
              >
                <InputLabel
                  className={classes.selectLabel}
                  style={{
                    fontSize: 14,
                    top: '-34px',
                    marginLeft: 5,
                    marginBottom: 16,
                    marginTop: 16,
                  }}
                >
                  Unload Door
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  isClearable
                  styles={{
                    ...asyncSelectStyles,
                    menuPortal: (styles: any) => {
                      return {
                        ...styles,
                        zIndex: 9999,
                      };
                    },
                    control: (styles: any, state: any) => {
                      return {
                        ...styles,
                        backgroundColor: 'transparent',
                        border: '1px solid white',
                        color: colors.gray,
                        padding: '10px 0',
                        boxShadow: 'none !important',
                        outline: `none !important`,
                        '&:hover': {
                          border: `1px solid ${colors.ultraLightGray}`,
                        },
                        '&:focus': {
                          border: `1px solid ${colors.ultraLightGray}`,
                        },
                      };
                    },
                    placeholder: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    container: (styles: any) => {
                      return {
                        width: '100%',
                      };
                    },
                    loadingIndicator: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    input: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.black,
                      };
                    },
                    singleValue: (styles: any) => {
                      return {
                        ...styles,
                        color: inboundLoadParams.dockdoor_name
                          ? colors.black
                          : colors.lightGray,
                      };
                    },
                    indicatorsContainer: (styles: any) => {
                      return {
                        ...styles,
                        svg: {
                          color: `${colors.dark} !important`,
                        },
                        color: colors.black,
                      };
                    },
                  }}
                  loadOptions={(
                    inputValue: string,
                    callback: any,
                  ) => {
                    loadDDOptions(inputValue, callback);
                  }}
                  onChange={(option) => {
                    const selectedDockDoor = option as TypeAheadItem;
                    setInboundLoadParams({
                      ...inboundLoadParams,
                      dockdoor: selectedDockDoor
                        ? Number(selectedDockDoor.value)
                        : undefined,
                      dockdoor_name: selectedDockDoor
                        ? selectedDockDoor.label
                        : '',
                    });
                  }}
                  placeholder={'Start Typing...'}
                  value={
                    inboundLoadParams.dockdoor
                      ? {
                          value: String(inboundLoadParams.dockdoor),
                          label:
                            inboundLoadParams.dockdoor_name || '',
                        }
                      : {
                          value: '',
                          label: 'Start Typing...',
                        }
                  }
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '14px 54px' }}>
          <MuiButton
            variant='outlined'
            className={clx(
              classes.outlinedButton,
              classes.cancelButton,
            )}
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            data-testid={'inbound-load-checkin-save-button'}
            variant='outlined'
            className={clx(
              classes.outlinedButton,
              classes.saveButton,
            )}
            disabled={false}
            onClick={(e) => {
              completeCheckin();
            }}
          >
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
    },
    form: {
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    saveButton: {
      background: colors.darkGold,
      '&:hover': {
        background: colors.darkGold,
      },
    },
    cancelButton: {
      background: colors.white,
      color: colors.darkGold,
      borderColor: colors.darkGold,
      borderWidth: 2,
      '&:hover': {
        background: colors.white,
      },
    },
  })),
)(InboundLoadCheckinDialog);
