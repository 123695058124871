import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './singleDetail.styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import { common } from '../../../utils/strings';

export interface IProps extends ButtonProps {
  classes: { [key: string]: string };
  label: string;
  value: any;
  valueStyle?: React.CSSProperties;
  inline?: boolean;
  inlineWidth?: string;
  inputType?: 'string' | 'number';
  onEdit?: (params: any) => any;
  customValueComponent?: boolean;
}

const SingleDetail: React.FC<IProps> = ({
  classes,
  label,
  value,
  valueStyle,
  inline = false,
  inlineWidth = '250px',
  inputType = 'string',
  onEdit,
  customValueComponent = false,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editableValue, setEditableValue] = useState<string>(
    value === common.emptyValue ? undefined : value,
  );

  const renderDialog = () => {
    const onClose = () => {
      setEditableValue('');
      setOpenDialog(false);
    };

    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={openDialog}
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogTitle>{`Edit ${label}`}</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <FormControl
            className={classes.formControl}
            style={{
              width: '100%',
              margin: '5px 0px',
              border: '1px solid #363437',
              padding: 5,
              borderRadius: 5,
            }}
          >
            <TextField
              autoFocus
              label='Value'
              type={inputType}
              defaultValue={
                value === common.emptyValue ? undefined : value
              }
              onChange={(e) => {
                setEditableValue(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
              FormHelperTextProps={{ style: { paddingLeft: '5px' } }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (onEdit) {
                onEdit(editableValue);
              }
              onClose();
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {!!onEdit && renderDialog()}
      <div
        style={
          inline
            ? {
                display: 'inline-flex',
                flexDirection: 'row',
                padding: '5px 0',
                width: inlineWidth,
                marginRight: '10px',
              }
            : {
                display: 'flex',
                flexDirection: 'row',
                padding: '15px 0',
              }
        }
      >
        <Typography
          data-testid={`single-detail:${label}:label`}
          className={classes.boldNameParam}
          style={{ marginRight: 15 }}
        >
          {label}
        </Typography>
        {customValueComponent ? (
          { ...value }
        ) : (
          <Typography
            data-testid={`single-detail:${label}:value`}
            className={classes.paramValueLight}
            style={valueStyle}
            title={value}
          >
            {value || common.emptyValue}
          </Typography>
        )}

        {!!onEdit && (
          <EditIcon
            fontSize={'small'}
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{
              fill: 'rgba(0, 0, 0, 0.54)',
              cursor: 'pointer',
              marginLeft: 15,
            }}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(SingleDetail);
