import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import { Typography, AlertBanner } from '../primitives';
import Paper from '@material-ui/core/Paper';
import Layout from '../layout/Layout';
import { humanReadableNull } from '../DetailsPagesFunctions';
import {
  InboundLoad,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import Button from '@material-ui/core/Button';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import { Grid, Theme } from '@material-ui/core';
import colors from '../../utils/colors';
import configurationUtils from '../../utils/configurationUtils';
import PermissionsService from '../../services/Permissions.service';
import EventsService from '../../services/Events.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import InboundLoadsService from '../../services/InboundLoads.service';
import format from 'date-fns/format';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import browserHistory from '../../utils/browserHistory';
import InboundLoadDialog from './InboundLoadDialog';
import InboundLoadCheckinDialog from './InboundLoadCheckinDialog';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { formatStatusEnum } from './utils/misc';
import { common } from '../../utils/strings';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const InboundLoadDetails: React.FC<Props> = ({ classes, match }) => {
  const loadId = match.params.id;
  const [inboundLoadData, setInboundLoadData] =
    useState<InboundLoad>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] =
    useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showCheckinModal, setShowCheckinModal] =
    useState<boolean>(false);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const fetchInboundLoadDetails = async () => {
    return InboundLoadsService.getById(loadId);
  };

  const fetchLoadDetailsData = async () => {
    try {
      setShowProgress(true);
      const { data } = await fetchInboundLoadDetails();
      setInboundLoadData(data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.INBOUNDLOADREAD,
    );

    fetchLoadDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  const deleteInboundLoad = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.deleteById(
        inboundLoadData?.id || loadId,
      );
      browserHistory.push(AuthRoutes.INBOUND_LOAD);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
      setShowDeleteModal(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      inboundLoad: loadId,
      pageSize: rowsPerPage,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
  ${inboundLoadLabels.singular} ${
            inboundLoadData?.name || loadId || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='INBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <InboundLoadDialog
          isOpen={showEditModal}
          onAfterClose={() => setShowEditModal(false)}
          updateParent={() => {
            fetchLoadDetailsData();
          }}
          type={'edit'}
          data={inboundLoadData}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-inbound-load-confirmation'}
          title={`Delete ${inboundLoadLabels.singular} ${
            inboundLoadData?.name || loadId || ''
          }`}
          msg={`Are you sure you want to delete ${
            inboundLoadLabels.singular
          } ${inboundLoadData?.name || loadId || ''}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            deleteInboundLoad();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          isOpen={showDeleteModal}
        />
        <InboundLoadCheckinDialog
          isOpen={showCheckinModal}
          onAfterClose={() => setShowCheckinModal(false)}
          updateParent={() => {
            fetchLoadDetailsData();
          }}
          data={inboundLoadData!}
        />
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              style={{ marginBottom: 16 }}
            >
              {`${inboundLoadLabels.singular} ${
                inboundLoadData?.name || inboundLoadData?.id || ''
              }`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.INBOUNDLOADWRITE,
            ) && (
              <>
                <Button
                  data-testid='edit-load-btn'
                  variant='contained'
                  className={classes.containedButton}
                  disabled={!inboundLoadData}
                  style={{ margin: '0 0 8px 8px' }}
                  onClick={(e) => {
                    setShowEditModal(true);
                  }}
                >
                  <EditIcon style={{ marginRight: 10 }} />
                  Edit
                </Button>
                <Button
                  data-testid='delete-load-btn'
                  variant='contained'
                  className={classes.containedButton}
                  disabled={!inboundLoadData}
                  style={{ margin: '0 0 8px 8px' }}
                  onClick={(e) => {
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon style={{ marginRight: 10 }} />
                  Delete
                </Button>
                <Button
                  data-testid='checkin-load-btn'
                  variant='contained'
                  className={classes.containedButton}
                  disabled={!inboundLoadData}
                  style={{ margin: '0 0 8px 8px' }}
                  onClick={(e) => {
                    setShowCheckinModal(true);
                  }}
                >
                  <CheckCircleOutlineIcon
                    style={{ marginRight: 10 }}
                  />
                  {inboundLoadLabels.singular} Checkin
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={8}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid='inbound-details-detail'
            >
              <SingleDetail
                inline={true}
                label='Status'
                value={
                  inboundLoadData?.load_status
                    ? formatStatusEnum(inboundLoadData?.load_status)
                    : common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                label='Trailer ID'
                value={
                  inboundLoadData?.trailer_id || common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                label='Unload Door'
                value={
                  inboundLoadData?.dockdoor_name || common.emptyValue
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              data-testid='inbound-load:details:time'
              className={classes.paper}
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
              }}
            >
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                <Typography
                  data-testid='inbound-load:details:Planned Arrival Time:label'
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Planned Arrival Time
                </Typography>
                <Typography
                  data-testid='inbound-load:details:Planned Arrival Time:value'
                  variant='h6'
                  className={classes.statsValue}
                >
                  {inboundLoadData?.expected_inbound_load_time
                    ? new Date(
                        inboundLoadData?.expected_inbound_load_time,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue}
                </Typography>
                <Typography
                  data-testid='inbound-load:details:Planned Arrival date:value'
                  className={classes.statsValue}
                  style={{
                    fontSize: '12px',
                    color: colors.gray,
                  }}
                >
                  {inboundLoadData?.expected_inbound_load_time
                    ? format(
                        new Date(
                          inboundLoadData?.expected_inbound_load_time,
                        ),
                        'MM/dd/yyyy',
                      )
                    : ''}
                </Typography>
              </div>
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                {inboundLoadData?.arrival_time && (
                  <ArrowDropDownIcon
                    className={classes.arrivedLoadMarker}
                  />
                )}
                <Typography
                  data-testid='inbound-load:details:Actual Arrival Time:label'
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Actual Arrival Time
                </Typography>
                <Typography
                  data-testid='inbound-load:details:Actual Arrival Time:value'
                  variant='h6'
                  className={classes.statsValue}
                >
                  {inboundLoadData?.arrival_time
                    ? new Date(
                        inboundLoadData?.arrival_time,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue}
                </Typography>
                <Typography
                  data-testid='inbound-load:details:Actual Arrival date:value'
                  className={classes.statsValue}
                  style={{
                    fontSize: '12px',
                    color: colors.gray,
                  }}
                >
                  {inboundLoadData?.arrival_time
                    ? format(
                        new Date(inboundLoadData?.arrival_time),
                        'MM/dd/yyyy',
                      )
                    : ''}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Paper
              className={classes.paper}
              style={{ padding: 16, height: '100%' }}
              data-testid='inboundload-details-card'
            >
              <Typography
                className={classes.boldNameParam}
                style={{
                  paddingBottom: 16,
                  borderBottom: `1px solid ${colors.lightGray}`,
                }}
              >
                {inboundLoadLabels.singular} Details
              </Typography>
              <SingleDetail
                valueStyle={{ color: colors.darkGold }}
                label='Origin'
                value={humanReadableNull(
                  inboundLoadData?.origin_station,
                )}
              />
              <SingleDetail
                valueStyle={{ color: colors.darkGold }}
                label='Owner'
                value={humanReadableNull(
                  inboundLoadData?.owner_full_name,
                )}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              className={classes.paper}
              style={{ padding: 16, height: '100%' }}
              data-testid='inbound-load-details-card2'
            >
              <Typography
                className={classes.boldNameParam}
                style={{
                  paddingBottom: 16,
                  borderBottom: `1px solid ${colors.lightGray}`,
                }}
              >
                {inboundLoadLabels.singular} Details
              </Typography>
              <SingleDetail
                valueStyle={{ color: colors.darkGold }}
                label='Load ID'
                value={humanReadableNull(inboundLoadData?.name)}
              />
              <SingleDetail
                valueStyle={{ color: colors.darkGold }}
                label={`Planned ${configurationUtils.getPageTitle(
                  false,
                  'CONTAINER',
                )}`}
                value={humanReadableNull(
                  inboundLoadData?.expected_containers_at_arrival,
                )}
              />
              <SingleDetail
                valueStyle={{ color: colors.darkGold }}
                label={`Planned ${configurationUtils.getPageTitle(
                  false,
                  'PACKAGE',
                )}`}
                value={humanReadableNull(
                  inboundLoadData?.expected_packages_at_arrival,
                )}
              />
            </Paper>
          </Grid>
        </Grid>
        <EventsPaginatedTable
          dataTestIdPrefix={'load-details-events'}
          fetch={fetchEvents}
          sortableBy={['timestamp']}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    arrivedLoadMarker: {
      fontSize: '3em',
      position: 'absolute',
      margin: '-28px 0 0 -0.5em',
      color: colors.darkGold,
    },
  })),
)(InboundLoadDetails);
