import { DevicesApi } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';
import dateUtils from './utils/date';

class Devices {
  private api: DevicesApi;

  constructor() {
    this.api = new DevicesApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
  }

  public async getAll(params: {
    page: number;
    pageSize?: number;
    deviceId?: string;
    sortBy?: 'version' | '-version' | undefined;
    deprecated?: boolean | undefined;
    excludeNullVersion?: boolean;
    versionStatus?: 'LIVE' | 'OUTDATED' | 'DEPRECATED';
    deviceDetails?: boolean;
    fromDate?: string;
    toDate?: string;
  }) {
    return this.api.devicesList({
      ...params,
      stationId: EphemeralStateService.getMyStationId(),
      fromDate:
        params.fromDate === '' ? undefined : dateUtils.fromDate(),
      toDate: params.toDate === '' ? undefined : dateUtils.toDate(),
      extendData: true,
    });
  }

  public async getById(id: number) {
    return this.api.devicesRead({
      id,
    });
  }

  public async addDevice(deviceId: string) {
    return this.api.devicesCreate({
      data: {
        device_id: deviceId,
        station: EphemeralStateService.getMyStationId(),
      },
    });
  }

  public async updateDevice(params: {
    id: number;
    deviceId: string;
  }) {
    return this.api.devicesPartialUpdate({
      id: params.id,
      data: {
        device_id: params.deviceId,
      },
    });
  }

  public async deleteDevice(id: number) {
    return this.api.devicesDelete({ id });
  }

  public async getEnrolmentToken() {
    return this.api.devicesEnrollmenttoken({
      station: EphemeralStateService.getMyStationId(),
      options: { responseType: 'arraybuffer' },
    });
  }
}

export default new Devices();
