import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import colors from '../../utils/colors';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DateTimePicker,
} from '@material-ui/pickers';

interface Props {
  date: Date;
  classes: { [key: string]: string };
  triggerEl: HTMLElement | null;
  onAfterClose?: () => void;
  onAfterChange?: (date: Date | null) => void;
  disableToolbar?: boolean;
  rightAlign?: boolean;
  variant: 'dialog' | 'inline';
  autoOk?: boolean;
  label?: string;
  onlyTime?: boolean;
  dateTime?: boolean;
  id: string;
}

const DateOrTimePicker: React.FC<Props> = ({
  date,
  triggerEl,
  classes,
  onAfterClose,
  disableToolbar,
  onAfterChange,
  rightAlign,
  variant,
  autoOk,
  label,
  onlyTime,
  dateTime,
  id,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (onAfterChange) {
      date?.setSeconds(0);
      date?.setMilliseconds(0);
      onAfterChange(date);
    }
  };

  const setCssClassName = () => {
    requestAnimationFrame(() => {
      const dialogEl: HTMLDivElement | null = document.querySelector(
        `#${id} .MuiPaper-root.MuiDialog-paper`,
      );
      if (dialogEl) {
        dialogEl.classList.add(classes.dialogRoot);
      }
    });
  };

  const setCustomPosition = () => {
    setCssClassName();
    requestAnimationFrame(() => {
      const rect = triggerEl
        ? triggerEl.getBoundingClientRect()
        : undefined;

      const dialogEl: HTMLDivElement | null = document.querySelector(
        `#${id} .MuiPaper-root.MuiDialog-paper`,
      );
      const backdrops: NodeListOf<HTMLDivElement> | null =
        document.querySelectorAll('.MuiBackdrop-root');

      if (dialogEl) {
        backdrops.forEach((backdrop) => {
          backdrop.style.display = 'none';
        });

        dialogEl.style.margin = '0';
        dialogEl.style.position = 'absolute';
        dialogEl.style.top = `${rect?.bottom}px`;
        if (rightAlign && rect) {
          dialogEl.style.right = `${
            window.innerWidth - (rect?.left + rect?.width)
          }px`;
        } else {
          dialogEl.style.left = `${rect?.left}px`;
        }
      }
    });
  };

  useEffect(() => {
    let resizeListener: () => void;
    if (triggerEl && variant === 'dialog') {
      const inputEl: HTMLDivElement | null = document.querySelector(
        `.${classes.hiddenInput} button`,
      );
      if (inputEl && inputEl.click) {
        inputEl.click();
        resizeListener = () => {
          setCustomPosition();
        };
        window.addEventListener('resize', resizeListener);
      }
      return () => {
        if (resizeListener) {
          window.removeEventListener('resize', resizeListener);
        }
      };
    }
  });

  if (onlyTime) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {' '}
        <KeyboardTimePicker
          variant={variant}
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          className={variant === 'dialog' ? classes.hiddenInput : ''}
          onOpen={
            variant === 'dialog' ? setCustomPosition : undefined
          }
          onClose={() => {
            if (onAfterClose) {
              onAfterClose();
            }
          }}
          InputProps={{
            disableUnderline: true,
          }}
          disableToolbar={disableToolbar}
          autoOk={autoOk}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (dateTime) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          variant={variant}
          label={label}
          format='yyyy/MM/dd hh:mm a'
          value={selectedDate}
          onChange={handleDateChange}
          className={variant === 'dialog' ? classes.hiddenInput : ''}
          onOpen={
            variant === 'dialog' ? setCustomPosition : undefined
          }
          onClose={() => {
            if (onAfterClose) {
              onAfterClose();
            }
          }}
          InputProps={{
            disableUnderline: true,
          }}
          disableToolbar={disableToolbar}
          autoOk={autoOk}
        />
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        showTodayButton
        variant={variant}
        label={label}
        format='MM/dd/yyyy'
        value={selectedDate}
        onChange={handleDateChange}
        className={variant === 'dialog' ? classes.hiddenInput : ''}
        onOpen={variant === 'dialog' ? setCustomPosition : undefined}
        onClose={() => {
          if (onAfterClose) {
            onAfterClose();
          }
        }}
        InputProps={{
          disableUnderline: true,
        }}
        DialogProps={{
          id,
        }}
        disableToolbar={disableToolbar}
        autoOk={autoOk}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles({
  hiddenInput: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  dialogRoot: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: colors.dark,
    },
    '& .MuiDialogActions-root button': {
      color: colors.gold,
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: colors.gold,
    },
    '& .MuiPickersCalendar-week button.MuiPickersDay-daySelected p': {
      color: colors.white,
    },
    '& .MuiPickersCalendar-week button p': {
      color: 'rgba(54,52,55,0.87)',
    },
    '& button.MuiPickersDay-current p': {
      color: `${colors.gold} !important`,
    },
    '& button.MuiPickersDay-daySelected p': {
      color: `${colors.white} !important`,
    },
  },
})(DateOrTimePicker);
