import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { Button, TextField } from '../primitives';
import MuiButton from '@material-ui/core/Button';
import Modules from './modules/Modules';
import Settings from './settings/Settings';
import Roles from './roles/Roles';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Theme,
  Divider,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import colors from '../../utils/colors';
import { useParams } from 'react-router-dom';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import browserHistory from '../../utils/browserHistory';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives';
import RouteLeavingGuard from '../routeLeavingGuard/RouteLeavingGuard';
import { Search as SearchIcon } from '@material-ui/icons';

// Redux
import { useAppDispatch } from '../../redux/store';
import { updateNavStart } from '../../redux/slices/configSlice';

// services
import StationsService from '../../services/Stations.service';
import OrganizationsService from '../../services/Organizations.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import CorportateService from '../../services/Corportate.service';
import PermissionsService from '../../services/Permissions.service';
import { RouterManualUpdateContext } from '../router/Router';
import { resetDynamicRoutes } from '../../interfaces/routes';

// Types
import { AxiosError } from 'axios';
import {
  PatchCorporateRoles,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';

// Utils
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import classNames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { Helmet } from 'react-helmet';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { debounce } from 'lodash';
import configPageStyles from '../commonStyles/configPage.style';
import { isEqual, cloneDeep } from 'lodash';
import { updateSearchQuery } from '../../redux/slices/searchSlice';

interface Props {
  classes: { [key: string]: string };
}

export enum Mode {
  CLOUDSORT,
  ORGANIZATION,
  STATION,
}

enum TabsEnum {
  SETTINGS,
  MODULES,
  ROLES,
}

export enum INTERACTION {
  READ,
  WRITE,
}

interface ConfirmationDialogParams {
  title: string;
  msg: string;
  onPrimaryAction: () => void;
  onCancel: () => void;
}

const Configuration: React.FC<Props> = ({ classes }) => {
  const [tab, setTab] = React.useState(TabsEnum.SETTINGS);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [blockedTab, setBlockedTab] = useState<
    TabsEnum | undefined
  >();
  const [confirmationDialogParams, setConfirmationDialogParams] =
    useState<ConfirmationDialogParams | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<boolean>(false);
  const [showJustImportedBanner, setshowJustImportedBanner] =
    useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [initData, setInitData] = useState<any>(null);
  const [roles, setRoles] = useState<any>();
  const [initRoles, setInitRoles] = useState<any>(null);
  const [error, setError] = useState<string>();
  const [showFloatHeader, setShowFloatHeader] = useState(false);
  const [activeVisibilitySensor, setActiveVisibilitySensor] =
    useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchQueryValue, setSearchQueryValue] =
    useState<string>('');

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const routerManualUpdate = useContext(RouterManualUpdateContext);
  const urlParams: any = useParams();
  const dispatch = useAppDispatch();

  let mode: Mode = Mode.CLOUDSORT;
  if (urlParams.stationId && urlParams.orgId) {
    mode = Mode.STATION;
  } else if (urlParams.orgId) {
    mode = Mode.ORGANIZATION;
  }

  const getInteraction = () => {
    if (mode === Mode.CLOUDSORT) {
      if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.MASTERWRITE,
        )
      ) {
        return INTERACTION.WRITE;
      } else if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.MASTERREAD,
        )
      ) {
        return INTERACTION.READ;
      }
    } else if (mode === Mode.ORGANIZATION) {
      if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.ORGANIZATIONMODIFY,
        )
      ) {
        return INTERACTION.WRITE;
      } else if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.ORGANIZATIONREAD,
        )
      ) {
        return INTERACTION.READ;
      }
    } else if (mode === Mode.STATION) {
      if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.STATIONWRITE,
        )
      ) {
        return INTERACTION.WRITE;
      } else if (
        PermissionsService.hasPermission(
          PermissionsPermissionsEnum.STATIONREAD,
        )
      ) {
        return INTERACTION.READ;
      }
    }
  };

  const fetchConfigData = async (updateRoutes?: boolean) => {
    setShowProgress(true);
    try {
      if (tab === TabsEnum.ROLES) {
        if (mode === Mode.STATION) {
          const { data } = await StationsService.getStationRoles(
            urlParams.stationId,
          );
          setRoles({ ...data });
          setInitRoles(cloneDeep(data));
        } else if (mode === Mode.ORGANIZATION) {
          const { data } = await OrganizationsService.getRoles(
            urlParams.orgId,
          );
          setRoles({ ...data });
          setInitRoles(cloneDeep(data));
        } else if (mode === Mode.CLOUDSORT) {
          const { data } =
            await CorportateService.getCorporateRoles();
          setRoles({ ...data });
          setInitRoles(cloneDeep(data));
        }
      } else {
        if (mode === Mode.STATION) {
          const { data } = await StationsService.getStationById(
            urlParams.stationId,
          );
          setData({ ...data });
          setInitData(cloneDeep(data));
        } else if (mode === Mode.ORGANIZATION) {
          const { data } = await OrganizationsService.getById(
            urlParams.orgId,
          );
          setData({ ...data });
          setInitData(cloneDeep(data));
        } else if (
          mode === Mode.CLOUDSORT &&
          PermissionsService.hasPermission(
            PermissionsPermissionsEnum.MASTERREAD,
          )
        ) {
          const { data } =
            await CorportateService.getCorporateConfig();
          setData({ ...data });
          setInitData(cloneDeep(data));
        }
        if (EphemeralStateService.getMyStationId()) {
          await globalStationOptionsUtils.setStationData(
            EphemeralStateService.getMyStationId(),
          );
          if (updateRoutes) {
            resetDynamicRoutes();
            routerManualUpdate();
          }
        }
        return data;
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const patchConfigData = async (
    partialData: any,
    reloadAfter?: boolean,
  ) => {
    setShowProgress(true);
    setError(undefined);
    try {
      setShowProgress(true);
      if (mode === Mode.STATION) {
        await StationsService.editStation(partialData);
        dispatch(updateNavStart());
      } else if (mode === Mode.ORGANIZATION) {
        await OrganizationsService.edit(partialData);
        dispatch(updateNavStart());
      } else if (mode === Mode.CLOUDSORT) {
        await CorportateService.edit(partialData);
      }
      setHasUnsavedChanges(false);
      setInitData(null);
      if (!reloadAfter) {
        fetchConfigData(true);
      }
    } catch (e) {
      handleError(e);
    } finally {
      if (reloadAfter) {
        window.location.reload();
      } else {
        setShowProgress(false);
        if (showJustImportedBanner) {
          setshowJustImportedBanner(false);
        }
      }
    }
  };

  const onSavePrimaryAction = () => {
    executePatch();
    setHasUnsavedChanges(false);
    setConfirmationDialogParams(null);
  };

  const onSave = () => {
    setConfirmationDialogParams({
      title: 'Save',
      msg: 'Are you sure you want to save?',
      onPrimaryAction: onSavePrimaryAction,
      onCancel: () => {
        setConfirmationDialogParams(null);
      },
    });
  };

  const onCancelCallBack = () => {
    setConfirmationDialogParams({
      title: 'Cancel',
      msg: 'Are you sure you want to cancel? All unsaved changes will be lost.',
      onPrimaryAction: () => {
        onCancel();
        setHasUnsavedChanges(false);
        setConfirmationDialogParams(null);
      },
      onCancel: () => {
        setConfirmationDialogParams(null);
      },
    });
  };

  const getPermissionsByRoleatrixRow = (role: string) => {
    const matrixIndex = roles.role_types.indexOf(role);
    if (matrixIndex !== undefined || matrixIndex !== null) {
      const permissions: string[] = [];
      roles.permissions.forEach(
        (permission: string, index: number) => {
          if (roles.matrix[matrixIndex][index]) {
            permissions.push(permission);
          }
        },
      );
      return permissions;
    }
  };

  const patchRoles = async (reloadAfter?: boolean) => {
    setShowProgress(true);
    try {
      const payload: PatchCorporateRoles = {
        roles: roles.role_types.map((role: string) => ({
          label: role,
          role_type: role,
          permissions: getPermissionsByRoleatrixRow(role),
        })),
      };
      if (mode === Mode.CLOUDSORT) {
        await CorportateService.updateCorporateRoles(payload);
      } else if (mode === Mode.ORGANIZATION) {
        await OrganizationsService.updateOrgRoles(
          urlParams.orgId,
          payload,
        );
      } else if (mode === Mode.STATION) {
        await StationsService.updateStationRoles(
          urlParams.stationId,
          payload,
        );
      }
      setHasUnsavedChanges(false);
      setInitRoles(null);
      await fetchConfigData();
      setShowProgress(true);
      if (reloadAfter) {
        window.location.reload();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onCancel = () => {
    fetchConfigData();
    if (showJustImportedBanner) {
      setshowJustImportedBanner(false);
    }
  };

  const handleTabChange = (event: any, newValue: number) => {
    if (hasUnsavedChanges) {
      setBlockedTab(newValue);
    } else {
      setTab(newValue);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const resetGlobalUnsavedChanges = () => {
    setHasUnsavedChanges(false);
    unblockTabs(true);
  };

  const unblockTabs = (changeTab: boolean) => {
    if (blockedTab !== undefined) {
      if (changeTab) {
        setTab(blockedTab);
      }
      setBlockedTab(undefined);
    }
  };

  const createOrg = async () => {
    setShowProgress(true);
    try {
      const res = await OrganizationsService.create({
        name: 'New Organization',
      });
      browserHistory.push(
        `/configuration/organization/${res.data.id}/true`,
      );
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const createStation = async () => {
    setShowProgress(true);
    try {
      const res = await StationsService.create({
        name: 'New Station',
        organization: urlParams.orgId,
      });
      browserHistory.push(
        `/configuration/organization/${urlParams.orgId}/station/${res.data.id}/true`,
      );
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const exportConfig = async () => {
    setShowProgress(true);
    try {
      const dataObj = await fetchConfigData();
      const blob = new Blob([JSON.stringify(dataObj.config)], {
        type: 'application/json',
      });
      saveAs(
        blob,
        `${dataObj.name || 'CloudSort'}-configuration-${Date.now()}`,
      );
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const importConfig = async (configToImport: any) => {
    try {
      const dataObj = { ...data };
      dataObj.config = configToImport;
      setData(dataObj);
      setshowJustImportedBanner(true);
      setHasUnsavedChanges(true);
    } catch (e) {
      handleError(e);
    }
  };

  const updateSearchDebounced = useCallback(
    debounce((query: string) => {
      dispatch(updateSearchQuery(query));
    }, 250),
    [],
  );

  const renderConfigHeader = () => {
    return (
      <>
        <Box
          className={
            showFloatHeader ? classes.spacerOn : classes.spacerOff
          }
        />
        <Box mt={2} mb={3}>
          <Paper
            className={classNames(
              classes.paperWithoutShadow,
              { [classes.floatPaper]: showFloatHeader },
              { [classes.noShadow]: tab === TabsEnum.ROLES },
            )}
          >
            <Grid container justify='space-between'>
              <Grid
                item
                xs={isMobileView ? 12 : 6}
                data-testid={'navigation-menu'}
              >
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  variant={isMobileView ? 'fullWidth' : 'scrollable'}
                  classes={{
                    indicator: classes.tabsIndicator,
                  }}
                  centered={isMobileView}
                >
                  <Tab
                    label='Settings'
                    classes={{
                      wrapper: classes.tabWrapper,
                      selected: classes.selectedTopMenuTab,
                      root: classes.headerTabsRoot,
                    }}
                    disableRipple
                  />
                  <Tab
                    label='Modules'
                    classes={{
                      wrapper: classes.tabWrapper,
                      selected: classes.selectedTopMenuTab,
                      root: classes.headerTabsRoot,
                    }}
                    disableRipple
                  />
                  <Tab
                    label='Roles'
                    classes={{
                      wrapper: classes.tabWrapper,
                      selected: classes.selectedTopMenuTab,
                      root: classes.headerTabsRoot,
                    }}
                    disableRipple
                  />
                </Tabs>
              </Grid>
              {!isMobileView && (
                <Grid
                  item
                  xs={4}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <Box
                      fontWeight={400}
                      style={{
                        fontSize: 14,
                        color: colors.darkGold,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}
                      className={
                        isSearchFocused
                          ? classes.hideCancelSaveButtons
                          : classes.showCancelSaveButtons
                      }
                    >
                      {getInteraction() === INTERACTION.WRITE && (
                        <>
                          <span
                            data-testid='top-navigation-cancel-changes-btn'
                            onClick={() => {
                              if (hasUnsavedChanges) {
                                onCancelCallBack();
                              }
                            }}
                            style={{
                              marginRight: '15px',
                              cursor: hasUnsavedChanges
                                ? 'pointer'
                                : 'not-allowed',
                            }}
                          >
                            Cancel
                          </span>

                          <MuiButton
                            variant='outlined'
                            component='span'
                            className={classes.goldButton}
                            data-testid='top-navigation-save-changes-btn'
                            disabled={!hasUnsavedChanges}
                            style={{
                              cursor: hasUnsavedChanges
                                ? 'pointer'
                                : 'not-allowed',
                            }}
                            onClick={() => {
                              if (hasUnsavedChanges) {
                                onSave();
                              }
                            }}
                          >
                            Save
                          </MuiButton>
                        </>
                      )}
                    </Box>
                    {tab === TabsEnum.MODULES && (
                      <TextField
                        data-testid='search-input'
                        placeholder='Search'
                        onFocus={() => setIsSearchFocused(true)}
                        onBlur={() => setIsSearchFocused(false)}
                        className={
                          isSearchFocused
                            ? classes.searchExpanded
                            : classes.search
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon
                                style={{ color: '#8E8E93' }}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            marginTop: '-10px',
                            maxHeight: '40px',
                          },
                          classes: {
                            root: isSearchFocused
                              ? classes.notchedOutline
                              : classes.notchedOutlineHover,
                            focused: classes.notchedOutline,
                            notchedOutline: isSearchFocused
                              ? classes.notchedOutline
                              : null,
                          },
                        }}
                        variant='outlined'
                        value={searchQueryValue}
                        onChange={(event: any) => {
                          setSearchQueryValue(event.target.value);
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Paper>
          {isMobileView && (
            <Grid
              container
              justify='space-between'
              className={classes.mobileSearchContainer}
            >
              <Grid
                item
                xs={6}
                style={{
                  alignSelf: 'center',
                }}
              >
                {tab === TabsEnum.MODULES && (
                  <TextField
                    placeholder='Configuration Search'
                    className={classes.mobileSearchField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon style={{ color: '#8E8E93' }} />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: '13px',
                        maxHeight: '30px',
                        width: 'auto',
                      },
                    }}
                    variant='outlined'
                    value={searchQueryValue}
                    onChange={(event: any) => {
                      setSearchQueryValue(event.target.value);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {getInteraction() === INTERACTION.WRITE && (
                  <>
                    <Button
                      className={classes.mobileSearchButton}
                      variant='outlined'
                      onClick={() => {
                        if (hasUnsavedChanges) {
                          onSave();
                        }
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.mobileSearchButton}
                      variant='outlined'
                      onClick={() => {
                        if (hasUnsavedChanges) {
                          onCancelCallBack();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          )}
        </Box>
      </>
    );
  };

  const executePatch = () => {
    if (tab === TabsEnum.ROLES) {
      return patchRoles();
    } else {
      return patchConfigData(data, false);
    }
  };

  const checkAndSetData = (data: any) => {
    setHasUnsavedChanges(!isEqual(initData, data));
    setData(data);
  };

  const checkAndSetRoles = (roles: any) => {
    setHasUnsavedChanges(!isEqual(initRoles, roles));
    setRoles(roles);
  };

  useEffect(() => {
    updateSearchDebounced(searchQueryValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue]);

  useEffect(() => {
    fetchConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, tab]);

  useEffect(() => {
    const onScroll = () => {
      setActiveVisibilitySensor(true);
      window.removeEventListener('scroll', onScroll);
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`CloudSort - Configuration ${
          data?.name ? 'for ' + data?.name : ''
        }`}</title>
      </Helmet>
      {confirmationDialogParams && (
        <ConfirmationDialog
          dataTestIdPrefix='config-page-save-dialog'
          title={confirmationDialogParams.title}
          msg={confirmationDialogParams.msg}
          onPrimaryAction={confirmationDialogParams.onPrimaryAction}
          onCancel={confirmationDialogParams.onCancel}
          isOpen={true}
        />
      )}
      <Layout navCurrent='station_configuration'>
        {showProgress && <ProgressIndicator />}
        <RouteLeavingGuard
          when={true}
          navigate={(path) => browserHistory.push(path)}
          resetGlobalUnsavedChanges={resetGlobalUnsavedChanges}
          forceOpen={blockedTab !== undefined}
          onAfterCancel={() => {
            unblockTabs(false);
          }}
          onSave={() => {
            unblockTabs(true);
            onSavePrimaryAction();
          }}
          shouldBlockNavigation={(location) => {
            return (
              browserHistory.location !== location &&
              hasUnsavedChanges
            );
          }}
        />
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {!data && (
          <AlertBanner
            className={classes.banner}
            severity='info'
            alertTitle={'Info'}
            alertMsg={
              'There is no information to display at the moment'
            }
          />
        )}
        {!!data && (
          <Fragment>
            <Box mb={2} style={{ fontSize: '14px' }}>
              <b>Configurations </b>
              {mode === Mode.CLOUDSORT && 'CloudSort'}
              {mode === Mode.ORGANIZATION &&
                `CloudSort / ${data?.name || '...'}`}
              {mode === Mode.STATION &&
                `CloudSort / ${data?.organization_name || '...'} / `}
              {mode === Mode.STATION && (
                <span
                  style={{
                    color: colors.darkGold,
                    fontWeight: 700,
                    textDecoration: 'underline',
                  }}
                >{`${data?.name || '...'}`}</span>
              )}
            </Box>

            {renderConfigHeader()}

            <VisibilitySensor
              active={activeVisibilitySensor}
              delayedCall
              scrollCheck
              offset={{ top: 66 }}
              intervalDelay={50}
            >
              {({ isVisible }) => {
                setTimeout(() => {
                  if (isVisible !== null && !isMobileView) {
                    setShowFloatHeader(
                      !isVisible && activeVisibilitySensor,
                    );
                  }
                }, 0);
                return <div style={{ width: 1, height: 1 }} />;
              }}
            </VisibilitySensor>

            {tab === TabsEnum.SETTINGS && (
              <Settings
                data={data}
                mode={mode}
                createOrg={createOrg}
                createStation={createStation}
                interaction={getInteraction()}
                setData={(data) => {
                  checkAndSetData(data);
                }}
              />
            )}
            {tab === TabsEnum.MODULES && (
              <Modules
                data={data}
                interaction={getInteraction()}
                showJustImportedBanner={showJustImportedBanner}
                exportConfig={exportConfig}
                importConfig={importConfig}
                mode={mode}
                setData={(data) => {
                  checkAndSetData(data);
                }}
              />
            )}
            {tab === TabsEnum.ROLES && (
              <Roles
                data={roles}
                interaction={getInteraction()}
                setRoles={(roles) => {
                  checkAndSetRoles(roles);
                }}
                showFloatTableHeader={
                  activeVisibilitySensor && showFloatHeader
                }
              />
            )}
          </Fragment>
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
    showCancelSaveButtons: {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity 0.8s linear',
    },
    hideCancelSaveButtons: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.2s linear',
    },
    search: {
      width: '45%',
      marginRight: 10,
      transition: 'width .8s',
    },
    searchExpanded: {
      width: '70%',
      marginRight: 10,
      transition: 'width .8s',
    },
    paperWithoutShadow: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      boxShadow: 'none',
      backgroundColor: colors.white,
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.veryLightGray,
      },
    },
    floatPaper: {
      top: 66,
      zIndex: 999,
      right: 0,
      border: 0,
      position: 'fixed',
      boxShadow: '0px 10px 5px rgb(0,0,0, 0.2)',
      maxWidth: 'calc(100% - 300px)',
      padding: '0 4%',
    },
    noShadow: {
      boxShadow: 'none',
    },
    spacerOn: {
      height: 88,
    },
    spacerOff: {
      height: 1,
    },
    mobileSearchContainer: { marginTop: '20px' },
    mobileSearchField: {
      marginTop: 0,
      marginBottom: 0,
      '& > .MuiInputBase-fullWidth': {
        paddingLeft: '6px',
        paddingRight: '6px',
      },
      '& > .Mui-focused fieldset': {
        border: `2px solid ${colors.darkGold}!important`,
      },
    },
    mobileSearchButton: {
      fontSize: '12px',
      padding: '6px',
      color: colors.darkGold,
      fontWight: 'bold',
      backgroundColor: 'transparent',
      border: `1px solid ${colors.lightGray}`,
      width: 'auto',
      height: '30px',
      marginLeft: '5px',
      marginBottom: 0,
    },
    link: {
      color: colors.darkGold,
      textAlign: 'right',
      float: 'right',
      top: 15,
      position: 'relative',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: colors.darkGold,
      },
    },
    tabWrapper: {
      fontSize: '20px',
      textTransform: 'capitalize',
      padding: '12px 0px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        padding: '0px 12px',
      },
    },
    tabsIndicator: {
      display: 'None',
    },
    headerTabsRoot: {
      minWidth: '0px',
    },
    selectedTopMenuTab: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.white,
        border: `2px solid ${colors.gold}`,
        borderRadius: 6,
      },
      [theme.breakpoints.up('lg')]: {
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textDecorationColor: colors.gold,
        textUnderlineOffset: '7px',
      },
    },
    notchedOutline: {
      borderColor: `${colors.gold} !important`,
    },
    notchedOutlineHover: {
      '&:hover > fieldset': {
        borderColor: `${colors.gold} !important`,
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.dark,
      border: 'none',
      textTransform: 'none',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: colors.gold,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
        border: 'none',
      },
    },
  })),
)(Configuration);
