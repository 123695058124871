import { createStyles, Theme } from '@material-ui/core/styles';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import selectStyles from '../select/select.styles';

export default createStyles((theme: Theme) => ({
  ...selectStyles,
  ...sectionPageBaseStyle,
  nonMobileAlignRight: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right',
    },
  },
  inputPadding: {
    paddingLeft: '5px',
    paddingRigth: '5px',
  },
  infoTitle: {
    opacity: '0.7',
    color: '#222222',
    flexGrow: 1,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  banner: {
    width: '100%',
    borderRadius: 4,
    margin: '10px 0',
  },
  half: {
    width: '50%',
  },
}));
