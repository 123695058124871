import {
  PackagesApi,
  PackageDetails,
  NestedProcessStepSerializerPackageProcessStepEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

class Packages {
  private api: PackagesApi;

  constructor() {
    this.api = new PackagesApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
    this.getAllAllTime = this.getAllAllTime.bind(this);
  }

  public async getAll(params: {
    container?: number[];
    owner?: number[];
    carrier?: number[];
    route?: number[];
    destination?: number[];
    outboundLoad?: number[];
    manifest?: number[];
    isManifested?: boolean;
    isScanned?: boolean;
    processStep?: [NestedProcessStepSerializerPackageProcessStepEnum];
    excludeProcessStep?: [
      NestedProcessStepSerializerPackageProcessStepEnum,
    ];
    sortBy?:
      | 'id'
      | 'process_step_time'
      | 'outbound_load_id'
      | 'container_id'
      | '-id'
      | '-process_step_time'
      | '-outbound_load_id'
      | '-container_id';
    page?: number;
    pageSize?: number;
    area?: number;
    inSystem?: boolean;
    inScanned?: boolean;
    isArrived?: boolean;
    missorted?: boolean;
    hidePending?: boolean;
    defects?: Array<
      | 'EXCEPTION_MISSORT_FORCED'
      | 'EXCEPTION_MISLOAD_FORCED'
      | 'EXCEPTION_UNPROCESSABLE'
      | 'EXCEPTION_RETURNED'
      | 'EXCEPTION_RELABELED'
    >;
    firstScannedInWindow?: boolean;
    fromDate?: string;
    toDate?: string;
    trackingNumber?: string;
  }) {
    return this.api.packagesList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate:
        params.fromDate === '' ? undefined : dateUtils.fromDate(),
      toDate: params.toDate === '' ? undefined : dateUtils.toDate(),
    });
  }

  public async getAllAllTime(params: {
    container?: number[];
    owner?: number[];
    outboundLoad?: number[];
    manifest?: number[];
    isManifested?: boolean;
    isScanned?: boolean;
    processStep?: [NestedProcessStepSerializerPackageProcessStepEnum];
    excludeProcessStep?: [
      NestedProcessStepSerializerPackageProcessStepEnum,
    ];
    sortBy?: 'process_step_time' | '-process_step_time';
    page?: number;
    pageSize?: number;
    area?: number;
    inSystem?: boolean;
  }) {
    return this.api.packagesList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.packagesRead({ id });
  }

  public async getVolume(params: {
    container?: number[];
    owner?: number[];
    outboundLoad?: number[];
    area?: number;
    manifest?: number[];
    isManifested?: boolean;
    isScanned?: boolean;
    isArrived?: boolean;
    inSystem?: boolean;
    processStep?: [NestedProcessStepSerializerPackageProcessStepEnum];
    excludeProcessStep?: [
      NestedProcessStepSerializerPackageProcessStepEnum,
    ];
    sortBy?: 'process_step_time' | '-process_step_time';
    page?: number;
    pageSize?: number;
    hidePending?: boolean;
    firstScannedInWindow?: boolean;
    options?: any;
  }) {
    return this.api.packagesAggregatedata({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
    });
  }

  public async getLabel(id: number, size: string, dpi: number) {
    return this.api.packagesLabelLabelPng({
      id,
      size,
      dpi,
      options: { responseType: 'blob' },
    });
  }

  public async getCSVExport(params: {
    id?: number;
    container?: number[];
    owner?: number[];
    outboundLoad?: number[];
    area?: number;
    carrier?: number[];
    route?: number[];
    destination?: number[];
    manifest?: number[];
    isManifested?: boolean;
    isScanned?: boolean;
    isArrived?: boolean;
    inSystem?: boolean;
    processStep?: [NestedProcessStepSerializerPackageProcessStepEnum];
    missorted?: boolean;
    missortResolved?: boolean;
    fromDate?: string;
    toDate?: string;
    hidePending?: boolean;
    firstScannedInWindow?: boolean;
    defects?: Array<
      | 'EXCEPTION_MISSORT_FORCED'
      | 'EXCEPTION_MISLOAD_FORCED'
      | 'EXCEPTION_UNPROCESSABLE'
    >;
  }) {
    return this.api.packagesCsv({
      ...params,
      //Station id should be excluded when exporting a single manifest or outbound load.
      station:
        !params.manifest && !params.outboundLoad
          ? EphemeralStateService.getMyStationId()
          : undefined,
      eventTimes: true,
    });
  }

  public async update(params: {
    id: number;
    data: PackageDetails;
    force?: boolean;
    scan?: boolean;
  }) {
    return this.api.packagesPartialUpdate({
      ...params,
    });
  }
}

export default new Packages();
