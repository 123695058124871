import React, { useEffect, useState } from 'react';
import { StackedAreaChart } from '../../primitives/index';
import {
  NestedProcessStepSerializerPackageProcessStepEnum,
  ReportDatasetExpectedInfluxInfluxTypeEnum,
} from 'cloudsort-client';
import {
  FETCH_INTERVAL_TIME,
  FETCH_INTERVAL_ACTIVE,
  CARD_LOAD_DELAY,
} from '../../dashboard/Dashboard';
import dateUtils from '../../../services/utils/date';
import configurationUtils from '../../../utils/configurationUtils';
import {
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import {
  Box,
  CircularProgress,
  createStyles,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import colors from '../../../utils/colors';

//Services
import ReportsService from '../../../services/Reports.service';

//Icons
import FilterListIcon from '@material-ui/icons/FilterList';

const metric = ReportDatasetExpectedInfluxInfluxTypeEnum.COUNT;
const formatAMPM = (date: Date, isMobileView: boolean) => {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  minutes === 0 ? (minutes = '') : (minutes = ':' + minutes);
  let ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return isMobileView ? hours : hours + minutes + ampm;
};

const chartColors = {
  MANIFESTED_INBOUND: '#F694C1',
  ARRIVED: '#debb9b',
  PRIMARY: '#363437',
  SECONDARY: '#5064e4',
  ZONE: '#a3a3a3',
  CONTAINERIZED: '#3fc4d9',
  STAGED: '#efe0d1',
  LOADED: '#38bd8e',
  DISPATCHED: '#8ddec6',
};

interface Props {
  classes: { [key: string]: string };
  title: string;
  index: number;
  isMobileView: boolean;
  filters?: SelectedFilters;
}

const TodayPackagesChart: React.FC<Props> = ({
  classes,
  title,
  index,
  isMobileView,
  filters,
}) => {
  const [chartData, setChartData] = useState<ReadonlyArray<object>>();

  const sorRrefArray = Object.values(
    NestedProcessStepSerializerPackageProcessStepEnum,
  );

  const skippedProcessSteps = ['MANIFESTED_OUTBOUND', 'PLANNED'];

  const activePs = configurationUtils
    .getActiveProcessSteps()
    .filter((ps) => !skippedProcessSteps.includes(ps.key))
    .sort((a: any, b: any) => {
      return (
        sorRrefArray.indexOf(a.key) - sorRrefArray.indexOf(b.key)
      );
    });

  const fetchStats = async () => {
    const response = await ReportsService.getProcessstepsList(
      activePs.map((ps) => ps.key),
      metric,
      'PACKAGES',
      undefined,
      {
        owner:
          filters && filters.owner && filters.owner.values.length
            ? filters.owner.values.map((item: any) => item.id)
            : undefined,
        carrier:
          filters && filters.carrier && filters.carrier.values.length
            ? filters.carrier.values.map((item: any) => item.id)
            : undefined,
        route:
          filters && filters.route && filters.route.values.length
            ? filters.route.values.map((item: any) => item.id)
            : undefined,
        destination:
          filters && filters.stop && filters.stop.values.length
            ? filters.stop.values.map((item: any) => item.id)
            : undefined,
      },
    );
    if (response.data) {
      const data = response.data.data?.data;
      const columns = response.data.data?.columns;
      if (data) {
        const chartData = data.map((dataEl: any) => {
          let formatedDataEl: any = {};
          dataEl.forEach((de: any, index: number) => {
            if (!index) {
              formatedDataEl.name = formatAMPM(
                new Date(de as unknown as string),
                isMobileView,
              );
            } else {
              const keyLabel = Object.entries(
                NestedProcessStepSerializerPackageProcessStepEnum,
              ).find((enumVal) => enumVal.includes(columns![index]));
              const configuredLabel = activePs.find(
                (ps) => ps.key === keyLabel![1],
              )?.label;
              formatedDataEl[configuredLabel] =
                de as unknown as number;
            }
          });
          return formatedDataEl;
        });
        setChartData(chartData);
      }
    }
  };

  useEffect(() => {
    let componentMounted = true;

    setTimeout(() => {
      if (componentMounted) {
        fetchStats();
      }
    }, index * CARD_LOAD_DELAY);

    if (
      dateUtils.isSystemDateToday() &&
      FETCH_INTERVAL_ACTIVE &&
      componentMounted
    ) {
      const interval = setInterval(() => {
        fetchStats();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }

    return function cleanup() {
      componentMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  return (
    <Paper className={classes.paper}>
      <Typography variant='h3' className={classes.cardTitle}>
        {filters &&
        getAppliedFilters(filters, ['hidePending']).length ? (
          <Tooltip
            title={
              <Box className={classes.filterTooltip}>
                <p>The following filters are applied to this card:</p>
                <ul>
                  {getAppliedFilters(filters, ['hidePending']).map(
                    (filter) => (
                      <li key={filter.key}>
                        <b>{filter.label}:</b>{' '}
                        {filter.values
                          .map(
                            (value) => value.name || value.full_name,
                          )
                          .join(', ')}
                      </li>
                    ),
                  )}
                </ul>
              </Box>
            }
          >
            <FilterListIcon className={classes.filterIcon} />
          </Tooltip>
        ) : undefined}
        {title}
      </Typography>

      <StackedAreaChart
        height={250}
        yLabel={'Number of Packages'}
        hideYAxis={isMobileView}
        config={activePs.map((ps) => ({
          dataKey: ps.label,
          // @ts-ignore
          color: chartColors[ps.key],
        }))}
        chartData={chartData}
        dataTestId='packages-charts-stats'
      />

      {!chartData && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: 130,
            left: '50%',
          }}
        />
      )}
    </Paper>
  );
};

export default withStyles(
  createStyles(() => ({
    cardTitle: {
      color: colors.dark,
      margin: '0 0 10px 0!important',
      fontWeight: 'bold',
      fontSize: '18px',
      paddingRight: '64px',
    },
    paper: {
      padding: 16,
      position: 'relative',
    },
    //Filter tooltip
    filterTooltip: {
      '& p': {
        fontSize: '12px',
      },
      '& ul': {
        fontSize: '12px',
        padding: '0 0 0 20px',
      },
    },
    filterIcon: {
      fontSize: '18px',
      background: colors.gold,
      color: colors.white,
      padding: '2px',
      borderRadius: '50%',
      margin: '0 5px -2px 0',
    },
  })),
)(TodayPackagesChart);
