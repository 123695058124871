import React from 'react';
import DashboardStatsService from '../../../services/DashboardStats.service';
import SingleRowStats from '../../singleRowStats/SingleRowStats';
import { AuthRoutes } from '../../../interfaces/routes';
import { NestedProcessStepProcessLabels } from '../../../utils/stats';
import { createStyles, withStyles } from '@material-ui/core/styles';
import utils from '../../../utils/stats';
import { renderVolumeString } from '../../DetailsPagesFunctions';

// Types
import {
  AreaDetailsAreaTypeEnum,
  NestedProcessStepSerializerPackageProcessStepEnum,
} from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  areaId?: number;
  areaType?: AreaDetailsAreaTypeEnum;
}

const styles = createStyles(() => ({
  packageStats: {
    '& .MuiGrid-item div': {
      padding: '16px 0 2px 0',
    },
    '& div:last-child': {
      '& div:last-child': {
        borderRadius: '5px',
        '& a:last-child p': {
          display: 'none',
        },
      },
    },
  },
}));

const PackagesOverview: React.FC<Props> = ({
  classes,
  areaType,
  areaId,
}) => {
  const getCallsByType = () => {
    switch (areaType) {
      case AreaDetailsAreaTypeEnum.PRIMARY:
        return [
          DashboardStatsService.getPackagesByStatus({
            processStep: [
              NestedProcessStepSerializerPackageProcessStepEnum.PRIMARY,
            ],
            area: areaId,
          }),
        ];
      case AreaDetailsAreaTypeEnum.SECONDARY:
        return [
          DashboardStatsService.getPackagesByStatus({
            processStep: [
              NestedProcessStepSerializerPackageProcessStepEnum.SECONDARY,
            ],
            area: areaId,
          }),
          DashboardStatsService.getPackagesByStatus({
            processStep: [
              NestedProcessStepSerializerPackageProcessStepEnum.ZONE,
            ],
            area: areaId,
          }),
          DashboardStatsService.getPackagesByStatus({
            processStep: [
              NestedProcessStepSerializerPackageProcessStepEnum.CONTAINERIZED,
            ],
            area: areaId,
          }),
        ];
      case AreaDetailsAreaTypeEnum.STAGING:
        return [
          DashboardStatsService.getPackagesByStatus({
            processStep: [
              NestedProcessStepSerializerPackageProcessStepEnum.STAGED,
            ],
            area: areaId,
          }),
        ];
      default:
        return [];
    }
  };

  const fetchStats = async () => {
    const calls = getCallsByType();
    const {
      data: { volume },
    } = await DashboardStatsService.getVolume({ area: areaId });

    const responses = await Promise.all(calls);
    let packagesInArea = 0;

    const data = responses.map(
      ({ data: { count, results }, request: { responseURL } }) => {
        let label = '';
        if (
          responseURL.includes(
            NestedProcessStepSerializerPackageProcessStepEnum.PRIMARY,
          )
        ) {
          label = NestedProcessStepProcessLabels.PRIMARY;
        } else if (
          responseURL.includes(
            NestedProcessStepSerializerPackageProcessStepEnum.CONTAINERIZED,
          )
        ) {
          label = NestedProcessStepProcessLabels.CONTAINERIZED;
        } else if (
          responseURL.includes(
            NestedProcessStepSerializerPackageProcessStepEnum.SECONDARY,
          )
        ) {
          label = NestedProcessStepProcessLabels.SECONDARY;
        } else if (
          responseURL.includes(
            NestedProcessStepSerializerPackageProcessStepEnum.ZONE,
          )
        ) {
          label = NestedProcessStepProcessLabels.ZONE;
        } else if (
          responseURL.includes(
            NestedProcessStepSerializerPackageProcessStepEnum.STAGED,
          )
        ) {
          label = NestedProcessStepProcessLabels.STAGED;
        }
        packagesInArea += count;
        return {
          label,
          value: count,
          longestDwellId: results.length ? results[0].id : undefined,
          longestDwellTime: results.length
            ? utils.getLongestDwellTimeLabel(
                results[0].process_step_time,
              )
            : undefined,
          filterDisabled: false,
          customFilters: [
            {
              filter_name: 'processStep',
              filter_value: label,
            },
            {
              filter_name: 'area',
              filter_value: areaId,
            },
          ],
        };
      },
    );
    if (areaType === AreaDetailsAreaTypeEnum.SECONDARY) {
      data.push({
        label: 'Packages',
        value: packagesInArea.toString(),
        filterDisabled: true,
      } as any);
    }
    data.push({
      label: 'Volume',
      value: renderVolumeString(volume),
      filterDisabled: true,
    } as any);
    return data;
  };

  return (
    <SingleRowStats
      link_base={AuthRoutes.PACKAGE}
      filter_name='processStep'
      dataTestId='areas-packages-overview'
      fetch={fetchStats}
      className={classes.packageStats}
    />
  );
};

export default withStyles(styles)(PackagesOverview);
