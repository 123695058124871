import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './singleRowStats.styles';
import { Typography, LoaderRing } from '../primitives';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import clx from 'classnames';
import {
  FETCH_INTERVAL_TIME,
  FETCH_INTERVAL_ACTIVE,
} from '../dashboard/Dashboard';
import dateUtils from '../../services/utils/date';
import { Link } from 'react-router-dom';

export interface CustomFilter {
  filter_name: string;
  filter_value: string;
}
export interface StatData {
  label: string;
  longestDwellTime?: string;
  longestDwellId?: string;
  value: string;
  linkTo?: string;
  customFilters?: CustomFilter[];
  filterDisabled?: boolean;
  valueStyle?: React.CSSProperties;
}

interface Props {
  classes: { [key: string]: string };
  dataTestId?: string;
  fetch: () => any;
  borderDividers?: boolean;
  link_base: string;
  filter_name?: string;
  className?: string;
  containerClassName?: string;
  dark?: boolean;
  style?: React.CSSProperties;
}

const SingleRowStats: React.FC<Props> = ({
  classes,
  dataTestId,
  fetch,
  borderDividers = true,
  link_base,
  filter_name,
  className,
  containerClassName,
  dark,
  style,
}) => {
  const [stats, setStats] = useState<StatData[] | undefined>(
    undefined,
  );

  const fetchData = async () => {
    const data = await fetch();
    setStats(data);
  };

  useEffect(() => {
    fetchData();

    if (dateUtils.isSystemDateToday() && FETCH_INTERVAL_ACTIVE) {
      const interval = setInterval(() => {
        fetchData();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseCustomFilters = (
    customFilters: CustomFilter[],
    baseFilter: CustomFilter,
  ) => {
    let link = customFilters.reduce(
      (accumulated, current, currentIndex) => {
        return `${accumulated}${currentIndex === 0 ? '' : '&'}${
          current.filter_name || baseFilter.filter_name
        }=${current.filter_value || baseFilter.filter_value}`;
      },
      '',
    );
    return link;
  };

  return (
    <Paper
      style={style}
      className={
        containerClassName +
        ' ' +
        (dark ? `${classes.paper} ${classes.dark}` : classes.paper)
      }
      data-testid={dataTestId}
    >
      {stats === undefined && (
        <div className={classes.singleStat}>
          <LoaderRing />
        </div>
      )}
      {stats !== undefined && (
        <Grid container spacing={3} className={className}>
          <Grid
            container
            item
            xs={12}
            className={clx({
              [classes.borderDividers]: borderDividers,
            })}
          >
            {stats.map((stat, index) => {
              return (
                <Grid
                  item
                  xs={stats.length === 1 ? 12 : 6}
                  sm={'auto'}
                  md={'auto'}
                  lg={'auto'}
                  xl={'auto'}
                  className={classes.singleStat}
                  key={`${stat?.label}-${index}`}
                >
                  <Typography
                    variant='body1'
                    className={classes.body1small}
                    data-testid={`${stat?.label}:title`}
                  >
                    {stat?.label}
                  </Typography>
                  {stat.filterDisabled === true ||
                  stat?.filterDisabled === undefined ? (
                    <Typography
                      variant='h6'
                      className={classes.bigNumber}
                      style={stat.valueStyle}
                      data-testid={`${stat?.label}:value`}
                    >
                      {stat?.value}
                    </Typography>
                  ) : (
                    <Link
                      className={classes.singleStatLink}
                      to={
                        link_base +
                        '?' +
                        (stat?.customFilters
                          ? parseCustomFilters(stat.customFilters, {
                              filter_name: filter_name || '',
                              filter_value: stat?.value,
                            })
                          : `${filter_name}=${stat?.label}`) +
                        '&sortBy=process_step_time'
                      }
                    >
                      <Typography
                        variant='h6'
                        className={classes.bigNumber}
                        style={stat.valueStyle}
                        data-testid={`${stat?.label}:value`}
                      >
                        {stat?.value}
                      </Typography>
                    </Link>
                  )}
                  {stat?.longestDwellId && (
                    <Link
                      className={classes.singleStatLink}
                      to={`${link_base}/${stat?.longestDwellId}`}
                    >
                      <Typography
                        variant='body1'
                        className={classes.longestDwellTime}
                      >
                        {stat?.longestDwellTime}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SingleRowStats);
