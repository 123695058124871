import React, { useEffect, useState } from 'react';
import {
  AuthRoutes,
  getModuleUrl,
  ModulesKeys,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import {
  MenuList,
  MenuItem,
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import styles from './navigation.styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';

// Mui components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import AddIcon from '@material-ui/icons/Add';

// Services
import LocalStorageService from '../../services/LocalStorage.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

// Types
import {
  PermissionsPermissionsEnum,
  StationDetails,
  Area,
  DockDoor,
  // Zone,
  // LoadPoint,
} from 'cloudsort-client';
import { ElementType } from '../stationLayout/StationLayout';

interface Props {
  classes: { [key: string]: string };
  currentPage?: string;
  stationLayoutData?: any;
  selectStationLayoutItem?: (params: {
    type: ElementType;
    id: number;
  }) => void;
  addStationLayoutItem?: (params: {
    type: ElementType;
    id: number;
  }) => void;
  newElementClick?: number;
}

enum NAV_PERMISSION_REQUIRED {
  AREA = PermissionsPermissionsEnum.STATIONREAD,
  CONTAINER = PermissionsPermissionsEnum.CONTAINERREAD,
  DASHBOARD = PermissionsPermissionsEnum.STATIONREAD,
  DEVICE = PermissionsPermissionsEnum.DEVICEREAD,
  DOCK_DOOR = PermissionsPermissionsEnum.STATIONREAD,
  LOADPLAN = PermissionsPermissionsEnum.LOADPLANREAD,
  MANIFEST = PermissionsPermissionsEnum.MANIFESTREAD,
  OUTBOUND_LOAD = PermissionsPermissionsEnum.OUTBOUNDLOADREAD,
  INBOUND_LOAD = PermissionsPermissionsEnum.INBOUNDLOADREAD,
  PACKAGE = PermissionsPermissionsEnum.PACKAGEREAD,
  ROUTE = PermissionsPermissionsEnum.ROUTEREAD,
  STAFF = PermissionsPermissionsEnum.USERREAD,
  STOP = PermissionsPermissionsEnum.STATIONREAD,
  SCHEME = PermissionsPermissionsEnum.LOADPLANREAD,
}

const Navigation: React.FC<Props> = ({
  classes,
  currentPage,
  stationLayoutData,
  selectStationLayoutItem,
  addStationLayoutItem,
  newElementClick,
}) => {
  const [myStationData, setMyStationData] =
    useState<StationDetails>();
  const [stationsData, setStationsData] =
    useState<StationDetails[]>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [stationDashboardExpanded, setStationDashboardExpanded] =
    useState<boolean>(true);

  const [stationPlannerExpanded, setStationPlannerExpanded] =
    useState<boolean>(false);

  const [stationViewExpanded, setStationViewExpanded] =
    useState<boolean>(false);

  //Station layout
  const [addElementExpanded, setAddElementExpanded] =
    useState<boolean>(false);
  const [expandedStationElements, setExpandedStationElements] =
    useState<string[]>([]);
  const [selectedElement, setSelectedElement] =
    useState<{ type: ElementType; id: number }>();

  const [navItems, setNavItems] = useState<Object[]>([]);

  const modulesData =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES;

  const fetchNavItems = () => {
    setNavItems(
      configurationUtils.getConfigArray({
        config:
          EphemeralStateService.getMyStationConfiguratation()?.WEB
            .NAV_MENU,
        filter: { key: 'active', value: true },
        sortBy: 'order',
      }),
    );
  };

  const expansionPanelClasses = {
    root: classes.expansionPanelRoot,
    disabled: classes.expansionPanelDisabled,
    expanded: classes.expansionPanelExpanded,
  };

  const expansionPanelSummaryClasses = {
    root: classes.expansionPanelSummary,
    content: classes.expansionPanelSummaryContent,
    expanded: classes.expansionPanelSummaryExpanded,
    expandIcon: classes.expansionPanelSummaryExpandIcon,
    disabled: classes.expansionPanelSummaryDisabled,
  };

  const onSelectStation = async (stationId: number) => {
    setShowProgress(true);
    await globalStationOptionsUtils.setStationData(stationId);
    setTimeout(() => {
      browserHistory.push(
        (process.env.REACT_APP_BASENAME || '') +
          getModuleUrl(ModulesKeys.DASHBOARD) +
          `?${stationDashboardUrlId}=` +
          stationId,
      );
    }, 0);
  };

  const setInitialStationData = async () => {
    try {
      fetchNavItems();
    } catch (e) {
      console.error(ErrorHandler.getLabel(e));
    }
  };

  const setStationsDataState = async () => {
    try {
      const myStations = LocalStorageService.getMyStationsData();
      if (myStations) {
        setStationsData(myStations);
      }
      const myStation = LocalStorageService.getMyStationData();
      if (myStation) {
        setMyStationData(myStation);
      }
    } catch (e) {
      console.error(ErrorHandler.getLabel(e));
    }
  };

  const renderNavItems = () => {
    return navItems.map((item: any) => {
      if (item.id === 'DASHBOARD') {
        return '';
      }
      return PermissionsService.hasPermission(
        NAV_PERMISSION_REQUIRED[item.id],
      ) ? (
        <div key={item.id}>
          <MenuItem
            data-testid={'navigation-link-' + item.id}
            id={'navigation-link-' + item.id}
            className={
              currentPage === item.id
                ? classes.current + ' ' + classes.menuItem
                : classes.menuItem
            }
            onClick={(e) => {
              e.preventDefault();
              const newPageUrl =
                AuthRoutes[item.id as keyof typeof AuthRoutes];
              const currentPageUrl = window.location.href;
              //Target only main route(ex: shoud stop redirect on /packages, but not on /packages/:id)
              if (!currentPageUrl.includes(`${newPageUrl}?`)) {
                browserHistory.push(newPageUrl);
              }
            }}
          >
            <Divider className={classes.indicator} />
            {item.id === 'SCHEME'
              ? 'Schemes' //Schemes is not a module, the plural is not available anywhere in the config.
              : modulesData[item.id].label_plural}
          </MenuItem>
          {item.divider_after && (
            <Divider className={classes.divider} />
          )}
        </div>
      ) : null;
    });
  };

  const setSelected = (params: { type: ElementType; id: number }) => {
    setSelectedElement(params);
    selectStationLayoutItem!(params);
  };

  const renderStaionLayoutNavigation = () => {
    if (
      stationLayoutData &&
      stationLayoutData.mappedAreas &&
      stationLayoutData.mappedZones &&
      stationLayoutData.mappedLoadpoints &&
      stationLayoutData.mappedDockdoors
    ) {
      const {
        mappedAreas,
        mappedZones,
        mappedLoadpoints,
        mappedDockdoors,
      } = stationLayoutData;

      return [
        mappedAreas.map((area: any) => {
          return (
            <ExpansionPanel
              classes={expansionPanelClasses}
              expanded={
                !!expandedStationElements.find(
                  (el) => el === 'A' + area.id,
                )
              }
              key={'LAYOUT-NAV-AREA' + area.id}
            >
              <ExpansionPanelSummary
                classes={expansionPanelSummaryClasses}
                className={
                  selectedElement &&
                  selectedElement.type === 'AREA' &&
                  selectedElement.id === area.id
                    ? classes.currentElement
                    : ''
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (
                    ['svg', 'path', 'BUTTON'].includes(
                      e.target.nodeName,
                    )
                  ) {
                    if (
                      expandedStationElements.includes('A' + area.id)
                    ) {
                      //remove
                      setExpandedStationElements(
                        [...expandedStationElements].filter(
                          (el) => el !== 'A' + area.id,
                        ),
                      );
                    } else {
                      //add
                      setExpandedStationElements([
                        ...expandedStationElements,
                        'A' + area.id,
                      ]);
                    }
                  } else {
                    setSelected({ type: 'AREA', id: area.id });
                  }
                }}
                expandIcon={
                  mappedZones.find(
                    (zone: any) => zone.area === area.id,
                  ) ? (
                    <IconButton>
                      <ExpandMoreIcon
                        className={classes.expandMoreIcon}
                      />
                    </IconButton>
                  ) : undefined
                }
              >
                <StopRoundedIcon style={{ margin: '-1px 5px 0 0' }} />{' '}
                {configurationUtils.getPageTitle(true, 'AREA')}:{' '}
                {area.name}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{
                  root: classes.expansionPanelDetailsRoot,
                }}
                style={{ display: 'block' }}
              >
                {mappedZones
                  .filter((zone: any) => zone.area === area.id)
                  .map((zone: any) => {
                    return (
                      <ExpansionPanel
                        classes={expansionPanelClasses}
                        expanded={
                          !!expandedStationElements.find(
                            (el) => el === 'Z' + zone.id,
                          )
                        }
                        key={'LAYOUT-NAV-ZONE' + zone.id}
                      >
                        <ExpansionPanelSummary
                          classes={expansionPanelSummaryClasses}
                          className={
                            selectedElement &&
                            selectedElement.type === 'ZONE' &&
                            selectedElement.id === zone.id
                              ? classes.currentElement
                              : ''
                          }
                          expandIcon={
                            mappedLoadpoints.find(
                              (lp: any) => lp.zone === zone.id,
                            ) ? (
                              <IconButton>
                                <ExpandMoreIcon
                                  className={classes.expandMoreIcon}
                                />
                              </IconButton>
                            ) : undefined
                          }
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (
                              ['svg', 'path', 'BUTTON'].includes(
                                e.target.nodeName,
                              )
                            ) {
                              if (
                                expandedStationElements.includes(
                                  'Z' + zone.id,
                                )
                              ) {
                                //remove
                                setExpandedStationElements(
                                  [...expandedStationElements].filter(
                                    (el) => el !== 'Z' + zone.id,
                                  ),
                                );
                              } else {
                                //add
                                setExpandedStationElements([
                                  ...expandedStationElements,
                                  'Z' + zone.id,
                                ]);
                              }
                            } else {
                              setSelected({
                                type: 'ZONE',
                                id: zone.id,
                              });
                            }
                          }}
                          style={{ paddingLeft: '40px' }}
                        >
                          <StopRoundedIcon
                            style={{ margin: '-1px 5px 0 0' }}
                          />{' '}
                          {configurationUtils.getPageTitle(
                            true,
                            'ZONE',
                          )}
                          : {zone.name}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          classes={{
                            root: classes.expansionPanelDetailsRoot,
                          }}
                          style={{ display: 'block' }}
                        >
                          <MenuList
                            className={classes.menuList}
                            data-testid='navigation-menu'
                          >
                            {mappedLoadpoints
                              .filter(
                                (lp: any) => lp.zone === zone.id,
                              )
                              .map((lp: any) => {
                                return (
                                  <MenuItem
                                    key={'LAYOUT-NAV-LP' + lp.id}
                                    className={
                                      selectedElement &&
                                      selectedElement.type ===
                                        'LOADPOINT' &&
                                      selectedElement.id === lp.id
                                        ? classes.menuItem +
                                          ' ' +
                                          classes.currentElement
                                        : classes.menuItem
                                    }
                                    onClick={() => {
                                      setSelected({
                                        type: 'LOADPOINT',
                                        id: lp.id,
                                      });
                                    }}
                                  >
                                    {lp.name}
                                  </MenuItem>
                                );
                              })}
                          </MenuList>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }),
        mappedDockdoors.map((dd: any) => {
          return (
            <MenuItem
              key={'LAYOUT-NAV-DD' + dd.id}
              className={
                selectedElement &&
                selectedElement.type === 'DOCKDOOR' &&
                selectedElement.id === dd.id
                  ? classes.menuItem + ' ' + classes.currentElement
                  : classes.menuItem
              }
              style={{ fontSize: '14px', padding: '14px 24px' }}
              onClick={() => {
                setSelected({
                  type: 'DOCKDOOR',
                  id: dd.id,
                });
              }}
            >
              <StopRoundedIcon
                style={{ margin: '-1px 2px -6px 0' }}
              />
              {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}:{' '}
              {dd.name}
            </MenuItem>
          );
        }),
      ];
    } else return <></>;
  };

  const renderStaionLayoutNewElement = () => {
    if (
      stationLayoutData &&
      stationLayoutData.allAreas &&
      stationLayoutData.allZones &&
      stationLayoutData.allLoadpoints &&
      stationLayoutData.allDockdoors
    ) {
      const { allAreas, allDockdoors } = stationLayoutData;

      if (
        !allAreas.find((area: Area) => !area.geo_shape) &&
        !allDockdoors.find((dd: DockDoor) => !dd.geo_shape)
      )
        return (
          <Typography className={classes.newElemetDrawerMessage}>
            All elements from this station have been added to the
            layout
          </Typography>
        );

      return [
        allAreas.map((area: Area) => {
          if (!area.geo_shape) {
            return (
              <Button
                className={classes.newElementDrawerButton}
                variant='contained'
                key={'ADD-AREA-BUTTON' + area.id}
                onClick={() => {
                  addStationLayoutItem!({
                    type: 'AREA',
                    id: area.id!,
                  });
                }}
              >
                Add {configurationUtils.getPageTitle(true, 'AREA')}{' '}
                {area.name}
                <AddIcon style={{ float: 'right' }} />
              </Button>
            );
          } else return null;
        }),
        allDockdoors.map((dd: DockDoor) => {
          if (!dd.geo_shape) {
            return (
              <Button
                className={classes.newElementDrawerButton}
                variant='contained'
                key={'ADD-DD-BUTTON' + dd.id}
                onClick={() => {
                  addStationLayoutItem!({
                    type: 'DOCKDOOR',
                    id: dd.id!,
                  });
                }}
              >
                Add{' '}
                {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}{' '}
                {dd.name}
                <AddIcon style={{ float: 'right' }} />
              </Button>
            );
          } else return null;
        }),
      ];
    }
    // TODO: Uncoment to add zones and LPs that are added later -- might need style adjusting
    // if (
    //   allZones.find(
    //     (zone: Zone) => zone.area === area.id && !zone.geo_shape,
    //   ) ||
    //   allLoadpoints.find(
    //     (lp: LoadPoint) => lp.area === area.id && !lp.geo_shape,
    //   )
    // ) {
    //   return (
    //     <>
    //       <span style={{ color: 'red' }}>
    //         Area {area.name} &rarr;{' '}
    //       </span>
    //       {allZones.map((zone: Zone) => {
    //         if (zone.area === zone.id && !zone.geo_shape) {
    //           return (
    //             <Button style={{ display: 'block' }}>
    //               Add Zone {zone.name}
    //             </Button>
    //           );
    //         } else if (
    //           allLoadpoints.find(
    //             (lp: LoadPoint) =>
    //               lp.zone === zone.id && !lp.geo_shape,
    //           )
    //         ) {
    //           return (
    //             <>
    //               <span style={{ color: 'yellow' }}>
    //                 Zone {zone.name} &rarr;
    //               </span>
    //               {allLoadpoints.map((lp: LoadPoint) => {
    //                 if (lp.zone === zone.id && !lp.geo_shape)
    //                   return (
    //                     <Button style={{ display: 'block' }}>
    //                       Add Loadpoint {lp.name}
    //                     </Button>
    //                   );
    //               })}
    //             </>
    //           );
    //         }
    //       })}
    //     </>
    //   );
    // } else return null;
  };

  useEffect(() => {
    if (currentPage === 'DASHBOARD') {
      setInitialStationData();
    }
    fetchNavItems();
    setStationsDataState();

    if (
      (browserHistory as any).location.pathname ===
      AuthRoutes.STATION_LAYOUT
    )
      setStationDashboardExpanded(false);

    if (
      currentPage &&
      ['STATION_PLANNER', 'SHIFT_PRESETS', 'SHIFT_CALENDAR'].includes(
        currentPage,
      )
    )
      setStationPlannerExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    newElementClick && setAddElementExpanded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newElementClick]);

  return (
    <>
      {showProgress && <ProgressIndicator />}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '5px 0',
        }}
      >
        <FormControl>
          <InputLabel className={classes.stationSelectorLabel}>
            STATION
          </InputLabel>
          <Select
            value={myStationData?.id || ''}
            className={classes.stationName}
            onChange={(e) => {
              e.preventDefault();
              const value = e.target.value;
              if (value && myStationData?.id! !== (value as number)) {
                onSelectStation(value as number);
              }
            }}
            inputProps={{
              classes: {
                icon:
                  stationsData && stationsData.length <= 1
                    ? classes.noStationSelectIcon
                    : classes.stationSelectIcon,
              },
            }}
          >
            {stationsData?.map((station) => {
              return (
                <MenuItem value={station.id} key={station.id}>
                  {station.name ? station.name : '---'}
                  <br />
                  {station.city ? station.city : ''}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <ExpansionPanel
        classes={expansionPanelClasses}
        expanded={stationDashboardExpanded}
      >
        <ExpansionPanelSummary
          classes={expansionPanelSummaryClasses}
          className={
            (browserHistory as any).location.pathname ===
            AuthRoutes.DASHBOARD
              ? classes.current
              : ''
          }
          onClick={(e: any) => {
            e.stopPropagation();
            if (
              ['svg', 'path', 'BUTTON'].includes(e.target.nodeName)
            ) {
              setStationDashboardExpanded(!stationDashboardExpanded);
            } else {
              browserHistory.push(AuthRoutes.DASHBOARD);
            }
          }}
          expandIcon={
            <IconButton>
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            </IconButton>
          }
        >
          <Divider className={classes.indicator} />
          <Typography variant='h6'>
            {modulesData?.DASHBOARD.label}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetailsRoot }}
        >
          <MenuList
            className={classes.menuList}
            data-testid='navigation-menu'
          >
            {renderNavItems()}
          </MenuList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {configurationUtils.isModuleActive('STATION_LAYOUT') &&
        PermissionsService.hasPermission('STATION_LAYOUT_READ') && (
          <>
            <ExpansionPanel
              classes={expansionPanelClasses}
              style={{ marginTop: '20px!important' }}
              expanded={
                stationViewExpanded ||
                (browserHistory as any).location.pathname ===
                  AuthRoutes.STATION_LAYOUT
              }
            >
              <ExpansionPanelSummary
                classes={expansionPanelSummaryClasses}
                className={
                  (browserHistory as any).location.pathname ===
                  AuthRoutes.STATION_LAYOUT
                    ? selectedElement === undefined
                      ? classes.currentStationView +
                        ' ' +
                        classes.currentElement
                      : classes.currentStationView
                    : ''
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (
                    ['svg', 'path', 'BUTTON'].includes(
                      e.target.nodeName,
                    )
                  ) {
                    setStationViewExpanded(!stationViewExpanded);
                  } else {
                    browserHistory.push(
                      AuthRoutes.STATION_LAYOUT +
                        '?stationId=' +
                        EphemeralStateService.getMyStationId(),
                    );
                  }
                }}
                expandIcon={
                  <IconButton>
                    <ExpandMoreIcon
                      className={classes.expandMoreIcon}
                    />
                  </IconButton>
                }
              >
                <Divider className={classes.indicator} />
                <Typography variant='h6'>
                  {configurationUtils.getPageTitle(
                    true,
                    'STATION_LAYOUT',
                  )}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{ root: classes.expansionPanelDetailsRoot }}
                style={{ display: 'block', paddingBottom: '50px' }}
              >
                {renderStaionLayoutNavigation()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {stationLayoutData?.allAreas && (
              <Box
                className={
                  addElementExpanded
                    ? classes.newElementDrawer +
                      ' ' +
                      classes.expandedDrawer
                    : classes.newElementDrawer
                }
              >
                <Box
                  className={classes.newElementDrawerHeader}
                  onClick={() => {
                    setAddElementExpanded(!addElementExpanded);
                  }}
                >
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography
                        style={{ padding: '12px 6px', color: '#000' }}
                      >
                        Add Element
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton>
                        <ExpandMoreIcon
                          className={classes.expandMoreIcon}
                          style={
                            addElementExpanded
                              ? { padding: 0 }
                              : {
                                  padding: 0,
                                  transform: 'rotate(180deg)',
                                }
                          }
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.newElementDrawerBody}>
                  {stationLayoutData?.allAreas?.length ||
                  stationLayoutData?.allDockdoors?.length ? (
                    renderStaionLayoutNewElement()
                  ) : (
                    <Typography
                      className={classes.newElemetDrawerMessage}
                    >
                      This station has no elements in it. Please
                      create some before adding them to the layout.
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            {configurationUtils.isModuleActive('STATION_PLANNER') &&
              PermissionsService.hasPermission(
                'STATION_PLANNER_READ',
              ) && (
                <ExpansionPanel
                  classes={expansionPanelClasses}
                  style={{ marginTop: '20px!important' }}
                  expanded={stationPlannerExpanded}
                >
                  <ExpansionPanelSummary
                    classes={expansionPanelSummaryClasses}
                    className={
                      currentPage === 'STATION_PLANNER'
                        ? classes.currentElement
                        : ''
                    }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (
                        ['svg', 'path', 'BUTTON'].includes(
                          e.target.nodeName,
                        )
                      ) {
                        setStationPlannerExpanded(
                          !stationPlannerExpanded,
                        );
                      } else {
                        browserHistory.push(
                          AuthRoutes.STATION_PLANNER +
                            '?stationId=' +
                            EphemeralStateService.getMyStationId(),
                        );
                      }
                    }}
                    expandIcon={
                      <IconButton>
                        <ExpandMoreIcon
                          className={classes.expandMoreIcon}
                        />
                      </IconButton>
                    }
                  >
                    <Divider className={classes.indicator} />
                    <Typography variant='h6'>
                      {configurationUtils.getPageTitle(
                        true,
                        'STATION_PLANNER',
                      )}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    classes={{
                      root: classes.expansionPanelDetailsRoot,
                    }}
                  >
                    <MenuList
                      className={classes.menuList}
                      data-testid='station-planner-menu'
                    >
                      <MenuItem
                        className={
                          currentPage === 'SHIFT_PRESETS'
                            ? classes.current + ' ' + classes.menuItem
                            : classes.menuItem
                        }
                        onClick={() => {
                          browserHistory.push(
                            AuthRoutes.SHIFT_PRESETS +
                              '?stationId=' +
                              EphemeralStateService.getMyStationId(),
                          );
                        }}
                      >
                        Shift Presets
                      </MenuItem>
                      {/* <MenuItem
                  className={
                    currentPage === 'SHIFT_CALENDAR'
                      ? classes.current + ' ' + classes.menuItem
                      : classes.menuItem
                  }
                  onClick={() => {
                    browserHistory.push(
                      AuthRoutes.SHIFT_CALENDAR +
                        '?stationId=' +
                        EphemeralStateService.getMyStationId(),
                    );
                  }}
                >
                  Calendar
                </MenuItem> */}
                    </MenuList>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
          </>
        )}
    </>
  );
};

export default withStyles(styles)(Navigation);
