import React, { useState } from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import PackagesModule from './ProcessSteps';
import ModulesModule from './Modules';
import Sorting from './Sorting';
import GhostResources from './GhostResources';
import LoadOps from './LoadOps';
import ContainerOps from './ContainerOps';
import StatesModule from './StatesModule';
import Entities from './Entities';
import { INTERACTION } from '../../Configuration';
import { TabPanel } from '../../../tabPanel/TabPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MultipleToggleBehaviors from './MultipleToggleBehaviors';
import { CurrentView } from '../Modules';
import ExceptionProcess from './ExceptionProcess';
import MachineLearningIntegration from './MachineLearningIntegration';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataWeb: any;
  setPartialData: (timers: any) => void;
  setPartialWebData: (webData: any) => void;
  interaction?: INTERACTION;
}

const GeneralDesktop: React.FC<Props> = ({
  classes,
  data,
  dataWeb,
  interaction,
  setPartialData,
  setPartialWebData,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const currentView = CurrentView.DESKTOP;
  return (
    <Box mt={1}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          classes={{
            expanded: classes.panelSummaryExpanded,
            root: classes.panelSummary,
          }}
          expandIcon={
            <ExpandMoreIcon
              className={classes.expansionPanelExpandColapseIcon}
            />
          }
        >
          <Typography className={classes.summaryPanelTitleText}>
            General
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.panelDetails,
          }}
        >
          <Tabs
            data-testid='general-tabs-root'
            value={currentTab}
            variant='scrollable'
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
            }}
            classes={{
              indicator: classes.tabsIndicator,
              root: classes.tabsRoot,
            }}
          >
            <Tab
              label='Modules'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Entities'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Process Steps'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Operations'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Sorting'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Ghost Resources'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
          </Tabs>

          <TabPanel currentTab={currentTab} index={0} label='Modules'>
            <ModulesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.MODULES}
              dataWeb={dataWeb}
              setPartialData={(modes) => {
                const dataObj = { ...data };
                dataObj.MODULES = modes;
                setPartialData(dataObj);
              }}
              setWebData={(data) => {
                const dataObj = { ...dataWeb, ...data };
                setPartialWebData(dataObj);
              }}
            />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={1}
            label='Entities'
          >
            <Entities
              currentView={currentView}
              interaction={interaction}
              data={data?.ENTITIES}
              setPartialData={(entities) => {
                const dataObj = { ...data };
                dataObj.ENTITIES = entities;
                setPartialData(dataObj);
              }}
            />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={2}
            label='Process Steps'
          >
            <PackagesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.PROCESS_STEPS}
              setPartialData={(modes) => {
                const dataObj = { ...data };
                dataObj.PROCESS_STEPS = modes;
                setPartialData(dataObj);
              }}
            />
            <StatesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.STATES}
              setPartialData={(states) => {
                const dataObj = { ...data };
                dataObj.STATES = states;
                setPartialData(dataObj);
              }}
            />
            <ExceptionProcess
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS = behaviorData;
                setPartialData(dataObj);
              }}
            />
            <MultipleToggleBehaviors
              title='Outbound Manifesting'
              interaction={interaction}
              data={[data?.BEHAVIORS?.OUTBOUND_MANIFESTING]}
              dataKeys={['OUTBOUND MANIFESTING']}
              setPartialData={() => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.OUTBOUND_MANIFESTING = {
                  ...dataObj.BEHAVIORS.OUTBOUND_MANIFESTING,
                };
                setPartialData(dataObj);
              }}
              currentView={currentView}
            />
            <MultipleToggleBehaviors
              title='Dispatchable Verification'
              interaction={interaction}
              data={[data?.BEHAVIORS?.DISPATCHABLE_VERIFICATION]}
              dataKeys={['DISPATCHABLE_VERIFICATION']}
              setPartialData={() => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.DISPATCHABLE_VERIFICATION = {
                  ...dataObj.BEHAVIORS.DISPATCHABLE_VERIFICATION,
                };
                setPartialData(dataObj);
              }}
              currentView={currentView}
            />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={3}
            label='Operations'
          >
            <LoadOps
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.LOAD_OPS}
              dataKey={'LOAD_OPS'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.LOAD_OPS = behaviorData;
                setPartialData(dataObj);
              }}
            />
            <ContainerOps
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.CONTAINER_OPS}
              dataKey={'CONTAINER_OPS'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.CONTAINER_OPS = behaviorData;
                setPartialData(dataObj);
              }}
            />
            <MachineLearningIntegration
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.ML_INTEGRATION}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.ML_INTEGRATION = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </TabPanel>
          <TabPanel currentTab={currentTab} index={4} label='Sorting'>
            <Sorting
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.SORTING}
              dataKey={'SORTING'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.SORTING = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={5}
            label='Ghost Resources'
          >
            <GhostResources
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.GHOST_RESOURCES_DAYS}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.GHOST_RESOURCES_DAYS = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </TabPanel>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};
export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(GeneralDesktop);
