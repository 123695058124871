import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';

interface Props {
  classes: { [key: string]: string };
  sourcePosition: Position;
  targetPosition: Position;
}

const Handles = ({
  sourcePosition,
  targetPosition,
  classes,
}: Props) => {
  return (
    <>
      <Handle
        type='source'
        position={sourcePosition || targetPosition}
        className={classes.invisibleHandle}
        style={{ [sourcePosition || targetPosition]: 5 }}
      />
      <Handle
        type='target'
        position={targetPosition || sourcePosition}
        className={classes.invisibleHandle}
        style={{ [targetPosition || sourcePosition]: 5 }}
      />
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    invisibleHandle: {
      opacity: 0,
      width: 1,
      height: 1,
      border: 0,
    },
  })),
)(Handles);
