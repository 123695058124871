import { StaffApi, Staff } from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import { LABEL_DPI, LABEL_SIZE } from './utils/constants';
import EphemeralStateService from './EphemeralState.service';

class StaffService {
  private api: StaffApi;

  constructor() {
    this.api = new StaffApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    search?: string;
    sortBy?:
      | 'active'
      | 'seconds_per_package'
      | '-active'
      | '-seconds_per_package';
    page?: number;
    pageSize?: number;
    stationId?: number;
    organizationId?: number;
    organizationstaffOnly?: boolean;
    active?: boolean;
    roleType?:
      | 'OPERATOR_I'
      | 'OPERATOR_II'
      | 'OPERATOR_III'
      | 'STATION_ADMIN'
      | 'ORG_ADMIN'
      | 'SUPER_USER';
  }) {
    if (!params.stationId && !params.organizationId)
      params.stationId = EphemeralStateService.getMyStationId();
    return this.api.staffList({
      ...params,
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
      extendData: true,
    });
  }

  public async getById(
    id: number,
    stationId?: number,
    organizationId?: number,
  ) {
    return this.api.staffRead({
      id,
      stationId,
      organizationId,
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
      extendData: true,
    });
  }

  public async getStatsById(id: number) {
    return this.api.staffStats({
      id,
      stationId: EphemeralStateService.getMyStationId(),
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
    });
  }

  public async getLabel(
    id: number,
    size: string = LABEL_SIZE,
    dpi: number = LABEL_DPI,
  ) {
    return this.api.staffLabelLabelPng({
      id,
      size,
      dpi,
      options: { responseType: 'blob' },
    });
  }

  public async create(data: Staff) {
    return this.api.staffCreate({ data });
  }

  public async update(id: number, data: Staff) {
    return this.api.staffPartialUpdate({ id, data });
  }

  public async delete(id: number) {
    return this.api.staffDelete({ id });
  }

  public async invalidateBadge(id: number) {
    return this.api.staffDeviceTokenReset({ id, data: {} });
  }

  public async checkEmail(email: string) {
    return this.api.staffLookup({ data: { email } });
  }
}

export default new StaffService();
