import { WebhooksApi } from 'cloudsort-client';
import axios from '../utils/axios';

class Webhooks {
  private api: WebhooksApi;

  constructor() {
    this.api = new WebhooksApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    page: number;
    page_size: number;
    owner: number | undefined;
    organization: number | undefined;
  }) {
    return this.api.webhooksList({ ...params });
  }

  public async getById(id: number) {
    return this.api.webhooksRead({
      id: id,
    });
  }
}

export default new Webhooks();
