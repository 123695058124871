import React, { useState, useEffect } from 'react';
import {
  Button,
  createStyles,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  TextField,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import colors from '../../utils/colors';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';

interface FetchResponse {
  data: JSX.Element[];
  count: number;
  errors?: any;
}
interface Props {
  classes: { [key: string]: string };
  dataTestIdPrefix?: string;
  title: string;
  fetch: (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => Promise<FetchResponse>;
  rowsPerPage: number;
  optionsMenu?: { label: string; callback: () => void }[];
  lastUpdateTimestamp: number;
}
const PaginatedList: React.FC<Props> = ({
  classes,
  dataTestIdPrefix = '',
  title,
  fetch,
  rowsPerPage = 1,
  optionsMenu,
  lastUpdateTimestamp,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isInSearchMode, setIsInSearchMode] = useState(false);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const fetchAndSetData = async (
    currentIndex: number,
    search: string = '',
  ) => {
    setShowProgress(true);
    const res = await fetch(currentIndex, rowsPerPage, search);
    if (res.errors) {
      handleError(res.errors);
    } else {
      setData(res.data);
      setCount(res.count);
      setError(undefined);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    if (isInSearchMode) {
      fetchAndSetData(currentPageIndex, searchQuery);
    } else {
      fetchAndSetData(currentPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  useEffect(() => {
    //Reset the state, then fetch data
    setCurrentPageIndex(0);
    setSearchQuery('');
    setShowSearchInput(false);
    setIsInSearchMode(false);
    fetchAndSetData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTimestamp]);

  const renderSettingsMenu = () => {
    return (
      <div>
        <Button
          data-testid={`${dataTestIdPrefix}-open-options-menu-btn`}
          onClick={(event) => {
            setOptionsMenuAnchorEl(event.currentTarget);
          }}
          style={{ margin: '-2px 0 2px 0' }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          anchorEl={optionsMenuAnchorEl}
          keepMounted
          open={Boolean(optionsMenuAnchorEl)}
          onClose={() => {
            setOptionsMenuAnchorEl(null);
          }}
          TransitionComponent={Fade}
        >
          {optionsMenu?.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setOptionsMenuAnchorEl(null);
                menuItem.callback();
              }}
              data-testid={`${dataTestIdPrefix}-options-menu-item-${menuItem.label}`}
            >
              {menuItem.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };
  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Paper
        className={classes.paper}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px 16px',
          paddingBottom: 0,
          minHeight: 449,
        }}
        data-testid={`${dataTestIdPrefix}-card`}
      >
        <div>
          <Grid
            container
            spacing={0}
            style={{
              borderBottom: `1px solid ${colors.ultraLightGray}`,
              marginBottom: 16,
            }}
          >
            <Grid item xs={12} sm={3}>
              <Typography
                data-testid={`${dataTestIdPrefix}-card-title`}
                className={classes.boldNameParam}
                style={{
                  paddingBottom: 8,
                  marginTop: 4,
                  fontSize: 18,
                  lineHeight: '21px',
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={optionsMenu ? 7 : 9}
              style={{ textAlign: 'right' }}
            >
              <>
                {showSearchInput && (
                  <TextField
                    style={{ width: '60%' }}
                    data-testid={`${dataTestIdPrefix}-card-search-input`}
                    value={searchQuery}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        if (!isInSearchMode) {
                          setIsInSearchMode(true);
                        }
                        fetchAndSetData(
                          currentPageIndex,
                          searchQuery,
                        );
                      }
                    }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                )}
                <Button
                  data-testid={`${dataTestIdPrefix}-card-search-icon`}
                  style={{ margin: '-2px 0 2px 0' }}
                  onClick={(e) => {
                    if (!showSearchInput) {
                      setShowSearchInput(true);
                    } else {
                      fetchAndSetData(currentPageIndex, searchQuery);
                    }
                  }}
                >
                  <SearchIcon />
                </Button>
              </>
            </Grid>
            {optionsMenu && (
              <Grid
                item
                xs={12}
                sm={2}
                style={{ textAlign: 'right' }}
              >
                {renderSettingsMenu()}
              </Grid>
            )}
          </Grid>
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          {data.map((element, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                data-testid={`${dataTestIdPrefix}-card-list-item-${index}`}
              >
                {element}
              </Grid>
            );
          })}
        </div>
        <div style={{ marginRight: -16 }}>
          <TablePagination
            data-testid={`${dataTestIdPrefix}-card-pagination`}
            rowsPerPageOptions={[]}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPageIndex}
            onChangePage={(
              event: React.MouseEvent<
                HTMLButtonElement,
                MouseEvent
              > | null,
              page: number,
            ) => {
              setCurrentPageIndex(page);
            }}
          />
        </div>
      </Paper>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
  })),
)(PaginatedList);
