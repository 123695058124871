import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import OperatorTool from './operatorTool/OperatorTool';
import GeneralModule from './general/General';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import { INTERACTION, Mode } from '../Configuration';
import { AlertBanner, Typography } from '../../primitives';
import clx from 'classnames';
import UploadConfigDialog from './UploadConfigDialog';
import WebApp from './webApp/WebApp';
import colors from '../../../utils/colors';
import OperatorToolOnboarding from './operatorToolOnboarding/OperatorToolOnboarding';

// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '../../../utils/svgs/CloudDownloadIcon';

// Redux
import { useAppSelector } from '../../../redux/store';
import {
  selectSearchQuery,
  selectSections,
} from '../../../redux/slices/searchSlice';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  showJustImportedBanner?: boolean;
  setData: (data: any) => void;
  exportConfig: () => void;
  importConfig: (configToImport: any) => void;
  mode: Mode;
}

export enum CurrentView {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  SEARCH = 'Search',
}

export enum SelectedModule {
  GENERAL = 'General',
  OT = 'Operator Tool',
  OT_ONBOARDING = 'Operator Tool Onboarding',
  WEB = 'Web App',
}

export enum ModuleName {
  GENERAL = 'GENERAL',
  OPERATOR_TOOL = 'OT',
  OT_ONBOARDING = 'OPERATOR_TOOL_ONBOARDING',
  WEB = 'WEB',
}

const Modules: React.FC<Props> = ({
  classes,
  data,
  interaction,
  showJustImportedBanner,
  setData,
  exportConfig,
  importConfig,
}) => {
  const [showUploadDialog, setShowUploadDialog] =
    useState<boolean>(false);

  const [currentView, setCurrentView] = useState<CurrentView>(
    CurrentView.DESKTOP,
  );
  const [selectedModule, setSelectedModule] =
    useState<SelectedModule | null>(null);

  const searchQuery = useAppSelector(selectSearchQuery);
  const sectionsWithResults = useAppSelector(selectSections);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const hasSearchQuery = searchQuery.trim().length > 0;
    if (hasSearchQuery && currentView !== CurrentView.SEARCH) {
      setCurrentView(CurrentView.SEARCH);
    }
    if (
      !hasSearchQuery &&
      isMobileView &&
      currentView !== CurrentView.MOBILE
    ) {
      setCurrentView(CurrentView.MOBILE);
    }
    if (
      !hasSearchQuery &&
      !isMobileView &&
      currentView !== CurrentView.DESKTOP
    ) {
      setCurrentView(CurrentView.DESKTOP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isMobileView]);

  const renderDesktopView = () => {
    return (
      <Fragment>
        <UploadConfigDialog
          isOpen={showUploadDialog}
          data={data}
          importConfig={(configToImport: any) => {
            importConfig(configToImport);
          }}
          onAfterClose={() => {
            setShowUploadDialog(false);
          }}
        />
        <Grid
          direction='row'
          xs={12}
          container
          item
          data-testid='modules-config-page'
        >
          {!isMobileView && (
            <Grid item sm={6} xs={12}>
              <Box mb={2} mt={2}>
                <Box m={0} mb={-2}>
                  <Button
                    fullWidth={false}
                    variant='contained'
                    className={clx(
                      classes.containedButton,
                      classes.containedButtonDark,
                    )}
                    onClick={() => {
                      setShowUploadDialog(true);
                    }}
                  >
                    <CloudUploadIcon style={{ marginRight: 10 }} />
                    Import Configuration
                  </Button>
                  <Button
                    fullWidth={false}
                    variant='contained'
                    className={classes.containedButton}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      exportConfig();
                    }}
                  >
                    <CloudDownloadIcon style={{ marginRight: 10 }} />
                    Export Configuration
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid direction='row' xs={12} container item></Grid>
          {showJustImportedBanner && (
            <Grid direction='row' xs={12} container item>
              <Box mt={2} width={'100%'}>
                <AlertBanner
                  className={classes.bannerGold}
                  severity='info'
                  alertMsg={
                    'Module Configuration imported successfully. Please save to confirm the changes.'
                  }
                />
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.GENERAL}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <GeneralModule
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data?.config?.GENERAL}
              dataWeb={data?.config?.WEB}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.GENERAL = partialData;
                setData(partialDataObj);
              }}
              setPartialWebData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.WEB = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.OPERATOR_TOOL}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <OperatorTool
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data?.config?.OT}
              modulesData={data?.config?.GENERAL?.MODULES}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.OT = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.OT_ONBOARDING}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <OperatorToolOnboarding
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.OT.ONBOARDING = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.WEB}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <WebApp
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data}
              setData={(newData) => {
                const newDataObj = { ...data };
                setData(newDataObj);
              }}
            />
          </Grid>
          {currentView === CurrentView.SEARCH &&
            sectionsWithResults.length === 0 &&
            searchQuery.length > 0 && (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                }}
                data-testid='empty-search-results'
              >
                <Typography variant='h3'>
                  Your search for{' '}
                  <pre className={classes.highlightedSearchTerm}>
                    {searchQuery}
                  </pre>{' '}
                  didn't return any results.
                </Typography>
              </Grid>
            )}
        </Grid>
      </Fragment>
    );
  };

  const renderModuleSelection = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems='center'
        justify='flex-start'
      >
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.GENERAL)}
          >
            General
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.OT)}
          >
            Operator Tool
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() =>
              setSelectedModule(SelectedModule.OT_ONBOARDING)
            }
          >
            Operator Tool Onboarding
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.WEB)}
          >
            Web App
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderMobileView = () => {
    if (selectedModule === null) {
      return renderModuleSelection();
    }

    if (selectedModule === SelectedModule.GENERAL) {
      return (
        <GeneralModule
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data?.config?.GENERAL}
          dataWeb={data?.config?.WEB}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.GENERAL = partialData;
            setData(partialDataObj);
          }}
          setPartialWebData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.WEB = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.OT) {
      return (
        <OperatorTool
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data?.config?.OT}
          modulesData={data?.config?.GENERAL?.MODULES}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.OT = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.OT_ONBOARDING) {
      return (
        <OperatorToolOnboarding
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.OT.ONBOARDING = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.WEB) {
      return (
        <WebApp
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data}
          setData={(newData) => {
            const newDataObj = { ...data };
            setData(newDataObj);
          }}
        />
      );
    }
    return <></>;
  };

  const selectViewToRender = () => {
    if (currentView === CurrentView.MOBILE) {
      return renderMobileView();
    }
    return renderDesktopView();
  };

  return selectViewToRender();
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    categoryCard: {
      fontSize: '14px',
      cursor: 'pointer',
      textAlign: 'center',
      minHeight: '73px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      verticalAlign: 'middle',
      boxShadow:
        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      borderRadius: '3px',
    },
    highlightedSearchTerm: {
      display: 'inline-block',
      backgroundColor: colors.lightGold,
      padding: 5,
      borderRadius: 3,
    },
  })),
)(Modules);
