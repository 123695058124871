import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: '#fff',
    color: '#646464',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      background: colors.dark,
      color: colors.white,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerLogo: {
    marginBottom: '-5px',
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      filter: 'invert(1)',
    },
  },
  headerMenuToggle: {
    color: colors.white,
    marginRight: '5px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  title: {
    display: 'none',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: '1rem',
    minWidth: '12%',
    color: colors.black,
    padding: theme.spacing(2, 2, 2, 0),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  searchHoder: {
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '9%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '11%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '12.5%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '13%',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f5f6f9',
    '&:hover': {
      backgroundColor: '#f5f6f9',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderRadius: 4,
    boxShadow: 'inset 0 1px 3px 1px rgba(196, 196, 196, 0.32)',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  sectionDesktop: {
    display: 'none',
    '& svg': {
      color: colors.black,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  accountNameInitials: {
    width: 30,
    height: 30,
    lineHeight: '30px',
    marginRight: 6,
    overflow: 'none',
    borderRadius: '50%',
    textAlign: 'center',
    color: colors.gold,
    backgroundColor: colors.black,
    fontWeight: 'bold',
    fontSize: 10,
  },
  accountName: {
    // width: 65,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  borderLeft: {
    borderLeft: '2px solid #f5f6f9',
    borderRadius: '0',
  },
  paddingButton: {
    padding: 21,
  },
  paddingMenu: {
    padding: '0 0 0 10px',
  },
  timeHolder: {
    minWidth: 136,
    borderLeft: '2px solid #f5f6f9',
    height: '100%',
    display: 'flex',
    placeItems: 'center',
    flexDirection: 'column',
    padding: '10px 0',
    cursor: 'pointer',
  },
  currentTime: {
    textAlign: 'center',
    color: colors.black,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.46px',
  },
  GMTTime: {
    textAlign: 'center',
    color: colors.gold,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.76px',
  },
  currentDate: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#b6b6b6',
  },
  notToday: {
    padding: '0 0 2px 0',
    '& svg': {
      width: 30,
      height: 'auto',
    },
  },
  weatherTooltip: {
    padding: '0 50px',
    textAlign: 'center',
    '& h4': {
      fontSize: '1.5rem',
      fontWeight: 'normal',
      margin: '0',
      color: colors.gold,
    },
    '& p': {
      marginTop: '0',
    },
    '& img': {
      marginTop: theme.spacing(2),
    },
  },
  weatherMoreInfo: {
    textDecoration: 'none',
    color: '#222222',
    border: '0.8px solid #E1E1E1',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    padding: '5px 10px',
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  exitProfileButton: {
    backgroundColor: colors.dark,
    color: colors.white,
    paddingLeft: '20px',
    paddingRight: '20px',
    '& svg': {
      color: colors.white,
      width: '30px',
      height: '30px',
      marginLeft: '20px',
    },
    '&:hover': {
      backgroundColor: colors.black,
    },
  },
}));
