import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner } from '../../primitives';
import colors from '../../..//utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  Box,
  DialogTitle,
  createStyles,
  TextField,
  FormControl,
  Theme,
} from '@material-ui/core';
import selectStyles from '../../select/select.styles';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import { onlyNumbers } from '../StationPlanner';

//Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import configurationUtils from '../../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  showProgress?: boolean;
  fields: { [name: string]: string };
  onClose: () => void;
  onSubmit: (fields: { [name: string]: string }) => void;
  isOpen: boolean;
  error?: string;
}

const EditStationPlannerDialog: React.FC<Props> = ({
  showProgress = false,
  isOpen,
  fields,
  classes,
  onClose,
  onSubmit,
  error,
}) => {
  const [fieldValues, setFieldValues] = useState<{
    [name: string]: string;
  }>({
    packages: '',
  });

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const containerLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'CONTAINER'),
      plural: configurationUtils.getPageTitle(false, 'CONTAINER'),
    };
  }, []);

  const handleSubmit = () => {
    onSubmit(fieldValues);
  };

  useEffect(() => {
    setFieldValues(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={isOpen} data-testid='edit-station-planner-dialog'>
        <DialogTitle
          style={{ textAlign: 'center', padding: '40px 0 20px 0' }}
        >
          <AddToPhotosOutlinedIcon
            style={{
              color: colors.darkGold,
              width: 36,
              height: 'auto',
            }}
          />
          <br />
          {`Edit ${packageLabels.plural} per ${containerLabels.singular}`}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                  style={{ marginBottom: 20 }}
                />
              )}

              <FormControl
                className={classes.formControl}
                style={{ width: '100%', margin: '0 0px 10px 0' }}
              >
                <TextField
                  label={
                    <span style={{ color: colors.title }}>
                      {/* tslint:disable-next-line */}
                      {`${packageLabels.plural} per 
                      ${containerLabels.singular} (Pallet)`}
                    </span>
                  }
                  value={fieldValues.packages}
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      packages: onlyNumbers(e.target.value),
                    });
                  }}
                  FormHelperTextProps={{
                    style: { paddingLeft: '5px', marginTop: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pl={1} pr={1}>
            <Button
              variant='outlined'
              className={classes.containedButton}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              disabled={!fieldValues.packages}
              variant='outlined'
              className={classes.buttonFilled}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...selectStyles,
    dialogContent: {
      width: '500px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',
      '&:hover': {
        color: colors.darkGold,
        background: 'transparent',
      },
    },
  })),
)(EditStationPlannerDialog);
