import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner } from '../../primitives';
import colors from '../../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  DialogTitle,
  createStyles,
  TextField,
  FormControl,
  Theme,
} from '@material-ui/core';

import {
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import selectStyles from '../../select/select.styles';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  calculateShiftLength,
  DialogInteraction,
  onlyNumbers,
} from '../StationPlanner';

//Icons
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import configurationUtils from '../../../utils/configurationUtils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ShiftPlanPresetRecurrenceEnum } from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  interaction: DialogInteraction;
  showProgress?: boolean;
  fields: { [name: string]: string };
  onClose: () => void;
  onSubmit: (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => void;
  onRemove: () => void;
  isOpen: boolean;
  error?: string;
  selectedDate: Date;
  onlyTime?: boolean;
  recurrenceData?: ShiftPlanPresetRecurrenceEnum[];
  filterByRecurrence: boolean;
}

const AddEditShiftRowDialog: React.FC<Props> = ({
  showProgress = false,
  interaction,
  isOpen,
  fields,
  classes,
  onClose,
  onSubmit,
  onRemove,
  error,
  selectedDate,
  onlyTime,
  filterByRecurrence = false,
  recurrenceData,
}) => {
  const [fieldValues, setFieldValues] = useState<{
    [name: string]: string;
  }>({
    sort: '',
    startTime: new Date().toString(),
    endTime: new Date().toString(),
    secondsPerPackage: '',
    staffDirect: '',
    staffSupport: '',
  });
  const [sortLength, setSortLength] = useState<string>('0');

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const staffLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'STAFF'),
      plural: configurationUtils.getPageTitle(false, 'STAFF'),
    };
  }, []);

  const handleSubmit = () => {
    onSubmit({ interaction, fields: fieldValues });
  };

  useEffect(() => {
    setFieldValues(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    setSortLength(
      calculateShiftLength(
        fieldValues.startTime,
        fieldValues.endTime,
      ).toFixed(1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={isOpen} data-testid='edit-shift-row-dialog'>
        <DialogTitle
          style={{ textAlign: 'center', padding: '40px 0 20px 0' }}
        >
          {interaction === DialogInteraction.ADD ? (
            <AddToPhotosOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          ) : (
            <EditIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          )}
          <br />
          {interaction === DialogInteraction.ADD
            ? 'Add Sort'
            : 'Edit Sort'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                  style={{ marginBottom: 20 }}
                />
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label='Sort'
                      inputProps={{ maxLength: 50 }}
                      value={fieldValues.sort}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          sort: e.target.value,
                        });
                      }}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {onlyTime ? (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{
                          width: '100%',
                          margin: '0 0px 10px 0',
                        }}
                      >
                        <KeyboardTimePicker
                          variant='dialog'
                          label='Start Time'
                          format='hh:mm a'
                          value={fieldValues.startTime}
                          onChange={(date) => {
                            setFieldValues({
                              ...fieldValues,
                              startTime: date?.toString() || '',
                            });
                          }}
                          FormHelperTextProps={{
                            style: {
                              paddingLeft: '5px',
                              marginTop: 0,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.selectLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputPadding,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{
                          width: '100%',
                          margin: '0 0px 10px 0',
                        }}
                      >
                        <KeyboardTimePicker
                          variant='dialog'
                          label='End Time'
                          format='hh:mm a'
                          value={fieldValues.endTime}
                          onChange={(date) => {
                            setFieldValues({
                              ...fieldValues,
                              endTime: date?.toString() || '',
                            });
                          }}
                          FormHelperTextProps={{
                            style: {
                              paddingLeft: '5px',
                              marginTop: 0,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.selectLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputPadding,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{
                          width: '100%',
                          margin: '0 0px 10px 0',
                        }}
                      >
                        <KeyboardDateTimePicker
                          variant='dialog'
                          label='Start Time'
                          format='MM/dd/yyyy hh:mm a'
                          value={fieldValues.startTime}
                          disablePast={true}
                          shouldDisableDate={(
                            date: MaterialUiPickersDate,
                          ) => {
                            if (
                              filterByRecurrence &&
                              recurrenceData
                            ) {
                              const dayOfTheWeek = moment(date)
                                .format('dddd')
                                .toUpperCase() as ShiftPlanPresetRecurrenceEnum;

                              return !recurrenceData.includes(
                                dayOfTheWeek,
                              );
                            }

                            return !moment(date).isSame(selectedDate);
                          }}
                          onChange={(date) => {
                            setFieldValues({
                              ...fieldValues,
                              startTime: date?.toString() || '',
                            });
                          }}
                          FormHelperTextProps={{
                            style: {
                              paddingLeft: '5px',
                              marginTop: 0,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.selectLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputPadding,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{
                          width: '100%',
                          margin: '0 0px 10px 0',
                        }}
                      >
                        <KeyboardDateTimePicker
                          variant='dialog'
                          label='End Time'
                          format='MM/dd/yyyy hh:mm a'
                          minDateMessage={
                            'End time must be after start time'
                          }
                          value={fieldValues.endTime}
                          minDate={moment(fieldValues.startTime)}
                          maxDate={moment(fieldValues.startTime).add(
                            1,
                            'day',
                          )}
                          onChange={(date) => {
                            setFieldValues({
                              ...fieldValues,
                              endTime: date?.toString() || '',
                            });
                          }}
                          FormHelperTextProps={{
                            style: {
                              paddingLeft: '5px',
                              marginTop: 0,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.selectLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputPadding,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </MuiPickersUtilsProvider>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label='Sort Length (Hours)'
                      value={sortLength}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                        disabled: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label={`Seconds per ${packageLabels.singular}`}
                      value={fieldValues.secondsPerPackage}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          secondsPerPackage: onlyNumbers(
                            e.target.value,
                          ),
                        });
                      }}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label={`${staffLabels.plural} - Direct`}
                      value={fieldValues.staffDirect}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          staffDirect: onlyNumbers(e.target.value),
                        });
                      }}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '100%', margin: '0 0px 10px 0' }}
                  >
                    <TextField
                      label={`${staffLabels.plural} - Support`}
                      value={fieldValues.staffSupport}
                      onChange={(e) => {
                        setFieldValues({
                          ...fieldValues,
                          staffSupport: onlyNumbers(e.target.value),
                        });
                      }}
                      FormHelperTextProps={{
                        style: { paddingLeft: '5px', marginTop: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.selectLabel,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.inputPadding,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {interaction === DialogInteraction.EDIT && (
            <>
              <Button
                variant='outlined'
                className={classes.containedButton}
                onClick={onRemove}
              >
                Remove
              </Button>
              <div style={{ flexGrow: 1 }} />
            </>
          )}
          <Button
            variant='outlined'
            className={classes.containedButton}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !fieldValues.sort ||
              !fieldValues.startTime ||
              !fieldValues.endTime ||
              !fieldValues.secondsPerPackage ||
              !fieldValues.staffDirect ||
              !fieldValues.staffSupport
            }
            variant='outlined'
            className={classes.buttonFilled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...selectStyles,
    dialogContent: {
      width: '520px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',

      '&:hover': {
        color: colors.darkGold,
        background: 'transparent',
      },
    },
  })),
)(AddEditShiftRowDialog);
