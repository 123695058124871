import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 15,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
  },
  root: {},
  dark: {
    backgroundColor: colors.dark,
    color: colors.lightGray,
    '& h6': {
      color: colors.gold,
    },
    '& $borderDividers': {
      '& $singleStat': {
        borderRight: '2px solid #545057',
      },
      '& $singleStat:last-child': {
        borderRight: 'none',
      },
    },
  },
  button: {
    float: 'right',
  },
  body1small: {
    fontSize: '0.75rem',
    marginBottom: 5,
    color: colors.lightGray,
  },
  longestDwellTime: {
    color: colors.darkGold,
    fontSize: 12,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  bigNumber: {
    fontSize: '1.75rem',
    fontWeight: 'normal',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  borderDividers: {
    '& $singleStat': {
      borderRight: '2px solid #f5f6f9',
      [theme.breakpoints.down('sm')]: {
        borderBottom: '2px solid #f5f6f9',
      },
    },
    '& $singleStat:last-child': {
      borderRight: 'none',
      [theme.breakpoints.down('sm')]: {
        borderRight: '2px solid #f5f6f9',
      },
    },
  },
  singleStat: {
    flexGrow: 1,
    minHeight: 88,
    padding: '16px 16px 2px 16px',
  },
  singleStatLink: {
    textDecoration: 'none',
    color: colors.dark,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
