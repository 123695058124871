export default (txt: string) => {
  if (!txt) {
    return '';
  }
  return txt
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) =>
        txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
};
