import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import { Typography, AlertBanner } from '../primitives';

import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';
import {
  renderPackageTarget1stSortationIdString,
  renderPackageTarget2ndSortationIdString,
  renderPackageTargetStagingIdString,
  humanReadableNull,
  renderWeightString,
  renderPackageContainerLocationString,
  renderVolumeString,
} from '../DetailsPagesFunctions';
import {
  PackageDetails,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import ProcessSteps from '../eventsSnapshot/processSteps/ProcessSteps';
import Button from '@material-ui/core/Button';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import { Grid, Theme } from '@material-ui/core';
import colors from '../../utils/colors';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import qrCodeUtils from '../../utils/qrCode';
import SingleRowStats from '../singleRowStats/SingleRowStats';
import configurationUtils from '../../utils/configurationUtils';
import sanitizeHtml from 'sanitize-html';

//Services
import PackagesService from '../../services/Packages.service';
import PermissionsService from '../../services/Permissions.service';
import EventsService from '../../services/Events.service';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import { labelConfigOpt } from '../../services/utils/labelDefinitions';
import PackagePrediction from './utils/PackagePrediction';
import EphemeralStateService from '../../services/EphemeralState.service';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const PackageDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const thisPackagesId = match.params.id;
  const [packageData, setPackageData] = useState<PackageDetails>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const fetchPackageDetails = async () => {
    return PackagesService.getById(thisPackagesId);
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
    sortedBy?: string,
  ) => {
    let sortedByVal = sortedBy;
    if (sortedByVal && sortedByVal.includes('area_name')) {
      sortedByVal = sortedByVal.replace('area_name', 'area__name');
    }
    return EventsService.getAll({
      page: pageIndex,
      sortBy:
        (sortedByVal as
          | 'timestamp'
          | 'area__name'
          | '-timestamp'
          | '-area__name') || undefined,
      _package: thisPackagesId,
      pageSize: rowsPerPage,
    });
  };

  const fetchPackageDetailsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await fetchPackageDetails();
      setPackageData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (packageData?.id) {
        const labelOTConfig = await labelConfigOpt();
        qrCodeUtils.download(
          await PackagesService.getLabel(
            packageData.id,
            labelOTConfig.size,
            labelOTConfig.dpi,
          ),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const updatePackageDetailsData = async (
    newData: PackageDetails,
  ) => {
    setShowProgress(true);
    try {
      const { data } = await PackagesService.update({
        id: packageData?.id!,
        data: newData,
      });
      setPackageData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.PACKAGEREAD,
    );

    fetchPackageDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
    ${configurationUtils.getPageTitle(true, 'PACKAGE')} ${
            packageData?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='PACKAGE'>
        {showProgress && <ProgressIndicator />}
        <div data-testid='package-details-view'>
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Typography
                className={classes.title}
                style={{ marginBottom: 16 }}
              >
                {`${configurationUtils.getPageTitle(
                  true,
                  'PACKAGE',
                )} ${packageData?.id || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              {PermissionsService.hasPermission(
                PermissionsPermissionsEnum.PACKAGELABELREAD,
              ) && (
                <Button
                  data-testid='package-details:label-button'
                  variant='outlined'
                  className={classes.outlinedButton}
                  disabled={!packageData}
                  onClick={onGetQrCick}
                >
                  <FilterCenterFocusIcon
                    style={{ marginRight: 10 }}
                  />
                  label
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6} md={8}>
              <Paper
                className={classes.paper}
                style={{ padding: '15px 30px' }}
                data-testid='package-details-detail'
              >
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label='Location'
                  value={renderPackageContainerLocationString(
                    packageData?.station_name,
                    packageData?.next_steps?.staging_area?.name,
                    undefined,
                    packageData?.next_steps?.outbound_load?.name,
                  )}
                />
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={
                    configurationUtils.getPageTitle(
                      true,
                      'OUTBOUND_LOAD',
                    ) + ' ID'
                  }
                  value={
                    (packageData?.outbound_load &&
                      packageData?.outbound_load?.id) ||
                    common.emptyValue
                  }
                />
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPageTitle(
                    true,
                    'INBOUND_LOAD',
                  )}
                  value={
                    packageData?.inbound_load ? (
                      <Link
                        to={`${AuthRoutes.INBOUND_LOAD}/${packageData.inbound_load}`}
                        style={{
                          color: colors.darkGold,
                        }}
                      >
                        {humanReadableNull(
                          packageData?.inbound_load_name,
                        )}
                      </Link>
                    ) : (
                      common.emptyValue
                    )
                  }
                />
                <SingleDetail
                  inline={true}
                  label={'Sortation Parameter'}
                  value={sanitizeHtml(
                    packageData?.sort_param || common.emptyValue,
                    {
                      allowedTags: [],
                    },
                  )}
                  onEdit={(value) => {
                    updatePackageDetailsData({
                      ...packageData,
                      sort_param: value || null,
                    });
                    fetchPackageDetailsData();
                  }}
                />

                <br />
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPageTitle(
                    true,
                    'MANIFEST',
                  )}
                  value={
                    packageData?.last_manifest_id || common.emptyValue
                  }
                />
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={
                    configurationUtils.getPageTitle(
                      true,
                      'CONTAINER',
                    ) + ' ID'
                  }
                  value={
                    packageData?.container &&
                    packageData?.container.id ? (
                      <Link
                        to={`${AuthRoutes.CONTAINER}/${packageData?.container.id}`}
                      >
                        {humanReadableNull(packageData?.container.id)}
                      </Link>
                    ) : (
                      common.emptyValue
                    )
                  }
                />
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={'Origin Station'}
                  value={
                    packageData?.origin_station_name ||
                    common.emptyValue
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                data-testid='package:details:time'
                className={classes.paper}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <div
                  className={classes.singleStat}
                  style={{ textAlign: 'center', flex: '1 1 0' }}
                >
                  <Typography
                    data-testid='package:details:Target Load Time:label'
                    variant='body1'
                    className={classes.singleStatLabel}
                  >
                    Target Load Time
                  </Typography>
                  <Typography
                    data-testid='package:details:Target Load Time:value'
                    variant='h6'
                    className={classes.statsValue}
                  >
                    {packageData?.next_steps?.outbound_load?.load_time
                      ? new Date(
                          packageData?.next_steps?.outbound_load?.load_time,
                        ).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : common.emptyValue}
                  </Typography>
                </div>
                <div
                  className={classes.singleStat}
                  style={{ textAlign: 'center', flex: '1 1 0' }}
                >
                  <Typography
                    data-testid='package:details:Time Left:label'
                    variant='body1'
                    className={classes.singleStatLabel}
                  >
                    Time Left
                  </Typography>
                  <Typography
                    data-testid='package:details:Time Left:value'
                    variant='h6'
                    className={classes.statsValue}
                  >
                    {packageData?.next_steps?.outbound_load
                      ?.load_time &&
                    new Date() <
                      new Date(
                        packageData?.next_steps?.outbound_load?.load_time,
                      )
                      ? utils.getLongestDwellTimeLabel(
                          packageData?.next_steps?.outbound_load
                            ?.load_time,
                        )
                      : common.emptyValue}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ padding: 16, height: '100%' }}
                data-testid='package-details-card'
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  {configurationUtils.getPageTitle(true, 'PACKAGE')}{' '}
                  Details
                </Typography>
                <SingleDetail
                  label='Tracking ID'
                  value={humanReadableNull(
                    packageData?.tracking_number_alias ||
                      packageData?.tracking_number,
                  )}
                />
                <SingleDetail
                  label='Owner'
                  value={humanReadableNull(
                    packageData?.owner_full_name,
                  )}
                />
                <SingleDetail
                  label='Weight'
                  value={renderWeightString(packageData?.weight)}
                />
                <SingleDetail
                  label='Volume'
                  value={renderVolumeString(
                    Number(packageData?.volume),
                  )}
                />
                {EphemeralStateService.getMyStationConfiguratation()
                  .GENERAL.BEHAVIORS.ML_INTEGRATION
                  .arrival_prediction && (
                  <SingleDetail
                    label='Prediction'
                    value={
                      <PackagePrediction
                        prediction={packageData?.arrival}
                        arrivalTimestamp={
                          packageData?.predicted_arrival_timestamp
                        }
                        withText
                      />
                    }
                    customValueComponent
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ padding: 16 }}
                data-testid='delivery-information-card'
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  Delivery Information
                </Typography>
                <SingleDetail
                  label='ZIP Code'
                  value={humanReadableNull(packageData?.zipcode)}
                />
                <SingleDetail
                  label='Scheme'
                  value={
                    packageData?.scheme ? (
                      <Link
                        style={{ color: colors.darkGold }}
                        to={`${AuthRoutes.SCHEME}/${packageData?.scheme}`}
                      >
                        {humanReadableNull(packageData?.scheme_name)}
                      </Link>
                    ) : (
                      common.emptyValue
                    )
                  }
                />
                <SingleDetail
                  label={configurationUtils.getPageTitle(
                    true,
                    'STOP',
                  )}
                  value={
                    packageData?.stop
                      ? humanReadableNull(packageData?.stop_name)
                      : common.emptyValue
                  }
                  valueStyle={{ color: colors.darkGold }}
                />
                <SingleDetail
                  label={`${configurationUtils.getPageTitle(
                    true,
                    'ROUTE',
                  )}`}
                  value={
                    packageData?.route ? (
                      <Link
                        to={`${AuthRoutes.ROUTE}/${packageData?.route}`}
                      >
                        {humanReadableNull(packageData?.route_name)}
                      </Link>
                    ) : (
                      common.emptyValue
                    )
                  }
                  valueStyle={{ color: colors.darkGold }}
                />
                <SingleDetail
                  label='Carrier'
                  value={humanReadableNull(
                    packageData?.fmc_full_name,
                  )}
                />
              </Paper>
            </Grid>
          </Grid>

          <Typography
            className={classes.boldNameParam}
            style={{ marginBottom: 12 }}
          >
            Event Checkpoints
          </Typography>
          {packageData?.process_steps_history ? (
            <ProcessSteps
              stepsData={packageData?.process_steps_history}
              dataTestPrefix='package'
            />
          ) : (
            <AlertBanner
              className={classes.banner}
              severity='info'
              alertTitle={
                'There is no information to display at the moment'
              }
            />
          )}

          <br />
          <br />
          <Typography
            className={classes.boldNameParam}
            style={{ marginBottom: 12 }}
          >
            Station Targets
          </Typography>
          {packageData && (
            <SingleRowStats
              link_base={''}
              dataTestId='package-details-station-targets'
              fetch={() => {
                return configurationUtils.isModuleActive('AREA')
                  ? [
                      {
                        label: 'Target 1st Sortation',
                        value:
                          renderPackageTarget1stSortationIdString(
                            packageData?.next_steps?.primary_sort
                              ?.name,
                            packageData?.next_steps?.primary_sort
                              ?.load_point?.name,
                          ),
                      },
                      {
                        label: 'Target 2nd Sortation',
                        value:
                          renderPackageTarget2ndSortationIdString(
                            packageData?.next_steps?.secondary_sort
                              ?.name,
                            packageData?.next_steps?.secondary_sort
                              ?.zone?.name,
                            packageData?.next_steps?.secondary_sort
                              ?.load_point?.name,
                          ),
                      },
                      {
                        label: 'Target Staging Area',
                        value: renderPackageTargetStagingIdString(
                          packageData?.next_steps?.staging_area?.name,
                        ),
                        valueStyle: { color: colors.darkGold },
                      },
                      {
                        label: 'Target Load',
                        value:
                          (packageData?.outbound_load &&
                            packageData?.outbound_load?.name) ||
                          packageData?.next_steps?.outbound_load
                            ?.name ||
                          'N/A',
                        valueStyle: { color: colors.darkGold },
                      },
                    ]
                  : [
                      {
                        label: 'Target Load',
                        value:
                          (packageData?.outbound_load &&
                            packageData?.outbound_load?.name) ||
                          packageData?.next_steps?.outbound_load
                            ?.name ||
                          'N/A',
                        valueStyle: { color: colors.darkGold },
                      },
                    ];
              }}
            />
          )}
          <br />
          <br />
          <EventsPaginatedTable
            dataTestIdPrefix={'package-details-events'}
            fetch={fetchEvents}
            sortableBy={['timestamp']}
          />
        </div>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    padding2em: {
      padding: '2em',
    },
    flexRowEvenSpread: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    half: {
      width: '50%',
      borderRight: '1px solid #fafafa',
    },
    containerDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableHeader: {
      background: '#fafafa',
    },
  })),
)(PackageDetailsComponent);
