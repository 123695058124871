import React, { Ref } from 'react';

const DeleteIcon = React.forwardRef(
  (props: any, ref: Ref<HTMLOrSVGElement>) => {
    return (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
      >
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.35352 0H18.3535C19.4535 0 20.3535 0.9 20.3535 2V14C20.3535 15.1 19.4535 16 18.3535 16H6.35352C5.25352 16 4.35352 15.1 4.35352 14V2C4.35352 0.9 5.25352 0 6.35352 0ZM0.353516 4H2.35352V18H16.3535V20H2.35352C1.25352 20 0.353516 19.1 0.353516 18V4ZM18.3535 14H6.35352V2H18.3535V14ZM16.3535 9H13.3535H11.3535H8.35352V7H11.3535H13.3535H16.3535V9Z'
            fill='#363437'
          />
        </svg>
      </svg>
    );
  },
);
export default DeleteIcon;
