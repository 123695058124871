import colors from '../../utils/colors';
export default {
  title: {
    color: colors.title,
    fontSize: 24,
    fontWeight: 'normal',
    opacity: 1,
    margin: '6px 0',
  },
  header: {
    margin: '40px 0 20px 0',
    paddingBottom: 15,
    borderBottom: '0.5px solid rgb(220, 220, 220)',
  },
  outlinedButton: {
    height: 42,
    borderRadius: 4,
    marginBottom: 10,
    backgroundColor: colors.dark,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    textTransform: 'none',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.dark,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    },
    '&:disabled': {
      color: '#999',
      cursor: 'not-allowed',
    },
  },
  containedButton: {
    boxSizing: 'border-box',
    height: 42,
    border: `2px solid ${colors.darkGold}`,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    color: colors.darkGold,
    backgroundColor: 'transparent',
    textTransform: 'none',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
      border: `2px solid ${colors.darkGold}`,
      color: colors.darkGold,
    },
  },
};
