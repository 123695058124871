import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core//Button';
import { Typography } from '../primitives';
import colors from '../../utils/colors';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import FormControl from '@material-ui/core/FormControl';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  Box,
  useTheme,
  useMediaQuery,
  CardContent,
  Card,
  Paper,
} from '@material-ui/core';

import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
// Services
import StationsService from '../../services/Stations.service';
import EphemeralStateService from '../../services/EphemeralState.service';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import { CloudUploadOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';

// Types
import { AxiosError } from 'axios';
import {
  APIException,
  StationDetailsStationTypeEnum,
  StationStationTypeEnum,
  StopsImportSummary,
} from 'cloudsort-client';
import { CSVLink } from 'react-csv';
interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  type: 'add' | 'edit';
  data?: StopParams;
}
interface StopParams {
  name: string;
  station_type: StationStationTypeEnum;
  address: string;
  state: string;
  zipcode: string;
  city: string;
  external_id: string;
}
enum AddStopMethod {
  NONE = 'none',
  CREATE = 'create stop',
  IMPORT = 'import stops',
}

const emptyStopData = {
  name: '',
  station_type: Object.values(StationStationTypeEnum)[0],
  address: '',
  state: '',
  zipcode: '',
  city: '',
  external_id: '',
};

const AddStopsDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  type,
  data,
}) => {
  //General
  const [currentStep, setCurrentStep] = useState<AddStopMethod>(
    AddStopMethod.NONE,
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{
    message: string;
    data: any;
  }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [stopParams, setStopParams] =
    useState<StopParams>(emptyStopData);
  //Stops upload
  const [formClass, setFormClass] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);

  const [dryRunResults, setDryRunResults] =
    useState<StopsImportSummary>();
  const [selectedFile, setSelectedFile] = useState<any>();

  //Misc
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (type === 'edit' && data) {
      setStopParams({
        ...data,
      });
    }
    if (type === 'add') {
      setStopParams({
        ...emptyStopData,
      });
    }
  }, [data, type]);

  const handleClose = () => {
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    onAfterClose();
    setCurrentStep(AddStopMethod.NONE);
    setStopParams({
      ...emptyStopData,
    });
  };

  const renderModeSelection = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              data-testid='select-create-new-stop'
              className={classes.cardContent}
              onClick={() => {
                setCurrentStep(AddStopMethod.CREATE);
              }}
            >
              <Box className={classes.iconBox}>
                <FlipToBackIcon
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Create New
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              data-testid='select-import-new-stops'
              className={classes.cardContent}
              onClick={() => {
                setCurrentStep(AddStopMethod.IMPORT);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  className={classes.img}
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/icons/csv.svg`}
                  alt={'CSV File'}
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: '30px',
                    height: 'auto',
                  }}
                />
                <CloudUploadOutlined
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Import {stopsLabels.plural}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const stopHandler = async () => {
    setShowProgress(true);
    try {
      if (type === 'add') {
        await StationsService.createStation({
          ...stopParams,
          station_type: StationStationTypeEnum.STOP,
          owner: EphemeralStateService.getMyStationId(),
          organization: EphemeralStateService.getMyOrgId(),
        });
      } else {
        await StationsService.editStation({
          ...stopParams,
          station_type: StationDetailsStationTypeEnum.STOP,
          organization: EphemeralStateService.getMyOrgId(),
        });
      }

      updateParent();
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const renderStopForm = () => {
    return (
      <>
        <FormControl
          style={{
            marginRight: 16,
            marginBottom: isXsScreen ? 16 : 0,
            height: 50,
            width: '100%',
          }}
          className={classes.formControl}
          data-testid={'edit-stop-name-field'}
        >
          <TextField
            value={stopParams.name}
            autoFocus
            label='Name'
            onChange={(e) => {
              stopParams.name = e.target.value;
              setStopParams({ ...stopParams });
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputPadding,
            }}
          />
        </FormControl>
        <div>
          <FormControl
            style={{ marginTop: 16, height: 50, width: '100%' }}
            className={classes.formControl}
            data-testid={'edit-stop-address-field'}
          >
            <TextField
              value={stopParams.address}
              label='Address'
              onChange={(e) => {
                stopParams.address = e.target.value;
                setStopParams({ ...stopParams });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            style={{ marginTop: 16, height: 50, width: '100%' }}
            className={classes.formControl}
            data-testid={'edit-stop-state-field'}
          >
            <TextField
              label='State'
              value={(stopParams.state || '').toUpperCase()}
              onChange={(e) => {
                stopParams.state = e.target.value
                  .replace(/[^a-zA-Z]/, '')
                  .toUpperCase();
                setStopParams({ ...stopParams });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
              inputProps={{
                maxLength: 2,
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            style={{ marginTop: 16, height: 50, width: '100%' }}
            className={classes.formControl}
            data-testid={'edit-stop-zipcode-field'}
          >
            <TextField
              label='ZIP Code'
              value={stopParams.zipcode}
              onChange={(e) => {
                stopParams.zipcode = e.target.value;
                setStopParams({ ...stopParams });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            style={{ marginTop: 16, height: 50, width: '100%' }}
            className={classes.formControl}
            data-testid={'edit-stop-city-field'}
          >
            <TextField
              label='City'
              value={stopParams.city}
              onChange={(e) => {
                stopParams.city = e.target.value;
                setStopParams({ ...stopParams });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            style={{ marginTop: 16, height: 50, width: '100%' }}
            className={classes.formControl}
            data-testid={'edit-stop-external-id-field'}
          >
            <TextField
              value={stopParams.external_id}
              label='External ID (optional)'
              onChange={(e) => {
                stopParams.external_id = e.target.value;
                setStopParams({ ...stopParams });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </div>
      </>
    );
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (errorData.error_context?.import_issues) {
      setError({
        message: `Found ${errorData.error_context.import_issues.length} issues.`,
        data: errorData.error_context.import_issues.reduce(
          (acc: any, curr: any) => {
            let newItem = {
              ...curr,
              ...curr.content,
            };
            delete newItem['content'];
            acc.push(newItem);
            return acc;
          },
          new Array<any>(),
        ),
      });
    } else {
      setError({
        message: await ErrorHandler.getLabel(e),
        data: null,
      });
    }
  };

  const onFileChangeHandler = (event: any) => {
    setSelectedFileLabel(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const onFileDroppedHandler = (event: any) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
        setFormClass(undefined);
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      });
    }
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const uploadStopsFile = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      setIsUploading(true);
      const response = await StationsService.importStops({
        dryRun,
        file: selectedFile,
        options: {
          onUploadProgress,
        },
      });

      if (dryRun) {
        setDryRunResults(response.data);
        setDryRunComplete(true);
      } else {
        updateParent();
        handleClose();
      }
      setShowProgress(false);
      setIsUploading(false);
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const renderFileSelectionForm = () => {
    return (
      <>
        <form
          data-testid={'import-stops:drop-zone'}
          className={
            classes.form + ' ' + (formClass && classes[formClass])
          }
          onDragOver={(e) => {
            e.preventDefault();
            setFormClass('draggingActive');
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setFormClass(undefined);
          }}
          onDrop={(e) => {
            e.preventDefault();
            onFileDroppedHandler(e);
          }}
        >
          <input
            accept='.csv'
            className={classes.input}
            style={{ display: 'none' }}
            id='button-file'
            type='file'
            onChange={onFileChangeHandler}
          />
          <p>{selectedFileLabel}</p>
          <label htmlFor='button-file'>
            <Button
              variant='outlined'
              component='span'
              className={classes.goldButton}
            >
              Browse File
            </Button>
          </label>
        </form>
      </>
    );
  };

  const renderUploadingProgress = () => {
    return (
      <>
        {!dryRunComplete && (
          <p className={classes.grayTitle}>
            Uploading new {stopsLabels.plural} file
          </p>
        )}
        <Paper
          className={classes.fileContainer}
          data-testid={'imported-stops-info'}
        >
          <Grid container>
            <Grid item xs={2} style={{ padding: '10px' }}>
              <img
                className={classes.img}
                src={`${
                  process.env.REACT_APP_BASENAME || ''
                }/icons/csv-file.png`}
                alt={'CSV'}
              />
            </Grid>
            <Grid item xs={10} style={{ padding: '10px' }}>
              <p className={classes.uploadFileInfo}>
                <span className={classes.uploadFilename}>
                  {selectedFileLabel}
                </span>

                <span className={classes.uploadPercentage}>
                  {uploadProgress}%
                </span>
              </p>
              <div className={classes.uploadProgressBar}>
                <div
                  className={classes.uploadProgressBarInner}
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  const renderDryRunResults = () => {
    const importIssuesData = (dryRunResults?.issues || []).reduce(
      (acc, curr) => {
        let newItem = {
          ...curr,
          ...curr.content,
        };
        delete newItem['content'];
        acc.push(newItem);
        return acc;
      },
      new Array<any>(),
    );
    return (
      <Grid container data-testid='dry-run-results'>
        <Grid item container xs={6} style={{ marginBottom: '10px' }}>
          <Grid item container xs={8} direction={'column'}>
            <Typography className={classes.boldNameParam}>
              New {stopsLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.paramValueLight}
              data-testid={'added-stops'}
            >
              {dryRunResults?.new_stops}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} style={{ marginBottom: '10px' }}>
          <Grid item container xs={8} direction={'column'}>
            <Typography className={classes.boldNameParam}>
              Updated {stopsLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.paramValueLight}
              data-testid={'updated-stops'}
            >
              {dryRunResults?.updated_stops}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} style={{ marginBottom: '10px' }}>
          <Grid item container xs={4} direction={'column'}>
            <Typography className={classes.boldNameParam}>
              Used Fields
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.paramValueLight}
              data-testid={'stops-used-fields'}
            >
              {dryRunResults?.used_fields.length
                ? dryRunResults.used_fields.map((field) => (
                    <span
                      className={classes.highlightedField}
                      key={field}
                    >
                      {field}
                    </span>
                  ))
                : 'None'}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} style={{ marginBottom: '10px' }}>
          <Grid item container xs={4} direction={'column'}>
            <Typography className={classes.boldNameParam}>
              Ignored Fields
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.paramValueLight}
              data-testid={'ignored-fields-stops'}
            >
              {dryRunResults?.ignored_fields.length
                ? dryRunResults.ignored_fields.map((field) => (
                    <pre className={classes.highlightedField}>
                      {field}
                    </pre>
                  ))
                : 'None'}
            </Typography>
          </Grid>
        </Grid>

        {dryRunResults?.issues && dryRunResults?.issues.length > 0 && (
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
          >
            <Grid item container xs={4} direction={'column'}>
              <Typography className={classes.boldNameParam}>
                Issues
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.paramValueLight}
                data-testid={'ignored-fields-stops'}
              >
                Found {dryRunResults?.issues.length}{' '}
                {dryRunResults?.issues.length > 1
                  ? 'issues'
                  : 'issue'}
                <br />
                <CSVLink
                  className={classes.link}
                  style={{ fontSize: '15px', color: colors.darkGold }}
                  data={importIssuesData}
                  filename={`${selectedFileLabel}-import-issues-log.csv`}
                >
                  Download summary
                </CSVLink>
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box m={0.8} />
      </Grid>
    );
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {type === 'add' && (
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <AddToPhotosOutlinedIcon
                style={{
                  margin: '10px auto',
                  height: 'auto',
                  width: 40,
                  color: colors.darkGold,
                }}
              />

              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 18,
                  marginBottom: 20,
                }}
              >
                {type === 'add' &&
                currentStep === AddStopMethod.CREATE
                  ? `Add New ${stopsLabels.singular}`
                  : `Create ${stopsLabels.plural}`}
              </span>
            </div>
          </DialogTitle>
        )}
        {type === 'edit' && (
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <EditIcon
                style={{
                  margin: '10px auto',
                  height: 'auto',
                  width: 40,
                  color: colors.darkGold,
                }}
              />

              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 18,
                  marginBottom: 20,
                }}
              >
                {`Edit ${stopsLabels.singular}`}
              </span>
            </div>
          </DialogTitle>
        )}
        {type === 'edit' && (
          <>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error.message}
                />
              )}
              {renderStopForm()}
            </DialogContent>
            <DialogActions>
              <MuiButton
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CANCEL
              </MuiButton>
              <MuiButton
                data-testid={'add-stop-save-button'}
                disabled={
                  !stopParams.name ||
                  !stopParams.address ||
                  !stopParams.city ||
                  !stopParams.state ||
                  !stopParams.zipcode
                }
                onClick={(e) => {
                  stopHandler();
                }}
                className={classes.button}
              >
                SAVE
              </MuiButton>
            </DialogActions>
          </>
        )}
        {type === 'add' && currentStep === AddStopMethod.NONE && (
          <>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {renderModeSelection()}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CLOSE
              </Button>
            </DialogActions>
          </>
        )}
        {type === 'add' && currentStep === AddStopMethod.CREATE && (
          <>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error.message}
                />
              )}
              {renderStopForm()}
            </DialogContent>
            <DialogActions>
              <MuiButton
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CANCEL
              </MuiButton>
              <MuiButton
                data-testid={'add-stop-save-button'}
                disabled={
                  !stopParams.name ||
                  !stopParams.address ||
                  !stopParams.city ||
                  !stopParams.state ||
                  !stopParams.zipcode
                }
                onClick={(e) => {
                  stopHandler();
                }}
                className={classes.button}
              >
                SAVE
              </MuiButton>
            </DialogActions>
          </>
        )}
        {type === 'add' &&
          currentStep === AddStopMethod.IMPORT &&
          !isUploading &&
          !dryRunComplete && (
            <>
              <DialogContent
                className={classes.dialogContent}
                style={{ padding: '0 40px' }}
              >
                {renderFileSelectionForm()}
              </DialogContent>
              <DialogActions>
                <MuiButton
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </MuiButton>
                <MuiButton
                  data-testid='start-import'
                  onClick={(e) => {
                    uploadStopsFile();
                  }}
                  className={classes.button}
                  disabled={selectedFile === undefined}
                >
                  IMPORT
                </MuiButton>
              </DialogActions>
            </>
          )}
        {type === 'add' &&
          currentStep === AddStopMethod.IMPORT &&
          isUploading && (
            <>
              <DialogContent
                className={classes.dialogContent}
                style={{ padding: '0 40px' }}
              >
                {error && (
                  <AlertBanner
                    className={classes.banner}
                    severity='error'
                    alertTitle={'Error'}
                    alertMsg={error.message}
                  />
                )}
                {error?.data && (
                  <Typography>
                    <CSVLink
                      className={classes.link}
                      style={{
                        fontSize: '15px',
                        color: colors.darkGold,
                      }}
                      data={error?.data || ''}
                      filename={`${selectedFileLabel}-import-errors-log.csv`}
                    >
                      Download summary
                    </CSVLink>
                  </Typography>
                )}
                {renderUploadingProgress()}
              </DialogContent>
              <DialogActions>
                <MuiButton
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </MuiButton>
                <MuiButton
                  onClick={(e) => {
                    uploadStopsFile();
                  }}
                  className={classes.button}
                  disabled={!!error}
                >
                  IMPORT
                </MuiButton>
              </DialogActions>
            </>
          )}
        {type === 'add' &&
          currentStep === AddStopMethod.IMPORT &&
          dryRunComplete && (
            <>
              <DialogContent
                className={classes.dialogContent}
                style={{ padding: '0 40px' }}
              >
                {!isUploading && (
                  <>
                    {renderUploadingProgress()}
                    <Box mb={2} />
                  </>
                )}
                {renderDryRunResults()}
              </DialogContent>
              <DialogActions>
                <MuiButton
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </MuiButton>
                <MuiButton
                  data-testid='complete-import'
                  onClick={(e) => {
                    uploadStopsFile(false);
                  }}
                  className={classes.button}
                >
                  IMPORT
                </MuiButton>
              </DialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
    },
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.gold,
      },
    },
    form: {
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    draggingActive: {
      borderColor: colors.lightGray,
    },
    dropRejected: {
      borderColor: 'red',
    },
    grayTitle: {
      color: colors.gray,
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: colors.gray,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: colors.lightGold,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: colors.darkGold,
      height: '5px',
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
      backgroundColor: colors.secondaryWhite,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    highlightedField: {
      display: 'inline-block',
      backgroundColor: colors.lightGold,
      padding: 5,
      borderRadius: 3,
      margin: '0 5px 5px 0',
      color: colors.black,
    },
  })),
)(AddStopsDialog);
