import * as React from 'react';
import styles from './progressBarItem.styles';
import {
  Grid,
  LinearProgress,
  Paper,
  withStyles,
} from '@material-ui/core';
import Typography from '../typography/Typography';

// Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface Props {
  classes: { [key: string]: string };
  label?: string;
  labelElement?: JSX.Element;
  value: number;
}

const ProgressBarItem: React.FC<Props> = ({
  classes,
  label,
  labelElement,
  value,
}) => {
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        alignContent='center'
        justify='center'
        alignItems='center'
      >
        <Grid item>
          <FiberManualRecordIcon className={classes.icon} />
        </Grid>
        <Grid item style={{ marginRight: 16 }}>
          {label && (
            <Typography
              style={{
                fontSize: 14,
              }}
            >
              {label}
            </Typography>
          )}
          {labelElement && { ...labelElement }}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <LinearProgress
            variant='determinate'
            value={value}
            classes={{
              root: classes.progressRoot,
              colorPrimary: classes.barColorGrey,
              bar1Determinate: classes.barColorGold,
            }}
          />
        </Grid>
        <Grid item style={{ marginRight: 16, width: 48 }}>
          {value}%
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ProgressBarItem);
