import { createStyles, Theme } from '@material-ui/core/styles';

const errorColor = 'rgb(97, 26, 21)';

export default createStyles((theme: Theme) => ({
  root: {
    '& p': {
      margin: 0,
    },
    '& svg': {
      color: errorColor,
      alignSelf: 'center',
    },
  },
  alert: {
    width: '100%',
    '& p': {
      width: '100%',
      alignSelf: 'center',
    },
  },
  message: {
    flexDirection: 'row',
    width: '100%',
    '& button': {
      width: 100,
      padding: 0,
      color: errorColor,
      border: `1px solid ${errorColor}`,
    },
    '& a': {
      color: 'inherit',
      fontWeight: 'bold',
    },
    '& ul': {
      margin: '5px 0 0 0',
      paddingLeft: '0',
    },
  },
  flexColumn: {
    flexDirection: 'column',
  },
}));
