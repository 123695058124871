import React, { Fragment, useState, useEffect } from 'react';
import browserHistory from '../../utils/browserHistory';
import { withStyles, useTheme } from '@material-ui/core/styles';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, Typography } from '../primitives';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import styles from './paginatedTable.styles';
import ErrorHandler from '../../utils/ErrorHandler';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';
import DOMPurify from 'dompurify';
import { NonAuthRoutes } from '../../interfaces/routes';

// Icons
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SearchIcon from '@material-ui/icons/Search';

// Types
import { Column } from '../../interfaces/components';

// Utils
import { isCypressRunning } from '../../utils/cypress';
import { AxiosError } from 'axios';

const ROWS_PER_PAGE = 10;

export interface filterOption {
  label: string;
  value: string;
  selected: boolean;
}

export interface filterObj {
  name: string;
  options: filterOption[];
  labelWidth?: number; //this can be removed as it's solved with CSS
}

export interface Action {
  tableLabel?: string;
  cellWidth?: number | string;
  columnLabel?: JSX.Element;
  callback?: (id: number) => any;
  qualifier?: string;
}

interface Props {
  classes: { [key: string]: string };
  columns: Column[];
  dataTestIdPrefix?: string;
  fetch: (
    pageIndex: number,
    rowsPerPage: number,
    filtersValue?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => any;
  rowsLoadDetailPages?: boolean;
  filterIsEnabled?: boolean;
  filterByString?: boolean;
  filterByStringPlaceholder?: string;
  searchQueryStringPrefix?: string;
  fetchfilters?: () => any;
  detailsPageBasePath?: string;
  sortableBy?: string[];
  enablePrefetch?: boolean;
  defaultSort?: string;
  title: string;
  disableUpdateQueryStringUrl?: boolean;
  isExpandedPanel?: boolean;
  expandedContentRenderer?: (data: any) => any;
  loadExpandedContentData?: (
    params: any,
    onAfterLoadExpandedContentData: () => void,
  ) => any;
  actions?: Action[];
  tableKey?: string;
  showPagination?: boolean;
  disableNoDataMessage?: boolean;
}

export const getSelectedFilterOption = (
  filterOptions: filterObj[],
  filterName: string,
) => {
  //Set fStatus filter
  return filterOptions
    .filter((obj) => {
      return obj.name === filterName;
    })[0]
    .options.filter((opt) => {
      return opt.selected === true;
    })[0].value;
};

export const selectFilterByQueryString = (
  qs: string | string[],
  options: filterOption[],
) => {
  if (qs) {
    //check if options value provided in QS exists in the array
    const qsOption = options.find((option) => option.label === qs);
    if (qsOption) {
      //get selected option (all)
      const selectedOption = options.find(
        (option) => option.selected === true,
      );

      //deselect selected option
      if (selectedOption) {
        selectedOption.selected = false;
      }

      //select the option from the QS
      qsOption.selected = true;
    }
  }
};

const PaginatedTable: React.FC<Props> = ({
  classes,
  columns,
  dataTestIdPrefix,
  fetch,
  rowsLoadDetailPages = false,
  filterIsEnabled = false,
  fetchfilters = undefined,
  detailsPageBasePath = null,
  filterByString,
  filterByStringPlaceholder,
  searchQueryStringPrefix = '',
  sortableBy = [],
  enablePrefetch = false,
  defaultSort,
  title,
  isExpandedPanel,
  disableUpdateQueryStringUrl = false,
  expandedContentRenderer = () => {},
  loadExpandedContentData,
  actions,
  tableKey,
  showPagination = true,
  disableNoDataMessage = false,
}) => {
  const pageToFetchByUrl = queryString.parse(
    browserHistory.location.search,
  )['page'];

  const pageToFetchByUrlVal =
    !!pageToFetchByUrl && typeof pageToFetchByUrl === 'string'
      ? parseInt(pageToFetchByUrl) - 1
      : null;

  const rowsPerPageByUrl = queryString.parse(
    browserHistory.location.search,
  )['rowsPerPage'];

  const rowsPerPageByUrlVal =
    !!rowsPerPageByUrl && typeof rowsPerPageByUrl === 'string'
      ? parseInt(rowsPerPageByUrl)
      : null;
  const searchQuery = queryString.parse(
    browserHistory.location.search,
  )[`${searchQueryStringPrefix}search`] as string;
  const [page, setPage] = useState<number>(pageToFetchByUrlVal || 0);
  const [isAllInfoFetched, setIsAllInfoFetched] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(true);
  const [linkClickTime, setLinkClickTime] = useState<Date>();
  const [showNoDataMessage, setShowNoDataMessage] =
    useState<boolean>(false);
  const [redirectClickTime, setRedirectClickTime] = useState<Date>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [filterByStringStr, setFilterByStringStr] = useState<string>(
    searchQuery || '',
  );
  const [filterByStringVisible, setFilterByStringVisible] =
    useState<boolean>(false);

  const [data, setData] = useState<any[]>([]);
  const [initialfiltersValue, setInitialFiltersValue] = useState<
    filterObj[] | undefined
  >();
  const [filtersValue, setFiltersValue] = useState<
    filterObj[] | undefined
  >();
  // These are used only for use effect trigger
  const [applyCount, setApplyCount] = useState<number>(0);
  // These are used only for use effect trigger
  const [clearCount, setClearCount] = useState<number>(0);
  const [rowsPerPageUpdateCount, setRowsPerPageUpdateCount] =
    useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [sorted, setSorted] = useState<string | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageByUrlVal || ROWS_PER_PAGE,
  );

  const sortBy = sorted || defaultSort;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileEps, setMobileEps] = useState<Map<number, boolean>>(
    new Map<number, boolean>(),
  );
  const mobileColumns = columns.filter(
    (column) => column.showOnMobile,
  ).length
    ? columns.filter((column) => column.showOnMobile)
    : columns.filter((column, index) =>
        actions?.length
          ? [0, 1].includes(index) // if table has actions -- show only first column and the + sign
          : [0, 1, 2, columns.length - 1].includes(index),
      );
  const isDesktopExpansionPanel = isExpandedPanel && !isMobileView;
  const urlParams: any = useParams();

  const hasActiveFilters = (filters: filterObj[]) => {
    return filters.some((filter) => {
      return filter.options.some(
        (option) => option.value !== 'ALL' && option.selected,
      );
    });
  };

  const checkForFiltersClear = () => {
    // Checks for router updates without in component interaction
    if (
      !browserHistory.location.search &&
      hasActiveFilters(filtersValue || [])
    ) {
      setTimeout(() => {
        filtersClear();
      }, 0);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
    getData(newPage);

    updateUrlQuery(filtersValue, sorted, newPage);
  };

  const checkIfAllInfoIsFetched = (isLastPage: boolean) => {
    if (!isAllInfoFetched && isLastPage) {
      setIsAllInfoFetched(isLastPage);
    }
  };

  const getData = async (pageToFetch?: number) => {
    let currentPage = 0;
    if (
      !data.length &&
      (pageToFetch === undefined || pageToFetch === null) &&
      !!pageToFetchByUrlVal
    ) {
      handleChangePage(undefined, pageToFetchByUrlVal);
      return;
    } else {
      // match indexes between Mui and BE
      currentPage = pageToFetch === undefined ? 1 : pageToFetch + 1;
    }

    const fetchPageIndex = currentPage;
    const preFetchPageIndex = currentPage + 1;

    const dataObj: any[] = [];
    const mainFetch: any = await fetchData(fetchPageIndex);
    checkIfAllInfoIsFetched(mainFetch.isLastPage);

    dataObj.push(...mainFetch.data);
    if (mainFetch && !mainFetch.isLastPage && !isAllInfoFetched) {
      if (enablePrefetch) {
        const preFetch: any = await fetchData(preFetchPageIndex);
        checkIfAllInfoIsFetched(preFetch.isLastPage);
        dataObj.push(...preFetch.data);
      }
    }

    setData([...dataObj]);
  };

  const fetchData = async (pageIndex: number) => {
    try {
      setShowProgress(true);
      //Avoid sending empty string as search query to the API
      const filterByStringQuery =
        (filterByStringStr || '').trim().length > 0
          ? filterByStringStr
          : undefined;
      let res;
      if (filterIsEnabled && !initialfiltersValue) {
        //fetch filter values then forward filter values to the fetch method
        const fo: filterObj[] = await fetchfilters!();
        res = await fetch(
          pageIndex,
          rowsPerPage,
          fo,
          filterByStringQuery,
          sortBy,
        );

        if (hasActiveFilters(fo)) {
          updateUrlQuery(fo, sorted, page);
        }

        //update the state
        setInitialFiltersValue(fo);
        setFiltersValue(fo);
      } else {
        //forward filter values from the state to the fetch method
        res = await fetch(
          pageIndex,
          rowsPerPage,
          filtersValue,
          filterByStringQuery,
          sortBy,
        );
      }

      const resData = res.data;
      if (totalCount !== res.data.count) {
        setTotalCount(res.data.count);
      }

      if (
        (resData && resData.results.length < rowsPerPage) ||
        !resData.count
      ) {
        if (!resData.count && !data.length) {
          setShowNoDataMessage(true);
        } else {
          setShowNoDataMessage(false);
        }
      } else {
        setShowNoDataMessage(false);
      }

      return {
        data: resData ? resData.results : [],
        isLastPage: !resData.next,
      };
    } catch (e) {
      setErrorMsg(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
    return {
      data: [],
      isLastPage: false,
    };
  };

  // Update filter values in the state
  const filterChanged = (filterName: string, filterValue: string) => {
    //make deep copy of array of objects -- kinda hacky
    const filters = JSON.parse(
      JSON.stringify(filtersValue),
    ) as filterObj[];

    const thisFilter = filters.find((obj) => {
      return obj.name === filterName;
    })?.options;

    const oldOption = thisFilter?.find((opt) => {
      return opt.selected === true;
    });

    const newOption = thisFilter?.find((opt) => {
      return opt.value === filterValue;
    });
    if (oldOption && newOption) {
      oldOption.selected = false;
      newOption.selected = true;
      setFiltersValue(filters);

      // filtersApply
      setData([]);
      setApplyCount(applyCount + 1);
      updateUrlQuery(filters, sorted, 0);
    }
  };

  const updateUrlQuery = (
    filtersData: filterObj[] | undefined,
    sortedStr: string | undefined,
    newPage: number,
    rowsPerPage?: number,
  ) => {
    if (disableUpdateQueryStringUrl) {
      return;
    }
    const qss = queryString.parse(window.location.search);

    qss['page'] = (newPage + 1).toString();
    if (rowsPerPage) qss['rowsPerPage'] = rowsPerPage.toString();

    filtersData?.forEach((item, index) => {
      const option = item.options.find(
        (option) => option.selected,
      )?.label;
      if (option) {
        if (option !== 'All')
          qss[item.name.replace(/ /g, '')] = option;
        else delete qss[item.name.replace(/ /g, '')];
      }
    });

    if (sortedStr || sortBy) {
      qss['sortBy'] = sortedStr || sortBy;
    }

    if (filterByString) {
      qss[`${searchQueryStringPrefix}search`] = filterByStringStr;
    }
    if (detailsPageBasePath) {
      newPage === 0 // if executed on first page, replace browser history so back button will go to the actual previous page
        ? browserHistory.replace(
            detailsPageBasePath + '?' + queryString.stringify(qss),
          )
        : browserHistory.push(
            detailsPageBasePath + '?' + queryString.stringify(qss),
          );
    }
  };

  useEffect(() => {
    //update data only after state has been updated
    if (applyCount || clearCount !== 0) {
      setPage(0);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyCount, clearCount]);

  const filtersClear = () => {
    if (filtersValue) {
      const filters = JSON.parse(
        JSON.stringify(filtersValue),
      ) as filterObj[];
      filters.forEach((filter) => {
        filter.options.find((o) => o.selected === true)!.selected =
          false;
        filter.options.find((o) => o.value === 'ALL')!.selected =
          true;
      });
      setFiltersValue(filters); // cancel all filters -- set all option values to 'ALL'
    }
    setFilterByStringStr('');
    setFilterByStringVisible(false);
    setClearCount(clearCount + 1);
  };

  const getRowsPerPageOptions = () => {
    switch (true) {
      case totalCount >= 50:
        return [10, 20, 50];
      case totalCount > 10:
        return [10, 20];
      default:
        return [];
    }
  };

  const onExpansionPanelClick = (event: any, row: any) => {
    if (!['path', 'BUTTON'].includes(event.target.nodeName)) {
      if (loadExpandedContentData) {
        setShowProgress(true);
        loadExpandedContentData({ id: row.id }, () => {
          setShowProgress(false);
        });
      }
    }
  };

  useEffect(() => {
    const mobileEpsVal = new Map<number, boolean>();
    data.forEach((row) => {
      mobileEpsVal.set(row.id, false);
    });
    setMobileEps(mobileEpsVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  useEffect(() => {
    if (clearCount !== 0) {
      setPage(0);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  useEffect(() => {
    //Page is being loaded for the first time. Use the page number from query string.
    if (rowsPerPageUpdateCount === 0 && pageToFetchByUrlVal) {
      setPage(pageToFetchByUrlVal);
      setData([]);
      getData(pageToFetchByUrlVal);
    } else if (!tableKey || rowsPerPageUpdateCount > 0) {
      setPage(0);
      setData([]);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  useEffect(() => {
    if (tableKey) {
      setPage(0);
      setData([]);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableKey]);

  const sortByColumn = (column: string) => {
    const order = sortBy === column ? '-' : '';
    const sortedStr = `${order}${column}`;
    setData([]);
    setSorted(sortedStr);
    updateUrlQuery(filtersValue, sortedStr, 0);
  };

  useEffect(() => {
    if (sorted) {
      setPage(0);
      getData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorted]);

  const renderTableCellValue = (column: Column, row: any) => {
    const value = row[column.id];
    return (
      <Fragment>
        {column.link && value && value !== common.emptyValue ? (
          <Link
            underline={'always'}
            className={classes.link}
            onMouseDown={() => {
              setLinkClickTime(new Date());
            }}
            onMouseUp={(event: any) => {
              event.stopPropagation();
              event.preventDefault();
              const timeElapsed =
                new Date().getTime() -
                (linkClickTime?.getTime() || 0);
              if (
                column.link?.path &&
                column.link?.value &&
                linkClickTime &&
                timeElapsed < 200
              ) {
                browserHistory.push(
                  `${column.link.path}/${row[column.link.value]}`,
                );
              }
            }}
          >
            {value}
          </Link>
        ) : column.useCustomComponent ? (
          { ...value }
        ) : value !== null && value !== undefined ? (
          <span
            dangerouslySetInnerHTML={{
              //DOMPurify doesn't work well with non html/svg/mathml types.
              __html: DOMPurify.sanitize(`<span>${value}</span>`),
            }}
          ></span>
        ) : (
          common.emptyValue
        )}
      </Fragment>
    );
  };

  const renderTableRow = (row: any, index: number) => {
    const customStyle = isDesktopExpansionPanel
      ? {
          paddingLeft: 16,
          paddingRight: 0,
        }
      : {};
    return (
      <Fragment key={`row-${row.id}-${index}`}>
        <TableRow
          className={classes.tableRow}
          hover
          role='checkbox'
          tabIndex={-1}
          data-testid={`${dataTestIdPrefix}-row-${row.id}`}
          data-id={row.id}
          style={{
            cursor: rowsLoadDetailPages ? 'pointer' : '',
            display: row.id === ' ' ? 'none' : 'table-row',
          }}
          onMouseDown={() => {
            setRedirectClickTime(new Date());
          }}
          onMouseUp={(event: any) => {
            if (
              !['svg', 'path', 'BUTTON'].includes(
                event.target.nodeName,
              )
            ) {
              const timeElapsed = isCypressRunning
                ? 100
                : new Date().getTime() -
                  (redirectClickTime?.getTime() || 0);

              if (
                timeElapsed < 200 &&
                rowsLoadDetailPages &&
                !(event.target instanceof HTMLAnchorElement) &&
                !(
                  event.target instanceof HTMLButtonElement ||
                  event.nativeEvent?.target?.parentElement instanceof
                    HTMLButtonElement
                )
              ) {
                // Force redirect when basePath is the same as window pathname.
                // List to details page
                if (
                  window.location.pathname.includes(
                    `${detailsPageBasePath!}/`,
                  )
                ) {
                  browserHistory.push(NonAuthRoutes.REFRESH_ROUTE);
                  setTimeout(() => {
                    browserHistory.replace(
                      `${detailsPageBasePath}/${row.id}`,
                    );
                  }, 0);
                } else
                  browserHistory.push(
                    `${detailsPageBasePath}/${row.id}`,
                  );
              }
            }
          }}
        >
          {isMobileView && (
            <TableCell padding='none'>
              <Box p={1}>
                <IconButton
                  size='small'
                  disabled={mobileColumns.length === columns.length}
                  style={
                    mobileColumns.length === columns.length
                      ? { opacity: 0 }
                      : {}
                  }
                  onClick={(event) => {
                    setMobileEps(
                      new Map(
                        mobileEps.set(row.id, !mobileEps.get(row.id)),
                      ),
                    );
                    onExpansionPanelClick(event, row);
                  }}
                >
                  {mobileEps.get(row.id) ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}
                </IconButton>
              </Box>
            </TableCell>
          )}
          {(isMobileView ? mobileColumns : columns).map(
            (column, index) => {
              if (isMobileView && !mobileEps.has(row.id)) {
                setMobileEps(new Map(mobileEps.set(row.id, false)));
              }
              if (!column.hide) {
                return (
                  <TableCell
                    key={`${column.id}-${index}`}
                    align={column.align}
                    style={{
                      width: !isMobileView ? column.width : 'auto',
                      ...customStyle,
                    }}
                    onClick={(event) => {
                      setMobileEps(
                        new Map(
                          mobileEps.set(
                            row.id,
                            !mobileEps.get(row.id),
                          ),
                        ),
                      );
                      onExpansionPanelClick(event, row);
                    }}
                  >
                    {renderTableCellValue(column, row)}
                  </TableCell>
                );
              } else return null;
            },
          )}
          {actions &&
            actions.map((action, index) => (
              <TableCell
                key={'action-' + index}
                className={classes.actionColumn}
                style={{
                  width: action.cellWidth || 'auto',
                }}
              >
                <Button
                  data-testid={`paginated-table:action-${index}:button`}
                  className={classes.actionColumnButton}
                  onClick={() => {
                    action.callback!(row['id']);
                  }}
                  disabled={
                    !row[action.qualifier!] ||
                    row[action.qualifier!] === 0
                  }
                >
                  {action.columnLabel}
                </Button>
              </TableCell>
            ))}
        </TableRow>
        {isMobileView && mobileEps.get(row.id) && (
          <>
            <TableRow key={`mobile-extra-info-row-${row.id}`}>
              <TableCell
                colSpan={columns.length + 1}
                style={{ padding: 0 }}
              >
                {columns
                  .filter(
                    (column) =>
                      !mobileColumns.includes(column) && !column.hide,
                  )
                  .map((column, index) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        key={`${column}-${index}`}
                        style={{ backgroundColor: colors.lightGold }}
                      >
                        <Grid item xs={6}>
                          <Box
                            pl={10}
                            pt={2}
                            pb={2}
                            className={classes.mobileColumnLabel}
                          >
                            {column.label}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            pt={2}
                            pb={2}
                            className={classes.mobileColumnValue}
                          >
                            {renderTableCellValue(column, row)}
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
              </TableCell>
            </TableRow>
            {isExpandedPanel && (
              <TableRow
                key={`mobile-extra-info-expanded-panel-row-${row.id}`}
                style={{ backgroundColor: colors.lightGold }}
              >
                <TableCell
                  colSpan={columns.length + 1}
                  style={{ padding: 0 }}
                >
                  {expandedContentRenderer(row)}
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </Fragment>
    );
  };

  const renderEp = (row: any, index: number) => {
    return (
      <TableRow key={`ep-row-${index}`}>
        <TableCell
          colSpan={
            columns.length + 1 + (actions ? actions.length : 0)
          }
          style={{ padding: 0 }}
        >
          <ExpansionPanel
            square
            className={classes.expansionPanel}
            defaultExpanded={urlParams.defaultOpenIds
              ?.split('-')
              .map((id: string) => parseInt(id))
              .includes(row.id)}
            onClick={(e) => {
              onExpansionPanelClick(e, row);
            }}
            TransitionProps={{
              unmountOnExit: true,
              mountOnEnter: true,
            }}
          >
            <ExpansionPanelSummary
              onClick={(e) => {
                onExpansionPanelClick(e, row);
              }}
              expandIcon={
                <ExpandMoreIcon style={{ pointerEvents: 'none' }} />
              }
            >
              <Table>
                <TableBody>{renderTableRow(row, index)}</TableBody>
              </Table>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {expandedContentRenderer(row)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </TableCell>
      </TableRow>
    );
  };

  // Ensures that always clean internal filters value state
  checkForFiltersClear();

  const showRetryResetPaginationButton = (errorMessage: string) => {
    if (page > 0 && !errorMessage?.includes('Network Error')) {
      return {
        buttonLabel: 'RETRY',
        buttonAction: () => {
          setErrorMsg('');
          filtersClear();
        },
      };
    }
    return {};
  };

  return (
    <Fragment>
      {showProgress && <ProgressIndicator />}
      {errorMsg && (
        <AlertBanner
          data-testid={`${dataTestIdPrefix}-error`}
          className={classes.banner}
          severity='error'
          alertMsg={errorMsg}
          alertTitle={'Error'}
          {...showRetryResetPaginationButton(errorMsg)}
        />
      )}
      {!!filtersValue && (
        <Grid
          container
          data-testid='filter-value'
          spacing={2}
          className={classes.flexTidyArrangement}
        >
          {filtersValue.map((filter) => {
            return (
              <Grid item key={filter.name}>
                <FormControl className={classes.filter}>
                  <InputLabel>{filter.name}</InputLabel>
                  <Select
                    key={filter.name}
                    id={
                      'filter-select-' +
                      filter.name.replace(/ /g, '-')
                    }
                    data-testid={`filter:${filter.name}`}
                    name={filter.name}
                    value={getSelectedFilterOption(
                      filtersValue,
                      filter.name,
                    )}
                    onChange={(e: React.BaseSyntheticEvent) => {
                      filterChanged(filter.name, e.target.value);
                    }}
                    variant='outlined'
                    className={classes.muiSelect}
                  >
                    {filter.options.map((opt) => (
                      <MenuItem
                        key={filter.name + '_' + opt.value}
                        value={opt.value}
                        data-testid={'filter-option-' + opt.label}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      )}
      <div style={{ boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }}>
        {(title || filterByString) && (
          <Toolbar className={classes.toolBar}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography style={{ marginTop: '10px' }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {' '}
                {(!!filtersValue || filterByString) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {filterByString && (
                      <>
                        {(filterByStringStr ||
                          filterByStringVisible) && (
                          <TextField
                            autoFocus
                            data-testid={
                              'paginated-table:search-input'
                            }
                            value={filterByStringStr}
                            placeholder={filterByStringPlaceholder}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                // filtersApply
                                setData([]);
                                setApplyCount(applyCount + 1);
                              }
                            }}
                            onChange={(e) => {
                              setFilterByStringStr(e.target.value);
                            }}
                            id={'filter-select-by-string'}
                          />
                        )}
                        <Button
                          data-testid={'paginated-table:search-icon'}
                          className={classes.removeFilters}
                          onClick={(e) => {
                            if (!filterByStringVisible) {
                              setFilterByStringVisible(true);
                            } else {
                              // filtersApply
                              setData([]);
                              setApplyCount(applyCount + 1);
                            }
                          }}
                        >
                          <SearchIcon />
                        </Button>
                      </>
                    )}
                    {(filtersValue || filterByStringVisible) && (
                      <Button
                        data-testid={
                          'paginated-table:remove-filters-btn'
                        }
                        className={classes.removeFilters}
                        onClick={(e) => {
                          e.preventDefault();
                          filtersClear();
                        }}
                      >
                        remove filters
                      </Button>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        )}
        {!!data.length && (
          <Fragment>
            <TableContainer>
              <Table
                stickyHeader
                data-testid={`${dataTestIdPrefix}-paginated-table`}
                style={{ overflow: 'hidden' }}
              >
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {isMobileView && (
                      <TableCell style={{ width: 16 }} />
                    )}
                    {isDesktopExpansionPanel && (
                      <TableCell style={{ width: 48 }} />
                    )}
                    {(isMobileView ? mobileColumns : columns).map(
                      (column, index) =>
                        !column.hide && (
                          <TableCell
                            key={`column-${column.id}`}
                            align={column.align}
                            style={{
                              width: column.width,
                              paddingLeft: 16,
                            }}
                          >
                            {sortableBy?.includes(
                              column.id as string,
                            ) ? (
                              // eslint-disable-next-line
                              <a
                                className={classes.sortableLink}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sortByColumn(column.id as string);
                                }}
                              >
                                {column.label}
                                {sortBy &&
                                  sortBy === `-${column.id}` && (
                                    <ArrowDownwardIcon
                                      className={classes.sortIcon}
                                    />
                                  )}
                                {sortBy && sortBy === column.id && (
                                  <ArrowUpwardIcon
                                    className={classes.sortIcon}
                                  />
                                )}
                              </a>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ),
                    )}
                    {actions &&
                      actions.map((action, index) => (
                        <TableCell
                          key={index}
                          style={{
                            width: action.cellWidth || 'auto',
                          }}
                        >
                          {action.tableLabel}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => {
                    if (isDesktopExpansionPanel) {
                      return renderEp(row, index);
                    }
                    return renderTableRow(row, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {showPagination && (
              <TablePagination
                data-testid={`${dataTestIdPrefix}-pagination`}
                className={classes.paginationCaption}
                rowsPerPageOptions={getRowsPerPageOptions()}
                component='div'
                count={totalCount || 0}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const rpp = +event.target.value;
                  setRowsPerPage(rpp);
                  setRowsPerPageUpdateCount(
                    rowsPerPageUpdateCount + 1,
                  );
                  updateUrlQuery(filtersValue, sorted, page, rpp);
                }}
                page={page}
                onChangePage={handleChangePage}
              />
            )}
          </Fragment>
        )}
        {showNoDataMessage && !disableNoDataMessage && (
          <AlertBanner
            data-testid={`${dataTestIdPrefix}-info-banner`}
            severity='info'
            alertTitle={
              filterIsEnabled
                ? 'No results match the current filters'
                : 'There is no information to display at the moment'
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(PaginatedTable);
