import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';

import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (data: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  days: 0,
};

const GhostResources: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Ghost Resources';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        data.toString(),
      ]);

      setSearchResult(results);

      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onlyNumbers = (value: number) =>
    parseInt(value.toString().replace('/[^]/g', ''));

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='Ghost Resources-dialog-edit-days'
            autoFocus
            label={'Days'}
            type={'number'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.days}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                days: onlyNumbers(parseInt(value)),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='Ghost Resources-dialog-save-btn'
            onClick={() => {
              data = dialogData.days;
              setPartialData(data);
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Days
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid
                container
                item
                sm={12}
                className={classes.row}
                justify='space-between'
              >
                <Grid
                  item
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight={data.toString()}
                  />
                </Grid>
                <Grid item sm={1} xs={2}>
                  <IconButton
                    data-testid='Ghost Resources-edit-days'
                    size='small'
                    className={classes.editIcon}
                    disabled={interaction === INTERACTION.READ}
                    onClick={() => {
                      setDialogData({
                        days: data,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(GhostResources);
