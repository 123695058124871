import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './stackedStats.styles';
import { Typography } from '../primitives';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Grid,
  CircularProgress,
  CircularProgressProps,
  Switch,
  Tooltip,
} from '@material-ui/core';
import clx from 'classnames';
import { Link } from 'react-router-dom';
import colors from '../../utils/colors';
import { FilterItem } from '../filtersDrawer/FiltersDrawer';
import NoDataAvailableIcon from '../../components/dashboard/dashboardModules/utils/NoDataAvailableIcon';

//Icons
import FilterListIcon from '@material-ui/icons/FilterList';

export enum CardContainerVariants {
  DARK,
  GOLD,
  WHITE,
}
export enum CardItemVariants {
  NO_BG_WHITE,
  NO_BG_BLACK,
  WHITE_PAPER,
  GOLD_PAPER,
}

export enum CardLayoutVariants {
  REGULAR,
  TITLE_AND_COUNT,
}

export interface CustomFilter {
  filter_name: string;
  filter_value: string;
}
export interface StatData {
  [key: string]: {
    label: string;
    value: string | number;
    itemLinkBase?: string;
    headingBefore?: string;
    longestDwell?: {
      time: string;
      id?: string;
    };
    filter?: {
      name: string;
      value: string;
    };
    itemsStyleVariant?: CardItemVariants;
    itemStyle?: React.CSSProperties;
  };
}

interface Props {
  classes: { [key: string]: string };
  containerStyleVariant?: CardContainerVariants;
  itemsStyleVariant?: CardItemVariants;
  itemsLayoutVariant?: CardLayoutVariants;
  dataTestId?: string;
  style?: React.CSSProperties;
  stats: StatData;
  linkBase: string;
  title: string;
  percentage?: number;
  highlighedNumber?: number;
  highlightPositiveValues?: boolean;
  twoWaySwitchLabels?: string[];
  twoWaySwitchAction?: (active: boolean) => void;
  filters?: FilterItem[];
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress color='primary' variant='static' {...props} />
      <CircularProgress
        variant='static'
        color='secondary'
        value={100}
        style={{ position: 'absolute' }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption'>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const SingleRowStats: React.FC<Props> = ({
  classes,
  dataTestId,
  style,
  linkBase,
  containerStyleVariant,
  itemsStyleVariant,
  itemsLayoutVariant = CardLayoutVariants.REGULAR,
  stats,
  title,
  percentage,
  highlighedNumber,
  highlightPositiveValues,
  twoWaySwitchLabels,
  twoWaySwitchAction,
  filters,
}) => {
  const [switchActive, setSwitchActive] = useState<boolean>(false);

  const containerClasses = clx({
    [classes.paper]: true,
    [classes.containerDark]:
      containerStyleVariant === CardContainerVariants.DARK,
    [classes.containerWhite]:
      containerStyleVariant === CardContainerVariants.WHITE,
    [classes.containerGold]:
      containerStyleVariant === CardContainerVariants.GOLD,
  });

  const itemClasses = (key: string) =>
    clx({
      [classes.singleStat]: true,
      [classes.noBgWhite]:
        stats[key].itemStyle === CardItemVariants.NO_BG_WHITE ||
        (!stats[key].itemStyle &&
          itemsStyleVariant === CardItemVariants.NO_BG_WHITE),
      [classes.noBgBlack]:
        stats[key].itemStyle === CardItemVariants.NO_BG_BLACK ||
        (!stats[key].itemStyle &&
          itemsStyleVariant === CardItemVariants.NO_BG_BLACK),
      [classes.whitePaper]:
        stats[key].itemStyle === CardItemVariants.WHITE_PAPER ||
        (!stats[key].itemStyle &&
          itemsStyleVariant === CardItemVariants.WHITE_PAPER),
      [classes.goldPaper]:
        stats[key].itemStyle === CardItemVariants.GOLD_PAPER ||
        (!stats[key].itemStyle &&
          itemsStyleVariant === CardItemVariants.GOLD_PAPER),
    });

  const renderRegular = (key: string) => {
    const getStatsValue = (value: string | number) => {
      switch (value) {
        case 'pending':
          return <CircularProgress size={18} style={{ margin: 5 }} />;

        case 'error':
          return <NoDataAvailableIcon />;

        default:
          return (
            <Typography
              variant='body1'
              className={classes.itemCountRight}
            >
              {stats[key].filter ? (
                <Link
                  data-testid={`stats:${stats[key].label}:value`}
                  style={{
                    color:
                      highlightPositiveValues &&
                      stats[key].value !== 0
                        ? colors.red
                        : 'inherit',
                  }}
                  to={
                    (stats[key].itemLinkBase || linkBase) +
                    '?' +
                    stats[key].filter!.name +
                    '=' +
                    stats[key].filter!.value
                  }
                >
                  {stats[key].value}
                </Link>
              ) : (
                stats[key].value
              )}
            </Typography>
          );
      }
    };

    return (
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Typography className={classes.itemTitle} variant='body1'>
            {stats[key].filter ? (
              <Link
                data-testid={`stats:${stats[key].label}:title`}
                to={
                  (stats[key].itemLinkBase || linkBase) +
                  '?' +
                  stats[key].filter!.name +
                  '=' +
                  stats[key].filter!.value
                }
              >
                {stats[key].label}
              </Link>
            ) : (
              stats[key].label
            )}
          </Typography>
          {stats[key].longestDwell && (
            <>
              <span className={classes.itemDwellBadge}>
                {stats[key].longestDwell?.id ? (
                  <Link
                    data-testid={`stats:${stats[key].label}:dwell-time-value`}
                    className={classes.singleStatLink}
                    to={
                      (stats[key].itemLinkBase || linkBase) +
                      '/' +
                      stats[key].longestDwell!.id
                    }
                  >
                    {stats[key]?.longestDwell!.time}
                  </Link>
                ) : (
                  stats[key]?.longestDwell!.time
                )}
              </span>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {getStatsValue(stats[key].value)}
        </Grid>
      </Grid>
    );
  };

  const renderTitleAndCount = (key: string) => {
    const renderStatsValue = (value: string | number) => {
      switch (value) {
        case 'pending':
          return (
            <CircularProgress
              size={18}
              style={{ marginTop: '10px' }}
            />
          );

        case 'error':
          return <NoDataAvailableIcon color='#FFF' />;

        default:
          return (
            <Typography
              variant='body1'
              className={classes.itemCountLarge}
            >
              {stats[key].filter ? (
                <Link
                  data-testid={`stats:${stats[key].label}:value`}
                  to={
                    (stats[key].itemLinkBase || linkBase) +
                    '?' +
                    stats[key].filter!.name +
                    '=' +
                    stats[key].filter!.value
                  }
                >
                  {stats[key].value}
                </Link>
              ) : (
                stats[key].value
              )}
            </Typography>
          );
      }
    };

    return (
      <>
        <Typography variant='h4' className={classes.itemTitleSmall}>
          {stats[key].filter ? (
            <Link
              data-testid={`stats:${stats[key].label}:title`}
              to={
                (stats[key].itemLinkBase || linkBase) +
                '?' +
                stats[key].filter!.name +
                '=' +
                stats[key].filter!.value
              }
            >
              {stats[key].label}
            </Link>
          ) : (
            stats[key].label
          )}
        </Typography>
        {renderStatsValue(stats[key].value)}
      </>
    );
  };

  return (
    <Paper
      style={style}
      className={containerClasses}
      data-testid={dataTestId}
    >
      <Box className={classes.cardHeader}>
        <Typography
          variant='h3'
          className={classes.cardTitle}
          data-testid={`stats:${title}:card-title`}
        >
          {filters && filters.length ? (
            <Tooltip
              title={
                <Box className={classes.filterTooltip}>
                  <p>
                    The following filters are applied to this card:
                  </p>
                  <ul>
                    {filters.map((filter) => (
                      <li key={filter.key}>
                        <b>{filter.label}:</b>{' '}
                        {filter.values
                          .map(
                            (value) => value.name || value.full_name,
                          )
                          .join(', ')}
                      </li>
                    ))}
                  </ul>
                </Box>
              }
            >
              <FilterListIcon
                className={classes.filterIcon}
                style={
                  containerStyleVariant === CardContainerVariants.GOLD
                    ? { backgroundColor: colors.black }
                    : undefined
                }
              />
            </Tooltip>
          ) : undefined}
          {title}
        </Typography>
        <Box className={classes.percentageIndicator}>
          {percentage !== undefined ? (
            <CircularProgressWithLabel value={percentage} />
          ) : undefined}
          {highlighedNumber !== undefined ? (
            <Box className={classes.highlighedNumber}>
              {highlighedNumber}
            </Box>
          ) : undefined}
        </Box>
      </Box>

      {stats === undefined && (
        <div className={classes.singleStat}>
          <CircularProgress />
        </div>
      )}
      {stats !== undefined &&
        Object.keys(stats).map((key, index) => (
          <Fragment key={`${stats[key]?.label}-${index}`}>
            {stats[key].headingBefore && (
              <Typography className={classes.headingBefore}>
                {stats[key].headingBefore}
              </Typography>
            )}
            <Paper className={itemClasses(key)}>
              {itemsLayoutVariant === CardLayoutVariants.REGULAR &&
                renderRegular(key)}
              {itemsLayoutVariant ===
                CardLayoutVariants.TITLE_AND_COUNT &&
                renderTitleAndCount(key)}
            </Paper>
          </Fragment>
        ))}
      {twoWaySwitchLabels && (
        <Box className={classes.switchBox}>
          {twoWaySwitchLabels[0]}
          <Switch
            data-testid={`stats:switch:${twoWaySwitchLabels[0]}/${twoWaySwitchLabels[1]}`}
            checked={switchActive}
            color='default'
            classes={{
              track: classes.switchTrack,
            }}
            onClick={() => {
              setSwitchActive(!switchActive);
              twoWaySwitchAction!(switchActive);
            }}
          />
          {twoWaySwitchLabels[1]}
        </Box>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SingleRowStats);
