import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DockDoorsService from '../../services/DockDoors.service';
import styles from './dockDoors.styles';
import Layout from '../layout/Layout';
import { AuthRoutes } from '../../interfaces/routes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { AlertBanner, Typography } from '../primitives/index';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';

// Types
import { AxiosError } from 'axios';
import { PermissionsPermissionsEnum } from 'cloudsort-client';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

//Services
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';

const COLUMNS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: 50,
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'station_name',
    label: 'Station',
    width: 'auto',
    align: 'center',
  },
];

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const DockDoors: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [name, setName] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    return DockDoorsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
    });
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const addDockDoorHandler = async () => {
    setShowProgress(true);
    try {
      if (name) {
        await DockDoorsService.create(name);
        onAfterDialogClose();
        setLastUpdated(new Date().toISOString());
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={onAfterDialogClose}
      >
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <DialogTitle>
          Add {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <FormControl
            className={classes.formControl}
            style={{ width: '100%' }}
          >
            <TextField
              data-testid='new-dock-door-name'
              label='Name'
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onAfterDialogClose}>Cancel</Button>
          <Button
            data-testid='save-new-dock-door'
            onClick={() => {
              addDockDoorHandler();
            }}
            disabled={!name}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'DOCK_DOOR')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='DOCK_DOOR'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}

        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.STATIONWRITE,
            ) && (
              <Button
                data-testid='add-dock-door'
                variant='contained'
                className={classes.containedButton}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add
              </Button>
            )}
          </Grid>
        </Grid>
        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          dataTestIdPrefix={'dock-doors-lists'}
          fetch={fetch}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.DOCK_DOOR.replace('/', '')}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(DockDoors);
