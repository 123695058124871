import React from 'react';
import SingleRowStats from '../../singleRowStats/SingleRowStats';
import { EventEventTypeEnum } from 'cloudsort-client';
import DashboardStatsService from '../../../services/DashboardStats.service';
import EventsService from '../../../services/Events.service';
import dateUtils from '../../../services/utils/date';

// Types
import { AreaDetailsAreaTypeEnum } from 'cloudsort-client';
import configurationUtils from '../../../utils/configurationUtils';

interface Props {
  areaId: number;
  areaType?: AreaDetailsAreaTypeEnum;
}

const AreaTotals: React.FC<Props> = ({ areaId, areaType }) => {
  const getCallsByType = () => {
    switch (areaType) {
      case AreaDetailsAreaTypeEnum.PRIMARY:
        return [];
      case AreaDetailsAreaTypeEnum.SECONDARY:
        return [
          EventsService.getAll({
            area: areaId,
            eventType: EventEventTypeEnum.SECONDARY,
            fromDate: dateUtils.fromDate(),
            toDate: dateUtils.toDate(),
          }),
          EventsService.getAll({
            area: areaId,
            eventType: EventEventTypeEnum.ZONE,
            fromDate: dateUtils.fromDate(),
            toDate: dateUtils.toDate(),
          }),
          EventsService.getAll({
            area: areaId,
            eventType: EventEventTypeEnum.CONTAINERIZED,
            fromDate: dateUtils.fromDate(),
            toDate: dateUtils.toDate(),
          }),
        ];
      case AreaDetailsAreaTypeEnum.STAGING:
        return [];
      default:
        return [];
    }
  };

  const fetchStats = async () => {
    const calls = [
      ...getCallsByType(),
      DashboardStatsService.getPackagesByStatus({ area: areaId }),
    ];
    const responses = await Promise.all(calls as any[]);
    return responses.map(
      ({ data: { count }, request: { responseURL } }) => {
        let label = '';
        if (responseURL.includes('event_type=SECONDARY')) {
          label = 'Total Secondary';
        } else if (responseURL.includes('event_type=ZONE')) {
          label = 'Total Zone';
        } else if (responseURL.includes('event_type=CONTAINERIZED')) {
          label = 'Total Containerized';
        } else {
          label = `Total ${configurationUtils.getPageTitle(
            false,
            'PACKAGE',
          )}`;
        }
        return {
          label,
          value: count,
          filterDisabled: true,
        };
      },
    );
  };

  return (
    <SingleRowStats
      link_base={''}
      filter_name='ProcessStep'
      dataTestId='area-totals-stats'
      fetch={fetchStats}
    />
  );
};

export default AreaTotals;
