import { combineReducers } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import filtersReducer from './filtersSlice';
import searchReducer from './searchSlice';

const rootReducer = combineReducers({
  config: configReducer,
  filters: filtersReducer,
  search: searchReducer,
});

export default rootReducer;
