import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles } from '@material-ui/core/styles';

import colors from '../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, CustomTextField } from '../primitives';

// Services
import AuthService from '../../services/Auth.service';
import { Box, DialogTitle } from '@material-ui/core';
import { TokenObtainPairRequestSwagger } from 'cloudsort-client';

//Icons
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  callbackFn?: () => any;
}

const ReAuthDialog: React.FC<Props> = ({
  isOpen,
  classes,
  callbackFn,
  onAfterClose,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [authState, setAuthState] = useState<
    TokenObtainPairRequestSwagger
  >({
    email: AuthService.getMyEmail(),
    password: '',
    device_id: 'web',
  });
  const [passwordFieldType, setPasswordFieldType] = useState<string>(
    'password',
  );

  const handleClose = () => {
    onAfterClose();
  };

  const onButtonClick = async () => {
    setShowProgress(true);
    try {
      await AuthService.logIn(authState);
      callbackFn && callbackFn();
    } catch (e) {
      setError(`The password you've entered is not correct`);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    setAuthState((prevState) => {
      return {
        ...prevState,
        password: '',
      };
    });
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          <Box style={{ float: 'left' }} mr={1}>
            <LockOutlinedIcon
              style={{
                width: '3rem',
                height: '3rem',
                marginTop: '8px',
              }}
            />
          </Box>
          You're attempting to modify sensitive data.
          <br />
          Please enter your password to continue
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minWidth: 450,
            }}
          >
            <CustomTextField
              inputProps={{
                'data-testid': 'reauth-password',
              }}
              buttonProps={{
                tabIndex: 1,
              }}
              required
              name='password'
              label='Your Password'
              type={passwordFieldType}
              autoComplete='password'
              value={authState.password}
              labelButton={
                passwordFieldType === 'text' ? 'Hide' : 'Show'
              }
              labelButtonAction={() => {
                passwordFieldType === 'text'
                  ? setPasswordFieldType('password')
                  : setPasswordFieldType('text');
              }}
              onChange={(e: React.BaseSyntheticEvent) => {
                e.persist();
                if (error) {
                  setError('');
                }
                const { name, value } = e.target;
                setAuthState((prevState) => {
                  return {
                    ...prevState,
                    [name]: value,
                  };
                });
              }}
            />
            {error && (
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            CANCEL
          </Button>
          <Button onClick={onButtonClick} className={classes.button}>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    datePicker: {
      margin: '10px',
    },
  })),
)(ReAuthDialog);
