import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner, Typography } from '../../primitives';
import colors from '../../..//utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  Box,
  DialogTitle,
  createStyles,
  TextField,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../../asyncSelect/asyncSelect.styles';
import { debounce } from 'lodash';
import sanitizeHtml from 'sanitize-html';

//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  Customer,
  SchemeDetails,
  User,
} from 'cloudsort-client';
import { TypeAheadItem } from '../../../interfaces/components';

// Services
import FmcService from '../../../services/Fmc.service';
import SchemesService from '../../../services/Schemes.service';

//Icons
import SettingsIcon from '@material-ui/icons/Settings';
import { noOptionsMessage } from '../../asyncSelect/utils';
import CustomersService from '../../../services/Customers.service';

interface Props {
  loadplanId?: number;
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
}

const AddSchemeDialog: React.FC<Props> = ({
  loadplanId,
  isOpen,
  classes,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [addSchemeData, setAddSchemeData] = useState<SchemeDetails>({
    name: '',
    fmc: undefined,
    sort_param: '',
    owner: undefined,
  });

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.id,
        label: dataEl.full_name,
      };
    });
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(undefined, inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const handleClose = () => {
    setError(undefined);

    setAddSchemeData({
      name: '',
      fmc: undefined,
      sort_param: '',
      owner: undefined,
    });
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (errorData?.error_code === APIExceptionErrorCodeEnum.ResourceConflict) {
      const conflictingZipcodes = ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are duplicated in the Scheme Set: ' +
        formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e));
  };

  const processAddScheme = async () => {
    setShowProgress(true);
    try {
      await SchemesService.create({
        ...addSchemeData!,
        load_plan: loadplanId,
      });

      updateParent();
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={open}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <SettingsIcon
            style={{
              color: colors.darkGold,
              width: '48px',
              height: 'auto',
            }}
          />
          <br />
          Create New Scheme
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                />
              )}
              <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
                <Typography>Name</Typography>
              </Box>
              <TextField
                autoFocus
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                value={addSchemeData?.name}
                onChange={(e) => {
                  setAddSchemeData({
                    ...addSchemeData,
                    name: e.target.value,
                  });
                }}
              />
              <Box fontWeight='fontWeightBold' p={2} pl={0}>
                <Typography>Sortation Parameter</Typography>
              </Box>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                value={
                  addSchemeData?.sort_param
                    ? sanitizeHtml(addSchemeData.sort_param, {
                      allowedTags: [],
                    })
                    : ''
                }
                onChange={(e) => {
                  setAddSchemeData({
                    ...addSchemeData,
                    sort_param: e.target.value,
                  });
                }}
              />
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Carrier</Typography>
              </Box>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                styles={{
                  ...asyncSelectStyles,
                  menuPortal: (styles: any) => {
                    return {
                      ...styles,
                      zIndex: 9999,
                    };
                  },
                  placeholder: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.lightGray,
                    };
                  },
                  control: (styles: any, state: any) => {
                    return {
                      ...styles,
                      backgroundColor: 'transparent',
                      background: '#F1F1F1',
                      padding: '10px 0',
                      boxShadow: 'none !important',
                      outline: `none !important`,
                      border: 0,
                    };
                  },
                }}
                loadOptions={loadOptions}
                onChange={(option) => {
                  setAddSchemeData({
                    ...addSchemeData,
                    fmc: option
                      ? Number((option as TypeAheadItem).value)
                      : undefined,
                  });
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Owner</Typography>
              </Box>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                styles={{
                  ...asyncSelectStyles,
                  menuPortal: (styles: any) => {
                    return {
                      ...styles,
                      zIndex: 9999,
                    };
                  },
                  placeholder: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.lightGray,
                    };
                  },
                  control: (styles: any, state: any) => {
                    return {
                      ...styles,
                      backgroundColor: 'transparent',
                      background: '#F1F1F1',
                      padding: '10px 0',
                      boxShadow: 'none !important',
                      outline: `none !important`,
                      border: 0,
                    };
                  },
                }}
                loadOptions={loadCustomerOptions}
                onChange={(option) => {
                  setAddSchemeData({
                    ...addSchemeData,
                    owner: option
                      ? Number((option as TypeAheadItem).value)
                      : undefined,
                  });
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pl={1} pr={1}>
            <Button
              data-testid={'scheme-add-dialog-cancel'}
              className={classes.button}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>

            <Button
              data-testid={'scheme-add-dialog-save'}
              className={classes.button}
              disabled={!addSchemeData.name}
              onClick={() => {
                processAddScheme();
              }}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: { width: '500px', maxWidth: '100%' },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
  })),
)(AddSchemeDialog);
