import React, { Fragment, useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '../../primitives';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import EditNameDialog from './EditNameDialog';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../../asyncSelect/asyncSelect.styles';
import {
  EditFunctions,
  UtilsFunctions,
  FilterState,
  goToAddStationModule,
  goToAddCorporateModule,
} from './AreaSettings';
import colors from '../../../utils/colors';
import { common } from '../../../utils/strings';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
import configurationUtils, {
  GlobalModules,
} from '../../../utils/configurationUtils';
import { debounce } from 'lodash';

// Types
import {
  ContainerDetailsContainerTypeEnum,
  AreaDetailsAreaTypeEnum,
  Area,
  Zone,
  Module,
  LoadPoint,
  LoadPointDetails,
  ModuleDetails,
  AreaDetails,
  PermissionsPermissionsEnum,
  Scheme,
  Container,
} from 'cloudsort-client';
import { TypeAheadItem } from '../../../interfaces/components';
import { AxiosError } from 'axios';

// Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Services
import ContainerService from '../../../services/Containers.service';
import ZoneModulesService from '../../../services/ZoneModules.service';
import PermissionsService from '../../../services/Permissions.service';
import AreasService from '../../../services/Areas.service';
import ZonesService from '../../../services/Zones.service';
import LoadPointsService from '../../../services/LoadPoints.service';
import SchemesService from '../../../services/Schemes.service';
import EphemeralStateService from '../../../services/EphemeralState.service';
import { noOptionsMessage } from '../../asyncSelect/utils';

interface Props {
  classes: { [key: string]: string };
  zone: Zone;
  filterState: FilterState;
  lpOptions?: LoadPoint[];
  containerTypeOptions?: ContainerDetailsContainerTypeEnum[];
  editFunctions: EditFunctions;
  utilsFunctions: UtilsFunctions;
  match: any;
  area?: AreaDetails;
}

interface ZoneModule {
  id?: number;
  name?: string;
  zone: number;
  is_active?: boolean;
  load_points: LoadPointDetails[];
}

interface ConfirmationDialogParams {
  title: string;
  msg: string;
  primaryAction: () => void;
  primaryActionLabel?: string;
}

interface MenuParams {
  anchorEl: HTMLElement | null;
  id: number | null;
}

interface RenameDialogParam {
  title: string;
  name: string;
  onEdit: (name?: string) => void;
  validationRegex?: RegExp;
  validationHelperText?: string;
  validationNameCheck?: keyof typeof GlobalModules;
}

const getEnumLabel = (str: string) => {
  return str.charAt(0) + str.slice(1).toLowerCase();
};

const defaultTypeAheadOption: TypeAheadItem = {
  label: 'None',
  value: 'none',
};

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    )
  );
};

const ZoneModuleTable: React.FC<Props> = ({
  classes,
  zone,
  containerTypeOptions,
  lpOptions,
  editFunctions,
  utilsFunctions,
  filterState,
  match,
  area,
}) => {
  const [modules, setModules] = useState<Module[]>();
  const [zoneModules, setZoneModules] = useState<ZoneModule[]>();
  const [targetLps, setTargetLps] = useState<
    Map<number, LoadPoint[]>
  >(new Map<number, LoadPoint[]>());
  const [expanded, setExpanded] = useState<boolean>(false);
  const [confirmationDialogParams, setConfirmationDialogParams] =
    useState<ConfirmationDialogParams | undefined>();
  const [zoneMenuParams, setZoneMenuParams] =
    React.useState<MenuParams>({
      anchorEl: null,
      id: null,
    });
  const [moduleMenuParams, setModuleMenuParams] =
    React.useState<MenuParams>({
      anchorEl: null,
      id: null,
    });
  const [renameDialogParam, setRenameDialogParam] =
    useState<RenameDialogParam | null>(null);

  const filtersActive =
    filterState.fmc !== 'ALL' || filterState.scheme !== 'ALL';

  const fetchModules = async () => {
    utilsFunctions.showProgress(true);
    try {
      const data = (
        await ZoneModulesService.getAll({
          zone: zone?.id,
          pageSize: MAX_PAGE_SIZE,
          areaType: area?.area_type,
        })
      ).data.results;
      const zoneModulesData: any[] = [...data];

      // Sort array, is_active first
      zoneModulesData.sort(
        (x, y) => Number(y.is_active) - Number(x.is_active),
      );

      // Fetch Load Point Data
      for (const zM of zoneModulesData) {
        let lpData: LoadPointDetails[] = [];
        if (
          filterState.scheme !== 'ALL' ||
          filterState.fmc !== 'ALL'
        ) {
          let dataList = getLpByZoneModule(zM);

          if (filterState.scheme !== 'ALL') {
            dataList = dataList?.filter(
              (lpOption) => lpOption.scheme === filterState.scheme,
            );
          }
          if (filterState.fmc !== 'ALL') {
            dataList = dataList?.filter((lpOption) =>
              lpOption?.fmcs?.includes(filterState.fmc!),
            );
          }

          if (dataList) {
            lpData = [...lpData, ...dataList];
          }
        } else {
          lpData = getLpByZoneModule(zM);
        }
        zM.load_points = lpData;
      }

      setZoneModules(zoneModulesData);
      setModules(data);
      setExpanded(true);
    } catch (e) {
      utilsFunctions.handleError(e as AxiosError);
    } finally {
      utilsFunctions.showProgress(false);
    }
  };

  const getLpByZoneModule = (zM: ZoneModule) => {
    const lpData: LoadPointDetails[] = [];
    for (const lpId of [...zM.load_points].sort()) {
      const data = lpOptions?.find(
        (lpOption) => lpOption.id === lpId,
      );
      if (data) {
        lpData.push(data as unknown as LoadPointDetails);
      }
      if (data?.target_zone && !targetLps.get(data?.target_zone)) {
        fetchTargetLps(data.target_zone);
      }
    }
    return lpData;
  };

  const onActiveModuleChange = async (
    id: number,
    isActive: boolean,
  ) => {
    const module = modules?.find((m) => m.id === id);
    if (module) {
      module.is_active = isActive;
      updateModule(module);
    }
  };

  const updateModule = async (module: Module) => {
    utilsFunctions.showProgress(true);
    try {
      await ZoneModulesService.update(
        module.id!,
        module as unknown as ModuleDetails,
      );
      await fetchModules();
    } catch (e) {
      utilsFunctions.handleError(e as AxiosError);
    } finally {
      utilsFunctions.showProgress(false);
    }
  };

  const checkContainers = async (
    module?: ZoneModule,
    zone?: Zone,
  ) => {
    utilsFunctions.showProgress(true);

    try {
      const res = await ContainerService.getAll({
        module: !module ? undefined : module.id,
        zone: !zone ? undefined : zone.id,
      });

      let containersIds: string =
        res.data.results[0]?.id?.toString() || '';
      let containersCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        containersIds += ', ' + container.id;
        containersCount += containersCount;
      });

      let loadPoints: string =
        res.data.results[0]?.load_point?.name?.toString() || '';
      let loadPointCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        loadPoints += ', ' + container.load_point?.name;
        loadPointCount += loadPointCount;
      });

      const zMName = module?.name || module?.id;
      const zName = zone?.name || zone?.id;
      const simpleMsg = `Are you sure you want to remove ${
        zone ? ' Zone ' : ' Zone Module '
      }${zone ? zName : zMName}?`;

      const message =
        res.data.count === 0
          ? simpleMsg
          : `${
              containersCount > 1 ? 'Containers' : 'Container'
            } ${containersIds} ${
              containersCount > 1 ? 'are' : 'is'
            } still on ${
              loadPointCount > 1 ? 'Loadpoints' : 'Loadpoint'
            } ${loadPoints}. After deleting the ${
              zone ? ' Zone' : ' Zone Module'
            }, the ${
              containersCount > 1
                ? 'containers have'
                : 'container has'
            } to be manually moved (Use Troubleshooting Mode in the Operator Tool) to the new loadpoint.
            ${simpleMsg}`;

      setConfirmationDialogParams({
        title: `Remove ${zone ? ' Zone' : ' Zone Module'}`,
        msg: message,
        primaryAction: async () => {
          zone
            ? await editFunctions.onRemoveZone(zone?.id!)
            : await editFunctions.onRemoveModule(module?.id!);

          module
            ? fetchModules()
            : ZonesService.getAll(
                undefined,
                zone?.area,
                MAX_PAGE_SIZE,
              );
        },
        primaryActionLabel: 'Remove',
      });
    } catch (e) {
      utilsFunctions.handleError(e as AxiosError);
    }
    utilsFunctions.showProgress(false);
  };

  const handleMenuClose = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    setZoneMenuParams({ anchorEl: null, id: null });
    setModuleMenuParams({ anchorEl: null, id: null });
  };

  const onAfterDialogClose = () => {
    setRenameDialogParam(null);
  };

  const editLoadPoint = async (lp: LoadPointDetails) => {
    await editFunctions.onEditLoadPoint(lp);
    await fetchModules();
  };

  const checkForNoneValue = (value: any) => {
    return value === 'none' ? null : value;
  };

  const checkForValueOnOptions = (options: any[], value: any) => {
    return options.some((option) => option.id === value)
      ? value
      : 'none';
  };

  const renderTable = () => {
    return zoneModules?.length
      ? zoneModules?.map((zoneModule) => (
          <div
            key={zoneModule.id}
            data-testid='zone-module-item'
            style={{ padding: '0 0 28px' }}
          >
            {renderTableToolBar(zoneModule)}
            <TableContainer
              component={Paper}
              style={{
                marginBottom:
                  area?.area_type === AreaDetailsAreaTypeEnum.PRIMARY
                    ? 30
                    : 0,
              }}
            >
              <Table>
                {renderTableHeader()}
                <TableBody>
                  {zoneModule.load_points.length
                    ? zoneModule.load_points.map((lp) => {
                        return (
                          <TableRow key={lp.id}>
                            <TableCell
                              align='center'
                              scope='row'
                              className={classes.nameTd}
                              style={{ width: 250 }}
                            >
                              {lp.name}
                              <IconButton
                                onClick={() => {
                                  setRenameDialogParam({
                                    title: 'Edit Load Point Name',
                                    name: lp.name || '',
                                    onEdit: (name?: string) => {
                                      if (name && lp) {
                                        lp.name = name;
                                        editFunctions.onEditLoadPoint(
                                          lp,
                                        );
                                        setZoneModules([
                                          ...zoneModules,
                                        ]);
                                      }
                                      onAfterDialogClose();
                                    },
                                    validationRegex: /^[0-9]{1,}$/,
                                    validationHelperText:
                                      'Numbers only.',
                                    validationNameCheck: 'LOADPOINT',
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>

                            <TableCell
                              align='center'
                              style={{ padding: 0, width: 250 }}
                            >
                              {renderSelect(
                                lp,
                                false,
                                containerTypeOptions?.map(
                                  (option) => {
                                    return {
                                      id: option,
                                      name: getEnumLabel(option),
                                    };
                                  },
                                ) || [],
                                'default_container_type',
                                (value) => {
                                  return {
                                    title: `Change ${configurationUtils.getPageTitle(
                                      true,
                                      'CONTAINER',
                                    )} Type`,
                                    msg: `Are you sure you want to
                                               change ${configurationUtils.getPageTitle(
                                                 true,
                                                 'CONTAINER',
                                               )} type to ${value}?`,
                                    primaryAction: () => {
                                      lp.default_container_type =
                                        value as any;
                                      editLoadPoint(lp);
                                    },
                                  };
                                },
                              )}
                            </TableCell>

                            {area?.area_type ===
                              AreaDetailsAreaTypeEnum.PRIMARY && (
                              <Fragment>
                                <TableCell
                                  align='center'
                                  style={{ padding: 0, width: 350 }}
                                >
                                  {renderAsyncSelect(
                                    lp,
                                    false,
                                    'target_area',
                                    'target_area_name',
                                    (value: TypeAheadItem) => {
                                      const valueLabel = value.label;
                                      return {
                                        title: 'Change Target Area',
                                        msg: `Are you sure you want
                                                   to change target area to ${valueLabel}?`,
                                        primaryAction: () => {
                                          lp.target_area =
                                            checkForNoneValue(
                                              value.value,
                                            );
                                          lp.target_area_name =
                                            checkForNoneValue(
                                              value.label,
                                            );
                                          lp.target_zone = null;
                                          lp.target_loadpoint = null;
                                          editLoadPoint(lp);
                                        },
                                      };
                                    },
                                    debounce(
                                      (
                                        inputValue: string,
                                        callback: any,
                                      ) => {
                                        AreasService.search(
                                          inputValue,
                                          AreaDetailsAreaTypeEnum.SECONDARY,
                                        )
                                          .then((data) => {
                                            callback(
                                              data.data.results.map(
                                                (dataEl: Area) => {
                                                  return {
                                                    value: dataEl.id,
                                                    label:
                                                      dataEl.name,
                                                  };
                                                },
                                              ),
                                            );
                                          })
                                          .catch((e) => {
                                            utilsFunctions.handleError(
                                              e,
                                            );
                                          });
                                      },
                                      500,
                                    ),
                                  )}
                                </TableCell>

                                <TableCell
                                  align='center'
                                  style={{ padding: 0, width: 350 }}
                                >
                                  {renderAsyncSelect(
                                    lp,
                                    !lp.target_area,
                                    'target_zone',
                                    'target_zone_name',
                                    (value: TypeAheadItem) => {
                                      const valueLabel = value.label;
                                      return {
                                        title: 'Change Target Zone',
                                        msg: `Are you sure you want
                                                     to change target zone to ${valueLabel}?`,
                                        primaryAction: () => {
                                          lp.target_loadpoint = null;
                                          lp.target_zone =
                                            checkForNoneValue(
                                              value.value,
                                            );
                                          lp.target_zone_name =
                                            checkForNoneValue(
                                              value.label,
                                            );
                                          editLoadPoint(lp);
                                        },
                                      };
                                    },
                                    debounce(
                                      (
                                        inputValue: string,
                                        callback: any,
                                      ) => {
                                        ZonesService.search(
                                          inputValue,
                                          lp.target_area!,
                                        )
                                          .then((data) => {
                                            callback(
                                              data.data.results.map(
                                                (dataEl: Zone) => {
                                                  return {
                                                    value: dataEl.id,
                                                    label:
                                                      dataEl.name,
                                                  };
                                                },
                                              ),
                                            );
                                          })
                                          .catch((e) => {
                                            utilsFunctions.handleError(
                                              e,
                                            );
                                          });
                                      },
                                      500,
                                    ),
                                  )}
                                </TableCell>

                                <TableCell
                                  align='center'
                                  style={{
                                    padding: 0,
                                    width: '250px',
                                  }}
                                >
                                  {renderAsyncSelect(
                                    lp,
                                    !lp.target_area ||
                                      !lp.target_zone,
                                    'target_loadpoint',
                                    'target_loadpoint_name',
                                    (value: TypeAheadItem) => {
                                      const valueLabel = value.label;
                                      return {
                                        title:
                                          'Change Target Load Point',
                                        msg: `Are you sure you want
                                                   to change target load point to ${valueLabel}?`,
                                        primaryAction: () => {
                                          lp.target_loadpoint =
                                            checkForNoneValue(
                                              value.value,
                                            );
                                          lp.target_loadpoint_name =
                                            checkForNoneValue(
                                              value.label,
                                            );
                                          editLoadPoint(lp);
                                        },
                                      };
                                    },
                                    debounce(
                                      (
                                        inputValue: string,
                                        callback: any,
                                      ) => {
                                        LoadPointsService.getAll({
                                          search: inputValue,
                                          isActive: true,
                                          zone: lp.target_zone!,
                                        })
                                          .then((data) => {
                                            callback(
                                              data.data.results.map(
                                                (
                                                  dataEl: LoadPoint,
                                                ) => {
                                                  return {
                                                    value: dataEl.id,
                                                    label:
                                                      dataEl.name,
                                                  };
                                                },
                                              ),
                                            );
                                          })
                                          .catch((e) => {
                                            utilsFunctions.handleError(
                                              e,
                                            );
                                          });
                                      },
                                      500,
                                    ),
                                  )}
                                </TableCell>
                              </Fragment>
                            )}

                            {area?.area_type ===
                              AreaDetailsAreaTypeEnum.SECONDARY && (
                              <Fragment>
                                <TableCell
                                  align='center'
                                  style={{ padding: 0, width: 350 }}
                                >
                                  {renderAsyncSelect(
                                    lp,
                                    false,
                                    'target_area',
                                    'target_area_name',
                                    (value: TypeAheadItem) => {
                                      const valueLabel = value.label;
                                      return {
                                        title: 'Change Staging Area',
                                        msg: `Are you sure you want
                                             to change staging area to ${valueLabel}?`,
                                        primaryAction: () => {
                                          lp.target_area =
                                            checkForNoneValue(
                                              value.value,
                                            );
                                          lp.target_area_name =
                                            checkForNoneValue(
                                              value.label,
                                            );
                                          editLoadPoint(lp);
                                        },
                                      };
                                    },
                                    debounce(
                                      (
                                        inputValue: string,
                                        callback: any,
                                      ) => {
                                        AreasService.search(
                                          inputValue,
                                          AreaDetailsAreaTypeEnum.STAGING,
                                        )
                                          .then((data) => {
                                            callback(
                                              data.data.results.map(
                                                (dataEl: Area) => {
                                                  return {
                                                    value: dataEl.id,
                                                    label:
                                                      dataEl.name,
                                                  };
                                                },
                                              ),
                                            );
                                          })
                                          .catch((e) => {
                                            utilsFunctions.handleError(
                                              e,
                                            );
                                          });
                                      },
                                      500,
                                    ),
                                  )}
                                </TableCell>

                                <TableCell
                                  align='center'
                                  style={{ padding: 0, width: 270 }}
                                >
                                  {renderAsyncSelect(
                                    lp,
                                    false,
                                    'scheme',
                                    'scheme_name',
                                    (value) => {
                                      const valueLabel = value.label;
                                      return {
                                        title: 'Scheme',
                                        msg: `Are you sure you want
                                               to change scheme to ${valueLabel}?`,
                                        primaryAction: () => {
                                          lp.scheme =
                                            checkForNoneValue(
                                              value.value,
                                            );
                                          lp.scheme_name =
                                            checkForNoneValue(
                                              value.label,
                                            );

                                          editLoadPoint(lp);
                                        },
                                      };
                                    },
                                    debounce(
                                      (
                                        inputValue: string,
                                        callback: any,
                                      ) => {
                                        SchemesService.getAll({
                                          loadplanStation:
                                            EphemeralStateService.getMyStationId(),
                                          search: inputValue,
                                          isActive: true,
                                        })
                                          .then((data) => {
                                            callback(
                                              data.data.results.map(
                                                (dataEl: Scheme) => {
                                                  return {
                                                    value: dataEl.id,
                                                    label:
                                                      dataEl.name,
                                                  };
                                                },
                                              ),
                                            );
                                          })
                                          .catch((e) => {
                                            utilsFunctions.handleError(
                                              e,
                                            );
                                          });
                                      },
                                      500,
                                    ),
                                  )}
                                </TableCell>

                                <TableCell
                                  align='center'
                                  style={{
                                    color: colors.gray,
                                    width: 175,
                                  }}
                                >
                                  {lp.fmcs?.length
                                    ? lp.fmcs.join(', ')
                                    : common.emptyValue}
                                </TableCell>
                              </Fragment>
                            )}
                          </TableRow>
                        );
                      })
                    : renderEmptyTableLabel()}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      : zoneModules?.length === 0 && renderNoDataLabel();
  };

  const renderAsyncSelect = (
    lp: LoadPointDetails,
    disabled: boolean,
    param: string,
    param_name: string,
    getDialogParams: (value: any) => ConfirmationDialogParams,
    loadOptions: any,
  ) => {
    return (
      <FormControl style={{ minWidth: '80%' }}>
        <AsyncSelect<TypeAheadItem>
          cacheOptions={false}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          isClearable={!!(lp as any)[param]}
          styles={{
            ...asyncSelectStyles,
            menuPortal: (styles: any) => {
              return {
                ...styles,
                zIndex: 9999,
              };
            },
            menu: (styles: any) => {
              return {
                ...styles,
                marginTop: '-5px',
              };
            },
            control: (styles: any, state: any) => {
              return {
                ...styles,
                backgroundColor: 'transparent',
                boxShadow: 'none !important',
                outline: `none !important`,
                border: 0,
              };
            },
          }}
          components={{ DropdownIndicator }}
          loadOptions={loadOptions}
          onChange={(option) => {
            setConfirmationDialogParams(
              getDialogParams(option || defaultTypeAheadOption),
            );
          }}
          isDisabled={disabled}
          noOptionsMessage={noOptionsMessage}
          placeholder={'Start Typing...'}
          value={
            (lp as any)[param]
              ? ({
                  value: (lp as any)[param]?.toString(),
                  label: (lp as any)[param_name],
                } as TypeAheadItem)
              : defaultTypeAheadOption
          }
          menuPortalTarget={document.body}
        />
      </FormControl>
    );
  };

  const fetchTargetLps = async (zoneId: number) => {
    utilsFunctions.showProgress(true);
    try {
      const data = await utilsFunctions.getActiveLoadpointsForZone(
        zoneId,
      );
      if (targetLps) {
        targetLps.set(zoneId, data);
        setTargetLps(new Map(targetLps));
      }
    } catch (e) {
      utilsFunctions.handleError(e as AxiosError);
    } finally {
      utilsFunctions.showProgress(false);
    }
  };

  const renderSelect = (
    lp: LoadPointDetails,
    disabled: boolean,
    paramOptions: any[],
    param: string,
    getDialogParams: (value: any) => ConfirmationDialogParams,
  ) => {
    return paramOptions ? (
      <FormControl style={{ minWidth: '50%' }}>
        <Select
          data-testid='zone-module:container-type'
          disableUnderline
          disabled={disabled}
          className={classes.muiSelect}
          value={checkForValueOnOptions(
            paramOptions,
            (lp as any)[param],
          )}
          onChange={(e) => {
            let value = e.target.value;
            if ((lp as any)[param] !== value) {
              setConfirmationDialogParams(
                getDialogParams(value as any),
              );
            }
          }}
        >
          <MenuItem value={'none'}>None</MenuItem>
          {renderMenuItems(paramOptions)}
        </Select>
      </FormControl>
    ) : (
      common.emptyValue
    );
  };

  const renderMenuItems = (options: any[]) => {
    return options?.map((option) => {
      return (
        <MenuItem
          value={option?.id}
          key={option?.id}
          data-testid={`menu-item:${option.name}`}
        >
          {option?.name}
        </MenuItem>
      );
    });
  };

  const renderTableToolBar = (zoneModule: ZoneModule) => {
    const zoneName = zoneModule.name || String(zoneModule.id);
    return (
      <Toolbar
        className={
          zoneModule.is_active
            ? classes.toolBar
            : `${classes.toolBar} ${classes.inactive}`
        }
      >
        <Typography>
          {zoneModule?.name || `Module ${zoneModule.id}`}
        </Typography>
        <Switch
          color='default'
          className={classes.muiSwitch}
          checked={zoneModule.is_active}
          onChange={() => {
            const toggleLabel = zoneModule.is_active
              ? 'deactivate'
              : 'activate';

            setConfirmationDialogParams({
              title: `${
                toggleLabel.charAt(0).toUpperCase() +
                toggleLabel.slice(1)
              } Module`,
              msg: zoneModule.is_active
                ? `You are about to deactivate Module ${zoneName?.toUpperCase()}, you won't be able to process any more packages, until you activate it again.

              Please confirm if you want to proceed.`
                : `Are you sure you want to activate Module ${zoneName?.toUpperCase()}?`,
              primaryAction: () => {
                if (zoneModule) {
                  const value = !zoneModule.is_active;
                  zoneModule.is_active = value;
                  onActiveModuleChange(zoneModule.id!, value);
                }
              },
              primaryActionLabel: 'Confirm',
            });
          }}
        />
        <IconButton
          data-testid='module:options:menu'
          style={{
            alignSelf: 'center',
            padding: 5,
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setModuleMenuParams({
              anchorEl: e.currentTarget,
              id: zoneModule.id!,
            });
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={moduleMenuParams.anchorEl}
          open={
            !!moduleMenuParams.anchorEl &&
            zoneModule?.id === moduleMenuParams.id
          }
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={(e) => {
              handleMenuClose(e);
              setRenameDialogParam({
                title: 'Rename Module',
                name: zoneModule.name || '',
                onEdit: (name?: string) => {
                  const editCb = () => {
                    zoneModule.name = name;
                    const moduleObj = modules?.find(
                      (m) => m.id === zoneModule.id,
                    );
                    if (moduleObj) {
                      // @ts-ignore
                      setZoneModules([...zoneModules]);
                      moduleObj.name = name;
                      updateModule(moduleObj);
                    }
                    onAfterDialogClose();
                  };
                  const nameInUse = modules
                    ?.map((m) => m.name)
                    .some((n) => n === name);
                  if (nameInUse) {
                    setConfirmationDialogParams({
                      title: 'Duplicated Module Name',
                      msg: `Name ${name}
                               is already in use, are you sure
                               you want to proceed?`,
                      primaryAction: () => {
                        editCb();
                      },
                    });
                  } else {
                    editCb();
                  }
                },
              });
            }}
          >
            Rename
          </MenuItem>
          <MenuItem
            data-testid='remove-module'
            onClick={(e) => {
              handleMenuClose(e);
              checkContainers(zoneModule);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </Toolbar>
    );
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align='center' style={{ width: 250 }}>
            Load Points
          </TableCell>
          <TableCell align='center' style={{ width: 250 }}>
            {configurationUtils.getPageTitle(true, 'CONTAINER')} Type
          </TableCell>
          {area?.area_type === AreaDetailsAreaTypeEnum.PRIMARY && (
            <Fragment>
              <TableCell align='center' style={{ width: 350 }}>
                Target {configurationUtils.getPageTitle(true, 'AREA')}
              </TableCell>
              <TableCell align='center' style={{ width: 250 }}>
                Target Zone
              </TableCell>
              <TableCell align='center' style={{ width: 250 }}>
                Target Load Point
              </TableCell>
            </Fragment>
          )}
          {area?.area_type === AreaDetailsAreaTypeEnum.SECONDARY && (
            <Fragment>
              <TableCell align='center' style={{ width: 350 }}>
                Staging{' '}
                {configurationUtils.getPageTitle(true, 'AREA')}
              </TableCell>
              <TableCell align='center' style={{ width: 270 }}>
                Scheme
              </TableCell>
              <TableCell align='center' style={{ width: 175 }}>
                Carrier
              </TableCell>
            </Fragment>
          )}
        </TableRow>
      </TableHead>
    );
  };

  const renderNoDataLabel = () => {
    return (
      <div
        style={{
          backgroundColor: colors.white,
          display: 'flex',
          justifyContent: 'center',
        }}
        data-testid='area-settings:no-data-label'
      >
        <Typography
          variant={'h3'}
          style={{
            fontWeight: 'normal',
            margin: '20px auto',
            fontSize: 20,
          }}
        >
          {filtersActive ? (
            'No results for the selected filters.'
          ) : (
            <Fragment>
              No modules data. Please click on
              <MoreVertIcon
                style={{
                  top: 5,
                  position: 'relative',
                }}
              />
              to add modules.
            </Fragment>
          )}
        </Typography>
      </div>
    );
  };

  const renderEmptyTableLabel = () => {
    return (
      <TableRow>
        <TableCell align='center' colSpan={6} style={{ padding: 0 }}>
          <Typography
            variant={'h3'}
            style={{
              fontWeight: 'normal',
              margin: '20px auto',
              fontSize: 20,
            }}
          >
            No results for the selected filters.
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    if (zoneModules) {
      fetchModules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  useEffect(() => {
    if (
      match.params.areaType === AreaDetailsAreaTypeEnum.PRIMARY &&
      lpOptions
    ) {
      fetchModules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpOptions]);

  return (
    <Fragment>
      {confirmationDialogParams && (
        <ConfirmationDialog
          dataTestIdPrefix='area-settings:'
          title={confirmationDialogParams?.title}
          msg={confirmationDialogParams?.msg}
          primaryActionLabel={
            confirmationDialogParams.primaryActionLabel
          }
          onPrimaryAction={() => {
            if (confirmationDialogParams) {
              confirmationDialogParams.primaryAction();
            }
            setConfirmationDialogParams(undefined);
          }}
          onCancel={() => {
            setConfirmationDialogParams(undefined);
          }}
          isOpen={!!confirmationDialogParams}
        />
      )}

      {renameDialogParam && (
        <EditNameDialog
          title={renameDialogParam.title}
          open={!!renameDialogParam}
          name={renameDialogParam?.name || ''}
          onAfterDialogClose={onAfterDialogClose}
          onEdit={renameDialogParam.onEdit}
          validationRegex={renameDialogParam.validationRegex}
          validationHelperText={
            renameDialogParam.validationHelperText
          }
          validationNameCheck={renameDialogParam.validationNameCheck}
        />
      )}

      {area?.area_type === AreaDetailsAreaTypeEnum.SECONDARY && (
        <ExpansionPanel
          className={classes.muiExpansionPanel}
          expanded={expanded}
        >
          <ExpansionPanelSummary
            className={classes.muiExpansionPanelSummary}
            onClick={() => {
              if (zoneModules) {
                setExpanded(!expanded);
              } else {
                fetchModules();
              }
            }}
          >
            <Typography
              style={{ margin: '5px 12px 0 0' }}
              data-testid='area-settings:zone-name'
            >
              {zone.name}
            </Typography>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.STATIONWRITE,
            ) && (
              <IconButton
                data-testid='zone:options:menu'
                style={{
                  alignSelf: 'center',
                  padding: 5,
                  marginLeft: 'auto',
                }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setZoneMenuParams({
                    anchorEl: e.currentTarget,
                    id: zone.id!,
                  });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={zoneMenuParams.anchorEl}
              open={
                !!zoneMenuParams.anchorEl &&
                zone?.id === zoneMenuParams.id
              }
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={(e) => {
                  handleMenuClose(e);
                  goToAddStationModule(
                    match.params.id,
                    match.params.areaType,
                    zone.id!.toString(),
                  );
                }}
              >
                Add Station Module
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleMenuClose(e);
                  goToAddCorporateModule(
                    match.params.id,
                    match.params.areaType,
                    zone.id!.toString(),
                  );
                }}
              >
                Add Corporate Module
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleMenuClose(e);
                  setRenameDialogParam({
                    title: 'Rename Zone',
                    name: zone.name || '',
                    onEdit: (name?: string) => {
                      const editCb = () => {
                        onAfterDialogClose();
                        zone.name = name;
                        editFunctions.onEditZone(zone);
                      };
                      const nameInUse = utilsFunctions
                        ?.getAllZoneNames()
                        .some((n) => n === name?.toUpperCase());
                      if (nameInUse) {
                        setConfirmationDialogParams({
                          title: 'Duplicated Zone Name',
                          msg: `Name "${name}"
                               is already in use, are you sure
                               you want to proceed?`,
                          primaryAction: () => {
                            editCb();
                          },
                        });
                      } else {
                        editCb();
                      }
                    },
                    validationRegex: /^[A-Z]$/,
                    validationHelperText: 'A single capital letter.',
                    validationNameCheck: 'ZONE',
                  });
                }}
              >
                Rename
              </MenuItem>
              <MenuItem
                data-testid='remove-zone'
                onClick={(e) => {
                  handleMenuClose(e);
                  checkContainers(undefined, zone);
                }}
              >
                Remove
              </MenuItem>
            </Menu>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.muiExpansionPanelDetailsRoot }}
          >
            {renderTable()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {area?.area_type === AreaDetailsAreaTypeEnum.PRIMARY &&
        renderTable()}
    </Fragment>
  );
};

export default withStyles(
  createStyles(() => ({
    toolBar: {
      backgroundColor: colors.white,
      minHeight: 56,
      justifyContent: 'space-between',
      borderBottom: `0.5px solid ${colors.dark}`,
      '& p': {
        color: colors.dark,
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    muiExpansionPanel: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: '0 !important',
    },
    muiExpansionPanelSummary: {
      backgroundColor: colors.gold,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26)',
      marginBottom: 10,
      '& p': {
        color: colors.dark,
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    muiExpansionPanelDetailsRoot: {
      flexDirection: 'column',
      margin: '-8px 5px 0 5px',
      padding: 0,
    },
    muiSwitch: {
      marginLeft: 'auto',
      '&.MuiSwitch-root': {
        width: 87,
        height: 28,
        padding: 0,
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(59px)',
          '& + .MuiSwitch-track': {
            opacity: 'inherit',
          },
        },
      },
      '& .MuiSwitch-input': {
        left: '-210%',
        width: '310%',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: colors.gold,
        boxShadow: 'none',
        width: 24,
        height: 24,
      },
      '& .MuiSwitch-track': {
        backgroundColor: colors.ultrLightGold,
        opacity: 'inherit',
        borderRadius: 20,
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          height: 14,
          fontSize: '0.75rem',
          fontWeight: 700,
        },
        '&:before': {
          content: '"ACTIVE"',
          left: 14,
        },
      },
    },
    muiSelect: {
      textAlign: 'left',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    inactive: {
      '& .MuiSwitch-input': {
        left: 0,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: colors.red,
      },
      '& .MuiSwitch-track': {
        backgroundColor: 'rgba(234, 59, 80, 0.2)',
        '&:before': {
          content: 'none',
        },
        '&:after': {
          content: '"INACTIVE"',
          right: 5,
        },
      },
    },
    nameTd: {
      '& button': {
        padding: 0,
        float: 'right',
        visibility: 'hidden',
      },
      '&:hover': {
        '& button': {
          visibility: 'visible',
        },
      },
    },
  })),
)(ZoneModuleTable);
