import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

export default createStyles((theme: Theme) => ({
  boldNameParam: {
    color: colors.dark,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  paramValueLight: {
    color: colors.gray,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: colors.gray,
    },
  },
  dialogRoot: {
    overflow: 'hidden',
    minWidth: 300,
  },
  inputPadding: {
    paddingLeft: '5px',
    paddingRigth: '5px',
  },
  muiSelect: {
    paddingLeft: 5,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}));
