import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Typography, AlertBanner } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import qrCodeUtils from '../../utils/qrCode';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import StaffStats from './staffStats/StaffStats';
import colors from '../../utils/colors';
import validateEmail from '../../utils/validateEmail';
import ReAuthDialog from '../reAuthDialog/ReAuthDialog';
import { renderDuration } from '../DetailsPagesFunctions';
import { common } from '../../utils/strings';

// Icons
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import SettingsIcon from '@material-ui/icons/Settings';
import BlockIcon from '@material-ui/icons/Block';
import CreateIcon from '@material-ui/icons/Create';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

// Types
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Staff, PermissionsPermissionsEnum } from 'cloudsort-client';

// Services
import StaffService from '../../services/Staff.service';
import AuthService from '../../services/Auth.service';
import EventsService from '../../services/Events.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import configurationUtils from '../../utils/configurationUtils';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const StationStaffDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [showReAuthDialog, setShowReAuthDialog] =
    useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [staffEditParams, setStaffEditParams] = useState<Staff>({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [showInvalidateDialog, setShowInvalidateDialog] =
    useState<boolean>(false);
  const [showRedownloadDialog, setShowRedownloadDialog] =
    useState<boolean>(false);
  const [
    showEnableDisableUserDialog,
    setShowEnableDisableUserDialog,
  ] = useState<boolean>(false);

  const [error, setError] = useState<string>();

  const isMyUser = AuthService.getMyId() === staffData?.id;

  const isValidEmail = validateEmail(staffEditParams?.email || '');

  const enableDisableUser = async () => {
    try {
      setShowProgress(true);
      if (staffData?.id) {
        const stateObj = { ...staffData };
        stateObj.is_active = !stateObj.is_active;
        stateObj.email = undefined;
        stateObj.permission_data!.organization = undefined;
        await StaffService.update(stateObj.id!, stateObj);
        await getStaffData();
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowEnableDisableUserDialog(false);
    setShowProgress(false);
  };

  const getStaffData = async () => {
    setShowProgress(true);
    try {
      const { data } = await StaffService.getById(
        (match.params as any).id,
        EphemeralStateService.getMyStationId(),
      );
      setStaffData(data);
      setStaffEditParams(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const deleteStaff = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        await StaffService.delete(staffData?.id);

        browserHistory.push(AuthRoutes.STAFF);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      user: staffData?.id,
      pageSize: rowsPerPage,
    });
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        qrCodeUtils.download(
          await StaffService.getLabel(staffData.id),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const invalidateBadge = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        await StaffService.invalidateBadge(staffData?.id).then(() => {
          setShowRedownloadDialog(true);
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
    setShowInvalidateDialog(false);
  };

  const processEditForm = async () => {
    try {
      setShowProgress(true);
      const reqParams = { ...staffEditParams };

      //if not editing email, remove it from the request
      if (staffEditParams.email === staffData?.email) {
        reqParams.email = undefined;
      }
      reqParams.permission_data = undefined;
      if (staffData?.id) {
        await StaffService.update(staffData.id!, reqParams);
        await getStaffData();
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowEditDialog(false);
    setShowProgress(false);
  };

  useEffect(() => {
    if (!staffData) {
      getStaffData();
    }
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.USERREAD,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderForm = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
            <Typography>First Name</Typography>
          </Box>
          <TextField
            autoFocus
            placeholder={'First Name'}
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            classes={{
              root: classes.inputHolder,
            }}
            value={staffEditParams?.first_name}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                first_name: e.target.value,
              });
            }}
          />
          <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
            <Typography>Last Name</Typography>
          </Box>
          <TextField
            placeholder={'Last Name'}
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            classes={{
              root: classes.inputHolder,
            }}
            value={staffEditParams?.last_name}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                last_name: e.target.value,
              });
            }}
          />

          <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
            <Typography>Email Address</Typography>
          </Box>
          <TextField
            placeholder={'Email Address'}
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            classes={{
              root: classes.inputHolder,
            }}
            value={staffEditParams?.email}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                email: e.target.value,
              });
            }}
            helperText={
              !!staffEditParams?.email && !isValidEmail
                ? 'Please enter a valid email.'
                : `If you update an email, the ${configurationUtils.getPageTitle(
                    true,
                    'STAFF',
                  )} will need to verify it before they can log in with it.`
            }
            error={!!staffEditParams?.email && !isValidEmail}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'STAFF')} Details for ${
            staffData?.full_name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STAFF'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid='station-staff-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-'}
          data-testid={'station-staff-details-delete-dialog'}
          title={`Delete ${configurationUtils.getPageTitle(
            true,
            'STAFF',
          )}`}
          msg={`Are you sure you want to delete ${staffData?.full_name}?`}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteStaff}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-invalidate'}
          data-testid={'station-staff-details-invalidate-dialog'}
          title={'Invalidate Badge'}
          msg={
            'This will invalidate existing badges and a new one will need to be printed.'
          }
          primaryActionLabel={'Invalidate'}
          onPrimaryAction={() => {
            invalidateBadge();
          }}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowInvalidateDialog(false);
          }}
          isOpen={showInvalidateDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-redownload'}
          data-testid={'station-staff-details-redownload-dialog'}
          title={'Badge Invalidated'}
          msg={
            'The badge has been invalidated for the user. Would you like to download a new one?'
          }
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            onGetQrCick();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowRedownloadDialog(false);
          }}
          isOpen={showRedownloadDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={
            'station-staff-details-enable-disable-user'
          }
          data-testid={'station-staff-details-enable-disable-user'}
          title={
            staffData?.is_active
              ? `Disable ${configurationUtils.getPageTitle(
                  true,
                  'STAFF',
                )}`
              : `Enable ${configurationUtils.getPageTitle(
                  true,
                  'STAFF',
                )}`
          }
          msg={
            staffData?.is_active
              ? `Are you sure you want to disable the ${configurationUtils.getPageTitle(
                  true,
                  'STAFF',
                )} ${staffData.full_name}?`
              : `Are you sure you want to enable the ${configurationUtils.getPageTitle(
                  true,
                  'STAFF',
                )} ${staffData?.full_name || ''}?`
          }
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            enableDisableUser();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowEnableDisableUserDialog(false);
          }}
          isOpen={showEnableDisableUserDialog}
        />

        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
          className={classes.header}
          data-testid={'station-staff-details-header'}
        >
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title}>
              {`${configurationUtils.getPageTitle(
                true,
                'STAFF',
              )} Details for ${staffData?.full_name || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.USERLOCK,
            ) && (
              <Button
                disabled={!staffData}
                fullWidth={false}
                variant='contained'
                className={classes.containedButton}
                style={{ marginLeft: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowEnableDisableUserDialog(true);
                }}
              >
                {staffData?.is_active ? (
                  <CancelOutlinedIcon style={{ marginRight: 10 }} />
                ) : (
                  <DoneOutlineOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                )}
                {staffData?.is_active
                  ? `Disable ${configurationUtils.getPageTitle(
                      true,
                      'STAFF',
                    )}`
                  : `Enable ${configurationUtils.getPageTitle(
                      true,
                      'STAFF',
                    )}`}
              </Button>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.USERWRITE,
            ) && (
              <>
                <Button
                  variant='contained'
                  className={classes.containedButton}
                  disabled={!staffData}
                  data-testid={'station-staff-details-edit-button'}
                  style={{ marginLeft: 15 }}
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    setError(undefined);
                    setShowEditDialog(true);
                  }}
                >
                  <CreateIcon style={{ marginRight: 10 }} />
                  Edit
                </Button>

                <Button
                  variant='contained'
                  className={classes.containedButton}
                  disabled={!staffData || isMyUser}
                  data-testid={'station-staff-details-delete-button'}
                  style={{ marginLeft: 15 }}
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    setError(undefined);
                    setShowDeleteDialog(true);
                  }}
                >
                  <IndeterminateCheckBoxOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Delete
                </Button>

                <Button
                  disabled={!staffData}
                  fullWidth={false}
                  variant='contained'
                  className={classes.containedButton}
                  style={{ marginLeft: 10 }}
                  onClick={(e) => {
                    e.preventDefault();
                    browserHistory.push(
                      `${AuthRoutes.STAFF}/permissions/${
                        (match.params as any).id
                      }`,
                    );
                  }}
                >
                  <SettingsIcon style={{ marginRight: 10 }} />
                  Users Permissions
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <div
          className={classes.subHeader}
          data-testid={'station-staff-details-sub-header'}
        >
          <div style={{ display: 'flex' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.USERBADGEINVALIDATE,
            ) && (
              <Button
                data-testid={'station-staff-invalidate-badge'}
                disabled={!staffData}
                fullWidth={false}
                variant='outlined'
                className={classes.outlinedButton}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  setShowInvalidateDialog(true);
                }}
              >
                <BlockIcon style={{ marginRight: 10 }} />
                Invalidate Badge
              </Button>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.USERBADGEREAD,
            ) && (
              <Button
                data-testid={'station-staff-details-qr'}
                disabled={!staffData}
                fullWidth={false}
                variant='outlined'
                className={classes.outlinedButton}
                style={{ marginLeft: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onGetQrCick();
                }}
              >
                <FilterCenterFocusIcon style={{ marginRight: 10 }} />
                Badge
              </Button>
            )}
          </div>
        </div>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} data-testid={'station-staff-details'}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid=''
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Name'
                value={staffData?.full_name}
              />
              {configurationUtils.isModuleActive('AREA') && (
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPageTitle(
                    true,
                    'AREA',
                  )}
                  value={staffData?.area_name || common.emptyValue}
                />
              )}
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={configurationUtils.getPageTitle(
                  true,
                  'DEVICE',
                )}
                value={staffData?.device_id || common.emptyValue}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Daily Totals'
                value={staffData?.daily_totals || common.emptyValue}
              />
              <br />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='ID'
                value={staffData?.id}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Station'
                value={
                  staffData?.permission_data?.station_name ||
                  common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Uptime'
                value={
                  staffData?.active_since && staffData?.active
                    ? renderDuration(
                        (new Date().getTime() -
                          new Date(
                            staffData?.active_since!,
                          ).getTime()) /
                          1000,
                      )
                    : common.emptyValue
                }
              />
            </Paper>
          </Grid>
        </Grid>

        <Typography
          className={classes.boldNameParam}
          style={{ marginBottom: 16 }}
        >
          {configurationUtils.getPageTitle(true, 'STAFF')} Statistics
        </Typography>
        <StaffStats staffId={(match.params as any).id} />

        {!!staffData && (
          <div style={{ marginTop: 24 }}>
            <EventsPaginatedTable
              dataTestIdPrefix={'station-staff-details-events'}
              fetch={fetchEvents}
            />
          </div>
        )}

        <Dialog open={showEditDialog}>
          <DialogTitle>
            Update{' '}
            {configurationUtils.getPageTitle(true, 'STAFF') +
              ' ' +
              staffData?.first_name +
              ' ' +
              staffData?.last_name}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {renderForm()}
          </DialogContent>
          <DialogActions>
            <Box pl={1} pr={1}>
              <Button
                data-testid={
                  'station-staff-details-edit-dialog-cancel'
                }
                className={classes.button}
                onClick={() => {
                  setShowEditDialog(false);
                }}
              >
                Cancel
              </Button>

              <Button
                data-testid={
                  'station-staff-details-edit-dialog-update'
                }
                className={classes.button}
                disabled={
                  !staffEditParams.first_name ||
                  !staffEditParams.last_name ||
                  !staffEditParams.email ||
                  !validateEmail(staffEditParams.email)
                }
                onClick={() => {
                  //if email is not changed, don't show re-auth dialog
                  if (staffData?.email === staffEditParams.email)
                    processEditForm();
                  else {
                    setShowEditDialog(false);
                    setShowReAuthDialog(true);
                  }
                }}
              >
                Update
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <ReAuthDialog
          isOpen={showReAuthDialog}
          onAfterClose={() => {
            setShowReAuthDialog(false);
            setShowEditDialog(true);
          }}
          callbackFn={() => {
            setShowReAuthDialog(false);
            processEditForm();
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 20,
      justifyContent: 'space-between',
      borderBottom: '0.5px solid rgb(220, 220, 220)',
      paddingBottom: 20,
    },
    subHeader: {
      width: '100%',
      display: 'flex',
      marginBottom: 20,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    infoHolder: {
      margin: '0 0 24px 0',
      display: 'flex',
      alignItems: 'start',
      textAlign: 'left',
      color: '#222222',
      justifyContent: 'space-between',
      padding: '24px 30px',
      '& p': {
        opacity: 0.7,
        padding: '14px 0',
      },
      '& b': {
        opacity: 1,
      },
    },
    edit: {
      display: 'flex',
      minWidth: '100%',
      padding: '7px 0',
      '& p:first-child': {
        margin: '5px 5px 0 0',
      },
    },
    syncSelect: {
      flexGrow: 1,
      minWidth: 300,
      margin: '15px 0',
    },
    permissionsHolder: {
      width: '100%',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    divider: {
      height: 2,
    },
    dialogContent: { width: '500px', maxWidth: '100%' },
    button: {
      color: colors.darkGold,
    },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
  })),
)(StationStaffDetailsComponent);
