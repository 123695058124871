import { Alert, AlertProps } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './alertBanner.styles';
import * as React from 'react';

export interface IAlertProps extends AlertProps {
  alertTitle?: string;
  alertMsg?: string;
  classes: { [key: string]: string };
  className?: string;
  buttonLabel?: string;
  buttonAction?: (e: React.BaseSyntheticEvent) => any;
}

const AlertBanner: React.FC<IAlertProps> = (props) => {
  const {
    alertTitle,
    alertMsg,
    classes,
    className,
    buttonLabel,
    buttonAction,
    ...muiProps
  } = props;
  return (
    <Alert
      {...muiProps}
      className={`${classes.alert} ${className}`}
      classes={{
        root: classes.root,
        message: buttonLabel
          ? classes.message + ' ' + classes.flexColumn
          : classes.message,
      }}
    >
      <p>
        <b>
          {alertTitle}
          {alertTitle && !!alertMsg && ': '}
        </b>
        {!!alertMsg && <span>{alertMsg}</span>}
      </p>
      {alertMsg?.includes('Network Error') && (
        <Button
          variant={'outlined'}
          onClick={() => {
            window.location.reload();
          }}
        >
          Try Again
        </Button>
      )}
      {buttonLabel && (
        <Button
          variant={'outlined'}
          onClick={buttonAction}
          style={{ marginTop: '10px' }}
        >
          {buttonLabel}
        </Button>
      )}
    </Alert>
  );
};

export default withStyles(styles)(AlertBanner);
