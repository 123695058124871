import { createStyles, Theme } from '@material-ui/core/styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  ...detailsPageStyles,
  ...sectionPageBaseStyle,
  ...selectStyles,
  detailHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 13,
    minHeight: 40,
  },
  detailAddHolder: {
    display: 'flex',
    marginBottom: 20,
    maxWidth: '60%',
  },
  detailAddButton: {
    marginLeft: 16,
    paddingRight: 15,
    border: 0,
    borderRadius: 5,
    backgroundColor: colors.darkGold,
    color: colors.white,
    textTransform: 'capitalize',
    padding: '6px 10px',
    '&:hover': {
      backgroundColor: colors.darkGold,
      color: colors.white,
      border: 0,
    },
  },
  detailReorderButton: {
    '& svg': {
      marginLeft: 5,
    },
  },
  detailAsyncSelect: {
    flexGrow: 1,
  },
  banner: {
    width: '100%',
    borderRadius: 4,
    margin: '24px 0 30px 0',
    backgroundColor: '#F1E0D1',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    color: colors.dark,
  },
  addButton: {
    border: '1px solid #E1E1E1',
    borderRadius: 5,
    backgroundColor: '#565656',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    marginRight: 15,
    '& svg': {
      marginRight: 5,
    },
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  nonMobileAlignRight: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right',
    },
  },
  inputPadding: {
    paddingLeft: '5px',
    paddingRigth: '5px',
  },
}));
