import { BaseAPI } from 'cloudsort-client/dist/base';

class Base {
  private api: BaseAPI;

  constructor() {
    this.api = new BaseAPI();
  }

  public getBasePath() {
    try {
      return JSON.parse(JSON.stringify(this.api)).basePath;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new Base();
