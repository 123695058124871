import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, AlertBanner } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import styles from './routes.styles';
import DndTable, { Actions } from '../dndTable/DndTable';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import ErrorHandler from '../../utils/ErrorHandler';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  useMediaQuery,
  useTheme,
  TextField,
  Button as MuiButton,
  InputLabel,
} from '@material-ui/core';
import configurationUtils from '../../utils/configurationUtils';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { debounce } from 'lodash';
import browserHistory from '../../utils/browserHistory';
import {
  AuthRoutes,
  getModuleUrl,
  ModulesKeys,
} from '../../interfaces/routes';
import DockDoorService from '../../services/DockDoors.service';

// Icons
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import CreateIcon from '@material-ui/icons/Create';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

// Types
import {
  Route,
  NestedRoute2Station,
  Location,
  Route2StationDetails,
  PermissionsPermissionsEnum,
  DockDoorDetails,
} from 'cloudsort-client';
import { Link, match } from 'react-router-dom';
import { Column, TypeAheadItem } from '../../interfaces/components';
import { ValueType, components } from 'react-select';
import { AxiosError } from 'axios';

// Services
import RoutesService from '../../services/Routes.service';
import StationsService from '../../services/Stations.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import { noOptionsMessage } from '../asyncSelect/utils';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

interface DialogParams {
  msg: string;
  id: number | string;
  data?: Route2StationDetails;
}

const RouteDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  // Get labels on each render cycle
  const COLUMNS: Column[] = [
    {
      id: 'station_name_url',
      label: configurationUtils.getPageTitle(true, 'STOP'),
      width: 250,
      align: 'left',
    },
    {
      id: 'fmcs',
      label: 'Carrier',
      width: 150,
      align: 'left',
    },
  ];

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [routeData, setRouteData] = useState<Route>();
  const [selectedStation, setSelectedStation] = useState<
    TypeAheadItem | undefined
  >();

  const [defaultStationOptions, setDefaultStationOptions] =
    useState<TypeAheadItem[]>();
  const [deleteDialogParams, setDeleteDialogParams] = useState<
    DialogParams | undefined
  >();
  const [addDialogParams, setAddDialogParams] = useState<
    DialogParams | undefined
  >();
  const [prevStationOrder, setPrevStationOrder] = useState<Route>();

  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [editName, setEditName] = useState<string>();
  const [editDockDoor, setEditDockDoor] = useState<TypeAheadItem>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const isReorderInProgress = !!prevStationOrder;
  const showDialog = !!deleteDialogParams || !!addDialogParams;

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const getRouteData = async () => {
    setShowProgress(true);
    try {
      const { data } = await RoutesService.getById(
        (match.params as any).id,
      );
      setRouteData(data);
      setEditName(data.name ? data.name : undefined);
      setEditDockDoor({
        label: data.default_dockdoor_name || '',
        value: String(data.default_dockdoor),
      });
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const onDragEnd = (
    recentlyOrderedStations: NestedRoute2Station[],
  ) => {
    const route = { ...(routeData as Route) };
    if (!prevStationOrder) {
      setPrevStationOrder({ ...(route as Route) });
    }
    route.route2stations = recentlyOrderedStations;
    setRouteData({ ...(route as Route) });
  };

  const onDeleteIconClick = (id: number | string | null) => {
    const station = routeData?.route2stations!.find(
      (stationData: NestedRoute2Station) =>
        stationData.id?.toString() === id,
    );
    if (id && station) {
      setDeleteDialogParams({
        msg: `Are you sure you want to delete ${
          station.station_name
        } ${configurationUtils.getPageTitle(true, 'STOP')}?`,
        id,
      });
    }
  };

  const onDialogCancel = () => {
    setDeleteDialogParams(undefined);
    setAddDialogParams(undefined);
  };

  const onDeleteDialogOk = () => {
    if (deleteDialogParams?.id) {
      setShowProgress(true);
      RoutesService.removeRouteToStationsById(
        deleteDialogParams?.id as number,
      )
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          setDeleteDialogParams(undefined);
          setShowProgress(false);
        });
    }
  };

  const onReorder = (save: boolean) => {
    if (save && routeData) {
      setShowProgress(true);
      RoutesService.setRoutes(routeData.id!, routeData)
        .then((res) => {
          setRouteData(res.data);
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          setShowProgress(false);
        });
    } else {
      setRouteData({ ...(prevStationOrder as Route) });
    }
    setPrevStationOrder(undefined);
  };

  const onAddClick = () => {
    if (selectedStation && routeData) {
      setShowProgress(true);
      RoutesService.getRouteToStationsById(
        parseInt(selectedStation.value),
      )
        .then((res) => {
          const results = res.data.results;
          if (results.length) {
            setShowProgress(false);
            setAddDialogParams({
              msg: `${results[0].station_name} is already in use by other route.`,
              id: -1,
              data: results[0],
            });
          } else {
            addStation();
          }
        })
        .catch((e) => {
          handleError(e);
          setShowProgress(false);
        });
    }
  };

  const addStation = () => {
    if (selectedStation && routeData) {
      setShowProgress(true);
      RoutesService.addRouteToStations({
        station: parseInt(selectedStation.value),
        route: routeData?.id!,
      })
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          setSelectedStation(undefined);
          setShowProgress(false);
          setAddDialogParams(undefined);
        });
    }
  };

  const patchStation = () => {
    if (routeData && addDialogParams?.data) {
      setShowProgress(true);
      const data = { ...addDialogParams?.data };
      data.route = routeData.id;
      RoutesService.updateRouteToStationsById(
        addDialogParams?.data?.id!,
        data,
      )
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          setSelectedStation(undefined);
          setAddDialogParams(undefined);
          setShowProgress(false);
        });
    }
  };

  const formatStationOptions = (data: Location[]) => {
    return data.map((location: Location) => {
      return {
        value: location.id?.toString() || '',
        label: location.zipcode
          ? `${location.name} - Zipcode: ${location.zipcode}`
          : `${location.name}`,
      };
    });
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      StationsService.getByName(
        inputValue,
        routeData?.id,
        undefined,
        undefined,
        EphemeralStateService.getMyStationId(),
      )
        .then((data) => {
          callback(formatStationOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [routeData],
  );

  const handleOnStationSelect = (
    option: ValueType<TypeAheadItem>,
  ) => {
    if (option) {
      setSelectedStation(option as TypeAheadItem);
    } else {
      setSelectedStation(undefined);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const fetchDefaultStationOptions = async () => {
    const { data } = await StationsService.getByName(
      undefined,
      (match.params as any).id,
      10,
      undefined,
      EphemeralStateService.getMyStationId(),
    );
    setDefaultStationOptions(formatStationOptions(data.results));
  };

  const onAfterDialogClose = (update = false) => {
    setShowEditDialog(false);
    setError(undefined);

    if (!update) {
      setEditName(routeData!.name!);
      setEditDockDoor({
        label: routeData?.default_dockdoor_name || '',
        value: String(routeData?.default_dockdoor),
      });
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const editHandler = async () => {
    setShowProgress(true);
    try {
      const { data } = await RoutesService.setRoutes(routeData!.id!, {
        ...routeData!,
        name: editName,
        default_dockdoor: Number(editDockDoor?.value),
      });
      setRouteData(data);
      setEditName(data.name ? data.name : undefined);
      setEditDockDoor({
        label: data?.default_dockdoor_name || '',
        value: String(data?.default_dockdoor),
      });
      onAfterDialogClose(true);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const renderEditDialog = () => {
    return (
      <Dialog
        open={showEditDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogContent style={{ padding: inXsScreen ? 16 : 0 }}>
          <DialogTitle>
            Edit {configurationUtils.getPageTitle(true, 'ROUTE')}{' '}
            {routeData!.id!}
          </DialogTitle>

          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <div
            style={{
              padding: inXsScreen ? '10px 0px' : '10px 24px',
            }}
          >
            <FormControl
              className={classes.formControl}
              style={{ width: '100%', margin: '0 0px 10px 0' }}
            >
              <TextField
                data-testid={'route-dialog-name'}
                autoFocus
                label={
                  <span style={{ color: colors.title }}>Name</span>
                }
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                FormHelperTextProps={{
                  style: { paddingLeft: '5px', marginTop: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
                style={{ width: '300px', maxWidth: '100%' }}
              />
            </FormControl>
            {configurationUtils.isModuleActive('DOCK_DOOR') && (
              <FormControl
                className={classes.formControl}
                style={{
                  width: '100%',
                  margin: 0,
                  height: 50,
                }}
                data-testid='select-dock-door'
              >
                <InputLabel
                  className={classes.selectLabel}
                  style={{ fontSize: 12, top: '-18px' }}
                >
                  Default{' '}
                  {configurationUtils.getModuleLabel(
                    true,
                    'DOCK_DOOR',
                  )}
                </InputLabel>
                <AsyncSelect<TypeAheadItem>
                  isClearable
                  cacheOptions
                  styles={{
                    ...asyncSelectStyles,
                    menuPortal: (styles: any) => {
                      return {
                        ...styles,
                        zIndex: 9999,
                      };
                    },
                    input: (styles: any) => {
                      return {
                        ...styles,
                        margin: '0 -3px',
                      };
                    },
                    placeholder: (styles: any) => {
                      return {
                        ...styles,
                        color: colors.lightGray,
                        top: '65%',
                      };
                    },
                    control: (styles: any, state: any) => {
                      return {
                        ...styles,
                        backgroundColor: 'transparent !important',
                        background: '#F1F1F1',
                        padding: '10px 0 0 0',
                        boxShadow: 'none !important',
                        outline: `none !important`,
                        border: 0,
                      };
                    },
                  }}
                  loadOptions={loadDDOptions}
                  onChange={(option) => {
                    setEditDockDoor(option as TypeAheadItem);
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  value={editDockDoor}
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </FormControl>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <MuiButton
            data-testid={'route-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              editHandler();
            }}
            data-testid={'route-dialog-update'}
            disabled={!editName}
          >
            Update
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  const processDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      await RoutesService.delete(routeData!.id!);
      browserHistory.push(AuthRoutes.ROUTE);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.ROUTEREAD,
    );

    if (!routeData) {
      getRouteData();
    }
    fetchDefaultStationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'ROUTE')} Details for ${
            routeData?.name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='ROUTE'>
        {showProgress && <ProgressIndicator />}
        {showEditDialog && renderEditDialog()}
        <ConfirmationDialog
          dataTestIdPrefix={'route-details-'}
          data-testid={'routes-details-confirmation-dialog'}
          title={
            deleteDialogParams
              ? `Delete ${stopsLabels.singular}`
              : `Add ${stopsLabels.singular}`
          }
          msg={
            deleteDialogParams
              ? deleteDialogParams.msg
              : addDialogParams?.msg
          }
          primaryActionLabel={deleteDialogParams ? 'Delete' : 'Add'}
          onPrimaryAction={
            deleteDialogParams ? onDeleteDialogOk : addStation
          }
          secondaryActionLabel={deleteDialogParams ? '' : 'Move'}
          onSecondaryAction={patchStation}
          cancelLabel={'Cancel'}
          onCancel={onDialogCancel}
          isOpen={showDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-route-confirmation-dialog'}
          title={
            'Delete ' + configurationUtils.getPageTitle(true, 'ROUTE')
          }
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'ROUTE',
          )} ${routeData?.name}?`}
          primaryActionLabel={'Delete'}
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              style={{ marginBottom: 16 }}
            >
              {`${configurationUtils.getPageTitle(
                true,
                'ROUTE',
              )} Details for ${routeData?.name || ''}`}
            </Typography>
          </Grid>
          {PermissionsService.hasPermission(
            PermissionsPermissionsEnum.ROUTEWRITE,
          ) && (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.nonMobileAlignRight}
              >
                <MuiButton
                  data-testid='edit-route-btn'
                  variant='outlined'
                  className={classes.containedButton}
                  disabled={!!error}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditDialog(true);
                  }}
                >
                  <CreateIcon style={{ marginRight: 10 }} />
                  Edit
                </MuiButton>
                <MuiButton
                  data-testid={'delete-route-btn'}
                  variant='outlined'
                  className={classes.containedButton}
                  style={{ marginLeft: '10px' }}
                  disabled={!!error}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteConfirmation(true);
                  }}
                >
                  <IndeterminateCheckBoxOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Delete
                </MuiButton>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid=''
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(true, 'ROUTE') +
                  ' ID'
                }
                value={routeData?.id || common.emptyValue}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(false, 'STOP') +
                  ' Count'
                }
                value={routeData?.stations_count || common.emptyValue}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(
                    false,
                    'OUTBOUND_LOAD',
                  ) + ' Count'
                }
                value={
                  routeData?.outboundloads_count || common.emptyValue
                }
              />
              {configurationUtils.isModuleActive('DOCK_DOOR') && (
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={`Default ${configurationUtils.getModuleLabel(
                    true,
                    'DOCK_DOOR',
                  )}`}
                  value={
                    routeData?.default_dockdoor_name ? (
                      <Link
                        style={{ color: colors.darkGold }}
                        to={`${AuthRoutes.DOCK_DOOR}/${routeData?.default_dockdoor}`}
                        title={`Go to ${routeData?.default_dockdoor_name}`}
                      >
                        {routeData?.default_dockdoor_name}
                      </Link>
                    ) : (
                      common.emptyValue
                    )
                  }
                />
              )}
            </Paper>
          </Grid>
        </Grid>

        {!error && !!routeData?.route2stations?.length && (
          <AlertBanner
            data-testid='routes-details-info-banner'
            className={classes.banner}
            severity='info'
            alertTitle={`Edit ${configurationUtils.getPageTitle(
              true,
              'STOP',
            )} order`}
            alertMsg={
              !isReorderInProgress
                ? `Drag and drop rows to reorder ${configurationUtils.getPageTitle(
                    false,
                    'STOP',
                  )}.`
                : 'Add and delete functions are disabled until reorder is done.'
            }
          />
        )}

        <div
          className={classes.detailHeader}
          data-testid='routes-details'
        >
          <Typography className={classes.subTitle}>
            Add {stopsLabels.plural}
          </Typography>
          {isReorderInProgress && (
            <div>
              <Button
                data-testid='routes-details-done-button'
                className={classes.detailReorderButton}
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  onReorder(true);
                }}
              >
                Done
                <DoneOutlineOutlinedIcon />
              </Button>
              <Button
                data-testid='routes-details-cancel-button'
                className={classes.detailReorderButton}
                style={{ marginLeft: 10 }}
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onReorder(false);
                }}
              >
                Cancel
                <CancelOutlinedIcon />
              </Button>
            </div>
          )}
        </div>
        <Grid
          container
          wrap='nowrap'
          data-testid='routes-details-add-form-holder'
        >
          <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
            {routeData && (
              <AsyncSelect<TypeAheadItem>
                data-testid={'routes-details-async-select'}
                isClearable
                cacheOptions
                defaultOptions={defaultStationOptions}
                styles={asyncSelectStyles}
                loadOptions={loadOptions}
                className={classes.detailAsyncSelect}
                onChange={handleOnStationSelect}
                isDisabled={isReorderInProgress}
                noOptionsMessage={noOptionsMessage}
                placeholder={`Find ${configurationUtils.getPageTitle(
                  false,
                  'STOP',
                )} by name or zipcode`}
                value={selectedStation ? selectedStation : null}
                components={{
                  MenuList: (menuListprops: any) => {
                    return (
                      <Fragment>
                        <div className={classes.arrowUp} />
                        <components.MenuList
                          {...menuListprops}
                          className={classes.menuList}
                        >
                          <div>{menuListprops.children}</div>
                        </components.MenuList>
                      </Fragment>
                    );
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.ROUTEWRITE,
            ) && (
              <Button
                data-testid='routes-details-add-button'
                className={classes.detailAddButton}
                style={{ float: 'right' }}
                fullWidth={false}
                disabled={isReorderInProgress || !selectedStation}
                onClick={(e) => {
                  e.preventDefault();
                  onAddClick();
                }}
              >
                <Hidden smDown>
                  <AddToPhotosOutlinedIcon style={{ margin: 5 }} />
                </Hidden>
                Add {stopsLabels.plural}
              </Button>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />
        {error && (
          <AlertBanner
            data-testid='routes-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {!error && !routeData?.route2stations?.length && (
          <AlertBanner
            data-testid='routes-details-info-banner'
            className={classes.banner}
            severity='info'
            alertTitle={
              'There is no information to display at the moment'
            }
          />
        )}
        {!error && !!routeData?.route2stations?.length && (
          <DndTable
            title={`${configurationUtils.getPageTitle(
              true,
              'ROUTE',
            )} ${configurationUtils.getPageTitle(false, 'STOP')}`}
            columns={COLUMNS}
            data={routeData?.route2stations.map((r2s: any) => {
              r2s.fmcs = r2s.station_data.fmcs.join(', ');
              const stopUrl = `${
                process.env.REACT_APP_BASENAME || ''
              }${getModuleUrl(ModulesKeys.STOP)}?search=${
                r2s.station_name
              }&stationId=${EphemeralStateService.getMyStationId()}`;
              r2s.station_name_url = `<a class="${classes.link}" href="${stopUrl}"> ${r2s.station_name}<a>`;
              return r2s;
            })}
            onAfterDragEnd={onDragEnd}
            isDragDisabled={
              !PermissionsService.hasPermission(
                PermissionsPermissionsEnum.ROUTEWRITE,
              )
            }
            action={Actions.DELETE}
            onActionClick={onDeleteIconClick}
            isActionDisabled={isReorderInProgress}
            dataTestIdPrefix={'routes-details'}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(styles)(RouteDetailsComponent);
