import LocalStorageService from './LocalStorage.service';
import { WeatherApi } from 'cloudsort-client';
import axios from '../utils/axios';

class Weather {
  private api: WeatherApi;

  constructor() {
    this.api = new WeatherApi(undefined, undefined, axios as any);
  }
  private async fetchWeather(zip: string) {
    return this.api.weatherList({
      q: zip + ',US',
      units: 'Imperial',
    });
  }

  public async getWeatherForStation(zipcode: string) {
    const data = LocalStorageService.getWeatherInfo();
    //if there is cached data
    if (data && new Date(data.expires) > new Date()) {
      return data;
    } else {
      const res = await this.fetchWeather(zipcode.substring(0, 5)); //zip codes in DB have 9 digits but we only need first 5
      if (res) {
        const data: any = {
          icon: res.data.weather.icon,
          title: res.data.weather.main,
          temperature: Math.round(res.data.temp.temp),
          cityID: res.data.id,
          expires: new Date(new Date().valueOf() + 1000 * 60 * 60), //1hr from now
        };
        LocalStorageService.setWeatherInfo(data);
        return data;
      }
      return null;
    }
  }
}

export default new Weather();
