import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  menuList: {
    height: '100%',
    width: '244px',
    backgroundColor: colors.dark,
    padding: 0,
  },
  menuItem: {
    padding: '9px 45px',
    color: colors.secondaryWhite,
    fontSize: 15,
    letterSpacing: 0,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgb(224,187,155, 0.8)',
    },
  },
  current: {
    color: colors.black,
    backgroundColor: colors.gold,
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.gold,
      opacity: 1,
    },
    '& svg': {
      color: `${colors.black} !important`,
      fill: `${colors.black} !important`,
      stroke: `${colors.black} !important`,
      strokeWidth: '.3',
    },
    '&$expansionPanelSummaryExpanded': {
      minHeight: 50,
    },
    '& h6': {
      color: `${colors.black} !important`,
    },
    '& $indicator': {
      display: 'block',
    },
  },
  currentStationView: {
    color: colors.white,
    backgroundColor: '#49474a',
  },
  currentElement: {
    color: `${colors.black}!important`,
    backgroundColor: colors.gold,
    '& svg': {
      color: `${colors.black} !important`,
      fill: `${colors.black} !important`,
      stroke: `${colors.black} !important`,
    },
    '& h6': {
      color: `${colors.black} !important`,
    },
  },
  expansionPanelRoot: {
    color: colors.white,
    backgroundColor: colors.dark,
    boxShadow: 'none !important',
    margin: '2px 0',
    '&:before': {
      display: 'none',
    },
  },
  expansionPanelDetailsRoot: {
    padding: 0,
  },
  expansionPanelDisabled: {
    color: colors.dark,
    backgroundColor: `${colors.gray} !important`,
    '&:before': {
      display: 'none',
    },
    '& h6': {
      color: `${colors.dark} !important`,
    },
    '& svg': {
      color: `${colors.dark} !important`,
      fill: `${colors.dark} !important`,
    },
  },
  expansionPanelSummary: {
    padding: '0 24px',
    minHeight: `56px !important`,
    '& svg': {
      margin: '2px 10px 0 0',
      color: colors.secondaryWhite,
      fill: colors.secondaryWhite,
      stroke: colors.secondaryWhite,
      strokeWidth: '.3',
    },
    '& h6': {
      fontSize: 15,
      fontWeight: 'normal',
      color: colors.secondaryWhite,
    },
  },
  expansionPanelSummaryDisabled: {
    opacity: '1 !important',
  },
  expansionPanelSummaryContent: {
    margin: '10px 0',
    '&$expansionPanelSummaryExpanded': {
      margin: '10px 0',
    },
  },
  expansionPanelSummaryExpandIcon: {
    padding: 0,
  },
  expansionPanelSummaryExpanded: {},
  expansionPanelExpanded: {
    margin: `0 !important`,
    boxShadow: 'none',
  },
  divider: {
    backgroundColor: colors.black,
    margin: '10px 0',
  },
  indicator: {
    width: 4,
    backgroundColor: colors.white,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    display: 'none',
  },
  stationName: {
    color: colors.white,
    minHeight: 25,
    display: 'flex',
    placeItems: 'center',
    flexDirection: 'column',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 'normal',
    '& .MuiSelect-root': {
      maxWidth: 140,
    },
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  noStationSelectIcon: {
    opacity: 0,
  },
  stationSelectIcon: {
    color: colors.white,
  },
  expandMoreIcon: {
    margin: `0 !important`,
  },
  stationSelectorLabel: {
    position: 'absolute',
    left: '-15px',
    top: 5,
    color: colors.white,
    fontEeight: 500,
    fontSize: 10,
    '&.Mui-focused': {
      color: colors.white,
    },
  },
  // Configuration only styles
  expansionPanelSummaryConfig: {
    padding: '0 24px',
    minWidth: 220,
    minHeight: `50px !important`,
    '& svg': {
      color: colors.lightGray,
      fill: colors.lightGray,
      stroke: colors.lightGray,
      strokeWidth: '.3',
    },
    '& h6': {
      fontSize: 15,
      fontWeight: 'normal',
      color: colors.secondaryWhite,
    },
  },
  folderIcon: {
    position: 'relative',
    pointerEvents: 'none',
    top: '-1px',
    '& svg': {
      marginRight: 10,
    },
  },
  newElementDrawer: {
    position: 'fixed',
    left: '56px',
    bottom: 'calc(-40vh + 68px)',
    width: '244px',
    backgroundColor: colors.dark,
    color: colors.white,
    height: '40vh',
    transition: 'all 0.3s',
  },
  expandedDrawer: {
    transform: 'translateY(calc(-40vh + 68px))',
  },
  newElementDrawerHeader: {
    backgroundColor: colors.gold,
    padding: '10px',
  },
  newElementDrawerBody: {
    padding: '20px 10px 10px 10px',
    overflow: 'auto',
  },
  newElementDrawerButton: {
    display: 'block',
    width: '100%',
    marginBottom: '15px',
    textAlign: 'left',
    padding: '10px 16px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    textTransform: 'none',
    backgroundColor: colors.white,
  },

  newElemetDrawerMessage: {
    color: colors.white,
    fontSize: '14px',
    textAlign: 'center',
    padding: '10px',
  },
}));
