import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import { AlertBanner, Typography } from '../primitives/index';
import MuiButton from '@material-ui/core/Button';
import styles from './OutboundLoads.styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorHandler from '../../utils/ErrorHandler';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import Layout from '../layout/Layout';
import { Column, TypeAheadItem } from '../../interfaces/components';
import {
  formatFirstNItemsAndMore,
  renderTimestampString,
} from '../DetailsPagesFunctions';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { STRING_UNITS_VOLUME } from '../UIStrings';
import DateOrTimePicker from '../datePicker/DateOrTimePicker';
import fedexLabels from '../../utils/fedexLabels';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import configurationUtils from '../../utils/configurationUtils';
import { Box, Grid } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import { debounce, isEmpty } from 'lodash';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';
import clx from 'classnames';
import { Helmet } from 'react-helmet';

// Types
import {
  Route,
  PermissionsPermissionsEnum,
  DockDoorDetails,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';

// Services
import DockDoorService from '../../services/DockDoors.service';
import RoutesService from '../../services/Routes.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import LocalStorageService from '../../services/LocalStorage.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

interface Props extends RouteComponentProps {
  classes: { [key: string]: string };
}

const asyncStyles = {
  ...asyncSelectStyles,
  menuPortal: (styles: any) => {
    return {
      ...styles,
      zIndex: 9999,
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      margin: '0 -3px',
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: colors.lightGray,
      top: '65%',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      backgroundColor: 'transparent !important',
      background: '#F1F1F1',
      padding: '10px 0 0 0',
      boxShadow: 'none !important',
      outline: `none !important`,
      border: 0,
    };
  },
};

const OutboundLoads: React.FC<Props> = ({ location, classes }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string;
  }>();

  const isBOLActive = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.LOAD_OPS.bol_active;
  }, []);

  // Filter drawer
  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setLastUpdated(Date.now());
  };

  const keyHash = isEmpty(selectedFilters)
    ? ''
    : Object.entries(selectedFilters!).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        '',
      );

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.PACKAGEREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get labels on each render cycle
  const COLUMNS_OUTBOUND_LOADS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 30,
      align: 'left',
    },
    {
      id: 'stops',
      label: configurationUtils.getPageTitle(false, 'STOP'),
      align: 'center',
      width: 'auto',
    },
    {
      id: 'containers_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'CONTAINER',
      )}`,
      align: 'center',
      width: 'auto',
      hide: !EphemeralStateService.getMyStationConfiguratation()
        .GENERAL.MODULES.CONTAINER.active,
    },
    {
      id: 'packages_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'PACKAGE',
      )}`,
      align: 'center',
      width: 'auto',
    },
    {
      id: 'load_status',
      label: 'Status',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dispatched_time',
      label: 'Close time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dockdoor_name',
      label: configurationUtils.getPageTitle(false, 'DOCK_DOOR'),
      align: 'center',
      width: 'auto',
    },
    {
      id: 'trailer_id',
      label: configurationUtils.getPropertylabel(
        'OUTBOUND_LOAD',
        'trailer_id',
      ),
      align: 'center',
      width: 'auto',
    },
    {
      id: 'is_dispatched',
      label: 'Is Dispatched',
      hide: true,
    },
  ];

  if (isBOLActive) {
    COLUMNS_OUTBOUND_LOADS.push({
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
    });
  }

  const fetchOutboundLoads = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await OutboundLoadsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      owner:
        selectedFilters?.owner && selectedFilters.owner.values.length
          ? selectedFilters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        selectedFilters?.carrier &&
        selectedFilters.carrier.values.length
          ? selectedFilters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        selectedFilters?.route && selectedFilters.route.values.length
          ? selectedFilters.route.values.map((item: any) => item.id)
          : undefined,
      stop:
        selectedFilters?.stop && selectedFilters.stop.values.length
          ? selectedFilters.stop.values.map((item: any) => item.id)
          : undefined,
      hidePending:
        selectedFilters?.hidePending &&
        selectedFilters.hidePending.values.length
          ? selectedFilters.hidePending.values[0].id === 'yes'
            ? true
            : selectedFilters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
      outboundLoadStatus:
        selectedFilters?.loadStatus &&
        selectedFilters?.loadStatus.values.length
          ? (selectedFilters.loadStatus?.values[0].id as
              | 'OPEN'
              | 'LOADING'
              | 'DISPATCHED')
          : undefined,
      defects:
        selectedFilters?.loadDefect &&
        selectedFilters.loadDefect.values.length
          ? selectedFilters.loadDefect.values.map(
              (item: any) => item.id,
            )
          : undefined,
      sortBy: sortedBy as '-load_time' | 'load_time' | undefined,
    });

    res.data.results.forEach((ol: any) => {
      ol.load_time = renderTimestampString(ol.load_time);
      ol.dockdoor_name = ol.dockdoor_name || common.emptyValue;
      ol.dispatched_time = renderTimestampString(ol.dispatched_time);
      ol.is_dispatched = ol.load_status === 'DISPATCHED' ? 1 : 0;
      ol.trailer_id = ol.trailer_id || common.emptyValue;
      ol.stops = ol.stops
        ? formatFirstNItemsAndMore(ol.stops, 1)
        : common.emptyValue;

      ol.load_status = fedexLabels.getLabel(
        fedexLabels.formatLabel(ol.load_status),
      );
    });

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    return res;
  };

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  //Add form fields
  const [capacity, setCapacity] = useState<string>('0');
  const [route, setRoute] = useState<TypeAheadItem>();
  const [loadName, setLoadName] = useState<string>();
  const [loadTime, setLoadTime] = useState<Date>();
  const [dockDoor, setDockDoor] = useState<TypeAheadItem>();
  const [isDockDoorSetByUser, setIsDockDoorSetByUser] =
    useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [trailerId, setTrailerId] = useState<string>();
  const [bol, setBol] = useState<string>();

  //Get available dock doors and routes -- run on first render
  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.OUTBOUNDLOADREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadCSV = async (outboundLoad: number) => {
    setShowProgress(true);
    try {
      const res = await OutboundLoadsService.getCSVExport({
        id: outboundLoad,
      });

      const blob = new Blob([res.data], {
        type: 'text/csv;charset=utf-8',
      });

      saveAs(blob, `outbound_load_export_for_id_${outboundLoad}.csv`);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setLoadTime(undefined);
    setDockDoor(undefined);
    setBol(undefined);
    setCapacity('0');
    setError(undefined);
  };

  const addLoadHandler = async () => {
    setShowProgress(true);
    try {
      if (route) {
        let dd;

        const loadTimeVal = !loadTime ? getSystemDateNow() : loadTime;
        dockDoor?.value === '0'
          ? (dd = undefined)
          : (dd = Number(dockDoor?.value));
        await OutboundLoadsService.create(
          parseInt(route.value!),
          loadTimeVal,
          dd,
          Number(capacity) || 0,
          loadName,
          trailerId || null,
          bol,
        );
        setLastUpdated(Date.now());
        onAfterDialogClose();
        setShowProgress(false);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getSystemDateNow = () => {
    const systemDate = LocalStorageService.getSystemDate();
    const systemDateNow: Date = new Date(Date.now());
    systemDateNow.setDate(systemDate.getDate());
    systemDateNow.setMonth(systemDate.getMonth());
    systemDateNow.setFullYear(systemDate.getFullYear());
    return systemDateNow;
  };

  const loadRouteOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      RoutesService.getAll({ search: inputValue })
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Route) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
                routeData: dataEl,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogContent>
          <DialogTitle
            style={{ padding: inXsScreen ? '16px 0px' : '16px 24px' }}
          >
            Add a new{' '}
            {configurationUtils.getPageTitle(true, 'OUTBOUND_LOAD')}
          </DialogTitle>

          {error && (
            <AlertBanner
              data-testid='outbound-loads-error-banner'
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <div
            style={{
              padding: inXsScreen ? '10px 0px' : '10px 24px',
            }}
          >
            <FormControl
              className={classes.formControl}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: '0px 10px 10px 0px',
              }}
              data-testid='select-route'
            >
              <InputLabel
                className={classes.selectLabel}
                style={{ fontSize: 12, top: '-18px' }}
              >
                {`Select ${configurationUtils.getPageTitle(
                  true,
                  'ROUTE',
                )}`}
              </InputLabel>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                styles={asyncStyles}
                loadOptions={loadRouteOptions}
                onChange={(value) => {
                  if (value) {
                    const valuObj = value as TypeAheadItem & {
                      routeData: any;
                    };
                    setRoute(valuObj);
                    setLoadName(
                      valuObj.label ? 'OL-' + valuObj.label : '',
                    );
                    //Set default dockdoor
                    if (!isDockDoorSetByUser) {
                      if (
                        //Check if selected route has default dock door
                        valuObj.routeData.default_dockdoor &&
                        valuObj.routeData.default_dockdoor_name
                      ) {
                        setDockDoor({
                          label:
                            valuObj.routeData.default_dockdoor_name,
                          value: valuObj.routeData.default_dockdoor,
                        });
                      } else {
                        //Route without dockdoor, reset the dock door selection
                        setDockDoor(undefined);
                      }
                    }
                  }
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                value={route}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: inXsScreen ? '0 0 10px 0' : '0px 0px 10px',
              }}
              data-testid='trailer-id'
            >
              <TextField
                label={'Trailer ID'}
                type='text'
                value={trailerId}
                onChange={(e) => {
                  setTrailerId(e.target.value.trim());
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
                inputProps={{ min: '0' }}
              />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: '0px 10px 10px 0px',
              }}
            >
              <TextField
                data-testid='outbound-load-name'
                label='Name'
                type='text'
                value={loadName}
                onChange={(e) => {
                  setLoadName(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() === '')
                    setLoadName(
                      route?.label ? 'OL-' + route?.label : '',
                    );
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
                inputProps={{ min: '0' }}
              />
            </FormControl>

            <FormControl
              className={`${classes.formControl} ${classes.datePicker}`}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: '0px 0px 10px',
                height: 50,
              }}
              data-testid='load-dispatch-time-picker'
            >
              <DateOrTimePicker
                dateTime
                label={'Dispatch time'}
                variant={'inline'}
                date={getSystemDateNow()}
                triggerEl={null}
                onAfterChange={(date) => {
                  date ? setLoadTime(date) : setLoadTime(undefined);
                }}
                id={'date-picker-new-load'}
              />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: '0px 10px 10px 0px',
                height: 50,
              }}
              data-testid='select-dock-door'
            >
              <InputLabel
                className={classes.selectLabel}
                style={{ fontSize: 12, top: '-18px' }}
              >
                {configurationUtils.isModuleActive('DOCK_DOOR')
                  ? configurationUtils.getPageTitle(true, 'DOCK_DOOR')
                  : 'Destination'}
              </InputLabel>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                styles={asyncStyles}
                loadOptions={loadDDOptions}
                onChange={(option) => {
                  setDockDoor(option as TypeAheadItem);
                  setIsDockDoorSetByUser(Boolean(option));
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                value={dockDoor}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{
                width: inXsScreen ? '100%' : '49%',
                margin: '0',
              }}
            >
              <TextField
                data-testid='load-capacity'
                label='Capacity'
                type='number'
                value={capacity}
                onChange={(e) => {
                  setCapacity(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                  endAdornment: (
                    <InputAdornment
                      style={{ marginRight: 5 }}
                      position='end'
                    >
                      {STRING_UNITS_VOLUME}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ min: '0' }}
              />
            </FormControl>

            {isBOLActive && (
              <FormControl
                className={classes.formControl}
                style={{
                  width: inXsScreen ? '100%' : '49%',
                  margin: '0',
                }}
                data-testid='add-load-bol'
              >
                <TextField
                  label='BOL'
                  value={bol}
                  onChange={(e) => {
                    setBol(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputPadding,
                  }}
                  inputProps={{ min: '0' }}
                />
              </FormControl>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <MuiButton
            data-testid={'outbound-loads-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              addLoadHandler();
            }}
            data-testid={'outbound-loads-dialog-save'}
            disabled={!route || capacity?.includes('-')}
          >
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
    ${configurationUtils.getPageTitle(false, 'OUTBOUND_LOAD')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='OUTBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <ExportCSVDialog
          downloadName={'outbound_load_export'}
          key={'dialog' + lastUpdated}
          type='OUTBOUND_LOADS'
          isOpen={showDialog}
          onAfterClose={() => {
            setShowDialog(false);
          }}
        />

        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />

        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.OUTBOUNDLOADWRITE,
            ) && (
              <MuiButton
                disabled={!!error}
                variant='outlined'
                className={classes.containedButton}
                data-testid={'load-add-button'}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);

                  setLoadName(
                    route?.label ? 'OL-' + route?.label : '',
                  );
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add{' '}
                {configurationUtils.getPageTitle(
                  true,
                  'OUTBOUND_LOAD',
                )}
              </MuiButton>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.OUTBOUNDLOADREPORTREAD,
            ) && (
              <MuiButton
                variant='outlined'
                className={classes.outlinedButton}
                style={{ marginLeft: 15 }}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Batch Export CSVs
              </MuiButton>
            )}
            <MuiButton
              variant='outlined'
              className={clx(
                classes.outlinedButton,
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </MuiButton>
          </Grid>
          <Grid item xs={12}>
            {selectedFilters && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}
                {Object.values(selectedFilters).map((item: any) =>
                  item.values.length ? (
                    <Box
                      key={item.key}
                      className={classes.filterBadge}
                    >
                      <b>{item.label}</b>
                      {item.values.map((value: any) => (
                        <span
                          style={{ marginLeft: '5px' }}
                          key={`${value.name || value.full_name}-${
                            value.id
                          }`}
                        >
                          {value.name || value.full_name}
                          <MuiButton
                            className={classes.filterBadgeButton}
                            onClick={() => {
                              setFilterToRemove({
                                filterKey: item.key,
                                filterValue: value.id,
                              });
                            }}
                          >
                            <ClearIcon />
                          </MuiButton>
                        </span>
                      ))}
                    </Box>
                  ) : undefined,
                )}
              </>
            )}
          </Grid>
        </Grid>

        {PermissionsService.hasPermission(
          PermissionsPermissionsEnum.OUTBOUNDLOADREAD,
        ) &&
          !!lastUpdated && (
            <PaginatedTable
              key={'table' + lastUpdated}
              tableKey={'table' + lastUpdated}
              title=''
              columns={COLUMNS_OUTBOUND_LOADS}
              dataTestIdPrefix={'outbound-loads'}
              fetch={fetchOutboundLoads}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.OUTBOUND_LOAD}
              defaultSort={
                (queryString.parse(location.search)[
                  'sortBy'
                ] as string) || undefined
              }
              sortableBy={['load_time']}
              actions={[
                {
                  tableLabel: ' ',
                  columnLabel: <>Export CSV</>,
                  qualifier: 'is_dispatched',
                  callback: downloadCSV,
                },
              ]}
            />
          )}
      </Layout>
    </>
  );
};

export default withStyles(styles)(OutboundLoads);
