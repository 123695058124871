import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';
import enumToLabel from '../../../../utils/enumToLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Switch,
  Hidden,
  Theme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  label: '',
  key: null,
};

const ScanModules: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [modulesData, setModulesData] = useState<any[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Scan Modes';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(
    function setModuleData() {
      if (data && currentView !== CurrentView.SEARCH) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          if (item[0] === 'DEFAULT') continue;
          const itemData = item[1] as {
            label: string;
            active: boolean;
          };
          tmpData.push(itemData);
        }
        setModulesData(tmpData);
      }
    },
    [data, currentView],
  );

  useEffect(
    function filterModuleDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          if (item[0] === 'DEFAULT') continue;
          const itemData = item[1] as {
            label: string;
            active: boolean;
          };
          if (
            showWholeSection ||
            searchResult.some(
              (result) =>
                enumToLabel(item[0])
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                itemData.label
                  .toLowerCase()
                  .includes(result.toLowerCase()),
            )
          )
            tmpData.push(itemData);
        }
        setModulesData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let item of Object.entries(data)) {
          if (item[0] === 'DEFAULT') continue;
          const data = item[1] as {
            label: string;
            active: boolean;
          };
          dynamicLabels.push(enumToLabel(item[0]));
          dynamicLabels.push(data.label);
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);

        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const getElKey = (obj: any) => {
    const el = Object.entries(data).find(
      ([key, value]: any) => value === obj,
    );
    return el?.length ? el[0] : '';
  };

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid={`scan-modules-dialog-${dialogData.label}`}
            autoFocus
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.label}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                label: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='scan-modules-dialog-save-btn'
            onClick={() => {
              data[dialogData.key].label = dialogData.label;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Item
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={7}>
                      Label
                    </Grid>
                  </Hidden>
                  <Grid item sm={1} xs={2} />
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                  <Grid item sm={1} xs={3}>
                    Default
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              {modulesData?.map(
                (partialDataEl: any, index: number, array: any[]) => {
                  const isDefault =
                    getElKey(partialDataEl) === data.DEFAULT;
                  return (
                    <Grid
                      container
                      item
                      sm={12}
                      key={partialDataEl.label}
                      className={classes.row}
                    >
                      <Grid
                        item
                        sm={2}
                        xs={4}
                        className={classNames(
                          classes.text,
                          classes.pLeft,
                        )}
                      >
                        <Highlighter
                          highlightClassName={
                            classes.searchResultsMark
                          }
                          searchWords={searchResult}
                          autoEscape={true}
                          textToHighlight={enumToLabel(
                            getElKey(partialDataEl),
                          )}
                        />
                      </Grid>
                      <Hidden xsDown>
                        <Grid
                          item
                          sm={7}
                          className={classes.text}
                          style={{ paddingRight: 10 }}
                        >
                          <Highlighter
                            highlightClassName={
                              classes.searchResultsMark
                            }
                            searchWords={searchResult}
                            autoEscape={true}
                            textToHighlight={partialDataEl.label}
                          />
                        </Grid>
                      </Hidden>
                      <Grid item sm={1} xs={2}>
                        <IconButton
                          data-testid={`scan-modules-${partialDataEl.label}-edit-btn`}
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          className={classes.editIcon}
                          onClick={() => {
                            setDialogData({
                              label: partialDataEl.label,
                              key: getElKey(partialDataEl),
                            });
                            setShowDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.muiSwitchHoler}
                      >
                        <Switch
                          data-testid={`scan-modules-${partialDataEl.label}-active-switch`}
                          color='default'
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          className={
                            partialDataEl.active
                              ? classes.muiSwitch
                              : classes.muiSwitchOff
                          }
                          checked={partialDataEl.active}
                          onChange={() => {
                            const elKey = getElKey(partialDataEl);
                            if (
                              elKey === data.DEFAULT &&
                              data[elKey].active
                            ) {
                              // toggle off default
                              data.DEFAULT = null;
                            }
                            data[elKey].active =
                              !partialDataEl.active;
                            setPartialData({ ...data });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.muiSwitchHoler}
                      >
                        <Switch
                          data-testid={`scan-modules-${partialDataEl.label}-default-switch`}
                          color='default'
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          className={
                            isDefault
                              ? classes.muiSwitch
                              : classes.muiSwitchOff
                          }
                          checked={isDefault}
                          onChange={() => {
                            const elKey = getElKey(partialDataEl);
                            if (!data[elKey].active) {
                              data[elKey].active = true;
                            }
                            data.DEFAULT =
                              data.DEFAULT === elKey ? null : elKey;
                            setPartialData({ ...data });
                          }}
                        />
                      </Grid>
                      {index !== array.length - 1 && (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              )}
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(ScanModules);
