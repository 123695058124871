import React, { useEffect, useState } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ContainerService from '../../services/Containers.service';
import Layout from '../layout/Layout';
import { AlertBanner } from '../primitives';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import browserHistory from '../../utils/browserHistory';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import PackagesOverview from './details/PackagesOverview';
import ContainersOverview from './details/ContainersOverview';
import AreaTotals from './details/AreaTotals';
import TodayPackagesChart from './details/TodayPackagesChart';
import { createStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PrintQrCodesDialog from '../printQrCodesDialog/PrintQrCodesDialog';
import configurationUtils from '../../utils/configurationUtils';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

// Icons
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Types
import {
  AreaDetails,
  AreaDetailsAreaTypeEnum,
  Container,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import AreasService from '../../services/Areas.service';
import PackagesPerformanceStats from '../packages/packagesStats/PackagesPerformanceStats';
import PermissionsService from '../../services/Permissions.service';

import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

enum DashbaordSections {
  PERFORMANCE_CHART = 'PERFORMANCE_CHART',
}

const AreasComponent: React.FC<Props> = ({ classes, match }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [nameToEdit, setNameToEdit] = useState<string | null>();
  const [areaData, setAreaData] = useState<AreaDetails>();
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [anchorMenuEl, setAnchorMenuEl] =
    useState<HTMLElement | null>();
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [msg, setMsg] = useState<String>('');

  const layoutConfig =
    EphemeralStateService.getMyStationConfiguratation()?.WEB?.LAYOUTS
      ?.DASHBOARD;

  const getAreaData = async () => {
    setShowProgress(true);
    try {
      const { data } = await AreasService.getById(match.params.id);
      setAreaData(data);
    } catch (e) {
      handleError(e);
    }
    setShowProgress(false);
  };

  const deleteAreaHandler = async () => {
    setShowProgress(true);
    try {
      if (areaData) {
        await AreasService.delete(areaData.id!);
        browserHistory.push(AuthRoutes.AREA);
      }
    } catch (e) {
      handleError(e);
      setShowProgress(false);
    }
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={typeof nameToEdit === 'string'}
        TransitionComponent={Transition}
        onClose={() => {
          setNameToEdit(null);
        }}
      >
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <FormControl
            className={classes.formControl}
            style={{ width: '100%', margin: '5px 0px' }}
          >
            <TextField
              autoFocus
              label='Name'
              type={'string'}
              defaultValue={nameToEdit}
              onChange={(e) => {
                setNameToEdit(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
              error={
                configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(nameToEdit || '')
              }
              helperText={
                configurationUtils.isModuleNameValidated('AREA')
                  ? 'A capital letter followed by up to 3 digits.'
                  : undefined
              }
              FormHelperTextProps={{ style: { paddingLeft: '5px' } }}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setNameToEdit(null);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !nameToEdit ||
              (configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(nameToEdit || ''))
            }
            onClick={() => {
              const areaDataObj: AreaDetails = { ...areaData };
              areaDataObj.name = nameToEdit;
              editArea(areaDataObj);
              setNameToEdit(null);
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const editArea = async (areaDataObj: AreaDetails) => {
    setShowProgress(true);
    try {
      if (areaData) {
        await AreasService.update(areaData.id!, areaDataObj);
        await getAreaData();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const checkContainers = async (area: AreaDetails | undefined) => {
    setShowProgress(true);

    try {
      const res = await ContainerService.getAll({
        area: area?.id,
      });

      let containersIds: string =
        res.data.results[0]?.id?.toString() || '';
      let containersCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        containersIds += ', ' + container.id;
        containersCount += containersCount;
      });

      let loadPoints: string =
        res.data.results[0]?.load_point?.name?.toString() || '';
      let loadPointCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        loadPoints += ', ' + container.load_point?.name;
        loadPointCount += loadPointCount;
      });

      const areaName = area?.name || area?.id;
      const simpleMsg = `Are you sure you want to delete ${configurationUtils.getPageTitle(
        true,
        'AREA',
      )} ${areaName}?`;

      res.data.count === 0
        ? setMsg(simpleMsg)
        : setMsg(`${
            containersCount > 1 ? 'Containers' : 'Container'
          } ${containersIds} ${
            containersCount > 1 ? 'are' : 'is'
          } still on ${
            loadPointCount > 1 ? 'Loadpoints' : 'Loadpoint'
          } ${loadPoints}. After deleting the ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )} ${areaName}, the ${
            containersCount > 1 ? 'containers have' : 'container has'
          } to be manually moved (use Troubleshooting Mode in the Operator Tool) to the new loadpoint.
          ${simpleMsg}`);

      setShowDeleteDialog(true);
    } catch (e) {
      handleError(e);
    }
    setShowProgress(false);
  };

  const handleMenuClose = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorMenuEl(null);
  };

  useEffect(() => {
    if (!areaData) {
      getAreaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )} Details for ${areaData?.name || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        {areaData && (
          <PrintQrCodesDialog
            areaId={areaData.id}
            downloadName={`${areaData.name}_area_labels`}
            isOpen={openQrDialog}
            onAfterClose={() => {
              setOpenQrDialog(false);
            }}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix={'area-details-delete-dialog'}
          title={`Delete ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )}`}
          msg={msg}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteAreaHandler}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        {error && (
          <AlertBanner
            data-testid='areas-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
          data-testid='areas-detail-view'
        >
          <Grid item container xs={12} sm={6} wrap='nowrap'>
            <Box p={0} mb={2} mr={0.5} mt={1}>
              {PermissionsService.hasPermission(
                PermissionsPermissionsEnum.STATIONWRITE,
              ) && (
                <IconButton
                  data-testid='area:options:menu'
                  style={{
                    alignSelf: 'center',
                    padding: 5,
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement>,
                  ) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnchorMenuEl(e.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={anchorMenuEl}
                open={!!anchorMenuEl}
                onClose={handleMenuClose}
                disableScrollLock={true}
              >
                <MenuItem
                  onClick={(e) => {
                    setNameToEdit(areaData?.name);
                    handleMenuClose(e);
                  }}
                >
                  Edit Name
                </MenuItem>
                <MenuItem
                  data-testid='delete-area'
                  onClick={(e) => {
                    checkContainers(areaData);
                    handleMenuClose(e);
                  }}
                >
                  Delete Area
                </MenuItem>
              </Menu>
            </Box>
            <Typography
              className={classes.title}
              style={{ marginBottom: 16 }}
            >
              {areaData
                ? `${configurationUtils.getPageTitle(
                    true,
                    'AREA',
                  )} Details for ${areaData?.name || ''}`
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              className={classes.outlinedButton}
              disabled={!areaData}
              style={{ margin: '0 0 8px 8px' }}
              onClick={(e) => {
                e.preventDefault();
                setOpenQrDialog(true);
              }}
            >
              <FilterCenterFocusIcon style={{ marginRight: 10 }} />
              QR Codes
            </Button>
            {areaData?.area_type !==
              AreaDetailsAreaTypeEnum.STAGING && (
              <Button
                data-testid='go-to-area-settings-button'
                variant='contained'
                className={classes.containedButton}
                disabled={!areaData}
                style={{ margin: '0 0 8px 8px' }}
                onClick={(e) => {
                  e.preventDefault();
                  browserHistory.push(
                    `${match.params.id}/settings/${areaData?.area_type}`,
                  );
                }}
              >
                <SettingsSharpIcon style={{ marginRight: 10 }} />
                Settings
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid
            item
            sm={AreaDetailsAreaTypeEnum.SECONDARY ? 8 : 12}
            xs={12}
          >
            <Typography
              className={classes.boldNameParam}
              style={{ paddingBottom: 16 }}
            >
              {configurationUtils.getPageTitle(false, 'PACKAGE')}{' '}
              Overview
            </Typography>
            {areaData && (
              <PackagesOverview
                areaId={areaData.id}
                areaType={areaData.area_type}
              />
            )}
          </Grid>
          {areaData?.area_type ===
            AreaDetailsAreaTypeEnum.SECONDARY && (
            <Grid item sm={4} xs={12}>
              <Typography
                className={classes.boldNameParam}
                style={{ paddingBottom: 16 }}
              >
                {configurationUtils.getPageTitle(false, 'CONTAINER')}{' '}
                Overview
              </Typography>
              <ContainersOverview areaId={match.params.id} />
            </Grid>
          )}
        </Grid>
        {layoutConfig?.TODAY_CHART?.active && (
          <>
            <Typography
              className={classes.boldNameParam}
              style={{ paddingBottom: 16 }}
            >
              Today{' '}
              {configurationUtils.getPageTitle(false, 'PACKAGE')}
            </Typography>
            <Paper
              className={classes.paper}
              style={{
                paddingBottom: 15,
                minHeight: 265,
                marginBottom: 25,
              }}
            >
              {areaData && (
                <TodayPackagesChart
                  areaId={match.params.id}
                  areaType={areaData.area_type}
                />
              )}
            </Paper>
          </>
        )}
        {layoutConfig[DashbaordSections.PERFORMANCE_CHART]
          ?.active && (
          <div style={{ marginBottom: 25 }}>
            <PackagesPerformanceStats
              title={`${configurationUtils.getPageTitle(
                true,
                'AREA',
              )} Performance`}
              areaId={match.params.id}
            />
          </div>
        )}
        <Typography
          className={classes.boldNameParam}
          style={{ paddingBottom: 16 }}
        >
          {configurationUtils.getPageTitle(true, 'AREA')} Totals
        </Typography>
        {areaData && (
          <AreaTotals
            areaId={match.params.id}
            areaType={areaData.area_type}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    detailInfoButton: {
      margin: '-50px 0 0 auto',
      '& svg': {
        marginLeft: 5,
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      minWidth: 300,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    muiSelect: {
      paddingLeft: 5,
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })),
)(AreasComponent);
