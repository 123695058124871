import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
} from 'react';
import {
  AuthRoutes,
  NonAuthRoutes,
  resetDynamicRoutes,
} from '../../interfaces/routes';
import { TokenObtainPairRequestSwagger } from 'cloudsort-client';
import {
  AlertBanner,
  Button,
  Checkbox,
  Typography,
  Container,
  CustomTextField,
} from '../primitives';
import { Link } from 'react-router-dom';

import styles from './logIn.styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import browserHistory from '../../utils/browserHistory';
import { RouterManualUpdateContext } from '../router/Router';

// Services
import Auth from '../../services/Auth.service';
import LocalStorageService from '../../services/LocalStorage.service';

// Utils
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import validateEmail from '../../utils/validateEmail';
import { Paper } from '@material-ui/core';

//Icons
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Helmet } from 'react-helmet';

interface Props {
  match: any;
  classes: { [key: string]: string };
}

const LogIn: React.FC<Props> = ({ match, classes }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [authState, setAuthState] =
    useState<TokenObtainPairRequestSwagger>({
      email: '',
      password: '',
      device_id: 'web',
    });
  const [alertMessage, setAlertMessage] = useState<string>();
  const [logInError, setLogInError] = useState<string>('');
  const [passwordFieldType, setPasswordFieldType] =
    useState<string>('password');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const routerManualUpdate = useContext(RouterManualUpdateContext);
  const afterLoginUrl = LocalStorageService.getAfterLoginUrl();

  const canSubmitForm =
    authState.password &&
    authState.email &&
    validateEmail(authState.email) &&
    !showProgress;

  const setStationData = async () => {
    try {
      return globalStationOptionsUtils.setStationData();
    } catch (e) {
      setLogInError(await ErrorHandler.getLabel(e));
    }
  };

  const onLogInClick = async () => {
    setShowProgress(true);
    try {
      Auth.setRememberMe(rememberMe);
      await Auth.logIn(authState);
      const stationData = await setStationData();
      if (stationData) {
        resetDynamicRoutes();
        routerManualUpdate();
        setTimeout(() => {
          if (afterLoginUrl) {
            window.location.replace(
              String(LocalStorageService.getAfterLoginUrl()),
            );
            LocalStorageService.removeAfterLoginUrl();
          } else {
            browserHistory.push(AuthRoutes.DASHBOARD);
          }
        }, 0);
      } else {
        // Log out if user has no stations data
        Auth.logOut({});
        setLogInError(
          `You don't have sufficient permissions to log in to the web app.`,
        );
        setShowProgress(false);
      }
    } catch (e) {
      setLogInError(await ErrorHandler.getLabel(e));
      setShowProgress(false);
    }
  };

  useEffect(() => {
    if ((match.params as any).alertMessage === 'password-set')
      setAlertMessage('Your password has been set.');
    else if (
      (match.params as any).alertMessage ===
      'password-reset-requested'
    )
      setAlertMessage(
        `If you have an account, we'll email you a password reset link`,
      );
    else if ((match.params as any).alertMessage === 'link-resent')
      setAlertMessage(
        `We've just sent you a new link for setting your password. Please check your Inbox.`,
      );
    if ((match.params as any).alertMessage === 'email-updated')
      setAlertMessage(
        `Your email has been updated. Use your new email to log in.`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const setStationDataAndRedirectToDashboard = async () => {
    const stationData = await setStationData();
    if (stationData) {
      resetDynamicRoutes();
      routerManualUpdate();
      setTimeout(() => {
        browserHistory.push(AuthRoutes.DASHBOARD);
      }, 0);
    }
  };

  useEffect(() => {
    if (Auth.isLoggedIn() && !afterLoginUrl) {
      setStationDataAndRedirectToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`CloudSort - Login`}</title>
      </Helmet>
      <div
        className={classes.halfWidth + ' ' + classes.loginBackground}
      >
        <img
          className={classes.loginLogo}
          alt='CloudSort'
          src={`${
            process.env.REACT_APP_BASENAME || ''
          }/loginAssets/logo.svg`}
        />
      </div>
      <div
        className={classes.halfWidth}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showProgress && (
          <ProgressIndicator data-testid='progress-indicator-component' />
        )}
        <Container
          component='main'
          maxWidth='xs'
          data-testid='login-component'
          style={{
            marginLeft: '20px',
          }}
        >
          <div className={classes.paper}>
            <Typography variant='h2' className={classes.loginHeading}>
              Sign in to CloudSort
            </Typography>
            {alertMessage && (
              <Paper className={classes.alertBox}>
                <CheckCircleOutlineOutlinedIcon />
                <p>{alertMessage}</p>
              </Paper>
            )}
            <form className={classes.form} noValidate>
              <CustomTextField
                inputProps={{
                  'data-testid': 'login-email',
                }}
                required
                label='E-mail address'
                name='email'
                autoComplete='email'
                autoFocus
                value={authState.email}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  if (logInError) {
                    setLogInError('');
                  }
                  const { name, value } = e.target;
                  setAuthState((prevState) => {
                    return {
                      ...prevState,
                      [name]: value,
                    };
                  });
                }}
                validatorFunction={validateEmail}
                validatorError={`The email address you've entered doesn't seem right. Please check spelling.`}
              />
              <CustomTextField
                inputProps={{
                  'data-testid': 'login-password',
                }}
                buttonProps={{
                  tabIndex: 1,
                }}
                required
                name='password'
                label='Password'
                type={passwordFieldType}
                autoComplete='current-password'
                value={authState.password}
                labelButton={
                  passwordFieldType === 'text' ? 'Hide' : 'Show'
                }
                labelButtonAction={() => {
                  passwordFieldType === 'text'
                    ? setPasswordFieldType('password')
                    : setPasswordFieldType('text');
                }}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  if (logInError) {
                    setLogInError('');
                  }
                  const { name, value } = e.target;
                  setAuthState((prevState) => {
                    return {
                      ...prevState,
                      [name]: value,
                    };
                  });
                }}
              />
              <Link
                to={NonAuthRoutes.RESET_PASSWORD}
                className={classes.forgotPasswordLink}
              >
                Forgot your password?
              </Link>
              <br />
              <br />
              <Checkbox
                data-testid='login-remember-me'
                checked={rememberMe}
                onChange={(e) => {
                  e.preventDefault();
                  setRememberMe(!rememberMe);
                }}
                value='rememberMe'
                label={'Remember me'}
              />
              {logInError && (
                <AlertBanner
                  severity='error'
                  data-testid='login-error'
                  alertMsg={logInError}
                  alertTitle={'Error'}
                />
              )}
              <br />
              <Button
                data-testid='login-button'
                disabled={!canSubmitForm}
                className={classes.submitButton}
                onClick={(e) => {
                  e.preventDefault();
                  onLogInClick();
                }}
              >
                Sign In
              </Button>
              {canSubmitForm && (
                <div className={classes.pressEnter}>
                  or press enter <KeyboardReturnIcon />
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(LogIn);
