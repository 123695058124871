import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '../primitives';
import colors from '../../utils/colors';
import { withStyles } from '@material-ui/core/styles';

import { DialogTitle, createStyles, Theme } from '@material-ui/core';

import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  icon?: JSX.Element;
  title?: string;
  description: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
}

const SelectPresetDialog: React.FC<Props> = ({
  classes,
  isOpen,
  icon,
  title,
  description,
  primaryActionLabel,
  secondaryActionLabel,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  return (
    <Dialog open={isOpen} data-testid='actions-dialog'>
      <DialogTitle
        style={{ textAlign: 'center', padding: '40px 0 20px 0' }}
      >
        {icon && (
          <>
            {icon}
            <br />
          </>
        )}

        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {description && <Typography>{description}</Typography>}
      </DialogContent>
      <DialogActions>
        {secondaryActionLabel && (
          <Button
            variant='outlined'
            className={classes.containedButton}
            onClick={() => {
              onSecondaryAction && onSecondaryAction();
            }}
          >
            {secondaryActionLabel}
          </Button>
        )}
        {primaryActionLabel && (
          <Button
            variant='outlined'
            className={classes.buttonFilled}
            onClick={() => {
              onPrimaryAction && onPrimaryAction();
            }}
          >
            {primaryActionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    dialogContent: {
      width: '520px',
      maxWidth: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      marginBottom: '20px',
    },
    button: {
      color: colors.darkGold,
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',

      '&:hover': {
        color: colors.darkGold,
        background: 'transparent',
      },
    },
  })),
)(SelectPresetDialog);
