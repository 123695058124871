import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './stationPlanner.styles';
import Layout from '../layout/Layout';
import { AlertBanner, Typography } from '../primitives/index';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';
import queryString from 'query-string';
import browserHistory from '../../utils/browserHistory';
import { Helmet } from 'react-helmet';
import VolumeOfPackages from './VolumeOfPackages';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EditStationPlanner from './editStationPlannerDialog/EditStationPlanner';
import AddEditShiftRowDialog from './editShiftRowDiialog/AddEditShiftRowDialog';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';
import moment from 'moment';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import SelectPresetDialog from './selectPresetDialog/SelectPresetDialog';
import ActionsDialog from '../actionsDialog/ActionsDialog';
import AddEditPresetDialog from './addEditPresetDialog/AddEditPresetDialog';
import colors from '../../utils/colors';

// Types
import {
  PermissionsPermissionsEnum,
  ShiftPlan,
  ShiftPlanPreset,
  ShiftPlanShiftPlanTypeEnum,
} from 'cloudsort-client';

// Icons
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

//Services
import StationPlannerService from '../../services/ShiftPlanner.service';
import PermissionsService from '../../services/Permissions.service';
import ShiftPlannerPresetsService from '../../services/ShiftPlannerPresets.service';
import { cloneDeep } from 'lodash';

export enum DialogInteraction {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

enum ActiveDialog {
  NONE = 'READ',
  ADD_EDIT_SORT = 'ADD_EDIT_SORT',
  NO_DATA = 'NO_DATA',
  NO_DATA_PAST = 'NO_DATA_PAST',
  SELECT_PRESET = 'SELECT_PRESET',
  UPDATE_PRESET_CONFIRM = 'UPDATE_PRESET_CONFIRM',
  ADD_PRESET_SUCCESS = 'ADD_PRESET_SUCCESS',
  ADD_PRESET = 'ADD_PRESET',
  PACKAGES_PER_CONTAINER = 'PACKAGES_PER_CONTAINER',
  PAGE_LEAVE = 'PAGE_LEAVE',
}

const defaultAddPresetDialogDialogFields = {
  name: '',
  recurrence: 'none',
  recurrenceDays: '',
};

interface Props {
  classes: { [key: string]: string };
  location: any;
}

export const calculateShiftLength = (
  startTime: string,
  endTime: string,
) => {
  return (
    (new Date(endTime).getTime() - new Date(startTime).getTime()) /
    3600000
  ); // milliseconds to hours
};

export const onlyNumbers = (value: string) => {
  const parsed = parseInt(value.replace('/[^]/g', ''));
  return isNaN(parsed) ? '' : parsed.toString();
};

const StationPlanner: React.FC<Props> = ({ classes, location }) => {
  const baseQuery = queryString.parse(window.location.search);

  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [activeDialog, setActiveDialog] = useState<ActiveDialog>(
    ActiveDialog.NONE,
  );

  const [addPresetDialogFields, setAddPresetDialogFields] = useState<{
    [name: string]: string;
  }>(defaultAddPresetDialogDialogFields);

  const [addPresetErrorContext, setAddPresetErrorContext] =
    useState<any>();

  const [forcePresetSave, setForcePresetSave] =
    useState<boolean>(false);

  const [sortDialogInteraction, setSortDialogInteraction] =
    useState<DialogInteraction>(DialogInteraction.ADD);

  const [loadedPlanType, setLoadedPlanType] =
    useState<ShiftPlanShiftPlanTypeEnum>();

  const [availableShiftPlanPresets, setAvailableShiftitPlanPresets] =
    useState<ShiftPlanPreset[]>([]);

  const [editDialogFields, setEditDialogFields] = useState<{
    [name: string]: string;
  }>({});

  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [editRowDialogFields, setEditRowDialogFields] = useState<{
    [name: string]: string;
  }>({});

  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [chartLastUpdated, setChartLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const [shiftPlanData, setShiftPlanData] = useState<
    ShiftPlan | ShiftPlanPreset
  >();

  const [savedShiftPlanData, setSavedShiftPlanData] = useState<
    ShiftPlan | ShiftPlanPreset
  >();

  const [presetUsedId, setPresetUsedId] = useState<number>();
  const [presetUsedName, setPresetUsedName] = useState<string>();
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<boolean>(false);

  const [datePickerOpen, setDatePickerOpen] =
    useState<boolean>(false);
  const [datePickerChangeAttempt, setDatePickerChangeAttempt] =
    useState<boolean>(false);

  const [datePickerSelection, setDatePickerSelection] =
    useState<Date>(
      baseQuery.date
        ? new Date(baseQuery.date as string)
        : new Date(),
    );

  const [dateIsPast, setDateIsPast] = useState<boolean>(
    baseQuery.date
      ? moment(baseQuery.date).isBefore(moment(), 'day')
      : false,
  );

  //Save menu
  const [saveMenuAnchorEl, setSaveMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const staffLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'STAFF'),
      plural: configurationUtils.getPageTitle(false, 'STAFF'),
    };
  }, []);

  const COLUMNS_LEFT: Column[] = [
    {
      id: 'id',
      label: '',
      width: 'auto',
      align: 'left',
      hide: true,
    },
    {
      id: 'name',
      label: 'Sort',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'start_time_formatted',
      label: 'Start Time',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'end_time_formatted',
      label: 'End Time',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'sort_length',
      label: 'Sort Length (Hours)',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'staff_direct',
      label: `${staffLabels.plural} - Direct`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'staff_support',
      label: `${staffLabels.plural} - Support`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'seconds_per_package',
      label: `Seconds Per ${packageLabels.singular}`,
      width: 'auto',
      align: 'left',
    },
  ];

  const COLUMNS_RIGHT: Column[] = [
    {
      id: 'sort_capacity',
      label: 'Sort Capacity',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'sort_capacity_hour',
      label: 'Sort Capacity Per Hour',
      width: 'auto',
      align: 'left',
    },
  ];

  const fetchTableData = async () => {
    let shifts: any[] = [];

    if (shiftPlanData?.shifts) {
      shifts = shiftPlanData.shifts.map((shift: any, index) => {
        const shiftLength = calculateShiftLength(
          shift.start_time,
          shift.end_time,
        );

        const sortCapacty =
          shift.staff_direct + shift.staff_support && shiftLength
            ? (shiftLength *
                3600 *
                (shift.staff_direct + shift.staff_support)) /
              shift.seconds_per_package
            : 0; // when there are no staff sort capacity should be 0

        return {
          ...shift,
          id: index + 1,
          start_time_formatted: moment(shift.start_time).format(
            'hh:mm A',
          ),
          end_time_formatted: moment(shift.end_time).format(
            'hh:mm A',
          ),
          sort_length: shiftLength.toFixed(1),
          sort_capacity: sortCapacty.toFixed(1),
          sort_capacity_hour: shiftLength
            ? (sortCapacty / shiftLength).toFixed(1)
            : '0.0',
        };
      });
    }

    return {
      data: {
        count: shifts.length,
        results: shifts,
      },
    };
  };

  const [lastLocation, setLastLocation] = useState<Location | null>(
    null,
  );
  const [leavePageconfirmed, setLeavePageConfirmed] =
    useState<boolean>(false);

  const handleBlockedNavigation = (
    nextLocation: Location,
  ): boolean => {
    if (hasUnsavedChanges && !leavePageconfirmed) {
      setLastLocation(nextLocation);
      setActiveDialog(ActiveDialog.PAGE_LEAVE);

      return false;
    }
    return true;
  };

  const confirmPageLeave = () => {
    if (datePickerChangeAttempt) {
      savedShiftPlanData && setData(savedShiftPlanData, true);
      setActiveDialog(ActiveDialog.NONE);
      setDatePickerOpen(true);
    } else {
      // Navigate to the previous blocked location with your navigate function
      setLeavePageConfirmed(true);
      setTimeout(() => {
        lastLocation && browserHistory.push(lastLocation.pathname);
      }, 0);
    }
  };

  const setData = (
    data: ShiftPlan | ShiftPlanPreset | undefined,
    setSaved: boolean = false,
    skipSettingPresetName: boolean = false,
    availablePresets = availableShiftPlanPresets,
  ) => {
    setShiftPlanData(data);

    // set plan type and preset name
    const planType = data?.shift_plan_type;
    if (planType) {
      setLoadedPlanType(planType as ShiftPlanShiftPlanTypeEnum);
    }
    if (
      data &&
      planType === ShiftPlanShiftPlanTypeEnum.PRESET &&
      !skipSettingPresetName
    ) {
      if ((data as ShiftPlan).preset) {
        setPresetUsedId((data as ShiftPlan).preset!);
        setPresetUsedName((data as ShiftPlan)?.name || '');
      } else {
        setPresetUsedId(data.id);
        setPresetUsedName(
          availablePresets.find((preset) => preset.id === data.id)
            ?.name || '',
        );
      }
    } else if (!skipSettingPresetName) {
      setPresetUsedId(undefined);
      setPresetUsedName(undefined);
    }

    const now = new Date().toISOString();

    if (data)
      setEditDialogFields({
        packages: data?.packages_per_container.toString() || '0', // 0 is the minimum number of packages per container.
      });

    setLastUpdated(now);
    setShowProgress(false);

    if (setSaved) {
      setChartLastUpdated(now);
      setSavedShiftPlanData(data);
    }

    setHasUnsavedChanges(!setSaved);
  };

  const isUsingReccurentPresent = (data: ShiftPlan): boolean => {
    return (
      data.shift_plan_type === ShiftPlanShiftPlanTypeEnum.PRESET &&
      !data.preset
    );
  };

  const isUsingManuallyAppliedPresent = (
    data: ShiftPlan,
  ): boolean => {
    return (
      data.shift_plan_type === ShiftPlanShiftPlanTypeEnum.PRESET &&
      !!data.preset
    );
  };

  const fetchAvailablePresets = async () => {
    const presets = (
      await ShiftPlannerPresetsService.getAll({
        page: 1,
        pageSize: MAX_PAGE_SIZE,
      })
    ).data.results;

    setAvailableShiftitPlanPresets(presets);

    return presets;
  };

  const fetchShiftPlan = async (date?: Date) => {
    setShowProgress(true);
    const selectedDate = date || datePickerSelection;

    //get available presets
    const presets = await fetchAvailablePresets();

    try {
      const shiftPlan = await StationPlannerService.getShiftByDate(
        selectedDate,
      );
      if (shiftPlan.data.count) {
        setData(shiftPlan.data.results[0], true, false, presets);
      } else {
        // we don't have shift plan for a date, check if there are available presets
        setData(undefined, true);
        if (!dateIsPast) {
          if (presets.length) {
            //show dialog to select preset or start from scratch
            setActiveDialog(ActiveDialog.NO_DATA);
          } else {
            createNewShiftPlan(selectedDate);
          }
        } else {
          setActiveDialog(ActiveDialog.NO_DATA_PAST);
        }
      }
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    }

    setShowProgress(false);
  };

  const createNewShiftPlan = async (
    selectedDate: Date,
    presetId?: number,
    data?: ShiftPlan,
  ) => {
    try {
      const res = await StationPlannerService.createShiftPlan(
        selectedDate,
        presetId,
        data,
      );

      setData(res.data, true);
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    }
  };

  const saveShiftPlan = async () => {
    const data = cloneDeep(shiftPlanData) as ShiftPlan;

    if (isUsingReccurentPresent(data)) {
      // convert preset into a shift plan

      data.preset = null;
      data.shift_plan_type = ShiftPlanShiftPlanTypeEnum.DEFAULT;
      data.id = undefined;

      // create new shift plan with data
      createNewShiftPlan(datePickerSelection, undefined, data);
    } else if (shiftPlanData?.id) {
      //update existing shift plan

      if (isUsingManuallyAppliedPresent(data)) {
        data.preset = null;
        data.shift_plan_type = ShiftPlanShiftPlanTypeEnum.DEFAULT;
      }

      try {
        const res = await StationPlannerService.updateShiftPlan(
          shiftPlanData.id,
          {
            ...data,
            shift_plan_type: ShiftPlanShiftPlanTypeEnum.DEFAULT,
            name: '',
          },
        );
        setData(res.data, true);
      } catch (error) {
        setError(error.response.data.descriptions.join(', '));
      }
    }
  };

  const selectPresetForShiftPlan = async (
    presetId: number,
    skipSettingPresetName: boolean = false,
  ) => {
    if (
      shiftPlanData?.id &&
      (loadedPlanType === ShiftPlanShiftPlanTypeEnum.DEFAULT ||
        isUsingManuallyAppliedPresent(shiftPlanData as ShiftPlan))
    ) {
      //update existing shift plan
      const data = cloneDeep(shiftPlanData) as ShiftPlan;
      data.preset = presetId;
      data.shifts = [];

      try {
        const res = await StationPlannerService.updateShiftPlan(
          shiftPlanData.id,
          {
            ...data,
            shift_plan_type: ShiftPlanShiftPlanTypeEnum.DEFAULT,
            name: null,
          } as ShiftPlan,
        );

        setData(res.data, true, skipSettingPresetName);
      } catch (error) {
        setError(error.response.data.descriptions.join(', '));
      }
    } else {
      //create new shift plan with a preset
      createNewShiftPlan(datePickerSelection, presetId);
    }
  };

  const updateExisitingPreset = async (id: number) => {
    if (shiftPlanData && availableShiftPlanPresets) {
      const presedEdited = availableShiftPlanPresets.find(
        (preset) => preset.id === id,
      );

      if (presedEdited) {
        try {
          const res =
            await ShiftPlannerPresetsService.updateShiftPlanPreset(
              id,
              {
                ...presedEdited,
                shifts: shiftPlanData.shifts,
              },
            );

          if (
            isUsingManuallyAppliedPresent(shiftPlanData as ShiftPlan)
          ) {
            //refetch shift plan to get new data
            selectPresetForShiftPlan(res.data.id!);
          } else {
            fetchShiftPlan();
          }
        } catch (error) {
          setError(error.response.data.descriptions.join(', '));
        }

        setActiveDialog(ActiveDialog.NONE);
      }
    }
  };

  const editRowClickHandler = (id: number) => {
    if (dateIsPast) return;

    setError(undefined);
    const rowData = shiftPlanData?.shifts[id - 1];
    setSelectedRowId(id - 1);

    if (rowData) {
      setEditRowDialogFields({
        sort: rowData.name,
        startTime: rowData.start_time,
        endTime: rowData.end_time,
        secondsPerPackage: rowData.seconds_per_package.toString(),
        staffDirect: rowData.staff_direct.toString(),
        staffSupport: rowData.staff_support.toString(),
      });
      setSortDialogInteraction(DialogInteraction.EDIT);
      setActiveDialog(ActiveDialog.ADD_EDIT_SORT);
    }
  };

  const onSubmitEditShiftPlanPackagesPerContainer = async (params: {
    [name: string]: string;
  }) => {
    setShowProgress(true);
    setError(undefined);
    try {
      if (shiftPlanData?.id) {
        if (loadedPlanType === ShiftPlanShiftPlanTypeEnum.DEFAULT) {
          //update on shift plan
          const res = await StationPlannerService.updateShiftPlan(
            shiftPlanData?.id,
            {
              ...(shiftPlanData as ShiftPlan),
              packages_per_container: Number(params.packages),
            },
          );
          if (res.data) {
            setData(res.data, true);
            setActiveDialog(ActiveDialog.NONE);
          }
        } else if (
          loadedPlanType === ShiftPlanShiftPlanTypeEnum.PRESET
        ) {
          //update on preset
          const id =
            (shiftPlanData as ShiftPlan)?.preset || shiftPlanData?.id;
          await ShiftPlannerPresetsService.updateShiftPlanPreset(id, {
            ...(shiftPlanData as ShiftPlanPreset),
            packages_per_container: Number(params.packages),
          });
          //refetch shift plan
          fetchShiftPlan();
          setActiveDialog(ActiveDialog.NONE);
        }
      }
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    } finally {
      setShowProgress(false);
    }
  };

  const onSubmitAddShiftPreset = async (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => {
    setError(undefined);
    setAddPresetErrorContext(undefined);
    try {
      const res =
        await ShiftPlannerPresetsService.createShiftPlanPreset(
          params.fields.name,
          params.fields.recurrenceDays
            ? (params.fields.recurrenceDays.split(',') as any)
            : undefined,
          forcePresetSave,
          shiftPlanData?.start_time,
          shiftPlanData?.end_time,
          shiftPlanData?.packages_per_container,
          shiftPlanData?.shifts,
        );

      setActiveDialog(ActiveDialog.NONE);
      setPresetUsedId(res.data.id);
      setPresetUsedName(res.data.name || '');
      setActiveDialog(ActiveDialog.ADD_PRESET_SUCCESS);

      setAddPresetDialogFields(params.fields);
      setAddPresetDialogFields(defaultAddPresetDialogDialogFields);

      //update shift plan to use preset
      selectPresetForShiftPlan(res.data.id!, true);

      //re-fetch available presets
      fetchAvailablePresets();
    } catch (error) {
      if (error.response.data.error_context.conflicts) {
        setAddPresetErrorContext(error.response.data.error_context);
        setForcePresetSave(true);
      } else {
        setError(error.response.data.descriptions.join(', '));
      }
    }
  };

  const updateStartEndTimeAndSave = (
    plan: ShiftPlan | ShiftPlanPreset,
  ) => {
    const startTimes = plan.shifts.map((shift) => shift.start_time);

    const endTimes = plan.shifts.map((shift) => shift.end_time);

    const minTime = startTimes?.reduce((acc, item) => {
      if (new Date(item) < new Date(acc)) return item;
      return acc;
    }, startTimes[0]);

    const maxTime = endTimes?.reduce((acc, item) => {
      if (new Date(item) > new Date(acc)) return item;
      return acc;
    }, endTimes[0]);

    const now = new Date().toISOString();

    setData({
      ...plan,
      start_time: minTime || now,
      end_time: maxTime || now,
    });

    setLastUpdated(new Date().toISOString());
  };

  const onSubmitEditShiftPlanRow = async (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => {
    if (shiftPlanData) {
      if (params.interaction === DialogInteraction.ADD) {
        updateStartEndTimeAndSave({
          ...shiftPlanData,
          shifts: [
            ...shiftPlanData.shifts,
            {
              name: params.fields.sort,
              start_time: new Date(
                params.fields.startTime,
              ).toISOString(),
              end_time: new Date(params.fields.endTime).toISOString(),
              seconds_per_package: Number(
                params.fields.secondsPerPackage,
              ),
              staff_direct: Number(params.fields.staffDirect),
              staff_support: Number(params.fields.staffSupport),
            },
          ],
        });
      } else {
        updateStartEndTimeAndSave({
          ...shiftPlanData,
          shifts: [
            ...shiftPlanData.shifts.map((shift, index) => {
              if (index === selectedRowId) {
                return {
                  ...shift,
                  name: params.fields.sort,
                  start_time: new Date(
                    params.fields.startTime,
                  ).toISOString(),
                  end_time: new Date(
                    params.fields.endTime,
                  ).toISOString(),
                  seconds_per_package: Number(
                    params.fields.secondsPerPackage,
                  ),
                  staff_direct: Number(params.fields.staffDirect),
                  staff_support: Number(params.fields.staffSupport),
                };
              }
              return shift;
            }),
          ],
        });
      }
      setActiveDialog(ActiveDialog.NONE);
    }
  };

  const onRemoveEditShiftPlanRow = () => {
    if (shiftPlanData) {
      updateStartEndTimeAndSave({
        ...shiftPlanData,
        shifts: shiftPlanData.shifts.filter(
          (_, index) => index !== selectedRowId,
        ),
      });
    }
    setActiveDialog(ActiveDialog.NONE);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.STATIONPLANNERREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchShiftPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerSelection]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(
  false,
  'STATION_PLANNER',
)} for ${moment(shiftPlanData?.start_time).format(
            'dddd, MMMM Do YYYY',
          )} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STATION_PLANNER'>
        <Prompt when={true} message={handleBlockedNavigation} />
        {showProgress && <ProgressIndicator />}
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} variant={'h3'}>
              Shift Planner
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              style={{ padding: 16, marginBottom: 16 }}
            >
              <Typography className={classes.smallTitle}>
                Date
              </Typography>
              <Typography
                style={{
                  fontSize: 24,
                }}
              >
                {moment(datePickerSelection).isSame(new Date(), 'day')
                  ? 'Today'
                  : datePickerSelection.toLocaleDateString('en-US')}
                <IconButton
                  onClick={() => {
                    if (!hasUnsavedChanges) {
                      setDatePickerOpen(!datePickerOpen);
                    } else {
                      setDatePickerChangeAttempt(true);
                      setActiveDialog(ActiveDialog.PAGE_LEAVE);
                    }
                  }}
                >
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  showTodayButton
                  variant='dialog'
                  label=''
                  format='MM/dd/yyyy'
                  value={datePickerSelection}
                  onChange={(date) => {
                    if (date) {
                      baseQuery.date = date.toISOString();
                      browserHistory.replace(
                        '?' + queryString.stringify(baseQuery),
                      );
                      setDatePickerSelection(date);
                      setDateIsPast(
                        moment(date).isBefore(moment(), 'day'),
                      );
                      setShiftPlanData(undefined);
                    }

                    setDatePickerOpen(false);
                  }}
                  onClose={() => {
                    setDatePickerOpen(false);
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  DialogProps={{
                    id: 'date-picker-staion-planner',
                  }}
                  className={classes.hiddenInput}
                  open={datePickerOpen}
                />
              </MuiPickersUtilsProvider>
              <Typography variant='h4' className={classes.heading4}>
                {`${packageLabels.singular} Level Sort`}
              </Typography>
              <Paper
                className={classes.paper}
                style={{ padding: 16, margin: '10px 0' }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontSize: 18,
                        lineHeight: '24px',
                      }}
                    >
                      Start Time
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 24,
                        lineHeight: '24px',
                      }}
                    >
                      {shiftPlanData?.start_time
                        ? moment(shiftPlanData?.start_time).format(
                            'hh:mm A',
                          )
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                className={classes.paper}
                style={{ padding: 16 }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontSize: 18,
                        lineHeight: '24px',
                      }}
                    >
                      End Time
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 24,
                        lineHeight: '24px',
                      }}
                    >
                      {shiftPlanData?.end_time
                        ? moment(shiftPlanData?.end_time).format(
                            'hh:mm A',
                          )
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
            <Paper
              className={classes.paper}
              style={{ padding: 16, textAlign: 'center' }}
            >
              <Typography variant='h4' className={classes.heading4}>
                {`${
                  packageLabels.plural
                } per ${configurationUtils.getPageTitle(
                  true,
                  'CONTAINER',
                )} (Pallet)`}
              </Typography>
              <Typography style={{ fontSize: 30 }}>
                {shiftPlanData?.packages_per_container}{' '}
                {PermissionsService.hasPermission(
                  'STATION_PLANNER_WRITE',
                ) && (
                  <IconButton
                    onClick={() => {
                      setError(undefined);
                      setActiveDialog(
                        ActiveDialog.PACKAGES_PER_CONTAINER,
                      );
                    }}
                    disabled={dateIsPast}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Paper className={classes.paper}>
              <VolumeOfPackages
                date={datePickerSelection}
                fromDate={shiftPlanData?.start_time || null}
                toDate={shiftPlanData?.end_time || null}
                key={chartLastUpdated + '-chart'}
              />
            </Paper>
          </Grid>
        </Grid>

        {error && (
          <Grid container>
            <Grid item xs={12}>
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
                style={{ marginBottom: 20 }}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          style={{
            borderBottom: `1px solid ${colors.veryLightGray}`,
            paddingBottom: 15,
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography
              variant={'h3'}
              style={{
                fontSize: 24,
                borderBottom: 0,
                margin: 0,
                fontWeight: 'normal',
              }}
            >
              Shift Planner Configuration
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {hasUnsavedChanges && (
              <Typography
                style={{
                  color: colors.gray,
                  display: 'inline-block',
                  fontSize: 14,
                  marginRight: 10,
                }}
              >
                *Unsaved Changes
              </Typography>
            )}
            <Button
              aria-controls='save-shift-plan-dropdown-menu'
              variant='outlined'
              className={classes.buttonFilled}
              aria-haspopup='true'
              onClick={(event) => {
                setSaveMenuAnchorEl(event.currentTarget);
              }}
              endIcon={<KeyboardArrowDownOutlinedIcon />}
              disabled={!hasUnsavedChanges || dateIsPast}
              style={{
                marginTop: 2,
              }}
            >
              Save
            </Button>
            <Menu
              id='save-shift-plan-dropdown-menu'
              anchorEl={saveMenuAnchorEl}
              keepMounted
              open={Boolean(saveMenuAnchorEl)}
              onClick={(event) => {
                setSaveMenuAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  saveShiftPlan();
                }}
              >
                Save
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setActiveDialog(ActiveDialog.ADD_PRESET);
                }}
              >
                Save as new preset
              </MenuItem>
              {loadedPlanType ===
                ShiftPlanShiftPlanTypeEnum.PRESET && (
                <MenuItem
                  onClick={() => {
                    setActiveDialog(
                      ActiveDialog.UPDATE_PRESET_CONFIRM,
                    );
                  }}
                >
                  Update Existing Preset
                </MenuItem>
              )}
            </Menu>
            <Button
              style={{ marginLeft: '10px', marginTop: 2 }}
              variant='outlined'
              className={classes.buttonOutlined}
              disabled={dateIsPast}
              onClick={() => {
                setSortDialogInteraction(DialogInteraction.ADD);
                setEditRowDialogFields({
                  sort: '',
                  startTime: datePickerSelection.toISOString(),
                  endTime: datePickerSelection.toISOString(),
                  secondsPerPackage: '10',
                  staffDirect: '10',
                  staffSupport: '10',
                });
                setActiveDialog(ActiveDialog.ADD_EDIT_SORT);
                setError(undefined);
              }}
            >
              <AddToPhotosOutlinedIcon /> Add Sort
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <Typography className={classes.smallTitle}>
                    {configurationUtils.getPageTitle(
                      false,
                      'INBOUND_LOAD',
                    )}
                  </Typography>
                  <Typography style={{ fontSize: 18 }}>
                    &mdash;
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography className={classes.smallTitle}>
                    {`Expected ${packageLabels.plural}`}
                  </Typography>
                  <Typography style={{ fontSize: 18 }}>
                    &mdash;
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography className={classes.smallTitle}>
                    Sort Capacity
                  </Typography>
                  <Typography style={{ fontSize: 18 }}>
                    &mdash;
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box style={{ display: 'flex' }}>
                    <Box>
                      <Typography className={classes.smallTitle}>
                        Preset
                      </Typography>
                      <Typography style={{ fontSize: 18 }}>
                        {presetUsedName || <>&mdash;</>}
                      </Typography>
                    </Box>
                    <Box>
                      {availableShiftPlanPresets && (
                        <IconButton
                          onClick={() => {
                            setActiveDialog(
                              ActiveDialog.SELECT_PRESET,
                            );
                          }}
                          disabled={dateIsPast}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <PaginatedTable
              key={lastUpdated + '-left'}
              title=''
              columns={COLUMNS_LEFT}
              dataTestIdPrefix={'station-planner-shifts'}
              fetch={fetchTableData}
              showPagination={false}
              actions={
                PermissionsService.hasPermission(
                  'STATION_PLANNER_WRITE',
                )
                  ? [
                      {
                        tableLabel: ' ',
                        columnLabel: (
                          <EditIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        qualifier: 'id',
                        callback: (id) => {
                          editRowClickHandler(id);
                        },
                      },
                    ]
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PaginatedTable
              key={lastUpdated + '-right'}
              title=''
              columns={COLUMNS_RIGHT}
              dataTestIdPrefix={'station-planner-shifts-actions'}
              fetch={fetchTableData}
              showPagination={false}
              disableNoDataMessage
            />
          </Grid>
        </Grid>
        <EditStationPlanner
          isOpen={
            activeDialog === ActiveDialog.PACKAGES_PER_CONTAINER
          }
          fields={editDialogFields}
          onClose={() => {
            setActiveDialog(ActiveDialog.NONE);
          }}
          onSubmit={onSubmitEditShiftPlanPackagesPerContainer}
          error={error}
          showProgress={showProgress}
        />
        <AddEditShiftRowDialog
          interaction={sortDialogInteraction}
          isOpen={activeDialog === ActiveDialog.ADD_EDIT_SORT}
          fields={editRowDialogFields}
          onClose={() => {
            setActiveDialog(ActiveDialog.NONE);
          }}
          onSubmit={onSubmitEditShiftPlanRow}
          onRemove={onRemoveEditShiftPlanRow}
          error={error}
          showProgress={showProgress}
          selectedDate={datePickerSelection}
          filterByRecurrence={false}
        />

        {availableShiftPlanPresets.length > 0 && (
          <SelectPresetDialog
            isOpen={activeDialog === ActiveDialog.SELECT_PRESET}
            onSubmit={(id) => {
              selectPresetForShiftPlan(id);
              setActiveDialog(ActiveDialog.NONE);
            }}
            onClose={() => {
              setActiveDialog(ActiveDialog.NONE);

              //create blank shift plan if there is no data already
              !shiftPlanData &&
                createNewShiftPlan(datePickerSelection);
            }}
            error={error}
            showProgress={showProgress}
            presets={availableShiftPlanPresets}
            currentPreset={
              presetUsedId ||
              (availableShiftPlanPresets[0].id as number)
            }
          />
        )}
        <AddEditPresetDialog
          isOpen={activeDialog === ActiveDialog.ADD_PRESET}
          interaction={DialogInteraction.ADD}
          fields={addPresetDialogFields}
          onClose={() => {
            setActiveDialog(ActiveDialog.NONE);
            setForcePresetSave(false);

            setAddPresetErrorContext(undefined);
            setAddPresetDialogFields(
              defaultAddPresetDialogDialogFields,
            );
            setError(undefined);
          }}
          onSubmit={onSubmitAddShiftPreset}
          conflicts={addPresetErrorContext}
          error={error}
        />
        <ActionsDialog
          isOpen={activeDialog === ActiveDialog.PAGE_LEAVE}
          title='Unsaved Changes'
          description={`You didn't save your new configurations. Are you sure you want to leave without saving?'`}
          primaryActionLabel='Stay On This Page'
          onPrimaryAction={() => {
            setActiveDialog(ActiveDialog.NONE);
          }}
          secondaryActionLabel='Discard Changes'
          onSecondaryAction={() => {
            confirmPageLeave();
          }}
        />
        <ActionsDialog
          isOpen={activeDialog === ActiveDialog.NO_DATA}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          }
          title='Nothing planned for this day'
          description='Select a Preset or start customizing'
          primaryActionLabel='Select Preset'
          onPrimaryAction={() => {
            setActiveDialog(ActiveDialog.SELECT_PRESET);
          }}
          secondaryActionLabel='OK'
          onSecondaryAction={() => {
            //create empty shift plan
            createNewShiftPlan(datePickerSelection);
            setActiveDialog(ActiveDialog.NONE);
          }}
        />
        <ActionsDialog
          isOpen={activeDialog === ActiveDialog.NO_DATA_PAST}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          }
          title='Nothing planned for this day'
          description='There was no shift plan on this date'
          primaryActionLabel='OK'
          onPrimaryAction={() => {
            setActiveDialog(ActiveDialog.NONE);
          }}
        />
        <ActionsDialog
          isOpen={activeDialog === ActiveDialog.UPDATE_PRESET_CONFIRM}
          icon={
            <CheckCircleOutlineOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          }
          title='Update Current Preset?'
          description={`The preset ${presetUsedName} will be updated. This will overwrite the settings for the previous assigned days.`}
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            //preform preset update
            presetUsedId && updateExisitingPreset(presetUsedId);
          }}
          secondaryActionLabel='Cancel'
          onSecondaryAction={() => {
            //cancel preset update
            setActiveDialog(ActiveDialog.NONE);
          }}
        />
        <ActionsDialog
          isOpen={activeDialog === ActiveDialog.ADD_PRESET_SUCCESS}
          icon={
            <CheckCircleOutlineOutlinedIcon
              style={{
                color: colors.darkGold,
                width: 36,
                height: 'auto',
              }}
            />
          }
          title='New Preset Created'
          description={`The preset ${presetUsedName} was successfully created and added to the presets list.`}
          secondaryActionLabel='OK'
          onSecondaryAction={() => {
            setActiveDialog(ActiveDialog.NONE);
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(StationPlanner);
