import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';

import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Switch, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// React
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataKey: string;
  interaction?: INTERACTION;
  setPartialData: (data: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  confirmation_scans_count: 0,
};

const LoadOps: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Load Operations';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  const inputLabelProps = {
    shrink: true,
    className: classes.inputNameLabel,
  };
  const inputProps = {
    disableUnderline: true,
    className: classes.inputName,
  };
  const textFieldClasses = {
    root: classes.inputNameHolder,
  };

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        'Confirmation Scans',
        data.confirmation_scans_count.toString(),
        'BOL',
        data.bol_mode.toString(),
        'Trailer ID',
      ]);

      setSearchResult(results);
      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onlyNumbers = (value: number) =>
    parseInt(value.toString().replace('/[^]/g', ''));

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='load-ops-edit-dialog-confirmation-scans-count'
            autoFocus
            label={'Count'}
            type={'number'}
            InputLabelProps={inputLabelProps}
            InputProps={inputProps}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={textFieldClasses}
            value={dialogData.confirmation_scans_count}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                ...dialogData,
                confirmation_scans_count: onlyNumbers(
                  parseInt(value),
                ),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='load-ops-edit-dialog-confirmation-scans-save-btn'
            onClick={() => {
              data.confirmation_scans_count =
                dialogData.confirmation_scans_count;
              data.bol_mode = dialogData.bol_mode;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    const isBolModeAny = data.bol_mode === 'ANY';

    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Behaviour
                  </Grid>
                  <Grid item sm={7} xs={3}>
                    Enabled
                  </Grid>
                  <Grid item sm={2} xs={3}>
                    Scan Count
                  </Grid>
                  <Grid item sm={1} xs={2} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='Confirmation Scans'
                  />
                </Grid>
                <Grid
                  item
                  sm={7}
                  xs={3}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <Switch
                    data-testid='Load Operations-Confirmation Scans-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      data.confirmation_scans_active
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={data.confirmation_scans_active}
                    onChange={() => {
                      data.confirmation_scans_active =
                        !data.confirmation_scans_active;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={3}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight={data.confirmation_scans_count.toString()}
                  />
                </Grid>
                <Grid item sm={1} xs={2}>
                  <IconButton
                    data-testid='Load Operations-Confirmation Scans-edit-scan-count-btn'
                    size='small'
                    className={classes.editIcon}
                    disabled={interaction === INTERACTION.READ}
                    onClick={() => {
                      setDialogData({
                        confirmation_scans_count:
                          data.confirmation_scans_count,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Behaviour
                  </Grid>
                  <Grid item sm={5} xs={3}>
                    Enabled
                  </Grid>
                  <Grid item sm={2} xs={3}>
                    REQUIRED
                  </Grid>
                  <Grid item sm={2} xs={3}>
                    BOL per Container
                  </Grid>
                  <Grid item sm={1} xs={2} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='BOL'
                  />
                </Grid>
                <Grid
                  item
                  sm={5}
                  xs={3}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <Switch
                    data-testid='load-operations-BOL-enabled-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      data.bol_active
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={data.bol_active}
                    onChange={() => {
                      data.bol_active = !data.bol_active;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={3}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Switch
                    data-testid='load-operations-BOL-required-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      data.bol_required
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={data.bol_required}
                    onChange={() => {
                      data.bol_required = !data.bol_required;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={3}
                  className={classNames(classes.text)}
                >
                  <Switch
                    data-testid='load-operations-BOL-mode-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      isBolModeAny
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={isBolModeAny}
                    onChange={() => {
                      data.bol_mode = isBolModeAny
                        ? 'INHERIT_ONLY'
                        : 'ANY';
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid item sm={1} xs={2} />
              </Grid>
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Behaviour
                  </Grid>
                  <Grid item sm={5} xs={3}>
                    Enabled
                  </Grid>
                  <Grid item sm={2} xs={3}>
                    REQUIRED
                  </Grid>
                  <Grid item sm={1} xs={2} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='Trailer ID'
                  />
                </Grid>
                <Grid
                  item
                  sm={5}
                  xs={3}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <Switch
                    data-testid='load-operations-trailer-id-enabled-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      data.trailer_id_active
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={data.trailer_id_active}
                    onChange={() => {
                      data.trailer_id_active =
                        !data.trailer_id_active;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={3}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Switch
                    data-testid='load-operations-trailer-id-required-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    className={
                      data.trailer_id_required
                        ? classes.muiSwitch
                        : classes.muiSwitchOff
                    }
                    checked={data.trailer_id_required}
                    onChange={() => {
                      data.trailer_id_required =
                        !data.trailer_id_required;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid item sm={1} xs={2} />
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
    ddInput: {
      padding: '5px 7px',
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  })),
)(LoadOps);
