import React from 'react';

export const transformDependencyData = (
  permissionDependenciesStatusData: {
    [key: string]: {
      enabled: boolean;
      dependencies: string[];
    };
  },
  permissionLabels: { [permission: string]: string },
) => {
  let dependencyStatusData: {
    [key: string]: {
      enabled: boolean;
      tooltipMessage: JSX.Element;
    };
  } = {};

  for (let dependency in permissionDependenciesStatusData) {
    const tooltipMsg =
      permissionDependenciesStatusData[dependency].dependencies
        .length > 1 ? (
        <span>
          To activate {permissionLabels[dependency]}, at least one of
          the following needs to be active:
          <ul
            style={{
              paddingLeft: '15px',
              marginBottom: '0px',
              marginTop: '3px',
            }}
          >
            {permissionDependenciesStatusData[
              dependency
            ].dependencies.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </span>
      ) : (
        <span>
          You need to activate{' '}
          {permissionDependenciesStatusData[dependency].dependencies}{' '}
          first before activating {permissionLabels[dependency]}.
        </span>
      );

    dependencyStatusData[dependency] = {
      enabled: permissionDependenciesStatusData[dependency].enabled,
      tooltipMessage: permissionDependenciesStatusData[dependency]
        .enabled ? (
        <></>
      ) : (
        tooltipMsg
      ),
    };
  }
  return dependencyStatusData;
};
