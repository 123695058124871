import React, { useState, Fragment } from 'react';
import {
  AlertBanner,
  Button,
  CustomTextField,
  Typography,
  Container,
} from '../primitives';
import { withStyles } from '@material-ui/core/styles';
import styles from '../logIn/logIn.styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import validateEmail from '../../utils/validateEmail';
import { NonAuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';

//Services
import AuthService from '../../services/Auth.service';

//Icons
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
}

const ResetPassword: React.FC<Props> = ({ classes }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const canSubmitForm =
    email && validateEmail(email) && !showProgress;

  const onButtonClick = async () => {
    setShowProgress(true);
    try {
      //send reset password
      await AuthService.resetPassword(email!);
      //redirect to login
      browserHistory.push(
        NonAuthRoutes.LOGIN + '/password-reset-requested',
      );
    } catch (e) {
      setError(await ErrorHandler.getLabel(e));
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`CloudSort - Reset Password`}</title>
      </Helmet>
      <div
        className={classes.halfWidth + ' ' + classes.loginBackground}
      >
        <a href='/'>
          <img
            className={classes.loginLogo}
            alt='CloudSort'
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/loginAssets/logo.svg`}
          />
        </a>
      </div>
      <div
        className={classes.halfWidth}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showProgress && (
          <ProgressIndicator data-testid='progress-indicator-component' />
        )}
        <Container
          component='main'
          maxWidth='xs'
          data-testid='reset-password-component'
          style={{
            marginLeft: '20px',
          }}
        >
          <div className={classes.paper}>
            <Typography variant='h2' className={classes.loginHeading}>
              Forgot your password?
            </Typography>
            <Typography
              variant='h2'
              className={classes.loginSubtitle}
            >
              We'll help you reset it and get back on track.
            </Typography>
            <form className={classes.form} noValidate>
              <CustomTextField
                inputProps={{
                  'data-testid': 'reset-password-email',
                }}
                required
                label='E-mail address'
                name='email'
                autoComplete='email'
                autoFocus
                value={email}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  if (error) {
                    setError('');
                  }
                  setEmail(e.target.value);
                }}
              />
              {error && (
                <AlertBanner
                  severity='error'
                  data-testid='reset-password-error'
                  alertMsg={error}
                  alertTitle={'Error'}
                />
              )}

              <Button
                data-testid='reset-password-button'
                disabled={!canSubmitForm}
                className={classes.submitButton}
                onClick={(e) => {
                  e.preventDefault();
                  onButtonClick();
                }}
              >
                Reset Password
              </Button>
              {canSubmitForm && (
                <div className={classes.pressEnter}>
                  or press enter <KeyboardReturnIcon />
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(ResetPassword);
