import React, { useState } from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../Configuration';
import { TabPanel } from '../../../tabPanel/TabPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DashboardModule from './DashboardModule';
import Navigation from './Navigation';
import { CurrentView } from '../Modules';

interface Props {
  classes: { [key: string]: string };
  data: any;
  setData: (data: any) => void;
  interaction?: INTERACTION;
}

const WebAppDesktop: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setData,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const currentView = CurrentView.DESKTOP;
  return (
    <Box mt={1}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          classes={{
            expanded: classes.panelSummaryExpanded,
            root: classes.panelSummary,
          }}
          expandIcon={
            <ExpandMoreIcon
              className={classes.expansionPanelExpandColapseIcon}
            />
          }
        >
          <Typography className={classes.summaryPanelTitleText}>
            Web App
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.panelDetails,
          }}
        >
          <Tabs
            data-testid='webapp-tabs-root'
            value={currentTab}
            variant='scrollable'
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
            }}
            classes={{
              indicator: classes.tabsIndicator,
              root: classes.tabsRoot,
            }}
          >
            <Tab
              label='Dashboard'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
            <Tab
              label='Navigation'
              classes={{
                selected: classes.selectedTab,
                root: classes.tab,
              }}
              disableRipple
            />
          </Tabs>

          <TabPanel
            currentTab={currentTab}
            index={0}
            label='Dashboard'
          >
            <DashboardModule
              currentView={currentView}
              interaction={interaction}
              data={data?.config?.WEB?.LAYOUTS?.DASHBOARD}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.WEB.LAYOUTS.DASHBOARD =
                  partialData;
                setData(partialDataObj);
              }}
            />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={1}
            label='Navigation'
          >
            <Navigation
              currentView={currentView}
              interaction={interaction}
              dataModules={data?.config?.GENERAL?.MODULES}
              data={data?.config?.WEB?.NAV_MENU}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.WEB.NAV_MENU = partialData;
                setData(partialDataObj);
              }}
            />
          </TabPanel>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};
export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(WebAppDesktop);
