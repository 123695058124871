import { createStyles, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import colors from '../../../utils/colors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '../../primitives/typography/Typography';
import moment from 'moment';

type PropsWithText = {
  classes: { [key: string]: string };
  prediction: boolean | null | undefined;
  withText: boolean;
  arrivalTimestamp: string | null | undefined;
};

type PropsWithOutText = {
  classes: { [key: string]: string };
  prediction: boolean | null | undefined;
  withText?: never;
  arrivalTimestamp?: never;
};

const PackagePrediction: React.FC<PropsWithOutText | PropsWithText> =
  ({ classes, prediction = null, withText, arrivalTimestamp }) => {
    const getColor = () => {
      if (prediction === true) {
        return colors.green;
      }
      if (prediction === false) {
        return colors.red;
      }
      return colors.gray;
    };

    const getText = () => {
      if (prediction === true) {
        return (
          'Arriving at ' +
          moment(arrivalTimestamp).format('MM/DD/YYYY, hh:mm A')
        );
      }
      if (prediction === false) {
        return 'Not arriving';
      }
      return 'N/A';
    };

    return (
      <div style={{ display: 'flex' }}>
        <FiberManualRecordIcon
          style={{
            color: getColor(),
          }}
        />
        {withText && <Typography>{getText()}</Typography>}
      </div>
    );
  };

export default withStyles(createStyles((theme: Theme) => ({})))(
  PackagePrediction,
);
