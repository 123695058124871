import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, AlertBanner } from '../primitives';

import Paper from '@material-ui/core/Paper';
import ManifestsServices from '../../services/Manifests.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import { humanReadableNull } from '../DetailsPagesFunctions';

import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import utils from '../../utils/stats';
import {
  GET_COLUMNS_PACKAGES,
  fetch as fetchPackages,
} from '../packages/Packages';
import configurationUtils from '../../utils/configurationUtils';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import { Column } from '../../interfaces/components';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';
import { createStyles, Grid, Theme } from '@material-ui/core';
import detailsPageStyle from '../commonStyles/detailsPage.style';

// Types
import {
  ManifestSerializerDetails,
  ManifestSerializerDetailsStatusEnum,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';

// Icons
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';

//Services
import ContainersService from '../../services/Containers.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

import { Helmet } from 'react-helmet';
import { formatContainer } from '../containers/Containers';
import ArrivalPredictionStats from './ArrivalPredictionStats';

export const formatLabel = (str?: string) => {
  const label = str
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const ManifestsDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [manifestsData, setManifestsData] =
    useState<ManifestSerializerDetails>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  // Get labels on each render cycle
  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: packageLabels.plural,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'volume',
      label: 'Volume',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
  ];

  const getManifestsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await ManifestsServices.getById(
        (match.params as any).id,
      );
      setManifestsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const markAsArrived = async () => {
    setShowProgress(true);
    try {
      const dataObj: ManifestSerializerDetails = { ...manifestsData };
      dataObj.status = ManifestSerializerDetailsStatusEnum.ARRIVED;
      const { data } = await ManifestsServices.update(
        manifestsData!.id!,
        dataObj,
      );
      setManifestsData(data);
      setTimeout(() => {
        setLastUpdated(new Date().toISOString());
      }, 2000);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.MANIFESTREAD,
    );

    if (!manifestsData) {
      getManifestsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      manifest: [(match.params as any).id],
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });
    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });
    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'MANIFEST')} ${
            manifestsData?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='MANIFEST'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid={'manifests-detail-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          data-testid={'manifests-detail-confirmation-dialog'}
          title={'Mark as Arrived'}
          msg={`Are you sure you want to mark ${configurationUtils.getPageTitle(
            true,
            'MANIFEST',
          )} ${manifestsData?.id} as arrived?`}
          onPrimaryAction={() => {
            setShowDialog(false);
            markAsArrived();
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
          isOpen={showDialog}
        />

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              style={{ marginBottom: 16 }}
            >
              {`${configurationUtils.getPageTitle(
                true,
                'MANIFEST',
              )} ${manifestsData?.id || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.MANIFESTWRITE,
            ) &&
              manifestsData?.inbound &&
              manifestsData.status !==
                ManifestSerializerDetailsStatusEnum.ARRIVED && (
                <Button
                  variant='outlined'
                  className={classes.outlinedButton}
                  style={{ width: 'auto' }}
                  disabled={!manifestsData}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDialog(true);
                  }}
                >
                  <DoneOutlineOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Mark As Arrived
                </Button>
              )}
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={8}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid='manifests-detail-view'
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Status'
                value={humanReadableNull(manifestsData?.status)}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Owner'
                value={humanReadableNull(
                  manifestsData?.owner_full_name,
                )}
              />
              <br />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Creation Date'
                value={
                  manifestsData?.created_on
                    ? new Date(
                        manifestsData?.created_on,
                      ).toLocaleDateString()
                    : common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Carrier'
                value={humanReadableNull(
                  manifestsData?.fmc_full_name,
                )}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.paper}
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
              }}
            >
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                <Typography
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Received On
                </Typography>
                <Typography
                  variant='h6'
                  className={classes.statsValue}
                >
                  {manifestsData?.received_on
                    ? new Date(
                        manifestsData?.received_on,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue}
                </Typography>
              </div>
              <div
                className={classes.singleStat}
                style={{ textAlign: 'center', flex: '1 1 0' }}
              >
                <Typography
                  variant='body1'
                  className={classes.singleStatLabel}
                >
                  Time Left
                </Typography>
                <Typography
                  variant='h6'
                  className={classes.statsValue}
                >
                  {manifestsData?.received_on &&
                  new Date() < new Date(manifestsData?.received_on)
                    ? utils.getLongestDwellTimeLabel(
                        manifestsData?.received_on,
                      )
                    : common.emptyValue}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
        {EphemeralStateService.getMyStationConfiguratation().GENERAL
          .BEHAVIORS.ML_INTEGRATION.arrival_prediction && (
          <Grid container style={{ marginBottom: 8 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <ArrivalPredictionStats
                title={packageLabels.plural as string}
                expected={manifestsData?.total_packages || 0}
                arrived={manifestsData?.total_arrived_packages || 0}
                predictedToArrive={
                  manifestsData?.predicted_arrival_packages || 0
                }
                predictedToArriveAndArrived={
                  manifestsData?.total_predicted_and_arrived_packages ||
                  0
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ArrivalPredictionStats
                title={'Volume'}
                expected={parseFloat(
                  manifestsData?.total_volume?.toFixed(1) || '0',
                )}
                arrived={parseFloat(
                  manifestsData?.total_arrived_volume?.toFixed(1) ||
                    '0',
                )}
                predictedToArrive={parseFloat(
                  manifestsData?.predicted_arrival_volume?.toFixed(
                    1,
                  ) || '0',
                )}
                predictedToArriveAndArrived={parseFloat(
                  manifestsData?.total_predicted_and_arrived_volume?.toFixed(
                    1,
                  ) || '0',
                )}
              />
            </Grid>
          </Grid>
        )}
        {configurationUtils.isModuleActive('PACKAGE') &&
          PermissionsService.hasPermission(
            PermissionsPermissionsEnum.PACKAGEREAD,
          ) && (
            <>
              <br />
              <br />
              <PaginatedTable
                key={lastUpdated}
                disableUpdateQueryStringUrl
                title={packageLabels.plural}
                columns={GET_COLUMNS_PACKAGES()}
                dataTestIdPrefix={'load-details-packages'}
                fetch={(
                  pageIndex: number,
                  rowsPerPage: number,
                  filterOptions?: filterObj[],
                  filterByString?: string,
                  sortedBy?: string,
                ) => {
                  return fetchPackages(
                    pageIndex,
                    rowsPerPage,
                    {}, // no filters
                    sortedBy,
                    {
                      manifest: [(match.params as any).id],
                      fromDate: '',
                      toDate: '',
                    } as any,
                  );
                }}
                rowsLoadDetailPages={true}
                detailsPageBasePath={AuthRoutes.PACKAGE}
              />
            </>
          )}

        {configurationUtils.isModuleActive('CONTAINER') &&
          PermissionsService.hasPermission(
            PermissionsPermissionsEnum.CONTAINERREAD,
          ) && (
            <>
              <br />
              <br />
              <PaginatedTable
                key={lastUpdated}
                disableUpdateQueryStringUrl
                title={configurationUtils.getPageTitle(
                  false,
                  'CONTAINER',
                )}
                columns={COLUMNS_CONTAINERS}
                dataTestIdPrefix={'load-details-containers'}
                fetch={fetchContainers}
                rowsLoadDetailPages={true}
                detailsPageBasePath={AuthRoutes.CONTAINER}
              />
            </>
          )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
  })),
)(ManifestsDetailsComponent);
