import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  flexTidyArrangement: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  filter: {
    height: 56,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
      {
        borderColor: `${colors.darkGold} !important`,
        borderWidth: 2,
      },
    '& .MuiFormLabel-root': {
      top: '-6px',
      left: 15,
      zIndex: 2,
      backgroundColor: '#f1f1f1',
      padding: `0 ${theme.spacing(1)}px`,
      whiteSpace: 'nowrap',
      height: '1rem',
      overflow: 'hidden',
      maxWidth: '140px',
      textOverflow: 'ellipsis',
    },
    width: 175,
    [theme.breakpoints.down('md')]: {
      width: 140,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 175,
    },
  },
  muiSelect: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  toolBar: {
    backgroundColor: colors.white,
    minHeight: 56,
    justifyContent: 'space-between',
    '& p': {
      color: colors.dark,
      fontSize: 24,
      lineHeight: '28px',
    },
  },
  tableHeadRow: {
    '& th': {
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  tableRow: {
    backgroundColor: colors.white,
    '&:hover td': {
      backgroundColor: '#eee',
    },
  },
  removeFilters: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: '1.25px',
    color: colors.gray,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    '&:hover': {
      color: colors.darkGold,
    },
  },
  sortableLink: {
    color: 'inherit',
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  sortIcon: {
    position: 'absolute',
    width: 16,
    marginLeft: 8,
    color: colors.black,
  },
  paginationCaption: {
    backgroundColor: colors.white,
  },
  filterByStrInput: {
    padding: '0 7px',
  },
  filterByStrHolder: {
    minWidth: '12em',
    marginRight: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '3px',
    backgroundColor: '#fff',
  },
  filterByStrLabel: {
    padding: 7,
  },
  expansionPanel: {
    boxShadow: 'none',
    '& .MuiExpansionPanelSummary-root': {
      flexDirection: 'row-reverse',
      padding: 0,
      '& .MuiExpansionPanelSummary-expandIcon': {
        padding: 0,
        margin: 12,
      },
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
    },
    '& .MuiTableRow-root': {
      backgroundColor: `transparent !important`,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: `transparent !important`,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover td': {
      backgroundColor: `transparent !important`,
    },
    '& td.MuiTableCell-root': {
      border: 0,
    },
    '& .MuiButtonBase-root.MuiExpansionPanelSummary-root.Mui-expanded':
      {
        backgroundColor: colors.darkGold,
        '& button': {
          '&:hover': {
            color: `${colors.white}!important`,
          },
        },
      },
    '& .MuiExpansionPanelDetails-root': {
      padding: 0,
      backgroundColor: 'rgb(225,187,155, 0.39)',
    },
  },
  actionColumn: {
    textAlign: 'right',
  },
  actionColumnButton: {
    fontSize: '14px',
    textTransform: 'none',
    display: 'inline-flex',
    minWidth: 0,
    padding: 0,
    '&:hover': {
      color: colors.darkGold,
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      opacity: '0.5',
    },
  },
  mobileColumnLabel: {
    opacity: 0.54,
    color: colors.dark,
    fontSize: 12,
    fontWeight: 500,
  },
  mobileColumnValue: {
    color: colors.dark,
    fontSize: 13,
  },
}));
