import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../stationPlanner.styles';
import Layout from '../../layout/Layout';
import { Typography } from '../../primitives/index';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import { Box, Button, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import colors from '../../../utils/colors';
import { Column } from '../../../interfaces/components';
import PaginatedTable from '../../paginatedTable/PaginatedTable';
import PermissionsService from '../../../services/Permissions.service';
import {
  calculateShiftLength,
  DialogInteraction,
} from '../StationPlanner';
import {
  PermissionsPermissionsEnum,
  ShiftPlanPreset,
} from 'cloudsort-client';
import moment from 'moment';
import { match } from 'react-router-dom';
import ShiftPlannerPresetsService from '../../../services/ShiftPlannerPresets.service';
import AddEditShiftRowDialog from '../editShiftRowDiialog/AddEditShiftRowDialog';
import browserHistory from '../../../utils/browserHistory';
import { AuthRoutes } from '../../../interfaces/routes';

// Icons
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import configurationUtils from '../../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const ShiftPresetDetails: React.FC<Props> = ({ classes, match }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [editRowDialogFields, setEditRowDialogFields] = useState<{
    [name: string]: string;
  }>({});

  const [sortDialogInteraction, setSortDialogInteraction] =
    useState<DialogInteraction>(DialogInteraction.ADD);

  const [showAddEditSortDialog, setShowAddEditSortDialog] =
    useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const [shiftPresetData, setShiftPresetData] =
    useState<ShiftPlanPreset>();

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const staffLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'STAFF'),
      plural: configurationUtils.getPageTitle(false, 'STAFF'),
    };
  }, []);

  const COLUMNS_LEFT: Column[] = [
    {
      id: 'id',
      label: '',
      width: 'auto',
      align: 'left',
      hide: true,
    },
    {
      id: 'name',
      label: 'Sort',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'start_time_formatted',
      label: 'Start Time',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'end_time_formatted',
      label: 'End Time',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'sort_length',
      label: 'Sort Length (Hours)',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'staff_direct',
      label: `${staffLabels.plural} - Direct`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'staff_support',
      label: `${staffLabels.plural} - Support`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'seconds_per_package',
      label: `Seconds Per ${packageLabels.singular}`,
      width: 'auto',
      align: 'left',
    },
  ];

  const COLUMNS_RIGHT: Column[] = [
    {
      id: 'sort_capacity',
      label: 'Sort Capacity',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'sort_capacity_hour',
      label: 'Sort Capacity Per Hour',
      width: 'auto',
      align: 'left',
    },
  ];

  const setData = (data: ShiftPlanPreset) => {
    const today = moment();
    const tomorrow = moment().add(1, 'day');

    //change dates to current date to make editing easier
    data.shifts.map((shift) => {
      return {
        ...shift,
        start_time: moment(shift.start_time)
          .date(today.date())
          .month(today.month())
          .year(today.year())
          .toISOString(),
        end_time: moment(shift.end_time).isSame(
          moment(shift.start_time),
          'day',
        )
          ? moment(shift.end_time)
              .date(today.date())
              .month(today.month())
              .year(today.year())
              .toISOString()
          : moment(shift.end_time)
              .date(tomorrow.date())
              .month(tomorrow.month())
              .year(tomorrow.year())
              .toISOString(),
      };
    });

    setShiftPresetData(data);
    setLastUpdated(new Date().toISOString());
    setShowProgress(false);
  };

  const fetchShiftPlanPreset = async () => {
    setShowProgress(true);

    try {
      const res = await ShiftPlannerPresetsService.getById(
        (match.params as any).id,
      );

      if (res.data) {
        setData(res.data);
      }
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    }

    setShowProgress(false);
  };

  const fetchTableData = async () => {
    let shifts: any[] = [];

    if (shiftPresetData?.shifts) {
      shifts = shiftPresetData.shifts.map((shift: any, index) => {
        const shiftLength = calculateShiftLength(
          shift.start_time,
          shift.end_time,
        );

        const sortCapacty =
          shift.staff_direct + shift.staff_support && shiftLength
            ? (shiftLength *
                3600 *
                (shift.staff_direct + shift.staff_support)) /
              shift.seconds_per_package
            : 0; // when there are no staff sort capacity should be 0

        return {
          ...shift,
          id: index + 1,
          start_time_formatted: moment(shift.start_time).format(
            'hh:mm A',
          ),
          end_time_formatted: moment(shift.end_time).format(
            'hh:mm A',
          ),
          sort_length: shiftLength.toFixed(1),
          sort_capacity: sortCapacty.toFixed(1),
          sort_capacity_hour: shiftLength
            ? (sortCapacty / shiftLength).toFixed(1)
            : '0.0',
        };
      });
    }

    return {
      data: {
        count: shifts.length,
        results: shifts,
      },
    };
  };

  const editRowClickHandler = (id: number) => {
    setError(undefined);
    const rowData = shiftPresetData?.shifts[id - 1];
    setSelectedRowId(id - 1);

    if (rowData) {
      setSortDialogInteraction(DialogInteraction.EDIT);
      setEditRowDialogFields({
        sort: rowData.name,
        startTime: rowData.start_time,
        endTime: rowData.end_time,
        secondsPerPackage: rowData.seconds_per_package.toString(),
        staffDirect: rowData.staff_direct.toString(),
        staffSupport: rowData.staff_support.toString(),
      });
      setShowAddEditSortDialog(true);
    }
  };

  const updateStartEndTimeAndSave = async (
    preset: ShiftPlanPreset,
  ) => {
    const startTimes = preset.shifts.map((shift) => shift.start_time);

    const endTimes = preset.shifts.map((shift) => shift.end_time);

    const minTime = startTimes?.reduce((acc, item) => {
      if (new Date(item) < new Date(acc)) return item;
      return acc;
    }, startTimes[0]);

    const maxTime = endTimes?.reduce((acc, item) => {
      if (new Date(item) > new Date(acc)) return item;
      return acc;
    }, endTimes[0]);

    const now = new Date().toISOString();

    const presetData: ShiftPlanPreset = {
      ...preset,
      start_time: minTime || now,
      end_time: maxTime || now,
    };

    setShowProgress(true);

    try {
      const result =
        await ShiftPlannerPresetsService.updateShiftPlanPreset(
          presetData.id!,
          presetData,
        );

      setData(result.data);
      browserHistory.push(
        AuthRoutes.SHIFT_PRESETS + '/' + result.data.id,
      );
      setShowAddEditSortDialog(false);
    } catch (error) {
      setError(error.response.data.descriptions.join(', '));
    }

    setShowProgress(false);
  };

  const onSubmitEditShiftPlanRow = async (params: {
    interaction: DialogInteraction;
    fields: { [name: string]: string };
  }) => {
    if (shiftPresetData) {
      if (params.interaction === DialogInteraction.ADD) {
        updateStartEndTimeAndSave({
          ...shiftPresetData,
          shifts: [
            ...shiftPresetData.shifts,
            {
              name: params.fields.sort,
              start_time: new Date(
                params.fields.startTime,
              ).toISOString(),
              end_time: new Date(params.fields.endTime).toISOString(),
              seconds_per_package: Number(
                params.fields.secondsPerPackage,
              ),
              staff_direct: Number(params.fields.staffDirect),
              staff_support: Number(params.fields.staffSupport),
            },
          ],
        });
      } else {
        updateStartEndTimeAndSave({
          ...shiftPresetData,
          shifts: [
            ...shiftPresetData.shifts.map((shift, index) => {
              if (index === selectedRowId) {
                return {
                  ...shift,
                  name: params.fields.sort,
                  start_time: new Date(
                    params.fields.startTime,
                  ).toISOString(),
                  end_time: new Date(
                    params.fields.endTime,
                  ).toISOString(),
                  seconds_per_package: Number(
                    params.fields.secondsPerPackage,
                  ),
                  staff_direct: Number(params.fields.staffDirect),
                  staff_support: Number(params.fields.staffSupport),
                };
              }
              return shift;
            }),
          ],
        });
      }
    }
  };

  const onRemoveEditShiftPlanRow = () => {
    if (shiftPresetData) {
      updateStartEndTimeAndSave({
        ...shiftPresetData,
        shifts: shiftPresetData.shifts.filter(
          (_, index) => index !== selectedRowId,
        ),
      });
    }
    setShowAddEditSortDialog(false);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.STATIONPLANNERREAD,
    );

    fetchShiftPlanPreset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {/* tslint:disable-next-line */}
          {`Cloudsort - Shift Preset Details for 
            ${shiftPresetData?.name || shiftPresetData?.id}`}
        </title>
      </Helmet>
      <Layout navCurrent='SHIFT_PRESETS'>
        {showProgress && <ProgressIndicator />}
        <Grid
          container
          style={{
            borderBottom: `1px solid ${colors.veryLightGray}`,
            paddingBottom: 15,
            marginBottom: 30,
          }}
        >
          <Grid item xs={6} sm={8}>
            <Box
              mb={1}
              onClick={() => {
                browserHistory.push(AuthRoutes.SHIFT_PRESETS);
              }}
              className={classes.backButton}
            >
              <ArrowLeftIcon />
              <Typography>Back</Typography>
            </Box>

            <Typography
              style={{
                fontSize: 24,
                borderBottom: 0,
                margin: 0,
                fontWeight: 'normal',
              }}
              variant={'h3'}
            >
              {shiftPresetData?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              className={classes.buttonOutlined}
              data-testid='add-shift-sort-button'
              onClick={() => {
                setSortDialogInteraction(DialogInteraction.ADD);
                setEditRowDialogFields({
                  sort: '',
                  startTime: new Date().toISOString(),
                  endTime: new Date().toISOString(),
                  secondsPerPackage: '10',
                  staffDirect: '10',
                  staffSupport: '10',
                });
                setShowAddEditSortDialog(true);
              }}
            >
              <AddToPhotosOutlinedIcon /> Add sort
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <PaginatedTable
              title=''
              key={lastUpdated + '-left'}
              columns={COLUMNS_LEFT}
              dataTestIdPrefix={'station-planner-shifts'}
              fetch={fetchTableData}
              showPagination={false}
              actions={
                PermissionsService.hasPermission(
                  'STATION_PLANNER_WRITE',
                )
                  ? [
                      {
                        tableLabel: ' ',
                        columnLabel: (
                          <EditIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        qualifier: 'id',
                        callback: (id) => {
                          editRowClickHandler(id);
                        },
                      },
                    ]
                  : []
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <PaginatedTable
              title=''
              key={lastUpdated + '-right'}
              columns={COLUMNS_RIGHT}
              dataTestIdPrefix={'station-planner-shifts-actions'}
              fetch={fetchTableData}
              showPagination={false}
              disableNoDataMessage
            />
          </Grid>
        </Grid>
        <AddEditShiftRowDialog
          interaction={sortDialogInteraction}
          isOpen={showAddEditSortDialog}
          fields={editRowDialogFields}
          onClose={() => {
            setError(undefined);
            setShowAddEditSortDialog(false);
          }}
          onSubmit={onSubmitEditShiftPlanRow}
          onRemove={onRemoveEditShiftPlanRow}
          error={error}
          showProgress={showProgress}
          selectedDate={new Date()}
          filterByRecurrence={true}
          recurrenceData={shiftPresetData?.recurrence}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(ShiftPresetDetails);
