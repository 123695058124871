import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AlertBanner, Typography } from '../primitives';

import colors from '../../utils/colors';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import {
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Card,
  CardContent,
  DialogTitle,
} from '@material-ui/core';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../DetailsPagesFunctions';

//Types
import { AxiosError } from 'axios';
import {
  APIExceptionErrorCodeEnum,
  LoadPlanImportSummary,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';

// Icons
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';

// Services
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import PermissionsService from '../../services/Permissions.service';
import configurationUtils from '../../utils/configurationUtils';
import LoadPlansService from '../../services/LoadPlans.service';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/components/CommonPropTypes';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
}

const UploadCSVDialog: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] =
    useState<{ message: string; data: Data | null }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [formClass, setFormClass] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);
  const [activateImmediately, setActivateImmediately] =
    useState<boolean>(true);
  const [dryRunResults, setDryRunResults] =
    useState<LoadPlanImportSummary>();
  const [selectedFile, setSelectedFile] = useState<any>();

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const loadPlanLabels = {
    singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
    plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
  };

  const handleClose = () => {
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    setIsUpload(false);
    onAfterClose();
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as any; // There is no csv_import_issues on the api-js
    const knownError =
      errorData.error_code === APIExceptionErrorCodeEnum.ResourceConflict ||
      errorData.error_code === 'csv_parser_failure';
    if (errorData && knownError) {
      //Handle resource conflict
      if (errorData.error_code === APIExceptionErrorCodeEnum.ResourceConflict) {
        const conflictingZipcodes =
          errorData.error_context?.conflicts?.map(
            (item: any) => item.zipcode,
          ) || [];
        setError({
          message:
            'The following Zip Codes are part of multiple schemes from the same carrier: ' +
            formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
          data: null,
        });
      }
      //Handle csv parser failure
      if (errorData.error_code === 'csv_parser_failure') {
        const singleProblem = `Failed to parse the CSV file. We found 1 problem (${errorData.error_context?.csv_import_issues[0][0]
          }) at row ${Object.keys(errorData.error_context.csv_import_issues)[0]
          }.`;
        const multipleProblems = `Failed to parse the CSV file. We found ${errorData.error_context.csv_import_issues.length} errors.`;
        setError({
          message:
            errorData.error_context.csv_import_issues.length > 1
              ? multipleProblems
              : singleProblem,
          data:
            errorData.error_context.csv_import_issues.length > 1
              ? errorData.error_context.csv_import_issues
              : null,
        });
      }
    } else
      setError({
        message: await ErrorHandler.getLabel(e),
        data: null,
      });
  };

  const createNewLoadPlan = async () => {
    setShowProgress(true);
    setError(undefined);
    try {
      const lp = await LoadPlansService.create();
      browserHistory.push(
        AuthRoutes.LOADPLAN + '/' + lp.data.id + '/true',
      );
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const uploadLoadplanFile = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      if (dryRun) {
        setIsUploading(true);
        const response = await LoadPlansService.import({
          dryRun,
          file: selectedFile,
          //generateDestinations: true, TODO -- Remove if implicit ttrue
          options: {
            onUploadProgress,
          },
        });

        setDryRunResults(response.data);
        setDryRunComplete(true);
        setShowProgress(false);
        setIsUploading(false);
      } else {
        const response = await LoadPlansService.import({
          dryRun,
          fileId: dryRunResults?.file_id,
          setActive:
            PermissionsService.hasPermission(
              PermissionsPermissionsEnum.LOADPLANWRITE,
            ) && activateImmediately,
        });
        if (!activateImmediately) {
          browserHistory.push(
            AuthRoutes.LOADPLAN +
            '/' +
            response.data.loadplan +
            '/true',
          );
        }
        updateParent(); // notify parent component to re-render table
        handleClose();
      }
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onFileChangeHandler = (event: any) => {
    setSelectedFileLabel(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const onFileDroppedHandler = (event: any) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (!file.name.includes('.csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
        setFormClass(undefined);
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      });
    }
  };

  const renderModeSelection = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                createNewLoadPlan();
              }}
            >
              <Box className={classes.iconBox}>
                <FlipToBackIcon
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Start from scratch
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                setIsUpload(true);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  className={classes.img}
                  src={`${process.env.REACT_APP_BASENAME || ''
                    }/icons/csv.svg`}
                  alt={`CS ${loadPlanLabels.singular}`}
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: '30px',
                    height: 'auto',
                  }}
                />
                <CloudUploadOutlined
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Import {loadPlanLabels.singular}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        data-testid='upload-CSV-dialog'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {!isUpload && (
          <>
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 40,
                    color: colors.darkGold,
                  }}
                />

                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 18,
                    marginBottom: 20,
                  }}
                >
                  Add a new {loadPlanLabels.singular}
                </span>
              </div>
            </DialogTitle>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {renderModeSelection()}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CLOSE
              </Button>
            </DialogActions>
          </>
        )}
        {isUpload && (
          <>
            <DialogContent className={classes.dialogContent}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                data-testid={'import-load-plan-dialog-content'}
              >
                <CloudUploadOutlined
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 40,
                    color: colors.darkGold,
                  }}
                />

                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 18,
                  }}
                >
                  {dryRunComplete
                    ? 'Import Details'
                    : `Import  ${loadPlanLabels.singular}`}
                </span>

                {!dryRunComplete && !isUploading && (
                  <>
                    <form
                      data-testid={'import-load-plan:drop-zone'}
                      className={
                        classes.form +
                        ' ' +
                        (formClass && classes[formClass])
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                        setFormClass('draggingActive');
                      }}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        setFormClass(undefined);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        onFileDroppedHandler(e);
                      }}
                    >
                      <input
                        accept='.csv'
                        className={classes.input}
                        style={{ display: 'none' }}
                        id='button-file'
                        type='file'
                        onChange={onFileChangeHandler}
                      />
                      <p>{selectedFileLabel}</p>
                      <label htmlFor='button-file'>
                        <Button
                          variant='outlined'
                          component='span'
                          className={classes.goldButton}
                        >
                          Browse File
                        </Button>
                      </label>
                    </form>
                    <Typography className={classes.grayText}>
                      Supported file types: .CSV
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors.dark,
                            marginTop: 20,
                          }}
                        >
                          Columns
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          stop_name
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the name of the {stopsLabels.singular}
                          this scheme is associated with; if empty
                          will default to the same value as the
                          scheme_name
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          scheme_name
                          <span className={classes.goldText}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the name of the this scheme entry
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          carrier
                          <span className={classes.goldText}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the carrier identifier of this scheme (e.g.
                          USPS)
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          zipcode
                          <span className={classes.goldText}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the zipcode to assign to the scheme
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          sort_param
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the sort parameter to use on this zipcode
                          entry
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.goldText}>
                          *Required Fields
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      style={{
                        fontSize: 12,
                        marginTop: 28,
                        lineHeight: '14px',
                      }}
                    >
                      The selected file will be imported as a new,
                      full {loadPlanLabels.singular} not affecting
                      existing operations until manually activated.
                      Before the
                      {loadPlanLabels.singular}
                      is imported, a short summary will be displayed
                      allowing a review of the data before finalizing
                      the import.
                    </Typography>
                  </>
                )}
                {isUploading && !error && (
                  <p className={classes.grayTitle}>Uploading file</p>
                )}
                {(isUploading || dryRunComplete) && (
                  <Paper
                    className={classes.fileContainer}
                    data-testid={'imported-plan-info'}
                  >
                    <Grid container>
                      <Grid item xs={2} style={{ padding: '10px' }}>
                        <img
                          className={classes.img}
                          src={`${process.env.REACT_APP_BASENAME || ''
                            }/icons/csv-file.png`}
                          alt={`CS ${loadPlanLabels.singular}`}
                        />
                      </Grid>
                      <Grid item xs={10} style={{ padding: '10px' }}>
                        <p className={classes.uploadFileInfo}>
                          <span className={classes.uploadFilename}>
                            {selectedFileLabel}
                          </span>

                          <span className={classes.uploadPercentage}>
                            {uploadProgress}%
                          </span>
                        </p>
                        <div className={classes.uploadProgressBar}>
                          <div
                            className={classes.uploadProgressBarInner}
                            style={{
                              width: `${uploadProgress}%`,
                            }}
                          ></div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {error && (
                  <AlertBanner
                    className={classes.banner}
                    severity='error'
                    alertTitle={'Error'}
                    alertMsg={error.message}
                  />
                )}
                {error?.data && (
                  <Typography>
                    <CSVLink
                      className={classes.link}
                      style={{ fontSize: '15px' }}
                      data={error?.data || ''}
                      filename={`${selectedFileLabel}-import-errors-log.csv`}
                    >
                      Download error log
                    </CSVLink>
                  </Typography>
                )}
                {dryRunComplete && (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: '10px' }}
                    >
                      <Box className={classes.grayTitle}>
                        New {loadPlanLabels.singular}
                      </Box>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography className={classes.boldNameParam}>
                          Added {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                          data-testid={'loadplan-added-stops'}
                        >
                          {dryRunResults?.stops_added.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    ></Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography className={classes.boldNameParam}>
                          Removed {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                          data-testid={'loadplan-removed-stops'}
                        >
                          {dryRunResults?.stops_removed.length}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography className={classes.boldNameParam}>
                          Moved ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                          data-testid={'loadplan-moved-zip-codes'}
                        >
                          {dryRunResults?.zipcodes_moved}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography className={classes.boldNameParam}>
                          Total {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueGold}
                          data-testid={'loadplan-total-stops'}
                        >
                          {dryRunResults?.stops_total}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography className={classes.boldNameParam}>
                          Total ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueGold}
                          data-testid={'loadplan-total-zip-codes'}
                        >
                          {dryRunResults?.zipcodes_total}
                        </Typography>
                      </Grid>
                    </Grid>
                    {dryRunResults?.issues &&
                      (dryRunResults?.issues as unknown as any[])
                        .length > 0 && (
                        <>
                          <Grid
                            item
                            container
                            xs={6}
                            style={{ marginBottom: '10px' }}
                          >
                            <Grid
                              item
                              container
                              xs={8}
                              direction={'column'}
                            >
                              <Typography
                                className={classes.boldNameParam}
                              >
                                Warnings
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={classes.paramValueGold}
                                data-testid={
                                  'loadplan-warnings-count'
                                }
                              >
                                {
                                  (
                                    dryRunResults?.issues as unknown as any[]
                                  ).length
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            style={{ marginBottom: '10px' }}
                          >
                            <Grid
                              item
                              container
                              xs={6}
                              direction={'column'}
                            >
                              <Typography
                                style={{ textAlign: 'left' }}
                              >
                                <CSVLink
                                  className={classes.link}
                                  style={{ fontSize: '15px' }}
                                  data={
                                    (dryRunResults.issues as unknown as Data) ||
                                    ''
                                  }
                                  filename={`${selectedFileLabel}-import-warnings-log.csv`}
                                >
                                  Download warnings log
                                </CSVLink>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </>
                      )}
                    {PermissionsService.hasPermission(
                      PermissionsPermissionsEnum.LOADPLANWRITE,
                    ) && (
                        <Grid item xs={12}>
                          <FormControlLabel
                            style={{ width: '100%' }}
                            control={
                              <Checkbox
                                color={undefined}
                                classes={{
                                  root: classes.checkbox,
                                  checked: classes.checked,
                                }}
                                checked={activateImmediately}
                                onChange={(e) => {
                                  setActivateImmediately(
                                    e.target.checked,
                                  );
                                }}
                              />
                            }
                            label={`Activate ${loadPlanLabels.singular} immediately`}
                            data-testid='activate-load-plan-immediately'
                          />
                        </Grid>
                      )}

                    <Box m={0.8} />
                  </Grid>
                )}
              </div>
            </DialogContent>
            {!dryRunComplete && (
              <DialogActions>
                <Button
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </Button>
                <Button
                  data-testid={'upload-selected-load-plan'}
                  disabled={!selectedFile}
                  onClick={(e) => {
                    uploadLoadplanFile();
                  }}
                  className={classes.button}
                >
                  UPLOAD
                </Button>
              </DialogActions>
            )}
            {dryRunComplete && (
              <DialogActions>
                <Button
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={(e) => {
                    uploadLoadplanFile(false);
                  }}
                  className={classes.button}
                  data-testid={'import-load-plan'}
                >
                  IMPORT
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
    },
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.gold,
      },
    },
    datePicker: {
      margin: '10px',
    },
    form: {
      margin: '20px 0px 8px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
        fontSize: 16,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    draggingActive: {
      borderColor: colors.lightGray,
    },
    dropRejected: {
      borderColor: 'red',
    },
    grayTitle: {
      color: colors.gray,
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: colors.gray,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: colors.lightGold,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: colors.darkGold,
      height: '5px',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
      backgroundColor: colors.secondaryWhite,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    grayText: {
      fontSize: 12,
      color: colors.gray,
      lineHeight: '14px',
    },
    goldText: {
      fontSize: 12,
      color: colors.darkGold,
      lineHeight: '14px',
    },
  })),
)(UploadCSVDialog);
