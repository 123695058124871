import {
  OutboundloadsApi,
  OutboundLoadLoadStatusEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

class OLoads {
  private api: OutboundloadsApi;

  constructor() {
    this.api = new OutboundloadsApi(
      undefined,
      undefined,
      axios as any,
    );

    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  public async getAll(params: {
    page?: number;
    dockdoor?: number;
    pageSize?: number;
    outboundLoadStatus?: 'OPEN' | 'LOADING' | 'DISPATCHED';
    sortBy?: '-load_time' | 'load_time';
    owner?: number[];
    carrier?: number[];
    route?: number[];
    stop?: number[];
    hidePending?: boolean;
    fromDate?: string;
    toDate?: string;
    defects?: Array<'EXCEPTION_DISPATCH_FORCED'>;
  }) {
    return this.api.outboundloadsList({
      ...params,
      fromDate: params.fromDate
        ? params.fromDate
        : dateUtils.fromDate(),
      toDate: params.toDate ? params.toDate : dateUtils.toDate(),
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.outboundloadsRead({ id });
  }

  public async getCSVExport(params: {
    id?: number;
    fromDate?: string;
    toDate?: string;
  }) {
    return this.api.outboundloadsCsv({
      fromDate: params.fromDate,
      toDate: params.toDate,
      outboundLoadIds: params.id ? [params.id] : undefined,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async create(
    route: number,
    loadTime: Date,
    dockdoor?: number,
    capacity?: number,
    name?: string,
    trailer_id?: string | null,
    bol?: string,
  ) {
    return this.api.outboundloadsCreate({
      data: {
        route: route,
        dockdoor: dockdoor,
        load_time: loadTime.toISOString(),
        capacity,
        name,
        trailer_id,
        bol,
      },
    });
  }

  public async update(
    id: number,
    route: number,
    loadTime?: Date,
    dockdoor?: number,
    status?: OutboundLoadLoadStatusEnum,
    capacity?: number,
    trailer_id?: string | null,
    name?: string,
    bol?: string | null,
  ) {
    return this.api.outboundloadsPartialUpdate({
      id: id,
      data: {
        route: route,
        dockdoor: dockdoor,
        load_time: loadTime?.toISOString(),
        load_status: status,
        capacity,
        trailer_id,
        name,
        bol,
      },
    });
  }

  public async delete(id: number) {
    return this.api.outboundloadsDelete({ id });
  }

  public async manifest(id: number) {
    return this.api.outboundloadsManifest({ id });
  }

  public async getOutboundloadsState(id: number) {
    return this.api.outboundloadsState({ id });
  }
}

export default new OLoads();
