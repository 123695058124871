import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import {
  formatDuration,
  formatMinutesSeconds,
  formatDecimals,
} from '../ChartHelperFunctions';
import colors from '../../../../utils/colors';

type Data =
  | 'duration'
  | 'minutesSeconds'
  | 'mpp'
  | 'volume'
  | 'wholeNumber'
  | undefined;

interface Props {
  chartData:
    | {
        arg: string;
        value: number | string;
      }[]
    | undefined;
  height?: number;
  dataType: Data;
  dataTestId: string;
  fill?: string;
}

const formatLabel = (label: string | number, dataType: Data) => {
  switch (dataType) {
    case 'duration':
      return formatDuration(Number(label));
    case 'minutesSeconds':
      return formatMinutesSeconds(Number(label));
    case 'volume':
      return formatDecimals(label.toString());
    case 'mpp':
      //round to 1 decimal place
      return Number(label).toFixed(2);
    case 'wholeNumber':
      return Math.round(Number(label));
    default:
      return label;
  }
};

const CustomizedLabel: React.FC<any> = (props) => {
  const { dataType, x, y, fill, value, width } = props;
  return (
    <text
      x={x + width / 2}
      y={y}
      dy={-4}
      fill={fill}
      textAnchor='middle'
    >
      {value !== 0 && formatLabel(value, dataType)}
    </text>
  );
};

const chartDomain = (dataMax: number, dataType: Data) => {
  if (dataMax <= 0) return 2;
  dataMax = Math.ceil(dataMax * 1.1);
  if (dataType === 'minutesSeconds') {
    dataMax = dataMax + (120 - (dataMax % 120)); // round up to even number of minutes
    return dataMax;
  } else if (dataType === 'mpp') {
    return dataMax;
  } else {
    if (dataMax % 3 === 0) {
      return dataMax;
    } else if (dataMax % 2 === 0) {
      return dataMax;
    } else return dataMax + 1;
  }
};

const BarChartComponent: React.FC<Props> = ({
  chartData,
  height,
  dataType,
  dataTestId,
  fill,
}) => {
  const responsiveWidth =
    process.env.NODE_ENV === 'test' ? 100 : '100%';
  return (
    <div data-testid={dataTestId}>
      <ResponsiveContainer width={responsiveWidth} height={height}>
        <BarChart
          data={chartData}
          barGap={0}
          barCategoryGap={8}
          margin={{
            top: 20,
            bottom: 10,
            left: -20,
            right: 20,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='arg' tick={{ fontSize: '0.67em' }} />
          <YAxis
            yAxisId='data1'
            orientation='left'
            axisLine={false}
            domain={[0, (dataMax) => chartDomain(dataMax, dataType)]}
            tick={{ fontSize: '0.67em' }}
            tickFormatter={(label) => formatLabel(label, dataType)}
          />
          <Bar
            yAxisId='data1'
            dataKey='value'
            fill={fill || colors.gray}
            label={<CustomizedLabel dataType={dataType} />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
