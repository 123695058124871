import React, { useState, useEffect, Fragment } from 'react';
import { NonAuthRoutes, AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import { Box, Typography } from '../primitives/index';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
// import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import styles from './header.styles';
import { withStyles } from '@material-ui/core/styles';
import DateOrTimePicker from '../datePicker/DateOrTimePicker';
import colors from '../../utils/colors';
import dateUtils from '../../services/utils/date';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import moment from 'moment';

// Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RestoreIcon from '@material-ui/icons/Restore';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// Services
import WeatherService from '../../services/Weather.service';
import LocalStorageService from '../../services/LocalStorage.service';
import SystemService from '../../services/System.service';
import Auth from '../../services/Auth.service';
import PermissionsService from '../../services/Permissions.service';

// Utils
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
} from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  type?: string;
  navActive: boolean;
  setNavActive: (active: boolean) => void;
}

const Header: React.FC<Props> = ({
  classes,
  type = 'default',
  navActive,
  setNavActive,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const [weatherAnchorEl, setWeatherAnchorEl] =
    useState<null | HTMLElement>(null);
  const [datePickerAnchorEl, setDatePickerAnchorEl] =
    useState<null | HTMLElement>(null);
  const [currentTime, setCurrentTime] = React.useState<String>();
  const [weatherData, setWeatherData] = useState<any | undefined>({
    icon: 'na',
  });

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isWeatherOpen = Boolean(weatherAnchorEl);

  const [showResetDBDialog, setShowResetDBDialog] =
    useState<boolean>(false);

  const userFullName: any = LocalStorageService.getMyFullName();

  const getWeather = async () => {
    const station = await LocalStorageService.getMyStationData();
    try {
      if (station && station.zipcode) {
        const data = await WeatherService.getWeatherForStation(
          station.zipcode,
        );
        setWeatherData(data);
      } else {
        //try again in 2s, station data is not set yet
        setTimeout(getWeather, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleWeatherOpen = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setWeatherAnchorEl(event.currentTarget);
  };

  const handleWeatherClose = () => {
    setWeatherAnchorEl(null);
  };

  const resetDB = async () => {
    if (process.env.REACT_APP_ENV !== 'production') {
      setShowProgress(true);
      try {
        await SystemService.resetDB();
        await globalStationOptionsUtils.setStationData();
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      disableScrollLock={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {PermissionsService.hasPermission(
        PermissionsPermissionsEnum.STATIONUSERSELFREAD,
      ) && (
        <MenuItem
          onClick={() => {
            browserHistory.push(AuthRoutes.PROFILE);
          }}
          id='header-link-profile'
        >
          My CloudSort profile
        </MenuItem>
      )}
      {PermissionsService.hasPermission(
        PermissionsPermissionsEnum.WEBHOOKREAD,
      ) &&
        PermissionsService.belongsToAnyOfRoles([
          PermissionsRoleTypeEnum.ORGADMIN,
          PermissionsRoleTypeEnum.SUPERUSER,
        ]) && (
          <MenuItem
            onClick={() => {
              browserHistory.push(AuthRoutes.WEBHOOKS);
            }}
            id='header-link-webhooks'
          >
            Webhooks
          </MenuItem>
        )}

      {process.env.REACT_APP_ENV !== 'production' &&
        (window.location.host.includes('staging') ||
          (window.location.host.includes('demo') &&
            PermissionsService.belongsToAnyOfRoles([
              PermissionsRoleTypeEnum.SUPERUSER,
            ])) ||
          window.location.host.includes('preview') ||
          window.location.host.includes('development') ||
          window.location.host.includes('localhost')) && [
          <MenuItem
            key='header-tracking-page'
            id='header-link-tracking-page'
            onClick={(e) => {
              e.preventDefault();
              browserHistory.push(NonAuthRoutes.TRACKING);
            }}
          >
            Tracking Page
          </MenuItem>,
          <MenuItem
            key='header-reset-db'
            id='header-link-reset-db'
            onClick={(e) => {
              e.preventDefault();
              handleMenuClose();
              setShowResetDBDialog(true);
            }}
          >
            Reset DB
          </MenuItem>,
        ]}
      <MenuItem
        data-testid={'header-logout'}
        id='header-link-logout'
        onClick={(e) => {
          e.preventDefault();
          Auth.logOut({}).then(() => {
            browserHistory.push(NonAuthRoutes.LOGIN);
          });
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label='show 4 new mails' color='inherit'>
          <MailOutlineOutlinedIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label='show 11 new notifications'
          color='inherit'
        >
          <NotificationsNoneOutlinedIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem
        onClick={handleProfileMenuOpen}
        id='header-link-account'
      >
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>{userFullName || 'Account'}</p>
      </MenuItem>
    </Menu>
  );

  const weatherTooltipId = 'weather-tooltip';
  const renderWeatherTooltip = (
    <Menu
      anchorEl={weatherAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={weatherTooltipId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isWeatherOpen}
      onClose={handleWeatherClose}
    >
      {!!weatherData && (
        <div className={classes.weatherTooltip}>
          <img
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/header/weather-icons/${weatherData.icon}.png`}
            alt='Weather'
            width='48'
            height='auto'
          />
          <h4>{weatherData.title}</h4>
          <p>{weatherData.temperature} &deg;F</p>
          {weatherData.cityID && (
            <p>
              <a
                className={classes.weatherMoreInfo}
                target='_blank'
                rel='noopener noreferrer'
                href={`https://openweathermap.org/city/${weatherData.cityID}`}
              >
                More Info
              </a>
            </p>
          )}
        </div>
      )}
      {!weatherData && (
        <div className={classes.weatherTooltip}>
          <h3>There is no weather information at the moment.</h3>
        </div>
      )}
    </Menu>
  );

  const getGMT = () => {
    let hour = new Date().getUTCHours();
    let minutes: number | string = new Date().getUTCMinutes();
    const middayConfig = hour >= 12 ? 'PM' : 'AM';

    hour = hour > 12 ? hour - 12 : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `GMT: ${
      hour.toString().length === 2 ? '' : '0'
    }${hour}:${minutes} ${middayConfig}`;
  };

  const getCurrentTime = () => {
    return new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  useEffect(() => {
    setCurrentTime(getCurrentTime());
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    getWeather();

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {showProgress && <ProgressIndicator />}
      <Box style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
        <div className={classes.grow}>
          <AppBar position='static' className={classes.appbar}>
            <Toolbar disableGutters className={classes.toolbar}>
              <Box style={{ display: 'flex' }}>
                <IconButton
                  onClick={() => {
                    setNavActive(!navActive);
                  }}
                  className={classes.headerMenuToggle}
                >
                  {navActive ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <Link to={AuthRoutes.DASHBOARD}>
                  <img
                    src={`${
                      process.env.REACT_APP_BASENAME || ''
                    }/header/cloudsort-full-transparent.png`}
                    width='185'
                    height='auto'
                    alt='CloudSort'
                    className={classes.headerLogo}
                  />
                </Link>
              </Box>
              {/* <div className={classes.searchHoder}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <img
                    src={'/header/search.png'}
                    alt='Search'
                    width='20'
                    height='20'
                  />
                </div>
                <InputBase
                  placeholder='Search for package/container/operator'
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            </div> */}

              <DateOrTimePicker
                variant={'dialog'}
                date={LocalStorageService.getSystemDate()}
                triggerEl={datePickerAnchorEl}
                onAfterClose={() => {
                  setDatePickerAnchorEl(null);
                }}
                onAfterChange={(date) => {
                  if (date) {
                    if (moment(date).isSame(new Date(), 'date')) {
                      LocalStorageService.removeSystemDate();
                    } else {
                      LocalStorageService.setSystemDate(date);
                    }
                    let urlQueryParams: string[] = [];
                    if (window.location.href.includes('?'))
                      urlQueryParams = window.location.href
                        .split(/[?#]/)[1]
                        .split('&');

                    // Reset page url query param to 1 on date change
                    if (
                      urlQueryParams.filter((el) =>
                        el.includes('page='),
                      ).length
                    ) {
                      urlQueryParams = urlQueryParams.map(
                        (param: string) => {
                          if (param.includes('page=')) {
                            return 'page=1';
                          }
                          return param;
                        },
                      );

                      window.history.replaceState(
                        null,
                        '',
                        `?${urlQueryParams.join('&')}`,
                      );
                    }

                    window.location.reload();
                  }
                }}
                rightAlign={true}
                id={'date-picker-header'}
              />

              <div className={classes.sectionDesktop}>
                <div
                  className={classes.timeHolder}
                  id='header-link-current-time'
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    PermissionsService.hasPermission(
                      PermissionsPermissionsEnum.STATIONREPORTREAD,
                    ) && setDatePickerAnchorEl(event.currentTarget);
                  }}
                >
                  {dateUtils.isSystemDateToday() ? (
                    <Fragment>
                      <div className={classes.GMTTime}>
                        {getGMT()}
                      </div>
                      <div>
                        <Typography
                          variant='h6'
                          className={classes.currentTime}
                        >
                          {currentTime}
                        </Typography>
                      </div>
                      <div className={classes.currentDate}>
                        {new Date().toDateString()}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <IconButton className={classes.notToday}>
                        <RestoreIcon />
                      </IconButton>
                      <div
                        className={classes.currentDate}
                        style={{ color: colors.darkGold }}
                      >
                        {new Date(
                          LocalStorageService.getSystemDate(),
                        ).toDateString()}
                      </div>
                    </Fragment>
                  )}
                </div>

                {/* <IconButton
                color='inherit'
                className={
                  classes.borderLeft + ' ' + classes.paddingButton
                }
              >
                <img
                  src={'/header/alerts.png'}
                  alt='Alerts'
                  width='24'
                  height='auto'
                />
              </IconButton> */}
                {type === 'default' && (
                  <IconButton
                    aria-label='Weather Info'
                    aria-controls={weatherTooltipId}
                    aria-haspopup='true'
                    onClick={handleWeatherOpen}
                    color='inherit'
                    className={
                      classes.borderLeft + ' ' + classes.paddingButton
                    }
                  >
                    <img
                      src={`${
                        process.env.REACT_APP_BASENAME || ''
                      }/header/weather-icons/${
                        weatherData?.icon
                      }.png`}
                      alt='Search'
                      width='24'
                      height='auto'
                    />
                  </IconButton>
                )}
                {type === 'default' && (
                  <MenuItem
                    aria-controls={menuId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    color='inherit'
                    className={
                      classes.borderLeft + ' ' + classes.paddingMenu
                    }
                    data-testid={'header-menu'}
                  >
                    <div className={classes.accountNameInitials}>
                      {userFullName &&
                        userFullName.split(' ')[0].charAt(0) +
                          userFullName
                            .split(' ')
                            [
                              userFullName.split(' ').length - 1
                            ].charAt(0)}
                    </div>
                    <div className={classes.accountName}>
                      {userFullName || 'Account'}
                    </div>
                    <MoreVertIcon />
                  </MenuItem>
                )}
                {type === 'profile' && (
                  <MenuItem
                    onClick={() => {
                      browserHistory.push(AuthRoutes.DASHBOARD);
                    }}
                    color='inherit'
                    className={
                      classes.borderLeft +
                      ' ' +
                      classes.paddingMenu +
                      ' ' +
                      classes.exitProfileButton
                    }
                    data-testid={'header-close-profile'}
                  >
                    Exit CloudSort Profile <CloseIcon />
                  </MenuItem>
                )}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label='More...'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          {renderMobileMenu}
          {renderMenu}
          {renderWeatherTooltip}
        </div>
        <ConfirmationDialog
          data-testid={'reset-db-dialog'}
          title={'Reset Database'}
          msg={`Are you sure you want to reset the database?`}
          primaryActionLabel={'Reset'}
          onPrimaryAction={() => {
            resetDB();
          }}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowResetDBDialog(false);
          }}
          isOpen={showResetDBDialog}
        />
      </Box>
    </Fragment>
  );
};

export default withStyles(styles)(Header);
