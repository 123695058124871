import { createStyles, Theme } from '@material-ui/core/styles';
import selectStyles from '../select/select.styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  ...detailsPageStyles,
  ...selectStyles,
  ...sectionPageBaseStyle,
  ...filterBadgeStyle,
  detailInfoHolder: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'start',
    textAlign: 'left',
    color: '#222222',
    '& b': {
      opacity: 1,
    },
    '& p': {
      opacity: 0.7,
      marginTop: theme.spacing(1),
    },
  },
  detailInfoButton: {
    margin: '0 0 10px 0',
    boxSizing: 'border-box',
    height: 40,
    border: '0.8px solid #E1E1E1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    color: '#222222',
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      border: '0.8px solid #E1E1E1',
      backgroundColor: '#FFFFFF',
      color: '#222222',
      textTransform: 'none',
      fontWeight: 'normal',
    },
    '& svg': {
      marginLeft: 5,
    },
  },
  asyncSelect: {
    flexGrow: 1,
    minWidth: 300,
  },
  syncSelect: {
    flexGrow: 1,
    minWidth: 300,
    margin: '15px 0',
  },
  dialogRoot: {
    overflow: 'hidden',
    minWidth: 600,
  },
  inputPadding: {
    paddingLeft: '5px',
    paddingRigth: '5px',
  },
  dockDoorMenu: {
    marginTop: 47,
  },
  autoCompleteComponent: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(5px, 17px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      top: '5px',
      left: '5px',
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '5px',
    },
    '& .MuiInput-underline': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  datePicker: {
    '& .MuiTextField-root': {
      position: 'absolute',
      width: '100%',
      top: 3,
      left: 5,
    },
  },
  headerFilterButton: {
    color: colors.black,
    backgroundColor: colors.veryLightGray,
    borderColor: colors.veryLightGray,
    minWidth: '40px',
    marginLeft: '20px',
  },
}));
