import { CustomersApi } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class Customers {
  private api: CustomersApi;

  constructor() {
    this.api = new CustomersApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
  }

  public async getAll(
    page?: number,
    search?: string,
    pageSize?: number,
  ) {
    return this.api.customersList({
      search,
      page,
      pageSize,
      stationId: EphemeralStateService.getMyStationId(),
      organizationId: EphemeralStateService.getMyOrgId(),
    });
  }

  public async getById(id: number) {
    return this.api.customersRead({
      id: id,
      stationId: EphemeralStateService.getMyStationId(),
      organizationId: EphemeralStateService.getMyOrgId(),
    });
  }
}

export default new Customers();
