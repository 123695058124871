import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import selectStyles from '../select/select.styles';

export default createStyles((theme: Theme) => ({
  ...selectStyles,
  ...sectionPageBaseStyle,
  paper: {
    width: '100%',
    height: 'auto',
    borderRadius: 4,
    backgroundColor: colors.white,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    marginBottom: 16,
    padding: '10px 0',
  },
  hiddenInput: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  title: {
    color: colors.title,
    fontSize: 24,
    fontWeight: 'normal',
    borderBottom: `1px solid ${colors.veryLightGray}`,
    paddingBottom: 15,
    marginBottom: 30,
  },
  heading4: {
    fontSize: 14,
    color: colors.lightGray,
  },
  smallTitle: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  buttonFilled: {
    background: `${colors.darkGold}!important`,
    color: colors.white,
    border: `2px solid ${colors.darkGold}`,
    '& svg': {
      marginRight: 10,
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
    '&:disabled': {
      background: `${colors.gray}!important`,
    },
  },
  buttonOutlined: {
    color: colors.darkGold,
    border: `2px solid ${colors.darkGold}`,
    '& svg': {
      marginRight: 10,
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
  backButton: {
    position: 'relative',
    left: '-8px',
    display: 'flex',
    color: colors.darkGold,
    cursor: 'pointer',
    width: 70,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
