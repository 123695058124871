import React, { useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import colors from '../../utils/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import qrCodeUtils from '../../utils/qrCode';
import { AlertBanner } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';

// Icons
import PrintIcon from '@material-ui/icons/Print';

// Services
import StationsService from '../../services/Stations.service';
import configurationUtils from '../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  areaId?: number;
  downloadName?: string;
}

const PrintQrCodesDialog: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  areaId,
  downloadName,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [state, setState] = useState({
    all: false,
    areas: false,
    staff: false,
    zones: false,
    activeLP: false,
    inactiveLP: false,
    dockDoor: false,
  });

  const {
    all,
    areas,
    inactiveLP,
    staff,
    zones,
    activeLP,
    dockDoor,
  } = state;

  const areasActive = configurationUtils.getIsModuleActive('AREA');

  const handleClose = () => {
    setState({
      all: false,
      areas: false,
      inactiveLP: false,
      staff: false,
      zones: false,
      activeLP: false,
      dockDoor: false,
    });
    onAfterClose();
  };

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.checked;
    if (name === 'all') {
      setState({
        all: value,
        activeLP: areasActive ? value : false,
        inactiveLP: areasActive ? value : false,
        zones: areasActive ? value : false,
        areas: !areasActive ? false : value,
        staff: !!areaId ? false : value,
        dockDoor: !!areaId ? false : value,
      });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      qrCodeUtils.download(
        await StationsService.getLabel({
          area: areaId,
          areas,
          zones,
          activeLoadpoints: activeLP,
          inactiveLoadpoints: inactiveLP,
          dockdoors: dockDoor,
          staff,
        }),
        downloadName,
      );
      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minWidth: inXsScreen ? 200 : 450,
            }}
          >
            {error && (
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            )}
            <PrintIcon
              style={{
                margin: '10px auto',
                height: 'auto',
                width: 40,
                color: colors.darkGold,
              }}
            />
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 18,
              }}
            >
              Printing QR Codes
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 25px',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  color: colors.darkGold,
                  width: '100%',
                  margin: '10px 0',
                }}
              >
                Select QR Codes to Print
              </span>
              <div
                className={classes.formGroup}
                style={{
                  maxHeight: inXsScreen ? 'auto' : areaId ? 120 : 170,
                }}
              >
                <Fragment>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={all}
                          onChange={handleChange('all')}
                          value='all'
                        />
                      }
                      label='Select All'
                    />
                  </FormGroup>
                  {areasActive && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={areas}
                            onChange={handleChange('areas')}
                            value='areas'
                          />
                        }
                        label={configurationUtils.getPageTitle(
                          false,
                          'AREA',
                        )}
                      />
                    </FormGroup>
                  )}
                  {areasActive && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={activeLP}
                            onChange={handleChange('activeLP')}
                            value='activeLP'
                          />
                        }
                        label='Active Loadpoints'
                      />
                    </FormGroup>
                  )}
                  {areasActive && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inactiveLP}
                            onChange={handleChange('inactiveLP')}
                            value='inactiveLP'
                          />
                        }
                        label='Inactive Loadpoints'
                      />
                    </FormGroup>
                  )}
                  {!areaId && ( //not area dashboard
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staff}
                            onChange={handleChange('staff')}
                            value='staff'
                          />
                        }
                        label={configurationUtils.getPageTitle(
                          false,
                          'STAFF',
                        )}
                      />
                    </FormGroup>
                  )}

                  {areasActive && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={zones}
                            onChange={handleChange('zones')}
                            value='zones'
                          />
                        }
                        label='Zones'
                      />
                    </FormGroup>
                  )}
                </Fragment>

                <Fragment>
                  {!areaId && ( //not area dashboard
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dockDoor}
                            onChange={handleChange('dockDoor')}
                            value='dockDoor'
                          />
                        }
                        label={configurationUtils.getPageTitle(
                          false,
                          'DOCK_DOOR',
                        )}
                      />
                    </FormGroup>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            CANCEL
          </Button>
          <Button
            onClick={onGetQrCick}
            className={classes.button}
            disabled={!Object.values(state).some((el) => el)}
          >
            PRINT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: colors.darkGold,
      },
    },
  })),
)(PrintQrCodesDialog);
