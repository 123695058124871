import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
} from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Typography } from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import { AlertBanner } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';
import colors from '../../../utils/colors';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
import browserHistory from '../../../utils/browserHistory';

import Button from '@material-ui/core//Button';
import Box from '@material-ui/core//Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ZoneSettings from './ZoneModuleTable';
import InputLabel from '@material-ui/core/InputLabel';
import { debounce, isEqual } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import selectStyles from '../../select/select.styles';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Types
import {
  AreaAreaTypeEnum,
  AreaDetailsAreaTypeEnum,
  User,
  LoadPoint,
  LoadPointDetails,
  ContainerDetailsContainerTypeEnum,
  Zone,
  AreaDetails,
  ZoneDetails,
  // Location,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import AreasService from '../../../services/Areas.service';
import FmcService from '../../../services/Fmc.service';
import ZonesService from '../../../services/Zones.service';
import LoadPointsService from '../../../services/LoadPoints.service';
import ZoneModulesService from '../../../services/ZoneModules.service';
import configurationUtils from '../../../utils/configurationUtils';
import PermissionsService from '../../../services/Permissions.service';
import { AuthRoutes } from '../../../interfaces/routes';
//import EphemeralStateService from '../../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../../asyncSelect/asyncSelect.styles';
import { TypeAheadItem } from '../../../interfaces/components';
import { components, ValueType } from 'react-select';
import { noOptionsMessage } from '../../asyncSelect/utils';
//import SchemesService from '../../../services/Schemes.service';

const ALPHABET = [...'abcdefghijklmnopqrstuvwxyz'];

interface Props {
  classes: { [key: string]: string };
  match: any;
}

export interface FilterState {
  scheme?: string;
  fmc?: string;
}

export interface EditFunctions {
  onRemoveModule: (id: number) => void;
  onRemoveZone: (id: number) => void;
  onEditLoadPoint: (lp: LoadPointDetails) => Promise<void>;
  onEditZone: (data: ZoneDetails) => void;
}

export interface UtilsFunctions {
  showProgress: (showProgress: boolean) => void;
  handleError: (e: AxiosError) => void;
  getAllZoneNames: () => (string | null | undefined)[];
  getActiveLoadpointsForZone: (zone: number) => Promise<LoadPoint[]>;
}

export const goToAddStationModule = (
  area: string,
  areaType: string,
  zone: string,
) => {
  browserHistory.push(
    `${AuthRoutes.AREA}/${area}/settings/${areaType}/add-station-module/${zone}`,
  );
};

export const goToAddCorporateModule = (
  area: string,
  areaType: string,
  zone: string,
) => {
  browserHistory.push(
    `${AuthRoutes.AREA}/${area}/settings/${areaType}/add-corporate-module/${zone}`,
  );
};

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon style={{ color: colors.dark }} />
      </components.DropdownIndicator>
    )
  );
};

const AreasSettings: React.FC<Props> = ({ classes, match }) => {
  const [zones, setZones] = useState<Zone[]>();
  //const [schemesOptions, setSchemesOptions] = useState<Location[]>();
  const [nameToAdd, setNameToAdd] = useState<string | null>();
  const [lpOptipns, setLpOptipns] = useState<LoadPoint[]>();
  const [areaData, setAreaData] = useState<AreaDetails>();
  const [error, setError] = useState<string>();
  const [dialogError, setDialogError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<FilterState>({
    scheme: 'ALL',
    fmc: 'ALL',
  });

  const fetchZones = async () => {
    try {
      const data = (
        await ZonesService.getAll(
          undefined,
          match.params.id,
          MAX_PAGE_SIZE,
        )
      ).data.results;
      data.sort((a, b) => {
        if (a.name && b.name) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        }
        return 0;
      });
      setZones(data);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  // const fetchSchemes = async () => {
  //   try {
  //     const res = await SchemesService.getAll({
  //       pageSize: MAX_PAGE_SIZE,
  //       loadplanStation: EphemeralStateService.getMyStationId(),
  //       isActive: true,
  //     });
  //     setSchemesOptions(res.data.results);
  //   } catch (e) {
  //     handleError(e);
  //   }
  // };

  const fetchLoadPoints = async () => {
    try {
      const data = (
        await LoadPointsService.getAll({
          areaType:
            match.params.areaType === AreaAreaTypeEnum.SECONDARY
              ? AreaAreaTypeEnum.SECONDARY
              : undefined,
          pageSize: MAX_PAGE_SIZE,
        })
      ).data.results;
      setLpOptipns(data);
    } catch (e) {
      handleError(e);
    }
  };
  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.id,
        label: dataEl.full_name,
      };
    });
  };

  const loadFmcOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const fetchArea = async () => {
    try {
      const data = (await AreasService.getById(match.params.id)).data;
      setAreaData(data);
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const getAllZoneNames = () => {
    return zones?.map((z) => z.name) || [];
  };

  const getActiveLoadpointsForZone = async (zone: number) => {
    return (
      await LoadPointsService.getAll({
        zone,
        isActive: true,
        pageSize: MAX_PAGE_SIZE,
      })
    ).data.results;
  };

  const addZone = async (name: string) => {
    setShowProgress(true);
    try {
      await ZonesService.create({
        name: name,
        area: match.params.id,
      });
      fetchZones();
    } catch (e) {
      handleError(e);
      setShowProgress(false);
    }
  };

  const onRemoveZone = async (id: number) => {
    setShowProgress(true);
    try {
      if (zones) {
        await ZonesService.delete(id);
        fetchZones();
      }
    } catch (e) {
      handleError(e);
      setShowProgress(false);
    }
  };

  const onRemoveModule = async (id: number) => {
    setShowProgress(true);
    try {
      await ZoneModulesService.delete(id);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onEditLoadPoint = async (lp: LoadPointDetails) => {
    setShowProgress(true);
    try {
      const { data } = await LoadPointsService.updateById(lp.id!, lp);
      const lpToUpdate = lpOptipns?.find(
        (lpOp) => lpOp.id === data.id,
      );
      if (lpToUpdate && !isEqual(lpToUpdate?.fmcs, data.fmcs)) {
        lpToUpdate.fmcs = data.fmcs;
        // @ts-ignore
        setLpOptipns([...lpOptipns]);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onEditZone = async (zone: ZoneDetails) => {
    setShowProgress(true);
    try {
      await ZonesService.update(zone);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onFilter = (state: FilterState) => {
    setFilterState(state);
    fetchZones();
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const renderFilters = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {areaData?.area_type ===
          AreaDetailsAreaTypeEnum.SECONDARY && (
          <Fragment>
            {/* <FormControl className={classes.filter}>
              <InputLabel>Scheme</InputLabel>
              <Select
                disabled={!!error}
                value={filterState?.scheme}
                onChange={(e) => {
                  if (e.target.value) {
                    const stateObj: FilterState = { ...filterState };
                    stateObj.scheme = e.target.value as string;
                    onFilter(stateObj);
                  }
                }}
                variant='outlined'
                labelWidth={35}
                className={classes.muiSelect}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                {schemesOptions?.map((scheme) => {
                  return (
                    <MenuItem key={scheme.id} value={scheme.id}>
                      {scheme.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}

            <FormControl className={classes.filter}>
              <InputLabel
                className={classes.selectLabel}
                style={{ fontSize: 14, top: '-30px' }}
              >
                Carrier
              </InputLabel>
              <AsyncSelect<TypeAheadItem>
                isClearable
                styles={{
                  ...asyncSelectStyles,

                  menuPortal: (styles: any) => {
                    return {
                      ...styles,
                      zIndex: 9999,
                    };
                  },
                  control: (styles: any, state: any) => {
                    return {
                      ...styles,
                      border: '2px solid #d39c6e !important',
                      backgroundColor: 'transparent',
                      background: '#F1F1F1',
                      padding: '10px 0',
                      boxShadow: 'none !important',
                      outline: `none !important`,
                    };
                  },
                  placeholder: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.lightGray,
                    };
                  },
                }}
                loadOptions={loadFmcOptions}
                onChange={(option: ValueType<TypeAheadItem>) => {
                  const stateObj: FilterState = { ...filterState };
                  stateObj.fmc = option
                    ? (option as TypeAheadItem).label
                    : 'ALL';
                  onFilter(stateObj);
                }}
                placeholder={'Start Typing...'}
                value={{
                  value: filterState.fmc || '',
                  label: filterState.fmc || 'ALL',
                }}
                menuPortalTarget={document.body}
                components={{ DropdownIndicator }}
                noOptionsMessage={noOptionsMessage}
              />
            </FormControl>
          </Fragment>
        )}
      </div>
    );
  };

  const renderAddZoneDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={typeof nameToAdd === 'string'}
        TransitionComponent={Transition}
        onClose={() => {
          setNameToAdd(null);
        }}
      >
        <DialogTitle>Add Zone</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          {dialogError && (
            <>
              <AlertBanner
                severity='info'
                alertTitle={'Duplicate name'}
                alertMsg={dialogError}
              />
              <Box mt={2} />
            </>
          )}
          <FormControl
            className={classes.formControl}
            style={{ width: '100%', margin: '5px 0px' }}
          >
            <TextField
              data-testid='area-settings:add-zone-dialog:zone-name'
              autoFocus
              label='Name'
              type={'string'}
              defaultValue={nameToAdd}
              onChange={(e) => {
                setNameToAdd(e.target.value);
              }}
              error={
                configurationUtils.isModuleNameValidated('ZONE') &&
                !/^[A-Z]$/.test(nameToAdd || '')
              }
              helperText={
                configurationUtils.isModuleNameValidated('ZONE')
                  ? 'A single capital letter.'
                  : undefined
              }
              FormHelperTextProps={{ style: { paddingLeft: '5px' } }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputPadding,
              }}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setNameToAdd(null);
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='area-settings:add-zone-dialog:add-zone-button'
            disabled={
              !nameToAdd ||
              (configurationUtils.isModuleNameValidated('ZONE') &&
                !/^[A-Z]$/.test(nameToAdd || ''))
            }
            onClick={() => {
              addZone(nameToAdd!.toUpperCase());
              setNameToAdd(null);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (
      nameToAdd &&
      getAllZoneNames().includes(nameToAdd.toLowerCase())
    ) {
      setDialogError('Name is already in use.');
    } else {
      setDialogError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameToAdd]);

  const loadData = async () => {
    const promises = [fetchZones(), fetchArea(), fetchLoadPoints()];
    await Promise.all(promises);
    setAllDataLoaded(true);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'AREA')} Configuration for ${
            areaData?.name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {(showProgress || !allDataLoaded) && <ProgressIndicator />}
        {renderAddZoneDialog()}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <div
          className={classes.header}
          style={{
            marginBottom:
              areaData?.area_type === AreaDetailsAreaTypeEnum.PRIMARY
                ? 40
                : 20,
            display: 'flex',
            flexDirection: inXsScreen ? 'column' : 'inherit',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Typography
              className={classes.title}
              variant={'h3'}
              style={{ fontSize: inXsScreen ? '22px' : '24px' }}
            >
              {areaData
                ? `${configurationUtils.getPageTitle(true, 'AREA')} ${
                    areaData.name
                  } - Configuration`
                : ''}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: inXsScreen ? 10 : 0,
            }}
          >
            {areaData?.area_type ===
              AreaDetailsAreaTypeEnum.SECONDARY &&
              PermissionsService.hasPermission(
                PermissionsPermissionsEnum.STATIONWRITE,
              ) && (
                <Button
                  data-testid='area-settings-add-zone-button'
                  disabled={
                    !!error || zones?.length === ALPHABET.length
                  }
                  variant='outlined'
                  className={classes.buttonOutlined}
                  onClick={() => {
                    setNameToAdd('');
                  }}
                >
                  <AddToPhotosOutlinedIcon />
                  Add Zone
                </Button>
              )}
            {areaData?.area_type ===
              AreaDetailsAreaTypeEnum.PRIMARY && (
              <Fragment>
                <Button
                  data-testid='area-settings:create-module'
                  disabled={!!error}
                  variant='outlined'
                  className={classes.buttonOutlined}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    goToAddCorporateModule(
                      match.params.id,
                      match.params.areaType,
                      zones![0].id?.toString()!,
                    );
                  }}
                >
                  <AddToPhotosOutlinedIcon />
                  Create Module
                </Button>
                <Button
                  data-testid='area-settings:copy-module'
                  disabled={!!error}
                  variant='outlined'
                  className={classes.buttonOutlined}
                  onClick={() => {
                    goToAddStationModule(
                      match.params.id,
                      match.params.areaType,
                      zones![0].id?.toString()!,
                    );
                  }}
                >
                  <FileCopyIcon />
                  Copy Module
                </Button>
              </Fragment>
            )}
            <FormControl style={{ height: 42, display: 'none' }}>
              <Input
                disabled
                disableUnderline
                className={classes.searchInput}
                placeholder={'Search Loadpoint'}
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </div>
        {renderFilters()}
        {allDataLoaded && zones?.length
          ? zones?.map((zone: Zone) => (
              <ZoneSettings
                match={match}
                key={zone.id}
                area={areaData}
                zone={zone}
                filterState={filterState}
                editFunctions={{
                  onRemoveModule: onRemoveModule,
                  onEditLoadPoint: onEditLoadPoint,
                  onRemoveZone: onRemoveZone,
                  onEditZone: onEditZone,
                }}
                utilsFunctions={{
                  showProgress: (showProgress: boolean) => {
                    setShowProgress(showProgress);
                  },
                  handleError: (e: AxiosError) => {
                    handleError(e);
                  },
                  getAllZoneNames: getAllZoneNames,
                  getActiveLoadpointsForZone:
                    getActiveLoadpointsForZone,
                }}
                lpOptions={lpOptipns}
                containerTypeOptions={Object.values(
                  ContainerDetailsContainerTypeEnum,
                )}
              />
            ))
          : zones?.length === 0 && (
              <AlertBanner
                severity='info'
                alertTitle={
                  'There is no information to display at the moment'
                }
              />
            )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    dialogRoot: {
      overflow: 'hidden',
      minWidth: 400,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    title: {
      height: 28,
      color: colors.title,
      fontSize: 24,
      fontWeight: 'normal',
      opacity: 1,
      margin: '6px 0',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '40px 0 20px 0',
      paddingBottom: 15,
      borderBottom: '0.5px solid rgb(220, 220, 220)',
    },
    buttonOutlined: {
      color: colors.darkGold,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    searchInput: {
      height: '100%',
      backgroundColor: colors.white,
      borderRadius: 4,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
      '& svg': {
        color: '#8E8E93', // TODO: Add to colors if reused
        marginLeft: 10,
      },
    },
    filter: {
      margin: '0 0 20px 25px',
      height: 56,
      width: 175,
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
        {
          borderColor: `${colors.darkGold} !important`,
          borderWidth: 2,
        },
      '& .MuiFormLabel-root': {
        top: '-6px',
        left: 15,
        zIndex: 2,
        backgroundColor: '#f1f1f1',
        padding: `0 ${theme.spacing(1)}px`,
      },
    },
    muiSelect: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    selectLabel: {
      background: colors.dark,
      padding: '0 5px',
    },
  })),
)(AreasSettings);
