import React, { useState, useEffect, useCallback } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import { AlertBanner, Typography } from '../primitives';
import colors from '../../utils/colors';
import ErrorHandler from '../../utils/ErrorHandler';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import browserHistory from '../../utils/browserHistory';

import { Link, RouteComponentProps } from 'react-router-dom';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { formatFirstNItemsAndMore } from '../DetailsPagesFunctions';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import { debounce } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import AddSchemeDialog from '../schemes/addSchemeDialog/AddSchemeDialog';
import EditSchemeDialog from '../schemes/editSchemeDialog/EditSchemeDialog';

// Services
import SchemesService from '../../services/Schemes.service';
import PermissionsService from '../../services/Permissions.service';
import LoadPlansService from '../../services/LoadPlans.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import CancelIcon from '@material-ui/icons/Cancel';

// Types
import { Column, TypeAheadItem } from '../../interfaces/components';
import { AxiosError } from 'axios';
import {
  APIExceptionErrorCodeEnum,
  Scheme,
  LoadPlan,
  PermissionsPermissionsEnum,
  SchemeDetails,
  User,
  APIException,
} from 'cloudsort-client';
import FmcService from '../../services/Fmc.service';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import AddSchemeSetDialog from '../schemes/addSchemeSetDialog/AddSchemeSetDialog';
import { CloudUploadOutlined } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import DeleteSchemeDialog from '../schemes/deleteSchemeDialog/DeleteSchemeDialog';

const DEFAULT_ALIGN = 'center';

const COLUMNS_STOPS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: '30%',
    align: 'left',
    hide: true,
  },
  {
    id: 'name_link',
    label: 'Name',
    width: '30%',
    align: 'left',
    useCustomComponent: true,
  },
  {
    id: 'fmc_full_name',
    label: 'Carrier',
    align: DEFAULT_ALIGN,
  },
  {
    id: 'owner_identifier',
    label: 'Owner',
    align: DEFAULT_ALIGN,
  },
  {
    id: 'zipcode_count',
    label: 'ZIP Codes',
    align: DEFAULT_ALIGN,
    width: '25%',
  },
  {
    id: 'sort_param',
    label: 'Sortation Parameter',
    align: DEFAULT_ALIGN,
    width: '35%',
  },
];

interface DeleteParams {
  id: number;
  name: string;
}

interface Props extends RouteComponentProps {
  match: any;
  classes: { [key: string]: string };
}

interface DeleteSetParams {
  id: number;
  name: string;
}

const Loadplans: React.FC<Props> = ({ match, classes, location }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddSchemeDialog, setShowAddSchemeDialog] =
    useState<boolean>(false);
  const [showAddSchemeSetDialog, setShowAddSchemeSetDialog] =
    useState<boolean>(false);
  const [showEditSchemeDialog, setShowEditSchemeDialog] =
    useState<boolean>(false);
  const [showDeleteSchemeSetDialog, setShowDeleteSchemeSetDialog] =
    useState<boolean>(false);

  const [schemeCurrentlyEditing, setSchemeCurrentlyEditing] =
    useState<SchemeDetails>();
  const [deleteSchemeSetData, setDeleteSchemeSetData] =
    useState<DeleteSetParams>({
      id: 0,
      name: '',
    });
  const [
    showDeleteSchemeSetConfirmation,
    setShowDeleteSchemeSetConfirmation,
  ] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [loadplanData, setLoadplanData] = useState<LoadPlan>();
  const [fetchedLoadPlanId, setFetchedLoadPlanId] =
    useState<number>();
  const [alertMessage, setAlertMessage] = useState<string>();

  const [
    showDeleteSchemeConfirmation,
    setShowDeleteSchemeConfirmation,
  ] = useState<boolean>(false);
  const [deleteParams, setDeleteParams] = useState<DeleteParams>();
  const [schemes, setSchemes] = useState<Scheme[]>();

  const [showDeleteLPConfirmation, setShowDeleteLPConfirmation] =
    useState<boolean>(false);

  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [fileIdByUrl, setFileIdByUrl] = useState<string>();

  const fetchLoadplan = async () => {
    const id = (match.params as any).id;

    if (id) {
      //loadplan by id
      const res = await LoadPlansService.getById(id);
      setLoadplanData(res.data);
      setFetchedLoadPlanId(res.data.id);
    } else {
      //active loadplan
      const res = await LoadPlansService.getAll({ active: true });
      if (res.data.results.length) {
        setLoadplanData(res.data.results[0]);
        setFetchedLoadPlanId(res.data.results[0].id);
      } else {
        setLoadplanData(undefined);
        setFetchedLoadPlanId(undefined);
        setAlertMessage(
          `This station has no active ${configurationUtils.getPageTitle(
            true,
            'LOADPLAN',
          )}`,
        );
      }
    }
  };

  const activateLoadPlan = async () => {
    setShowProgress(true);
    try {
      await LoadPlansService.updateById(fetchedLoadPlanId!, {
        active: true,
        station: loadplanData!.station,
        created_by: loadplanData!.created_by,
      });
      browserHistory.push(
        `${AuthRoutes.LOADPLAN}/${fetchedLoadPlanId!}`,
      );
      setAlertMessage(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      //activation is too fast, adding some delay as a feedback that something is happening
      setTimeout(() => {
        setShowProgress(false);
      }, 1500);
    }
  };

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.id,
        label: dataEl.full_name,
      };
    });
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const fetchSchemes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await SchemesService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      loadplan: fetchedLoadPlanId,
      search: filterByString,
    });

    res.data.results.forEach((scheme: any) => {
      scheme.name_link = (
        <Link
          style={{ color: colors.darkGold }}
          to={`${AuthRoutes.SCHEME}/${scheme.id}`}
        >
          {scheme.name}
        </Link>
      );
      scheme.sort_param = scheme.sort_param
        ? sanitizeHtml(scheme.sort_param, {
            allowedTags: [],
          })
        : common.emptyValue;
    });

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    setSchemes(res.data.results);

    return res;
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.ResourceConflict
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are part of multiple schemes from the same carrier: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e));
  };

  const downloadBatchCSVs = async () => {
    setShowProgress(true);
    try {
      const res = await LoadPlansService.export(loadplanData!.id!);
      downloadFile(res);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const downloadFile = (res: any) => {
    const blob = new Blob([res.data], {
      type: 'text/csv;charset=utf-8',
    });

    const fileNamStr = res.request.getResponseHeader(
      'content-disposition',
    );
    saveAs(blob, fileNamStr.split('filename=')[1].replace(/"/g, ''));
  };

  const onEditScheme = async (id: number) => {
    setShowProgress(true);
    try {
      const scheme = await SchemesService.getById(id);
      setSchemeCurrentlyEditing(scheme.data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowEditSchemeDialog(true);
      setShowProgress(false);
    }
  };

  const processDeleteLP = async () => {
    setShowDeleteLPConfirmation(false);
    setShowProgress(true);
    try {
      await LoadPlansService.delete(match.params.id);
      browserHistory.push(AuthRoutes.LOADPLAN + '_list');
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const processDeleteSchemeSet = async () => {
    const id = match.params.id;
    setShowDeleteSchemeSetConfirmation(false);
    setShowDeleteSchemeSetDialog(false);
    setShowProgress(true);
    try {
      await LoadPlansService.deleteSchemeSet(id, {
        carrier: deleteSchemeSetData.name,
      });
      setDeleteSchemeSetData({
        id: 0,
        name: '',
      });
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderDeleteSchemeSetDialog = () => {
    return (
      <Dialog open={showDeleteSchemeSetDialog}>
        <DialogTitle style={{ textAlign: 'center' }}>
          <IndeterminateCheckBoxOutlinedIcon
            style={{
              color: colors.darkGold,
              width: '48px',
              height: 'auto',
            }}
          />
          <br />
          Delete Scheme Set
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Carrier</Typography>
              </Box>
              <AsyncSelect<TypeAheadItem>
                isClearable
                cacheOptions
                styles={{
                  ...asyncSelectStyles,
                  menuPortal: (styles: any) => {
                    return {
                      ...styles,
                      zIndex: 9999,
                    };
                  },
                  placeholder: (styles: any) => {
                    return {
                      ...styles,
                      color: colors.lightGray,
                    };
                  },
                  control: (styles: any, state: any) => {
                    return {
                      ...styles,
                      backgroundColor: 'transparent',
                      background: '#F1F1F1',
                      padding: '10px 0',
                      boxShadow: 'none !important',
                      outline: `none !important`,
                      border: 0,
                    };
                  },
                }}
                loadOptions={loadOptions}
                onChange={(option) => {
                  if (option as TypeAheadItem) {
                    setDeleteSchemeSetData({
                      id: Number((option as TypeAheadItem).value),
                      name: (option as TypeAheadItem).label,
                    });
                  } else {
                    setDeleteSchemeSetData({
                      id: 0,
                      name: '',
                    });
                  }
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pl={1} pr={1}>
            <Button
              className={classes.button}
              onClick={() => {
                setShowDeleteSchemeSetDialog(false);
                setDeleteSchemeSetData({
                  id: 0,
                  name: '',
                });
              }}
            >
              Cancel
            </Button>

            <Button
              data-testid={'scheme-set-delete-dialog-save'}
              className={classes.button}
              onClick={() => {
                setShowDeleteSchemeSetConfirmation(true);
              }}
            >
              Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fileId = urlParams.get('fileId');
    if (fileId) {
      setFileIdByUrl(fileId);
      setShowAddSchemeSetDialog(true);
    }
  }, []);

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.LOADPLANREAD,
    );

    fetchLoadplan();
    if ((match.params as any).imported === 'true')
      setAlertMessage(
        `${configurationUtils.getPageTitle(
          true,
          'LOADPLAN',
        )} created/imported sucessfully. You can activate it by clicking the button on the top right.`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'LOADPLAN')} ${
            (match.params as any).id
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='LOAD_PLAN'>
        <AddSchemeDialog
          loadplanId={loadplanData?.id}
          isOpen={showAddSchemeDialog}
          onAfterClose={() => {
            setShowAddSchemeDialog(false);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        <EditSchemeDialog
          editSchemeData={schemeCurrentlyEditing!}
          isOpen={showEditSchemeDialog}
          onAfterClose={() => {
            setShowEditSchemeDialog(false);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        {deleteParams && (
          <DeleteSchemeDialog
            schemeId={deleteParams!.id}
            schemeName={deleteParams!.name}
            isOpen={showDeleteSchemeConfirmation}
            onAfterClose={() => {
              setShowDeleteSchemeConfirmation(false);
            }}
            updateParent={() => {
              setLastUpdated(new Date().toISOString());
            }}
          />
        )}
        {renderDeleteSchemeSetDialog()}
        {loadplanData && loadplanData.id && (
          <AddSchemeSetDialog
            loadplanId={loadplanData.id.toString()}
            isOpen={showAddSchemeSetDialog}
            fileId={fileIdByUrl}
            onAfterClose={() => {
              setShowAddSchemeSetDialog(false);
            }}
            updateParent={() => {
              setLastUpdated(new Date().toISOString());
            }}
          />
        )}

        <ConfirmationDialog
          dataTestIdPrefix={'delete-lp-confirmation'}
          title={`Delete ${configurationUtils.getPageTitle(
            true,
            'LOADPLAN',
          )}`}
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'LOADPLAN',
          )} ${(match.params as any).id}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDeleteLP();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteLPConfirmation(false);
          }}
          isOpen={showDeleteLPConfirmation}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-scheme-set-confirmation'}
          title={'Delete Scheme Set'}
          msg={`Are you sure you want to delete ${
            deleteSchemeSetData?.name
              ? `the ${deleteSchemeSetData?.name}`
              : 'all'
          } scheme set${
            deleteSchemeSetData?.name ? '' : 's'
          } for ${configurationUtils.getPageTitle(
            true,
            'LOADPLAN',
          )} ${(match.params as any).id}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDeleteSchemeSet();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteSchemeSetConfirmation(false);
          }}
          isOpen={showDeleteSchemeSetConfirmation}
        />
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box>
                <Link
                  to={AuthRoutes.LOADPLAN + '_list'}
                  className={classes.back}
                >
                  <ArrowLeftIcon />
                  <Typography>Back</Typography>
                </Link>
              </Box>
              <Typography className={classes.title} variant={'h3'}>
                {(match.params as any).id
                  ? `${configurationUtils.getPageTitle(
                      true,
                      'LOADPLAN',
                    )} Details for ${(match.params as any).id}`
                  : `Active ${configurationUtils.getPageTitle(
                      true,
                      'LOADPLAN',
                    )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.LOADPLANREPORTREAD,
            ) && (
              <Button
                variant='outlined'
                className={classes.containedButton}
                style={{ marginLeft: 15 }}
                disabled={!batchExportEnabled}
                onClick={() => {
                  downloadBatchCSVs();
                }}
                data-testid={'export-load-plan'}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Export{' '}
                {configurationUtils.getPageTitle(true, 'LOADPLAN')}
              </Button>
            )}

            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.LOADPLANWRITE,
            ) &&
              (match.params as any).id && (
                <>
                  <Button
                    variant='outlined'
                    className={classes.containedButton}
                    style={{
                      marginLeft: 15,
                    }}
                    onClick={() => {
                      setShowDeleteLPConfirmation(true);
                    }}
                    disabled={loadplanData?.active}
                    data-testid={'delete-load-plan'}
                  >
                    <IndeterminateCheckBoxOutlinedIcon
                      style={{ marginRight: 10 }}
                    />
                    Delete{' '}
                    {configurationUtils.getPageTitle(
                      true,
                      'LOADPLAN',
                    )}
                  </Button>
                  <Button
                    variant='outlined'
                    className={classes.containedButton}
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                      activateLoadPlan();
                    }}
                    disabled={loadplanData?.active}
                    data-testid={'activate-load-plan'}
                  >
                    <CheckCircleOutlineIcon
                      style={{ marginRight: 10 }}
                    />
                    Activate{' '}
                    {configurationUtils.getPageTitle(
                      true,
                      'LOADPLAN',
                    )}
                  </Button>
                </>
              )}
          </Grid>
        </Grid>
        {alertMessage && (
          <Paper className={classes.alertBox}>
            <ErrorOutlineIcon />
            <p>{alertMessage}</p>
          </Paper>
        )}
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid=''
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(true, 'LOADPLAN') +
                  ' ID'
                }
                value={loadplanData?.id || common.emptyValue}
              />{' '}
              <SingleDetail
                inline={true}
                valueStyle={{
                  color: colors.darkGold,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                label='Filename'
                value={loadplanData?.file_name || common.emptyValue}
              />{' '}
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  'Total ' +
                  configurationUtils.getPageTitle(false, 'STOP')
                }
                value={loadplanData?.total_stops || common.emptyValue}
              />
              <br />
              <SingleDetail
                inline={true}
                label='Imported by'
                value={
                  loadplanData?.created_by_name || common.emptyValue
                }
              />{' '}
              <SingleDetail
                inline={true}
                label='Imported on'
                value={
                  loadplanData?.created_on
                    ? new Date(
                        loadplanData.created_on,
                      ).toLocaleString()
                    : common.emptyValue
                }
              />{' '}
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Total ZIP Codes'
                value={
                  loadplanData?.total_zipcodes || common.emptyValue
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.LOADPLANWRITE,
            ) && (
              <>
                <Button
                  data-testid={'add-scheme-set'}
                  variant='outlined'
                  className={classes.outlinedButton}
                  style={{
                    background: colors.darkGold,
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowAddSchemeSetDialog(true);
                  }}
                  disabled={!!error || !loadplanData}
                >
                  <CloudUploadOutlined style={{ marginRight: 10 }} />
                  Add Scheme Set
                </Button>
                <Button
                  data-testid={'delete-scheme-set-btn'}
                  variant='outlined'
                  className={classes.outlinedButton}
                  style={{
                    background: colors.darkGold,
                    marginBottom: '10px',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    setShowDeleteSchemeSetDialog(true);
                  }}
                  disabled={!loadplanData}
                >
                  <IndeterminateCheckBoxOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Delete Scheme Set
                </Button>
                <Button
                  variant='outlined'
                  className={classes.outlinedButton}
                  style={{
                    background: colors.darkGold,
                    marginBottom: '10px',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    setShowAddSchemeDialog(true);
                  }}
                  disabled={!loadplanData}
                >
                  <AddToPhotosOutlinedIcon
                    style={{ marginRight: 10 }}
                  />
                  Create New Scheme
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        {fetchedLoadPlanId && (
          <PaginatedTable
            key={lastUpdated}
            title={'Schemes'}
            columns={COLUMNS_STOPS}
            dataTestIdPrefix={'stops'}
            fetch={fetchSchemes}
            rowsLoadDetailPages={false}
            detailsPageBasePath={
              AuthRoutes.LOADPLAN + '/' + match.params.id
            }
            filterIsEnabled={false}
            filterByString={true}
            filterByStringPlaceholder={`Search Schemes`}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || undefined
            }
            sortableBy={['routes_count']}
            actions={[
              {
                tableLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                ) ? (
                  <>
                    <SettingsIcon
                      style={{
                        color: colors.darkGold,
                        marginRight: '5px',
                      }}
                    />
                    Edit
                  </>
                ) : undefined,
                qualifier: 'id',
                callback: onEditScheme,
              },
              {
                tableLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                ) ? (
                  <>
                    <CancelIcon style={{ marginRight: '5px' }} />
                    Delete
                  </>
                ) : undefined,
                qualifier: 'id',
                callback: (id: number) => {
                  const scheme = schemes?.find(
                    (scheme) => scheme.id === id,
                  );
                  setDeleteParams({
                    id,
                    name: scheme?.name || '',
                  });
                  setShowDeleteSchemeConfirmation(true);
                },
              },
            ]}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    alertBox: {
      backgroundColor: '#eee1d3',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      '& p': {
        margin: '5px',
      },
    },
    inputPadding: {
      paddingLeft: 5,
      paddingRigth: 5,
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    dialogContent: { width: '500px', maxWidth: '100%' },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
  })),
)(Loadplans);
