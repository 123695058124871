export const processSearchQuery = (
  searchQuery: string,
  componentText: string[],
): string[] => {
  const keywordsFound: string[] = [];
  const keywordsToSearchFor = getSearchWords(searchQuery);
  for (let keyword of keywordsToSearchFor) {
    const searchResult = componentText.filter((componentText) =>
      componentText.toLowerCase().includes(keyword.toLowerCase()),
    );
    if (searchResult.length > 0) {
      keywordsFound.push(keyword);
    }
  }
  return keywordsFound;
};

const getSearchWords = (searchQuery: string): string[] => {
  return searchQuery
    .split(' ')
    .filter((keyword) => keyword.length > 0);
};
