import MuiTextField from '@material-ui/core/TextField';
import * as React from 'react';

const TextField: React.FC<any> = (props) => {
  return <MuiTextField {...props} />;
};

TextField.defaultProps = {
  fullWidth: true,
  variant: 'outlined',
  margin: 'normal',
};

export default TextField;
