import React, { useEffect, useState } from 'react';
import { Typography } from '../../../primitives';

import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Switch,
  Theme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import selectStyles from '../../../select/select.styles';
import { INTERACTION } from '../../Configuration';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';
import colors from '../../../../utils/colors';
import enumToLabel from '../../../../utils/enumToLabel';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataKey: string;
  interaction?: INTERACTION;
  setPartialData: (data: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  confirmation_scans_count: 0,
};

const requireLabeledMove: { [index: string]: string } = {
  NEVER: 'Never',
  ALWAYS: 'Always',
  STAGING: 'To Staging',
};

const ContainerOps: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const initDialogSelectData = {
    require_labeled_move: data.require_labeled_move,
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showSelectDialog, setShowSelectDialog] =
    useState<boolean>(false);
  const [dialogSelectData, setDialogSelectData] = useState<any>(
    initDialogSelectData,
  );
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Containers Operations';
  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        // Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const confirmationScansResults = processSearchQuery(
          searchQuery,
          [
            SECTION_TITLE,
            'Confirmation Scans',
            data.confirmation_scans_count.toString(),
          ],
        );
        const requireLabeledMoveResults = processSearchQuery(
          searchQuery,
          [
            SECTION_TITLE,
            'Require Labeled Move',
            requireLabeledMove[dialogSelectData.require_labeled_move],
          ],
        );

        const results = [
          ...confirmationScansResults,
          ...requireLabeledMoveResults,
        ];

        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const shouldShowElement = (item: string) => {
    return (
      (item && currentView !== CurrentView.SEARCH) ||
      (currentView === CurrentView.SEARCH &&
        searchResult.some((result) =>
          enumToLabel(item)
            .toLowerCase()
            .includes(result.toLowerCase()),
        )) ||
      (currentView === CurrentView.SEARCH && showWholeSection)
    );
  };

  const onlyNumbers = (value: number) =>
    parseInt(value.toString().replace('/[^]/g', ''));

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='Containers Operations-dialog-confirmation-scans-count'
            autoFocus
            label={'Count'}
            type={'number'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.confirmation_scans_count}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                ...dialogData,
                confirmation_scans_count: onlyNumbers(
                  parseInt(value),
                ),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='Containers Operations-dialog-confirmation-scans-count-save-btn'
            onClick={() => {
              data.confirmation_scans_count =
                dialogData.confirmation_scans_count;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onAfterSelectDialogClose = () => {
    setShowSelectDialog(false);
    setDialogSelectData(initDialogSelectData);
  };

  const renderSelectDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showSelectDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterSelectDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <FormControl
            className={classes.formControl}
            style={{
              width: '100%',
              margin: '10px 0px 10px 0',
              height: 50,
              borderColor: colors.black,
            }}
          >
            <InputLabel className={classes.selectLabel}>
              Labeled Move
            </InputLabel>
            <Select
              data-testid='Containers Operations-dialog-Require Labeled Move-select'
              disableUnderline={true}
              displayEmpty
              classes={{
                selectMenu: classes.selectMenu,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.selectPaper,
                  list: classes.selectList,
                },
              }}
              value={dialogSelectData.require_labeled_move}
              onChange={(e) => {
                const value = e.target.value as string;
                setDialogSelectData({
                  ...dialogSelectData,
                  require_labeled_move: value,
                });
              }}
            >
              <MenuItem value={'Please select...'} disabled>
                Please select...
              </MenuItem>
              {Object.keys(requireLabeledMove).map(
                (propertyKey: string) => (
                  <MenuItem
                    value={propertyKey}
                    key={propertyKey}
                    data-testid={`Containers Operations-dialog-Require Labeled Move-${requireLabeledMove[propertyKey]}`}
                  >
                    {requireLabeledMove[propertyKey]}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterSelectDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='Containers Operations-dialog-Require Labeled Move-save-btn'
            onClick={() => {
              let newData = { ...data };
              newData.require_labeled_move =
                dialogSelectData.require_labeled_move;
              initDialogSelectData.require_labeled_move =
                dialogSelectData.require_labeled_move;
              setPartialData({
                ...newData,
              });
              onAfterSelectDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        {renderSelectDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            {shouldShowElement('confirmation_scans_active') && (
              <Box mt={2}>
                <Box mb={2}>
                  <Grid
                    container
                    item
                    sm={12}
                    className={classes.textHeader}
                  >
                    <Grid
                      item
                      sm={2}
                      xs={4}
                      className={classes.pLeft}
                    >
                      Behaviour
                    </Grid>
                    <Grid item sm={7} xs={3}>
                      Enabled
                    </Grid>
                    <Grid item sm={2} xs={3}>
                      Scan Count
                    </Grid>
                    <Grid item sm={1} xs={2} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Box>
                <Grid container item sm={12} className={classes.row}>
                  <Grid
                    item
                    sm={2}
                    xs={4}
                    className={classNames(
                      classes.text,
                      classes.pLeft,
                    )}
                  >
                    <Highlighter
                      highlightClassName={classes.searchResultsMark}
                      searchWords={searchResult}
                      autoEscape={true}
                      textToHighlight='Confirmation Scans'
                    />
                  </Grid>
                  <Grid
                    item
                    sm={7}
                    xs={3}
                    className={classes.text}
                    style={{ paddingRight: 10 }}
                  >
                    <Switch
                      data-testid='Containers Operations-Confirmation Scans-switch'
                      color='default'
                      size='small'
                      disabled={interaction === INTERACTION.READ}
                      className={
                        data.confirmation_scans_active
                          ? classes.muiSwitch
                          : classes.muiSwitchOff
                      }
                      checked={data.confirmation_scans_active}
                      onChange={() => {
                        data.confirmation_scans_active =
                          !data.confirmation_scans_active;
                        setPartialData({ ...data });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    xs={3}
                    className={classNames(
                      classes.text,
                      classes.pLeft,
                    )}
                  >
                    <Highlighter
                      highlightClassName={classes.searchResultsMark}
                      searchWords={searchResult}
                      autoEscape={true}
                      textToHighlight={data.confirmation_scans_count.toString()}
                    />
                  </Grid>
                  <Grid item sm={1} xs={2}>
                    <IconButton
                      data-testid='Containers Operations-Confirmation Scans-edit-btn'
                      size='small'
                      className={classes.editIcon}
                      disabled={interaction === INTERACTION.READ}
                      onClick={() => {
                        setDialogData({
                          confirmation_scans_count:
                            data.confirmation_scans_count,
                        });
                        setShowDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )}
            {(shouldShowElement('require_labeled_move') ||
              (currentView === CurrentView.SEARCH &&
                searchResult.some(
                  (result) =>
                    Object.entries(requireLabeledMove).filter(
                      (item) =>
                        item
                          .toString()
                          .toLowerCase()
                          .includes(result.toLowerCase()),
                    ).length !== 0,
                ))) && (
              <Box mt={2}>
                <Box mb={2}>
                  <Grid
                    container
                    item
                    sm={12}
                    className={classes.textHeader}
                  >
                    <Grid
                      item
                      sm={2}
                      xs={4}
                      className={classes.pLeft}
                    >
                      Behaviour
                    </Grid>
                    <Grid item sm={2} xs={2} />
                    <Grid item sm={7} xs={4}>
                      Value
                    </Grid>
                    <Grid item sm={3} xs={5} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Box>
                <Grid container item sm={12} className={classes.row}>
                  <Grid
                    item
                    sm={2}
                    xs={4}
                    className={classNames(
                      classes.text,
                      classes.pLeft,
                    )}
                  >
                    <Highlighter
                      highlightClassName={classes.searchResultsMark}
                      searchWords={searchResult}
                      autoEscape={true}
                      textToHighlight='Require Labeled Move'
                    />
                  </Grid>
                  <Grid item sm={2} xs={2} />
                  <Grid
                    item
                    sm={7}
                    xs={4}
                    className={classes.text}
                    style={{ paddingRight: 10 }}
                  >
                    <Highlighter
                      highlightClassName={classes.searchResultsMark}
                      searchWords={searchResult}
                      autoEscape={true}
                      textToHighlight={
                        requireLabeledMove[data.require_labeled_move]
                      }
                    />
                  </Grid>
                  <Grid item sm={1} xs={2}>
                    <IconButton
                      data-testid='Containers Operations-Require Labeled Move-edit-btn'
                      size='small'
                      className={classes.editIcon}
                      disabled={interaction === INTERACTION.READ}
                      onClick={() => {
                        setDialogSelectData({
                          require_labeled_move:
                            data.require_labeled_move,
                        });
                        setShowSelectDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...configPageStyles(theme),
  })),
)(ContainerOps);
