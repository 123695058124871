import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography, AlertBanner } from '../../primitives';
import colors from '../../..//utils/colors';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Paper,
  Grid,
  Box,
  Card,
  CardContent,
  DialogTitle,
  createStyles,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import configurationUtils from '../../../utils/configurationUtils';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';

//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  LoadPlanImportSummary,
} from 'cloudsort-client';

// Icons
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';

// Services
import LoadPlansService from '../../../services/LoadPlans.service';
import { CSVLink } from 'react-csv';

interface Props {
  loadplanId: string;
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
  fileId?: string;
}

const AddSchemeSetDialog: React.FC<Props> = ({
  loadplanId,
  isOpen,
  classes,
  fileId,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{
    message: string;
    data: any;
  }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(true); // TODO: Change to false when we return mode selection
  const [formClass, setFormClass] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);
  const [dryRunResults, setDryRunResults] =
    useState<LoadPlanImportSummary>();
  const [selectedFile, setSelectedFile] = useState<any>();

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const loadPlanLabels = {
    singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
    plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
  };

  const handleClose = () => {
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    //setIsUpload(false); -- TODO: uncomment when we add add from DB mode
    onAfterClose();
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (errorData?.error_code === APIExceptionErrorCodeEnum.ResourceConflict) {
      const conflictingZipcodes = ErrorHandler.getConflictingZipcodes(errorData);
      setError({
        message:
          'The following Zip Codes are duplicated in the Scheme Set: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
        data: null,
      });
    } else
      setError({
        message: await ErrorHandler.getLabel(e),
        data: null,
      });

    if (errorData.error_context?.import_issues) {
      setError({
        message: `Found ${errorData.error_context.import_issues.length} issues.`,
        data: errorData.error_context.import_issues.reduce(
          (acc: any, curr: any) => {
            let newItem = {
              ...curr,
              ...curr.content,
            };
            delete newItem['content'];
            acc.push(newItem);
            return acc;
          },
          new Array<any>(),
        ),
      });
    }
  };

  const uploadCSV = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      if (dryRun) {
        setIsUploading(true);
        const response = await LoadPlansService.importSchemeSet({
          id: Number(loadplanId),
          dryRun,
          fileId,
          file: selectedFile,
          options: {
            onUploadProgress,
          },
        });

        setDryRunResults(response.data);
        setShowProgress(false);
        setIsUploading(false);
        response.data.zipcodes_total && response.data.stops_total
          ? setDryRunComplete(true)
          : setError({
            message: `The file you've uploaded doesn't contain any schemes for the selected carrier.`,
            data: null,
          });
      } else {
        await LoadPlansService.importSchemeSet({
          id: Number(loadplanId),
          dryRun,
          fileId,
          file: selectedFile,
          options: {
            onUploadProgress,
          },
        });
        updateParent(); // notify parent component to re-render table
        handleClose();
      }
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onFileChangeHandler = (event: any) => {
    setSelectedFileLabel(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const onFileDroppedHandler = (event: any) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (!file.type.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
        setFormClass(undefined);
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      });
    }
  };

  //This part is not in use for now
  const renderModeSelection = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                //TODO: Add Logic for selecting previous scheme sets when available
              }}
            >
              <Box className={classes.iconBox}>
                <FlipToBackIcon
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Add set from the DB
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                setIsUpload(true);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  className={classes.img}
                  src={`${process.env.REACT_APP_BASENAME || ''
                    }/icons/csv.svg`}
                  alt={'CSV'}
                  style={{ position: 'absolute', top: 10, right: 10 }}
                />
                <CloudUploadOutlined
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 60,
                    color: colors.darkGold,
                  }}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  variant={'h6'}
                  style={{ fontSize: '14px' }}
                >
                  Import Scheme Set
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (fileId) {
      uploadCSV(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {!isUpload && (
          <>
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 40,
                    color: colors.darkGold,
                  }}
                />

                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 18,
                    marginBottom: 20,
                  }}
                >
                  Add Scheme Set
                </span>
              </div>
            </DialogTitle>
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: '0 40px' }}
            >
              {renderModeSelection()}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                CLOSE
              </Button>
            </DialogActions>
          </>
        )}
        {isUpload && (
          <>
            <DialogContent className={classes.dialogContent}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {error && (
                  <AlertBanner
                    className={classes.banner}
                    severity='error'
                    alertTitle={'Error'}
                    alertMsg={error.message}
                  />
                )}
                {error?.data && (
                  <Typography>
                    <CSVLink
                      className={classes.link}
                      style={{
                        fontSize: '15px',
                        color: colors.darkGold,
                      }}
                      data={error?.data || ''}
                      filename={`${selectedFileLabel}-import-errors-log.csv`}
                    >
                      Download summary
                    </CSVLink>
                  </Typography>
                )}
                <CloudUploadOutlined
                  style={{
                    margin: '10px auto',
                    height: 'auto',
                    width: 40,
                    color: colors.darkGold,
                  }}
                />

                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 18,
                  }}
                >
                  {dryRunComplete
                    ? 'Import Details'
                    : 'Import New Scheme Set'}
                </span>
                {!dryRunComplete && !isUploading && (
                  <>
                    <form
                      className={
                        classes.form +
                        ' ' +
                        (formClass && classes[formClass])
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                        setFormClass('draggingActive');
                      }}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        setFormClass(undefined);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        onFileDroppedHandler(e);
                      }}
                    >
                      <input
                        data-testid={'scheme-upload-file-selector'}
                        accept='.csv'
                        className={classes.input}
                        style={{ display: 'none' }}
                        id='button-file'
                        type='file'
                        onChange={onFileChangeHandler}
                      />
                      <p>{selectedFileLabel}</p>
                      <label htmlFor='button-file'>
                        <Button
                          variant='outlined'
                          component='span'
                          className={classes.goldButton}
                        >
                          Browse File
                        </Button>
                      </label>
                    </form>
                    <Typography className={classes.grayText}>
                      Supported file types: .CSV
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors.dark,
                            marginTop: 20,
                          }}
                        >
                          Columns
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          stop_name
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the name of the {stopsLabels.singular} this
                          scheme is associated with; if empty will
                          default to the same value as the scheme_name
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          scheme_name
                          <span className={classes.goldText}> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the name of the this scheme entry
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          carrier
                          <span className={classes.goldText}> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the carrier identifier of this scheme (e.g.
                          USPS)
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          zipcode
                          <span className={classes.goldText}> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the zipcode to assign to the scheme
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.grayText}>
                          sort_param
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={classes.grayText}>
                          the sort parameter to use on this zipcode
                          entry
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.goldText}>
                          *Required Fields
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      style={{
                        fontSize: 12,
                        marginTop: 28,
                        lineHeight: '14px',
                      }}
                    >
                      The selected file will be imported into the Load
                      Plan, replacing all existing schemes for the
                      used carriers. Before the Scheme Set is
                      imported, a short summary will be displayed
                      allowing a review of the data before finalizing
                      the import. Example: uploading a Scheme Set file
                      for the carrier USPS, will delete all existing
                      USPS schemes from the {loadPlanLabels.singular},
                      before importing the new schemes from the
                      selected file.
                    </Typography>
                  </>
                )}
                {isUploading && !fileId && (
                  <p className={classes.grayTitle}>Uploading file</p>
                )}
                {isUploading && fileId && (
                  <Typography
                    className={classes.grayTitle}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: 18,
                    }}
                  >
                    Calculating summary...
                  </Typography>
                )}
                {(isUploading || dryRunComplete) && !fileId && (
                  <Paper className={classes.fileContainer}>
                    <Grid container>
                      <Grid item xs={2} style={{ padding: '10px' }}>
                        <img
                          className={classes.img}
                          src={`${process.env.REACT_APP_BASENAME || ''
                            }/icons/csv-file.png`}
                          alt={'CSV'}
                        />
                      </Grid>
                      <Grid item xs={10} style={{ padding: '10px' }}>
                        <p className={classes.uploadFileInfo}>
                          <span className={classes.uploadFilename}>
                            {selectedFileLabel}
                          </span>

                          <span className={classes.uploadPercentage}>
                            {uploadProgress}%
                          </span>
                        </p>
                        <div className={classes.uploadProgressBar}>
                          <div
                            className={classes.uploadProgressBarInner}
                            style={{ width: `${uploadProgress}%` }}
                          ></div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {dryRunComplete && (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: '10px' }}
                    >
                      <Box className={classes.grayTitle}>
                        New Scheme Set
                      </Box>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs={8} direction={'column'}>
                        <Typography className={classes.boldNameParam}>
                          Added {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                        >
                          {dryRunResults?.stops_added.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    ></Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs={8} direction={'column'}>
                        <Typography className={classes.boldNameParam}>
                          Removed {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                        >
                          {dryRunResults?.stops_removed.length}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs={8} direction={'column'}>
                        <Typography className={classes.boldNameParam}>
                          Moved ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueLight}
                        >
                          {dryRunResults?.zipcodes_moved}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs={8} direction={'column'}>
                        <Typography className={classes.boldNameParam}>
                          Total {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueGold}
                        >
                          {dryRunResults?.stops_total}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <Grid item xs={8} direction={'column'}>
                        <Typography className={classes.boldNameParam}>
                          Total ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.paramValueGold}
                        >
                          {dryRunResults?.zipcodes_total}
                        </Typography>
                      </Grid>
                    </Grid>
                    {dryRunResults?.issues &&
                      dryRunResults?.issues.length > 0 && (
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ marginBottom: '10px' }}
                        >
                          <Grid
                            item
                            container
                            xs={4}
                            direction={'column'}
                          >
                            <Typography
                              className={classes.boldNameParam}
                            >
                              Issues
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              className={classes.paramValueLight}
                              data-testid={'ignored-fields-stops'}
                            >
                              Found {dryRunResults?.issues.length}{' '}
                              {dryRunResults?.issues.length > 1
                                ? 'issues'
                                : 'issue'}
                              <br />
                              <CSVLink
                                className={classes.link}
                                style={{
                                  fontSize: '15px',
                                  color: colors.darkGold,
                                }}
                                data={dryRunResults?.issues}
                                filename={`${selectedFileLabel}-import-issues-log.csv`}
                              >
                                Download summary
                              </CSVLink>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    <Box m={0.8} />
                  </Grid>
                )}
              </div>
            </DialogContent>
            {!dryRunComplete && (
              <DialogActions>
                <Button
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </Button>
                <Button
                  data-testid={'start-scheme-upload'}
                  disabled={!selectedFile}
                  onClick={(e) => {
                    uploadCSV();
                  }}
                  className={classes.button}
                >
                  UPLOAD
                </Button>
              </DialogActions>
            )}
            {dryRunComplete && (
              <DialogActions>
                <Button
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  CANCEL
                </Button>
                <Button
                  data-testid={'start-scheme-import'}
                  onClick={(e) => {
                    uploadCSV(false);
                  }}
                  className={classes.button}
                >
                  {fileId ? 'APPLY CHANGES' : 'IMPORT'}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 80px',
    },
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.gold,
      },
    },
    datePicker: {
      margin: '10px',
    },
    form: {
      margin: '20px 0px 8px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
        fontSize: 16,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    draggingActive: {
      borderColor: colors.lightGray,
    },
    dropRejected: {
      borderColor: 'red',
    },
    grayTitle: {
      color: colors.gray,
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: colors.gray,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: colors.lightGold,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: colors.darkGold,
      height: '5px',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
      backgroundColor: colors.secondaryWhite,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    grayText: {
      fontSize: 12,
      color: colors.gray,
      lineHeight: '14px',
    },
    goldText: {
      fontSize: 12,
      color: colors.darkGold,
      lineHeight: '14px',
    },
  })),
)(AddSchemeSetDialog);
