import { createStyles, Theme } from '@material-ui/core/styles';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import colors from '../../../utils/colors';

export default createStyles((theme: Theme) => ({
  ...detailsPageStyles,
  eventsSnapshotOuterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  eventSnapshotContainer: {
    textAlign: 'center',
    padding: 10,
    flexGrow: 1,
    minWidth: 150,
  },
  activeEventTypeMarker: {
    fontSize: '3em',
    position: 'absolute',
    margin: '-28px 0 0 -0.5em',
    color: colors.darkGold,
  },
  paperHolder: {
    display: 'flex',
    height: 88,
    marginBottom: 10,
  },
}));
