import React, { useEffect, useState } from 'react';

import { AuthRoutes } from '../../../interfaces/routes';
import {
  DatasetRow,
  ReportDatasetPerformanceMetricEnum,
} from 'cloudsort-client';
import { withStyles } from '@material-ui/core/styles';

import {
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import StackedStats, {
  CardContainerVariants,
  CardItemVariants,
  CardLayoutVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import { BarChart, LoaderRing } from '../../primitives';

import {
  Box,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import {
  CARD_LOAD_DELAY,
  FETCH_INTERVAL_ACTIVE,
  FETCH_INTERVAL_TIME,
} from '../Dashboard';
import dateUtils from '../../../services/utils/date';
import colors from '../../../utils/colors';
import { common } from '../../../utils/strings';
import useMountedStatus from '../../../hooks/useMountedStatus';
import useNetworkStatsErrorRegistry from '../../../hooks/useNetworkStatsErrorRegistry';
import { setErrorsOnData } from './utils/utils';

//Services
import ReportsService, {
  ReportFilters,
} from '../../../services/Reports.service';

interface Props {
  title: string;
  index: number;
  filters?: SelectedFilters;
  classes: { [key: string]: string };
}

interface ChartData {
  arg: string;
  value: number;
}

const PerformanceStats: React.FC<Props> = ({
  title,
  index,
  filters,
  classes,
}) => {
  const [data, setData] = useState<StatData>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<
    ChartData[] | undefined
  >();
  const [performanceMetric, setPerformanceMetric] =
    useState<ReportDatasetPerformanceMetricEnum>(
      ReportDatasetPerformanceMetricEnum.PACKAGES,
    );

  const parsedFilters: ReportFilters = {
    owner:
      filters && filters.owner && filters.owner.values.length
        ? filters.owner.values.map((item: any) => item.id)
        : undefined,
    carrier:
      filters && filters.carrier && filters.carrier.values.length
        ? filters.carrier.values.map((item: any) => item.id)
        : undefined,
  };

  const componentMounted = useMountedStatus();
  const { networkStatsErrors, registerError } =
    useNetworkStatsErrorRegistry([]);

  const fetchChartData = async (
    metric?: ReportDatasetPerformanceMetricEnum,
  ) => {
    if (componentMounted) {
      setIsLoading(true);
    }
    const res = await ReportsService.getPerformanceStats(
      '1h',
      metric || performanceMetric,
      undefined,
      undefined,
      parsedFilters,
    );

    const chartData: ChartData[] = [];

    res.data.data?.map((item: DatasetRow) => {
      const label = new Date(item.timestamp ?? '').toLocaleTimeString(
        navigator.language,
        {
          hour: 'numeric',
        },
      );

      chartData.push({
        arg: label,
        value: item.value!,
      });
    });
    if (componentMounted) {
      setChartData(chartData);
      setIsLoading(false);
    }
  };

  const fetchStats = async () => {
    const packageLabels: {
      key: any;
      label: any;
    }[] = [
      {
        key: 'MPP',
        label: 'Minutes per package',
      },
      {
        key: 'MPFT3',
        label: 'Minutes per ft3',
      },
    ];

    let results: StatData = packageLabels.reduce((acc, step) => {
      return {
        ...acc,
        [step.key]: {
          label: step.label,
          value: 'pending',
        },
      };
    }, {});

    setData(results);

    setTimeout(() => {
      // Fetch data

      //Counts
      ReportsService.getPerformanceStats(
        '6m',
        ReportDatasetPerformanceMetricEnum.PACKAGES,
        true,
        undefined,
        parsedFilters,
      )
        .then((res) => {
          results = {
            ...results,
            MPP: {
              ...results['MPP'],
              value:
                res.data.data &&
                typeof res.data.data[0].value === 'number'
                  ? res.data.data[0].value.toFixed(2)
                  : common.emptyValue,
            },
          };
          if (componentMounted) {
            setData(results);
          }
        })
        .catch((e) => {
          registerError('MPP');
        });

      ReportsService.getPerformanceStats(
        '6m',
        ReportDatasetPerformanceMetricEnum.VOLUME,
        true,
        undefined,
        parsedFilters,
      )
        .then((res) => {
          results = {
            ...results,
            MPFT3: {
              ...results['MPFT3'],
              value:
                res.data.data &&
                typeof res.data.data[0].value === 'number'
                  ? res.data.data[0].value.toFixed(2)
                  : common.emptyValue,
            },
          };
          if (componentMounted) {
            setData(results);
          }
        })
        .catch((e) => {
          registerError('MPFT3');
        });

      //Chart Data
      fetchChartData();
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      dateUtils.isSystemDateToday() &&
      FETCH_INTERVAL_ACTIVE &&
      componentMounted
    ) {
      const interval = setInterval(() => {
        fetchStats();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <StackedStats
          title={title}
          containerStyleVariant={CardContainerVariants.GOLD}
          itemsStyleVariant={CardItemVariants.NO_BG_BLACK}
          itemsLayoutVariant={CardLayoutVariants.TITLE_AND_COUNT}
          dataTestId='packages-stats'
          stats={setErrorsOnData(data, networkStatsErrors)}
          linkBase={AuthRoutes.PACKAGE}
          style={{ height: '100%' }}
          filters={getAppliedFilters(filters, [
            'route',
            'stop',
            'hidePending',
          ])}
        />
      </Grid>
      <Grid item xs={9}>
        <Paper className={classes.paper}>
          {isLoading && (
            <Box style={{ position: 'relative' }}>
              <CircularProgress
                variant='indeterminate'
                style={{
                  position: 'absolute',
                  top: 100,
                  left: '50%',
                }}
              />
            </Box>
          )}
          <FormControl>
            <Select
              className={classes.select}
              disableUnderline={true}
              value={performanceMetric}
              onChange={(e) => {
                const val = e.target
                  .value as ReportDatasetPerformanceMetricEnum;

                setPerformanceMetric(val);
                fetchChartData(val);
              }}
            >
              <MenuItem
                value={ReportDatasetPerformanceMetricEnum.PACKAGES}
              >
                Minutes per Package
              </MenuItem>
              <MenuItem
                value={ReportDatasetPerformanceMetricEnum.VOLUME}
              >
                Minutes per Ft3
              </MenuItem>
            </Select>
          </FormControl>

          <BarChart
            height={250}
            chartData={chartData}
            fill={colors.gold}
            dataType='mpp'
            dataTestId='chart-dashboard-performance'
          />
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <LoaderRing />
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    paper: {
      padding: 16,
    },
    select: {
      fontSize: '18px',
    },
    //Filter tooltip
    filterTooltip: {
      '& p': {
        fontSize: '12px',
      },
      '& ul': {
        fontSize: '12px',
        padding: '0 0 0 20px',
      },
    },
    filterIcon: {
      fontSize: '18px',
      background: colors.gold,
      color: colors.white,
      padding: '2px',
      borderRadius: '50%',
      margin: '0 5px -2px 0',
    },
  })),
)(PerformanceStats);
