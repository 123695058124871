import React, { useState, useCallback, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '../../primitives';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import { createStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { AlertBanner } from '../../primitives';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from './asyncSelect.styles';
import TextField from '@material-ui/core/TextField';
import colors from '../../../utils/colors';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import validateEmail from '../../../utils/validateEmail';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { TypeAheadItem } from '../../../interfaces/components';
import { components, ValueType } from 'react-select';
import selectStyles from '../../select/select.outlined.styles';
import { debounce } from 'lodash';

// Types
import { PermissionsRoleTypeEnum, Staff } from 'cloudsort-client';
import { ROLE_LABELS } from '../../stationStaff/StationStaff';

//Services
import StationsService from '../../../services/Stations.service';
import OrganizationsService from '../../../services/Organizations.service';
import StaffService from '../../../services/Staff.service';
import { noOptionsMessage } from '../../asyncSelect/utils';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterDialogClose: () => void;
  onAddStaff: (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => void;
  error?: string;
  initialStaff?: Staff;
  preselectedEntity?: TypeAheadItem;
}

enum RoleType {
  STATION,
  ORGANIZATION,
}

const initAddParams: Staff = {
  first_name: '',
  last_name: '',
  email: '',
  permission_data: {},
};

const AddUserDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterDialogClose,
  onAddStaff,
  error,
  initialStaff,
  preselectedEntity,
}) => {
  const [staffParams, setStaffParams] =
    useState<Staff>(initAddParams);
  const [firstStepCompleted, setFirstStepCompleted] =
    useState<boolean>(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    useState<boolean>(true);
  const [roleType, setRoleType] = useState<RoleType>();
  const [selectedEntity, setSelectedEntity] = useState<
    TypeAheadItem | undefined
  >();

  const [isEmailConfirmationMsg, setIsEmailConfirmationMsg] =
    useState<boolean>(false);

  const isValidEmail = validateEmail(staffParams.email || '');
  const hasNoName =
    !staffParams?.first_name?.trim() ||
    !staffParams?.last_name?.trim();

  const hasNoEmail = !staffParams.email?.trim();

  const onClose = () => {
    onAfterDialogClose();
    setTimeout(() => {
      setStaffParams(initAddParams);
      setRoleType(undefined);
      setFirstStepCompleted(false);
      setIsEmailConfirmationMsg(false);
      setSelectedEntity(undefined);
    }, 300);
  };

  const selectRole = (role: PermissionsRoleTypeEnum) => {
    if (initialStaff) {
      setStaffParams({
        ...initialStaff,
        permission_data: {
          role_type: role,
        },
      });
    } else {
      if (preselectedEntity && !role.includes('ORG')) {
        setStaffParams({
          ...staffParams,
          permission_data: {
            role_type: role,
            station: Number(preselectedEntity.value),
          },
        });
        setSelectedEntity(preselectedEntity);
      } else
        setStaffParams({
          ...staffParams,
          permission_data: {
            role_type: role,
          },
        });
    }
    if (role === PermissionsRoleTypeEnum.OPERATORI) {
      setFirstStepCompleted(true);
      setEmailAlreadyRegistered(false);
    }
  };

  const checkIfEmailIsRegistered = debounce(async (email: string) => {
    try {
      const { data } = await StaffService.checkEmail(email);
      setStaffParams({
        ...staffParams,
        id: data.id,
        email: data.email,
      });
      setEmailAlreadyRegistered(true);
    } catch (e) {
      setStaffParams({
        ...staffParams,
        id: undefined,
        email,
      });
      setEmailAlreadyRegistered(false);
    }
  }, 300);

  const renderFirstStep = () => {
    return (
      <Grid
        container
        item
        direction={'row'}
        xs={12}
        spacing={2}
        style={{ padding: '0 50px' }}
      >
        <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
          <Typography>Select Role</Typography>
        </Box>
        <FormControl
          className={classes.formControl}
          style={{ width: '100%', margin: 0, height: 50 }}
        >
          <Select
            disableUnderline={true}
            onChange={(e: React.BaseSyntheticEvent) => {
              if (e.target.value !== '') {
                selectRole(e.target.value);
              }
              if (e.target.value.includes('ORG'))
                setRoleType(RoleType.ORGANIZATION);
              else setRoleType(RoleType.STATION);
            }}
            classes={{
              selectMenu: classes.selectMenu,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: {
                paper: classes.selectPaper,
                list: classes.selectList,
              },
              style: { width: '100%' },
            }}
            value={staffParams?.permission_data!.role_type ?? 'none'}
          >
            <MenuItem key={`fragment-none`} value={'none'} disabled>
              Please select...
            </MenuItem>
            {Object.keys(PermissionsRoleTypeEnum)
              .filter((key) => key !== 'SUPERUSER' && key !== 'BLANK')
              .map((key) => {
                return {
                  label:
                    ROLE_LABELS[
                      key as keyof typeof PermissionsRoleTypeEnum
                    ],
                  value:
                    PermissionsRoleTypeEnum[
                      key as keyof typeof PermissionsRoleTypeEnum
                    ],
                };
              })
              .map((permission) => {
                return (
                  <MenuItem
                    key={`fragment-${permission.label}`}
                    value={permission.value}
                  >
                    {permission.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {staffParams.permission_data?.role_type &&
          staffParams.permission_data?.role_type !==
            PermissionsRoleTypeEnum.OPERATORI &&
          !initialStaff && (
            <Fragment>
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Email Address </Typography>
              </Box>
              <TextField
                placeholder={'Email Address'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={(e) => {
                  if (validateEmail(e.target.value)) {
                    checkIfEmailIsRegistered(e.target.value);
                  } else
                    setStaffParams({
                      ...staffParams,
                      id: undefined,
                      email: undefined,
                    });
                }}
                helperText={
                  !!staffParams.email && !isValidEmail
                    ? 'Please enter a valid email.'
                    : null
                }
                error={!!staffParams.email && !isValidEmail}
              />
            </Fragment>
          )}
      </Grid>
    );
  };

  const renderStationSelection = () => {
    return (
      <>
        <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
          <Typography>
            Assigned{' '}
            {roleType === RoleType.ORGANIZATION
              ? 'Organization'
              : 'Station'}
          </Typography>
        </Box>
        <AsyncSelect<TypeAheadItem>
          data-testid={'add-user-dialog-async-select'}
          isClearable
          cacheOptions
          styles={asyncSelectStyles}
          loadOptions={loadOptions}
          onChange={handleOnEntitySelect}
          noOptionsMessage={noOptionsMessage}
          placeholder={
            roleType === RoleType.ORGANIZATION
              ? 'Find organizations by name'
              : 'Find stations by name'
          }
          value={selectedEntity ? selectedEntity : null}
          components={{
            MenuList: (menuListprops: any) => {
              return (
                <Fragment>
                  <div className={classes.arrowUp} />
                  <components.MenuList
                    {...menuListprops}
                    className={classes.menuList}
                  >
                    <div>{menuListprops.children}</div>
                  </components.MenuList>
                </Fragment>
              );
            },
          }}
        />
      </>
    );
  };

  const fetchStations = async (search: string) => {
    const { data } = await StationsService.getAllStationsSearch(
      search,
    );
    return data.results;
  };
  const fetchOrgs = async (search: string) => {
    const { data } = await OrganizationsService.getAllSearch(search);
    return data.results;
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      if (roleType === RoleType.ORGANIZATION) {
        fetchOrgs(inputValue).then((data) => {
          callback(
            data.map((entity) => {
              return {
                value: entity.id?.toString() || '',
                label: entity.name,
              };
            }),
          );
        });
      } else {
        fetchStations(inputValue).then((data) => {
          callback(
            data.map((entity) => {
              return {
                value: entity.id?.toString() || '',
                label: entity.name,
              };
            }),
          );
        });
      }
    }, 500),
    [roleType],
  );

  const handleOnEntitySelect = (option: ValueType<TypeAheadItem>) => {
    if (option) {
      if (roleType === RoleType.ORGANIZATION) {
        setStaffParams({
          ...staffParams,
          permission_data: {
            ...staffParams.permission_data,
            organization: Number((option as TypeAheadItem).value),
          },
        });
      } else {
        setStaffParams({
          ...staffParams,
          permission_data: {
            ...staffParams.permission_data,
            station: Number((option as TypeAheadItem).value),
          },
        });
      }

      setSelectedEntity(option as TypeAheadItem);
    } else {
      setSelectedEntity(undefined);
    }
  };

  const renderForm = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          {(staffParams.permission_data?.role_type! ===
            PermissionsRoleTypeEnum.OPERATORI ||
            !emailAlreadyRegistered) && (
            <>
              <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
                <Typography>First Name</Typography>
              </Box>
              <TextField
                autoFocus
                placeholder={'First Name'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={(e) => {
                  setStaffParams({
                    ...staffParams,
                    first_name: e.target.value,
                  });
                }}
              />
              <Box fontWeight='fontWeightBold' pt={2} p={0.5} pl={0}>
                <Typography>Last Name</Typography>
              </Box>
              <TextField
                placeholder={'Last Name'}
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                classes={{
                  root: classes.inputHolder,
                }}
                onChange={(e) => {
                  setStaffParams({
                    ...staffParams,
                    last_name: e.target.value,
                  });
                }}
              />
            </>
          )}
          {renderStationSelection()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      data-testid={'god-view-users-add-dialog'}
      open={isOpen}
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      onClose={() => {
        onClose();
      }}
    >
      {error && (
        <Box mt={2} ml={2} mr={2}>
          <AlertBanner
            data-testid={'station-staff-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        </Box>
      )}
      <DialogTitle>
        {!isEmailConfirmationMsg && !initialStaff && (
          <Fragment>
            <Box p={4} pt={2} pb={0.5}>
              <Typography align={'center'} variant={'h6'}>
                Please add the new user's details.
              </Typography>
              <Box p={2} pt={0.5} pb={0}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  style={{ opacity: 0.6 }}
                >
                  The user will receive a link in the email to
                  complete the registration.
                </Typography>
              </Box>
            </Box>
          </Fragment>
        )}
        {!isEmailConfirmationMsg && initialStaff && (
          <Fragment>
            <Box p={4} pt={2} pb={0.5}>
              <Typography align={'center'} variant={'h6'}>
                Assign a station
              </Typography>
              <Box p={2} pt={0.5} pb={0}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  style={{ opacity: 0.6 }}
                >
                  Please select a station and the role for that
                  station.
                </Typography>
              </Box>
            </Box>
          </Fragment>
        )}
      </DialogTitle>
      <DialogContent>
        {isEmailConfirmationMsg ? (
          <Fragment>
            <Box>
              <Box fontWeight='fontWeightBold'>
                <Typography align={'center'} variant={'body1'}>
                  New user added sucessfully
                </Typography>
              </Box>
              <Box p={18} pt={2} pb={0.5}>
                <Typography
                  align={'center'}
                  variant={'body1'}
                  style={{ opacity: 0.6 }}
                >
                  We have sent an email to{' '}
                  <span style={{ color: colors.darkGold }}>
                    {staffParams.email}
                  </span>{' '}
                  to complete the registration process.
                </Typography>
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            {!firstStepCompleted &&
              !initialStaff &&
              renderFirstStep()}
            {firstStepCompleted && !initialStaff && renderForm()}
            {initialStaff && renderFirstStep()}
            {initialStaff && (
              <div style={{ padding: '0 55px 0 45px' }}>
                {renderStationSelection()}
              </div>
            )}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Box pl={1} pr={1}>
          <Button
            data-testid={'station-staff-dialog-close'}
            className={classes.button}
            onClick={() => {
              onClose();
            }}
          >
            {isEmailConfirmationMsg ? 'OK' : 'Cancel'}
          </Button>
          {!isEmailConfirmationMsg &&
            !initialStaff &&
            !firstStepCompleted && (
              <Button
                data-testid={'station-staff-dialog-add'}
                className={classes.button}
                disabled={hasNoEmail || !isValidEmail}
                onClick={() => {
                  setFirstStepCompleted(true);
                }}
              >
                Next
              </Button>
            )}
          {!isEmailConfirmationMsg &&
            !initialStaff &&
            firstStepCompleted && (
              <Button
                data-testid={'station-staff-dialog-add'}
                className={classes.button}
                disabled={
                  !emailAlreadyRegistered &&
                  (hasNoName ||
                    (hasNoEmail &&
                      staffParams.permission_data!.role_type! !==
                        PermissionsRoleTypeEnum.OPERATORI) ||
                    (!hasNoEmail && !isValidEmail) ||
                    !selectedEntity)
                }
                onClick={() => {
                  onAddStaff(
                    staffParams!,
                    () => {
                      if (!hasNoEmail && !emailAlreadyRegistered) {
                        setIsEmailConfirmationMsg(true);
                      }
                    },
                    emailAlreadyRegistered,
                  );
                  if (hasNoEmail || emailAlreadyRegistered) onClose();
                }}
              >
                Add
              </Button>
            )}
          {initialStaff && (
            <Button
              data-testid={'station-staff-dialog-assign'}
              className={classes.button}
              disabled={
                !selectedEntity ||
                !staffParams.permission_data?.role_type
              }
              onClick={() => {
                onAddStaff(staffParams!, () => {}, true);
                onClose();
              }}
            >
              Assign
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    cardContent: {
      padding: 0,
      cursor: 'pointer',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    roleDescription: {
      minHeight: '55px',
      fontSize: '0.8rem',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    button: {
      color: colors.darkGold,
    },
    paper: {
      padding: 0,
      margin: 0,
    },
    paperScrollPaper: {
      maxHeight: '100%',
    },
  })),
)(AddUserDialog);
