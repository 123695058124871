import React, { useEffect, useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { inRange, isInteger } from 'lodash';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AlertBanner, Typography } from '../primitives/index';
import { common } from '../../utils/strings';
import {
  Theme,
  Grid,
  Paper,
  Button,
  Box,
  Menu,
  Fade,
  MenuItem,
} from '@material-ui/core';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import PermissionsService from '../../services/Permissions.service';
import ErrorHandler from '../../utils/ErrorHandler';
import {
  LoadPointDetails,
  PermissionsPermissionsEnum,
  SchemeDetails,
} from 'cloudsort-client';
import clx from 'classnames';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import colors from '../../utils/colors';
import sanitizeHtml from 'sanitize-html';
import SchemesService from '../../services/Schemes.service';
import RoutesService from '../../services/Routes.service';
import LoadPointsService from '../../services/LoadPoints.service';
import { AuthRoutes } from '../../interfaces/routes';
import { humanReadableNull } from '../DetailsPagesFunctions';
import PaginatedList from '../paginatedList/PaginatedList';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import DeleteIcon from '../../utils/svgs/DeleteIcon';
import DeleteSchemeDialog from './deleteSchemeDialog/DeleteSchemeDialog';
import EditSchemeDialog from './editSchemeDialog/EditSchemeDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import EditLoadBalancingDialog, {
  LoadPointDetailsWithDisplayName,
  normalizeLBValues,
} from '../areas/settings/EditLoadBalancingDialog';
import ProgressBarItem from '../primitives/progressBarItem/ProgressBarItem';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';

//Icons
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface Props extends RouteComponentProps {
  match: any;
  classes: { [key: string]: string };
}

const SchemeDetailsPage: React.FC<Props> = ({ match, classes }) => {
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [schemeDetails, setSchemeDetails] = useState<SchemeDetails>();
  const [schemeRoutes, setSchemeRoutes] = useState<any>();
  const [schemeLoadPoints, setSchemeLoadPoints] =
    useState<LoadPointDetailsWithDisplayName[]>();
  const [loadPointsForBalanceDialog, setLoadPointsForBalanceDialog] =
    useState<LoadPointDetailsWithDisplayName[]>();
  const [isViewReady, setIsViewReady] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showManageZipCodes, setShowManageZipCodes] = useState(false);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(
    Date.now(),
  );
  const schemeLabels = {
    singular: 'Scheme',
    plural: 'Schemes',
  };
  const schemeId = match.params.id;
  const history = useHistory();

  const getAndSetSchemeDetailsData = async () => {
    try {
      const scheme = await SchemesService.getById(schemeId);
      setSchemeDetails(scheme.data);
      const routes = await RoutesService.getAll({
        scheme: schemeId,
      });
      setSchemeRoutes(routes.data);

      setLastUpdateTimestamp(Date.now());
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const getAndSetSchemeLoadPointsData = async () => {
    const loadPoints = await LoadPointsService.getAll({
      scheme: schemeId,
      pageSize: MAX_PAGE_SIZE,
    });
    const results = loadPoints?.data?.results
      .map((item: any) => {
        return {
          ...item,
          display_name: `${item.area_name || ''} ${
            item.zone_name || ''
          } ${item.name || ''}`,
        };
      })
      .sort((a, b) => (a.display_name < b.display_name ? -1 : 1));
    setSchemeLoadPoints(
      normalizeLBValues(
        (results as unknown as LoadPointDetailsWithDisplayName[]) ||
          [],
      ) as unknown as LoadPointDetailsWithDisplayName[],
    );
  };

  useEffect(() => {
    getAndSetSchemeDetailsData();
    getAndSetSchemeLoadPointsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemeId]);

  useEffect(() => {
    if (schemeDetails && schemeRoutes && schemeLoadPoints) {
      setIsViewReady(true);
    }
  }, [schemeDetails, schemeRoutes, schemeLoadPoints]);

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.LOADPLANREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSchemeDetailsData = async (newData: SchemeDetails) => {
    setShowProgress(true);
    try {
      const { data } = await SchemesService.update(newData);
      setSchemeDetails(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const fetchZipCodes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => {
    //Return empty array if we don't have the data ready.
    if (!schemeDetails?.zipcodes) {
      return { data: [], count: 0 };
    }
    const fetchedData: JSX.Element[] = [];
    const zipCodesList = [];
    if (filterByString.trim().length > 0) {
      zipCodesList.push(
        ...schemeDetails.zipcodes.filter((zipCode) => {
          if (zipCode.length === 1) {
            //Single zip code
            return zipCode[0].includes(filterByString);
          } else {
            //Zip code range
            const searchNumber = Number(
              filterByString.padEnd(11, '0'),
            );
            const start = Number(zipCode[0].padEnd(11, '0'));
            const end = Number(zipCode[1].padEnd(11, '0'));
            if (!isInteger(searchNumber)) {
              return false;
            }
            return inRange(searchNumber, start, end + 1);
          }
        }),
      );
    } else {
      zipCodesList.push(...schemeDetails.zipcodes);
    }
    //Calculate start & end index. Add the correct amount of zipcodes.
    let currentIndex = pageIndex;
    let finalIndex = pageIndex + rowsPerPage;
    if (finalIndex > zipCodesList.length - 1) {
      finalIndex = zipCodesList.length - 1;
    }
    while (currentIndex <= finalIndex) {
      if (zipCodesList[currentIndex].length === 1) {
        fetchedData.push(
          <Typography style={{ marginBottom: 20, fontSize: 14 }}>
            {zipCodesList[currentIndex]}
          </Typography>,
        );
      } else {
        fetchedData.push(
          <Typography style={{ marginBottom: 20, fontSize: 14 }}>
            {zipCodesList[currentIndex][0]}
            {' - '}
            {zipCodesList[currentIndex][1]}
          </Typography>,
        );
      }
      currentIndex += 1;
    }
    return { data: fetchedData, count: zipCodesList.length };
  };

  const fetchRoutes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => {
    const fetchedData: JSX.Element[] = [];
    let count = 0;
    let error = null;
    try {
      const routes = await RoutesService.getAll({
        scheme: schemeId,
        search: filterByString,
        page: pageIndex + 1,
        pageSize: rowsPerPage,
      });
      count = routes.data.count;
      for (let route of routes.data.results) {
        fetchedData.push(
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 14 }}>
                <Link
                  style={{ color: colors.dark }}
                  to={`${AuthRoutes.ROUTE}/${route.id}`}
                >
                  {route.id}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 14 }}>
                {route.name}
              </Typography>
            </Grid>
          </Grid>,
        );
      }
      if (fetchedData.length > 0) {
        //Add the header
        fetchedData.unshift(
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: colors.gray,
                  fontSize: 14,
                }}
              >
                ID
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: colors.gray,
                  fontSize: 14,
                }}
              >
                Name
              </Typography>
            </Grid>
          </Grid>,
        );
      }
    } catch (e) {
      error = e;
    }
    return { data: fetchedData, count: count, errors: error };
  };

  const onEditLoadPoints = async (loadpoints: LoadPointDetails[]) => {
    setShowProgress(true);

    try {
      const calls = loadpoints?.map((loadpoint) =>
        LoadPointsService.updateById(loadpoint.id!, loadpoint),
      );
      if (calls) {
        await Promise.all(calls);
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${schemeLabels.singular} ${schemeDetails?.name || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='SCHEME'>
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {isViewReady ? (
          <>
            {showProgress && <ProgressIndicator />}
            <DeleteSchemeDialog
              schemeId={schemeId}
              schemeName={schemeDetails?.name || ''}
              isOpen={showDeleteDialog}
              onAfterClose={() => setShowDeleteDialog(false)}
              updateParent={() => {
                history.push(
                  `${AuthRoutes.LOADPLAN}/${schemeDetails?.load_plan}`,
                );
              }}
            />
            <EditSchemeDialog
              editSchemeData={schemeDetails!}
              isOpen={showManageZipCodes}
              onAfterClose={() => {
                setShowManageZipCodes(false);
              }}
              updateParent={() => {
                getAndSetSchemeDetailsData();
              }}
            />
            <Grid container className={classes.header}>
              <Grid item xs={12} sm={6}>
                <Box
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box>
                    <a
                      href='#/'
                      onClick={(event) => {
                        event.preventDefault();
                        history.goBack();
                      }}
                      className={classes.back}
                    >
                      <ArrowLeftIcon />
                      <Typography>Back</Typography>
                    </a>
                  </Box>
                  <Typography
                    className={classes.title}
                    variant={'h3'}
                  >
                    {schemeDetails?.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.nonMobileAlignRight}
                style={{ textAlign: 'right' }}
              >
                {PermissionsService.hasPermission(
                  PermissionsPermissionsEnum.LOADPLANWRITE,
                ) && (
                  <Button
                    fullWidth={false}
                    variant='contained'
                    className={clx(
                      classes.containedButton,
                      classes.containedButtonDark,
                    )}
                    onClick={() => {
                      setShowDeleteDialog(true);
                    }}
                  >
                    <DeleteIcon style={{ marginRight: 10 }} />
                    Delete {schemeLabels.singular}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12}>
                <Paper
                  className={classes.paper}
                  style={{ padding: 16 }}
                  data-testid='scheme-details-detail'
                >
                  <Typography
                    className={classes.boldNameParam}
                    style={{
                      paddingBottom: 10,
                      marginBottom: 16,
                      borderBottom: `1px solid ${colors.ultraLightGray}`,
                      fontSize: 18,
                      lineHeight: '21px',
                    }}
                  >
                    Scheme Details
                  </Typography>
                  <SingleDetail
                    inline={true}
                    valueStyle={{ color: colors.darkGold }}
                    label={`${configurationUtils.getPageTitle(
                      true,
                      'LOADPLAN',
                    )} ID`}
                    value={
                      <Link
                        style={{ color: colors.darkGold }}
                        to={`${AuthRoutes.LOADPLAN}/${schemeDetails?.load_plan}`}
                      >
                        {humanReadableNull(schemeDetails?.load_plan)}
                      </Link>
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'ZIP Codes'}
                    value={
                      schemeDetails?.zipcode_count ||
                      common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'Carrier'}
                    value={
                      schemeDetails?.fmc_full_name ||
                      common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'Owner'}
                    value={
                      schemeDetails?.owner_identifier ||
                      common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={'Sortation Parameter'}
                    value={sanitizeHtml(
                      schemeDetails?.sort_param || common.emptyValue,
                      {
                        allowedTags: [],
                      },
                    )}
                    onEdit={(value) => {
                      if (schemeDetails !== undefined) {
                        updateSchemeDetailsData({
                          ...schemeDetails,
                          sort_param: value,
                        });
                      }
                    }}
                  />
                  <br />
                  <SingleDetail
                    inline={true}
                    label={configurationUtils.getPageTitle(
                      false,
                      'LOADPOINT',
                    )}
                    value={
                      schemeLoadPoints?.length || common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    label={configurationUtils.getPageTitle(
                      false,
                      'ROUTE',
                    )}
                    value={schemeRoutes?.count || common.emptyValue}
                  />
                  <SingleDetail
                    inline={true}
                    label={configurationUtils.getPageTitle(
                      false,
                      'STOP',
                    )}
                    value={
                      schemeDetails?.used_on?.length ||
                      common.emptyValue
                    }
                  />
                  <SingleDetail
                    inline={true}
                    inlineWidth='auto'
                    label={'Date Modified'}
                    value={
                      schemeDetails?.modified_on
                        ? moment(schemeDetails.modified_on).format(
                            'hh:mma on MM/DD/YYYY',
                          )
                        : common.emptyValue
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12} sm={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px 16px',
                    paddingBottom: 0,
                    minHeight: 449,
                  }}
                  data-testid={'scheme-details-loadpoints-card'}
                >
                  <Grid
                    container
                    spacing={0}
                    style={{
                      borderBottom: `1px solid ${colors.ultraLightGray}`,
                      marginBottom: 16,
                    }}
                  >
                    <Grid item xs={9}>
                      <Typography
                        data-testid={
                          'scheme-details-loadpoints-card-title'
                        }
                        className={classes.boldNameParam}
                        style={{
                          paddingBottom: 8,
                          marginTop: 4,
                          fontSize: 18,
                          lineHeight: '21px',
                        }}
                      >
                        {configurationUtils.getPageTitle(
                          false,
                          'LOADPOINT',
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <Button
                        data-testid={
                          'scheme-details-loadpoints-card-open-options-menu-btn'
                        }
                        onClick={(event) => {
                          setOptionsMenuAnchorEl(event.currentTarget);
                        }}
                        style={{ margin: '-2px 0 2px 0' }}
                      >
                        <MoreVertIcon />
                      </Button>
                      <Menu
                        anchorEl={optionsMenuAnchorEl}
                        keepMounted
                        open={Boolean(optionsMenuAnchorEl)}
                        onClose={() => {
                          setOptionsMenuAnchorEl(null);
                        }}
                        TransitionComponent={Fade}
                      >
                        <MenuItem
                          onClick={() => {
                            setOptionsMenuAnchorEl(null);
                            setLoadPointsForBalanceDialog(
                              schemeLoadPoints,
                            );
                          }}
                          data-testid={
                            'scheme-details-loadpoints-card-options-menu-item'
                          }
                        >
                          Change Load Balancing
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  {schemeLoadPoints?.map((item, index) => (
                    <ProgressBarItem
                      key={`load-balancing-${index}`}
                      labelElement={
                        <Link
                          style={{ color: colors.dark }}
                          to={`${AuthRoutes.AREA}/${item.area}`}
                        >
                          {item.display_name}
                        </Link>
                      }
                      value={Math.round(
                        (item.load_balancing || 0) * 100 || 0,
                      )}
                    />
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <PaginatedList
                  title={configurationUtils.getPageTitle(
                    false,
                    'ROUTE',
                  )}
                  rowsPerPage={10}
                  lastUpdateTimestamp={lastUpdateTimestamp}
                  fetch={fetchRoutes}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PaginatedList
                  title='ZIP Codes'
                  rowsPerPage={10}
                  fetch={fetchZipCodes}
                  lastUpdateTimestamp={lastUpdateTimestamp}
                  optionsMenu={[
                    {
                      label: 'Manage ZIP Codes',
                      callback: () => {
                        setShowManageZipCodes(true);
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <ProgressIndicator />
        )}
        {loadPointsForBalanceDialog && (
          <EditLoadBalancingDialog
            open={!!loadPointsForBalanceDialog}
            loadpoints={loadPointsForBalanceDialog}
            onAfterDialogClose={() => {
              setLoadPointsForBalanceDialog(undefined);
            }}
            onEdit={async (loadpoints) => {
              //update each lp individually
              if (loadpoints) {
                await onEditLoadPoints(loadpoints);
                await getAndSetSchemeLoadPointsData();
                setLoadPointsForBalanceDialog(undefined);
              }
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(SchemeDetailsPage);
