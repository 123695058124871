import React, { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Layout from '../layout/Layout';
import { AuthRoutes } from '../../interfaces/routes';
import colors from '../../utils/colors';
import { useParams } from 'react-router-dom';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives';
import { Column } from '../../interfaces/components';
import AddUserDialog from './addUser/AddUserDialog';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { common } from '../../utils/strings';

// Services
import StaffService from '../../services/Staff.service';
import PermissionsService from '../../services/Permissions.service';

// Types
import { AxiosError } from 'axios';
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
  Staff,
} from 'cloudsort-client';
import { ROLE_LABELS } from '../stationStaff/StationStaff';

// Utils
import PaginatedTable, {
  filterObj,
  getSelectedFilterOption,
} from '../paginatedTable/PaginatedTable';
import configurationUtils from '../../utils/configurationUtils';
import { renderDuration } from '../DetailsPagesFunctions';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

enum Mode {
  CLOUDSORT,
  ORGANIZATION,
  STATION,
}

const COLUMNS: Column[] = [
  {
    id: 'full_name',
    label: 'Name',
    width: 'auto',
    align: 'left',
  },

  {
    id: 'station',
    label: 'Station',
    width: 'auto',
    align: 'left',
  },
  {
    id: 'org',
    label: 'Organization',
    width: 'auto',
    align: 'left',
  },
  {
    id: 'id',
    label: 'ID',
    width: 100,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    width: 'auto',
    align: 'left',
  },
  {
    id: 'role',
    label: 'Role',
    width: 'auto',
    align: 'left',
  },
];

const UsersGodView: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const urlParams: any = useParams();
  let mode: Mode = Mode.CLOUDSORT;
  if (urlParams.stationId && urlParams.orgId) {
    mode = Mode.STATION;
  } else if (urlParams.orgId) {
    mode = Mode.ORGANIZATION;
  }

  const onAddStaff = async (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => {
    setError(undefined);
    setShowProgress(true);

    try {
      if (updateUser) {
        staffParams.first_name = undefined;
        staffParams.last_name = undefined;
        staffParams.email = undefined;
        await StaffService.update(staffParams.id!, staffParams);
      } else {
        if (staffParams.email === '') staffParams.email = undefined;
        await StaffService.create(staffParams);
      }

      setLastUpdated(new Date().toISOString());
      onAfterDialogClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const filters = () => {
    return [
      {
        name: 'Role',
        options: [
          {
            label: 'All',
            value: 'ALL',
            selected: mode !== Mode.ORGANIZATION,
          },
          ...Object.keys(ROLE_LABELS)
            .filter(
              (role) => role !== 'SUPERUSER' && role !== 'BLANK',
            )
            .map((o) => {
              return {
                label: ROLE_LABELS[o as keyof typeof ROLE_LABELS],
                value:
                  PermissionsRoleTypeEnum[
                    o as keyof typeof PermissionsRoleTypeEnum
                  ],
                selected:
                  mode === Mode.ORGANIZATION &&
                  o.includes('ORGADMIN'),
              };
            }),
        ],
        labelWidth: 33,
      },
    ];
  };

  const fetchStaffData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const sortedByValue = sortedBy?.replace('status', 'active');

    let fRole = undefined;

    if (filterOptions)
      fRole = getSelectedFilterOption(filterOptions, 'Role');

    if (fRole === 'ALL') fRole = undefined;

    const res = await StaffService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      sortBy: sortedByValue as
        | 'active'
        | 'seconds_per_package'
        | '-active'
        | '-seconds_per_package'
        | undefined,
      organizationId:
        mode === Mode.ORGANIZATION ? urlParams.orgId : undefined,
      stationId:
        mode === Mode.STATION ? urlParams.stationId : undefined,
      roleType: fRole as
        | undefined
        | 'OPERATOR_I'
        | 'OPERATOR_II'
        | 'OPERATOR_III'
        | 'STATION_ADMIN'
        | 'ORG_ADMIN'
        | 'SUPER_USER',
    });

    res.data.results.forEach((staff: any) => {
      staff.device_id = staff.device_id || common.emptyValue;
      staff.active_since =
        staff?.active_since && staff?.active
          ? renderDuration(
              (new Date().getTime() -
                new Date(staff?.active_since!).getTime()) /
                1000,
            )
          : common.emptyValue;

      const stationArray = staff.assigned_stations.map(
        (station: any) => station.name,
      );
      staff.status = staff.active ? 'Active' : 'Inactive';
      staff.station = stationArray.length
        ? stationArray.join(', ')
        : common.emptyValue;

      const orgArray = staff.assigned_organizations.map(
        (org: any) => org.name,
      );
      staff.org = orgArray.length
        ? orgArray.join(', ')
        : common.emptyValue;
      staff.role = staff.permission_data
        ? ROLE_LABELS[
            staff.permission_data.role_type.replace(
              '_',
              '',
            ) as keyof typeof ROLE_LABELS
          ] ?? common.emptyValue
        : common.emptyValue;
    });

    return res;
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  return (
    <>
      <Helmet>
        <title>{`CloudSort - Profiles ${
          queryString.parse(location.search)['page']
            ? '- Page ' + queryString.parse(location.search)['page']
            : ''
        }`}</title>
      </Helmet>
      <Layout navCurrent='users_god_view'>
        {showProgress && <ProgressIndicator />}
        <div
          className={classes.header}
          style={{ margin: '0 0 40px 0' }}
        >
          <div style={{ display: 'flex' }}>
            <Typography className={classes.title} variant={'h3'}>
              CloudSort Profiles
            </Typography>
          </div>
          {PermissionsService.hasPermission(
            PermissionsPermissionsEnum.USERWRITE,
          ) && (
            <div
              style={{
                display: 'flex',

                marginBottom: 15,
              }}
            >
              <Button
                data-testid={'station-staff-add-button'}
                variant='contained'
                className={classes.containedButton}
                fullWidth={false}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add User
              </Button>
            </div>
          )}
        </div>
        <AddUserDialog
          isOpen={showAddDialog}
          onAfterDialogClose={() => {
            setShowAddDialog(false);
          }}
          onAddStaff={onAddStaff}
          error={error}
          preselectedEntity={
            mode === Mode.STATION
              ? {
                  value: urlParams.stationId,
                  label: 'Current Station',
                }
              : undefined
          }
        />
        {error && (
          <AlertBanner
            data-testid='station-staff-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        {mode !== Mode.CLOUDSORT && (
          <PaginatedTable
            key={
              urlParams.orgId +
              '-' +
              urlParams.stationId +
              '_' +
              lastUpdated
            }
            title={configurationUtils.getPageTitle()}
            columns={COLUMNS}
            dataTestIdPrefix={'station-staff'}
            fetch={fetchStaffData}
            rowsLoadDetailPages={true}
            detailsPageBasePath={
              mode === Mode.ORGANIZATION
                ? AuthRoutes.USERS_GOD_VIEW +
                  '/organization/' +
                  urlParams.orgId
                : AuthRoutes.USERS_GOD_VIEW +
                  '/organization/' +
                  urlParams.orgId +
                  '/station/' +
                  urlParams.stationId
            }
            filterIsEnabled={true}
            fetchfilters={filters}
            sortableBy={['seconds_per_package', 'status']}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    link: {
      color: colors.darkGold,
      textAlign: 'right',
      float: 'right',
      top: 15,
      position: 'relative',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: colors.darkGold,
      },
    },
    tabWrapper: {
      textTransform: 'capitalize',
      padding: '12px 40px',
    },
    tabsIndicator: {
      height: 5,
      backgroundColor: colors.darkGold,
    },
    tabsBorders: {
      borderRight: '1px solid rgba(151, 151, 151, 0.2)',
      borderLeft: '1px solid rgba(151, 151, 151, 0.2)',
    },
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
  })),
)(UsersGodView);
