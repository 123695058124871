import { createStyles, Theme, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Position } from 'react-flow-renderer';
import flowColors from '../flowColors';
import { NodeData } from '../interfaces';
import Handles from './parts/Handles';

interface Props {
  classes: { [key: string]: string };
  data: NodeData;
  sourcePosition: Position;
  targetPosition: Position;
}

const DockDoorAreaNode = ({
  classes,
  data,
  sourcePosition,
  targetPosition,
}: Props) => {
  const { dispatchTime }: { dispatchTime: number } = data.meta;

  const widthIsLarger = data.width > data.height;

  return (
    <>
      <div
        className={classes.nodeContainer}
        style={{
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: widthIsLarger ? 5 : 0,
          borderTopRightRadius: widthIsLarger ? 0 : 5,
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={
              widthIsLarger
                ? {
                    width: data.width,
                    height: 120,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    //textOrientation: 'sideways',
                    // writingMode: 'vertical-rl',
                  }
                : {
                    width: 120,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    height: data.height,
                  }
            }
          >
            <h4>{data.name}</h4>
            <p>{data.packages} P</p>
            {dispatchTime && (
              <p className={classes.loadTime}>
                {moment(dispatchTime).format('hh:mm A')}
              </p>
            )}
          </div>
        </div>
      </div>
      <Handles
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    nodeContainer: {
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'strech',
      flexDirection: 'column',
      color: 'white',
      background: flowColors.white,
      borderTop: `8px solid ${flowColors.dark}`,

      '& h4': {
        color: flowColors.black,
        fontSize: '15px',
        lineHeight: '15px',
        margin: 0,
        marginBottom: 5,
        fontWeight: 'bold',
      },

      '& p': {
        padding: 0,
        margin: 0,
        color: flowColors.black,
        fontSize: 10,
      },
    },

    loadTime: {
      marginTop: '10px!important',
    },
  })),
)(DockDoorAreaNode);
