import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import {
  LoadPlan,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import { Column } from '../../interfaces/components';
import { Typography } from '../primitives/index';
import MuiButton from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import colors from '../../utils/colors';
import { Link } from 'react-router-dom';
import PermissionsService from '../../services/Permissions.service';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import { Helmet } from 'react-helmet';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import SchemesService from '../../services/Schemes.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import RoutesService from '../../services/Routes.service';
import LoadPointsService from '../../services/LoadPoints.service';
import LoadPlansService from '../../services/LoadPlans.service';
import AddSchemeDialog from './addSchemeDialog/AddSchemeDialog';
import AddSchemeSetDialog from './addSchemeSetDialog/AddSchemeSetDialog';
import configurationUtils from '../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  location: any;
}
export interface IProcessStep {
  id: string;
  label: string;
  active: boolean;
}

const Schemes: React.FC<Props> = ({ classes, location }) => {
  const [showAddNewScheme, setShowAddNewScheme] =
    useState<boolean>(false);
  const [showAddSchemeSet, setShowAddSchemeSet] =
    useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [activeLoadPlan, setActiveLoadPlan] = useState<
    LoadPlan | undefined
  >();

  const COLUMNS_SCHEMES: Column[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'zipcode_count',
      label: 'Zip Codes',
    },
    {
      id: 'fmc_full_name',
      label: 'Carrier',
    },
    {
      id: 'routes_count',
      label: configurationUtils.getPageTitle(false, 'ROUTE'),
    },
    {
      id: 'loadpoints_count',
      label: 'Load Points',
    },
    {
      id: 'is_balancing',
      label: 'Balancing',
      useCustomComponent: true,
    },
    {
      id: 'load_plan_formatted',
      label: configurationUtils.getPageTitle(true, 'LOADPLAN'),
      useCustomComponent: true,
    },
    {
      id: 'loadplan_active',
      label: `${configurationUtils.getPageTitle(
        true,
        'LOADPLAN',
      )} Status`,
      useCustomComponent: true,
    },
  ];

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.LOADPLANREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAndSetActiveLoadPlanId = async () => {
    const res = await LoadPlansService.getAll({
      active: true,
    });
    setActiveLoadPlan(res.data.results[0]);
  };

  useEffect(() => {
    getAndSetActiveLoadPlanId();
  }, []);

  const fetchRoutesCount = async (schemeId: number) => {
    const res = await RoutesService.getAll({
      scheme: schemeId,
    });
    return res.data.count;
  };

  const fetchLoadPointsData = async (schemeId: number) => {
    const res = await LoadPointsService.getAll({
      scheme: schemeId,
    });
    const isBalancing = res.data.results.find(
      (lp) => lp.load_balancing !== null,
    );
    return {
      isBalancing: (
        <Typography>{isBalancing ? 'Yes' : 'No'}</Typography>
      ),
      count: res.data.count,
    };
  };

  const fetchLoadPlanStatus = async (loadPlanId: number) => {
    const res = await LoadPlansService.getById(loadPlanId);
    return res.data.active;
  };

  const fetchSchemesData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    let res = await SchemesService.getAll({
      loadplanStation: EphemeralStateService.getMyStationId(),
      page: pageIndex,
      pageSize: rowsPerPage,
      search: filterByString,
      sortBy:
        sortedBy === 'loadplan_active'
          ? '-load_plan__active'
          : 'load_plan__active',
    });
    //Mini cache for load plans active status.
    const fetchedLoadPlans = new Map();
    for (let scheme of res.data.results as any[]) {
      scheme.load_plan_formatted = (
        <Link
          to={`${AuthRoutes.LOADPLAN}/${scheme.load_plan}`}
          style={{ color: colors.darkGold }}
        >
          <Typography>{scheme.load_plan}</Typography>
        </Link>
      );
      scheme.routes_count = await fetchRoutesCount(scheme.id);
      const lpData = await fetchLoadPointsData(scheme.id);
      scheme.loadpoints_count = lpData.count;
      scheme.is_balancing = lpData.isBalancing;
      if (!fetchedLoadPlans.has(scheme.load_plan)) {
        fetchedLoadPlans.set(
          scheme.load_plan,
          await fetchLoadPlanStatus(scheme.load_plan),
        );
      }
      scheme.loadplan_active = fetchedLoadPlans.get(
        scheme.load_plan,
      ) ? (
        <Typography
          style={{
            color: colors.darkGold,
          }}
        >
          Active
        </Typography>
      ) : (
        <Typography>Inactive</Typography>
      );
    }
    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      Schemes ${
        queryString.parse(location.search)['page']
          ? '- Page ' + queryString.parse(location.search)['page']
          : ''
      }`}
        </title>
      </Helmet>
      <Layout navCurrent='SCHEME'>
        <AddSchemeDialog
          isOpen={showAddNewScheme}
          onAfterClose={() => {
            setShowAddNewScheme(false);
          }}
          updateParent={() => {
            setLastUpdate(lastUpdate + 1);
          }}
          loadplanId={activeLoadPlan?.id}
        />

        <AddSchemeSetDialog
          isOpen={showAddSchemeSet}
          onAfterClose={() => {
            setShowAddSchemeSet(false);
          }}
          updateParent={() => {
            setLastUpdate(lastUpdate + 1);
          }}
          loadplanId={String(activeLoadPlan?.id)}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              Schemes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.LOADPLANWRITE,
            ) && (
              <>
                <MuiButton
                  variant='outlined'
                  className={classes.buttonFilled}
                  onClick={() => {
                    setShowAddNewScheme(true);
                  }}
                >
                  <AddToPhotosOutlinedIcon />
                  Add New Scheme
                </MuiButton>

                <MuiButton
                  variant='outlined'
                  className={classes.outlinedButton}
                  style={{ marginLeft: 15 }}
                  onClick={() => {
                    setShowAddSchemeSet(true);
                  }}
                >
                  <CloudDownloadOutlined
                    style={{
                      marginRight: 10,
                      color: colors.darkGold,
                    }}
                  />
                  Add Scheme Set
                </MuiButton>
              </>
            )}
          </Grid>
        </Grid>
        <PaginatedTable
          key={'table' + lastUpdate}
          tableKey={'table' + lastUpdate}
          title={''}
          columns={COLUMNS_SCHEMES}
          dataTestIdPrefix={'schemes'}
          filterByString={true}
          filterByStringPlaceholder={`Search Schemes`}
          fetch={fetchSchemesData}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.SCHEME}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || '-loadplan_active'
          }
          sortableBy={['loadplan_active']}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      marginBottom: 10,
      height: 42,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
  })),
)(Schemes);
