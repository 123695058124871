class EphemeralState {
  private stationId?: number;
  private orgId?: number;
  private stationPermissions?: string[];
  private stationConfig?: Object;

  public setMyStationId(id: number) {
    this.stationId = id;
  }

  public setMyOrgId(id: number) {
    this.orgId = id;
  }

  public setPermissions(payload?: string[]) {
    this.stationPermissions = payload;
  }

  public setConfiguration(payload?: any) {
    if (
      payload &&
      payload !== '' &&
      JSON.stringify(payload).length > 20
    ) {
      this.stationConfig = payload;
    }
  }

  public getMyStationId() {
    return this.stationId || 0;
  }

  public getMyOrgId() {
    return this.orgId || 0;
  }

  public getMyStationConfiguratation(): any | null {
    return this.stationConfig || null;
  }

  public getMyStationPermissions(): any | null {
    return this.stationPermissions || [];
  }
}

export default new EphemeralState();
