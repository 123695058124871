import React, { useState } from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import Timers from './Timers';
import Tsm from './Tsm';
import ScanModules from './ScanModules';
import WifiConfig from './WifiConfig';
import { INTERACTION } from '../../Configuration';
import PrintedLabel from './PrintedLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TabPanel } from '../../../tabPanel/TabPanel';
import KioskMode from './KioskMode';
import configPageStyles from '../../../commonStyles/configPage.style';
import { CurrentView } from '../Modules';
import Session from './Session';

interface Props {
  classes: { [key: string]: string };
  data: any;
  modulesData: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
}

const OperatorToolDesktop: React.FC<Props> = ({
  classes,
  data,
  modulesData,
  interaction,
  setPartialData,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const currentView = CurrentView.DESKTOP;
  return (
    <>
      <Box mt={1}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.expansionPanelExpandColapseIcon}
              />
            }
          >
            <Typography
              style={{
                color: colors.white,
                fontWeight: 500,
                fontSize: '18px',
              }}
            >
              Operator Tool
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Tabs
                data-testid='operator-tool-tabs-root'
                value={currentTab}
                variant='scrollable'
                onChange={(event, newValue) => {
                  setCurrentTab(newValue);
                }}
                classes={{
                  indicator: classes.tabsIndicator,
                  root: classes.tabsRoot,
                }}
              >
                <Tab
                  label='Scan Modes'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='Timers'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='WiFi'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='Kiosk Mode'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='Printed Label'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='TSM'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
                <Tab
                  label='Session'
                  classes={{
                    selected: classes.selectedTab,
                    root: classes.tab,
                  }}
                  disableRipple
                />
              </Tabs>
            </Box>

            <TabPanel
              currentTab={currentTab}
              index={0}
              label='Scan Modes'
            >
              <ScanModules
                currentView={currentView}
                data={data?.MODES}
                interaction={interaction}
                setPartialData={(modes) => {
                  const dataObj = { ...data };
                  dataObj.MODES = modes;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel
              currentTab={currentTab}
              index={1}
              label='Timers'
            >
              <Timers
                currentView={currentView}
                data={data?.TIMERS}
                interaction={interaction}
                setPartialData={(timers) => {
                  const dataObj = { ...data };
                  dataObj.TIMERS = timers;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2} label='WiFi'>
              <WifiConfig
                currentView={currentView}
                data={data?.ADM.wifi}
                interaction={interaction}
                setPartialData={(c) => {
                  const dataObj = { ...data };
                  dataObj.ADM.wifi = c;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel
              currentTab={currentTab}
              index={3}
              label='Kiosk Mode'
            >
              <KioskMode
                currentView={currentView}
                data={data?.ADM.kiosk}
                interaction={interaction}
                setPartialData={(state) => {
                  const dataObj = { ...data };
                  dataObj.ADM.kiosk = state;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel
              currentTab={currentTab}
              index={4}
              label='Printed Label'
            >
              <PrintedLabel
                currentView={currentView}
                data={data?.LABEL_DEFINITION}
                interaction={interaction}
                setPartialData={(state) => {
                  const dataObj = { ...data };
                  dataObj.LABEL_DEFINITION = state;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={5} label='TSM'>
              <Tsm
                currentView={currentView}
                data={data?.TSM_OBJECT_STRUCTURE}
                modulesData={modulesData}
                interaction={interaction}
                setPartialData={(tsm) => {
                  const dataObj = { ...data };
                  dataObj.TSM_OBJECT_STRUCTURE = tsm;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
            <TabPanel
              currentTab={currentTab}
              index={6}
              label='Session'
            >
              <Session
                currentView={currentView}
                data={data?.SESSION}
                interaction={interaction}
                setPartialData={(sessionData) => {
                  const dataObj = { ...data };
                  dataObj.SESSION = sessionData;
                  setPartialData(dataObj);
                }}
              />
            </TabPanel>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(OperatorToolDesktop);
