import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, AlertBanner } from '../primitives';

import Paper from '@material-ui/core/Paper';
import {
  humanReadableNull,
  renderVolumeString,
  renderWeightString,
} from '../DetailsPagesFunctions';
import Layout from '../layout/Layout';
import {
  OutboundLoad,
  Container,
  OutboundLoadLoadStatusEnum,
  PermissionsPermissionsEnum,
  DockDoorDetails,
  NestedProcessStepSerializerContainerProcessStepEnum,
} from 'cloudsort-client';

import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiButton from '@material-ui/core/Button';
import { STRING_UNITS_VOLUME } from '../UIStrings';
import { common } from '../../utils/strings';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import InputLabel from '@material-ui/core/InputLabel';
import DateOrTimePicker from '../datePicker/DateOrTimePicker';
import colors from '../../utils/colors';

import {
  GET_COLUMNS_PACKAGES,
  fetch as fetchPackages,
} from '../packages/Packages';
import ContainersService from '../../services/Containers.service';
import configurationUtils from '../../utils/configurationUtils';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import { debounce } from 'lodash';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// Services
import DockDoorService from '../../services/DockDoors.service';
import RoutesService from '../../services/Routes.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

//Types
import { AxiosError } from 'axios';
import { Grid, createStyles } from '@material-ui/core';
import EventsService from '../../services/Events.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Column, TypeAheadItem } from '../../interfaces/components';
import { Helmet } from 'react-helmet';
import { formatContainer } from '../containers/Containers';

export const formatLabel = (str?: string) => {
  if (str === 'N/A') return str;
  const label = str
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

const asyncStyles = {
  ...asyncSelectStyles,
  menuPortal: (styles: any) => {
    return {
      ...styles,
      zIndex: 9999,
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      margin: '0 -3px',
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: colors.lightGray,
      top: '65%',
    };
  },
  control: (styles: any, state: any) => {
    return {
      ...styles,
      backgroundColor: 'transparent !important',
      background: '#F1F1F1',
      padding: '10px 0 0 0',
      boxShadow: 'none !important',
      outline: `none !important`,
      border: 0,
    };
  },
};

const defaultDialogInputStyle = {
  width: '48%',
  margin: 0,
  height: 50,
};
const labelDialogInputStyle = { fontSize: 12, top: '-18px' };
const ddDialogInputStyle = {
  width: '50%',
  margin: '0 10px 10px 0',
  height: 50,
};

interface Props {
  classes: { [key: string]: string };
  match: any;
}

interface PartialContainerData {
  id: number;
  bol: string;
}

const OutboundLoadDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const thisOutboundLoadsId = match.params.id;
  const [outboundLoad, setOutboundLoad] = useState<OutboundLoad>();
  const [containers, setContainers] = useState<Container[]>();
  const [partialContainerData, setPartialContainerData] = useState<
    PartialContainerData[]
  >([]);
  const [containerLastUpdated, setContainerLastUpdated] =
    useState<number>(0);
  const [
    partialContainerLastUpdated,
    setPartialContainerLastUpdated,
  ] = useState<number>(0);
  const [stagedContainersCount, setStagedContainersCount] =
    useState<number>();

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [showEditBOLDialog, setShowEditBOLDialog] =
    useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [error, setError] = useState<string>();

  //Edit form fields
  const [route, setRoute] = useState<TypeAheadItem>();
  const [capacity, setCapacity] = useState<string | number>();
  const [loadName, setLoadName] = useState<string>();
  const [loadTime, setLoadTime] = useState<Date>();
  const [dockDoor, setDockDoor] = useState<TypeAheadItem>();
  const [trailerId, setTrailerId] = useState<string>();
  const [bol, setBol] = useState<string>();
  const [loadStatus, setLoadStatus] =
    useState<OutboundLoadLoadStatusEnum>();

  const isBOLActive = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.LOAD_OPS.bol_active;
  }, []);

  const isBOLInherit = useMemo(() => {
    return (
      EphemeralStateService.getMyStationConfiguratation().GENERAL
        .BEHAVIORS.LOAD_OPS.bol_mode === 'INHERIT_ONLY'
    );
  }, []);

  // Get labels on each render cycle
  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'volume',
      label: 'Volume',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
  ];

  if (isBOLActive) {
    COLUMNS_CONTAINERS.push({
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
    });
  }

  const inputLabelProps = {
    shrink: true,
    className: classes.selectLabel,
  };

  const inputProps = {
    disableUnderline: true,
    className: classes.inputPadding,
  };

  const packagesTableColumns = useMemo(() => {
    return GET_COLUMNS_PACKAGES().map((column) => {
      if (column.id === 'outbound_load_name') {
        // remove link from outbound_load_name because is the same as the current view
        column.link = undefined;
      }
      return column;
    });
  }, []);

  const editClickHandler = () => {
    if (outboundLoad?.dockdoor) {
      setDockDoor({
        value: outboundLoad.dockdoor.toString(),
        label: outboundLoad.dockdoor_name || '',
      });
    } else {
      setDockDoor(undefined);
    }

    if (outboundLoad?.load_time)
      setLoadTime(new Date(outboundLoad?.load_time));

    if (outboundLoad?.route) {
      setRoute({
        value: outboundLoad.route.toString(),
        label: outboundLoad.route_name || '',
      });
    }
    if (outboundLoad?.capacity) setCapacity(outboundLoad?.capacity);

    if (outboundLoad?.name) setLoadName(outboundLoad?.name);

    if (outboundLoad?.bol) setBol(outboundLoad?.bol);

    setShowEditDialog(true);
  };

  const deleteLoadHandler = async () => {
    setShowProgress(true);
    try {
      if (thisOutboundLoadsId) {
        await OutboundLoadsService.delete(thisOutboundLoadsId);
        browserHistory.push(AuthRoutes.OUTBOUND_LOAD);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const loadRoutesOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      RoutesService.getAll({ search: inputValue })
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const checkIfChange = (partialValue: any, dataValue: any) => {
    if (partialValue instanceof Date && dataValue instanceof Date) {
      if (partialValue.toString() !== dataValue.toString()) {
        return partialValue;
      }
    } else if (partialValue !== dataValue) {
      return partialValue;
    }
    return undefined;
  };

  const saveEditHandler = async () => {
    setShowProgress(true);
    try {
      if (route && loadTime) {
        await OutboundLoadsService.update(
          thisOutboundLoadsId,
          checkIfChange(Number(route.value!), outboundLoad?.route),
          checkIfChange(loadTime, new Date(outboundLoad?.load_time!)),
          checkIfChange(
            Number(dockDoor?.value!),
            outboundLoad?.dockdoor,
          ),
          checkIfChange(loadStatus, outboundLoad?.load_status),
          checkIfChange(Number(capacity), outboundLoad?.capacity),
          checkIfChange(
            trailerId !== '' ? trailerId : null,
            outboundLoad?.trailer_id,
          ),
          checkIfChange(loadName, outboundLoad?.name),
          checkIfChange(bol, outboundLoad?.bol),
        );
        setShowEditDialog(false);
        await fetchAll();
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  //Fetch events

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      outboundLoad: thisOutboundLoadsId,
      pageSize: rowsPerPage,
    });
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const fetchOutboundLoadDetails = async () => {
    return OutboundLoadsService.getById(thisOutboundLoadsId);
  };

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      outboundLoad: thisOutboundLoadsId,
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });

    setContainers(res.data.results);

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });

    return res;
  };

  const fetchOutboundLoadDetailsData = async () => {
    const { data } = await fetchOutboundLoadDetails();
    setOutboundLoad(data);
    setLoadStatus(data.load_status);
    setCapacity(data.capacity?.toString());
    setTrailerId(data.trailer_id || '');
    if (data.route) {
      setRoute({
        value: data.route.toString(),
        label: data.route_name || '',
      });
    }

    if (data.load_status !== OutboundLoadLoadStatusEnum.DISPATCHED) {
      const res = await ContainersService.getAll({
        page: 1,
        targetOutboundLoad: thisOutboundLoadsId,
        pageSize: 10,
        fromDate: '',
        toDate: '',
        processStep: [
          NestedProcessStepSerializerContainerProcessStepEnum.STAGED,
        ],
        excludeParentContainers: true,
      });

      setStagedContainersCount(res.data.count);
    }

    setContainerLastUpdated(Date.now());
  };

  const fetchAll = async () => {
    setShowProgress(true);
    try {
      await fetchOutboundLoadDetailsData();
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const renderError = () => {
    return (
      error && (
        <AlertBanner
          data-testid='outbound-loads-error-banner'
          className={classes.banner}
          severity='error'
          alertTitle={'Error'}
          alertMsg={error}
        />
      )
    );
  };

  const getDialogTitle = () =>
    `Edit ${configurationUtils.getPageTitle(true, 'OUTBOUND_LOAD')} ${
      outboundLoad?.name || outboundLoad?.id
    }`;

  const updateContainerData = async (
    partialData: PartialContainerData[],
  ) => {
    setShowProgress(true);
    try {
      for (const dataEl of partialData) {
        await ContainersService.update(dataEl.id, {
          bol: dataEl.bol,
        });
      }
      setContainerLastUpdated(Date.now());
      setShowEditBOLDialog(false);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const renderEditBOLDialog = () => {
    const tableHeaders = [
      'Container ID',
      'Status',
      'Type',
      'Nr of Packages',
      'BOL',
    ];

    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showEditBOLDialog}
        TransitionComponent={Transition}
        style={{ minWidth: 650 }}
        maxWidth='xl'
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        {renderError()}
        <DialogTitle>
          {getDialogTitle()}
          <br />
          <br />
          Edit Containers BOLs
        </DialogTitle>

        <DialogContent
          className={classes.dialogRoot}
          style={{ overflow: 'hidden', minWidth: 650, padding: 0 }}
        >
          <Box mt={1}>
            <TableContainer
              component={Paper}
              key={partialContainerLastUpdated}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => {
                      return (
                        <TableCell
                          key={header}
                          className={classes.editBolTableHeader}
                        >
                          {header}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {containers?.map((container: Container) => (
                    <TableRow key={container.id}>
                      <TableCell>{container.id}</TableCell>
                      <TableCell>
                        {formatLabel(container.container_status)}
                      </TableCell>
                      <TableCell>
                        {formatLabel(container.container_type)}
                      </TableCell>
                      <TableCell>{container.package_count}</TableCell>
                      <TableCell style={{ padding: 5 }}>
                        <FormControl
                          className={classes.formControl}
                          style={{
                            maxWidth: 200,
                            minWidth: 100,
                          }}
                        >
                          <TextField
                            defaultValue={container.bol}
                            onChange={(e) => {
                              const c = partialContainerData.find(
                                (c) => c.id === container.id,
                              );
                              const value = e.target.value;
                              if (c) {
                                c.bol = value;
                                setPartialContainerData([
                                  ...partialContainerData,
                                ]);
                              } else {
                                setPartialContainerData([
                                  ...partialContainerData,
                                  { id: container.id!, bol: value },
                                ]);
                              }
                            }}
                            InputProps={inputProps}
                            inputProps={{ min: '0' }}
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box>
            <MuiButton
              data-testid={'outbound-loads-BOL-dialog-reset-all'}
              onClick={() => {
                setPartialContainerData([]);
                setPartialContainerLastUpdated(Date.now());
              }}
            >
              Reset All
            </MuiButton>
          </Box>
          <Box>
            <MuiButton
              data-testid={'outbound-loads-BOL-dialog-cancel'}
              onClick={() => {
                onAfterDialogClose();
              }}
            >
              Cancel
            </MuiButton>
            <MuiButton
              onClick={() => {
                updateContainerData(partialContainerData);
              }}
              data-testid={'outbound-loads-BOL-dialog-save'}
              disabled={!route}
            >
              Save
            </MuiButton>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDialog = () => {
    const hasCustomBol =
      !!bol && containers?.some((container) => container.bol !== bol);
    const showSomeContainersLabel =
      !!outboundLoad?.bol && hasCustomBol && !isBOLInherit;

    const getMinHeight = () => {
      if (isBOLActive) {
        if (showSomeContainersLabel) {
          return 290;
        } else {
          return 250;
        }
      }
      return 200;
    };

    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showEditDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        {renderError()}
        <DialogTitle>{getDialogTitle()}</DialogTitle>

        <DialogContent
          className={classes.dialogRoot}
          style={{ overflow: 'hidden', minHeight: getMinHeight() }}
        >
          <FormControl
            className={classes.formControl}
            style={ddDialogInputStyle}
            data-testid='select-route'
          >
            <InputLabel
              className={classes.selectLabel}
              style={labelDialogInputStyle}
            >
              {`Select ${configurationUtils.getPageTitle(
                true,
                'ROUTE',
              )}`}
            </InputLabel>
            <AsyncSelect<TypeAheadItem>
              isClearable
              cacheOptions
              styles={asyncStyles}
              loadOptions={loadRoutesOptions}
              onChange={(option) => {
                setRoute(option as TypeAheadItem);
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={route}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
            />
          </FormControl>

          <FormControl
            className={classes.formControl}
            style={defaultDialogInputStyle}
            data-testid='trailer-id'
          >
            <TextField
              label={configurationUtils.getPropertylabel(
                'OUTBOUND_LOAD',
                'trailer_id',
              )}
              type='text'
              defaultValue={trailerId}
              onChange={(e) => {
                setTrailerId(e.target.value);
              }}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </FormControl>

          <FormControl
            className={`${classes.formControl} ${classes.datePicker}`}
            style={ddDialogInputStyle}
            data-testid='select-dispatch-time'
          >
            <DateOrTimePicker
              dateTime
              label={'Dispatch time'}
              variant={'inline'}
              date={new Date(loadTime ?? '')}
              triggerEl={null}
              id={'date-picker-edit-load'}
              onAfterChange={(date) => {
                date ? setLoadTime(date) : setLoadTime(undefined);
              }}
            />
          </FormControl>

          <FormControl
            className={classes.formControl}
            style={defaultDialogInputStyle}
            data-testid='load-capacity'
          >
            <TextField
              label='Capacity'
              type='number'
              defaultValue={capacity}
              onChange={(e) => {
                setCapacity(e.target.value);
              }}
              InputLabelProps={inputLabelProps}
              InputProps={{
                ...inputProps,
                endAdornment: (
                  <InputAdornment
                    style={{ marginRight: 5 }}
                    position='end'
                  >
                    {STRING_UNITS_VOLUME}
                  </InputAdornment>
                ),
              }}
              inputProps={{ min: '0' }}
            />
          </FormControl>
          <FormControl
            className={classes.formControl}
            style={ddDialogInputStyle}
            data-testid='load-name'
          >
            <TextField
              label='Name'
              type='text'
              value={loadName}
              onChange={(e) => {
                setLoadName(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value.trim() === '')
                  setLoadName(
                    route?.label ? 'OL-' + route?.label : '',
                  );
              }}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
            />
          </FormControl>

          <FormControl
            className={classes.formControl}
            style={defaultDialogInputStyle}
            data-testid='select-dock-door'
          >
            <InputLabel
              className={classes.selectLabel}
              style={labelDialogInputStyle}
            >
              {configurationUtils.isModuleActive('DOCK_DOOR')
                ? configurationUtils.getPageTitle(true, 'DOCK_DOOR')
                : 'Destination'}
            </InputLabel>
            <AsyncSelect<TypeAheadItem>
              isClearable
              cacheOptions
              styles={asyncStyles}
              loadOptions={loadDDOptions}
              onChange={(option) => {
                setDockDoor(option as TypeAheadItem);
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={dockDoor}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
            />
          </FormControl>

          {isBOLActive && (
            <FormControl
              className={classes.formControl}
              style={{ ...defaultDialogInputStyle, width: '50%' }}
              data-testid='edit-load-bol'
            >
              <TextField
                label='BOL'
                value={bol}
                onChange={(e) => {
                  setBol(e.target.value);
                }}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                inputProps={{ min: '0' }}
              />
              {showSomeContainersLabel && (
                <Box fontSize={12}>
                  <Box mt={2}>
                    Some containers are already with BOL set up
                  </Box>
                  <Box
                    mt={0.5}
                    className={classes.bolLink}
                    role='button'
                    onClick={() => {
                      saveEditHandler();
                      setShowEditDialog(false);
                      setShowEditBOLDialog(true);
                    }}
                  >
                    Save and Set BOL’s at containers level
                  </Box>
                </Box>
              )}
            </FormControl>
          )}
        </DialogContent>

        <DialogActions>
          <MuiButton
            data-testid={'outbound-loads-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              saveEditHandler();
            }}
            data-testid={'outbound-loads-dialog-save'}
            disabled={!route}
          >
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  const onAfterDialogClose = () => {
    setShowEditBOLDialog(false);
    setShowEditDialog(false);
    setLoadTime(undefined);
    setDockDoor(undefined);
    setRoute(undefined);
    setCapacity(undefined);
    setError(undefined);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.OUTBOUNDLOADREAD,
    );

    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
  ${configurationUtils.getPageTitle(true, 'OUTBOUND_LOAD')} ${
            outboundLoad?.name || outboundLoad?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='OUTBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        {renderEditBOLDialog()}
        <ConfirmationDialog
          dataTestIdPrefix={'load-details-delete-dialog'}
          title={`Delete ${configurationUtils.getPageTitle(
            true,
            'OUTBOUND_LOAD',
          )}`}
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'OUTBOUND_LOAD',
          )} ${outboundLoad?.name || thisOutboundLoadsId}?`}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteLoadHandler}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        <div
          className={classes.detailViewoutboundLoad}
          data-testid='load-details-view'
        >
          {renderError()}
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.title}>
                {`${configurationUtils.getPageTitle(
                  true,
                  'OUTBOUND_LOAD',
                )} ${outboundLoad?.name || outboundLoad?.id || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              {PermissionsService.hasPermission(
                PermissionsPermissionsEnum.OUTBOUNDLOADWRITE,
              ) && (
                <Fragment>
                  <Button
                    className={classes.marginButton}
                    data-testid={'load-details-edit-button'}
                    fullWidth={false}
                    disabled={!outboundLoad}
                    onClick={(e) => {
                      e.preventDefault();
                      editClickHandler();
                    }}
                  >
                    Edit
                    <EditIcon style={{ marginLeft: 5 }} />
                  </Button>
                  <Button
                    className={classes.marginButton}
                    data-testid={'load-details-delete-button'}
                    fullWidth={false}
                    disabled={!outboundLoad}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDeleteDialog(true);
                    }}
                  >
                    Delete
                    <DeleteIcon />
                  </Button>
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6} md={8}>
              <Paper
                className={classes.paper}
                style={{ padding: '15px 30px' }}
                data-testid=''
              >
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label='Status'
                  value={
                    outboundLoad?.load_status
                      ? formatLabel(outboundLoad.load_status)
                      : common.emptyValue
                  }
                />{' '}
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPropertylabel(
                    'OUTBOUND_LOAD',
                    'trailer_id',
                  )}
                  value={
                    outboundLoad?.trailer_id || common.emptyValue
                  }
                />{' '}
                {isBOLActive && (
                  <SingleDetail
                    inline={true}
                    valueStyle={{ color: colors.darkGold }}
                    label={'BOL'}
                    value={outboundLoad?.bol || common.emptyValue}
                  />
                )}
                <br />{' '}
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPageTitle(
                    true,
                    'DOCK_DOOR',
                  )}
                  value={
                    outboundLoad?.dockdoor_name || common.emptyValue
                  }
                />{' '}
                <SingleDetail
                  inline={true}
                  valueStyle={{ color: colors.darkGold }}
                  label={configurationUtils.getPageTitle(
                    true,
                    'ROUTE',
                  )}
                  value={
                    outboundLoad?.route_name || common.emptyValue
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <div
                  className={classes.singleStat}
                  style={{ textAlign: 'center', flex: '1 1 0' }}
                >
                  <Typography
                    variant='body1'
                    className={classes.singleStatLabel}
                    data-testid='Target Load Time:label'
                  >
                    Target Load Time
                  </Typography>
                  <Typography
                    variant='h6'
                    className={classes.statsValue}
                    data-testid='Target Load Time:value'
                  >
                    {outboundLoad?.load_time
                      ? new Date(
                          outboundLoad?.load_time,
                        ).toLocaleString()
                      : common.emptyValue}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ marginRight: 18, padding: 16 }}
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  {configurationUtils.getPageTitle(
                    true,
                    'OUTBOUND_LOAD',
                  )}{' '}
                  Details
                </Typography>
                <SingleDetail
                  label={`Loaded ${configurationUtils.getPageTitle(
                    false,
                    'CONTAINER',
                  )}`}
                  value={humanReadableNull(
                    outboundLoad?.containers_loaded_count,
                  )}
                  valueStyle={{ color: colors.darkGold }}
                />
                {outboundLoad?.load_status !==
                  OutboundLoadLoadStatusEnum.DISPATCHED && (
                  <SingleDetail
                    label={`Staged ${configurationUtils.getPageTitle(
                      false,
                      'CONTAINER',
                    )}`}
                    value={humanReadableNull(stagedContainersCount)}
                    valueStyle={{ color: colors.darkGold }}
                  />
                )}
                <SingleDetail
                  label={`Loaded ${configurationUtils.getPageTitle(
                    false,
                    'PACKAGE',
                  )}`}
                  value={humanReadableNull(
                    outboundLoad?.packages_loaded_count,
                  )}
                  valueStyle={{ color: colors.darkGold }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ padding: 16 }}
              >
                <Typography
                  className={classes.boldNameParam}
                  style={{
                    paddingBottom: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                  }}
                >
                  {configurationUtils.getPageTitle(
                    true,
                    'OUTBOUND_LOAD',
                  )}{' '}
                  Details
                </Typography>
                <SingleDetail
                  label={`${configurationUtils.getPageTitle(
                    true,
                    'OUTBOUND_LOAD',
                  )} Capacity`}
                  value={renderVolumeString(outboundLoad?.capacity)}
                  valueStyle={{ color: colors.darkGold }}
                />
                <SingleDetail
                  label='Current Volume'
                  value={renderVolumeString(outboundLoad?.volume)}
                />
                <SingleDetail
                  label='Current Weight'
                  value={renderWeightString(outboundLoad?.weight)}
                />
              </Paper>
            </Grid>
          </Grid>

          <EventsPaginatedTable
            dataTestIdPrefix={'load-details-events'}
            fetch={fetchEvents}
            sortableBy={['timestamp', 'area_name']}
          />
          <br />
          {configurationUtils.isModuleActive('PACKAGE') &&
            PermissionsService.hasPermission(
              PermissionsPermissionsEnum.PACKAGEREAD,
            ) && (
              <PaginatedTable
                disableUpdateQueryStringUrl
                title={configurationUtils.getPageTitle(
                  false,
                  'PACKAGE',
                )}
                columns={packagesTableColumns}
                dataTestIdPrefix={'load-details-packages'}
                fetch={(
                  pageIndex: number,
                  rowsPerPage: number,
                  filterOptions?: filterObj[],
                  filterByString?: string,
                  sortedBy?: string,
                ) => {
                  return fetchPackages(
                    pageIndex,
                    rowsPerPage,
                    {},
                    sortedBy,
                    {
                      outboundLoad: [thisOutboundLoadsId],
                      outboundLoadDirect: true,
                      fromDate: '',
                      toDate: '',
                    } as any,
                  );
                }}
                rowsLoadDetailPages={true}
                detailsPageBasePath={AuthRoutes.PACKAGE}
              />
            )}

          {configurationUtils.isModuleActive('CONTAINER') &&
            PermissionsService.hasPermission(
              PermissionsPermissionsEnum.CONTAINERREAD,
            ) && (
              <Fragment>
                <br />
                <PaginatedTable
                  disableUpdateQueryStringUrl
                  key={'table' + containerLastUpdated}
                  tableKey={'table' + containerLastUpdated}
                  title={configurationUtils.getPageTitle(
                    false,
                    'CONTAINER',
                  )}
                  columns={COLUMNS_CONTAINERS}
                  dataTestIdPrefix={'load-details-containers'}
                  fetch={fetchContainers}
                  rowsLoadDetailPages={true}
                  detailsPageBasePath={AuthRoutes.CONTAINER}
                />
              </Fragment>
            )}
        </div>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    editBlock: {
      margin: theme.spacing(4, 0),
      padding: '0 2em 0 2em',
    },
    detailViewoutboundLoad: {
      padding: '0 2em 0 2em',
    },
    padding2em: {
      padding: '2em',
    },
    flexRowEvenSpread: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    half: {
      width: '50%',
      borderRight: '1px solid #fafafa',
    },
    outboundLoadDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableHeader: {
      background: '#fafafa',
    },
    asyncPadding: {
      '&>div': {
        padding: '6px 0',
      },
    },
    marginButton: {
      margin: '0 0 0 8px',
    },
    banner: {
      width: '100%',
      borderRadius: 4,
      margin: '10px 0',
    },
    bolLink: {
      color: colors.darkGold,
      cursor: 'pointer',
    },
    editBolTableHeader: {
      color: colors.lightGray,
      width: '25%',
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    editRow: {
      '& td': {
        height: 70,
      },
      '& th': {
        height: 70,
      },
    },
    autoCompleteComponent: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputLabel-formControl': {
        transform: 'translate(5px, 17px) scale(1)',
      },
      '& .MuiInputLabel-shrink': {
        top: '5px',
        left: '5px',
        transform: 'translate(0, 1.5px) scale(0.75)',
      },
      '& .MuiInputBase-root': {
        paddingLeft: '5px',
      },
      '& .MuiInput-underline': {
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
      },
    },
    datePicker: {
      '& .MuiTextField-root': {
        position: 'absolute',
        width: '100%',
        top: 3,
        left: 5,
      },
    },
    loadPercentageoutboundLoad: {
      padding: '20px',
    },
    loadBarOuter: {
      width: '100%',
      background: colors.lightGold,
      borderRadius: '5px',
    },
    loadBarInner: {
      width: 0,
      height: '10px',
      background: colors.darkGold,
      borderRadius: '5px',
    },
    loadBarLabel: {
      margin: '10px 0 0 0',
      fontSize: '30px',
      lineHeight: 1,
    },
  })),
)(OutboundLoadDetailsComponent);
