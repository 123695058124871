import React, { Dispatch, useEffect, useState } from 'react';
import { Typography } from '../../../primitives';
import enumToLabel from '../../../../utils/enumToLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Switch,
  Hidden,
  Theme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import classNames from 'classnames';
import { INTERACTION } from '../../Configuration';
import configPageStyles from '../../../commonStyles/configPage.style';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  currentView: CurrentView;
}

const initDialogData = {
  silent_after: 0,
  key: null,
};

const ExceptionProcess: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [exceptionProcessData, setExceptionProcessData] = useState<
    any[]
  >([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);

  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Exception Process';
  const exceptionBehaviors = [
    'MISLOADING',
    'MISSORTING',
    'MISMOVING',
  ];

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(
    function setProcessData() {
      if (data && currentView !== CurrentView.SEARCH) {
        const tmpData: any[] = [];

        for (let item of Object.entries(data)) {
          if (exceptionBehaviors.includes(item[0])) {
            tmpData.push(item[0]);
          }
        }
        setExceptionProcessData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, currentView],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section if there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );
        const dynamicLabels: string[] = [];
        for (let item of Object.entries(data)) {
          if (exceptionBehaviors.includes(item[0])) {
            dynamicLabels.push(enumToLabel(item[0]));
            //The silent flow is disabled for now.
            //dynamicLabels.push(`${data[item[0]].silent_after} scans`);
          }
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);
        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  useEffect(
    function filterProcessStepsBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          if (exceptionBehaviors.includes(item[0])) {
            const key = item[0];
            const silent_after = `${
              data[item[0]].silent_after
            } scans`;
            if (
              showWholeSection ||
              searchResult.some((result) => {
                return (
                  key.toLowerCase().includes(result.toLowerCase()) ||
                  silent_after
                    .toLowerCase()
                    .includes(result.toLowerCase())
                );
              })
            ) {
              tmpData.push(item[0]);
            }
          }
          setExceptionProcessData(tmpData);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid={`edit-silent-after-${dialogData.key}`}
            type={'number'}
            autoFocus
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.silent_after}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                silent_after: Number(e.target.value),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid='edit-dialog-save-btn'
            onClick={() => {
              data[dialogData.key].silent_after =
                dialogData.silent_after;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    ////The silent flow is disabled for now.
    //Configs that are related to silent flow are disabled with CSS.
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={5} xs={3} className={classes.pLeft}>
                    Behavior
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={2} />
                  </Hidden>
                  <Grid
                    item
                    sm={1}
                    xs={2}
                    style={{ visibility: 'hidden' }}
                  >
                    Silent
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    xs={3}
                    style={{ visibility: 'hidden' }}
                  >
                    Silent after
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    xs={1}
                    style={{ visibility: 'hidden' }}
                  />
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              {exceptionProcessData.map((itemKey, index, array) => {
                return (
                  <Grid
                    container
                    item
                    sm={12}
                    key={index}
                    className={classes.row}
                  >
                    <Grid
                      item
                      sm={5}
                      xs={3}
                      className={classNames(
                        classes.text,
                        classes.pLeft,
                      )}
                    >
                      <Highlighter
                        highlightClassName={classes.searchResultsMark}
                        searchWords={searchResult}
                        autoEscape={true}
                        textToHighlight={enumToLabel(itemKey)}
                      />
                    </Grid>
                    <Hidden xsDown>
                      <Grid item sm={2} />
                    </Hidden>
                    <Grid
                      item
                      sm={1}
                      xs={2}
                      className={classes.muiSwitchHoler}
                      style={{ visibility: 'hidden' }}
                    >
                      <Switch
                        data-testid={`Exception Process-switch-${enumToLabel(
                          itemKey,
                        )}-silent-status`}
                        color='default'
                        size='small'
                        disabled={interaction === INTERACTION.READ}
                        className={
                          data[itemKey].silent_active
                            ? classes.muiSwitch
                            : classes.muiSwitchOff
                        }
                        checked={data[itemKey].silent_active}
                        onChange={() => {
                          data[itemKey].silent_active =
                            !data[itemKey].silent_active;
                          setPartialData({
                            ...data,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      xs={3}
                      className={classes.text}
                      style={{ visibility: 'hidden' }}
                    >
                      <Highlighter
                        highlightClassName={classes.searchResultsMark}
                        searchWords={searchResult}
                        autoEscape={true}
                        textToHighlight={`${data[itemKey].silent_after} scans`}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      xs={1}
                      style={{ visibility: 'hidden' }}
                    >
                      <IconButton
                        data-testid={`Exception Process-edit-btn-${enumToLabel(
                          itemKey,
                        )}`}
                        size='small'
                        className={classes.editIcon}
                        disabled={interaction === INTERACTION.READ}
                        onClick={() => {
                          setDialogData({
                            silent_after: data[itemKey].silent_after,
                            key: itemKey,
                          });
                          setShowDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      xs={3}
                      className={classes.muiSwitchHoler}
                    >
                      <Switch
                        data-testid={`Exception Process-switch-${enumToLabel(
                          itemKey,
                        )}-active-status`}
                        color='default'
                        size='small'
                        disabled={interaction === INTERACTION.READ}
                        className={
                          data[itemKey].active
                            ? classes.muiSwitch
                            : classes.muiSwitchOff
                        }
                        checked={data[itemKey].active}
                        onChange={() => {
                          data[itemKey].active =
                            !data[itemKey].active;
                          setPartialData({
                            ...data,
                          });
                        }}
                      />
                    </Grid>
                    {index !== array.length - 1 && (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </>
    );
  };
  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(ExceptionProcess);
