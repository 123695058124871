import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  h3: {
    fontSize: '0.9rem',
    margin: theme.spacing(3, 0),
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
  },
  h5: {
    fontSize: '0.67rem',
    padding: theme.spacing(2, 2, 0, 2),
  },
}));
