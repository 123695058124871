import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  holder: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.26) 0px 2px 5px 0px',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
    '& tbody td': {
      background: colors.white,
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      height: 50,
    },
  },
  toolBar: {
    backgroundColor: colors.white,
    minHeight: 56,
    justifyContent: 'space-between',
    '& p': {
      color: colors.dark,
      fontSize: 24,
      lineHeight: '28px',
    },
  },
  tableHeadRow: {
    '& th': {
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'center',
      verticalAlign: 'middle',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      height: 50,
    },
    '& span': {
      opacity: 0.2,
    },
  },
  tableHeader: {
    color: colors.dark,
    fontSize: 14,
  },
  tableContent: {
    color: colors.dark,
    fontSize: 13,
  },
  dndIcon: {
    color: colors.dark,
    opacity: 0.7,
  },
  deleteButton: {
    color: colors.darkGold,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
  },
}));
