import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { createStyles, withStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import colors from '../../utils/colors';

interface Props {
  classes: { [key: string]: string };
  when?: boolean | undefined;
  forceOpen: boolean;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  resetGlobalUnsavedChanges: () => void;
  onSave: () => void;
  onAfterCancel: () => void;
}
const RouteLeavingGuard = ({
  classes,
  when,
  forceOpen,
  onSave,
  navigate,
  shouldBlockNavigation,
  resetGlobalUnsavedChanges,
  onAfterCancel,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(
    null,
  );
  const [confirmedNavigation, setConfirmedNavigation] =
    useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setLastLocation(null);
    setConfirmedNavigation(false);
    onAfterCancel();
  };

  const handleBlockedNavigation = (
    nextLocation: Location,
  ): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    resetGlobalUnsavedChanges();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (forceOpen) {
      setModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceOpen]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={modalVisible}
        TransitionComponent={Transition}
        onClose={closeModal}
      >
        <DialogTitle style={{ textAlign: 'center', marginTop: 40 }}>
          Unsaved Changes
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <Box style={{ textAlign: 'center' }}>
            You didn’t save your new configurations. <br />
            Are you sure you want to leave without saving?
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={2} mx={5} mb={2}>
            <Button
              onClick={closeModal}
              variant='contained'
              className={classes.containedButton}
              style={{
                color: colors.gray,
                border: `2px solid ${colors.gray}`,
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmNavigationClick}
              variant='contained'
              className={classes.containedButton}
              style={{ marginLeft: 10, marginBottom: 10 }}
            >
              Discard Changes
            </Button>
            <Button
              onClick={() => {
                onSave();
                handleConfirmNavigationClick();
              }}
              variant='outlined'
              className={classes.outlinedButton}
              style={{
                backgroundColor: colors.darkGold,
                color: colors.dark,
                border: `2px solid ${colors.darkGold}`,
                marginLeft: 10,
                marginBottom: 10,
              }}
            >
              Save and Exit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
  })),
)(RouteLeavingGuard);
