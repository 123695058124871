import colors from '../../utils/colors';

export default {
  subTitle: {
    color: colors.dark,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
    fontWeight: 'bold',
  },
  paper: {
    width: '100%',
    height: 'auto',
    borderRadius: 4,
    backgroundColor: colors.white,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
  },
  darkMoreVertIcon: {
    height: 28,
    width: 28,
    backgroundColor: colors.dark,
    color: colors.white,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: colors.dark,
      color: colors.white,
    },
  },
  boldNameParam: {
    color: colors.dark,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  paramValueGold: {
    color: colors.darkGold,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: colors.darkGold,
    },
  },
  paramValue: {
    color: colors.gray,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: colors.darkGold,
    },
  },
  paramValueLight: {
    color: colors.gray,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: colors.gray,
    },
  },
  singleStat: {
    flexGrow: '0',
    minHeight: 88,
    padding: '16px 16px 2px 16px',
    borderRight: `2px solid #F5F6F9`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  singleStatLabel: {
    fontSize: '0.75rem',
    marginBottom: 5,
    color: colors.lightGray,
  },
  statsValue: {
    fontSize: '1.75rem',
    fontWeight: 'normal',
    lineHeight: 1,
  },
  containedButton: {
    boxSizing: 'border-box',
    height: 42,
    border: `2px solid ${colors.darkGold}`,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    color: colors.darkGold,
    backgroundColor: 'transparent',
    textTransform: 'none',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
      border: `2px solid ${colors.darkGold}`,
      color: colors.darkGold,
    },
    '&:disabled': {
      border: `0px`,
    },
  },
  containedButtonDark: {
    border: `2px solid ${colors.dark}`,
    color: colors.dark,
    '&:hover': {
      border: `2px solid ${colors.dark}`,
      color: colors.dark,
    },
  },
  outlinedButton: {
    textTransform: 'capitalize',
    height: 42,
    borderRadius: 4,
    backgroundColor: colors.dark,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.dark,
      color: colors.white,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    },
    '&:disabled': {
      color: '#999',
      cursor: 'not-allowed',
    },
  },
  banner: {
    marginBottom: 15,
  },
  bannerGold: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#F1E0D1',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    color: colors.dark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 0 20px 0',
    paddingBottom: 15,
    borderBottom: '0.5px solid rgb(220, 220, 220)',
  },
  title: {
    color: colors.title,
    fontSize: 24,
    fontWeight: 'normal',
    opacity: 1,
    margin: '6px 0',
  },
  link: {
    color: colors.darkGold,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: colors.darkGold,
    },
  },
  expansionPanelExpandColapseIcon: {
    color: colors.white,
    fontSize: '2.3rem',
  },
};
