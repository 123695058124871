import React from 'react';
import {
  Router as ReactRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import PrivateRoute from '../PrivateRoute';
import LogIn from '../logIn/LogIn';
import ResetPassword from '../resetPassword/ResetPassword';
import SetPassword from '../setPassword/SetPassword';
import Dashboard from '../dashboard/Dashboard';
import Devices from '../devices/Devices';
import DeviceDetails from '../devices/DeviceDetails';
import Containers from '../containers/Containers';
import Packages from '../packages/Packages';
import Manifests from '../manifests/Manifests';
import ManifestsDetails from '../manifests/ManifestsDetails';
import DockDoors from '../dockDoors/DockDoors';
import DockDoorsDetails from '../dockDoors/DockDoorsDetails';
import Stops from '../stops/Stops';
import SchemeDetails from '../schemes/SchemeDetails';
import Loadplans from '../loadplans/Loadplans';
import LoadplansList from '../loadplans/LoadplansList';
import Routes from '../routes/Routes';
import RoutesDetails from '../routes/RoutesDetails';
import StationStaff from '../stationStaff/StationStaff';
import StationStaffDetails from '../stationStaff/StationStaffDetails';
import StationStaffPermissions from '../stationStaff/StationStaffPermissions';
import Configuration from '../configuration/Configuration';
import Areas from '../areas/Areas';
import AreasDetails from '../areas/AreaDetails';
import AreaSettings from '../areas/settings/AreaSettings';
import AddCorporateModule from '../areas/settings/AddCorporateModule';
import AddStationModule from '../areas/settings/AddStationModule';
import ContainerDetails from '../containers/ContainerDetails';
import PageNotFound from '../pageNotFound/PageNotFound';
import TrackingPage from '../trackingPage/TrackingPage';
import LoadingGlobalData from '../loadingGlobalData/LoadingGlobalData';
import RedirectToDashboard from '../pageNotFound/RedirectToDashboard';
import RefreshComponent from '../refreshComponent/RefreshComponent';
import browserHistory from '../../utils/browserHistory';
import {
  AuthRoutes,
  NonAuthRoutes,
  ModulesKeys,
  isModuleActive,
  resetDynamicRoutes,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import PackageDetails from '../packages/PackageDetails';
import OutboundLoads from '../outboundLoads/OutboundLoads';
import OutboundLoadDetails from '../outboundLoads/OutboundLoadDetails';
import StationLayout from '../stationLayout/StationLayout';
import Profile from '../profile/Profile';
import ChangeEmail from '../changeEmail/ChangeEmail';
import UsersGodView from '../usersGodView/UsersGodView';

// Services
import Auth from '../../services/Auth.service';

//Flow -- test
import Flow from '../stationLayout/throughput/Flow';

//Station Planner
import StationPlanner from '../stationPlanner/StationPlanner';
import ShiftPresets from '../stationPlanner/shiftPresets/ShiftPresets';
import ShiftPresetDetails from '../stationPlanner/shiftPresets/ShiftPresetDetails';
import ShiftCalendar from '../stationPlanner/ShiftCalendar';
import Schemes from '../schemes/Schemes';

import InboundLoads from '../inboundLoads/InboundLoads';
import InboundLoadDetails from '../inboundLoads/InboundLoadDetails';
import Webhooks from '../webhooks/Webhooks';
import WebhookDetails from '../webhooks/WebhookDetails';

export const RouterManualUpdateContext = React.createContext(
  () => {},
);
export const RouterManualUpdateProvider =
  RouterManualUpdateContext.Provider;
interface State {
  globalStationData: any;
}
class Router extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = { globalStationData: undefined };
  }

  public manualForceUpdate = () => {
    this.forceUpdate();
  };

  private setStationData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const stationId = urlParams.get(stationDashboardUrlId);
      const globalData =
        await globalStationOptionsUtils.setStationData(
          stationId ? parseInt(stationId) : undefined,
        );

      resetDynamicRoutes();
      this.setState({ globalStationData: globalData });
    } catch (e) {
      console.error(e);
    }
  };

  private getPrivateRoutes = () => {
    return [
      {
        key: ModulesKeys.DASHBOARD,
        path: AuthRoutes.DASHBOARD,
        component: Dashboard,
      },
      {
        key: null,
        path: AuthRoutes.FLOW,
        component: Flow,
      },
      {
        key: ModulesKeys.DEVICE,
        path: AuthRoutes.DEVICE,
        component: Devices,
      },
      {
        key: ModulesKeys.DEVICE,
        path: `${AuthRoutes.DEVICE}/:id`,
        component: DeviceDetails,
      },
      {
        key: ModulesKeys.CONTAINER,
        path: AuthRoutes.CONTAINER,
        component: Containers,
      },
      {
        key: ModulesKeys.CONTAINER,
        path: `${AuthRoutes.CONTAINER}/:id`,
        component: ContainerDetails,
      },
      {
        key: ModulesKeys.PACKAGE,
        path: AuthRoutes.PACKAGE,
        component: Packages,
      },
      {
        key: ModulesKeys.PACKAGE,
        path: `${AuthRoutes.PACKAGE}/:id`,
        component: PackageDetails,
      },
      {
        key: ModulesKeys.INBOUND_LOAD,
        path: AuthRoutes.INBOUND_LOAD,
        component: InboundLoads,
      },
      {
        key: ModulesKeys.INBOUND_LOAD,
        path: `${AuthRoutes.INBOUND_LOAD}/:id`,
        component: InboundLoadDetails,
      },
      {
        key: ModulesKeys.OUTBOUND_LOAD,
        path: AuthRoutes.OUTBOUND_LOAD,
        component: OutboundLoads,
      },
      {
        key: ModulesKeys.OUTBOUND_LOAD,
        path: `${AuthRoutes.OUTBOUND_LOAD}/:id`,
        component: OutboundLoadDetails,
      },
      {
        key: ModulesKeys.ROUTE,
        path: AuthRoutes.ROUTE,
        component: Routes,
      },
      {
        key: ModulesKeys.ROUTE,
        path: `${AuthRoutes.ROUTE}/:id`,
        component: RoutesDetails,
      },
      {
        key: ModulesKeys.STAFF,
        path: AuthRoutes.STAFF,
        component: StationStaff,
      },
      {
        key: ModulesKeys.STAFF,
        path: `${AuthRoutes.STAFF}/:id`,
        component: StationStaffDetails,
      },
      {
        key: ModulesKeys.STAFF,
        path: `${AuthRoutes.STAFF}/:permissions/:id`,
        component: StationStaffPermissions,
      },
      {
        key: ModulesKeys.AREA,
        path: AuthRoutes.AREA,
        component: Areas,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id`,
        component: AreasDetails,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType`,
        component: AreaSettings,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType/add-corporate-module/:zoneId`,
        component: AddCorporateModule,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType/add-station-module/:zoneId`,
        component: AddStationModule,
      },
      {
        key: null,
        path: AuthRoutes.STATION_LAYOUT,
        component: StationLayout,
      },
      {
        key: null,
        path: AuthRoutes.STATION_PLANNER,
        component: StationPlanner,
      },
      {
        key: null,
        path: AuthRoutes.SHIFT_PRESETS,
        component: ShiftPresets,
      },
      {
        key: null,
        path: `${AuthRoutes.SHIFT_PRESETS}/:id`,
        component: ShiftPresetDetails,
      },
      {
        key: null,
        path: AuthRoutes.SHIFT_CALENDAR,
        component: ShiftCalendar,
      },
      {
        key: ModulesKeys.MANIFEST,
        path: AuthRoutes.MANIFEST,
        component: Manifests,
      },
      {
        key: ModulesKeys.MANIFEST,
        path: `${AuthRoutes.MANIFEST}/:id`,
        component: ManifestsDetails,
      },
      {
        key: ModulesKeys.DOCK_DOOR,
        path: AuthRoutes.DOCK_DOOR,
        component: DockDoors,
      },
      {
        key: ModulesKeys.DOCK_DOOR,
        path: `${AuthRoutes.DOCK_DOOR}/:id`,
        component: DockDoorsDetails,
      },
      {
        key: ModulesKeys.STOP,
        path: AuthRoutes.STOP,
        component: Stops,
      },
      {
        key: null,
        path: AuthRoutes.SCHEME,
        component: Schemes,
      },
      {
        key: null,
        path: `${AuthRoutes.SCHEME}/:id`,
        component: SchemeDetails,
      },
      {
        key: ModulesKeys.STOP,
        path: `${AuthRoutes.STOP}/default-open/:defaultOpenIds`,
        component: Stops,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: AuthRoutes.LOADPLAN,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}/:id`,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}/:id/:imported`,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}_list`,
        component: LoadplansList,
      },
      {
        key: null,
        path: AuthRoutes.CONFIGURATION,
        component: Configuration,
      },
      {
        key: null,
        path: `${AuthRoutes.CONFIGURATION}/organization/:orgId`,
        component: Configuration,
      },
      {
        key: null,
        path: `${AuthRoutes.CONFIGURATION}/organization/:orgId/:created`,
        component: Configuration,
      },
      {
        key: null,
        path: `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId`,
        component: Configuration,
      },
      {
        key: null,
        path: `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/:created`,
        component: Configuration,
      },
      {
        key: null,
        path: AuthRoutes.USERS_GOD_VIEW,
        component: UsersGodView,
      },
      {
        key: null,
        path: `${AuthRoutes.USERS_GOD_VIEW}/organization/:orgId`,
        component: UsersGodView,
      },
      {
        key: null,
        path: `${AuthRoutes.USERS_GOD_VIEW}/organization/:orgId/:userId`,
        component: Profile,
      },
      {
        key: null,
        path: AuthRoutes.PROFILE,
        component: Profile,
      },
      {
        key: null,
        path: `${AuthRoutes.USERS_GOD_VIEW}/organization/:orgId/station/:stationId`,
        component: UsersGodView,
      },
      {
        key: null,
        path: `${AuthRoutes.USERS_GOD_VIEW}/organization/:orgId/station/:stationId/:userId`,
        component: Profile,
      },
      {
        key: null,
        path: AuthRoutes.WEBHOOKS,
        component: Webhooks,
      },
      {
        key: null,
        path: `${AuthRoutes.WEBHOOKS}/:webhookId`,
        component: WebhookDetails,
      },
    ];
  };

  render() {
    const privateRoutes = this.getPrivateRoutes();

    const isNotNonAuthPath = Object.values(NonAuthRoutes)
      .map((route) => route.split('/')[1])
      .filter((route) => route)
      .every(
        (path) => !browserHistory.location.pathname.includes(path),
      );

    if (
      isNotNonAuthPath &&
      (Auth.isLoggedIn() || Auth.hasRefreshToken()) &&
      this.state.globalStationData === undefined
    ) {
      this.setStationData();
    }
    if (
      isNotNonAuthPath &&
      (Auth.isLoggedIn() || Auth.hasRefreshToken()) &&
      !this.state.globalStationData &&
      !privateRoutes[0].path
    ) {
      return this.state.globalStationData === null ? (
        <PageNotFound />
      ) : (
        <LoadingGlobalData />
      );
    }

    return (
      <RouterManualUpdateProvider value={this.manualForceUpdate}>
        <ReactRouter history={browserHistory}>
          <Switch>
            <Redirect
              exact
              path={NonAuthRoutes.HOME}
              to={{
                pathname: NonAuthRoutes.LOGIN,
                state: { from: NonAuthRoutes.HOME },
              }}
            />
            {privateRoutes
              .filter((route) => {
                if (route.key) {
                  return isModuleActive(route.key);
                }
                return true;
              })
              .map((route, index) => {
                return (
                  <PrivateRoute
                    exact
                    key={`${route.key}-${index}`}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            <Route
              exact
              path={NonAuthRoutes.LOGIN}
              component={LogIn}
            />
            <Route
              exact
              path={NonAuthRoutes.LOGIN_ALERT}
              component={LogIn}
            />
            <Route
              path={NonAuthRoutes.RESET_PASSWORD}
              component={ResetPassword}
            />
            <Route
              path={NonAuthRoutes.SET_PASSWORD}
              component={SetPassword}
            />
            <Route
              path={NonAuthRoutes.CHANGE_EMAIL}
              component={ChangeEmail}
            />
            <Route
              path={NonAuthRoutes.TRACKING}
              component={TrackingPage}
            />
            <Route
              path={NonAuthRoutes.REFRESH_ROUTE}
              component={RefreshComponent}
            />
            <Route path='*' component={RedirectToDashboard} />
          </Switch>
        </ReactRouter>
      </RouterManualUpdateProvider>
    );
  }
}

export default Router;
