import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles } from '@material-ui/core/styles';

import colors from '../../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import { AlertBanner } from '../../primitives';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { isEmpty } from 'lodash';

//Types
import { AxiosError } from 'axios';

// Icons
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

interface Props {
  classes: { [key: string]: string };
  data: any;
  onAfterClose: () => void;
  importConfig: (configToImport: any) => void;
  isOpen: boolean;
}

const UploadConfigDialog: React.FC<Props> = ({
  isOpen,
  classes,
  data,
  onAfterClose,
  importConfig,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [formClass, setFormClass] = useState<string>();
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>();

  const handleClose = () => {
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const hasEqualStructure = (obj1: any, obj2: any): boolean => {
    try {
      return Object.keys(obj1).every((key) => {
        const v = obj1[key];
        if (typeof v === 'object' && v !== null) {
          return hasEqualStructure(v, obj2[key]);
        }
        return obj2.hasOwnProperty(key);
      });
    } catch (e) {
      throw new Error();
    }
  };

  const uploadJSON = async () => {
    setShowProgress(true);
    setError(undefined);
    try {
      const fr = new FileReader();
      fr.addEventListener('load', (e: any) => {
        try {
          if (e.target.result) {
            const configToUpdate = JSON.parse(e.target.result);
            if (isEmpty(configToUpdate)) {
              throw new Error();
            } else if (
              hasEqualStructure(configToUpdate, data.config)
            ) {
              importConfig(JSON.parse(e.target.result));
            } else {
              throw new Error();
            }
          }
          handleClose();
          setShowProgress(false);
        } catch (e) {
          setSelectedFile(undefined);
          setShowProgress(false);
          e.message = 'File data is corrupted or malformed.';
          handleError(e);
        }
      });

      fr.readAsText(selectedFile);
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e);
    }
  };

  const onFileChangeHandler = (event: any) => {
    setSelectedFileLabel(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);
  };

  const onFileDroppedHandler = (event: any) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (!file.type.includes('json')) {
      setSelectedFileLabel('Please select JSON file.');
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
        setFormClass(undefined);
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      });
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {error && (
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            )}
            <CloudUploadOutlined
              style={{
                margin: '10px auto',
                height: 'auto',
                width: 40,
                color: colors.darkGold,
              }}
            />
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 18,
              }}
            >
              Import Configuration
            </span>
            {!isUploading && (
              <form
                className={
                  classes.form +
                  ' ' +
                  (formClass && classes[formClass])
                }
                onDragOver={(e) => {
                  e.preventDefault();
                  setFormClass('draggingActive');
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setFormClass(undefined);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  onFileDroppedHandler(e);
                }}
              >
                <input
                  accept='.json'
                  className={classes.input}
                  style={{ display: 'none' }}
                  id='button-file'
                  type='file'
                  onChange={onFileChangeHandler}
                />
                <p>{selectedFileLabel}</p>
                <label htmlFor='button-file'>
                  <Button
                    variant='outlined'
                    component='span'
                    className={classes.goldButton}
                  >
                    Browse File
                  </Button>
                </label>
              </form>
            )}
            {isUploading && (
              <p className={classes.grayTitle}>Uploading file</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose();
            }}
            className={classes.button}
          >
            CANCEL
          </Button>
          <Button
            disabled={!selectedFile}
            onClick={(e) => {
              uploadJSON();
            }}
            className={classes.button}
          >
            UPLOAD
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: {
      padding: '0 80px',
    },
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    goldButton: {
      backgroundColor: colors.darkGold,
      color: colors.white,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.gold,
      },
    },
    form: {
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: colors.darkGold,
      backgroundColor: colors.whiteGold,
      '& p': {
        color: colors.darkGold,
      },
      '& button': {
        backgroundColor: colors.darkGold,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.gold,
        },
      },
    },
    draggingActive: {
      borderColor: colors.lightGray,
    },
    dropRejected: {
      borderColor: 'red',
    },
    grayTitle: {
      color: colors.gray,
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: colors.gray,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: colors.lightGold,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: colors.darkGold,
      height: '5px',
    },
  })),
)(UploadConfigDialog);
