import React, { useEffect, useState } from 'react';
import { AlertBanner, Typography, Button } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import { common } from '../../utils/strings';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getLastUsedLabel, getBateryLabel } from './Devices';
import configurationUtils from '../../utils/configurationUtils';

// Icons
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

// Types
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  DeviceDetails,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';

// Services
import EventsService from '../../services/Events.service';
import DevicesService from '../../services/Devices.service';
import PermissionsService from '../../services/Permissions.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import colors from '../../utils/colors';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const DeviceDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<DeviceDetails>();
  const [error, setError] = useState<string>();

  const fetchDetailsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await DevicesService.getById(
        (match.params as any).id,
      );
      setDetailsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const deleteDevice = async () => {
    setShowProgress(true);
    try {
      await DevicesService.deleteDevice((match.params as any).id);
      browserHistory.push(AuthRoutes.DEVICE);
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
      setShowDeleteDialog(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      device: (match.params as any).id,
      pageSize: rowsPerPage,
    });
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission('DEVICE_READ');
    if (!detailsData) {
      fetchDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
        ${configurationUtils.getPageTitle(
          true,
          'DEVICE',
        )} Details for ${detailsData?.device_id || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='DEVICE'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid='station-staff-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix={'device-details-'}
          data-testid={'device-details-delete-dialog'}
          title={`Remove ${configurationUtils.getPageTitle(
            true,
            'DEVICE',
          )}`}
          msg={`Are you sure you want to remove ${detailsData?.device_id}?`}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteDevice}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title}>
              {`${configurationUtils.getPageTitle(
                true,
                'DEVICE',
              )} Details for ${detailsData?.device_id || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.DEVICEWRITE,
            ) && (
              <Button
                variant='contained'
                className={classes.containedButton}
                data-testid={'device-details-delete-button'}
                style={{ marginLeft: 15 }}
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  setShowDeleteDialog(true);
                }}
              >
                <IndeterminateCheckBoxOutlinedIcon
                  style={{ marginRight: '5px' }}
                />
                Remove{' '}
                {configurationUtils.getPageTitle(true, 'DEVICE')}
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          data-testid={'device-details'}
          spacing={2}
          style={{ marginBottom: 8 }}
        >
          <Grid item xs={12} sm={9}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid=''
            >
              <SingleDetail
                inline={true}
                label='Status'
                value={detailsData?.active ? 'Active' : 'Inactive'}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Station'
                value={detailsData?.station_name || common.emptyValue}
              />
              <SingleDetail
                inline={true}
                label={
                  configurationUtils.getPageTitle(true, 'DEVICE') +
                  ' ID'
                }
                value={detailsData?.device_id}
              />
              <br />
              <SingleDetail
                inline={true}
                label={'Serial ID'}
                value={
                  detailsData?.serial_id &&
                  detailsData.serial_id !== ''
                    ? detailsData.serial_id
                    : common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                label={'Icc ID'}
                value={
                  detailsData?.icc_id && detailsData.icc_id !== ''
                    ? detailsData.icc_id
                    : common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={configurationUtils.getPageTitle(true, 'STAFF')}
                value={
                  detailsData?.current_user_full_name ||
                  common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={'Battery'}
                value={getBateryLabel(detailsData)}
              />
              <br />
              <SingleDetail
                inline={true}
                label='Last Used'
                inlineWidth={'100%'}
                value={getLastUsedLabel({
                  device_id: '',
                  last_event: detailsData?.last_event,
                })}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              style={{
                textAlign: 'center',
                padding: '15px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                data-testid={'Software Version:label'}
                className={classes.paramValue}
                style={{ marginBottom: 0 }}
              >
                Software Version
              </Typography>

              <Typography
                style={{ fontSize: '1.75rem' }}
                data-testid={'Software Version:value'}
              >
                {detailsData?.version || common.emptyValue}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {!!detailsData && (
          <div style={{ marginTop: 24 }}>
            <EventsPaginatedTable
              dataTestIdPrefix={'devices-details-events'}
              fetch={fetchEvents}
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
  })),
)(DeviceDetailsComponent);
