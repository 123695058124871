import React from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import PackagesModule from './ProcessSteps';
import ModulesModule from './Modules';
import Sorting from './Sorting';
import GhostResources from './GhostResources';
import LoadOps from './LoadOps';
import ContainerOps from './ContainerOps';
import StatesModule from './StatesModule';
import Entities from './Entities';
import { INTERACTION } from '../../Configuration';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MultipleToggleBehaviors from './MultipleToggleBehaviors';
import { CurrentView } from '../Modules';
import ExceptionProcess from './ExceptionProcess';
import MachineLearningIntegration from './MachineLearningIntegration';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataWeb: any;
  setPartialData: (timers: any) => void;
  setPartialWebData: (webData: any) => void;
  interaction?: INTERACTION;
}

const GeneralSearch: React.FC<Props> = ({
  classes,
  data,
  dataWeb,
  interaction,
  setPartialData,
  setPartialWebData,
}) => {
  const currentView = CurrentView.SEARCH;
  return (
    <Box mt={1}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          classes={{
            expanded: classes.panelSummaryExpanded,
            root: classes.panelSummary,
          }}
          expandIcon={
            <ExpandMoreIcon
              className={classes.expansionPanelExpandColapseIcon}
            />
          }
        >
          <Typography className={classes.summaryPanelTitleText}>
            General
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.panelDetails,
          }}
        >
          <Box>
            <ModulesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.MODULES}
              dataWeb={dataWeb}
              setPartialData={(modes) => {
                const dataObj = { ...data };
                dataObj.MODULES = modes;
                setPartialData(dataObj);
              }}
              setWebData={(data) => {
                const dataObj = { ...dataWeb, ...data };
                setPartialWebData(dataObj);
              }}
            />
          </Box>
          <Box>
            <Entities
              currentView={currentView}
              interaction={interaction}
              data={data?.ENTITIES}
              setPartialData={(entities) => {
                const dataObj = { ...data };
                dataObj.ENTITIES = entities;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <PackagesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.PROCESS_STEPS}
              setPartialData={(modes) => {
                const dataObj = { ...data };
                dataObj.PROCESS_STEPS = modes;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <StatesModule
              currentView={currentView}
              interaction={interaction}
              data={data?.STATES}
              setPartialData={(states) => {
                const dataObj = { ...data };
                dataObj.STATES = states;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <ExceptionProcess
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <MultipleToggleBehaviors
              title='Outbound Manifesting'
              interaction={interaction}
              data={[data?.BEHAVIORS?.OUTBOUND_MANIFESTING]}
              dataKeys={['OUTBOUND MANIFESTING']}
              setPartialData={() => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.OUTBOUND_MANIFESTING = {
                  ...dataObj.BEHAVIORS.OUTBOUND_MANIFESTING,
                };
                setPartialData(dataObj);
              }}
              currentView={currentView}
            />
          </Box>
          <Box>
            <MultipleToggleBehaviors
              title='Dispatchable Verification'
              interaction={interaction}
              data={[data?.BEHAVIORS?.DISPATCHABLE_VERIFICATION]}
              dataKeys={['DISPATCHABLE_VERIFICATION']}
              setPartialData={() => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.DISPATCHABLE_VERIFICATION = {
                  ...dataObj.BEHAVIORS.DISPATCHABLE_VERIFICATION,
                };
                setPartialData(dataObj);
              }}
              currentView={currentView}
            />
          </Box>
          <Box>
            <LoadOps
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.LOAD_OPS}
              dataKey={'LOAD_OPS'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.LOAD_OPS = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <ContainerOps
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.CONTAINER_OPS}
              dataKey={'CONTAINER_OPS'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.CONTAINER_OPS = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <MachineLearningIntegration
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.ML_INTEGRATION}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.ML_INTEGRATION = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <Sorting
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.SORTING}
              dataKey={'SORTING'}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.SORTING = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
          <Box>
            <GhostResources
              currentView={currentView}
              interaction={interaction}
              data={data?.BEHAVIORS?.GHOST_RESOURCES_DAYS}
              setPartialData={(behaviorData) => {
                const dataObj = { ...data };
                dataObj.BEHAVIORS.GHOST_RESOURCES_DAYS = behaviorData;
                setPartialData(dataObj);
              }}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(GeneralSearch);
