import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Typography } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Checkbox,
  Paper,
  useTheme,
  useMediaQuery,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import enumToLabel from '../../../utils/enumToLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { INTERACTION } from '../Configuration';
import FloatRolesTableHeader from '../modules/FloatRolesTableHeader';
import CorportateService from '../../../services/Corportate.service';

// services
import EphemeralStateService from '../../../services/EphemeralState.service';

// Types
import {
  CorporateRoles,
  CorporateRolesDependencies,
} from 'cloudsort-client';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';

interface Props {
  classes: { [key: string]: string };
  setRoles: (roles: CorporateRoles) => void;
  data?: CorporateRoles;
  interaction?: INTERACTION;
  showFloatTableHeader: boolean;
}

const Roles: React.FC<Props> = ({
  classes,
  data,
  interaction,
  showFloatTableHeader,
  setRoles,
}) => {
  const modulesData =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES;

  const [isViewReady, setIsViewReady] = useState<boolean>(false);
  const [permissionDependencies, setPermissionDepdendencies] =
    useState<CorporateRolesDependencies[]>([]);
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const theme = useTheme();
  const isPhoneView = useMediaQuery(theme.breakpoints.down('sm'));

  const keyAndTitleMap = [
    { title: 'Master', key: 'MASTER_' },
    { title: 'Organization', key: 'ORGANIZATION_' },

    { title: 'Station', key: 'STATION_' },
    { title: 'Operator Tool', key: 'OPERATORTOOL_' },
    { title: 'Load Plan', key: 'LOADPLAN_' },
    { title: modulesData?.PACKAGE?.label_plural, key: 'PACKAGE_' },
    {
      title: modulesData?.OUTBOUND_LOAD?.label_plural,
      key: 'OUTBOUNDLOAD_',
    },
    {
      title: modulesData?.INBOUND_LOAD?.label_plural,
      key: 'INBOUNDLOAD_',
    },
    { title: modulesData?.STAFF?.label_plural, key: 'USER_' },
    { title: modulesData?.DEVICE?.label_plural, key: 'DEVICE_' },
    {
      title: modulesData?.CONTAINER?.label_plural,
      key: 'CONTAINER_',
    },
    {
      title: modulesData?.MANIFEST?.label_plural,
      key: 'MANIFEST_',
    },
    {
      title: modulesData?.ROUTE?.label_plural,
      key: 'ROUTE_',
    },
    {
      title: 'Technical Assets',
      key: 'TECHNICAL_ASSETS_',
    },
    {
      title: 'Webhooks',
      key: 'WEBHOOK_',
    },
  ];

  const getAndSetPermissionDependencies = async () => {
    try {
      const { data } =
        await CorportateService.getPermissionDependencies();
      setPermissionDepdendencies(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAndSetPermissionDependencies();
  }, []);

  const dependencyStatus = useMemo(() => {
    if (permissionDependencies.length === 0) {
      return {};
    }
    let dependencyStatusData: {
      [key: string]: {
        [key: string]: {
          enabled: boolean;
          tooltipMessage: JSX.Element;
        };
      };
    } = {};

    data?.permissions?.forEach((permission: string) => {
      const dependencies = permissionDependencies.find(
        (dependencyData) => dependencyData.permission === permission,
      );
      dependencyStatusData[permission] = {};
      data.role_types?.forEach((role: string, rIndex: number) => {
        if (dependencies!.parents.length === 0) {
          //Nothing to check here, this permission doesn't have dependencies.
          dependencyStatusData[permission][role] = {
            enabled: true,
            tooltipMessage: <></>,
          };
        }
        if (dependencies!.parents.length > 0) {
          let hasEnabledParent = false;
          const dependsOn = [];
          for (let parentDependency of dependencies!.parents) {
            const pIndex = data?.permissions?.findIndex(
              (currentPermission) =>
                currentPermission === parentDependency,
            );
            if (data!.matrix![rIndex][pIndex!]) {
              hasEnabledParent = true;
            } else {
              //Key and title for parent permission.
              const keyAndTitle = keyAndTitleMap.find((configItem) =>
                parentDependency
                  .toLowerCase()
                  .startsWith(configItem.key.toLowerCase()),
              );

              dependsOn.push(
                `
                  ${keyAndTitle?.title} ${enumToLabel(
                  parentDependency.replace(keyAndTitle!.key, ''),
                )}`,
              );
            }
          }
          //Key and title for current permission
          const keyAndTitle = keyAndTitleMap.find((configItem) =>
            permission
              .toLowerCase()
              .startsWith(configItem.key.toLowerCase()),
          );

          const tooltipMsg =
            dependsOn.length > 1 ? (
              <span>
                To activate {keyAndTitle?.title}&nbsp;
                {enumToLabel(
                  permission.replace(keyAndTitle!.key, ''),
                )}
                , at least one of the following needs to be active:
                <ul
                  style={{
                    paddingLeft: '15px',
                    marginBottom: '0px',
                    marginTop: '3px',
                  }}
                >
                  {dependsOn.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </span>
            ) : (
              <span>
                You need to activate {dependsOn} first before
                activating {keyAndTitle?.title}&nbsp;
                {enumToLabel(
                  permission.replace(keyAndTitle!.key, ''),
                )}
                .
              </span>
            );
          dependencyStatusData[permission][role] = {
            enabled: hasEnabledParent,
            tooltipMessage: hasEnabledParent ? <></> : tooltipMsg,
          };
        }
      });
    });
    return dependencyStatusData;
  }, [data, permissionDependencies, keyAndTitleMap]);

  useEffect(() => {
    if (
      permissionDependencies.length > 0 &&
      Object.keys(dependencyStatus).length > 0
    ) {
      setIsViewReady(true);
    }
  }, [dependencyStatus, permissionDependencies]);

  const shouldPermissionStayEnabled = (
    rIndex: number,
    permission: string,
  ) => {
    let shouldStayEnabled = false;
    const dependencies = permissionDependencies.find(
      (dependencyData) => dependencyData.permission === permission,
    );
    if (dependencies!.parents.length === 0) {
      //No parents, the permission is enabled by default.
      return true;
    }
    //Go through list of parents, if atleast 1 parent is enabled, this permission should stay enabled too.
    for (let parentPermission of dependencies!.parents) {
      const parentPermissionIndex = data?.permissions?.findIndex(
        (currentPermission) => currentPermission === parentPermission,
      );
      if (data!.matrix![rIndex][parentPermissionIndex!]) {
        shouldStayEnabled = true;
      }
    }
    return shouldStayEnabled;
  };

  const renderEp = (title: string, key: string) => {
    const grid: JSX.Element[] = [];
    data?.permissions?.forEach(
      (permission: string, pIndex: number) => {
        if (!permission.includes(key)) {
          return;
        }

        grid.push(
          <TableRow key={pIndex}>
            {data.role_types?.map((role: string, rIndex: number) => {
              if (role === 'BLANK') {
                return;
              }
              const isEnabled =
                dependencyStatus[permission][role].enabled;
              return (
                <Fragment key={`${role}-${rIndex}`}>
                  {!rIndex && (
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: '20%' }}
                    >
                      <Box className={classes.permissionHolder}>
                        <Typography
                          className={classes.permissionHeader}
                        >
                          {enumToLabel(permission.replace(key, ''))}
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                  <Tooltip
                    classes={{
                      tooltipArrow: classes.tooltip,
                      arrow: classes.tooltipArrow,
                    }}
                    title={
                      isEnabled
                        ? ''
                        : dependencyStatus[permission][role]
                            .tooltipMessage
                    }
                    arrow
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{
                        backgroundColor:
                          rIndex % 2
                            ? 'trasparent'
                            : 'rgb(245 245 245)',
                      }}
                    >
                      <Checkbox
                        data-testid={`checkbox-${title}-${role}-${enumToLabel(
                          permission.replace(key, ''),
                        )}`}
                        disableRipple
                        disabled={
                          interaction === INTERACTION.READ ||
                          !isEnabled
                        }
                        className={classes.checkbox}
                        onChange={() => {
                          //Flip checkbox
                          data!.matrix![rIndex][pIndex] =
                            !data!.matrix![rIndex][pIndex];
                          //Check if we're disabling permission
                          if (!data!.matrix![rIndex][pIndex]) {
                            //Find all dependants
                            const disabledPermissions: string[] = [];
                            const dependencies =
                              permissionDependencies.find(
                                (dependencyData) =>
                                  dependencyData.permission ===
                                  permission,
                              );
                            if (dependencies!.children.length > 0) {
                              //Go through each dependant, decide if dependant should be disabled.
                              for (let dependant of dependencies!
                                .children) {
                                const dependantIndex =
                                  data?.permissions?.findIndex(
                                    (currentPermission) =>
                                      currentPermission === dependant,
                                  );
                                if (
                                  data!.matrix![rIndex][
                                    dependantIndex!
                                  ] &&
                                  !shouldPermissionStayEnabled(
                                    rIndex,
                                    dependant,
                                  )
                                ) {
                                  //Dependant should be disabled
                                  const keyAndTitle =
                                    keyAndTitleMap.find(
                                      (configItem) =>
                                        dependant
                                          .toLowerCase()
                                          .startsWith(
                                            configItem.key.toLowerCase(),
                                          ),
                                    );
                                  data!.matrix![rIndex][
                                    dependantIndex!
                                  ] = false;

                                  const disabledDependencyName = `${
                                    keyAndTitle?.title
                                  } ${enumToLabel(
                                    dependant.replace(
                                      keyAndTitle!.key,
                                      '',
                                    ),
                                  )}`;
                                  //Check if permission has disabled in one of the previous checks
                                  if (
                                    !disabledPermissions.includes(
                                      disabledDependencyName,
                                    )
                                  ) {
                                    disabledPermissions.push(
                                      disabledDependencyName,
                                    );
                                  }
                                }
                              }
                            }
                            //All dependencies are disabled, show notification to the user.
                            if (disabledPermissions.length > 0) {
                              setShowDependencyNotification(true);
                              setDependencyNotificationMessage(
                                `Your selection also disabled the dependant permission${
                                  disabledPermissions.length === 1
                                    ? ''
                                    : 's'
                                }: ${disabledPermissions.join(
                                  ', ',
                                )}.`,
                              );
                            }
                          }
                          setRoles({
                            ...data,
                          });
                        }}
                        checked={data.matrix![rIndex][pIndex]}
                      />
                    </TableCell>
                  </Tooltip>
                </Fragment>
              );
            })}
          </TableRow>,
        );
      },
    );

    return (
      <ExpansionPanel
        defaultExpanded
        className={classes.expansionPanel}
        style={{ marginTop: 0, marginBottom: 0 }}
        data-testid={`roles-permissions-matrix-category-${title}`}
      >
        <ExpansionPanelSummary
          className={classes.expansionPanelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: colors.dark,
            }}
          >
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ padding: 0 }}
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Grid
            container
            item
            xs={12}
            style={
              isPhoneView ? { paddingLeft: 6 } : { paddingLeft: 24 }
            }
          >
            <Table>
              <TableBody>
                {grid.map((gridEl: JSX.Element) => gridEl)}
              </TableBody>
            </Table>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  const renderHeader = () => {
    return (
      <Paper
        square
        className={classes.paper}
        style={{ boxShadow: 'none' }}
      >
        <Grid
          container
          item
          xs={12}
          style={
            isPhoneView ? { paddingLeft: 6 } : { paddingLeft: 24 }
          }
        >
          <Table style={{ maxWidth: '1566px', margin: '0 auto' }}>
            <TableBody>
              <TableRow>
                {data?.role_types?.map(
                  (role: string, index: number) => {
                    if (role === 'BLANK') {
                      return;
                    }
                    return (
                      <Fragment key={role}>
                        {!index && (
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              width: '20%',
                              padding: '20px 0',
                            }}
                          />
                        )}
                        <TableCell
                          align='center'
                          className={classes.tableCell}
                          style={{
                            width: data
                              ? `${80 / data!.role_types!.length}%`
                              : 'auto',
                            padding: '20px 0',
                          }}
                        >
                          <Typography className={classes.roleHeader}>
                            {enumToLabel(role)}
                          </Typography>
                        </TableCell>
                      </Fragment>
                    );
                  },
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    );
  };

  return isViewReady ? (
    <Fragment>
      <Snackbar
        data-testid='snackbar-notification'
        key={dependencyNotificationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showDependencyNotification}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setShowDependencyNotification(false);
        }}
        autoHideDuration={4000}
        message={dependencyNotificationMessage}
      />
      {isViewReady && showFloatTableHeader && (
        <FloatRolesTableHeader
          offset={'70px 0 0 -23px'}
          renderContent={renderHeader}
        />
      )}
      <Grid direction='row' xs={12} container item>
        <Grid item sm={6} xs={12}>
          <Typography
            className={classes.title}
            style={{ marginBottom: 16 }}
          >
            Roles
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} />
        <Grid item xs={12} data-testid={'roles-permissions-matrix'}>
          <Box>
            {renderHeader()}
            {keyAndTitleMap.map((configItem) => {
              return (
                <React.Fragment key={configItem.key}>
                  {renderEp(configItem.title, configItem.key)}
                </React.Fragment>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <ProgressIndicator />
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    roleHeader: {
      opacity: 0.53,
      color: '#363437',
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      letterSpacing: '-0.29px',
    },
    permissionHeader: {
      color: colors.dark,
      fontSize: 14,
      letterSpacing: '-0.29px',
    },
    checkbox: {
      display: 'flex',
      color: `${colors.gray}`,
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        color: colors.darkGold,
      },
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    permissionHolder: {},
    expansionPanelDetails: {
      padding: '0 0 0 24px',
      '& $permissionHolder:last-child': {
        padding: '12px 0',
      },
    },
    expansionPanelSummary: {
      backgroundColor: '#EFEFEF',
      margin: 0,
      minHeight: '20px !important',
      '& div': {
        margin: '8px 0 !important',
      },
      '& .MuiExpansionPanelSummary-expandIcon': {
        padding: 0,
      },
    },
    tableCell: {
      borderBottom: 'none',
      padding: 0,
      wordBreak: 'break-all',
    },
    tooltip: {
      background: colors.dark,
    },
    tooltipArrow: {
      color: colors.dark,
    },
  })),
)(Roles);
