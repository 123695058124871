import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, useTheme } from '@material-ui/core/styles';

import colors from '../../utils/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateUtils from '../../services/utils/date';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';

// Services
import PackagesService from '../../services/Packages.service';
import { SelectedFilters } from '../filtersDrawer/FiltersDrawer';
import { NestedProcessStepSerializerPackageProcessStepEnum } from 'cloudsort-client';
import ManifestsService from '../../services/Manifests.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  areaId?: number;
  downloadName?: string;
  type: 'MANIFESTS' | 'PACKAGES' | 'OUTBOUND_LOADS';
  packageFilters?: SelectedFilters;
  exportFilters?: {
    manifestType?: 'inbound' | 'outbound';
  };
}

const ExportCSVDialog: React.FC<Props> = ({
  isOpen,
  classes,
  type,
  packageFilters,
  onAfterClose,
  exportFilters = {},
  downloadName,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date>(
    new Date(dateUtils.fromDate()),
  );
  const [toDate, setToDate] = useState<Date>(
    new Date(dateUtils.toDate()),
  );

  const handleClose = () => {
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      let res: any;
      if (type === 'MANIFESTS') {
        res = await ManifestsService.getCSVExport({
          ...exportFilters,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        });
      } else if (type === 'OUTBOUND_LOADS') {
        res = await OutboundLoadsService.getCSVExport({
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        });
      } else if (type === 'PACKAGES' && packageFilters) {
        const isManifested =
          packageFilters.manifested &&
          packageFilters.manifested.values.length
            ? packageFilters.manifested.values[0].id === 'yes'
              ? true
              : packageFilters.manifested.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const isScanned =
          packageFilters.scanned &&
          packageFilters.scanned.values.length
            ? packageFilters.scanned.values[0].id === 'yes'
              ? true
              : packageFilters.scanned.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const isArrived =
          packageFilters.arrived &&
          packageFilters.arrived.values.length
            ? packageFilters.arrived.values[0].id === 'yes'
              ? true
              : packageFilters.arrived.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const inSystem =
          packageFilters.in_system &&
          packageFilters.in_system.values.length
            ? packageFilters.in_system.values[0].id === 'yes'
              ? true
              : packageFilters.in_system.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const hidePending =
          packageFilters.hidePending &&
          packageFilters.hidePending.values.length
            ? packageFilters.hidePending.values[0].id === 'yes'
              ? true
              : packageFilters.hidePending.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const firstScannedInWindow =
          packageFilters.packageScannedToday &&
          packageFilters.packageScannedToday.values.length
            ? packageFilters.packageScannedToday.values[0].id ===
              'yes'
              ? true
              : packageFilters.packageScannedToday.values[0].id ===
                'no'
              ? false
              : undefined
            : undefined;

        res = await PackagesService.getCSVExport({
          //all package filters
          owner:
            packageFilters.owner && packageFilters.owner.values.length
              ? packageFilters.owner.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          carrier:
            packageFilters.carrier &&
            packageFilters.carrier.values.length
              ? packageFilters.carrier.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          route:
            packageFilters.route && packageFilters.route.values.length
              ? packageFilters.route.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          destination:
            packageFilters.stop && packageFilters.stop.values.length
              ? packageFilters.stop.values.map((item: any) => item.id)
              : undefined,
          area:
            packageFilters.area && packageFilters.area.values.length
              ? (packageFilters.area.values[0].id as number)
              : undefined,
          processStep:
            packageFilters.processStep &&
            packageFilters.processStep.values.length
              ? (packageFilters.processStep.values.map(
                  (item: any) => item.id,
                ) as [
                  NestedProcessStepSerializerPackageProcessStepEnum,
                ])
              : undefined,
          isManifested,
          isArrived,
          inSystem,
          hidePending,
          isScanned,
          firstScannedInWindow,
          defects:
            packageFilters.packageDefect &&
            packageFilters.packageDefect.values.length
              ? packageFilters.packageDefect.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        });
      }

      const blob = new Blob([res!.data], {
        type: 'text/csv;charset=utf-8',
      });
      const fileNamStr = downloadName
        ? `${downloadName}.csv`
        : res.request
            .getResponseHeader('content-disposition')
            .split('filename=')[1]
            .replace(/"/g, '');
      saveAs(blob, fileNamStr);

      handleClose();
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minWidth: inXsScreen ? 200 : 450,
            }}
          >
            {error && (
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            )}
            <CloudDownloadOutlined
              style={{
                margin: '10px auto',
                height: 'auto',
                width: 40,
                color: colors.darkGold,
              }}
            />

            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: 18,
              }}
            >
              Batch CSV Export
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 25px',
              }}
            >
              <div
                className={classes.formGroup}
                style={{ display: 'grid' }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant='dialog'
                    format='MM/dd/yyyy'
                    margin='normal'
                    label='Start date'
                    value={fromDate}
                    maxDate={toDate}
                    onChange={(e) => {
                      setFromDate(new Date(e as unknown as string));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant='dialog'
                    format='MM/dd/yyyy'
                    margin='normal'
                    label='End date'
                    value={toDate}
                    minDate={fromDate}
                    onChange={(e) => {
                      setToDate(new Date(e as unknown as string));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            CANCEL
          </Button>
          <Button onClick={onGetQrCick} className={classes.button}>
            EXPORT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    button: {
      color: colors.darkGold,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    datePicker: {
      margin: '10px',
    },
  })),
)(ExportCSVDialog);
