import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loginHeading: {
    fontSize: '2.5rem',
    marginBottom: '2rem',
    textAlign: 'left',
    color: colors.darkGold,
  },
  loginSubtitle: {
    fontSize: '1.15rem',
    marginBottom: '1rem',
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    width: 'auto',
    height: 'auto',
    padding: '10px 50px',
    fontSize: '1.4rem',
    backgroundColor: colors.darkGold,
    border: '2px solid',
    borderColor: colors.darkGold,
    borderRadius: 0,
    color: colors.white,
    '&:hover': {
      border: '2px solid',
      borderColor: colors.darkGold,
      backgroundColor: colors.gold,
      color: colors.white,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: '2px solid',
      backgroundColor: 'transparent',
      color: colors.lightGray,
      borderColor: colors.lightGray,
    },
  },
  halfWidth: {
    background: colors.white,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '50%',
    float: 'left',
    height: '100vh',
  },
  loginBackground: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    position: 'relative',
    backgroundColor: '#000',
    backgroundImage: `url(${`${
      process.env.REACT_APP_BASENAME || ''
    }/loginAssets/bg.jpg`})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
  },
  loginLogo: {
    position: 'absolute',
    top: '50px',
    left: '50px',
    maxWidth: '360px',
    width: '100%',
  },
  strongPassword: {
    backgroundColor: '#abeada',
    color: '#000',
    border: '2px solid #07bc8c',
    fontSize: '1.4rem',
    padding: '10px',
    textAlign: 'center',
    width: '100%',
    marginTop: '-20px',
    borderBottomRightRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  alertBox: {
    margin: '20px 0',
    backgroundColor: '#eee1d3',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    '& p': {
      margin: '5px',
    },
  },
  pressEnter: {
    color: colors.gray,
    '& svg': {
      color: colors.darkGold,
      width: '30px',
      height: '30px',
      marginBottom: '-10px',
    },
  },
  forgotPasswordLink: {
    textDecoration: 'none',
    color: colors.darkGold,
  },
}));
