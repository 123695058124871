import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { createStyles, withStyles } from '@material-ui/core/styles';

export const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  },
);

interface Props {
  classes: { [key: string]: string };
  title?: string;
  msg?: string | React.ReactNode;
  primaryActionLabel?: string;
  onPrimaryAction?: () => void;
  secondaryActionLabel?: string;
  onSecondaryAction?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
  isOpen: boolean;
  dataTestIdPrefix?: string;
}

const ConfirmationDialog: React.FC<Props> = ({
  classes,
  title,
  msg,
  primaryActionLabel = 'OK',
  cancelLabel = 'Cancel',
  isOpen,
  onCancel,
  onPrimaryAction,
  secondaryActionLabel,
  onSecondaryAction,
  dataTestIdPrefix = '',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const handleCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handlePrimaryAction = () => {
    setOpen(false);
    if (onPrimaryAction) {
      onPrimaryAction();
    }
  };

  const handleSecondaryAction = () => {
    setOpen(false);
    if (onSecondaryAction) {
      onSecondaryAction();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      data-testid={`${dataTestIdPrefix}confirmation-dialog`}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCancel();
        setOpen(false);
      }}
      {...props}
      style={{ zIndex: 2000 }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.msg}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          data-testid={`${dataTestIdPrefix}confirmation-dialog-cancel`}
        >
          {cancelLabel}
        </Button>
        {secondaryActionLabel && (
          <Button
            onClick={handleSecondaryAction}
            data-testid={`${dataTestIdPrefix}confirmation-dialog-secondary-action`}
          >
            {secondaryActionLabel}
          </Button>
        )}
        <Button
          onClick={handlePrimaryAction}
          data-testid={`${dataTestIdPrefix}confirmation-dialog-ok`}
        >
          {primaryActionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    msg: {
      whiteSpace: 'pre-line',
    },
  })),
)(ConfirmationDialog);
