import MuiTypography, {
  TypographyProps,
} from '@material-ui/core/Typography';
import * as React from 'react';
import styles from './Typography.styles';
import { withStyles } from '@material-ui/core';

export interface ITypographyProps extends TypographyProps {}

const Typography: React.FC<ITypographyProps> = props => {
  return <MuiTypography {...props} />;
};

export default withStyles(styles)(Typography);
