import colors from '../../../utils/colors';

const styles = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none !important',
      fontSize: '1rem',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: '100%',
      top: 0,
      transform: 'translateY(-120%)',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  indicatorsContainer: (styles: any) => {
    return {
      ...styles,
      transform: 'rotate(180deg)',
    };
  },
  option: (styles: any) => {
    return {
      ...styles,
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgb(100, 100, 100, 0.2)',
        cursor: 'pointer',
      },
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: colors.dark,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '16px',
    };
  },
  dropdownIndicator: (styles: any) => {
    return {
      ...styles,
      color: colors.dark,
    };
  },
};

export default styles;
