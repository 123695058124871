import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AlertBanner, Typography, Button } from '../primitives';
import styles from './dockDoors.styles';
import DockDoorsService from '../../services/DockDoors.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { renderTimestampString } from '../DetailsPagesFunctions';
import { AuthRoutes } from '../../interfaces/routes';
import qrCodeUtils from '../../utils/qrCode';
import configurationUtils from '../../utils/configurationUtils';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  useMediaQuery,
  useTheme,
  TextField,
  Grid,
  Paper,
  Button as MuiButton,
} from '@material-ui/core';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import colors from '../../utils/colors';
import { common } from '../../utils/strings';

// Types
import { DockDoor, DockDoorDetails } from 'cloudsort-client';
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import CreateIcon from '@material-ui/icons/Create';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const DockDoorsDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [dockDoorsData, setDockDoorsData] = useState<DockDoor>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [editName, setEditName] = useState<string | undefined>(
    undefined,
  );

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // Get labels on each render cycle
  const COLUMNS_OUTBOUND_LOADS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'route_name',
      label: configurationUtils.getPageTitle(true, 'ROUTE'),
      align: 'center',
      width: 150,
    },
    {
      id: 'load_time',
      label: 'Dispatch time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'load_status',
      label: 'Status',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dockdoor_name',
      label: configurationUtils.getPageTitle(true, 'DOCK_DOOR'),
      align: 'center',
      width: 100,
    },
    {
      id: 'containers_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'CONTAINER',
      )}`,
      align: 'center',
      width: 160,
    },
    {
      id: 'packages_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'PACKAGE',
      )}`,
      align: 'center',
      width: 150,
    },
  ];

  const getDockDoorsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await DockDoorsService.getById(
        (match.params as any).id,
      );
      setDockDoorsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (dockDoorsData?.id) {
        qrCodeUtils.download(
          await DockDoorsService.getLabel(dockDoorsData.id),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const editDockDoor = async () => {
    try {
      setShowProgress(true);
      await DockDoorsService.edit(dockDoorsData!.id!, {
        ...(dockDoorsData as DockDoorDetails),
        name: editName,
      });
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      getDockDoorsData();
      onAfterDialogClose();
      setShowProgress(false);
    }
  };

  const fetchLoads = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await OutboundLoadsService.getAll({
      page: pageIndex,
      dockdoor: (match.params as any).id,
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });
    res.data.results.map((ol: any) => {
      ol.load_time = renderTimestampString(ol.load_time);
      return ol;
    });

    return res;
  };

  const onAfterDialogClose = () => {
    setEditName(undefined);
    setError(undefined);
  };

  const renderEditDialog = () => {
    return (
      <Dialog
        open={!!editName}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogContent style={{ padding: inXsScreen ? 16 : 0 }}>
          <DialogTitle>
            Edit {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}{' '}
            {dockDoorsData?.name}
          </DialogTitle>

          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <div
            style={{
              padding: inXsScreen ? '10px 0px' : '10px 24px',
            }}
          >
            <FormControl
              className={classes.formControl}
              style={{ width: '100%', margin: '0 0px 10px 0' }}
            >
              <TextField
                autoFocus
                label={
                  <span style={{ color: colors.title }}>Name</span>
                }
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                FormHelperTextProps={{
                  style: { paddingLeft: '5px', marginTop: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputPadding,
                }}
                style={{ width: '300px', maxWidth: '100%' }}
              />
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <MuiButton
            data-testid={'route-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              editDockDoor();
            }}
            data-testid={'route-dialog-update'}
            disabled={!editName}
          >
            Update
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (!dockDoorsData) {
      getDockDoorsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'DOCK_DOOR')} details for ${
            dockDoorsData?.name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='DOCK_DOOR'>
        {renderEditDialog()}
        {showProgress && <ProgressIndicator />}

        {error && (
          <AlertBanner
            data-testid='dock-doors-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.infoTitle} variant={'h3'}>
              {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}{' '}
              Info
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              data-testid='edit-dock-door'
              variant='contained'
              className={classes.containedButton}
              style={{ marginTop: 15 }}
              fullWidth={false}
              disabled={!dockDoorsData}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                setEditName(dockDoorsData!.name!);
              }}
            >
              <CreateIcon style={{ marginRight: 10 }} />
              Edit
            </Button>
            <Button
              data-testid='QR-code'
              variant='outlined'
              className={classes.outlinedButton}
              style={{ margin: '15px 0 10px 10px' }}
              fullWidth={false}
              disabled={!dockDoorsData}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                onGetQrCick();
              }}
            >
              <FilterCenterFocusIcon style={{ marginRight: 10 }} />
              QR Code
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid='dock-doors-detail-view'
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(true, 'DOCK_DOOR') +
                  ' ID'
                }
                value={dockDoorsData?.id || common.emptyValue}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Name'
                value={dockDoorsData?.name || common.emptyValue}
              />

              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Station Name'
                value={
                  dockDoorsData?.station_name || common.emptyValue
                }
              />
            </Paper>
          </Grid>
        </Grid>

        <PaginatedTable
          disableUpdateQueryStringUrl
          title={configurationUtils.getPageTitle(
            false,
            'OUTBOUND_LOAD',
          )}
          columns={COLUMNS_OUTBOUND_LOADS}
          dataTestIdPrefix={'dock-doors-loads'}
          fetch={fetchLoads}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.OUTBOUND_LOAD}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(DockDoorsDetailsComponent);
