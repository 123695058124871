import React from 'react';
import { Typography, AlertBanner } from '../../primitives';
import styles from './processSteps.styles';
import {
  useTheme,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import {
  NestedProcessStepSerializerPackage,
  NestedProcessStepSerializerContainer,
} from 'cloudsort-client';
import _ from 'lodash';

import fedexLabels from '../../../utils/fedexLabels';

// Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  classes: { [key: string]: string };
  stepsData: (
    | NestedProcessStepSerializerContainer
    | NestedProcessStepSerializerPackage
  )[];
  dataTestPrefix: string;
}

const ProcessSteps: React.FC<Props> = ({
  classes,
  stepsData,
  dataTestPrefix,
}) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const cellsPerRow = isXsScreen ? 2 : 4;
  let lastIndex: number;
  const stepsDataChunks = (
    array: (
      | NestedProcessStepSerializerContainer
      | NestedProcessStepSerializerPackage
    )[],
    chunkSize: number,
  ) => {
    const stepsDataChunksArray = _.chunk(array, chunkSize);
    lastIndex = stepsDataChunksArray.length - 1;
    return stepsDataChunksArray;
  };

  const renderRow = (data: any[], isLastRow: boolean) => {
    return (
      <div className={`${classes.paper} ${classes.paperHolder}`}>
        {data.map((step, index) => {
          return (
            <div
              className={`${classes.singleStat} ${classes.eventSnapshotContainer}`}
              key={`${step.process_step}-${index}`}
              data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}`}
            >
              {index === data.length - 1 && isLastRow && (
                <ArrowDropDownIcon
                  data-testid={'events-snapshot-active'}
                  className={classes.activeEventTypeMarker}
                />
              )}
              <Typography className={classes.singleStatLabel}>
                {fedexLabels.getLabel(step.process_step as string)}
              </Typography>
              <Typography
                variant='h6'
                className={classes.statsValue}
                data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}:time`}
              >
                {new Date(step.timestamp!)
                  .toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })
                  .toLocaleLowerCase()}
              </Typography>
              <Typography
                className={classes.singleStatLabel}
                style={{ marginTop: 2 }}
                data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}:date`}
              >
                {new Date(step.timestamp!).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: 'numeric',
                })}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={classes.eventsSnapshotOuterContainer}
      data-testid={dataTestPrefix + '-event-snapshots'}
    >
      {stepsData.length === 0 && (
        <AlertBanner
          className={classes.noShadow}
          severity='info'
          alertTitle={
            'There is no information to display at the moment'
          }
        />
      )}
      {stepsDataChunks(stepsData, cellsPerRow).map(
        (stepsDataChunks, index) => {
          let lastRow = false;
          if (index === lastIndex) lastRow = true;
          return (
            <div
              key={index}
              style={{
                width: `${
                  stepsDataChunks.length === cellsPerRow
                    ? 100
                    : stepsDataChunks.length === cellsPerRow / 2
                    ? 50
                    : stepsDataChunks.length * 25
                }%`,
              }}
            >
              {renderRow(stepsDataChunks, lastRow)}
            </div>
          );
        },
      )}
    </div>
  );
};

export default withStyles(styles)(ProcessSteps);
