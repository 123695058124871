import { Theme } from '@material-ui/core';
import colors from '../../utils/colors';

export default function configPageStyles(theme: Theme) {
  return {
    moduleTitle: {
      fontSize: '14px',
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
      },
    },
    textHeader: {
      fontSize: '13px',
      color: colors.gray,
      textTransform: 'uppercase',
      padding: '5px 0',
      marginBottom: 5,
    },
    text: {
      fontSize: '14px',
      color: colors.black,
      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
      },
    },
    summaryPanelTitleText: {
      color: colors.white,
      fontWeight: 500,
      fontSize: '18px',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        fontWeight: 700,
      },
    },
    row: {
      padding: '5px 0',
      backgroundColor: colors.white,
      marginBottom: 5,
      marginTop: 5,
      height: '48px',
      [theme.breakpoints.down('md')]: {
        height: '44px',
      },
    },
    editIcon: {
      opacity: 0.5,
    },
    dndIcon: {
      paddingLeft: 10,
      color: '#979797',
    },
    divider: {
      opacity: 0.5,
    },
    muiSwitch: {
      margin: 0,
      '& .MuiIconButton-label': {
        color: colors.gold,
      },
      '& .MuiSwitch-track': {
        backgroundColor: colors.darkGold,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: colors.darkGold,
      },
    },
    muiSwitchHoler: {
      marginTop: 3,
    },
    muiSwitchOff: {
      margin: 0,
      '& .MuiIconButton-label': {
        color: colors.white,
      },
      '& .MuiSwitch-track': {
        backgroundColor: colors.gray,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: colors.gray,
      },
    },
    dialogRoot: {
      minWidth: 400,
    },
    pLeft: {
      paddingLeft: 15,
    },
    inputName: {
      padding: '5px 7px',
    },
    inputNameHolder: {
      border: `1px solid ${colors.dark}`,
      borderRadius: '4px',
      width: '100%',
      marginBottom: 10,
    },
    inputNameLabel: {
      padding: 7,
    },
    tabsIndicator: {
      display: 'None',
    },
    tabsRoot: {
      fontSize: '16px',
    },
    tab: {
      color: colors.black,
      minWidth: 'min-content',
      height: '43px',
      textTransform: 'capitalize',
      fontSize: '16px',
      margin: '0px 10px;',
    },
    selectedTab: {
      background: '#F2F2F2',
      border: '1px solid #D2D2D2',
      borderRadius: '4px',
    },
    panelSummaryExpanded: {
      borderRadius: '4px 4px 0px 0px !important',
      minHeight: '0px !important',
    },
    panelSummary: {
      backgroundColor: colors.dark,
      borderRadius: '4px',
      height: '58px',
      [theme.breakpoints.down('md')]: {
        height: '50px',
      },
    },
    panelDetails: {
      flexDirection: 'column',
      padding: '8px 0px 0px 0px;',
    },
    searchResultsMark: {
      backgroundColor: 'transparent',
      color: '#D39C6E',
    },
  };
}
