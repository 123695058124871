import React from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import { INTERACTION } from '../../Configuration';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import configPageStyles from '../../../commonStyles/configPage.style';
import { CurrentView } from '../Modules';
import OnboardingStep from './OnboardingStep';
import enumToLabel from '../../../../utils/enumToLabel';

interface Props {
  classes: { [key: string]: string };
  data: any;
  onboardingData: any;
  interaction?: INTERACTION;
  setPartialData: (onboardingData: any) => void;
}
const OperatorToolOnboardingSearch: React.FC<Props> = ({
  classes,
  data,
  onboardingData,
  interaction,
  setPartialData,
}) => {
  const currentView = CurrentView.SEARCH;

  const renderOnboardingSteps = () => {
    return (
      <>
        <ExpansionPanel defaultExpanded elevation={0}>
          <ExpansionPanelSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            style={{
              marginTop: 8,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.expansionPanelExpandColapseIcon}
              />
            }
          >
            <Typography
              style={{
                color: colors.white,
                fontWeight: 500,
                fontSize: '18px',
              }}
            >
              Operator Tool Onboarding
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            {Object.entries(onboardingData).map((step, index) => {
              const stepKey = step[0] as string;
              return (
                <OnboardingStep
                  sectionKey={stepKey}
                  key={index}
                  sectionTitle={
                    enumToLabel(stepKey).replace('-', ' ') || ''
                  }
                  defaultExpanded={true}
                  currentView={currentView}
                  data={step[1]}
                  configData={data.config}
                  interaction={interaction}
                  setPartialData={(newData: any) => {
                    const dataObj = {
                      ...onboardingData,
                      [stepKey]: { items: [...newData] },
                    };
                    setPartialData(dataObj);
                  }}
                />
              );
            })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  };

  return renderOnboardingSteps();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
    panelDetails: {
      flexDirection: 'column',
      padding: '0px 0px 0px 0px;',
      background: colors.secondaryWhite,
      boxShadow: 'none',
    },
  })),
)(OperatorToolOnboardingSearch);
