import React, { useState, Fragment, useEffect } from 'react';
import { Typography, BarChart } from '../../primitives/index';
import { Paper } from '@material-ui/core';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { LoaderRing } from '../../primitives';
import ReportsService, {
  ReportFilters,
} from '../../../services/Reports.service';
import colors from '../../../utils/colors';
import { common } from '../../../utils/strings';
import {
  FETCH_INTERVAL_TIME,
  FETCH_INTERVAL_ACTIVE,
} from '../../dashboard/Dashboard';
import dateUtils from '../../../services/utils/date';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Types
import {
  DatasetRow,
  ReportDatasetPerformanceMetricEnum,
} from 'cloudsort-client';
import { SelectedFilters } from '../../filtersDrawer/FiltersDrawer';

interface Props {
  classes: { [key: string]: string };
  title?: string;
  areaId?: number;
  filters?: SelectedFilters;
}

interface ChartData {
  arg: string;
  value: number;
}

const PackagesPerormanceStats: React.FC<Props> = ({
  classes,
  title,
  areaId,
  filters,
}) => {
  const [chartData, setChartData] = useState<
    ChartData[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [performanceMetric, setPerformanceMetric] =
    useState<ReportDatasetPerformanceMetricEnum>(
      ReportDatasetPerformanceMetricEnum.PACKAGES,
    );
  const [currentPerformance, setCurrentPerformance] = useState<{
    [key: string]: string | number;
  }>({ mpp: common.emptyValue, mpft3: common.emptyValue });

  const tabsObj = [
    {
      key: 'mpp',
      label: 'MPP',
      value: ReportDatasetPerformanceMetricEnum.PACKAGES,
    },
    {
      key: 'mpft3',
      label: 'Minutes per ft³',
      value: ReportDatasetPerformanceMetricEnum.VOLUME,
    },
  ];

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: ReportDatasetPerformanceMetricEnum,
  ) => {
    if (newValue !== performanceMetric) {
      setChartData(undefined);
      setPerformanceMetric(newValue);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchStats();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceMetric]);

  const fetchStats = async () => {
    setIsLoading(true);

    const parsedFilters: ReportFilters = {
      owner:
        filters && filters.owner && filters.owner.values.length
          ? filters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        filters && filters.carrier && filters.carrier.values.length
          ? filters.carrier.values.map((item: any) => item.id)
          : undefined,
    };

    const res = await ReportsService.getPerformanceStats(
      '1h',
      performanceMetric,
      undefined,
      areaId,
      parsedFilters,
    );

    const chartData: ChartData[] = [];

    res.data.data?.map((item: DatasetRow) => {
      const label = new Date(item.timestamp ?? '').toLocaleTimeString(
        navigator.language,
        {
          hour: 'numeric',
        },
      );

      chartData.push({
        arg: label,
        value: item.value!,
      });
    });
    setChartData(chartData);

    const resMppCurrent = await ReportsService.getPerformanceStats(
      '6m',
      ReportDatasetPerformanceMetricEnum.PACKAGES,
      true,
      areaId,
      parsedFilters,
    );

    const resMpft3Current = await ReportsService.getPerformanceStats(
      '6m',
      ReportDatasetPerformanceMetricEnum.VOLUME,
      true,
      areaId,
      parsedFilters,
    );

    if (resMppCurrent?.data.data && resMpft3Current?.data.data) {
      if (typeof resMppCurrent.data.data[0].value === 'number') {
        setCurrentPerformance({
          mpp: resMppCurrent.data.data[
            resMppCurrent.data.data.length - 1
          ].value!.toFixed(2),
          mpft3:
            resMpft3Current.data.data[
              resMpft3Current.data.data.length - 1
            ].value!.toFixed(2),
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (dateUtils.isSystemDateToday() && FETCH_INTERVAL_ACTIVE) {
      const interval = setInterval(() => {
        fetchStats();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Typography variant='h3'>{title}</Typography>
      <Paper>
        {isLoading && (
          <div style={{ position: 'relative' }}>
            <LoaderRing
              style={{
                position: 'absolute',
                top: 200,
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        )}
        <Tabs
          value={performanceMetric}
          onChange={handleTabChange}
          variant='scrollable'
          classes={{
            indicator: classes.indicator,
          }}
        >
          {tabsObj.map((tabObj) => {
            return (
              <Tab
                data-testid={`packages-performance-stats:${tabObj.label}`}
                disableRipple
                disableFocusRipple
                key={tabObj.value}
                label={
                  <div>
                    <Typography className={classes.tabLabel}>
                      {tabObj.label}
                    </Typography>
                    <Typography variant={'h6'}>
                      {currentPerformance[tabObj.key]}
                    </Typography>
                  </div>
                }
                classes={{
                  root: classes.tab,
                }}
                value={tabObj.value}
              />
            );
          })}
        </Tabs>
        <BarChart
          height={250}
          chartData={chartData}
          fill={colors.gold}
          dataType='mpp'
          dataTestId='chart-dashboard-performance'
        />
      </Paper>
    </Fragment>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    flexRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    indicator: {
      backgroundColor: colors.gold,
    },
    tab: {
      flexGrow: 1,
      maxWidth: '100%',
      padding: '6px 12px 14px 12px',
      borderRight: '2px solid #f5f6f9',
    },
    tabLabel: {
      color: colors.lightGray,
      fontSize: 12,
      letterSpacing: 0,
      textAlign: 'center',
      marginTop: 20,
    },
  })),
)(PackagesPerormanceStats);
