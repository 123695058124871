import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import BaseApi from '../utils/BaseApi';

class SentryService {
  private isActive: boolean = true;

  private getEnviroment(basePath: string) {
    try {
      switch (basePath) {
        case 'https://api.cloudsort.com/api':
          return 'production';
        case 'https://api-staging.cloudsort.com/api':
          return 'staging';
        case 'https://api-demo.cloudsort.com/api':
          return 'demo';
        default:
          return undefined;
      }
    } catch (e) {
      console.error(e);
    }
  }

  public init() {
    try {
      const env = this.getEnviroment(BaseApi.getBasePath());

      if (!env) {
        this.isActive = false;
        return;
      }

      Sentry.init({
        dsn: 'https://fe333d1a4e234a1c93da71b5b7325af0@o493300.ingest.sentry.io/5681601',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.0,
        environment: env,
      });
    } catch (e) {
      console.error(e);
    }
  }

  public captureException(exception: any) {
    if (!this.isActive) {
      return;
    }

    Sentry.withScope((scope) => {
      if (exception.stack) {
        scope.setExtra('stack trace', exception.stack);
      }
      Sentry.captureException(exception);
    });
  }
}

export default new SentryService();
