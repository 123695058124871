import {
  CorporateRolesDependencies,
  PermissionsPermissionsEnum,
} from 'cloudsort-client';
import { useEffect, useState } from 'react';
import {
  OTHER_PERMISSIONS_LABELS,
  OT_PERMISSIONS_LABELS,
} from '../components/stationStaff/StationStaff';
import CorportateService from '../services/Corportate.service';
import usePermissionLabels from './usePermissionLabels';

//Used for dealing with permission dependencies in user details pages & add new user modal.
const usePermissionDependencies = () => {
  const [permissionDependencies, setPermissionDepdendencies] =
    useState<CorporateRolesDependencies[]>([]);
  const permissionLabels = usePermissionLabels();

  const getAndSetPermissionDependencies = async () => {
    try {
      const { data } =
        await CorportateService.getPermissionDependencies();
      setPermissionDepdendencies(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAndSetPermissionDependencies();
  }, []);

  const calculateDependencyStatus = (
    permissions:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
    currentPermissionsState: string[],
  ) => {
    //First, extract the list of permissions
    const listOfPermissions = Object.keys(PermissionsPermissionsEnum)
      .filter((permission) =>
        Object.keys(permissions).includes(permission),
      )
      .map((key) => {
        return PermissionsPermissionsEnum[
          key as keyof typeof PermissionsPermissionsEnum
        ];
      });

    let dependencyStatusData: {
      [key: string]: {
        enabled: boolean;
        dependencies: string[];
      };
    } = {};

    if (permissionDependencies.length === 0) {
      //We're missing data about dependencies, set every permission as enabled.
      for (let userPermission of listOfPermissions) {
        dependencyStatusData[userPermission] = {
          enabled: true,
          dependencies: [],
        };
      }
      return dependencyStatusData;
    }

    //Go through each permission and calculate the state.
    for (let userPermission of listOfPermissions) {
      const dependencies = permissionDependencies.find(
        (dependencyData) =>
          (dependencyData.permission as string) ===
          (userPermission as string),
      );
      //No parents, permission should stay enabled.
      if (dependencies!.parents.length === 0) {
        //Nothing to check here, this permission doesn't have dependencies.
        dependencyStatusData[userPermission] = {
          enabled: true,
          dependencies: [],
        };
      }
      //If at least 1 parent is enabled, the permission should stay enabled.
      if (dependencies!.parents.length > 0) {
        let hasEnabledParent = false;
        const dependsOn = [];
        for (let parentDependency of dependencies!.parents) {
          if (
            currentPermissionsState.some(
              (permission) =>
                permission === (parentDependency as string),
            )
          ) {
            hasEnabledParent = true;
          } else {
            dependsOn.push(permissionLabels[parentDependency]);
          }
        }
        dependencyStatusData[userPermission] = {
          enabled: hasEnabledParent,
          dependencies: dependsOn,
        };
      }
    }
    return dependencyStatusData;
  };

  const shouldPermissionStayEnabled = (
    permission: string,
    currentPermissionsState: string[],
  ) => {
    let shouldStayEnabled = false;
    const dependencies = permissionDependencies.find(
      (dependencyData) => dependencyData.permission === permission,
    );
    if (dependencies!.parents.length === 0) {
      //No parents, the permission is enabled by default.
      return true;
    }
    //Go through list of parents, if atleast 1 parent is enabled, this permission should stay enabled too.
    for (let parentPermission of dependencies!.parents) {
      if (
        currentPermissionsState.some(
          (permission) => permission === (parentPermission as string),
        )
      ) {
        shouldStayEnabled = true;
      }
    }
    return shouldStayEnabled;
  };

  const disablePermissionAndDependants = (
    permission: string,
    currentPermissionsState: string[],
  ) => {
    const newPermissionsState: any[] = [...currentPermissionsState];
    newPermissionsState.splice(
      newPermissionsState.indexOf(permission),
      1,
    );
    //Find all dependants
    const disabledPermissions: string[] = [];
    const dependencies = permissionDependencies.find(
      (dependencyData) =>
        dependencyData.permission === (permission as string),
    );
    if (dependencies!.children.length > 0) {
      for (let dependant of dependencies!.children) {
        if (
          newPermissionsState.some(
            (permission) => permission === (dependant as string),
          ) &&
          !shouldPermissionStayEnabled(dependant, newPermissionsState)
        ) {
          newPermissionsState.splice(
            newPermissionsState.indexOf(dependant),
            1,
          );
          const disabledDependencyName = permissionLabels[dependant];
          //Check if permission has disabled in one of the previous checks
          if (!disabledPermissions.includes(disabledDependencyName)) {
            disabledPermissions.push(disabledDependencyName);
          }
        }
      }
    }
    return {
      newPermissionsState,
      disabledPermissions: disabledPermissions,
    };
  };

  return {
    calculateDependencyStatus: calculateDependencyStatus,
    disablePermissionAndDependants: disablePermissionAndDependants,
  };
};

export default usePermissionDependencies;
