import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import colors from '../../../utils/colors';
import { Tooltip, Typography, withStyles } from '@material-ui/core';

type Props = CircularProgressProps & {
  classes: { [key: string]: string };
  value: number;
  tooltipText?: string;
};

const CircularProgressWithLabel: React.FC<Props> = ({
  classes,
  value,
  tooltipText = '',
  ...props
}) => {
  return (
    <Tooltip
      title={tooltipText}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <Box
        position='relative'
        display='inline-flex'
        style={{
          width: '36px',
          height: '36px',
        }}
      >
        <CircularProgress
          color='primary'
          variant='determinate'
          value={100}
          style={{
            color: colors.lightGray,
            width: '36px',
            height: '36px',
          }}
        />
        <CircularProgress
          variant='determinate'
          color='secondary'
          value={value}
          style={{
            position: 'absolute',
            color: colors.darkGold,
            width: '36px',
            height: '36px',
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            style={{
              fontSize: '10px',
            }}
            variant='caption'
          >{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default withStyles({
  tooltip: {
    background: colors.white,
    color: colors.dark,
    borderRadius: '4px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16);',
    fontSize: '12px',
  },
})(CircularProgressWithLabel);
