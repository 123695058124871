import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner, Typography } from '../../primitives';
import colors from '../../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  Box,
  DialogTitle,
  createStyles,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';
import sanitizeHtml from 'sanitize-html';
import { debounce, indexOf } from 'lodash';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../../asyncSelect/asyncSelect.styles';
import { common } from '../../../utils/strings';
//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  Customer,
  SchemeDetails,
  User,
} from 'cloudsort-client';

// Services
import SchemesService from '../../../services/Schemes.service';

//Icons
import SettingsIcon from '@material-ui/icons/Settings';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { noOptionsMessage } from '../../asyncSelect/utils';
import { TypeAheadItem } from '../../../interfaces/components';
import CustomersService from '../../../services/Customers.service';
import FmcService from '../../../services/Fmc.service';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
  editSchemeData: SchemeDetails;
}

enum EditSchemeState {
  LIST = 'LIST',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const EditSchemeDialog: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  updateParent,
  editSchemeData,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [editSchemeState, setEditSchemeState] =
    useState<EditSchemeState>(EditSchemeState.LIST);

  const [schemeCurrentlyEditing, setSchemeCurrentlyEditing] =
    useState<SchemeDetails>(editSchemeData);
  const [
    zipCodeIndexCurrentlyEditing,
    setZipCodeIndexCurrentlyEditing,
  ] = useState<number>();
  const [
    zipCodeValuesCurrentlyEditing,
    setZipCodeValuesCurrentlyEditing,
  ] = useState<string[]>(['', '']);
  const [zipCodesMode, setZipCodesMode] = useState<string>('single');

  const [
    showMoveZipCodeConfirmation,
    setShowMoveZipCodeConfirmation,
  ] = useState<boolean>(false);

  const onlyNumbers = (value: string) => value.replace(/\D/g, '');

  const zipCodeEditingDisabled =
    zipCodesMode === 'single'
      ? ![3, 5, 9, 11].includes(
          zipCodeValuesCurrentlyEditing![0].length,
        )
      : ![3, 5, 9, 11].includes(
          zipCodeValuesCurrentlyEditing![0].length,
        ) ||
        zipCodeValuesCurrentlyEditing![0].length !==
          zipCodeValuesCurrentlyEditing![1].length ||
        Number(zipCodeValuesCurrentlyEditing![0]) >=
          Number(zipCodeValuesCurrentlyEditing![1]);

  const handleClose = () => {
    setError(undefined);
    setEditSchemeState(EditSchemeState.LIST);
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.ResourceConflict
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are duplicated in the Scheme Set: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e));
  };

  const processUpdateScheme = async (moveZipCode = false) => {
    setShowProgress(true);
    try {
      if (moveZipCode) {
        await SchemesService.update(schemeCurrentlyEditing!, true);
        setShowMoveZipCodeConfirmation(false);
      } else {
        await SchemesService.update(schemeCurrentlyEditing!);
      }

      updateParent();
      handleClose();
    } catch (e) {
      if (
        e.response.data?.error_code ===
        APIExceptionErrorCodeEnum.ResourceConflict
      ) {
        setShowMoveZipCodeConfirmation(true);
      } else handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setSchemeCurrentlyEditing(editSchemeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSchemeData]);

  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(undefined, inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  const loadFmcOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: User) => {
              return {
                value: dataEl.id,
                label: dataEl.full_name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e);
        });
    }, 500),
    [],
  );

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog disableBackdropClick open={open}>
        <DialogTitle style={{ textAlign: 'center' }}>
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <SettingsIcon
            style={{
              color: colors.darkGold,
              width: '48px',
              height: 'auto',
            }}
          />
          <br />
          Edit {schemeCurrentlyEditing?.name} scheme
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {editSchemeState === EditSchemeState.LIST && (
            <Grid container item xs={12}>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: colors.darkGold,
                      fontWeight: 500,
                      margin: '10px 10px 0 0',
                    }}
                  >
                    Sortation Parameter
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      className: classes.inputGoldUnderline,
                    }}
                    classes={{
                      root: classes.inputHolder,
                    }}
                    value={
                      schemeCurrentlyEditing?.sort_param
                        ? sanitizeHtml(
                            schemeCurrentlyEditing.sort_param ||
                              common.emptyValue,
                            {
                              allowedTags: [],
                            },
                          )
                        : ''
                    }
                    onChange={(e) => {
                      setSchemeCurrentlyEditing({
                        ...schemeCurrentlyEditing,
                        sort_param: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: colors.darkGold,
                      fontWeight: 500,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    Carrier
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={8}
                  xs={12}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <AsyncSelect<TypeAheadItem>
                    isClearable
                    cacheOptions
                    styles={{
                      ...asyncSelectStyles,

                      menuPortal: (styles: any) => {
                        return {
                          ...styles,
                          zIndex: 9999,
                        };
                      },
                      placeholder: (styles: any) => {
                        return {
                          ...styles,
                          color: colors.lightGray,
                        };
                      },
                      container: (styles: any) => {
                        return {
                          width: '100%',
                          borderBottom: `3px solid ${colors.darkGold}`,
                        };
                      },
                      control: (styles: any, state: any) => {
                        return {
                          ...styles,
                          backgroundColor: 'transparent',
                          background: 'transparent',
                          boxShadow: 'none !important',
                          outline: `none !important`,
                          border: 0,
                        };
                      },
                    }}
                    loadOptions={loadFmcOptions}
                    onChange={(option) => {
                      setSchemeCurrentlyEditing({
                        ...schemeCurrentlyEditing,
                        fmc: option
                          ? Number((option as TypeAheadItem).value)
                          : null,
                        fmc_full_name: option
                          ? (option as TypeAheadItem).label
                          : undefined,
                      });
                    }}
                    value={{
                      label:
                        schemeCurrentlyEditing?.fmc_full_name || '',
                      value:
                        String(schemeCurrentlyEditing?.fmc) || '',
                    }}
                    isDisabled={!!error}
                    placeholder={'Start Typing...'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={noOptionsMessage}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: colors.darkGold,
                      fontWeight: 500,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    Owner
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={8}
                  xs={12}
                  container
                  style={{ marginBottom: 16 }}
                >
                  <AsyncSelect<TypeAheadItem>
                    isClearable
                    cacheOptions
                    styles={{
                      ...asyncSelectStyles,

                      menuPortal: (styles: any) => {
                        return {
                          ...styles,
                          zIndex: 9999,
                        };
                      },
                      placeholder: (styles: any) => {
                        return {
                          ...styles,
                          color: colors.lightGray,
                        };
                      },
                      container: (styles: any) => {
                        return {
                          width: '100%',
                          borderBottom: `3px solid ${colors.darkGold}`,
                        };
                      },
                      control: (styles: any, state: any) => {
                        return {
                          ...styles,
                          backgroundColor: 'transparent',
                          background: 'transparent',
                          boxShadow: 'none !important',
                          outline: `none !important`,
                          border: 0,
                        };
                      },
                    }}
                    loadOptions={loadCustomerOptions}
                    onChange={(option) => {
                      setSchemeCurrentlyEditing({
                        ...schemeCurrentlyEditing,
                        owner: option
                          ? Number((option as TypeAheadItem).value)
                          : null,
                        owner_identifier: option
                          ? (option as TypeAheadItem).label
                          : undefined,
                      });
                    }}
                    value={{
                      label:
                        schemeCurrentlyEditing?.owner_identifier ||
                        '',
                      value:
                        String(schemeCurrentlyEditing?.owner) || '',
                    }}
                    isDisabled={!!error}
                    placeholder={'Start Typing...'}
                    menuPortalTarget={document.body}
                    noOptionsMessage={noOptionsMessage}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  style={{
                    color: colors.darkGold,
                    fontSize: 16,
                    borderBottom: `1px solid ${colors.lightGray}`,
                    marginBottom: '3px',
                    paddingBottom: '3px',
                  }}
                >
                  ZIP Codes
                </Typography>
                {schemeCurrentlyEditing?.zipcodes?.length === 0 && (
                  <AlertBanner
                    className={classes.banner}
                    severity='info'
                    alertTitle={'No ZIP Codes'}
                    alertMsg={'This scheme has no ZIP Codes'}
                    style={{ marginTop: '20px' }}
                  />
                )}
              </Grid>

              {schemeCurrentlyEditing?.zipcodes &&
                schemeCurrentlyEditing.zipcodes.map((zip, index) => (
                  <Fragment key={index}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        borderBottom: `1px solid ${colors.lightGray}`,
                        padding: '3px 0',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {zip.join('-')}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        borderBottom: `1px solid ${colors.lightGray}`,
                        padding: '3px 0',
                      }}
                    >
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={(e) => {
                          setZipCodeIndexCurrentlyEditing(index);
                          if (zip.length > 1) {
                            setZipCodeValuesCurrentlyEditing(zip);
                            setZipCodesMode('range');
                          } else {
                            setZipCodeValuesCurrentlyEditing([
                              zip[0],
                              '',
                            ]);
                            setZipCodesMode('single');
                          }

                          setEditSchemeState(EditSchemeState.EDIT);
                        }}
                      >
                        <SettingsIcon
                          style={{
                            marginRight: '5px',
                            color: colors.darkGold,
                          }}
                        />{' '}
                        Edit
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{
                        borderBottom: `1px solid ${colors.lightGray}`,
                        padding: '3px 0',
                      }}
                    >
                      <Button
                        style={{ textTransform: 'none' }}
                        onClick={(e) => {
                          setSchemeCurrentlyEditing({
                            ...schemeCurrentlyEditing,
                            zipcodes:
                              schemeCurrentlyEditing.zipcodes!.filter(
                                (z) =>
                                  indexOf(
                                    schemeCurrentlyEditing.zipcodes,
                                    z,
                                  ) !== index,
                              ),
                          });
                        }}
                      >
                        <CancelIcon style={{ marginRight: '5px' }} />{' '}
                        Remove
                      </Button>
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
          )}
          {editSchemeState !== EditSchemeState.LIST && (
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <RadioGroup
                  style={{
                    textAlign: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                  value={zipCodesMode}
                  onChange={(e) => {
                    setZipCodesMode(e.target.value);
                    if (e.target.value === 'single')
                      setZipCodeValuesCurrentlyEditing([
                        zipCodeValuesCurrentlyEditing![0],
                        '',
                      ]);
                  }}
                >
                  <FormControlLabel
                    value='single'
                    control={<Radio color='primary' />}
                    label='Single ZIP Code'
                    style={{
                      display: 'inline-block',
                      fontSize: '14px',
                    }}
                  />
                  <FormControlLabel
                    value='range'
                    control={<Radio color='primary' />}
                    label='Range of ZIP Codes'
                    style={{
                      display: 'inline-block',
                      fontSize: '14px',
                    }}
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={6}>
                <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: colors.darkGold,
                    }}
                  >
                    Start ZIP Code
                  </Typography>
                </Box>
                <TextField
                  autoFocus
                  InputProps={{
                    disableUnderline: true,
                    className: classes.inputGoldUnderline,
                  }}
                  classes={{
                    root: classes.inputHolder,
                  }}
                  value={
                    zipCodeValuesCurrentlyEditing &&
                    zipCodeValuesCurrentlyEditing[0]
                  }
                  onChange={(e) => {
                    zipCodeValuesCurrentlyEditing!.length > 1
                      ? setZipCodeValuesCurrentlyEditing([
                          onlyNumbers(e.target.value),
                          zipCodeValuesCurrentlyEditing![1],
                        ])
                      : setZipCodeValuesCurrentlyEditing([
                          onlyNumbers(e.target.value),
                          '',
                        ]);
                  }}
                  helperText={
                    ![3, 5, 9, 11].includes(
                      zipCodeValuesCurrentlyEditing![0].length,
                    )
                      ? 'ZIP Code must have 3, 5, 9 or 11 digits.'
                      : undefined
                  }
                />
              </Grid>
              {zipCodesMode === 'range' && (
                <Grid item xs={6}>
                  <Box fontWeight='fontWeightBold' p={0.5} pl={0}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        color: colors.darkGold,
                      }}
                    >
                      End ZIP Code
                    </Typography>
                  </Box>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      className: classes.inputGoldUnderline,
                    }}
                    classes={{
                      root: classes.inputHolder,
                    }}
                    value={
                      zipCodeValuesCurrentlyEditing &&
                      zipCodeValuesCurrentlyEditing[1]
                    }
                    onChange={(e) => {
                      setZipCodeValuesCurrentlyEditing([
                        zipCodeValuesCurrentlyEditing![0],
                        onlyNumbers(e.target.value),
                      ]);
                    }}
                    helperText={
                      [3, 5, 9, 11].includes(
                        zipCodeValuesCurrentlyEditing![0].length,
                      )
                        ? zipCodeValuesCurrentlyEditing![0].length !==
                          zipCodeValuesCurrentlyEditing![1].length
                          ? `ZIP Code must have ${
                              zipCodeValuesCurrentlyEditing![0].length
                            } digits.`
                          : Number(
                              zipCodeValuesCurrentlyEditing![0],
                            ) >=
                            Number(zipCodeValuesCurrentlyEditing![1])
                          ? 'End ZIP code must be higher than start zip code'
                          : undefined
                        : undefined
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}{' '}
        </DialogContent>
        {editSchemeState === EditSchemeState.LIST && (
          <DialogActions>
            <Box pl={1} pr={1} style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant='outlined'
                    className={classes.containedButton}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setEditSchemeState(EditSchemeState.ADD);
                      setZipCodesMode('single');
                      setZipCodeValuesCurrentlyEditing(['', '']);
                    }}
                  >
                    <AddToPhotosOutlinedIcon
                      style={{ marginRight: 10 }}
                    />
                    Add ZIP Codes
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ textAlign: 'right', paddingTop: 5 }}
                >
                  <Button
                    data-testid={'scheme-edit-dialog-add-zip-codes'}
                    className={classes.button}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    data-testid={'scheme-edit-dialog-save'}
                    className={classes.button}
                    disabled={false}
                    onClick={() => {
                      processUpdateScheme();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        )}
        {editSchemeState === EditSchemeState.ADD && (
          <DialogActions>
            <Box pl={1} pr={1}>
              <Button
                data-testid={'scheme-edit-dialog-add-zipcode-cancel'}
                className={classes.button}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>

              <Button
                data-testid={'scheme-edit-dialog-add-zipcode-save'}
                className={classes.button}
                disabled={zipCodeEditingDisabled}
                onClick={() => {
                  const schemeCopy = {
                    ...schemeCurrentlyEditing,
                  } as SchemeDetails;

                  schemeCopy.zipcodes!.push(
                    zipCodeValuesCurrentlyEditing!.filter(
                      (value) => value !== '',
                    ),
                  );
                  setSchemeCurrentlyEditing(schemeCopy);
                  setEditSchemeState(EditSchemeState.LIST);
                }}
              >
                Add
              </Button>
            </Box>
          </DialogActions>
        )}
        {editSchemeState === EditSchemeState.EDIT && (
          <DialogActions>
            <Box pl={1} pr={1}>
              <Button
                data-testid={'scheme-edit-dialog-edit-zipcode-cancel'}
                className={classes.button}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>

              <Button
                data-testid={'scheme-edit-dialog-edit-zipcode-save'}
                className={classes.button}
                disabled={zipCodeEditingDisabled}
                onClick={() => {
                  const schemeCopy = {
                    ...schemeCurrentlyEditing,
                  } as SchemeDetails;
                  schemeCopy.zipcodes![
                    zipCodeIndexCurrentlyEditing!
                  ] = zipCodeValuesCurrentlyEditing!.filter(
                    (value) => value !== '',
                  );
                  setSchemeCurrentlyEditing(schemeCopy);
                  setEditSchemeState(EditSchemeState.LIST);
                }}
              >
                Edit
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
      <ConfirmationDialog
        data-testid={'move-zip-code-confirmation'}
        title={'Some ZIP Codes are already part of the other scheme'}
        msg={`Are you sure you want to move conflicting ZIP Codes to scheme ${schemeCurrentlyEditing?.name}?`}
        primaryActionLabel={'Move'}
        onPrimaryAction={() => {
          processUpdateScheme(true);
        }}
        cancelLabel={'Discard Changes'}
        onCancel={() => {
          handleClose();
        }}
        isOpen={showMoveZipCodeConfirmation}
      />
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: { width: '500px', maxWidth: '100%' },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
  })),
)(EditSchemeDialog);
