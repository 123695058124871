import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Typography } from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import { AlertBanner } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';

import Button from '@material-ui/core//Button';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import colors from '../../../utils/colors';
import browserHistory from '../../../utils/browserHistory';
import { Grid } from '@material-ui/core';
import { AuthRoutes } from '../../../interfaces/routes';

// Types
import { Module } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import ZoneModulesService from '../../../services/ZoneModules.service';
import { Helmet } from 'react-helmet';
import configurationUtils from '../../../utils/configurationUtils';
interface Props {
  classes: { [key: string]: string };
  match: any;
}

const AddCorporateModule: React.FC<Props> = ({ classes, match }) => {
  const [modules, setModules] = useState<Module[]>();

  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const fetchModules = async () => {
    setShowProgress(true);
    try {
      const data = (
        await ZoneModulesService.getAllCorporateModules(
          undefined,
          match.params.areaType,
          'load_point_count',
          20,
        )
      ).data.results;
      setModules(data);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const addToTheZone = async (moduleId: number) => {
    setShowProgress(true);
    try {
      await ZoneModulesService.assignModuleToAZone(
        match.params.zoneId,
        moduleId,
      );
      setTimeout(() => {
        browserHistory.push(
          `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
        );
      }, 0);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    fetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(
  true,
  'AREA',
)} - Add Corporate Module`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <div className={classes.header}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography className={classes.title} variant={'h3'}>
                Add Corporate Module
              </Typography>
              <Typography
                className={classes.subtitle}
                variant={'body1'}
                style={{ minWidth: 'max-content' }}
              >
                Please select a module to start
              </Typography>
            </div>
          </div>
        </div>
        <Grid container spacing={3}>
          {modules?.length
            ? modules?.map((module: Module) => (
                <Grid
                  key={module.id}
                  className={classes.flexItem}
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <Paper
                    className={classes.paper}
                    style={{
                      marginBottom: inXsScreen ? 10 : 0,
                      height: inXsScreen ? 110 : 'auto',
                    }}
                  >
                    <span className={classes.loadPointCount}>
                      {module.load_point_count}
                    </span>
                    <Typography
                      className={classes.name}
                      variant={'h3'}
                    >
                      {module.name}
                    </Typography>
                    <Typography
                      className={classes.loadPointCountSubtitle}
                      variant={'body1'}
                    >
                      {module.load_point_count} Loadpoints
                    </Typography>
                    <Button
                      data-testid={'add-corporate-module-button'}
                      className={classes.selectButton}
                      onClick={(e) => {
                        addToTheZone(module.id!);
                      }}
                    >
                      Select
                    </Button>
                  </Paper>
                </Grid>
              ))
            : modules?.length === 0 && (
                <AlertBanner
                  severity='info'
                  alertTitle={
                    'There is no information to display at the moment'
                  }
                />
              )}
        </Grid>
        <Button
          variant='contained'
          className={classes.buttonContained}
          onClick={() => {
            browserHistory.push({
              pathname: `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
            });
          }}
        >
          CANCEL
        </Button>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    title: {
      height: 28,
      color: colors.title,
      fontSize: 24,
      [theme.breakpoints.down('xs')]: {
        height: 'fit-content',
      },
      fontWeight: 'normal',
      opacity: 1,
      margin: '6px 0',
    },
    subtitle: {
      fontSize: '14px',
      color: colors.lightGray,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '40px 0 20px 0',
      paddingBottom: 15,
      borderBottom: '0.5px solid rgb(220, 220, 220)',
    },
    loadPointCount: {
      float: 'left',
      fontSize: '22px',
      backgroundColor: colors.gold,
      borderRadius: '50%',
      width: '40px',
      lineHeight: '40px',
      textAlign: 'center',
      margin: '10px',
      letterSpacing: '0',
      fontWeight: 'bold',
    },
    name: {
      textAlign: 'left',
      margin: '10px 0 0 0;',
      fontSize: '20px',
    },
    loadPointCountSubtitle: { textAlign: 'left', fontSize: '14px' },
    selectButton: {
      color: colors.darkGold,
    },
    filter: {
      margin: '0 0 10px 25px',
      [theme.breakpoints.down('xs')]: {
        margin: '0px',
      },
      height: 56,
      width: 175,
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
        {
          borderColor: `${colors.darkGold} !important`,
          borderWidth: 2,
        },
      '& .MuiFormLabel-root': {
        top: '-6px',
        left: 15,
      },
    },
    muiSelect: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    buttonContained: {
      marginTop: '20px',
      color: colors.white,
      backgroundColor: colors.darkGold,
      '&:hover': {
        backgroundColor: colors.darkGold,
      },
    },
    paper: {
      padding: '10px',
      textAlign: 'right',
    },
    flexContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  })),
)(AddCorporateModule);
