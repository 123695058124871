import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox, {
  CheckboxProps,
} from '@material-ui/core/Checkbox';
import * as React from 'react';

export interface ICheckboxProps extends CheckboxProps {
  label?: string;
}

const Checkbox: React.FC<ICheckboxProps> = props => {
  const { label, ...muiProps } = props;
  return (
    <FormControlLabel
      control={<MuiCheckbox {...muiProps} />}
      label={label}
    />
  );
};

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
