import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import colors from '../../utils/colors';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
  selectFilterByQueryString,
  getSelectedFilterOption,
} from '../paginatedTable/PaginatedTable';
import { AlertBanner, Typography } from '../primitives';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { COLUMNS_DEVICES } from '../TableColumns';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import moment from 'moment';
import queryString from 'query-string';
import fedexLabels from '../../utils/fedexLabels';
import {
  Device,
  PermissionsPermissionsEnum,
  DeviceDetails,
} from 'cloudsort-client';
import { AuthRoutes } from '../../interfaces/routes';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

// Services
import DevicesService from '../../services/Devices.service';
import ErrorHandler from '../../utils/ErrorHandler';
import PermissionsService from '../../services/Permissions.service';

//Types
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

export const getBateryLabel = (device?: Device | DeviceDetails) => {
  if (device) {
    const battery_info = device.device_info?.battery_info;
    if (
      battery_info &&
      battery_info?.percentage &&
      battery_info?.timestamp
    ) {
      return `${battery_info.percentage}% - ${moment(
        battery_info.timestamp,
      ).fromNow()}`;
    }
  }
  return common.emptyValue;
};

export const getVersionLabel = (device: Device) => {
  let label = '';
  if (device.version) {
    label += device.version;
    if (device.version_status === 'OUTDATED')
      label += `<span style="font-size:11px; border:1px solid ${colors.darkGold}; margin-left:5px; padding:5px 8px; border-radius:3px;">Outdated</span>`;
    else if (device.version_status === 'DEPRECATED')
      label +=
        '<span style="font-size:11px;border:1px solid red; margin-left:5px; padding:5px 8px; border-radius:3px;">Deprecated</span>';
    return label;
  } else {
    return common.emptyValue;
  }
};

export const getLastUsedLabel = (device: Device) => {
  let label = '';
  if (device.last_event && device.last_event.timestamp) {
    label = fedexLabels.getLabel(
      device.last_event.event_type as string,
    );

    if (device.last_event.area_name) {
      label += ' in ' + device.last_event.area_name;
    }

    label +=
      ' by ' +
      device.last_event.user_full_name +
      ' at ' +
      moment(device.last_event.timestamp).format(
        'h:mm a, MMMM Do YYYY',
      );
  } else {
    label = common.emptyValue;
  }

  return label;
};

const initRequestTokenConfirmationDialog = {
  open: false,
  cb: undefined,
};

const Devices: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [showEnrolmentTokenDialog, setShowEnrolmentTokenDialog] =
    useState<boolean>(false);
  const [
    enrolmentTokenDiaogAlreadyShown,
    setEnrolmentTokenDiaogAlreadyShown,
  ] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>();
  const [error, setError] = useState<string>();
  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [
    requestTokenConfirmationDialog,
    setRequestTokenConfirmationDialog,
  ] = useState<any>(initRequestTokenConfirmationDialog);

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
  };

  const onAddDevice = async () => {
    setShowProgress(true);
    try {
      await DevicesService.addDevice(deviceId!);
      setLastUpdated(new Date().toISOString());
      if (!enrolmentTokenDiaogAlreadyShown)
        setShowEnrolmentTokenDialog(true);
      setEnrolmentTokenDiaogAlreadyShown(true);
      onAfterDialogClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderDialog = () => {
    return (
      <Dialog
        data-testid={'devices-add-dialog'}
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        {error && (
          <div style={{ marginTop: 15 }}>
            <AlertBanner
              data-testid={'devices-error-banner'}
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          </div>
        )}
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>
              Add {configurationUtils.getPageTitle(true, 'DEVICE')}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <div style={{ display: 'flex', marginBottom: 15 }}>
            <TextField
              autoFocus
              data-testid={'devices-device-id-input'}
              label={`${configurationUtils.getPageTitle(
                true,
                'DEVICE',
              )} ID`}
              InputLabelProps={{
                shrink: true,
                className: classes.inputNameLabel,
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.inputName,
              }}
              classes={{
                root: classes.inputNameHolder,
              }}
              onChange={(e) => {
                setDeviceId(e.target.value.toUpperCase());
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid={'devices-dialog-close'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid={'devices-dialog-add'}
            disabled={!deviceId}
            onClick={() => {
              onAddDevice();
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const filters = async () => {
    const filters: filterObj[] = [];

    const versionStatusOptions = [
      { label: 'All', value: 'ALL', selected: true },
      { label: 'Up-to-date', value: 'LIVE', selected: false },
      { label: 'Outdated', value: 'OUTDATED', selected: false },
      { label: 'Deprecated', value: 'DEPRECATED', selected: false },
    ];

    const verssionStatusQS = queryString.parse(location.search)[
      'VersionStatus'
    ];

    if (verssionStatusQS) {
      selectFilterByQueryString(
        verssionStatusQS,
        versionStatusOptions,
      );
    }

    filters.push({
      name: 'Version Status',
      options: versionStatusOptions,
      labelWidth: 110,
    });

    return filters;
  };

  const fetchDevices = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    let fVersionStatus = undefined;

    if (filterOptions) {
      fVersionStatus = getSelectedFilterOption(
        filterOptions,
        'Version Status',
      );
      if (fVersionStatus === 'ALL') {
        fVersionStatus = undefined;
      }
    }

    sortedBy = sortedBy?.replace('_id', '');

    const res = await DevicesService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      deviceDetails: false,
      sortBy: sortedBy as 'version' | '-version' | undefined,
      versionStatus: fVersionStatus as
        | 'LIVE'
        | 'OUTDATED'
        | 'DEPRECATED',
    });

    res.data.results.forEach((device: any) => {
      device.active = device.active
        ? `<span style="color:${colors.darkGold}">Active</span>`
        : 'Inactive';
      device.last_used = getLastUsedLabel(device);
      device.version_id = getVersionLabel(device);
      device.battery_label = getBateryLabel(device);
    });
    return res;
  };

  const getEnrolmentToken = async () => {
    setShowProgress(true);
    try {
      const file = await DevicesService.getEnrolmentToken();
      const blob = new Blob([file.data as unknown as string], {
        type: 'application/pdf',
      });
      const fileNamStr = file.request.getResponseHeader(
        'content-disposition',
      );
      saveAs(
        blob,
        fileNamStr.split('filename=')[1].replace(/"/g, ''),
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.DEVICEREAD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
          ${configurationUtils.getPageTitle(false, 'DEVICE')}  ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='DEVICE'>
        {showProgress && <ProgressIndicator />}
        <ConfirmationDialog
          dataTestIdPrefix={'download-enrolment-token-dialog'}
          title='Download enrollment token'
          msg='Would you like to download the enrollment token?'
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            setRequestTokenConfirmationDialog({
              open: true,
              cb: getEnrolmentToken,
            });
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowEnrolmentTokenDialog(false);
          }}
          isOpen={showEnrolmentTokenDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'confirmation-enrolment-token-dialog'}
          title='Request New Token'
          msg='Requesting a new Token will invalidate all previously downloaded Tokens'
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            requestTokenConfirmationDialog.cb();
            setRequestTokenConfirmationDialog(
              initRequestTokenConfirmationDialog,
            );
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setRequestTokenConfirmationDialog(
              initRequestTokenConfirmationDialog,
            );
          }}
          isOpen={requestTokenConfirmationDialog.open}
        />
        {renderDialog()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle(false, 'DEVICE')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.DEVICEWRITE,
            ) && (
              <Button
                variant='outlined'
                className={classes.outlinedButton}
                onClick={() => {
                  setRequestTokenConfirmationDialog({
                    open: true,
                    cb: getEnrolmentToken,
                  });
                }}
              >
                <FilterCenterFocusIcon style={{ marginRight: 10 }} />
                {`Enroll new ${configurationUtils.getPageTitle(
                  true,
                  'DEVICE',
                )}`}
              </Button>
            )}
            {PermissionsService.hasPermission(
              PermissionsPermissionsEnum.DEVICEWRITE,
            ) && (
              <Button
                data-testid={'devices-add-button'}
                variant='contained'
                className={classes.containedButton}
                style={{ marginLeft: 15 }}
                fullWidth={false}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
              >
                <AddToPhotosOutlinedIcon
                  style={{ marginRight: 10 }}
                />
                Add {configurationUtils.getPageTitle(true, 'DEVICE')}
              </Button>
            )}
          </Grid>
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS_DEVICES}
          dataTestIdPrefix={'devices'}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.DEVICE}
          fetch={fetchDevices}
          sortableBy={['version_id']}
          filterIsEnabled={true}
          fetchfilters={filters}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || undefined
          }
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    dialogRoot: {
      minWidth: 400,
    },
    inputName: {
      padding: '0 7px',
      '& input': {
        textTransform: 'uppercase',
      },
    },
    inputNameHolder: {
      minWidth: '12em',
      marginRight: theme.spacing(2),
      border: `2px solid ${colors.dark}`,
      borderRadius: '3px',
      backgroundColor: '#fff',
      width: '100%',
      '&$checked': {
        color: '#444444',
      },
    },
    inputNameLabel: {
      padding: 7,
    },
  })),
)(Devices);
