import {
  Station,
  StationsApi,
  LocationsApi,
  LocationStationTypeEnum,
  StationDetails,
  PatchStationRoles,
} from 'cloudsort-client';
import axios from '../utils/axios';
import {
  MAX_PAGE_SIZE,
  LABEL_DATA_FORMAT,
  LABEL_DPI,
  LABEL_SIZE,
} from './utils/constants';
import EphemeralStateService from './EphemeralState.service';

class Stations {
  private stationsApi: StationsApi;
  private locationsApi: LocationsApi;

  constructor() {
    this.stationsApi = new StationsApi(
      undefined,
      undefined,
      axios as any,
    );
    this.locationsApi = new LocationsApi(
      undefined,
      undefined,
      axios as any,
    );

    this.getByName = this.getByName.bind(this);
    this.getAllStations = this.getAllStations.bind(this);
    this.getAllStations = this.getAllStations.bind(this);
  }

  // Locations

  public async getByName(
    name = '',
    excludeRoute?: number,
    pageSize?: number,
    locationType?:
      | 'CLOUDSORT'
      | 'CLOUDSORT_TEMPLATE'
      | 'USPS_DNDC'
      | 'USPS_DSCF'
      | 'USPS_ADC'
      | 'USPS_ASF'
      | 'USPS_DDU'
      | 'USPS_ISC'
      | 'FEDEX_HUB',
    stationOwner?: number,
  ) {
    return this.locationsApi.locationsList({
      search: name,
      excludeRoute,
      pageSize,
      locationType,
      stationOwner,
    });
  }

  public async getAllLocations(
    locationType?: LocationStationTypeEnum,
    geoSearch?: string,
  ) {
    return this.locationsApi.locationsList({
      pageSize: MAX_PAGE_SIZE,
      locationType,
      geoSearch,
    });
  }

  public async getLocations(params: {
    search?: string;
    geoSearch?: string;
    locationType?: LocationStationTypeEnum;
    excludeRoute?: number;
    route?: number;
    sortBy?: 'routes_count' | '-routes_count';
    fmc?: string;
    page?: number;
    pageSize?: number;
    loadplan?: number;
    stationOwner?: number;
    station?: number;
  }) {
    return this.locationsApi.locationsList({
      ...params,
    });
  }

  public async getLocationById(id: number) {
    return this.locationsApi.locationsRead({
      id,
    });
  }

  public async locationsExport(params: {
    search?: string;
    loadplan?: number;
    geoSearch?: string;
    locationType?: LocationStationTypeEnum;
    excludeRoute?: number;
    station?: number;
    route?: number;
    fmc?: string;
    sortBy?: 'routes_count' | '-routes_count';
    page?: number;
    pageSize?: number;
    options?: any;
  }) {
    return this.locationsApi.locationsCsv({
      ...params,
      stationOwner: EphemeralStateService.getMyStationId(),
    });
  }

  // Stations

  public async createStation(data: Station) {
    return this.stationsApi.stationsCreate({
      data,
    });
  }

  public async getAllStations(all?: boolean) {
    return this.stationsApi.stationsList({
      pageSize: MAX_PAGE_SIZE,
    });
  }

  public async getAllStationsSearch(search?: string) {
    return this.stationsApi.stationsList({
      search,
    });
  }

  public async getStationById(id: number) {
    return this.stationsApi.stationsRead({
      id,
    });
  }

  public async deleteStation(id: number) {
    return this.stationsApi.stationsDelete({
      id,
    });
  }

  public async getStationByOrg(
    organization: number,
    pageIndex: number | undefined,
    rowsPerPage: number,
  ) {
    return this.stationsApi.stationsList({
      organization,
      pageSize: rowsPerPage,
      page: pageIndex,
    });
  }

  public async getStationRoles(
    id: number,
    currentStation: boolean = false,
  ) {
    return this.stationsApi.stationsPermissionsRead({
      id: currentStation
        ? EphemeralStateService.getMyStationId()
        : id,
    });
  }

  public async editStation(data: StationDetails) {
    return this.stationsApi.stationsPartialUpdate({
      id: data.id!,
      data,
    });
  }

  public async create(data: Station) {
    return this.stationsApi.stationsCreate({
      data,
    });
  }

  public async updateStationRoles(
    id: number,
    data: PatchStationRoles,
  ) {
    return this.stationsApi.stationsPermissionsPartialUpdate({
      id,
      data,
    });
  }

  public async getLabel(params: {
    area?: number;
    areas?: boolean;
    zone?: number;
    zones?: boolean;
    activeLoadpoints?: boolean;
    inactiveLoadpoints?: boolean;
    dockdoors?: boolean;
    staff?: boolean;
    dataFormat?: 'ZPL' | 'PNG';
    size?: string;
    dpi?: number;
  }) {
    return this.stationsApi.stationsLabels({
      ...params,
      id: EphemeralStateService.getMyStationId()!,
      dataFormat: LABEL_DATA_FORMAT,
      size: LABEL_SIZE,
      dpi: LABEL_DPI,
      options: { responseType: 'blob' },
    });
  }

  public async importStops(params: {
    dryRun: boolean;
    file: any;
    options?: any;
  }) {
    return this.stationsApi.stationsStopsImport({
      ...params,
      id: EphemeralStateService.getMyStationId(),
    });
  }
}

export default new Stations();
