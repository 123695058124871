import React, { useEffect, useState, Fragment } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Typography } from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import { AlertBanner } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';
import colors from '../../../utils/colors';
import browserHistory from '../../../utils/browserHistory';

import Button from '@material-ui/core//Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';

// Types
import { Module, NestedModule } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import ZoneModulesService from '../../../services/ZoneModules.service';
import { AuthRoutes } from '../../../interfaces/routes';
import { Helmet } from 'react-helmet';
import configurationUtils from '../../../utils/configurationUtils';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const AddStationModule: React.FC<Props> = ({ classes, match }) => {
  const [modules, setModules] = useState<Module[]>();
  const [selectedModuleId, setSelectedModuleId] = useState<number>();
  const [sortBy, setSortBy] = useState<string>('none');
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const fetchModules = async () => {
    setShowProgress(true);
    try {
      const data = (
        await ZoneModulesService.getAll({
          sortBy: (sortBy === 'none' ? undefined : sortBy) as any,
          areaType: match.params.areaType,
        })
      ).data.results;
      setModules(data);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const onAddClick = async () => {
    setShowProgress(true);
    try {
      const selectedModule = modules?.find(
        (m) => m.id === selectedModuleId!,
      );
      if (selectedModule) {
        const data = (
          await ZoneModulesService.getAll({
            zone: (match.params as any).zoneId,
            sortBy: (sortBy === 'none' ? undefined : sortBy) as any,
          })
        ).data.results;
        if (
          data.some((module) => module.name === selectedModule.name)
        ) {
          setShowConfirmationDialog(true);
          return;
        } else {
          add();
        }
      }
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const add = async () => {
    setShowProgress(true);
    try {
      const selectedModule = modules?.find(
        (m) => m.id === selectedModuleId!,
      );
      await ZoneModulesService.assignModuleToAZone(
        match.params.zoneId,
        selectedModuleId!,
        selectedModule! as NestedModule,
      );
      setTimeout(() => {
        browserHistory.push(
          `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
        );
      }, 0);
    } catch (e) {
      handleError(e);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  useEffect(() => {
    fetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(
  true,
  'AREA',
)} - Add Station Module`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {showConfirmationDialog && (
          <ConfirmationDialog
            dataTestIdPrefix={'add-station-module-'}
            title={'Duplicate name'}
            msg={
              'There is already a module with the same name on the zone, are you sure you want to add this module?'
            }
            onPrimaryAction={() => {
              add();
            }}
            onCancel={() => {
              setShowConfirmationDialog(false);
            }}
            isOpen={showConfirmationDialog}
          />
        )}
        <div className={classes.header}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography className={classes.title} variant={'h3'}>
                Adding Station Module
              </Typography>
              <Typography className={classes.subTitle}>
                Please select at least a module to start
              </Typography>
            </div>

            <FormControl className={classes.filter}>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value as string);
                }}
                variant='outlined'
                labelWidth={55}
                className={classes.muiSelect}
              >
                <MenuItem value='none'>None</MenuItem>
                <MenuItem value={'load_point_count'}>
                  Load Point Number
                </MenuItem>
                <MenuItem value={'-load_point_count'}>
                  {'Reverse Load Point Number'}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {!!modules?.length ? (
          <Fragment>
            <Toolbar
              style={{
                height: 53,
                backgroundColor: colors.white,
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              <Typography
                style={{
                  color: colors.dark,
                  fontSize: 24,
                }}
              >
                Modules
              </Typography>
            </Toolbar>
            <TableContainer
              component={Paper}
              style={{ marginBottom: 20 }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ height: 67 }}>
                    <TableCell
                      align='center'
                      style={{ width: 100 }}
                    ></TableCell>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>Load Points</TableCell>
                    <TableCell align='center'>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modules.map((module) => (
                    <TableRow key={module.id}>
                      <TableCell align='center'>
                        <Radio
                          style={{ padding: 0 }}
                          checked={module.id === selectedModuleId}
                          color={'primary'}
                          onChange={(e) => {
                            setSelectedModuleId(
                              parseInt(e.target.value),
                            );
                          }}
                          value={module.id}
                          inputProps={{
                            // @ts-ignore
                            'data-testid': 'select-module-radio',
                          }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {!!module.name
                          ? module.name
                          : module.id?.toString().length === 1
                          ? `M 0${module.id}`
                          : `M ${module.id}`}
                      </TableCell>
                      <TableCell align='center'>{`${module.load_point_count} Loadpoints`}</TableCell>
                      <TableCell align='center'>{`${module.area_name} Z${module.zone_name}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        ) : (
          modules?.length === 0 && (
            <AlertBanner
              severity='info'
              alertTitle={
                'There is no information to display at the moment'
              }
            />
          )
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            variant='contained'
            className={classes.buttonContained}
            onClick={() => {
              browserHistory.push({
                pathname: `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            data-testid={'add-station-module-button'}
            disabled={!selectedModuleId || !!error}
            variant='contained'
            className={classes.buttonContained}
            onClick={() => {
              onAddClick();
            }}
          >
            ADD
          </Button>
        </div>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    title: {
      height: 28,
      color: colors.title,
      fontSize: 24,
      [theme.breakpoints.down('xs')]: {
        height: 'fit-content',
      },
      fontWeight: 'normal',
      opacity: 1,
      margin: '6px 0',
    },
    subTitle: {
      color: colors.gray,
      fontSize: 15,
      letterSpacing: 0,
      lineHeight: '18px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '40px 0 20px 0',
      paddingBottom: 15,
      borderBottom: '0.5px solid rgb(220, 220, 220)',
    },
    filter: {
      margin: '0 0 10px 25px',
      [theme.breakpoints.down('xs')]: {
        margin: '0px',
      },
      height: 56,
      width: 175,
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
        {
          borderColor: `${colors.darkGold} !important`,
          borderWidth: 2,
        },
      '& .MuiFormLabel-root': {
        top: '-6px',
        left: 15,
      },
    },
    muiSelect: {
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    buttonContained: {
      color: colors.white,
      backgroundColor: colors.darkGold,
      '&:hover': {
        backgroundColor: colors.darkGold,
      },
    },
  })),
)(AddStationModule);
