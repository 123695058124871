import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

export default createStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    height: 'auto',
    borderRadius: 4,
    backgroundColor: colors.white,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    marginBottom: 16,
    padding: '10px 0',
  },
  icon: {
    color: colors.darkGold,
    paddingLeft: 10,
    marginBottom: -5,
  },
  progressRoot: {
    height: 9,
    borderRadius: 4.5,
  },
  barColorGold: {
    backgroundColor: colors.darkGold,
  },
  barColorGrey: {
    backgroundColor: colors.ultraLightGray,
  },
}));
