import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    stale: false,
  },
  reducers: {
    updateNavStart: (state) => {
      state.stale = true;
    },
    updateNavEnd: (state) => {
      state.stale = false;
    },
  },
});

export const { updateNavStart, updateNavEnd } = configSlice.actions;

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
