import React, { useEffect, useState, Fragment } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Typography, Button, AlertBanner } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import colors from '../../utils/colors';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import selectStyles from '../select/select.styles';
import configurationUtils from '../../utils/configurationUtils';
import { Link, match } from 'react-router-dom';
import { common } from '../../utils/strings';
// Icons
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

// Types
import { AxiosError } from 'axios';
import {
  PermissionsPermissionsEnum,
  PermissionsRoleTypeEnum,
  Staff,
} from 'cloudsort-client';
import {
  OT_PERMISSIONS_LABELS,
  OTHER_PERMISSIONS_LABELS,
  ROLE_LABELS,
} from './StationStaff';

// Services
import StaffService from '../../services/Staff.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const StationStaffDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const [arePermissionsReady, setArePermissionsReady] =
    useState<boolean>(false);

  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();
  const [error, setError] = useState<string>();

  const backUrl = `${AuthRoutes.STAFF}/${(match.params as any).id}`;

  const toggleEditMode = () => {
    setEnableSave(!enableSave);
  };

  useEffect(() => {
    if (staffData?.permission_data?.permissions) {
      setArePermissionsReady(true);
    }
  }, [staffData]);

  const onSaveClick = async () => {
    try {
      setShowProgress(true);
      if (staffData?.id) {
        //unset email so we don't need re-auth dialog
        staffData.email = undefined;

        staffData.permission_data!.station =
          EphemeralStateService.getMyStationId();
        await StaffService.update(staffData?.id, staffData);
        await getStaffData();
        toggleEditMode();
        browserHistory.push(backUrl);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const getStaffData = async () => {
    setShowProgress(true);
    try {
      const { data } = await StaffService.getById(
        (match.params as any).id,
        EphemeralStateService.getMyStationId(),
      );
      //set permissions if not returned by BE sso page doesn't break
      if (!data.permission_data)
        data.permission_data = {
          role_type: PermissionsRoleTypeEnum.OPERATORI,
          permissions: [],
        };
      setStaffData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const selectRole = (role: PermissionsRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffData({
      ...staffData,
      permission_data: {
        role_type: role,
        permissions: defaultPermissions,
      },
    });
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      PermissionsPermissionsEnum.USERREAD,
    );
    if (!staffData) {
      getStaffData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPermissionsListAsCheckBoxes = (
    title: string,
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffData!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <>
        <Typography
          className={classes.boldNameParam}
          style={{
            paddingBottom: 16,
            marginBottom: 16,
            borderBottom: `1px solid ${colors.lightGray}`,
          }}
        >
          {title}
        </Typography>

        {Object.keys(PermissionsPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionsToRender).includes(permission),
          )
          .map((key) => {
            return PermissionsPermissionsEnum[
              key as keyof typeof PermissionsPermissionsEnum
            ];
          })
          .map((permission) => {
            return (
              <Fragment
                key={`fragment-${permissionLabels[permission]}`}
              >
                {staffData?.permission_data?.permissions && (
                  <Tooltip
                    classes={{
                      tooltipArrow: classes.tooltip,
                      arrow: classes.tooltipArrow,
                    }}
                    title={
                      dependencyStatus[permission].enabled
                        ? ''
                        : dependencyStatus[permission].tooltipMessage
                    }
                    placement='left'
                    arrow
                  >
                    <span>
                      <FormControlLabel
                        data-testid='select-user-permissions-checkboxes'
                        key={permissionLabels[permission]}
                        style={{ width: '100%' }}
                        control={
                          <Checkbox
                            disabled={
                              !dependencyStatus[permission].enabled
                            }
                            data-testid={`permissions-checkbox-${permissionLabels[permission]}`}
                            color={undefined}
                            key={`checkbox-${permissionLabels[permission]}`}
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            checked={staffData.permission_data?.permissions.some(
                              (selectedPermission) =>
                                selectedPermission === permission,
                            )}
                            onChange={(e) => {
                              const isAlreadySelected =
                                staffData.permission_data?.permissions!.some(
                                  (selectedPermission) =>
                                    selectedPermission === permission,
                                );
                              if (isAlreadySelected) {
                                const {
                                  newPermissionsState,
                                  disabledPermissions,
                                } = disablePermissionAndDependants(
                                  permission,
                                  staffData!.permission_data!
                                    .permissions as string[],
                                );
                                //All dependencies are disabled, show notification to the user.
                                if (disabledPermissions.length > 0) {
                                  setShowDependencyNotification(true);
                                  setDependencyNotificationMessage(
                                    `Your selection also disabled the dependant permission${
                                      disabledPermissions.length === 1
                                        ? ''
                                        : 's'
                                    }: ${disabledPermissions.join(
                                      ', ',
                                    )}.`,
                                  );
                                }
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    role_type:
                                      staffData.permission_data
                                        ?.role_type!,
                                    permissions: [
                                      ...newPermissionsState,
                                    ],
                                  },
                                });
                              } else {
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    role_type:
                                      staffData.permission_data
                                        ?.role_type!,
                                    permissions: [
                                      ...staffData.permission_data
                                        ?.permissions,
                                      permission,
                                    ],
                                  },
                                });
                              }
                              setEnableSave(true);
                            }}
                          />
                        }
                        label={permissionLabels[permission]}
                      />
                    </span>
                  </Tooltip>
                )}
              </Fragment>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'STAFF')}'s Permissions for ${
            staffData?.full_name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STAFF'>
        <Snackbar
          data-testid='snackbar-notification'
          key={dependencyNotificationMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showDependencyNotification}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setShowDependencyNotification(false);
          }}
          autoHideDuration={4000}
          message={dependencyNotificationMessage}
        />
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid={'station-staff-permissions-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Link to={backUrl} className={classes.back}>
              <ArrowLeftIcon />
              <Typography>Back</Typography>
            </Link>
            <Typography className={classes.title}>
              {`${configurationUtils.getPageTitle(
                true,
                'STAFF',
              )}'s Permissions for ID ${staffData?.id || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              disabled={!staffData}
              fullWidth={false}
              variant='contained'
              className={classes.containedButton}
              onClick={(e) => {
                e.preventDefault();

                setError(undefined);
                browserHistory.push(backUrl);
              }}
            >
              <CancelOutlinedIcon style={{ marginRight: 10 }} />
              Cancel
            </Button>
            <Button
              data-testid={'station-staff-permissions-save-button'}
              disabled={!staffData || !enableSave || !!error}
              fullWidth={false}
              variant='contained'
              className={classes.containedButton}
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                onSaveClick();
              }}
            >
              <CheckCircleOutlineIcon style={{ marginRight: 10 }} />
              Save
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ padding: '15px 30px' }}
              data-testid=''
            >
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label={
                  configurationUtils.getPageTitle(true, 'STAFF') +
                  ' ID'
                }
                value={staffData?.id}
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Station'
                value={
                  staffData?.permission_data?.station_name ||
                  common.emptyValue
                }
              />
              <SingleDetail
                inline={true}
                valueStyle={{ color: colors.darkGold }}
                label='Label'
                value={staffData?.full_name || common.emptyValue}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6}>
            <Paper
              className={classes.paper}
              style={{ marginRight: 18, padding: 16, height: '100%' }}
            >
              <Typography
                className={classes.boldNameParam}
                style={{
                  paddingBottom: 16,
                  marginBottom: 16,
                  borderBottom: `1px solid ${colors.lightGray}`,
                }}
              >
                Role
              </Typography>
              <FormControl
                className={classes.formControl}
                style={{ width: '100%', margin: 0, height: 50 }}
                data-testid='select-user-role'
              >
                <InputLabel className={classes.selectLabel}>
                  {configurationUtils.getPageTitle(true, 'STAFF')}{' '}
                  Role
                </InputLabel>
                {staffData?.permission_data?.role_type && (
                  <Select
                    disableUnderline={true}
                    onChange={(e: React.BaseSyntheticEvent) => {
                      selectRole(e.target.value);
                      setEnableSave(true);
                    }}
                    classes={{
                      selectMenu: classes.selectMenu,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      classes: {
                        paper: classes.selectPaper,
                        list: classes.selectList,
                      },
                      style: { width: '100%' },
                    }}
                    value={staffData?.permission_data.role_type}
                  >
                    {Object.keys(PermissionsRoleTypeEnum)
                      .filter((key) => key.includes('OPERATOR'))
                      .map((key) => {
                        return {
                          label:
                            ROLE_LABELS[
                              key as keyof typeof PermissionsRoleTypeEnum
                            ],
                          value:
                            PermissionsRoleTypeEnum[
                              key as keyof typeof PermissionsRoleTypeEnum
                            ],
                        };
                      })
                      .map((permission) => {
                        return (
                          <MenuItem
                            key={`fragment-${permission.label}`}
                            value={permission.value}
                          >
                            {permission.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            {arePermissionsReady ? (
              <>
                <Paper
                  className={classes.paper}
                  style={{ padding: 16 }}
                >
                  {renderPermissionsListAsCheckBoxes(
                    'Operator Tool Permissions',
                    OT_PERMISSIONS_LABELS,
                  )}
                </Paper>
                <br />
                <Paper
                  className={classes.paper}
                  style={{ padding: 16 }}
                >
                  {renderPermissionsListAsCheckBoxes(
                    'Other Permissions',
                    OTHER_PERMISSIONS_LABELS,
                  )}
                </Paper>
              </>
            ) : (
              <ProgressIndicator />
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    checkbox: {
      color: '#444444',
      '&$checked': {
        color: colors.darkGold,
      },
    },
    checked: {
      color: colors.darkGold,
    },
    divider: {
      height: 2,
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    tooltip: {
      background: colors.dark,
    },
    tooltipArrow: {
      color: colors.dark,
    },
  })),
)(StationStaffDetailsComponent);
